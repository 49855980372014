import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import InvestmentChart from '../../InvestmentChart';
import {
  BorderedContentWrapper,
  Highlight,
  Subtle,
  BorderedSection,
} from '../../StyedComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ItemIconSvg from '../../../../public/images/industries/finance.svg';
import LogoNotFound from '../../../../public/images/logo-not-found.png';
import Trend from '../../Trend';

const portfolios = [
  {
    name: 'Food & Market',
    percent: '32',
    value: '216.05',
    color: '#35C1B9',
    companies: [
      { name: 'Apple Inc.', symbol: 'AAPL', currentPrice: '110.65' },
      { name: 'Starbucks', symbol: 'SBUX', currentPrice: '106.05' },
    ],
  },
  { name: 'Technology', percent: '24', value: '122.9', color: '#F1A741' },
  { name: 'Finance', percent: '16', value: '98.11', color: '#F37247' },
  { name: 'Agro', percent: '14', value: '84.53', color: '#9C81DA' },
];

const data = [
  { name: 'Food & Market', y: 6, color: '#35C1B9' },
  { name: 'Technology', y: 5, color: '#F1A741' },
  { name: 'Finance', y: 3, color: '#F37247' },
  { name: 'Agro', y: 3, color: '#9C81DA' },
];

const Item = styled(BorderedSection)`
  ${tw`flex p-4 mt-4`};
`;

const ItemIcon = styled.div`
  ${tw`w-10 h-10 rounded-sm p-2.5`}
  background-color: ${(props) => props.bgColor};
`;

export const InvestedCompanies = styled.div`
  ${tw`rounded-t-none`}
  background-color: ${(props) => props.theme.pageTab.background};
  border: 1px solid ${(props) => props.theme.pageTab.border};
  border-top: 0;
`;

const MyInvestmentItem = ({ item }) => {
  const [openItem, setOpenItem] = React.useState(false);
  return (
    <>
      <Item onClick={() => setOpenItem(!openItem)}>
        <div className="w-1/6">
          <ItemIcon bgColor={item.color}>
            <img className="w-5 h-5" src={ItemIconSvg} alt="" />
          </ItemIcon>
        </div>
        <div className="w-5/6 flex justify-between text-sm font-medium my-auto">
          <div className="w-1/2 ml-4">
            <Highlight>{item.name}</Highlight>
            <Subtle>{item.percent}%</Subtle>
          </div>
          <div className="w-1/2 flex justify-around">
            <Highlight>${item.value}</Highlight>
            <Subtle className="ml-2 cursor-pointer">
              <FontAwesomeIcon
                className={`text-lg ${item.companies ? 'block' : 'hidden'}`}
                icon={openItem ? faAngleUp : faAngleDown}
              />
            </Subtle>
          </div>
        </div>
      </Item>
      {item.companies &&
        openItem &&
        item.companies.map((company, index) => (
          <InvestedCompanies
            key={index}
            className={`flex p-4 ${
              index === item.companies.length - 1 ? 'rounded' : ''
            }`}
          >
            <div className="w-1/6">
              <BorderedSection className="w-10 h-10">
                <img src={LogoNotFound} alt="" />
              </BorderedSection>
            </div>
            <div className="w-5/6 flex justify-between text-sm font-medium my-auto">
              <div className="w-1/2 ml-4">
                <Highlight>{company.name}</Highlight>
                <Subtle>{company.symbol}%</Subtle>
              </div>
              <div className="float-right">
                <Highlight>${company.currentPrice}</Highlight>
                <div className="flex mt-0.5">
                  <Trend trend={true} color="#36A867" className="mt-1" />
                  <span
                    className="mx-2 text-xs md:text-sm"
                    style={{ color: '#36A867' }}
                  >
                    +1.35
                  </span>
                </div>
              </div>
            </div>
          </InvestedCompanies>
        ))}
    </>
  );
};

const MyInvestments = () => {
  return (
    <BorderedContentWrapper className="rounded" style={{ paddingBottom: 0 }}>
      <Highlight className="font-medium">My Investments</Highlight>
      <InvestmentChart data={data} />
      <div className="relative bottom-12">
        {portfolios.map((portfolio, index) => (
          <MyInvestmentItem item={portfolio} key={index} />
        ))}
      </div>
    </BorderedContentWrapper>
  );
};

export default MyInvestments;
