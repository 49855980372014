import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import PriceGrowthButtonGroup from '../MultiSelectButtonGroupRevamp';

const StyledTable = styled.table`
  ${tw`border-collapse table-auto w-full my-4 text-sm`}
`;

const StyledTableData = styled.td`
  ${tw`py-2 px-8 border-0`}
  color: ${(props) =>
    props.first
      ? props.theme.table.headerColor
      : props.type === 'positive'
      ? props.theme.bulletPoints.positive
      : props.type === 'negative'
      ? props.theme.bulletPoints.negative
      : props.theme.table.color};
  font-weight: ${(props) => (props.first ? '500' : 'normal')};
`;

export default function PriceGrowthTable(props) {
  const { columns, data, timeSeries, setTimeSeries, darkMode } = props;

  const StyledTableHead = styled.th`
    ${tw`py-2 px-8 font-medium border-0 text-left`}
    background-color: ${(props) => (darkMode ? '#434243' : '#F4F4F4')};
    border: 1px solid ${(props) => props.theme.table.headerBorder};
    border-left: 0;
    border-right: 0;
    color: ${darkMode ? 'white' : 'black'};
  `;

  const BorderedDiv = styled.div`
    background-color: ${(props) =>
      darkMode ? '#262626' : props.theme.table.background};
    border: 0px solid ${(props) => props.theme.table.border};
    color: ${(props) => props.theme.content.color};
  `;

  const tableInstance = useTable({ columns, data });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <BorderedDiv>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <PriceGrowthButtonGroup
          values={timeSeries}
          setValues={setTimeSeries}
          darkMode={props.darkMode}
        />
      </div>
      <div className="overflow-x-scroll">
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableHead {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </StyledTableHead>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <StyledTableData
                        first={index === 0}
                        type={
                          index !== 0 && cell.value < 0
                            ? 'negative'
                            : cell.value > 0
                            ? 'positive'
                            : 'neutral'
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </StyledTableData>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </div>
    </BorderedDiv>
  );
}
