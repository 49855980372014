import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  buildTooltipByValue,
  getFormattedCurrencyValueForTooltip,
  getTooltipDate,
} from '../../../../../utils/ChartUtils';

import { getLogoUrl } from '../../../../../utils/LogoUtils';
import SectorLogo from '../../../SectorLogo';
import { bg } from 'date-fns/locale';

const Chart = ({
  data,
  darkMode,
  chartData = null,
  responsive = false,
  showLegend = false,
  format,
  tooltipFormatter = undefined,
  hideSymbol,
  callback,
  title,
}) => {
  const [mainData, setMainData] = useState([]);
  const [mouseOut, setMouseOut] = useState(false);
  const [clicked, setClicked] = useState(false);

  function getValue(_this) {
    const data = _this.point && _this.point.options;
    data && setMainData(data);
  }

  const options = useMemo(() => {
    let result =
      chartData !== null ||
      data.map((item) => ({
        name: item.HoldingName,
        y: item['value'],
        symbol: item.symbol,
        valuePercent: item['value'],
        value: item['value'],
        color: item.color,
      }));

    // .sort((a, b) => b.y - a.y);

    const option = {
      chart: {
        zoomType: 'xy',
        type: 'pie',
        style: {
          fontFamily: 'Roboto',
        },
        backgroundColor: darkMode ? 'transparent' : '#ffffff',
        width: responsive ? null : 400,
      },
      title: {
        verticalAlign: 'middle',
        floating: true,
        text: title,
        style: {
          color: '#808080',
          fontSize: '20px',
        },
      },

      plotOptions: {
        pie: {
          allowPointSelect: 'true',
          cursor: 'pointer',
          center: ['50%', '50%'],
          startAngle: 0,
          size: 20,
          borderWidth: 1,
          borderColor: darkMode ? '#1d212b' : 'white',
          point: {
            events: {
              click: (event) => setClicked(true),
              mouseOver: (event) => setMouseOut(false),
              mouseOut: (event) => setMouseOut(true),
            },
          },
        },
      },
      tooltip: {
        valueDecimals: 2,
        hideDelay: 600,
        shadow: false,
        useHTML: true,
        borderRadius: 8,
        followPointer: true,

        shared: true,
        formatter: function () {
          getValue(this);
          callback(mainData, clicked, mouseOut);
          setClicked(false);
          return false;
        },
      },
      legend: {
        // enabled: true,
      },
      label: {
        // enabled: true,
      },
      credits: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400,
            },
            chartOptions: {
              plotOptions: {
                //pointWidth: 20,
                pie: {
                  size: '70%',
                  borderWidth: 1,
                  borderColor: darkMode ? '#1d212b' : 'white',
                },
              },
              // tooltip: {},
              chart: {
                height: 420,
                width: 360,
              },
            },
          },
        ],
      },
      series: [
        {
          name: 'Portfolio',
          colorByPoint: true,
          bg: result.color,
          data: result,
          dataSorting: {
            enabled: false,
            sortKey: 'y',
          },

          borderWidth: 1,
          size: '69%',
          innerSize: '69%',
          plotOptions: {
            pie: {
              size: '80%',
              borderWidth: 1,
              borderColor: darkMode ? '#1d212b' : 'white',
            },
          },
          dataLabels: {
            enabled: showLegend || false,

            color: darkMode ? 'white' : 'black',
            style: {
              fontSize: '1.2em',
            },
          },
          label: { enabled: showLegend || false },
        },
      ],
    };
    if (format) {
      option.series[0].dataLabels.formatter = format;
      option.series[0].dataLabels.useHTML = true;
    }

    return option;
  }, [
    callback,
    chartData,
    clicked,
    darkMode,
    data,
    format,
    mainData,
    mouseOut,
    responsive,
    showLegend,
    title,
  ]);

  return (
    <div className="text-xs font-semibold">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(Chart);
