import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw from 'twin.macro';
import { ColoredContentText, ColoredHeaderText } from '../StyedComponents';

export const ScaleSegment = styled.div`
  min-width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background: ${(props) => props.theme.verdeScale.background};
  border-top: 1px solid ${(props) => props.theme.sectionBorder};
  border-bottom: 1px solid ${(props) => props.theme.sectionBorder};
`;

export const ScaleStartSection = styled(ScaleSegment)`
  border-radius: 18px 0 0 18px;
`;

export const ScaleEndSection = styled(ScaleSegment)`
  border-radius: 0 18px 18px 0;
`;

export const InnerScaleSegment = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background: ${(props) => props.theme.verdeScale[props.type].background};
  border: 1px solid ${(props) => props.theme.verdeScale[props.type].border};
  border-radius: ${(props) => props.height / 4}px;
  margin-top: 12.5px;
`;

export const InnerScaleStartSection = styled(InnerScaleSegment)`
  border-radius: ${(props) => props.height / 2}px
    ${(props) => props.height / 4}px ${(props) => props.height / 4}px
    ${(props) => props.height / 2}px;
`;

export const InnerScaleEndSection = styled(InnerScaleSegment)`
  border-radius: ${(props) => props.height / 4}px
    ${(props) => props.height / 2}px ${(props) => props.height / 2}px
    ${(props) => props.height / 4}px;
`;

export const CurrentPriceMarker = styled.div`
  ${tw`flex justify-center items-center`};
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  padding: 5px;
  position: relative;
  left: ${(props) => props.mark}px;
  top: 5px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 5px 5px;
  border: solid 1px ${(props) => props.theme.verdeScale.markerBorder};
  background-color: ${(props) => props.theme.verdeScale.markerBackground};
`;

export const FairPriceMarker = styled(CurrentPriceMarker)`
  padding: 3px;
`;

export const TargetPriceMarker = styled(CurrentPriceMarker)`
  top: -98px;
  padding: 8px 0 0 0;
  border-radius: 5px 5px 0px 0px;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #e28904 !important;
  vertical-align: top;
  font-size: 1.16rem;
`;

export const VerticalLine = styled.div`
  height: 52px;
  border-right: 2px dashed ${(props) => props.theme.verdeScale.markerLine};
  position: relative;
  left: ${(props) => props.mark}px;
  top: ${(props) => props.top}px;
`;

export const PriceTextMarker = styled.div`
  width: ${(props) => props.width}px;
  height: 45px;
  font-size: 0.75rem;
  position: relative;
  left: ${(props) => props.mark}px;
  top: 5px;
  text-align: center;
`;

export const PriceHeader = styled(ColoredHeaderText)`
  ${tw`text-xs md:text-sm`};
`;

export const PriceValue = styled(ColoredContentText)`
  ${tw`text-xs md:text-sm font-semibold`};
`;

export const ValuedTextMarker = styled(PriceTextMarker)`
  ${tw`relative rounded`};
  left: ${(props) => props.mark}px;
  top: ${(props) => props.top}px;
  background-color: ${(props) => props.theme.verdeScale.valuedTextBackground};
  border: 1px solid ${(props) => props.theme.sectionBorder};
  border-top: 0;
`;

export const MarkerImg = styled.img`
  ${tw`mx-auto`};
  width: 22px;
`;

export const UpArrow = styled.div`
  ${tw`w-0 h-0 relative bottom-2`};
  left: ${(props) => props.mark - 6}px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid
    ${(props) => props.theme.verdeScale.valuedTextBackground};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export const OvervaluedPercent = styled.p`
  color: ${(props) =>
    props.isOvervalued ? props.theme.negative : props.theme.positive};
`;
