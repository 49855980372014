export const showSuccessAlert = (message) => ({
  type: 'SHOW_ALERT',
  message,
  alertType: 'success',
});

export const showErrorAlert = (message) => ({
  type: 'SHOW_ALERT',
  message,
  alertType: 'error',
});

export const removeAlert = () => ({
  type: 'REMOVE_ALERT',
});
