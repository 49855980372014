import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';
import axios from 'axios';

function* fetchPastPerformanceData(action) {
  yield put({ type: 'FETCH_PASTPERFORMANCE_DATA_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .get(`${SYMBOL_BASE_URL}/pastperformance/${action.payload.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_PASTPERFORMANCE_DATA_SUCCESS',
      data: responseData,
      symbol: action.payload.toUpperCase(),
    });
  } catch (error) {
    yield put({
      type: 'FETCH_PASTPERFORMANCE_DATA_ERROR',
      error: error.message,
    });
  }
}

function* fetchPastPerformancePublicData(action) {
  yield put({ type: 'FETCH_PASTPERFORMANCE_DATA_LOADING' });
  try {
    const responseData = yield axios
      .get(`${SYMBOL_BASE_URL}/pastperformance-public/${action.payload.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_PASTPERFORMANCE_DATA_SUCCESS',
      data: responseData,
      symbol: action.payload.toUpperCase(),
    });
  } catch (error) {
    yield put({
      type: 'FETCH_PASTPERFORMANCE_DATA_ERROR',
      error: error.message,
    });
  }
}

export function* watchFetchingPastPerformanceData() {
  yield takeEvery('FETCH_PASTPERFORMANCE_DATA', fetchPastPerformanceData);
  yield takeEvery('FETCH_PASTPERFORMANCE_PUBLIC_DATA', fetchPastPerformancePublicData);
}
