import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useState, FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import VerdeLogo from '../../../public/images/VerdeLogo.png';
import { DefaultTheme, useTheme } from 'styled-components';
import { showPageLoading } from '../../../actions/coreAction';
import PageContainer from '../../shared/PageContainer';
import { useMediaQuery } from '@chakra-ui/react';
import WrappedAxios from '../../../utils/WrappedAxios';
import { Link } from 'react-router-dom';
import { SUPER_INVESTOR_URL } from '../../../config/default';

const PageContainerW = PageContainer;

const SuperInvestors = ({ darkMode, showPageLoading }) => {
  const currentTheme = useTheme();
  const [data, setData] = useState([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  showPageLoading(data.length === 0);

  useEffect(() => {
    async function antiPattern() {
      setData(
        (await WrappedAxios.get(`${SUPER_INVESTOR_URL}/get-superinvestors`))
          .data
      );
    }

    antiPattern();
  }, []);

  return (
    <Box backgroundColor={darkMode ? '#161616' : '#f4f4f4'}>
      <PageContainerW>
        <Box mt="6rem">
          <Box>
            <Text
              style={{
                marginRight: 30,
                color: darkMode ? '#cccccc' : 'black',
                fontSize: '24px',
                fontWeight: '600',
              }}
            >
              Super Investors
            </Text>
            <Text
              style={{
                marginRight: 30,
                color: darkMode ? '#cccccc' : 'black',
                fontSize: '22px',
                marginTop: 5,
              }}
            >
              Look into the portfolio of the industry best investors and find
              out their favourite stocks!
            </Text>
          </Box>
        </Box>

        <Flex
          wrap="wrap"
          justifyContent={isMobile ? 'center' : 'flex-start'}
          marginBottom={10}
        >
          {data.map((elem) => {
            return (
              <Box
                width={275}
                height={330}
                marginTop={10}
                padding={5}
                marginRight={10}
                backgroundColor={darkMode ? '#262626' : 'white'}
                borderRadius={5}
              >
                <div style={{ height: 270 }}>
                  <img
                    id="profilePicture"
                    // src={`${`https://images.verde.finance/${params.cik}.jpeg`}`}
                    src={elem['imageUrl']}
                    style={{
                      justifyContent: 'center',
                      borderRadius: 100,
                      width: 125,
                      height: 125,
                      margin: 'auto',
                      alignContent: 'center',
                      objectFit: 'cover',
                    }}
                  />
                  <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      style={{
                        color: darkMode ? 'white' : 'black',
                        fontWeight: 550,
                        marginTop: 10,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        inlineSize: 250,
                        overflow: 'hidden',
                        textAlign: 'center',
                        fontSize: 20,
                      }}
                    >
                      {elem.fundManagerName}
                    </Text>

                    <Text
                      style={{
                        color: darkMode ? '#cccccc' : '#8c8c8c',
                        fontWeight: 500,
                        marginTop: 2,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        inlineSize: 250,
                        overflow: 'hidden',
                        textAlign: 'center',
                        fontSize: 14,
                      }}
                    >
                      {elem.fundName}
                    </Text>
                    {/* <img
                        src={VerdeLogo}
                        style={{
                          width: isMobile ? '15px' : '18px',
                          height: isMobile ? '15px' : '18px',
                          marginTop: 8,
                        }}
                      /> */}
                  </Flex>

                  <Text
                    style={{
                      color: darkMode ? '#cccccc' : 'black',
                      fontSize: 12,
                      marginTop: 10,
                      marginBottom: 10,
                      textAlign: 'center',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {elem.description}
                  </Text>
                </div>

                <Link to={`/superinvestors/${elem.cik}`}>
                  <Flex justifyContent="center">
                    <button
                      style={{
                        padding: 5,
                        width: '75%',
                        backgroundColor: darkMode ? '#4adf79' : '#01874e',
                        borderRadius: 20,
                        marginTop: 10,
                        fontSize: 12,
                        fontWeight: 500,
                        color: darkMode ? 'black' : 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                      }}
                    >
                      View Portfolio
                    </button>
                  </Flex>
                </Link>
              </Box>
            );
          })}
        </Flex>
      </PageContainerW>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperInvestors);
