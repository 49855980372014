export const isProd = true;
//window.location.host === 'beta.verde.finance' ||
//window.location.host === 'verde.finance';
const STAGE = isProd ? 'prod' : 'dev';
const SYMBOL_BASE = isProd ? '1dubrsp7bh' : 'lydhn8bghb';
const SCREENER_BASE = isProd ? '1dubrsp7bh' : 'lydhn8bghb';
const USER_BASE = isProd ? '5zk134fywc' : 'ty4yn4uxlc';
const PLAID_GENERATE = isProd ? 'rqd7am0r37' : 't3b4jp8cw4';
const PLAID_USERS = isProd ? '5zk134fywc' : 'ty4yn4uxlc';
const SUPER_INVESTORS = isProd ? '91p1q6ovwj' : 'vvxz50azh4';
const USER_SOCIAL = isProd ? 'ymguldsjgg' : '1aiz8g7nw3';
const AI_INSIGHTS = isProd ? 'wj5eh9twn9' : 'wj5eh9twn9';

//export const SYMBOL_BASE_URL = 'http://localhost:8080';

export const SUPER_INVESTOR_URL = `https://${SUPER_INVESTORS}.execute-api.us-east-1.amazonaws.com/${STAGE}`;
export const SYMBOL_BASE_URL = `https://${SYMBOL_BASE}.execute-api.us-east-1.amazonaws.com/${STAGE}`;
export const SCREENER_BASE_URL = `https://${SCREENER_BASE}.execute-api.us-east-1.amazonaws.com/${STAGE}`;
export const PLAID_GENERATE_TOKEN = `https://${PLAID_GENERATE}.execute-api.us-east-1.amazonaws.com/${STAGE}`;
export const PLAID_USERS_LINK = `https://${PLAID_USERS}.execute-api.us-east-1.amazonaws.com/${STAGE}`;
export const USER_SOCIAL_LINK = `https://${USER_SOCIAL}.execute-api.us-east-1.amazonaws.com/${STAGE}`;
export const AI_INSIGHTS_LINK = `https://${AI_INSIGHTS}.execute-api.us-east-1.amazonaws.com/${STAGE}`;
export const awsConfig = {
  Auth: {
    region: isProd ? 'us-east-1' : 'us-east-1',
    userPoolId: isProd ? 'us-east-1_LOcAqFynd' : 'us-east-1_y57VVa26H',
    userPoolWebClientId: isProd
      ? 'gfj8o5b815iiaeonbddg7pqgc'
      : '78fv9q5klbu8gfgqcsks0rh0vf',
    /* identityPoolId: isProd
      ? 'us-east-1:7048dfa8-144b-4b20-a378-5f6c29675723'
      : 'us-east-1:7048dfa8-144b-4b20-a378-5f6c29675723', */
    oauth: {
      domain: isProd
        ? 'verde-finance-dev.auth.us-east-1.amazoncognito.com'
        : 'verde-finance-dev.auth.us-east-1.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: `${window.location.origin}/`,
      redirectSignOut: `${window.location.origin}/`,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};
