import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import {
  getStackedAreaChartData,
  QUARTERLY,
} from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';
import ToggleButton from '../../../ToggleButton';
import RiskPositives from '../../../RiskPositives';
import { useMediaQuery } from '@chakra-ui/react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';

const MarketCapMultiples = ({
  data = [],
  insights,
  showOnlyGraph,
  period,
  setPeriod,
  darkMode,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'PE',
      selected: true,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'PEG',
      selected: false,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'PB',
      selected: false,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
    {
      name: 'PS',
      selected: false,
      darkColor: '#DDDDDD',
      lightColor: '#012749',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    [
      'evaluated_on',
      'pe_ratio',
      'price_earnings_growth',
      'price_to_book',
      'price_to_sales',
    ],
    period
  );

  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['PE'].selected
      ? {
          name: 'PE',
          data: fullChartData.map((item) => [item[0], item[1]]),
        }
      : [],
    chartVariants['PEG'].selected
      ? {
          name: 'PEG',
          data: fullChartData.map((item) => [item[0], item[2]]),
        }
      : [],
    chartVariants['PB'].selected
      ? {
          name: 'PB',
          data: fullChartData.map((item) => [item[0], item[3]]),
        }
      : [],
    chartVariants['PS'].selected
      ? {
          name: 'PS',
          data: fullChartData.map((item) => [item[0], item[4]]),
        }
      : [],
  ];

  const priceToEarningsAveragePositives = insights?.priceToEarnings?.average
    ?.positives
    ? insights?.priceToEarnings?.average?.positives
    : [];
  const priceToEarningsIndustryPositives = insights?.priceToEarnings?.industry
    ?.positives
    ? insights?.priceToEarnings?.industry?.positives
    : [];
  const priceToEarningsMarketPositives = insights?.priceToEarnings?.market
    ?.positives
    ? insights?.priceToEarnings?.market?.positives
    : [];
  const priceToEarningsAverageNegatives = insights?.priceToEarnings?.average
    ?.negatives
    ? insights?.priceToEarnings?.average?.negatives
    : [];
  const priceToEarningsIndustryNegatives = insights?.priceToEarnings?.industry
    ?.negatives
    ? insights?.priceToEarnings?.industry?.negatives
    : [];
  const priceToEarningsMarketNegatives = insights?.priceToEarnings?.market
    ?.negatives
    ? insights?.priceToEarnings?.market?.negatives
    : [];

  const priceToEarningsGrowthAveragePositives = insights?.priceToEarningsGrowth
    ?.average?.positives
    ? insights?.priceToEarningsGrowth?.average?.positives
    : [];
  const priceToEarningsGrowthIndustryPositives = insights?.priceToEarningsGrowth
    ?.industry?.positives
    ? insights?.priceToEarningsGrowth?.industry?.positives
    : [];
  const priceToEarningsGrowthMarketPositives = insights?.priceToEarningsGrowth
    ?.market?.positives
    ? insights?.priceToEarningsGrowth?.market?.positives
    : [];
  const priceToEarningsGrowthAverageNegatives = insights?.priceToEarningsGrowth
    ?.average?.negatives
    ? insights?.priceToEarningsGrowth?.average?.negatives
    : [];
  const priceToEarningsGrowthIndustryNegatives = insights?.priceToEarningsGrowth
    ?.industry?.negatives
    ? insights?.priceToEarningsGrowth?.industry?.negatives
    : [];
  const priceToEarningsGrowthMarketNegatives = insights?.priceToEarningsGrowth
    ?.market?.negatives
    ? insights?.priceToEarningsGrowth?.market?.negatives
    : [];

  const priceToBookAveragePositives = insights?.priceToBook?.average?.positives
    ? insights?.priceToBook?.average?.positives
    : [];
  const priceToBookIndustryPositives = insights?.priceToBook?.industry
    ?.positives
    ? insights?.priceToBook?.industry?.positives
    : [];
  const priceToBookMarketPositives = insights?.priceToBook?.market?.positives
    ? insights?.priceToBook?.market?.positives
    : [];
  const priceToBookAverageNegatives = insights?.priceToBook?.average?.negatives
    ? insights?.priceToBook?.average?.negatives
    : [];
  const priceToBookIndustryNegatives = insights?.priceToBook?.industry
    ?.negatives
    ? insights?.priceToBook?.industry?.negatives
    : [];
  const priceToBookMarketNegatives = insights?.priceToBook?.market?.negatives
    ? insights?.priceToBook?.market?.negatives
    : [];

  const priceToSalesAveragePositives = insights?.priceToSales?.average
    ?.positives
    ? insights?.priceToSales?.average?.positives
    : [];
  const priceToSalesIndustryPositives = insights?.priceToSales?.industry
    ?.positives
    ? insights?.priceToSales?.industry?.positives
    : [];
  const priceToSalesMarketPositives = insights?.priceToSales?.market?.positives
    ? insights?.priceToSales?.market?.positives
    : [];
  const priceToSalesAverageNegatives = insights?.priceToSales?.average
    ?.negatives
    ? insights?.priceToSales?.average?.negatives
    : [];
  const priceToSalesIndustryNegatives = insights?.priceToSales?.industry
    ?.negatives
    ? insights?.priceToSales?.industry?.negatives
    : [];
  const priceToSalesMarketNegatives = insights?.priceToSales?.market?.negatives
    ? insights?.priceToSales?.market?.negatives
    : [];

  return (
    <>
      {showOnlyGraph ? (
        <BorderedChartContainer>
          <StackedLineChart data={chartData} valueType="multiple" />
          <div className="flex justify-center">
            <MultiLineGraphSelectorButton
              values={buttonValues}
              setValues={setButtonValues}
            />
          </div>
        </BorderedChartContainer>
      ) : (
        <>
          <Flex style={{ marginBottom: '25px' }}>
            <SectionHeader value="Market Cap Multiples" />
            {!isMobile && (
              <Box
                style={{
                  marginLeft: 'auto',
                  backgroundColor: darkMode ? '#161616' : '#f8f8f8',
                  borderRadius: '15px',
                  height: 'auto',
                }}
              >
                <ToggleButton period={period} setPeriod={setPeriod} />
              </Box>
            )}
          </Flex>

          {isMobile && (
            <Box
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                backgroundColor: darkMode ? '#161616' : '#f8f8f8',
                borderRadius: '15px',
                marginBottom: '5px',
                height: 'auto',
              }}
            >
              <ToggleButton period={period} setPeriod={setPeriod} />
            </Box>
          )}
          <div style={{ marginTop: '10px' }}>
            <StackedLineChart data={chartData} valueType="multiple" />
            <div className="flex justify-center">
              <MultiLineGraphSelectorButton
                values={buttonValues}
                setValues={setButtonValues}
              />
            </div>
          </div>

          {/* <BulletPoints
            title="Positives"
            points={[
              ...priceToEarningsAveragePositives,
              //...priceToEarningsIndustryPositives,
              //...priceToEarningsMarketPositives,
              ...priceToEarningsGrowthAveragePositives,
              //...priceToEarningsGrowthIndustryPositives,
              //...priceToEarningsGrowthMarketPositives,
              ...priceToBookAveragePositives,
              //...priceToBookIndustryPositives,
              //...priceToBookMarketPositives,
              ...priceToSalesAveragePositives,
              //...priceToSalesIndustryPositives,
              //...priceToSalesMarketPositives,
            ]}
            isPositive={true}
          />
          <BulletPoints
            title="Risks"
            points={[
              ...priceToEarningsAverageNegatives,
              //...priceToEarningsIndustryNegatives,
              //...priceToEarningsMarketNegatives,
              ...priceToEarningsGrowthAverageNegatives,
              //...priceToEarningsGrowthIndustryNegatives,
              //...priceToEarningsGrowthMarketNegatives,
              ...priceToBookAverageNegatives,
              //...priceToBookIndustryNegatives,
              //...priceToBookMarketNegatives,
              ...priceToSalesAverageNegatives,
              //...priceToSalesIndustryNegatives,
              //...priceToSalesMarketNegatives,
            ]}
            isPositive={false}
          /> */}
        </>
      )}
    </>
  );
};

export default MarketCapMultiples;
