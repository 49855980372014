import React, { FC, useEffect, useMemo, useState } from 'react';

import {
  getScreenerData,
  updateFilters,
} from '../../../../actions/screenerAction';
import Select from 'react-select';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@chakra-ui/icons';
import { useTable, useSortBy, usePagination } from 'react-table';
import { buildFilterPayload } from '../../../../utils/ScreenerUtils';
import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import { StyledTableHead, TableContainer, StyledTh, StyledTd } from './style';
import { DefaultTheme, useTheme } from 'styled-components';
import { Box, Text, Flex } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { useSticky } from 'react-table-sticky';
import { useMediaQuery } from '@chakra-ui/media-query';

export const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    borderColor: '#59bb88',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    height: '2.5rem',
    fontWeight: 500,
    '&:hover': {
      borderColor: '#59bb88',
      boxShadow: '0 0 0 1px #fff',
      outline: 'none',
      cursor: 'pointer',
    },
  }),
  singleValue: (styles: any) => ({ ...styles, color: '#59bb88' }),
  placeholder: (styles: any) => ({ ...styles, color: '#59bb88' }),
  dropdownIndicator: (styles: any) => ({ ...styles, color: '#59bb88' }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    backgroundColor: '#59bb88',
  }),
};
const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
];

const themeObject = {
  dark: {
    pageColor: '#858585',
    paginationColor: '#36a867',
    sortSelected: 'white',
  },
  light: {
    sortSelected: 'black',
  },
};

type Theme = DefaultTheme & { isDark: boolean };

const ScreenerTable = ({
  columns = [],
  updateTable,
}: {
  columns: Array<object>;
  updateTable: Function;
}) => {
  const dispatch = useAppDispatch();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const currentTheme: Theme = useTheme() as Theme;

  const theme: any = currentTheme.isDark ? themeObject.dark : themeObject.light;
  const [_pageSize, _setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const { appliedFilters, data, totalPages, totalCount } = useAppSelector(
    (state) => ({
      appliedFilters: state.screenerState.appliedFilters,
      data: state.screenerState.data,
      totalPages: state.screenerState.totalPages,
      totalCount: state.screenerState.totalCount,
    })
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageNumber,
        sortBy: [{ id: 'marketCap', desc: true }],
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      pageCount: data ? totalPages : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },

    useSortBy,
    usePagination,
    useSticky
  );
  useEffect(() => {
    dispatch(
      getScreenerData(
        pageSize * pageIndex,
        pageSize,
        buildFilterPayload(appliedFilters)
      )
    );
    updateTable({ pageSize, pageCount, pageIndex });
  }, [pageIndex, pageSize, appliedFilters]);

  useEffect(() => {
    if (appliedFilters?.sortFields !== sortBy) {
      dispatch(updateFilters({ ...appliedFilters, sortFields: sortBy }));
    }
  }, [appliedFilters, dispatch, sortBy]);
  return (
    // apply the table props
    <>
      <TableContainer>
        <table {...getTableProps()} className="w-full">
          <StyledTableHead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup: any) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column: any) => {
                      // Apply the header cell props
                      const columnObj = columns.find(
                        (item: any) => item.Header === column.Header
                      ) as any;
                      const align =
                        columnObj?.align === 'right' ? 'end' : 'start';

                      return (
                        <StyledTh {...column.getHeaderProps()}>
                          <div
                            className={`flex uppercase items-center ${`justify-${align}`}`}
                            {...column.getSortByToggleProps()}
                            onClick={(e) => {
                              column.isSortedDesc === undefined
                                ? column.toggleSortBy(false, true)
                                : column.isSortedDesc === true
                                ? column.clearSortBy()
                                : column.toggleSortBy(true, true);
                            }}
                          >
                            {
                              // Render the header
                              column.render('Header')
                            }
                            <span className="flex flex-col ml-2">
                              <ChevronUpIcon
                                w="4"
                                h="4"
                                fontSize="large"
                                color={
                                  column.isSorted && !column.isSortedDesc
                                    ? theme.sortSelected
                                    : 'gray.500'
                                }
                              />
                              <ChevronDownIcon
                                w="4"
                                h="4"
                                mt="-2"
                                fontSize="large"
                                color={
                                  column.isSorted && column.isSortedDesc
                                    ? theme.sortSelected
                                    : 'gray.500'
                                }
                              />
                            </span>
                          </div>
                        </StyledTh>
                      );
                    })
                  }
                </tr>
              ))
            }
          </StyledTableHead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row: any) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell: any) => {
                        // Apply the cell props
                        const columnObj = columns.find(
                          (item: any) => item.Header === cell.column.Header
                        ) as any;
                        const align =
                          columnObj?.align === 'right' ? 'end' : 'start';

                        return (
                          <StyledTd
                            {...cell.getCellProps()}
                            className={`${`justify-${align}`} !z-10`}
                            style={{
                              ...cell.getCellProps().style,
                              zIndex: '4',
                            }}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </StyledTd>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
            {page.length === 0 ? (
              <tr>
                <StyledTd className="text-center" colSpan={columns.length}>
                  No data
                </StyledTd>
              </tr>
            ) : null}
          </tbody>
        </table>
      </TableContainer>

      {/* Pagination */}
      {page.length ? (
        <div className="my-4">
          <Flex alignItems="center">
            <div className="flex md:justify-center flex-1 items-center">
              <Text color={theme.pageColor} fontSize="medium">
                Page {pageIndex + 1} of {pageCount}
              </Text>
              <Button
                className="md:ml-4"
                background="transparent"
                size="md"
                _focus={{ bg: 'transparent' }}
                _hover={{ bg: 'transparent' }}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                cursor="pointer"
                h="6"
                w="6"
              >
                <ChevronLeftIcon
                  color={theme.paginationColor}
                  height="6"
                  w="6"
                  // disabled={!canPreviousPage}
                />
              </Button>
              <Button
                className=""
                cursor="pointer"
                size="md"
                background="transparent"
                _focus={{ bg: 'transparent' }}
                _hover={{ bg: 'transparent' }}
                onClick={() => nextPage()}
                disabled={!canNextPage}
                h="6"
                w="6"
              >
                <ChevronRightIcon
                  color={theme.paginationColor}
                  // disabled={!canNextPage}
                  height="6"
                  w="6"
                />
              </Button>
            </div>
            <div className="ml-auto flex items-center">
              <Text color={theme.pageColor}>Rows per page</Text>
              <Select
                className="text-sm ml-4"
                options={options}
                styles={{
                  ...selectStyles,
                  control: (styles: any) => ({
                    ...styles,
                    height: 'initial',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    border: '0.5px solid #ececed',
                    minHeight: '0',
                  }),
                  valueContainer: (styles: any) => ({
                    ...styles,
                    minWidth: '2em',
                  }),
                  singleValue: (styles: any) => ({
                    ...styles,
                    color: theme.sortSelected,
                  }),
                  dropdownIndicator: (styles: any) => ({
                    ...styles,
                    color: theme.sortSelected,
                  }),
                  indicatorSeparator: (styles: any) => ({
                    ...styles,
                    display: 'none',
                  }),
                }}
                defaultValue={options[0]}
                onChange={({ value }: { value: any }) => {
                  setPageSize(value);
                }}
                menuPlacement="top"
              />
            </div>
          </Flex>
        </div>
      ) : null}
    </>
  );
};

export default ScreenerTable;
