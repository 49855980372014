import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  buildTooltipByValue,
  getFormattedCurrencyValueForTooltip,
  getTooltipDate,
  getFormattedCurrencyValueForAxis,
} from '../../../utils/ChartUtils';

Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B'],
  },
});

Highcharts.wrap(
  Highcharts.SVGRenderer.prototype,
  'symbol',
  function (proceed, symbol, x, y, w, h) {
    if (symbol.indexOf('text:') === 0) {
      var text = symbol.split(':')[1],
        svgElem = this.text(text, x, y + w + h);

      return svgElem;
    }

    return proceed.apply(this, Array.prototype.slice.call(arguments, 1));
  }
);

const StackedAreaChart = ({ data, darkMode, presentDate, chartVariant }) => {
  const lowData = data.map((item) => [item[0], item[1]]);
  const highData = data.map((item) => [item[0], item[2]]);
  const averageData = data.map((item) => [item[0], item[3]]);
  const actualData = data.map((item) => [item[0], item[4]]);

  function generateTooltip(_this, mobileMode) {
    const points = _this.points;
    let tooltips = '';
    const padding = mobileMode ? 3 : 5;
    tooltips = tooltips.concat(
      '<div style="position:relative;height:100%;padding:' + padding + 'px;"> '
    );
    const low = points[0];
    const high = points[1];
    const average = points[2];
    const actual = points[3];

    if (actual && actual.y) {
      tooltips = tooltips.concat(
        buildTooltipByValue(
          getFormattedCurrencyValueForTooltip(actual.y),
          actual.color,
          `Actual ${chartVariant}`,
          darkMode,
          mobileMode
        )
      );
    }
    tooltips = tooltips.concat(
      buildTooltipByValue(
        getFormattedCurrencyValueForTooltip(low.y) +
          ' - ' +
          getFormattedCurrencyValueForTooltip(high.y),
        low.color,
        `Analysts ${chartVariant} Range`,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(
      buildTooltipByValue(
        getFormattedCurrencyValueForTooltip(average.y),
        average.color,
        `Analysts Average ${chartVariant}`,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(getTooltipDate(_this.x, darkMode, mobileMode));
    return tooltips;
  }

  const options = {
    chart: {
      type: 'line',
      style: {
        fontFamily: 'Roboto',
      },
      backgroundColor: darkMode ? 'transparent' : '#ffffff',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      //tickInterval: 24 * 3600 * 1000 * 365,
      dateTimeLabelFormats: {
        day: '%e%b%y',
        month: '%b %Y',
      },
      crosshair: {
        enabled: true,
        width: 62,
        color: darkMode
          ? {
              linearGradient: [0, 0, 100, 300],
              stops: [
                [0, 'rgba(52, 94, 103, 0.4)'],
                [0.5, 'rgba(52, 94, 103, 0.4)'],
                [1, 'rgba(87, 103, 128, 0.4)'],
              ],
            }
          : {
              linearGradient: [0, 0, 100, 300],
              stops: [
                [0, 'rgba(225, 241, 243, 0.5)'],
                [1, 'rgba(225, 241, 243, 0.5)'],
              ],
            },
      },
      tickLength: 0,
      plotBands: [
        {
          from: Date.UTC(2000, 1, 1),
          to: presentDate,
          color: 'transparent',
          label: {
            text: `Actual ${chartVariant} & Past Estimates`,
            style: {
              color: darkMode ? '#d8d9e4' : '#686868',
              fontSize: '13px',
              fontWeight: 500,
            },
            align: 'right',
            x: -10,
            y: -10,
            verticalAlign: 'bottom',
          },
        },
        {
          from: presentDate,
          to: Date.UTC(3000, 12, 31),
          //borderColor: '#026875',
          //borderWidth: 1,
          color: {
            linearGradient: { x1: 0, x2: 1, y1: 1, y2: 1 },
            stops: darkMode
              ? [
                  [0, 'rgba(68,142,247,0.2)'],
                  [0.5, 'rgba(68,142,247,0.08)'],
                  [1, 'rgba(68,142,247,0.06)'],
                ]
              : [
                  [0, 'rgba(68,142,247,0.2)'],
                  [0.5, 'rgba(68,142,247,0.08)'],
                  [1, 'rgba(68,142,247,0.06)'],
                ],
          },
          label: {
            text: 'Analysts Forecasts',
            style: {
              color: darkMode ? '#d8d9e4' : '#686868',
              fontSize: '13px',
              fontWeight: 500,
            },
            align: 'left',
            x: 10,
            y: -10,
            verticalAlign: 'bottom',
          },
        },
      ],
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
        formatter: function () {
          return getFormattedCurrencyValueForAxis(this.value);
        },
      },
      stackLabels: {
        enabled: true,
        align: 'center',
      },
      gridLineColor: darkMode ? '#3b404f' : '#e6e6e6',
    },
    /* plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: false,
        },
      },
    }, */
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          style: {
            color: '#9E9E9E',
            fontWeight: 'bolder',
          },
          formatter: function () {
            return '$' + this.y;
          },
        },
        enableMouseTracking: false,
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shadow: false,
      useHTML: true,
      backgroundColor: darkMode ? '#141920' : '#fff',
      borderColor: darkMode ? '#86efaf' : '#5bae7f',
      borderRadius: 8,
      /* shadow: {
        color: 'rgba(56, 74, 64, 0.15)',
        offsetX: 0,
        offsetY: 4,
        width: 7,
        //width: 0,      
      }, */
      formatter: function () {
        return generateTooltip(this, false);
      },
      shared: true,
    },
    legend: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            tooltip: {
              formatter: function () {
                return generateTooltip(this, true);
              },
            },
            xAxis: {
              crosshair: {
                width: 45,
              },
            },
            chart: {
              height: 400,
            },
          },
        },
      ],
    },
    series: [
      {
        data: lowData,
        type: 'spline',
        marker: {
          enabled: false,
          symbol: 'circle',
          lineColor: 'transparent',
        },
        color: darkMode ? '#7BBFFF' : '#448EF7',
        dashStyle: 'ShortDash',
      },
      {
        data: highData,
        type: 'spline',
        marker: {
          enabled: false,
          symbol: 'circle',
          lineColor: 'transparent',
        },
        color: darkMode ? '#7BBFFF' : '#448EF7',
        dashStyle: 'ShortDash',
      },
      {
        data: averageData,
        type: 'spline',
        marker: {
          enabled: false,
          symbol: 'circle',
          lineColor: 'transparent',
        },
        color: darkMode ? '#7BBFFF' : '#448EF7',
      },
      {
        data: actualData,
        type: 'spline',
        marker: {
          enabled: false,
          //radius: 6,
          symbol: 'circle',
          lineColor: 'transparent',
          //symbol: 'text:\u25C9',
        },
        color: darkMode ? '#d49cff' : '#ab81e4',
        //threshold: Date.UTC(2020, 11, 31),
        //negativeColor: '#1fcbe0',
      },
    ],
  };

  return (
    <div className="text-xs font-semibold w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(StackedAreaChart);
