import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import { HollowGreenButton, Divider } from '../StyedComponents';

// export const HeaderWrapper = styled.header`
//   ${tw`text-white py-4 px-2  fixed inset-x-0 top-0 h-16 md:h-20`}
//   background: ${(props) => props.theme.content.background};
//   border: solid 1px ${(props) => props.theme.content.border};
// `;

export const Title = styled.div`
  color: #ed8f03;
`;

export const StyledLink = styled(Link)`
  ${tw`no-underline text-base font-medium cursor-pointer px-2`};
  padding-bottom: 5px;
  color: ${(props) => props.theme.content.color};
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${props.brandColor}` : 'none'};
  &:hover {
    color: ${(props) => props.theme.keyHighlight};
  }
`;

export const StyledExternalLink = styled.a.attrs(() => ({ target: '_blank' }))`
  ${tw`no-underline text-base font-medium cursor-pointer px-2`};
  padding-bottom: 5px;
  color: ${(props) => props.theme.content.color};
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${props.brandColor}` : 'none'};
  &:hover {
    color: ${(props) => props.theme.keyHighlight};
  }
`;

export const StyledLinkDiv = styled.div`
  ${tw`no-underline text-sm font-medium cursor-pointer px-2`};
  color: ${(props) => props.theme.content.color};
  &:hover {
    color: ${(props) => props.theme.keyHighlight};
  }
`;

export const VerticalLine = styled.div`
  height: 45px;
  border: solid 1px ${(props) => props.theme.grayBorder};
  background-color: ${(props) => props.theme.grayBorder};
  position: relative;
`;

export const SignUpButton = styled(HollowGreenButton)`
  ${tw`py-3 px-8`}
`;

export const ProfileMenu = styled.div`
  ${tw`absolute top-16 right-16 w-40 p-2 bg-white shadow-md rounded`};
  color: ${(props) => props.theme.content.color};
  background-color: ${(props) => props.theme.content.background};
  border: solid 1px ${(props) => props.theme.content.border};
`;

export const MenuContainer = styled.div`
  ${tw`h-full py-4 px-6`};
`;
