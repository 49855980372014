import React from 'react';
import LoadingIndicator from '../shared/LoadingIndicator';
import NoDataFound from '../shared/NoDataFound';

const SectionDisplay = ({
  children,
  loading,
  condition,
  displayText,
  darkMode,
}) =>
  condition ? (
    children
  ) : loading ? (
    <LoadingIndicator />
  ) : (
    <NoDataFound darkMode={darkMode} value={displayText} />
  );

export default SectionDisplay;
