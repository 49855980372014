import React, { useEffect, useState } from 'react';

import { Text } from '@chakra-ui/react';
import styled from 'styled-components';
import tw from 'twin.macro';
import DefaultDonut from '../Chart/DefaultDonut';
import NestedDonut from '../Chart/NestedDonut';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { SectionDivider } from '../../../StyedComponents';
import SectionHeader from '../../../SectionHeader';
import { useMediaQuery } from '@chakra-ui/react';

export default function ChartCard({
  data,
  innerData,
  darkMode,
  title,
  isNested,
  symbolHidden,
  keyTitle,
  nestedKeyTitle,
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [selected, setSelected] = useState({});
  const [clicked, setClicked] = useState({});
  const [symbol, setSymbol] = useState('');

  useEffect(() => {
    // setSelected({});
    // setClicked({});
    // setSymbol('');
  }, [data]);

  function callback(value, focused, mouseOut) {
    value && setSymbol(value.symbol);

    if (!focused) {
      value ? setSelected(value) : setSelected(undefined);
    } else {
      if (value === clicked) {
        setClicked({});
      } else {
        value && setClicked(value);
      }
    }

    if (mouseOut) {
      setSelected(undefined);
    }

    var element =
      selected !== {}
        ? document.getElementById(symbol)
        : document.getElementById(clicked.symbol);
    if (element !== null) {
      var headerOffset = isMobile ? 50 : 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      element.scrollIntoView({
        top: offsetPosition,
        block: 'nearest',
        inline: 'start',
      });
    }
  }

  const multiColors = [
    darkMode ? '#D49CFF' : '#ab81e4', //purple
    darkMode ? '#553e66' : '#785aa0',
    darkMode ? '#eed7ff' : '#ddcdf4',
    darkMode ? '#7bbfff' : '#785aa0', //blue
    darkMode ? '#314c66' : '#2247cc',
    darkMode ? '#cae5ff' : '#b4d2fc',
    darkMode ? '#ffc176' : '#eeb041', //yellow
    darkMode ? '#664d2f' : '#5f461a',
    darkMode ? '#ffe0bb' : '#f8dfb3',
    darkMode ? '#ff9494' : '#ee8079', //pink :)
    darkMode ? '#a75a55' : '#804a4a',
    darkMode ? '#ffcaca' : '#f8ccc9',
    // '#eeb041',
    // '#cbb6f8',
    // '#f4f0fd',
    // '#e5dafb',
  ];

  const innerColors = [
    darkMode ? '#434243' : '#666666', //dark
    darkMode ? '#161616' : '#272626',
    darkMode ? '#8C8C8D' : '#BABABA', // mid
    darkMode ? '#F5F4F4' : '#E8E8E8', // light
  ];

  let total = 0;
  let outerTotal = 0;

  const [randomizedColors, setRandomizedColors] = useState([
    10,
    2,
    8,
    0,
    4,
    11,
    5,
    9,
    3,
    6,
    1,
    7,
  ]);

  useEffect(() => {
    let newArr = [];

    for (let i = 0; i < 5; i++) {
      while (newArr.length < 10) {
        var r = Math.floor(Math.random() * 12);
        if (newArr.indexOf(r) === -1) newArr.push(r);
      }
    }
    setRandomizedColors(newArr);
  }, [darkMode, data]);

  //console.log(data)

  function defaultMapping(data, key, colors) {
    let obj = {};
    let resultArr = [];
    let count = -1;
    outerTotal = 0;
    total = 0;

    data &&
      data.map((elem) => {
        if (elem[key] !== undefined) {
          if (obj[elem[key]] === undefined) {
            obj = { ...obj, [elem[key]]: 1 };
          } else {
            obj[elem[key]] += 1;
          }
        }
      });

    for (let keys in obj) {
      count += 1;
      if (count >= multiColors.length || count === 9) {
        count = 0;
      }
      total += obj[keys];
      outerTotal += obj[keys];
      resultArr.push({
        value: obj[keys],
        symbol: keys,
        color:
          colors === innerColors
            ? colors[count]
            : colors[randomizedColors[count]],
      });
    }

    console.log(key + ' ' + total);

    // console.log(resultArr);

    return resultArr.sort((a, b) => b.value - a.value);
  }

  function defaultPercentMapping(data, colors) {
    let count = -1;

    let resultArr =
      data &&
      data
        .sort((a, b) => b.percent - a.percent)
        .map((elem) => {
          if (count === 9) {
            count = -1;
          }
          if (count === 4 && colors === innerColors) {
            count = -1;
          }
          count += 1;
          return {
            value: elem['percent'],
            symbol: elem['name'],
            color:
              colors === innerColors
                ? colors[count]
                : colors[randomizedColors[count]],
          };
        });

    return resultArr && resultArr.filter((elem) => elem.value !== 0);
  }

  function nestedPercentMapping(data, innerData, innerKey) {
    let dupData = data;
    let insideData = [];

    if (innerKey === 'marketcap_mln') {      
      dupData = innerData.map((elem) => {
        if (elem[innerKey] >= 100000) {
          //100B
          elem[innerKey] = 'Mega Cap';
        } else if (elem[innerKey] >= 10000) {
          //10B
          elem[innerKey] = 'Big Cap';
        } else if (elem[innerKey] >= 1000) {
          //1B
          elem[innerKey] = 'Medium Cap';
        } else if (elem[innerKey] < 1000) {
          elem[innerKey] = 'Small Cap';
        }

        return elem;
      });

      insideData = defaultMapping(dupData, innerKey, innerColors).sort(
        (a, b) => b.value - a.value
      );
    } else {
      insideData = defaultPercentMapping(innerData, innerColors);
    }

    let outerData = defaultPercentMapping(data, multiColors);

    return [insideData ? insideData : [], outerData ? outerData : []];
  }

  return (
    <>
      {!isMobile && <SectionHeader value={title} />}

      {!isNested ? (
        <div
          style={{
            display: 'flex',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            padding: !isMobile ? '15px' : '0',
          }}
        >
          <DefaultDonut
            data={
              defaultPercentMapping(data, multiColors)
                ? defaultPercentMapping(data, multiColors)
                : []
            }
            darkMode={darkMode}
            hideSymbol={symbolHidden}
            callback={callback}
            title={title}
          />
          <div style={{ height: '300px', overflowY: 'scroll' }}>
            {defaultPercentMapping(data, multiColors) &&
              defaultPercentMapping(data, multiColors).map((elem) => (
                <div
                  id={elem.symbol}
                  style={{
                    display: 'flex',
                    marginBottom: '15px',
                    backgroundColor: selected
                      ? selected.symbol === elem.symbol
                        ? `${elem.color}40`
                        : ''
                      : clicked.symbol === elem.symbol
                      ? `${elem.color}40`
                      : '',
                    borderRadius: '15px',
                    margin: '8px',
                    padding: '8px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: elem.color,
                      width: '20px',
                      height: '15px',
                      borderRadius: '10px',
                    }}
                  />
                  <div style={{ width: '160px' }}>
                    <h4
                      style={{
                        marginLeft: '10px',
                        fontSize: '14px',
                        fontWeight: selected
                          ? selected.symbol === elem.symbol
                            ? 'bold'
                            : ''
                          : clicked.symbol === elem.symbol
                          ? 'bold'
                          : '',
                        color: darkMode ? '#cccccc' : 'black',
                        // textDecoration:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? 'underline' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? 'underline' : '',
                        // textDecorationStyle: 'solid',
                        // textUnderlineOffset:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? '4px' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? '4px' : '',
                      }}
                    >
                      {elem.symbol}
                    </h4>
                  </div>
                  <h4
                    style={{
                      marginLeft: '5px',
                      color: darkMode ? 'white' : 'black',
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    {parseFloat(elem.value).toFixed(2)}%
                  </h4>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: !isMobile ? '0px' : '0',
            width: 'auto',
          }}
        >
          <NestedDonut
            data={nestedPercentMapping(data, innerData, nestedKeyTitle[0])}
            darkMode={darkMode}
            hideSymbol={symbolHidden}
            callback={callback}
            title={title}
          />

          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              height: '300px',
              overflowY: 'scroll',
            }}
          >
            {nestedPercentMapping(data, innerData, nestedKeyTitle[0])[0] &&
              nestedPercentMapping(data, innerData, nestedKeyTitle[0])[0].map(
                (elem) => (
                  <div
                    id={elem.symbol}
                    style={{
                      display: 'flex',
                      marginBottom: '15px',
                      backgroundColor: selected
                        ? selected.symbol === elem.symbol
                          ? `${elem.color}40`
                          : ''
                        : clicked.symbol === elem.symbol
                        ? `${elem.color}40`
                        : '',
                      borderRadius: '15px',
                      margin: '5px',
                      padding: '5px',
                      paddingTop: '4px',
                      paddingBottom: '4px',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: elem.color,
                        width: '19.5px',
                        height: '16px',
                        borderRadius: '10px',
                      }}
                    />
                    <div style={{ width: '160px' }}>
                      <h4
                        style={{
                          marginLeft: '10px',
                          fontSize: '14px',
                          fontWeight: selected
                            ? selected.symbol === elem.symbol
                              ? 'bold'
                              : ''
                            : clicked.symbol === elem.symbol
                            ? 'bold'
                            : '',
                          color: darkMode ? '#cccccc' : 'black',
                          // textDecoration:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? 'underline' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? 'underline' : '',
                          // textDecorationStyle: 'solid',
                          // textUnderlineOffset:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? '4px' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? '4px' : '',
                        }}
                      >
                        {elem.symbol}
                      </h4>
                    </div>
                    <h4
                      style={{
                        marginLeft: '5px',
                        color: darkMode ? 'white' : 'black',
                        fontWeight: 'bold',
                        fontSize: '15px',
                      }}
                    >
                      {title === 'Tickers'
                        ? elem.value !== 0
                          ? `${parseFloat((elem.value / total) * 100).toFixed(
                              2
                            )}%`
                          : `${(elem.value / total) * 100}%`
                        : `${parseFloat(elem.value).toFixed(2)}%`}
                    </h4>
                  </div>
                )
              )}

            <div
              style={{
                width: '190px',
                height: '3px',
                margin: 'auto',
                marginTop: '15px',
                marginBottom: '15px',
                borderRadius: '15px',
                backgroundColor: darkMode
                  ? '#8c8c8c'
                  : 'rgba(140, 140, 140, 0.2)',
              }}
            />

            {nestedPercentMapping(data, innerData, nestedKeyTitle[0])[1] &&
              nestedPercentMapping(data, innerData, nestedKeyTitle[0])[1].map(
                (elem) => (
                  <div
                    id={elem.symbol}
                    style={{
                      display: 'flex',
                      marginBottom: '15px',
                      backgroundColor: selected
                        ? selected.symbol === elem.symbol
                          ? `${elem.color}40`
                          : ''
                        : clicked.symbol === elem.symbol
                        ? `${elem.color}40`
                        : '',
                      borderRadius: '15px',
                      margin: '5px',
                      padding: '5px',
                      paddingTop: '4px',
                      paddingBottom: '4px',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: elem.color,
                        width: '19.5px',
                        height: '16px',
                        borderRadius: '10px',
                      }}
                    />
                    <div style={{ width: '160px' }}>
                      <h4
                        style={{
                          marginLeft: '10px',
                          fontSize: '14px',
                          fontWeight: selected
                            ? selected.symbol === elem.symbol
                              ? 'bold'
                              : ''
                            : clicked.symbol === elem.symbol
                            ? 'bold'
                            : '',
                          color: darkMode ? '#cccccc' : 'black',
                          // textDecoration:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? 'underline' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? 'underline' : '',
                          // textDecorationStyle: 'solid',
                          // textUnderlineOffset:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? '4px' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? '4px' : '',
                        }}
                      >
                        {elem.symbol}
                      </h4>
                    </div>
                    <h4
                      style={{
                        marginLeft: '5px',
                        color: darkMode ? 'white' : 'black',
                        fontWeight: 'bold',
                        fontSize: '15px',
                      }}
                    >
                      {parseFloat(elem.value).toFixed(2)}%
                    </h4>
                  </div>
                )
              )}
          </div>
        </div>
      )}
    </>
  );
}
