const initialState = {
  symbol: null,
  data: {
    insiders: [],
    institutionsHolders: [],
    fundsHolders: [],
  },
  loading: false,
  insidersError: null,
  holdersError: null,
};

const InsidersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_INSIDERS_DATA_LOADING': {
      return {
        ...state,
        loading: true,
        symbol: null,
        data: {
          ...state.data,
          insiders: [],
        },
        insidersError: null,
        holdersError: null,
      };
    }

    case 'FETCH_INSIDERS_DATA_SUCCESS': {
      return {
        ...state,
        loading: false,
        symbol: action.symbol,
        data: {
          ...state.data,
          insiders: action.data,
        },
      };
    }

    case 'FETCH_INSIDERS_DATA_ERROR': {
      return {
        ...state,
        loading: false,
        insidersError: action.error,
      };
    }

    case 'FETCH_HOLDERS_DATA_LOADING': {
      return {
        ...state,
        loading: true,
        symbol: null,
        data: {
          ...state.data,
          institutionsHolders: [],
          fundsHolders: [],
        },
        insidersError: null,
        holdersError: null,
      };
    }

    case 'FETCH_INSTITUTIONS_HOLDERS_DATA_SUCCESS': {
      return {
        ...state,
        loading: false,
        symbol: action.symbol,
        data: {
          ...state.data,
          institutionsHolders: action.data,
        },
      };
    }

    case 'FETCH_FUNDS_HOLDERS_DATA_SUCCESS': {
      return {
        ...state,
        loading: false,
        symbol: action.symbol,
        data: {
          ...state.data,
          fundsHolders: action.data,
        },
      };
    }

    case 'FETCH_HOLDERS_DATA_ERROR': {
      return {
        ...state,
        loading: false,
        holdersError: action.error,
      };
    }

    default:
      return state;
  }
};

export default InsidersReducer;
