import React from 'react';
import RatioGraph from '../../../RatioGraph';
import RatioGraphInsights from '../../../RatioGraphInsights';
import SectionHeader from '../../../SectionHeader';

const PriceToEarningsGrowth = ({ data, symbol, insights }) => {
  const ratios = [
    { name: 'Symbol', value: data.symbol },
    { name: 'Sector', value: data.sector },
    { name: 'Market', value: data.market },
  ];

  return (
    <>
      <SectionHeader value="Price to Earning Growth (PEG)" infoKey="peg" />
      <RatioGraph ratios={ratios} symbol={symbol} suffix="x" />
      <RatioGraphInsights title="PEG" insights={insights} />
    </>
  );
};

export default PriceToEarningsGrowth;
