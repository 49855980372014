import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import { AuthProvider } from './components/hocs/AuthProvider';
import { awsConfig } from './config/default';
import { Helmet } from 'react-helmet';
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import theme from './config/theme';
import { store } from './store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { getGtagURL, isDev } from './utils/analytics';
import clevertap from 'clevertap-web-sdk';
//
Amplify.configure(awsConfig);

clevertap.init(
  window.location.host === 'verde.finance' ? '844-8R5-976Z' : '8W4-K95-876Z'
);

Sentry.init({
  dsn:
    'https://e571897a9a2f4717b86e19f7cf401ec1@o1197952.ingest.sentry.io/6320614',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const gTagId = getGtagURL();

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <meta charSet="utf-8" />
      <script
        async
        type="text/javascript"
      >{`window.gtag = function() {}`}</script>
      {isDev() ? (
        <script
          async
          type="text/javascript"
        >{`window.gtag = function() {}`}</script>
      ) : (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gTagId}`}
        ></script>
      )}

      <script async src="https://widget.frill.co/v2/widget.js"></script>

      <script type="text/javascript">
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        =l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "ga20sf0i6y");`}
      </script>
      {isDev() ? null : (
        <script async type="text/javascript">
          {`<!-- Google Tag Manager -->
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K7P56Z8');
          <!-- End Google Tag Manager -->`}
        </script>
      )}

      {isDev() ? null : (
        <script async type="text/javascript">
          {`window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
      
          gtag('config', '${gTagId}', { 'transport_type': 'beacon' });`}
        </script>
      )}
    </Helmet>

    <AuthProvider>
      <ChakraProvider theme={extendTheme(theme)}>
        <App />
      </ChakraProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
