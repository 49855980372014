import React from 'react';

const Trend = ({ trend, color, size = 10, style, className = '' }) => {
  return (
    <span
      className={!trend ? `transform rotate-180 ${className}` : className}
      style={style}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox={`0 0 10 10`}
      >
        <path
          fill={color}
          d="M9.147 0H2.844c-.471 0-.853.382-.853.853s.382.853.853.853h4.244L.25 8.544c-.333.333-.333.873 0 1.206.333.333.873.333 1.206 0l6.838-6.838v4.244c0 .471.382.853.853.853S10 7.627 10 7.156V.853C10 .389 9.623 0 9.147 0z"
        />
      </svg>
    </span>
  );
};

export default Trend;
