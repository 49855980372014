import { InfoOutlineIcon, TriangleDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from '@chakra-ui/react';
import React, { FC, useRef } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import PageContainer from '../../shared/PageContainer';
import SectorIcon from '../../shared/SectorIcon';
import SymbolLogo from '../../shared/SymbolLogo';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import { useParams } from 'react-router';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import {
  getSimilarCompaniesData,
  getStockInfo,
  getValuationData,
  getPastPerformanceData,
} from '../../../actions/action';
import { fetchSymbolInsights } from '../../../actions/insightsAction';
import { fetchSymbolNews } from '../../../actions/newsAction';
import { useAppSelector } from '../../../hooks/store';
import ReadMore from '../../shared/ReadMore';
import VerdeScoreMeter from '../../shared/VerdeScoreMeter';
import { components, decorate } from '../../shared/BulletPoints';
import JsxParser from 'react-jsx-parser';
const tabs = [
  {
    name: 'Overview',
    selected: true,
    tabs: [
      {
        name: 'Stock Introduction',
        selected: true,
      },
      {
        name: 'Summary Preview',
      },
      {
        name: 'Similar Companies',
      },
    ],
  },
  { name: 'Valuation' },
  { name: 'Past Performance' },
  { name: 'Future Growth' },
  { name: 'Dividend' },
  { name: 'Ownership' },
  { name: 'Other Information' },
  { name: 'Discussion' },
];
type Theme = DefaultTheme & { isDark: boolean };
const themeObject = {
  dark: {
    bg: '#f4f4f4',
    navItemSelectedBg: 'white',
    navItemSelectedBorder: '#01874e',
    navItemColor: '#8c8c8c',
    navItemSelectedColor: '#01874e',
  },
  light: {
    bg: '#f4f4f4',
    navItemSelectedBg: 'white',
    navItemSelectedBorder: '#01874e',
    navItemColor: '#8c8c8c',
    navItemSelectedColor: '#01874e',
  },
};

const PageContainerW: any = PageContainer;

const Main: FC<{
  getValuationData: (arg: string) => void;
  getStockInfo: (arg: string) => void;
  getSimilarCompaniesData: (arg: string) => void;
  fetchSymbolInsights: (arg: string) => void;
  fetchSymbolNews: (arg: string) => void;
  getPastPerformanceData: (arg: string) => void;
}> = ({
  getValuationData,
  getStockInfo,
  getSimilarCompaniesData,
  fetchSymbolInsights,
  fetchSymbolNews,
  getPastPerformanceData,
}) => {
  const currentTheme: Theme = useTheme() as Theme;
  const theme = currentTheme.isDark ? themeObject.dark : themeObject.light;
  const params: { sid: string } = useParams();
  const { data: valuationState } = useAppSelector(
    (state) => state.valuationState
  );
  const insights = useAppSelector(
    (state) => state.symbolInsightsState.data?.valuation
  );

  const { symbol, loading } = valuationState;

  const descRef = useRef<HTMLDivElement>(null);
  const [descWidth, setDescWidth] = React.useState(0);

  React.useEffect(() => {
    descRef.current &&
      !loading &&
      setDescWidth(Math.round((descRef.current as HTMLElement).offsetWidth));
  }, [loading]);

  React.useEffect(() => {
    getValuationData(params.sid);
    getPastPerformanceData(params.sid);
    getStockInfo(params.sid);
    fetchSymbolInsights(params.sid);
    getSimilarCompaniesData(params.sid);
    fetchSymbolNews(params.sid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sid]);

  return (
    <Flex bg={theme.bg} minH="90vh" mt="80px">
      <Container maxW="container.xl">
        <Grid templateColumns="repeat(4, 1fr)" gap={4} mt="10">
          <GridItem rowSpan={1} colSpan={1}>
            <Box as="ul" position="sticky" top="100px">
              {tabs.map((tab) => (
                <Box as="li">
                  <Text
                    p="2"
                    borderLeft={tab.selected ? '4px' : 'none'}
                    borderColor={theme.navItemSelectedBorder}
                    minH="30px"
                    bg={tab.selected ? theme.navItemSelectedBg : 'transparent'}
                    boxSizing="border-box"
                    fontWeight="bold"
                  >
                    {tab.name}
                  </Text>
                  {tab.tabs?.length && tab.selected ? (
                    <Box as="ul" ml="10">
                      {tab.tabs.map((childTab) => (
                        <Box as="li" boxSizing="border-box" minH="30px" p="2">
                          <Text
                            color={
                              childTab.selected
                                ? theme.navItemSelectedColor
                                : theme.navItemColor
                            }
                            fontWeight={childTab.selected ? 'bold' : 'normal'}
                            fontSize="small"
                          >
                            {childTab.name}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                  ) : null}
                </Box>
              ))}
            </Box>
          </GridItem>
          <GridItem colSpan={3} bg="">
            <Flex flexDirection="column" gap="4">
              <Flex bg={'white'} p="5" flexDirection="column">
                <Flex>
                  <Flex>
                    <Box>
                      <SymbolLogo
                        logoUrl={'https://images.verde.finance/MSFT.png'}
                      />
                    </Box>
                    <Flex flexDirection="column" ml="2">
                      <Text fontWeight="bold">MSFT</Text>
                      <Text fontSize="small">Microsoft Corporation</Text>
                    </Flex>
                  </Flex>
                  <Box></Box>
                </Flex>
                <Flex mt="2" gap="4">
                  <Flex
                    bg="#f4f4f4"
                    px="3"
                    py="1"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="2"
                    borderRadius="5px"
                  >
                    <img
                      alt="United States"
                      className="w-5 h-4"
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg`}
                    />
                    <Text fontSize="small" color="#595959">
                      USA
                    </Text>
                  </Flex>
                  <Flex
                    bg="#f4f4f4"
                    px="3"
                    py="1"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="2"
                    borderRadius="5px"
                  >
                    <SectorIcon value="Technology" fill="black" />
                    <Text fontSize="small" color="#595959">
                      Technology
                    </Text>
                  </Flex>
                  <Flex
                    bg="#f4f4f4"
                    px="3"
                    py="1"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="2"
                    borderRadius="5px"
                  >
                    <Text fontSize="small" color="#595959">
                      Large Cap
                    </Text>
                  </Flex>
                </Flex>
                <Flex my="4" gap="3">
                  <Flex flexDirection="column">
                    <Text fontWeight="bold" fontSize="2xl">
                      $285.26
                    </Text>
                    <Flex alignItems="center">
                      <TriangleDownIcon color="#be2e2c"></TriangleDownIcon>
                      <Text color="#be2e2c">0.14% (-$5.30)</Text>
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column">
                    <Flex fontWeight="bold" fontSize="2xl" alignItems="center">
                      <img
                        src={VerdeScoreSvg}
                        alt=""
                        className="w-4 h-4 mb-0.5 mr-1"
                      />
                      <Text>43</Text>
                    </Flex>
                    <Flex alignItems="center" gap="1">
                      <Text fontWeight="bold">Verde Score</Text>
                      <Box>
                        <InfoOutlineIcon
                          color="#0072db"
                          height="15px"
                          width="15px"
                        ></InfoOutlineIcon>
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
                {/* <Flex mt="2" ref={descRef} color="#8c8c8c">
                  {loading ? null : (
                    <ReadMore text={symbol?.description} width={descWidth} darkMode = {darkMode} />
                  )}
                </Flex> */}
              </Flex>
              <Flex
                bg="white"
                p="5"
                justifyContent="space-between"
                boxSizing="border-box"
              >
                <Flex borderColor="#dedcdc" border="1px" borderRadius="5px">
                  <SimpleGrid columns={3} fontSize="small" spacing="10">
                    <Flex flexDir="column">
                      <Text>Market Capital</Text>
                      <Text>$2.32T</Text>
                    </Flex>
                    <Flex flexDir="column">
                      <Text>PE (FWD)</Text>
                      <Text>29.07</Text>
                    </Flex>
                    <Flex flexDir="column">
                      <Text>PE (TTM)</Text>
                      <Text>32.09</Text>
                    </Flex>
                  </SimpleGrid>
                </Flex>
                <Flex p="2">
                  <Flex
                    p="2"
                    borderColor="#dedcdc"
                    border="1px"
                    borderRadius="5px"
                  >
                    <SimpleGrid columns={2} fontSize="small" spacing="10">
                      <Flex flexDir="column">
                        <Text>Profit Margin</Text>
                        <Text>39%</Text>
                      </Flex>
                      <Flex flexDir="column">
                        <Text>Operating Margin (TTM)</Text>
                        <Text>43%</Text>
                      </Flex>
                    </SimpleGrid>
                  </Flex>
                </Flex>
              </Flex>
              <Flex bg="white" p="5" flexDir="column">
                <Flex alignItems="center" gap="1">
                  <Text fontWeight="bold" fontSize="lg">
                    Valuation Summary
                  </Text>
                  <InfoOutlineIcon
                    color="#0072db"
                    height="15px"
                    width="15px"
                  ></InfoOutlineIcon>
                </Flex>
                <Flex gap="20">
                  <Flex>
                    <VerdeScoreMeter score={45} />
                  </Flex>
                  <Flex flexDirection="column" gap="2">
                    <Flex
                      bg="rgba(86, 156, 48, 0.05)"
                      flexDir="column"
                      p="3"
                      borderRadius="5px"
                      minW="100%"
                    >
                      <Flex gap="1" alignItems="center">
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          color="#569c30"
                        ></FontAwesomeIcon>
                        <Text fontWeight="bold">Positives</Text>
                      </Flex>
                      <Box as="ul" fontSize="sm">
                        {insights?.summary?.positives.map(
                          (positive: string) => (
                            <Box as="li" p="2">
                              <JsxParser
                                components={components}
                                jsx={decorate(positive)}
                              />
                            </Box>
                          )
                        )}
                      </Box>
                    </Flex>
                    <Flex
                      bg="rgba(190, 46, 44, 0.05)"
                      flexDir="column"
                      p="3"
                      borderRadius="5px"
                    >
                      <Flex gap="1" alignItems="center">
                        <FontAwesomeIcon
                          icon={faThumbsDown}
                          color="#be2e2c"
                        ></FontAwesomeIcon>
                        <Text fontWeight="bold">Risks</Text>
                      </Flex>
                      <Box as="ul" fontSize="sm">
                        {insights?.summary?.negatives.map(
                          (positive: string) => (
                            <Box as="li" p="2">
                              <JsxParser
                                components={components}
                                jsx={decorate(positive)}
                              />
                            </Box>
                          )
                        )}
                      </Box>
                    </Flex>
                    <Flex gap="2" fontSize="sm">
                      <Flex
                        flexDir="column"
                        backgroundColor="#f9f9f9"
                        p="3"
                        borderRadius="5px"
                        alignItems="center"
                        gap="2"
                      >
                        <Box>
                          <Text
                            color="white"
                            px="1"
                            backgroundColor="#569c30"
                            display="inline-block"
                            borderRadius="5px"
                            fontWeight="bold"
                          >
                            A
                          </Text>
                        </Box>
                        <Text>Growth Rating</Text>
                      </Flex>
                      <Flex>
                        <Flex
                          flexDir="column"
                          backgroundColor="#f9f9f9"
                          borderRadius="5px"
                          p="3"
                          alignItems="center"
                          gap="2"
                        >
                          <Box>
                            <Text
                              color="white"
                              display="inline-block"
                              backgroundColor="#a6a739"
                              borderRadius="5px"
                              fontWeight="bold"
                              px="1"
                            >
                              B
                            </Text>
                          </Box>
                          <Text>Safety Rating</Text>
                        </Flex>
                      </Flex>
                      <Flex>
                        <Flex
                          flexDir="column"
                          backgroundColor="#f9f9f9"
                          borderRadius="5px"
                          p="3"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="2"
                        >
                          <Box>
                            <Text
                              color="white"
                              display="inline-block"
                              backgroundColor="#be2e2c"
                              borderRadius="5px"
                              px="1"
                              fontWeight="bold"
                            >
                              F
                            </Text>
                          </Box>
                          <Text>Value Rating</Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </GridItem>
        </Grid>
      </Container>
    </Flex>
  );
};

const mapStateToProps = (state: any) => ({
  valuationError: state.valuationState.error,
});

const mapDispatchToProps = {
  getValuationData,
  getStockInfo,
  fetchSymbolInsights,
  getSimilarCompaniesData,
  fetchSymbolNews,
  getPastPerformanceData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
