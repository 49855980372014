import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const TabContainer = styled.div`
  ${tw`rounded p-1.5 flex justify-evenly`};
  background-color: ${(props) => props.theme.pageTab.background};
  border: 1px solid ${(props) => props.theme.pageTab.border};
`;

export const Tab = styled.div`
  ${tw`rounded max-w-max font-medium text-sm px-3 py-2 ml-1`};
  background-color: ${(props) =>
    props.selected ? props.theme.order.profit : 'transparent'};
  color: ${(props) => (props.selected ? '#fff' : props.theme.pageTab.name)};
  cursor: ${(props) => (props.selected ? 'pointer' : 'not-allowed')};
`;

export const ColumnSelector = styled.div`
  ${tw`p-2 outline-none rounded-md cursor-pointer flex m-auto box-border`}
  flex-grow: 0;
  border: ${(props) => `1.5px solid ${props.theme.pageTab.border}`};
  background-color: ${(props) =>
    props.selected ? '#dbf3e6' : props.theme.table.headerBgColor};
`;
