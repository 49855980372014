import { put, takeEvery } from 'redux-saga/effects';
import { PLAID_USERS_LINK, PLAID_GENERATE_TOKEN , USER_SOCIAL_LINK} from '../config/default';

import { formatDecimalValue } from '../utils/number';
import wrappedAxios from '../utils/WrappedAxios';
const ratingMap = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
};
const reverseRatingMap = Object.keys(ratingMap).reduce((acc, item, index) => {
  acc[index] = item;
  return acc;
}, {});
function* fecthMyVerdeData(action) {
  yield put({ type: 'FETCH_MY_VERDE_LOADING' });
  try {
    const { data } = yield wrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings?sub=${action.payload}` // &accounts=a,b,c for account related else all account data
    );

    let [tableData, profileData] = data;
    let result = [];
    const accountDetails = [];
    const accountKeys = Object.keys(tableData);
    if (accountKeys.length) {
      tableData = tableData[accountKeys[0]];
      if (Object.keys(tableData).length) {
        Object.keys(tableData).forEach((item) => {
          const tableDataItem = tableData[item];
          const holdingsArray = tableDataItem.account_holdings;
          let {
            totalPortfolioPercent = 0,
            accountGrowthRating,
            accountMomentumRating,
            accountSafetyRating,
            accountDividendRating,
            accountValueRating,
            verdeScore = 0,
          } = holdingsArray.reduce(
            (acc, item) => ({
              totalPortfolioPercent:
                acc.totalPortfolioPercent + item.portfolio_percent,
              accountGrowthRating:
                acc.accountGrowthRating + (ratingMap[item.growth_rating] ?? 0),
              accountMomentumRating:
                acc.accountMomentumRating +
                (ratingMap[item.momentum_rating] ?? 0),
              accountSafetyRating:
                acc.accountSafetyRating + (ratingMap[item.safety_rating] ?? 0),
              accountValueRating:
                acc.accountValueRating + (ratingMap[item.value_rating] ?? 0),
              accountDividendRating:
                acc.accountDividendRating +
                (ratingMap[item.dividend_rating] ?? 0),
              verdeScore: acc.verdeScore + item.verde_score,
            }),
            {
              accountGrowthRating: 0,
              accountMomentumRating: 0,
              accountSafetyRating: 0,
              accountValueRating: 0,
              accountDividendRating: 0,
              verdeScore: 0,
              totalPortfolioPercent: 0,
            }
          );
          const accountHoldingCount = holdingsArray.length;
          accountGrowthRating =
            reverseRatingMap[
              Math.floor(accountGrowthRating / accountHoldingCount)
            ];
          accountMomentumRating =
            reverseRatingMap[
              Math.floor(accountMomentumRating / accountHoldingCount)
            ];
          accountSafetyRating =
            reverseRatingMap[
              Math.floor(accountSafetyRating / accountHoldingCount)
            ];
          accountValueRating =
            reverseRatingMap[
              Math.floor(accountValueRating / accountHoldingCount)
            ];
          accountDividendRating =
            reverseRatingMap[
              Math.floor(accountDividendRating / accountHoldingCount)
            ];
          verdeScore = accountHoldingCount
            ? verdeScore / accountHoldingCount
            : 0;
          holdingsArray.forEach((holding) => {
            const holdingPercentage = formatDecimalValue(
              holding.portfolio_percent * (100 / totalPortfolioPercent)
            );
            holding.holding_percent = holdingPercentage;
          });
          accountDetails.push({
            data: holdingsArray,
            accountId: item,
            accountName: tableDataItem.account_details?.account_name,
            displayName: `${accountKeys[0]} : ${tableDataItem.account_details?.account_name} - ${tableDataItem.account_details?.account_number}`,
            accountDividendRating,
            accountGrowthRating,
            accountMomentumRating,
            accountSafetyRating,
            accountValueRating,
            verdeScore: Math.floor(
              holdingsArray.reduce(
                (acc, holding) =>
                  acc + holding.holding_percent * holding.verde_score,
                0
              ) / 100
            ),
          });
        });
      }
    }
    yield put({
      type: 'FETCH_MY_VERDE_SUCCESS',
      data: { tableData: result, profileData, accountDetails },
    });
  } catch (error) {
    yield put({ type: 'FETCH_MY_VERDE_ERROR', error: error.message });
  }
}

// function* getMyVerde(action) {
//   yield put({ type: 'GET_MY_VERDE_LOADING' });
//   try {
//     const { data } = yield wrappedAxios.get(
//       `${PLAID_USERS_LINK}/users?email=${action.payload}`
//     );

//     yield put({
//       type: 'GET_MY_VERDE_SUCCESS',
//       linked: data.portfolio_linked,
//       verde_enable: data.myverde_enabled,
//     });
//   } catch (error) {
//     yield put({ type: 'GET_MY_VERDE_ERROR', error: error.message });
//   }
// }


function* getMyVerde(action) {
  yield put({ type: 'GET_MY_VERDE_LOADING' });
  try {
    const {data}  = yield wrappedAxios.get(
      `${USER_SOCIAL_LINK}/users/${action.payload}`
    );

    yield put({
      type: 'GET_MY_VERDE_SUCCESS',
      username: data.username,
      email: data.email,
      linked: data.portfolio_linked,
      verde_enabled: data.myverde_enabled,
      userImg: data.profile_pic_url
    });
  } catch (error) {
    yield put({ type: 'GET_MY_VERDE_ERROR', error: error.message });
  }
}


export function* watchFectchMyVerde() {
  yield takeEvery('FETCH_MY_VERDE', fecthMyVerdeData);
  yield takeEvery('GET_MY_VERDE', getMyVerde);
}
