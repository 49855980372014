import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { INFO } from '../../../config/info-tags';
import DomainIcon from '../DomainIcon';
import DomainTerm from '../DomainTerm';

export const Title = styled.div`
  ${tw`text-xl font-bold cursor-default mr-2`}
  color: ${(props) => props.theme.title};
`;

const SectionHeader = ({ value, className, infoKey }) => {
  return (
    <div className={`${className} flex items-center`}>
      <Title>{value}</Title>
      <DomainTerm infoKey={infoKey} mark={false}>
        <DomainIcon value="tooltip-info" />
      </DomainTerm>
    </div>
  );
};

export default SectionHeader;
