import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ClickedOutside from '../../shared/ClickOutside';
import ScreenerTable from '../../shared/Sections/ScreenerTable';
import ScreenerTableNew from '../../shared/Sections/ScreenerTableNew';
import ColumnToggleImg from '../../../public/images/column-toggle.svg';
import ColumnSelectorImg from '../../../public/images/screener/column-choose.svg';
import PageContainer from '../../shared/PageContainer';
import TableColumnSwitch from '../../shared/TableColumnSwitch';
import { ColoredContentText, GreenText } from '../../shared/StyedComponents';
import { TabContainer, Tab, ColumnSelector } from './style';
import {
  defaultColumns,
  additionalColumns,
} from '../../shared/StockTable/columns';
import DefaultFilters from '../../shared/Sections/DefaultFilters';
import DefaultFiltersNew from '../../shared/Sections/DefaultFiltersNew';

import {
  fetchFilterValues,
  getScreenerData,
  updateFilters,
} from '../../../actions/screenerAction';
import { showPageLoading } from '../../../actions/coreAction';
import { showErrorAlert } from '../../../actions/alertAction';
import { appliedFiltersInitialState } from '../../../reducer/screenerReducer';

const defaultColumnNames = defaultColumns.map((column) => column['Header']);

function ScreenerPage({
  fetchFilterValues,
  error,
  loading,
  showPageLoading,
  showErrorAlert,
  updateFilters,
  filterValues,
  isNew,
}) {
  const [tableToggle, setTableToggle] = React.useState(false);
  const [columns, setColumns] = React.useState(
    [...defaultColumns, ...additionalColumns].map((column) => ({
      name: column['Header'],
      checked: defaultColumnNames.includes(column['Header']),
    }))
  );
  const [openFilterModal, setOpenFilterModal] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    document.title = 'Verde Finance - Screener';
    filterValues.sectors.length === 0 && fetchFilterValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    showPageLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    error && showErrorAlert(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  React.useEffect(() => {
    location.state
      ? updateFilters({
          ...appliedFiltersInitialState,
          sectors: location.state.sectors,
        })
      : updateFilters({
          ...appliedFiltersInitialState,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <PageContainer>
      <div className="hidden lg:flex">
        {isNew ? (
          <DefaultFiltersNew
            openFilterModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
          ></DefaultFiltersNew>
        ) : (
          <DefaultFilters
            openFilterModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
          />
        )}
      </div>

      <div className="flex justify-between items-center">
        {isNew ? null : (
          <div className="hidden md:block">
            <TabContainer>
              <Tab selected={true}>All Stocks</Tab>
              <Tab>Top Movers</Tab>
              <Tab>Stock Trends</Tab>
            </TabContainer>
          </div>
        )}

        <ColoredContentText className="font-medium text-base block md:hidden">
          All Stocks
        </ColoredContentText>
        <div>
          <div className="flex justify-between items-center">
            <GreenText
              className="lg:hidden flex text-sm font-medium mr-4 cursor-pointer"
              onClick={() => {
                setOpenFilterModal(true);
              }}
            >
              &#43; Add Filter
            </GreenText>
            <ClickedOutside
              onClicked={() => {
                setTableToggle(false);
              }}
            >
              <ColumnSelector
                selected={tableToggle}
                onClick={() => setTableToggle(!tableToggle)}
              >
                <img
                  src={ColumnSelectorImg}
                  alt=""
                  className="m-auto w-6 h-6"
                />
              </ColumnSelector>
              {tableToggle ? (
                <TableColumnSwitch columns={columns} setColumns={setColumns} />
              ) : null}
            </ClickedOutside>
          </div>
        </div>
      </div>
      {isNew ? null : (
        <div className="block md:hidden mt-1">
          <TabContainer>
            <Tab selected={true}>All Stocks</Tab>
            <Tab>Top Movers</Tab>
            <Tab>Stock Trends</Tab>
          </TabContainer>
        </div>
      )}
      <div className="my-1">
        {isNew ? (
          <ScreenerTableNew
            columns={columns
              .filter((column) => column.checked)
              .map((column) => column.name)}
          />
        ) : (
          <ScreenerTable
            columns={columns
              .filter((column) => column.checked)
              .map((column) => column.name)}
          />
        )}
      </div>
    </PageContainer>
  );
}

const mapStateToProps = (state) => ({
  filterValues: state.screenerState.filterValues.data,
  error: state.screenerState.error,
  loading:
    state.screenerState.pageloading || state.screenerState.filterValues.loading,
});

const mapDispatchToProps = {
  fetchFilterValues,
  getScreenerData,
  showPageLoading,
  showErrorAlert,
  updateFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerPage);
