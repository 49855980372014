import React from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PageContainer from '../../shared/PageContainer';
import { Tabs, TabPanel } from 'react-tabs';
import ValuationComponent from '../../tabs/company/valuation';
import PastPerformance from '../../tabs/company/pastPerformance';
import FutureGrowth from '../../tabs/company/futureGrowth';
import Dividend from '../../tabs/company/dividend';
import Insiders from '../../tabs/company/insiders';
import CompanySummary from '../../shared/Sections/CompanySummary';
import SimilarCompanies from '../../shared/SimilarCompanies';
import { gtag } from '../../../utils/analytics';
import {
  ColoredContentText,
  ContentWrapper,
  StyledTabList,
  StyledTab,
} from '../../shared/StyedComponents';
import {
  getSimilarCompaniesData,
  getStockInfo,
  getValuationData,
  getPastPerformanceData,
} from '../../../actions/action';
import { fetchSymbolInsights } from '../../../actions/insightsAction';
import { fetchSymbolNews } from '../../../actions/newsAction';
import RelatedNews from '../../shared/RelatedNews';

const tabs = [
  {
    id: 'valuation',
    title: 'Valuation',
    comp: <ValuationComponent title="Valuation" />,
  },
  {
    id: 'past-performance',
    title: 'Past Performance',
    comp: <PastPerformance title="Past Performance" />,
  },
  {
    id: 'future-growth',
    title: 'Future Growth',
    comp: <FutureGrowth title="Future Growth" />,
  },
  {
    id: 'dividend',
    title: 'Dividend',
    comp: <Dividend title="Dividend" />,
  },
  {
    id: 'ownership',
    title: 'Ownership',
    comp: <Insiders title="Ownership" />,
  },
];

const tabIds = tabs.map((tab) => tab.id);

function CompanyPage({
  getValuationData,
  getStockInfo,
  getSimilarCompaniesData,
  fetchSymbolInsights,
  fetchSymbolNews,
  getPastPerformanceData,
}) {
  const params = useParams();
  const history = useHistory();
  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    getValuationData(params.sid);
    getPastPerformanceData(params.sid);
    getStockInfo(params.sid);
    fetchSymbolInsights(params.sid);
    getSimilarCompaniesData(params.sid);
    fetchSymbolNews(params.sid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sid]);

  React.useEffect(() => {
    const index = params.tab
      ? tabIds.indexOf(params.tab) < 0
        ? 0
        : tabIds.indexOf(params.tab)
      : 0;
    setTabIndex(index);
    document.title = `${tabs[index].title} - ${params.sid}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.tab]);

  return (
    <PageContainer>
      <div className="grid grid-cols-4 gap-3 md:py-2">
        <div className="col-span-4 lg:col-span-3">
          <div className="flex items-center mb-4 md:my-4">
            <ColoredContentText className="font-medium">
              Company Info
            </ColoredContentText>
          </div>
          <CompanySummary />
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => {
              gtag('event', `${tabIds[index]}-company-tag-click`, {
                event_category: tabIds[index],
                event_label: params.sid,
              });
              history.push(`/${params.sid}/${tabIds[index]}`);
            }}
            className="mb-4 mt-0 lg:my-4 rounded"
          >
            <div className="sticky top-16 md:top-20 z-20">
              <StyledTabList className="hide-scrollbar">
                {tabs.map(({ title, id }, index) => {
                  return (
                    <StyledTab key={index} data-test-id={id}>
                      {title}
                    </StyledTab>
                  );
                })}
              </StyledTabList>
            </div>
            <ContentWrapper>
              {tabs.map(({ comp }, index) => {
                return <TabPanel key={index}>{comp}</TabPanel>;
              })}
            </ContentWrapper>
          </Tabs>
        </div>
        <div className="hidden lg:block lg:col-span-1">
          <div className="sticky top-24">
            <SimilarCompanies />
            <RelatedNews />
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

const mapStateToProps = (state) => ({
  valuationError: state.valuationState.error,
});

const mapDispatchToProps = {
  getValuationData,
  getStockInfo,
  fetchSymbolInsights,
  getSimilarCompaniesData,
  fetchSymbolNews,
  getPastPerformanceData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
