export const buttonOptions = [
  {
    type: 'month',
    count: 1,
    text: '1M',
  },
  {
    type: 'ytd',
    count: 3,
    text: 'YTD',
  },
  {
    type: 'year',
    count: 1,
    text: '1Y',
  },
  {
    type: 'year',
    count: 3,
    text: '3Y',
  },
  {
    type: 'year',
    count: 5,
    text: '5Y',
  },
];

export const accessors = {
  'Quarter 1': 'portfolioValue',
  'Quarter 2': 'portfolioValue',
  'Quarter 3': 'portfolioValue',
  'Quarter 4': 'portfolioValue',
  'Quarter 5': 'portfolioValue',
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
