export default class ScreenerCM {
  constructor(build) {
    this._symbol = build._symbol;
    this._name = build._name;
    this._logoUrl = build._logoUrl;
    this._industry = build._industry;
    this._sector = build._sector;
    this._currentPrice = build._currentPrice;
    this._marketCap = build._marketCap;
    this._fairPrice = build._fairPrice;
    this._percentChangeInPrice = build._percentChangeInPrice;
    this._fairPriceUpside = build._fairPriceUpside;
    this._targetPriceUpside = build._targetPriceUpside;
    this._targetPrice = build._targetPrice;
    this._verdeScore = build._verdeScore;
    this._analystRating = build._analystRating;
    this._growthRating = build._growthRating;
    this._safetyRating = build._safetyRating;
    this._valueRating = build._valueRating;
    this._dividendRating = build._dividendRating;
    this._momentumRating = build._momentumRating;
    this._forwardYield = build._forwardYield;
    this._fairPriceDeviation = build._fairPriceDeviation;
    this._priceToEarnings = build._priceToEarnings;
    this._priceToEarningsFWD = build._priceToEarningsFWD;
    this._forwardDividendRate = build._forwardDividendRate;
    this._oneDay = build._oneDay;
    this._oneWeek = build._oneWeek;
    this._oneMonth = build._oneMonth;
    this._sixMonths = build._sixMonths;
    this._ytd = build._ytd;
    this._oneYear = build._oneYear;
    this._fiveYears = build._fiveYears;
    this._enterprise = build._enterprise;
    this._pegRatio = build._pegRatio;
    this._priceToSale = build._priceToSale;
    this._priceToBook = build._priceToBook;
    this._grossMargin = build._grossMargin;
    this._netMargin = build._netMargin;
    this._operatingMargin = build._operatingMargin;
    this._epsGrowth = build._epsGrowth;
    this._revenueGrowth = build._revenueGrowth;
    this._revenueGrowthFWD = build._revenueGrowthFWD;
    this._revenueGrowthFY1 = build._revenueGrowthFY1;
    this._revenueGrowthFY2 = build._revenueGrowthFY2;
    this._epsGrowthFWD = build._epsGrowthFWD;
    this._epsGrowthFY1 = build._epsGrowthFY1;
    this._epsGrowthFY2 = build._epsGrowthFY2;
    this._threeMonth = build._threeMonth;
    this._profitabilityRating = build._profitabilityRating;
    this._netIncomeMargin = build._netIncomeMargin;
    this._returnOnAssets = build._returnOnAssets;
    this._returnonEquity = build._returnonEquity;
    this._returnOnInvestmentCapital = build._returnOnInvestmentCapital;
    this._threeYears = build._threeYears;
    this._evEbit = build._evEbit;
    this._evEbita = build._evEbita;
    this._evRevenue = build._evRevenue;
    this._debtCapital = build._debtCapital;
    this._debtEquity = build._debtEquity;
    this._symbolMarket = build._symbolMarket;
  }

  get symbol() {
    return this._symbol;
  }

  set symbol(symbol) {
    this._symbol = symbol;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get logoUrl() {
    return this._logoUrl;
  }

  set logoUrl(logoUrl) {
    this._logoUrl = logoUrl;
  }

  get industry() {
    return this._industry;
  }

  set industry(industry) {
    this._industry = industry;
  }

  get sector() {
    return this._sector;
  }

  set sector(sector) {
    this._sector = sector;
  }

  get currentPrice() {
    return this._currentPrice;
  }

  set currentPrice(currentPrice) {
    this._currentPrice = currentPrice;
  }

  get marketCap() {
    return this._marketCap;
  }

  set marketCap(marketCap) {
    this._marketCap = marketCap;
  }

  get fairPrice() {
    return this._fairPrice;
  }

  set fairPrice(fairPrice) {
    this._fairPrice = fairPrice;
  }

  get fairPriceUpside() {
    return this._fairPriceUpside;
  }

  set fairPriceUpside(fairPriceUpside) {
    this._fairPriceUpside = fairPriceUpside;
  }

  get targetPriceUpside() {
    return this._targetPriceUpside;
  }

  set targetPriceUpside(targetPriceUpside) {
    this._targetPriceUpside = targetPriceUpside;
  }

  get percentChangeInPrice() {
    return this._percentChangeInPrice;
  }

  set percentChangeInPrice(percentChangeInPrice) {
    this._percentChangeInPrice = percentChangeInPrice;
  }

  get targetPrice() {
    return this._targetPrice;
  }

  set targetPrice(targetPrice) {
    this._targetPrice = targetPrice;
  }

  get verdeScore() {
    return this._verdeScore;
  }

  set verdeScore(verdeScore) {
    this._verdeScore = verdeScore;
  }

  get analystRating() {
    return this._analystRating;
  }

  set analystRating(analystRating) {
    this._analystRating = analystRating;
  }

  get growthRating() {
    return this._growthRating;
  }

  set growthRating(growthRating) {
    this._growthRating = growthRating;
  }

  get safetyRating() {
    return this._safetyRating;
  }

  set safetyRating(safetyRating) {
    this._safetyRating = safetyRating;
  }

  get valueRating() {
    return this._valueRating;
  }

  set valueRating(valueRating) {
    this._valueRating = valueRating;
  }

  get dividendRating() {
    return this._dividendRating;
  }

  set dividendRating(dividendRating) {
    this._dividendRating = dividendRating;
  }

  get momentumRating() {
    return this._momentumRating;
  }

  set momentumRating(momentumRating) {
    this._momentumRating = momentumRating;
  }

  get forwardYield() {
    return this._forwardYield;
  }

  set forwardYield(forwardYield) {
    this._forwardYield = forwardYield;
  }

  get fairPriceDeviation() {
    return this._fairPriceDeviation;
  }

  set fairPriceDeviation(fairPriceDeviation) {
    this._fairPriceDeviation = fairPriceDeviation;
  }

  get priceToEarnings() {
    return this._priceToEarnings;
  }

  set priceToEarnings(priceToEarnings) {
    this._priceToEarnings = priceToEarnings;
  }

  get priceToEarningsFWD() {
    return this._priceToEarningsFWD;
  }

  set priceToEarningsFWD(priceToEarningsFWD) {
    this._priceToEarningsFWD = priceToEarningsFWD;
  }

  get forwardDividendRate() {
    return this._forwardDividendRate;
  }

  set forwardDividendRate(forwardDividendRate) {
    this._forwardDividendRate = forwardDividendRate;
  }

  get oneWeek() {
    return this._oneWeek;
  }

  set oneWeek(oneWeek) {
    this._oneWeek = oneWeek;
  }

  get oneMonth() {
    return this._oneMonth;
  }

  set oneMonth(oneMonth) {
    this._oneMonth = oneMonth;
  }

  get oneDay() {
    return this._oneDay;
  }

  set oneDay(oneDay) {
    this._oneDay = oneDay;
  }

  get sixMonths() {
    return this._sixMonths;
  }

  set sixMonths(sixMonths) {
    this._sixMonths = sixMonths;
  }

  get ytd() {
    return this._ytd;
  }

  set ytd(ytd) {
    this._ytd = ytd;
  }

  get oneYear() {
    return this._oneYear;
  }

  set oneYear(oneYear) {
    this._oneYear = oneYear;
  }

  get fiveYears() {
    return this._fiveYears;
  }

  set fiveYears(fiveYears) {
    this._fiveYears = fiveYears;
  }

  get enterPrise() {
    return this._enterprise;
  }

  set enterPrise(enterPrise) {
    this._enterPrise = enterPrise;
  }

  get pegRatio() {
    return this._pegRatio;
  }

  set pegRatio(pegRatio) {
    this._pegRatio = pegRatio;
  }

  get priceToSale() {
    return this._priceToSale;
  }

  set priceToSale(priceToSale) {
    this._priceToSale = priceToSale;
  }

  get priceToBook() {
    return this._priceToBook;
  }

  set priceToBook(priceToBook) {
    this._priceToBook = priceToBook;
  }

  get grossMargin() {
    return this._grossMargin;
  }

  set grossMargin(grossMargin) {
    this._grossMargin = grossMargin;
  }

  get netMargin() {
    return this._netMargin;
  }

  set netMargin(netMargin) {
    this._netMargin = netMargin;
  }

  get operatingMargin() {
    return this._operatingMargin;
  }

  set operatingMargin(operatingMargin) {
    this._operatingMargin = operatingMargin;
  }

  get epsGrowth() {
    return this._epsGrowth;
  }

  set epsGrowth(epsGrowth) {
    this._epsGrowth = epsGrowth;
  }

  get epsGrowthFWD() {
    return this._epsGrowthFWD;
  }

  set epsGrowthFWD(epsGrowth) {
    this._epsGrowthFWD = epsGrowth;
  }
  get epsGrowthFY1() {
    return this._epsGrowthFY1;
  }

  set epsGrowth(epsGrowth) {
    this._epsGrowthFY1 = epsGrowth;
  }
  get epsGrowthFY2() {
    return this._epsGrowthFY2;
  }

  set epsGrowth(epsGrowth) {
    this._epsGrowthFY2 = epsGrowth;
  }

  get revenueGrowth() {
    return this._revenueGrowth;
  }

  set revenueGrowth(revenueGrowth) {
    this._revenueGrowth = revenueGrowth;
  }

  get revenueGrowthFWD() {
    return this._revenueGrowthFWD;
  }

  set revenueGrowthFWD(revenueGrowth) {
    this._revenueGrowthFWD = revenueGrowth;
  }

  get revenueGrowthFY1() {
    return this._revenueGrowthFY1;
  }

  set revenueGrowthFY1(revenueGrowth) {
    this._revenueGrowthFY1 = revenueGrowth;
  }

  get revenueGrowthFY2() {
    return this._revenueGrowthFY2;
  }

  set revenueGrowthFY2(revenueGrowth) {
    this._revenueGrowthFY2 = revenueGrowth;
  }

  get threeMonth() {
    return this._threeMonth;
  }

  set threeMonth(threeMonth) {
    this._threeMonth = threeMonth;
  }

  get profitabilityRating() {
    return this._profitabilityRating;
  }

  set profitabilityRating(rating) {
    this._profitabilityRating = rating;
  }

  get netIncomeMargin() {
    return this._netIncomeMargin;
  }

  set netIncomeMargin(margin) {
    this._netIncomeMargin = margin;
  }

  get returnOnAssets() {
    return this._returnOnAssets;
  }
  set returnOnAssets(returnOnAssets) {
    this._returnOnAssets = returnOnAssets;
  }

  get returnonEquity() {
    return this._returnonEquity;
  }
  set returnonEquity(equity) {
    this._returnonEquity = equity;
  }

  get returnOnInvestmentCapital() {
    return this._returnOnInvestmentCapital;
  }

  set returnOnInvestmentCapital(capital) {
    this._returnOnInvestmentCapital = capital;
  }

  get threeYears() {
    return this._threeYears;
  }

  set threeYears(years) {
    this._threeYears = years;
  }

  get evEbit() {
    return this._evEbit;
  }
  get evEbita() {
    return this._evEbita;
  }
  get evRevenue() {
    return this._evRevenue;
  }
  get debtCapital() {
    return this._debtCapital;
  }
  get debtEquity() {
    return this._debtEquity;
  }

  set evEbit(value) {
    this._evEbit = value;
  }
  set evEbita(value) {
    this._evEbita = value;
  }
  set evRevenue(value) {
    this._evRevenue = value;
  }
  set debtCapital(value) {
    this._debtCapital = value;
  }
  set debtEquity(value) {
    this._debtEquity = value;
  }

  get symbolMarket() {
    return this._symbolMarket;
  }

  set symbolMarket(symbolMarket) {
    this._symbolMarket = symbolMarket;
  }

  static get builder() {
    class Builder {
      symbol(symbol) {
        this._symbol = symbol;
        return this;
      }

      name(name) {
        this._name = name;
        return this;
      }

      logoUrl(logoUrl) {
        this._logoUrl = logoUrl;
        return this;
      }

      industry(industry) {
        this._industry = industry;
        return this;
      }

      sector(sector) {
        this._sector = sector;
        return this;
      }

      currentPrice(currentPrice) {
        this._currentPrice = currentPrice;
        return this;
      }

      marketCap(marketCap) {
        this._marketCap = marketCap;
        return this;
      }

      fairPrice(fairPrice) {
        this._fairPrice = fairPrice;
        return this;
      }

      fairPriceUpside(fairPriceUpside) {
        this._fairPriceUpside = fairPriceUpside;
        return this;
      }

      targetPriceUpside(targetPriceUpside) {
        this._targetPriceUpside = targetPriceUpside;
        return this;
      }

      percentChangeInPrice(percentChangeInPrice) {
        this._percentChangeInPrice = percentChangeInPrice;
        return this;
      }

      targetPrice(targetPrice) {
        this._targetPrice = targetPrice;
        return this;
      }

      verdeScore(verdeScore) {
        this._verdeScore = verdeScore;
        return this;
      }

      analystRating(analystRating) {
        this._analystRating = analystRating;
        return this;
      }

      growthRating(growthRating) {
        this._growthRating = growthRating;
        return this;
      }

      safetyRating(safetyRating) {
        this._safetyRating = safetyRating;
        return this;
      }

      valueRating(valueRating) {
        this._valueRating = valueRating;
        return this;
      }

      dividendRating(valueRating) {
        this._dividendRating = valueRating;
        return this;
      }

      momentumRating(momentumRating) {
        this._momentumRating = momentumRating;
        return this;
      }

      forwardYield(forwardYield) {
        this._forwardYield = forwardYield;
        return this;
      }

      fairPriceDeviation(fairPriceDeviation) {
        this._fairPriceDeviation = fairPriceDeviation;
        return this;
      }

      priceToEarnings(priceToEarnings) {
        this._priceToEarnings = priceToEarnings;
        return this;
      }

      priceToEarningsFWD(priceToEarningsFWD) {
        this._priceToEarningsFWD = priceToEarningsFWD;
        return this;
      }

      forwardDividendRate(forwardDividendRate) {
        this._forwardDividendRate = forwardDividendRate;
        return this;
      }

      oneWeek(oneWeek) {
        this._oneWeek = oneWeek;
        return this;
      }

      oneMonth(oneMonth) {
        this._oneMonth = oneMonth;
        return this;
      }

      sixMonths(sixMonths) {
        this._sixMonths = sixMonths;
        return this;
      }

      ytd(ytd) {
        this._ytd = ytd;
        return this;
      }

      oneYear(oneYear) {
        this._oneYear = oneYear;
        return this;
      }

      oneDay(oneDay) {
        this._oneDay = oneDay;
        return this;
      }

      fiveYears(fiveYears) {
        this._fiveYears = fiveYears;
        return this;
      }
      enterPrise(enterprise) {
        this._enterprise = enterprise;
        return this;
      }
      pegRatio(pegRatio) {
        this._pegRatio = pegRatio;
        return this;
      }

      priceToSale(priceToSale) {
        this._priceToSale = priceToSale;
        return this;
      }
      priceToBook(priceToBook) {
        this._priceToBook = priceToBook;
        return this;
      }

      grossMargin(grossMargin) {
        this._grossMargin = grossMargin;
        return this;
      }

      netMargin(netMargin) {
        this._netMargin = netMargin;
        return this;
      }

      operatingMargin(operatingMargin) {
        this._operatingMargin = operatingMargin;
        return this;
      }

      epsGrowth(epsGrowth) {
        this._epsGrowth = epsGrowth;
        return this;
      }

      epsGrowthFWD(epsGrowth) {
        this._epsGrowthFWD = epsGrowth;
        return this;
      }
      epsGrowthFY1(epsGrowth) {
        this._epsGrowthFY1 = epsGrowth;
        return this;
      }
      epsGrowthFY2(epsGrowth) {
        this._epsGrowthFY2 = epsGrowth;
        return this;
      }

      revenueGrowth(revenueGrowth) {
        this._revenueGrowth = revenueGrowth;
        return this;
      }
      revenueGrowthFWD(revenueGrowth) {
        this._revenueGrowthFWD = revenueGrowth;
        return this;
      }
      revenueGrowthFY1(revenueGrowth) {
        this._revenueGrowthFY1 = revenueGrowth;
        return this;
      }
      revenueGrowthFY2(revenueGrowth) {
        this._revenueGrowthFY2 = revenueGrowth;
        return this;
      }

      threeMonth(threeMonth) {
        this._threeMonth = threeMonth;
        return this;
      }

      profitabilityRating(rating) {
        this._profitabilityRating = rating;
        return this;
      }
      netIncomeMargin(margin) {
        this._netIncomeMargin = margin;
        return this;
      }
      returnOnAssets(assets) {
        this._returnOnAssets = assets;
        return this;
      }
      returnOnEquity(equity) {
        this._returnonEquity = equity;
        return this;
      }
      returnOnInvestmentCapital(capital) {
        this._returnOnInvestmentCapital = capital;
        return this;
      }
      threeYears(years) {
        this._threeYears = years;
        return this;
      }

      evEbit(value) {
        this._evEbit = value;
        return this;
      }
      evEbita(value) {
        this._evEbita = value;
        return this;
      }
      evRevenue(value) {
        this._evRevenue = value;
        return this;
      }
      debtCapital(value) {
        this._debtCapital = value;
        return this;
      }
      debtEquity(value) {
        this._debtEquity = value;
        return this;
      }

      symbolMarket(symbolMarket) {
        this._symbolMarket = symbolMarket;
        return this;
      }

      build() {
        return new ScreenerCM(this);
      }
    }
    return Builder;
  }
}
