import React from 'react';
import LeaderboardCrown from '../../shared/Sections/LeaderboardCrown';
import LeaderboardTable from '../../shared/Sections/LeaderboardTable';

const Leaderboard = () => {
  return (
    <div className="bg-white mb-16" style={{ minWidth: '600px' }}>
      <LeaderboardCrown />
      <LeaderboardTable />
    </div>
  );
};

export default Leaderboard;
