import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  buildTooltipByValue,
  getFormattedCurrencyValueForTooltip,
  getTooltipDate,
} from '../../../utils/ChartUtils';

const BarLineComboChart = ({ data, darkMode, xAxisData }) => {
  function generateTooltip(_this, mobileMode) {
    const points = _this.points;
    let tooltips = '';
    const padding = mobileMode ? 3 : 5;
    tooltips = tooltips.concat(
      '<div style="position:relative;height:100%;padding:' + padding + 'px;"> '
    );
    const shareholderReturn = points[0];
    const netIncome = points[1];
    const payoutRato = points[2];
    tooltips = tooltips.concat(
      buildTooltipByValue(
        getFormattedCurrencyValueForTooltip(shareholderReturn.y),
        shareholderReturn.series.color.stops[0][1],
        shareholderReturn.series.name,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(
      buildTooltipByValue(
        getFormattedCurrencyValueForTooltip(netIncome.y),
        netIncome.series.color.stops[0][1],
        netIncome.series.name,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(
      buildTooltipByValue(
        payoutRato.y + '%',
        payoutRato.series.color,
        payoutRato.series.name,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(getTooltipDate(_this.x, darkMode, mobileMode));
    return tooltips;
  }

  const options = {
    chart: {
      zoomType: 'xy',
      style: {
        fontFamily: 'Roboto',
      },
      backgroundColor: darkMode ? 'transparent' : '#ffffff',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: xAxisData.map((d) => {
        return Highcharts.dateFormat('%b %Y', new Date(d).getTime());
      }),
      type: 'datetime',
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      dateTimeLabelFormats: {
        day: '%e%b%y',
        month: '%b %Y',
      },
    },
    yAxis: [
      {
        title: {
          useHTML: true,
          text:
            '<span style="color:rgb(101, 235, 238);font-size:20px;margin-right:2px;">\u25CF</span> Total Dividends Paid Out <span style="color:rgb(225, 130, 222);font-size:20px;margin-left:12px;margin-right:2px;">\u25CF</span> Net Income',
          style: {
            fontSize: '14px',
            fontWeight: 500,
            color: darkMode ? '#d8d9e4' : '#686868',
          },
        },
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 500,
            color: darkMode ? '#d8d9e4' : '#3a3a3a',
          },
          formatter: function () {
            return '$' + this.axis.defaultLabelFormatter.call(this);
          },
        },
        gridLineColor: darkMode ? '#3b404f' : '#e6e6e6',
      },
      {
        title: {
          useHTML: true,
          text:
            '<span style="color:#9e71ff;font-size:20px;margin-right:2px;">\u25CF</span> Payout Ratio',
          style: {
            fontSize: '14px',
            fontWeight: 500,
            color: darkMode ? '#d8d9e4' : '#686868',
          },
          rotation: 270,
        },
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 500,
            color: darkMode ? '#d8d9e4' : '#3a3a3a',
          },
          formatter: function () {
            return this.axis.defaultLabelFormatter.call(this) + '%';
          },
        },
        gridLineWidth: 0,
        opposite: true,
      },
    ],
    plotOptions: {
      series: {
        //pointWidth: 18,
        borderRadius: 4,
        lineColor: 'transparent',
        borderWidth: 0,
        /* dataLabels: {
          useHTML: true,
          enabled: true,
          format:
            ' <div><div style="font-size: 12px;font-weight:500;font-family:Roboto;">$ {y} </div> </div>',
        }, */
      },
      spline: {
        lineColor: '#9e71ff',
      },
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shadow: false,
      useHTML: true,
      backgroundColor: darkMode ? '#141920' : '#fff',
      borderColor: darkMode ? '#86efaf' : '#5bae7f',
      borderRadius: 8,
      followPointer: true,
      formatter: function () {
        return generateTooltip(this, false);
      },
      shared: true,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            plotOptions: {
              //pointWidth: 20,
            },
            tooltip: {
              formatter: function () {
                return generateTooltip(this, true);
              },
            },
            chart: {
              height: 400,
            },
          },
        },
      ],
    },
    series: [
      {
        name: data[0].name,
        data: data[0].data,
        type: 'column',
        threshold: null,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: darkMode
            ? [
                [0, 'rgb(101, 235, 238)'],
                [1, 'rgb(6, 132, 144)'],
              ]
            : [
                [0, 'rgb(101, 235, 238)'],
                [1, 'rgb(161, 240, 249)'],
              ],
        },
        fillOpacity: 0.3,
        yAxis: 0,
      },
      {
        name: data[1].name,
        data: data[1].data,
        type: 'column',
        threshold: null,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: darkMode
            ? [
                [0, 'rgb(225, 130, 222)'],
                [1, 'rgb(121, 19, 118)'],
              ]
            : [
                [0, 'rgb(225, 130, 222)'],
                [1, 'rgb(239, 181, 238)'],
              ],
        },
        fillOpacity: 0.3,
        yAxis: 0,
      },
      {
        name: data[2].name,
        data: data[2].data,
        type: 'spline',
        threshold: null,
        color: '#9e71ff',
        yAxis: 1,
        //fillOpacity: 0.3,
      },
    ],
  };

  return (
    <div className="text-xs font-semibold w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(BarLineComboChart);
