import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import NumberBadge from '../../NumberBadge';
import PersonCard from '../../PersonCard';
import Flags from 'country-flag-icons/react/3x2';
import { getCountryNameFromCode } from '../../../../utils/CountryNameFinder';
import { ColoredContentText } from '../../StyedComponents';
import Trend from '../../Trend';
import StockInfo from '../Valuation/StockInformation/StockInfo';
import data from './data.json';

const Row = styled.div`
  ${tw`grid grid-cols-8 gap-4 py-4 border-b`}
  border-color: ${(props) => props.theme.sectionBorder};
`;

const Data = styled(ColoredContentText)`
  ${tw``}
`;

const Head = styled.div`
  color: #6e768c;
`;

const RelativeIncrease = styled.div`
  ${tw`mx-1.5`}
  color: ${(props) => props.theme.trend.upward};
`;

const LeaderboardTable = () => {
  const [showGraph, setShowGraph] = React.useState(new Set());
  return (
    <div className="relative top-12 text-sm font-medium">
      <Row>
        <Head>Rank</Head>
        <Head className="col-span-2">User</Head>
        <Head className="col-span-2">Country</Head>
        <Head>Portfolio</Head>
        <Head>Total Return</Head>
        <Head>Profit / Loss</Head>
      </Row>
      {Array.from(Array(10).keys()).map((rank) => (
        <React.Fragment key={rank}>
          <Row
            className="flex items-center cursor-pointer hover:bg-gray-50"
            onClick={() => {
              showGraph.has(rank)
                ? showGraph.delete(rank)
                : showGraph.add(rank);
              setShowGraph(new Set(showGraph));
            }}
          >
            <Data>
              <NumberBadge bgColor="#dbf3e6" value={rank + 1} />
            </Data>
            <Data className="col-span-2">
              <PersonCard name="Roger sinst" followers={25} />
            </Data>
            <Data className="col-span-2 flex">
              <div>
                <Flags.US title="United States" className="w-5 h-3 mr-2 mt-1" />
              </div>
              <div>{getCountryNameFromCode('US')}</div>
            </Data>
            <Data>$216.05</Data>
            <Data>$216.05</Data>
            <Data className="flex">
              <Trend trend={true} color="#36A867" className="mt-1" />
              <RelativeIncrease>+6.41%</RelativeIncrease>
            </Data>
          </Row>
          {showGraph.has(rank) && (
            <div className="py-4">
              <StockInfo data={data} />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default LeaderboardTable;
