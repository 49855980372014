import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';
import axios from 'axios';

function* fetchInsidersData(action) {
  yield put({ type: 'FETCH_INSIDERS_DATA_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .get(`${SYMBOL_BASE_URL}/insiders/${action.payload.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_INSIDERS_DATA_SUCCESS',
      data: responseData,
      symbol: action.payload.toUpperCase(),
    });
  } catch (error) {
    yield put({
      type: 'FETCH_INSIDERS_DATA_ERROR',
      error: error.message,
    });
  }
}

function* fetchHoldersData(action) {
  yield put({ type: 'FETCH_HOLDERS_DATA_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .get(
        `${SYMBOL_BASE_URL}/holders?symbol=${action.symbol.toUpperCase()}&type=${
          action.holderType
        }`
      )
      .then((response) => {
        return response.data;
      });
    if (action.holderType === 'INSTITUTIONS') {
      yield put({
        type: 'FETCH_INSTITUTIONS_HOLDERS_DATA_SUCCESS',
        data: responseData,
        symbol: action.symbol.toUpperCase(),
      });
    } else {
      yield put({
        type: 'FETCH_FUNDS_HOLDERS_DATA_SUCCESS',
        data: responseData,
        symbol: action.symbol.toUpperCase(),
      });
    }
  } catch (error) {
    yield put({
      type: 'FETCH_HOLDERS_DATA_ERROR',
      error: error.message,
    });
  }
}

function* fetchInsidersPublicData(action) {
  console.log("FETCH_INSIDERS_PUBLIC_DATA");
  yield put({ type: 'FETCH_INSIDERS_DATA_LOADING' });
  try {
    const responseData = yield axios
      .get(`${SYMBOL_BASE_URL}/insiders-public/${action.payload.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_INSIDERS_DATA_SUCCESS',
      data: responseData,
      symbol: action.payload.toUpperCase(),
    });
  } catch (error) {
    yield put({
      type: 'FETCH_INSIDERS_DATA_ERROR',
      error: error.message,
    });
  }
}

function* fetchHoldersPublicData(action) {
  yield put({ type: 'FETCH_HOLDERS_DATA_LOADING' });
  try {
    const responseData = yield axios
      .get(
        `${SYMBOL_BASE_URL}/holders-public?symbol=${action.symbol.toUpperCase()}&type=${
          action.holderType
        }`
      )
      .then((response) => {
        return response.data;
      });
    if (action.holderType === 'INSTITUTIONS') {
      yield put({
        type: 'FETCH_INSTITUTIONS_HOLDERS_DATA_SUCCESS',
        data: responseData,
        symbol: action.symbol.toUpperCase(),
      });
    } else {
      yield put({
        type: 'FETCH_FUNDS_HOLDERS_DATA_SUCCESS',
        data: responseData,
        symbol: action.symbol.toUpperCase(),
      });
    }
  } catch (error) {
    yield put({
      type: 'FETCH_HOLDERS_DATA_ERROR',
      error: error.message,
    });
  }
}

export function* watchFetchingInsidersData() {
  yield takeEvery('FETCH_INSIDERS_DATA', fetchInsidersData);
  yield takeEvery('FETCH_HOLDERS_DATA', fetchHoldersData);
  yield takeEvery('FETCH_INSIDERS_PUBLIC_DATA', fetchInsidersPublicData);
  yield takeEvery('FETCH_HOLDERS_PUBLIC_DATA', fetchHoldersPublicData);
}
