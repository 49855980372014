import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { Connect } from 'react-redux';

export const RATING_COLOR_CODE = {
  A: { color: '#559C30' },
  B: { color: '#B5A93B' },
  C: { color: '#EFB241' },
  D: { color: '#E0833A' },
  E: { color: '#D36535' },
  F: { color: '#BF2F2C' },
};

export const RatingValue = styled.div`
  ${tw`w-8 h-8 rounded text-center text-white text-lg pt-0.5 font-semibold`};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#1C1C1E50')};
`;
