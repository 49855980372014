import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import PriceGrowthButtonGroup from '../MultiSelectButtonGroup';

const StyledTable = styled.table`
  ${tw`border-collapse table-auto w-full my-4 text-sm`}
`;

const StyledTableHead = styled.th`
  ${tw`py-2 px-8 font-medium border-0 text-left`}
  background-color: ${(props) => props.theme.table.headerBgColor};
  border: 1px solid ${(props) => props.theme.table.headerBorder};
  border-left: 0;
  border-right: 0;
  color: ${(props) => props.theme.table.headerColor};
`;

const StyledTableData = styled.td`
  ${tw`py-2 px-8 border-0`}
  color: ${(props) =>
    props.first
      ? props.theme.table.headerColor
      : props.type === 'positive'
      ? props.theme.bulletPoints.positive
      : props.type === 'negative'
      ? props.theme.bulletPoints.negative
      : props.theme.table.color};
  font-weight: ${(props) => (props.first ? '500' : 'normal')};
`;

const BorderedDiv = styled.div`
  background-color: ${(props) => props.theme.table.background};
  border: 1px solid ${(props) => props.theme.table.border};
  color: ${(props) => props.theme.content.color};
`;

export default function PriceGrowthTable(props) {
  const { columns, data, timeSeries, setTimeSeries } = props;
  const tableInstance = useTable({ columns, data });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <BorderedDiv>
      <div className="md:flex flex-row mt-4">
        <div className="text-lg font-medium pb-1 md:p-3 ml-4">Price Growth</div>
        <div className="flex justify-center mx-auto">
          <PriceGrowthButtonGroup
            values={timeSeries}
            setValues={setTimeSeries}
          />
        </div>
      </div>
      <div className="overflow-x-scroll hide-scrollbar">
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableHead {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </StyledTableHead>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <StyledTableData
                        first={index === 0}
                        type={
                          index !== 0 && cell.value < 0
                            ? 'negative'
                            : cell.value > 0
                            ? 'positive'
                            : 'neutral'
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </StyledTableData>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </div>
    </BorderedDiv>
  );
}
