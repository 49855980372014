import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JsxParser from 'react-jsx-parser';
import { components, decorate } from '../../shared/BulletPoints';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

function RiskPositives({ positives, negatives, darkMode, isInsight }) {
  return (
    <>
      {positives && positives.length > 0 ? (
        <Flex
          bg={darkMode ? '#2D392E' : 'rgba(86, 156, 48, 0.05)'}
          flexDir="column"
          p="3"
          borderRadius="5px"
          margin="10px"
          marginTop="20px"
        >
          <Flex gap="1" alignItems="center">
            {!isInsight && (
              <FontAwesomeIcon
                icon={faThumbsUp}
                color="#569c30"
              ></FontAwesomeIcon>
            )}
            <Text
              fontWeight="bold"
              color={darkMode ? 'white' : 'black'}
              marginLeft="8px"
            >
              {isInsight ? 'Insights' : 'Positives'}
            </Text>
          </Flex>
          <Box as="ul" fontSize="sm" color={darkMode ? 'white' : 'black'}>
            {positives.map((positive) => (
              <Box as="li" p="2" ml="5" mb="1" display="flex">
                {isInsight && (
                  <div style={{ marginTop: 5 }}>
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      color="#569c30"
                    ></FontAwesomeIcon>
                  </div>
                )}
                <Text
                  style={{
                    marginLeft: 10,
                    fontSize: '16px',
                    color: darkMode ? 'white' : '#595959',
                  }}
                >
                  <JsxParser components={components} jsx={decorate(positive)} />
                </Text>
              </Box>
            ))}
          </Box>
        </Flex>
      ) : (
        <div></div>
      )}

      {negatives && negatives.length > 0 ? (
        <Flex
          bg={darkMode ? '#392e2d' : 'rgba(190, 46, 44, 0.05)'}
          flexDir="column"
          p="3"
          borderRadius="5px"
          margin="10px"
        >
          <Flex gap="1" alignItems="center">
            {!isInsight && (
              <FontAwesomeIcon
                icon={faThumbsDown}
                color="#be2e2c"
              ></FontAwesomeIcon>
            )}
            <Text
              fontWeight="bold"
              color={darkMode ? 'white' : 'black'}
              marginLeft="8px"
            >
              {isInsight ? 'Insights' : 'Risks'}
            </Text>
          </Flex>
          <Box as="ul" fontSize="sm" color={darkMode ? 'white' : 'black'}>
            {negatives.map((negative) => (
              <Box as="li" p="2" ml="5" mb="1" display="flex">
                {isInsight && (
                  <div style={{ marginTop: 5 }}>
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                      color="#be2e2c"
                    ></FontAwesomeIcon>
                  </div>
                )}
                <Text
                  style={{
                    marginLeft: 10,
                    fontSize: '16px',
                    color: darkMode ? 'white' : '#595959',
                  }}
                >
                  <JsxParser components={components} jsx={decorate(negative)} />
                </Text>
              </Box>
            ))}
          </Box>
        </Flex>
      ) : (
        <div></div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RiskPositives);
