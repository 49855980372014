import { Box, Flex, Text } from '@chakra-ui/layout';
import React, { FC, useState, useEffect } from 'react';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import {
  updateFilters,
  resetFilters,
} from '../../../../actions/screenerAction';
import { Button } from '@chakra-ui/button';
import { ChevronDownIcon, ChevronUpIcon, AddIcon } from '@chakra-ui/icons';
import { getFilters } from './filters';
import { SlideFade } from '@chakra-ui/react';
import { DefaultTheme, useTheme } from 'styled-components';
import FilterModalNew from '../../../shared/FilterModalNew';
import NewFilters from '../../../pages/ScreenerPageNew/newFilters';

export const themeObj = {
  dark: {
    addBorder: '#36a867',
    boxBoder: 'transparent',
    background: '#1d212b',
    tabText: '#e8e8e8',
    tabTextSelected: '#24b552',
    tabSelectedBg: '#1d212b',
    tabBg: '#0d0e13',
    filterText: '#f8fafd',
    dividerColor: '#3b404f',
    addFilterText: '#36a867',
    selectText: 'white',
    applyBtnColor: 'white',
    applyBtnBg: '#36A867',
    pillSelectedBorder: '#d1eeaf',
    pillSelectedBg: '#def4c5',
    pillBg: '#272931',
    pillBorder: '#1c1d23',
    pillSelectedColor: '#19233c',
    pillColor: '#383B54',
    collapseColor: '#707070',
    applyBtnHover: '#24b552',
    filters: {
      searchBorder: 'transparent',
      searchBg: '#262626',
      headerLink: 'white',
      selectBoxLabelColor: 'white',
      applyFilterBtnBg: '#36a867',
      applyFilterBtnColor: 'white',
      applyFilterBtnBgHover: '#24b552',
      pillBg: '#393b3f',
      pillBorder: '#3B404F ',
      pillColor: 'white',
    },
  },
  light: {
    background: 'white',
    boxBorder: '#ececed',
    tabBg: '#fafafa',
    tabTextSelectedBg: '#def4c5',
    tabTextSelected: '#36a867',
    tabSelectedBorder: '#ececed',
    tabSelectedBg: '#ffffff',
    pillSelectedBorder: '#d1eeaf',
    pillSelectedBg: '#def4c5',
    pillBg: '#ececed',
    pillBorder: '#e3e3e3',
    applyBtnColor: 'white',
    applyBtnBg: '#36A867',
    collapseColor: '#707070',
    applyBtnHover: '#24b552',
    filters: {
      searchBorder: '',
      searchBg: 'white',
      pillBg: '#e6f8f0',
      pillColor: '#19233c',
    },
  },
};

export const getSelectStyles = (isDark: boolean) => ({
  control: (styles: any) => ({
    ...styles,
    backgroundColor: isDark ? '#262626' : 'white',
    color: isDark ? 'white' : '#3a3a3a',
    borderColor: isDark ? '#595959' : '#dedcdc',
    boxShadow: 'none',
    '&:hover': {
      borderColor: isDark ? '#3b404f' : '#3a3a3a',
      boxShadow: isDark ? '0 0 0 1px #3b404f' : '0 0 0 1px #3a3a3a',
      outline: 'none',
    },
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: isDark ? 'white' : '#3a3a3a',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: isDark ? '#858585' : '#858585',
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: isDark ? '#9fa4b3' : '#9fa4b3',
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    backgroundColor: isDark ? 'white' : '#3a3a3a',
    display: 'none',
  }),
  menu: (styles: any) => ({ ...styles, zIndex: '30' }),
});
type AllFilters = {
  sectors: Array<object>;
  industries: Array<object>;
  marketCap: Array<object>;
};

export const FilterTabs: Tabs = {
  overview: {
    title: 'Overview',
    filters: ['sectors', 'industries', 'marketCap', 'enterprise'],
  },
  valuation: {
    title: 'Valuation',
    filters: ['peTTM', 'pegFWD', 'psTTM', 'pbTTM'],
  },
  financials: {
    title: 'Financials',
    filters: ['rg5', 'eps5', 'gross', 'operating'],
  },
  performance: {
    title: 'Performance',
    filters: ['oneM', 'threeM', 'oneY', 'fiveY'],
  },
};

type Tabs = {
  [key: string]: { title: string; filters: Array<string> };
};

const isTabSelected = (selectedTab: string, currentTab: string) =>
  selectedTab === currentTab;
const getTabCount = (allFilters: any, filters: any) => {
  let count = 0;
  filters.forEach((filter: any) => {
    if (allFilters[filter]?.length) {
      count++;
    }
  });
  return count;
};
type Theme = DefaultTheme & { isDark: boolean };
const DefaultFiltersNew: FC<{
  openFilterModal: boolean;
  getPillFilters: Function;
  showFilter: boolean;
  setShowFilter: Function;
}> = ({ openFilterModal, getPillFilters, showFilter, setShowFilter }) => {
  const currentTheme: Theme = useTheme() as Theme;
  const theme: any = currentTheme.isDark ? themeObj.dark : themeObj.light;
  const [selectedTab, setSelectedTab] = useState('overview');
  const [isOpen, setOpen] = useState(true);
  const dispatch = useAppDispatch();
  const { filterValues, appliedFilters } = useAppSelector((state) => ({
    filterValues: state.screenerState.filterValues.data,
    appliedFilters: state.screenerState.appliedFilters,
  }));
  const [componentFilters, setComponentFilters] = useState(appliedFilters);
  useEffect(() => {
    setComponentFilters(appliedFilters);
  }, [appliedFilters]);

  const selectStyles = getSelectStyles(currentTheme.isDark);
  return (
    <Box
      border="1px solid"
      borderColor={theme.boxBorder}
      boxShadow="0 0 16px 0 rgba(50, 53, 76, 0.03)"
      bg={theme.background}
      w="full"
      fontSize="14px"
      mt="4"
    >
      <Flex
        justifyContent="space-between"
        borderBottomColor={theme.dividerColor}
        borderBottomWidth="1px"
        alignItems="center"
        bg={theme.tabBg}
      >
        <Flex>
          {Object.keys(FilterTabs).map((tab: string, index) => {
            const tabObject: { title: string; filters: Array<string> } =
              FilterTabs[tab];
            const isSelected = isTabSelected(tab, selectedTab);
            const tabCount = getTabCount(appliedFilters, tabObject.filters);
            return (
              <Flex
                color={isSelected ? theme.tabTextSelected : theme.tabText}
                minW="10"
                p="4"
                cursor="pointer"
                onClick={() => setSelectedTab(tab)}
                borderBottomColor={isSelected ? theme.tabTextSelected : 'none'}
                bg={isSelected ? theme.tabSelectedBg : theme.tabBg}
                borderBottomWidth={isSelected ? '2px' : 'none'}
                alignItems="center"
                borderX={isSelected ? theme.tabSelectedBorder : 'none'}
              >
                <Box></Box>
                <Box
                  fontWeight={isSelected ? 'bold' : 'medium'}
                  color={isSelected ? theme.tabTextSelected : theme.tabText}
                >
                  {tabObject.title}
                </Box>
                <Box
                  borderColor={
                    tabCount > 0 ? theme.pillSelectedBorder : theme.pillBorder
                  }
                  bg={tabCount > 0 ? theme.pillSelectedBg : theme.pillBg}
                  color={
                    tabCount > 0 ? theme.pillSelectedColor : theme.pillColor
                  }
                  borderWidth="0.5px"
                  px="1"
                  ml="1"
                  fontSize="12px"
                >
                  {tabCount}
                </Box>
              </Flex>
            );
          })}
        </Flex>

        <Flex marginRight="4" fontWeight="bold">
          <Flex
            color={theme.collapseColor}
            cursor="pointer"
            ml="4"
            alignItems="center"
            onClick={() => {
              setShowFilter(true);
            }}
          >
            <AddIcon
              borderColor={theme.addFilterText}
              borderWidth="1px"
              borderRadius="50%"
              padding="1px"
              color={theme.addFilterText}
              height="12px"
              width="12px"
            />
            <Text color={theme.addFilterText} ml="1">
              New Filter
            </Text>
          </Flex>
          <Flex
            color={theme.collapseColor}
            cursor="pointer"
            ml="4"
            alignItems="center"
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            <Text ml="1">{isOpen ? 'Collapse' : 'Expand'}</Text>
          </Flex>
        </Flex>
      </Flex>
      <SlideFade
        offsetY="20px"
        in={isOpen}
        reverse
        transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}
      >
        {isOpen ? (
          <Flex
            height={isOpen ? 'auto' : '0px'}
            flexDirection="column"
            opacity={isOpen ? '1' : '0'}
          >
            <Flex p="4" alignItems="flex-end" justifyContent="space-between">
              {(getFilters(
                (filterValues as unknown) as AllFilters,
                selectedTab
              ) as Array<Object>)
                .slice(0, 4)
                .map((filter: any) => (
                  <Flex
                    flex="1"
                    flexDirection="column"
                    mr="2"
                    data-testid={filter.testId}
                    key={filter.property}
                  >
                    <Text color={theme.selectText} fontSize="small">
                      {filter.title}
                    </Text>
                    <Select
                      className="text-sm mt-1"
                      options={filter.options.map((s: any) => ({
                        value: s,
                        label: s.title ?? s,
                      }))}
                      value={componentFilters[filter.property]?.[0] ?? null}
                      styles={selectStyles}
                      isClearable={true}
                      onChange={(selected: any) => {
                        const fitlersToApply = {
                          ...componentFilters,
                          [filter.property]: selected ? [selected] : [],
                        };

                        setComponentFilters(fitlersToApply);
                        //dispatch(updateFilters(fitlersToApply));
                      }}
                    />
                  </Flex>
                ))}
            </Flex>
            <Flex p="4">
              <Button
                color={theme.applyBtnBg}
                borderColor={theme.applyBtnBg}
                borderWidth="1px"
                bg="transparent"
                _hover={{
                  bg: theme.applyBtnHover,
                  color: 'white',
                }}
                onClick={() => {
                  setComponentFilters({});
                  dispatch(resetFilters());
                }}
              >
                Reset
              </Button>
              <Button
                color={theme.applyBtnColor}
                bg={theme.applyBtnBg}
                _hover={{
                  bg: theme.applyBtnHover,
                  color: 'white',
                }}
                ml="4"
                onClick={() => dispatch(updateFilters(componentFilters))}
              >
                Apply
              </Button>
            </Flex>
          </Flex>
        ) : null}
      </SlideFade>
      <FilterModalNew open={showFilter} setOpen={setShowFilter} theme={theme}>
        <NewFilters
          theme={theme}
          selectStyles={getSelectStyles(currentTheme.isDark)}
          applyFilter={(selectedFilters: any) => {
            setShowFilter(false);
            dispatch(
              updateFilters({
                ...updateFilters,
                ...selectedFilters,
              })
            );
          }}
          getPillFilters={getPillFilters}
          filterValues={{}}
          allSelectedFilters={appliedFilters}
          filterOptions={getFilters(
            (filterValues as unknown) as AllFilters,
            'all'
          )}
        />
      </FilterModalNew>
    </Box>
  );
};

export default DefaultFiltersNew;
