import { Avatar, Box, Flex, Tag, TagLabel, Text } from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export function UnlockVerdePlus(props: any) {
  const { darkMode } = props;
  const publicSymbols = useSelector(
    (state: any) => state.symbolState.publicSymbols.data as Array<string>
  );
  return (
    <Flex
      color={darkMode ? '#fff' : 'rgb(51, 51, 51)'}
      alignItems={'center'}
      flexDirection={'column'}
      width={420}
      mx={'auto'}
    >
      <Flex fontWeight={500} fontSize={'lg'}>
        <Text>Unlock the Full Potential of </Text>
        <Text color={darkMode ? '#4adf79' : 'rgb(56, 168, 118)'} ml={1}>
          Verde+
        </Text>
      </Flex>
      <Text my={5} fontSize={'sm'}>
        Upgrade to Verde+ for unrestricted access to in-depth stock & ETF
        research, portfolio tracking & optimization!
      </Text>
      <Flex mt={4}>
        <Text>Check out Fully</Text>
        <Text color={darkMode ? '#4adf79' : 'rgb(56, 168, 118)'} ml={1}>
          Unlocked Equities
        </Text>
      </Flex>
      {publicSymbols?.length > 0 && (
        <Box
          display={{ base: 'block', lg: 'flex' }}
          alignItems={'center'}
          textAlign={'center'}
          mt={5}
        >
          <Box>
            {publicSymbols.map((s) => (
              <Link to={`/stocks/${s}`} className="no-underline text-black">
                <Tag
                  size={'lg'}
                  key={'md'}
                  borderRadius="full"
                  variant="solid"
                  backgroundColor="rgba(172, 210, 177, 0.44)"
                  color={'black'}
                  // fontWeight={'bold'}
                  fontSize={14}
                  fontFamily={'sans-serif'}
                  m={2}
                  _hover={{ cursor: 'pointer' }}
                >
                  <Avatar
                    src={`http://images.verde.finance/${s.split('.')[0]}.png`}
                    size="xs"
                    backgroundColor="transparent"
                    mr={1}
                    p={0.5}
                  />
                  <TagLabel color={'#fff'} fontSize={'sm'} mt={0.75}>
                    {s.split('.')[0]}
                  </TagLabel>
                </Tag>
              </Link>
            ))}
          </Box>
        </Box>
      )}
    </Flex>
  );
}
