import React from 'react';
import {
  BorderedChartContainer,
  KeyTextButton,
} from '../../../StyedComponents';
import { getInsidersChartData } from '../../../../../utils/ChartUtils';
import StackedBarChart from '../../../StackedBarChart';
import InsidersTable from '../../../InsidersTableRevamp';
import { insidersColumns as columns } from '../../../InsidersTable/columns';
import InsidersModal from '../../../InsidersModal';
import SectionHeader from '../../../SectionHeader';
import BulletPoints from '../../../BulletPoints';
import RiskPositives from '../../../RiskPositives';

const InsiderTransactions = ({ data, insights, darkMode }) => {
  const [openInsidersModal, setOpenInsidersModal] = React.useState(false);
  return (
    <>
      <SectionHeader value="Insiders Transaction" className="pb-6" />
      <div style={{ marginTop: '10px' }}>
        <StackedBarChart
          data={getInsidersChartData(data)}
          name="Insiders"
          color="#65e1ee"
        />
      </div>

      <RiskPositives
        positives={insights?.net_insider_transaction?.positives}
        negatives={insights?.net_insider_transaction?.negatives}
      />

      <div className="mt-12">
        <div className="flex justify-end mb-2">
          <KeyTextButton onClick={() => setOpenInsidersModal(true)}>
            View All
          </KeyTextButton>
        </div>
        <InsidersTable
          columns={columns}
          data={data.filter((d, i) => i < 5)}
          darkMode={darkMode}
        />
      </div>
      {openInsidersModal && (
        <InsidersModal
          title="Insiders Transaction"
          data={data}
          open={openInsidersModal}
          setOpen={setOpenInsidersModal}
          columns={columns}
        />
      )}
    </>
  );
};

export default InsiderTransactions;
