import { getPercent } from './number';
import numeral from 'numeral';
import _ from 'lodash';

export const QUARTERLY = 'Q';
export const ANNUALLY = 'Y';
export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const getChartData = (data = [], allowed, period = QUARTERLY) =>
  data
    .filter((d) => d.metric_type === period)
    .map((d) =>
      Object.keys(d)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          if (key === 'evaluated_on') d[key] = new Date(d[key]).getTime();
          obj[key] = d[key];
          return obj;
        }, {})
    )
    .map((item) => Object.values(item));

export const getAreaChartData = (data, allowed, period = QUARTERLY) =>
  getChartData(data, allowed, period).map((item) => [item[0], Number(item[1])]);

export const getStackedAreaChartData = (
  data = [],
  allowed,
  period = QUARTERLY
) => getChartData(data, allowed, period);

export const getAdvancedTimelineChartData = (data, allowed) =>
  data
    .map((d) =>
      Object.keys(d)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          if (key === 'evaluated_on') d[key] = new Date(d[key]).getTime();
          obj[key] = d[key];
          return obj;
        }, {})
    )
    .map((item) => Object.values(item));
//    .reverse();

export const getPresentDateForFutureGrowth = (data) => {
  const presentDate = data.filter((d) => d.timeline === 'PRESENT');
  if (presentDate.length > 0)
    return new Date(presentDate[0].evaluatedOn).getTime();
  return new Date().getTime();
};

export const getTooltipDate = (value, darkMode, mobileMode) => {
  console.log(value);
  const dateColor = darkMode ? '#d8d9e4' : '#3a3a3a';
  const margin = mobileMode ? 14 : 20;
  const fontSize = mobileMode ? 11 : 12;
  return (
    '<div style="margin-top:' +
    margin +
    'px;color:' +
    dateColor +
    ';font-size:' +
    fontSize +
    'px;">' +
    getDateLabel(value) +
    '</div>' +
    '</div>'
  );
};

export const getDateLabel = (value) =>
  MONTHS[new Date(value).getUTCMonth()] +
  ' ' +
  new Date(value).getUTCDate() +
  ', ' +
  new Date(value).getUTCFullYear();

export const getTooltipYearMonth = (value, darkMode, mobileMode) => {
  const dateColor = darkMode ? '#d8d9e4' : '#3a3a3a';
  const margin = mobileMode ? 14 : 20;
  const fontSize = mobileMode ? 11 : 12;
  return (
    '<div style="margin-top:' +
    margin +
    'px;color:' +
    dateColor +
    ';font-size:' +
    fontSize +
    'px;">' +
    MONTHS[new Date(value).getMonth()] +
    ' ' +
    new Date(value).getFullYear() +
    '</div>' +
    '</div>'
  );
};

export const buildTooltipByValue = (
  value,
  color,
  name,
  darkMode,
  mobileMode
) => {
  const valueColor = darkMode ? '#86efaf' : '#5bae7f';
  const nameColor = darkMode ? '#d8d9e4' : '#686868';
  const parentMargin = mobileMode ? 8 : 12;
  const valueFontSize = mobileMode ? 14 : 16;
  const iconSize = mobileMode ? 18 : 20;
  const nameSize = mobileMode ? 13 : 12;
  return (
    '<div style="margin-bottom:' +
    parentMargin +
    'px;font-weight:500;">' +
    '<div style="color:' +
    valueColor +
    ';font-size:' +
    valueFontSize +
    'px;margin-bottom:2px;">' +
    value +
    '</div>' +
    '<div>' +
    '<span style="font-size:' +
    iconSize +
    'px;line-height:1;position:relative;top:1px;color:' +
    color +
    '">\u25CF</span>' +
    '<span style="color:' +
    nameColor +
    ';font-size:' +
    nameSize +
    'px;">' +
    ' &ensp;' +
    name +
    '</span>' +
    '</div></div>'
  );
};

export const buildSummaryChartTooltipByValue = (
  value,
  darkMode,
  mobileMode
) => {
  const valueColor = darkMode ? '#86efaf' : '#5bae7f';
  const parentMargin = mobileMode ? 8 : 12;
  const valueFontSize = mobileMode ? 16 : 20;
  return (
    '<div style="margin-bottom:' +
    parentMargin +
    'px;font-weight:500;">' +
    '<div style="color:' +
    valueColor +
    ';font-size:' +
    valueFontSize +
    'px;margin-bottom:2px;">' +
    value +
    '</div></div>'
  );
};

export const getFormattedCurrencyValueForTooltip = (value) =>
  numeral(value).format('$0.00a').toUpperCase();
export const getFormattedNumberForTooltip = (value) =>
  numeral(value).format('0.00a').toUpperCase();

export const getFormattedCurrencyValueForAxis = (value) =>
  numeral(value).format('$0a').toUpperCase();

export const getFormattedValueForAxis = (value) =>
  numeral(value).format('0a').toUpperCase();

export const buildStockInfoTooltip = (value, name, darkMode, mobileMode) => {
  const valueColor = darkMode ? '#4adf79' : '#5bae7f';
  const nameColor = darkMode ? '#d8d9e4' : '#686868';
  const parentMargin = mobileMode ? 8 : 12;
  const valueFontSize = mobileMode ? 16 : 20;
  const nameSize = mobileMode ? 14 : 14;
  return (
    '<div style="margin-bottom:' +
    parentMargin +
    'px;font-weight:500;">' +
    '<div style="color:' +
    valueColor +
    ';font-size:' +
    valueFontSize +
    'px;margin-bottom:4px;">$' +
    value +
    '</div>' +
    '<div style="margin-bottom:-4px;color:' +
    nameColor +
    ';font-size:' +
    nameSize +
    'px;">' +
    name +
    '</div></div>'
  );
};

export const getInsidersChartData = (data) => {
  const threeYearsAgo = new Date(
    new Date().getFullYear() -
      3 +
      '-' +
      ('0' + (new Date().getMonth() + 1)).slice(-2) +
      '-01'
  ).getTime();

  const filteredData = data.filter(
    (d) =>
      new Date(`${d.transaction_date.slice(0, 8)}01`).getTime() >= threeYearsAgo
  );
  const dataByName = _.groupBy(filteredData, (d) => d.name);
  const names = Object.keys(dataByName);
  return names.map((name) => ({
    name,
    cost: dataByName[name].reduce((prev, next) => {
      const date = new Date(`${next.transaction_date.slice(0, 8)}01`).getTime();
      const transaction = Math.abs(next.shares);
      return { ...prev, [date + '-' + transaction]: next.cost };
    }, {}),
    data: dataByName[name].map((d) => [
      new Date(`${d.transaction_date.slice(0, 8)}01`).getTime(),
      d.transaction_type === 'S' ? -Math.abs(d.shares) : Math.abs(d.shares),
    ]),
  }));
};
