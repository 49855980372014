import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { QUARTERLY, ANNUALLY } from '../../../utils/ChartUtils';
import { connect } from 'react-redux';

const ToggleButton = ({ period, setPeriod, forceBorder, darkMode }) => {
  const StyledButton = styled.button`
    ${tw`font-medium h-9 w-24 focus:outline-none text-sm outline-none cursor-pointer`}
    background-color: ${(props) =>
      props.selected
        ? darkMode
          ? '#393b3f'
          : '#e6f8f0'
        : darkMode
        ? '#161616'
        : '#f8f8f8'};
    color: ${(props) =>
      props.selected ? (darkMode ? 'white' : 'black') : 'gray'};

    border: 0.5px solid
      ${(props) =>
        props.selected ? (darkMode ? 'white' : '#36a867') : 'transparent'};
    font-weight: ${(props) => (props.selected ? 'bold' : '')};
    border-left: ${(props) => (props.first ? '1.5' : '1.5')};
    border-top-right-radius: ${(props) => (props.first ? '100px' : '150px')};
    border-bottom-right-radius: ${(props) => (props.selected ? '0px' : '15px')};
    border-top-left-radius: ${(props) => (props.selected ? '15px' : '0px')};
    border-bottom-left-radius: ${(props) => (props.selected ? '15px' : '0px')};
    border-right: ${(props) => (props.first ? '1.5' : '1.5')};
    border-radius: 15px;
    font-size: 15px;
    /* opacity: ${(props) => (props.selected ? '1' : '0.5')}; */
  `;

  return (
    <div className="flex">
      <div>
        <StyledButton
          key={0}
          first={true}
          forceBorder={forceBorder}
          selected={period === QUARTERLY}
          onClick={() => setPeriod(QUARTERLY)}
        >
          Quarterly
        </StyledButton>
      </div>
      <div>
        <StyledButton
          key={1}
          first={false}
          selected={period === ANNUALLY}
          onClick={() => setPeriod(ANNUALLY)}
          forceBorder={forceBorder}
        >
          Annually
        </StyledButton>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(ToggleButton);
