import React, { useEffect, useState } from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import defaultPic from '../../../../public/images/default-profile-picture.jpg';
import {
  TriangleUpIcon,
  TriangleDownIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons';
import HoldingsImages from '../../../shared/HoldingsImages';
import { PLAID_GENERATE_TOKEN } from '../../../../config/default';
import WrappedAxios from '../../../../utils/WrappedAxios';
import { useHistory } from 'react-router-dom';
import clevertap from 'clevertap-web-sdk';
import { getMyVerde } from '../../../../actions/my-verde';
import { connect } from 'react-redux';
import LoadingIndicator from '../../../shared/LoadingIndicator';

const PortfolioModal = ({
  closeModal,
  data,
  darkMode,
  profileData,
  onUnlink,
  socialEmail,
  socialUsername,
}) => {
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [error, setError] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [unlink, setUnlink] = useState(false);
  const [chosenPortfolio, setChosenPortfolio] = useState(null);
  const [portfolioData, setPortfolioData] = useState(data);
  let inputString = '';
  const styles = {
    mainModel: {
      position: 'fixed',
      zIndex: 10,
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      padding: 20,
      overflow: 'auto',
      backgroundColor: 'rgb(0,0,0)',
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    modalContent: {
      margin: isMobile ? '35% auto' : '7% auto',
      padding: '20',
      borderRadius: '8px',
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      alignItems: 'center',
    },
  };

  useEffect(() => {
    setPortfolioData(data);
  }, [data]);

  function redirectToHome() {
    history.replace('/');
  }

  function listPortfolios() {
    let resultArray = [];
    const obj = portfolioData[0];

    for (let portfolioCompanyName in obj) {
      for (let portfolio in obj[portfolioCompanyName]) {
        let resultObj = obj[portfolioCompanyName][portfolio]['account_details'];
        resultObj = {
          portfolioCompanyName: portfolioCompanyName,
          portfolioId: portfolio,
          portfolioName: `${portfolioCompanyName}: ${obj[portfolioCompanyName][portfolio]['account_details']['account_name']} - ${obj[portfolioCompanyName][portfolio]['account_details']['account_number']}`,
          portfolioValue:
            obj[portfolioCompanyName][portfolio]['account_details'][
              'portfolio_value'
            ],
          portfolioPercent: parseFloat(
            (obj[portfolioCompanyName][portfolio]['account_details'][
              'portfolio_profit_loss'
            ] /
              obj[portfolioCompanyName][portfolio]['account_details'][
                'portfolio_invested_amount'
              ]) *
              100
          ).toFixed(2),
          linkError:
            obj[portfolioCompanyName][portfolio]['account_details'][
              'link_error'
            ],
          linkId:
            obj[portfolioCompanyName][portfolio]['account_details']['link_id'],
          holdings: [
            ...obj[portfolioCompanyName][portfolio]['account_holdings'],
          ],
          ...resultObj,
        };
        resultArray.push(resultObj);
      }
    }

    return resultArray;
  }

  return (
    <div style={styles.mainModel}>
      <div
        style={{
          ...styles.modalContent,
          backgroundColor: darkMode ? '#161616' : '#f4f4f4',
          padding: 20,
          width: isMobile ? '95%' : '60%',
          height: isMobile ? '80%' : confirmStatus ? 310 : 470,
        }}
      >
        {listPortfolios().length > 0 ? (
          <>
            <Text
              fontWeight="549"
              fontSize={22}
              color={darkMode ? 'white' : 'black'}
            >
              Manage Portfolios
            </Text>
            <Flex alignItems="center" mt={5}>
              <img
                className={`w-${isMobile ? 11 : 12} h-${
                  isMobile ? 11 : 12
                } mr-2 cursor-pointer`}
                style={{ borderRadius: 25 }}
                src={
                  profileData && profileData['profile_pic_url'] !== null
                    ? profileData['profile_pic_url']
                    : defaultPic
                }
                alt=""
              />

              <Box ml={2}>
                <Text
                  fontWeight="medium"
                  fontSize={18}
                  color={darkMode ? 'white' : 'black'}
                >
                  {profileData && profileData['name']}
                </Text>
                <Text fontWeight="medium" fontSize={14} color={'gray'}>
                  {listPortfolios().length} portfolios linked
                </Text>
              </Box>
            </Flex>
            {!confirmStatus ? (
              <Box overflowY="scroll" height={270} mt={2}>
                {listPortfolios().map((elem) => {
                  return (
                    <Flex
                      m={3}
                      backgroundColor={darkMode ? '#262626' : 'white'}
                      p={2}
                      paddingTop={3}
                      paddingBottom={3}
                      borderRadius={5}
                    >
                      <Box>
                        <Text
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          inlineSize={isMobile ? '150px' : '280px'}
                          overflow="hidden"
                          style={{ color: darkMode ? 'white' : 'black' }}
                        >
                          {elem['portfolioName']}
                        </Text>
                        <Flex flexDirection={isMobile ? 'column' : 'row'}>
                          <Text
                            fontWeight="bold"
                            style={{ color: darkMode ? 'white' : 'black' }}
                          >
                            $
                            {parseFloat(
                              elem['portfolioValue']
                            ).toLocaleString()}
                          </Text>
                          <Flex alignItems="center" ml={2}>
                            {elem['portfolioPercent'] > 0 ? (
                              <TriangleUpIcon
                                color={darkMode ? '#4adf79' : '#01874e'}
                              ></TriangleUpIcon>
                            ) : (
                              <TriangleDownIcon
                                color={darkMode ? '#ec5b55' : '#BF2F2C'}
                              ></TriangleDownIcon>
                            )}
                            <Text
                              style={{
                                color:
                                  elem['portfolioPercent'] > 0
                                    ? darkMode
                                      ? '#4adf79'
                                      : '#01874e'
                                    : darkMode
                                    ? '#ec5b55'
                                    : '#BF2F2C',
                                marginLeft: '3px',
                                fontWeight: '600',
                              }}
                            >
                              {elem['portfolioPercent']}%
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>

                      {!isMobile && (
                        <Box ml="auto" mr="auto">
                          <HoldingsImages
                            holdings={
                              elem['holdings'].length > 0
                                ? elem['holdings']
                                : []
                            }
                          />
                        </Box>
                      )}

                      <Box
                        ml={isMobile ? 3 : 'auto'}
                        justifyContent="center"
                        alignItems="center"
                        mt="auto"
                        mb="auto"
                        mr={isMobile ? 0 : 2}
                      >
                        <Text
                          style={{
                            color: elem.linkError
                              ? '#efb041'
                              : darkMode
                              ? '#4adf79'
                              : '#01874e',
                            cursor: elem.linkError ? 'pointer' : '',
                            textDecoration: elem.linkError ? 'underline' : '',
                            textUnderlineOffset: elem.linkError ? 3 : 0,
                          }}
                        >
                          {elem.linkError ? 'Relink' : 'Linked'}
                        </Text>
                        <Text
                          style={{
                            color: darkMode ? '#ec5b55' : '#BF2F2C',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            textUnderlineOffset: 3,
                          }}
                          onClick={() => {
                            setConfirmStatus(true);
                            setChosenPortfolio(elem);
                          }}
                        >
                          Unlink Portfolio
                        </Text>
                      </Box>
                    </Flex>
                  );
                })}
              </Box>
            ) : (
              <Box
                backgroundColor={darkMode ? '#262626' : 'white'}
                mt={15}
                p={5}
                borderRadius={5}
              >
                <Flex justifyContent="center" alignItems="center">
                  <WarningTwoIcon color="#efb041" width={6} height={6} />
                  <Text ml={2} style={{ color: darkMode ? 'white' : 'black' }}>
                    Are you sure you want to remove{' '}
                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                      "{chosenPortfolio['portfolioName']}"
                    </span>{' '}
                    from your verde account?
                  </Text>
                </Flex>
              </Box>
            )}
            <Flex marginLeft="auto">
              <button
                onClick={() => {
                  if (confirmStatus) {
                    setConfirmStatus(false);
                  } else {
                    closeModal();
                  }
                }}
                style={{
                  padding: 5,
                  width: '120px',
                  color: darkMode ? '#ec5b55' : '#BF2F2C',
                  border: `1.5px solid ${darkMode ? '#ec5b55' : '#BF2F2C'}`,
                  borderRadius: 20,
                  marginTop: 20,
                  fontSize: 15,
                  fontWeight: 550,
                }}
              >
                Cancel
              </button>
              {confirmStatus && (
                <button
                  onClick={async () => {
                    const response3 = WrappedAxios.post(
                      `${PLAID_GENERATE_TOKEN}/unlink-portfolio`,
                      {
                        linkid: chosenPortfolio.linkId,
                      }
                    )
                      .then((res) => {
                        let company = chosenPortfolio.portfolioCompanyName;
                        let id = chosenPortfolio.portfolioId;
                        let dupPort = { ...portfolioData[0] };
                        delete dupPort[company][id];
                        setPortfolioData([{ ...dupPort }]); //filter the chosen portfolio
                        setConfirmStatus(false);
                        onUnlink(chosenPortfolio);
                        clevertap.event.push('Portfolio Unlinked', {
                          Email: socialEmail,
                          Username: socialUsername,
                          Company: chosenPortfolio['portfolioCompanyName'],
                        });
                        redirectToHome();
                      })
                      .catch(() => {});
                  }}
                  style={{
                    padding: 5,
                    width: '120px',
                    color: darkMode ? '#4adf79' : '#01874e',
                    border: `1.5px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                    borderRadius: 20,
                    marginTop: 20,
                    fontSize: 15,
                    marginLeft: 8,
                    fontWeight: 550,
                  }}
                  // after unlinking set the parent's ind data to ind data without the unlinked portfolio
                >
                  Confirm
                </button>
              )}
            </Flex>
          </>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
  socialEmail: state.myVerde.email,
  socialUsername: state.myVerde.username,
});

const mapDispatchToProps = {
  getMyVerde,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioModal);
