import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import {
  getAdvancedTimelineChartData,
  getPresentDateForFutureGrowth,
} from '../../../../../utils/ChartUtils';
import AdvancedTimelineChart from '../../../AdvancedTimelineChart';
import SectionHeader from '../../../SectionHeader';
import RiskPositives from '../../../RiskPositives';

const EarningsPerShareProjection = ({ data, insights }) => {
  const earningsPerSharePositives = insights?.eps?.positives
    ? insights?.eps?.positives
    : [];
  const epsGrowthPositives = insights?.eps_growth?.positives
    ? insights?.eps_growth?.positives
    : [];
  const earningsPerShareNegatives = insights?.eps?.negatives
    ? insights?.eps?.negatives
    : [];
  const epsGrowthNegatives = insights?.eps_growth?.negatives
    ? insights?.eps_growth?.negatives
    : [];

  const chartData = getAdvancedTimelineChartData(data, [
    'eps_average',
    'eps_low',
    'eps_high',
    'eps_actual',
    'evaluated_on',
  ]).map((c) => [c[4], c[3], c[2], c[1], c[0]]);

  return (
    <>
      <SectionHeader
        value="Earnings Per Share Projection"
        className="pb-6"
        infoKey="eps"
      />
      <div style={{ marginTop: '10px' }}>
        <AdvancedTimelineChart
          data={chartData}
          chartVariant="EPS"
          presentDate={getPresentDateForFutureGrowth(data)}
        />
      </div>

      <RiskPositives
        positives={[...earningsPerSharePositives, ...epsGrowthPositives]}
        negatives={[...earningsPerShareNegatives, ...epsGrowthNegatives]}
      />
    </>
  );
};

export default EarningsPerShareProjection;
