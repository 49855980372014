import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { TabList, Tab } from 'react-tabs';
import { RatingValue } from '../RatingCode/style';

export const screenSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const device = {
  mobileS: `(min-width: ${screenSize.mobileS}px)`,
  mobileM: `(min-width: ${screenSize.mobileM}px)`,
  mobileL: `(min-width: ${screenSize.mobileL}px)`,
  tablet: `(min-width: ${screenSize.tablet}px)`,
  laptop: `(min-width: ${screenSize.laptop}px)`,
  laptopL: `(min-width: ${screenSize.laptopL}px)`,
  desktop: `(min-width: ${screenSize.desktop}px)`,
  desktopL: `(min-width: ${screenSize.desktop}px)`,
};

export const Divider = styled.div`
  ${tw`w-full my-6 h-0`};
  border-top: solid 1px ${(props) => props.theme.sectionDivider};
`;

export const SectionDivider = styled(Divider)`
  ${tw`my-12`}
`;

export const MainHeader = styled.header`
  ${tw`text-3xl flex-initial mb-4 font-medium`}
  font-family: 'Roboto';
`;

export const GreenSpan = styled.span`
  color: #58b948;
`;

export const RedSpan = styled.span`
  color: #e8522e;
`;

export const StyledTabList = styled(TabList)`
  ${tw`font-medium whitespace-nowrap overflow-x-scroll md:overflow-x-hidden overflow-y-hidden px-6`}
  font-size: 15px;
  color: ${(props) => props.theme.pageTab.name};
  background-color: ${(props) => props.theme.pageTab.background};
  border: 1px solid ${(props) => props.theme.pageTab.border};
`;

export const StyledTab = styled(Tab)`
  ${tw`inline-block py-3.5 px-3 mr-4 cursor-pointer`};
  &[aria-selected='true'] {
    ${tw`py-2 rounded font-bold`};
    background-color: #36a867;
    color: #fff;
  }
`;

export const StyledButton = styled.button`
  ${tw`inline-block py-2 px-3 mr-4 cursor-pointer rounded-md text-sm font-bold`};
  background-color: #36a867;
  color: white;
  &[aria-selected='true'] {
    ${tw`py-2 rounded font-bold`};
    background-color: #36a867;
    color: #fff;
  }
`;

export const ThemedWrapper = styled.div`
  background-color: ${(props) => props.theme.content.background};
`;

export const Wrapper = styled(ThemedWrapper)`
  background-color: ${(props) => props.theme.content.background};
  border: 1px solid ${(props) => props.theme.content.border};
`;

export const ContentWrapper = styled(Wrapper)`
  ${tw`px-2 py-4 lg:p-6 rounded`}
  box-shadow: ${(props) => props.theme.containerBoxShadow};
`;

export const BorderedContentWrapper = styled(ContentWrapper)`
  border: 1px solid ${(props) => props.theme.sectionBorder};
`;

export const Highlight = styled.div`
  color: ${(props) => props.theme.highlight};
`;

export const Subtle = styled.div`
  color: ${(props) => props.theme.subtle};
`;

export const BorderedSection = styled.div`
  border: 1px solid ${(props) => props.theme.sectionBorder};
`;

export const HollowButton = styled.button`
  ${tw`focus:outline-none rounded font-medium text-sm cursor-pointer`}
`;

export const HollowBorderedButton = styled(HollowButton)`
  border: solid 1px ${(props) => props.theme.news.filterButton.border};
  color: ${(props) => props.theme.content.color};
`;

export const HollowGreenButton = styled(HollowButton)`
  border: solid 1px ${(props) => props.theme.keyHighlight};
  color: ${(props) => props.theme.keyHighlight};
  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.keyHighlight};
  }
`;

export const ColoredContentText = styled.div`
  color: ${(props) => props.theme.content.color};
`;

export const ColoredHeaderText = styled.div`
  color: ${(props) => props.theme.header};
`;

export const GreenText = styled.div`
  color: ${(props) => props.theme.green};
`;

export const KeyTextButton = styled.button`
  ${tw`font-medium focus:outline-none text-center`};
  color: ${(props) => props.theme.keyHighlight};
`;

export const VerticalLine = styled.div`
  ${tw`h-3/4 mx-4`}
  border: solid 1px ${(props) => props.theme.verticalLine};
  background-color: ${(props) => props.theme.verticalLine};
`;

export const HoriontalLine = styled.div`
  ${tw`w-full my-4`}
  border: solid 1px ${(props) => props.theme.verticalLine};
  background-color: ${(props) => props.theme.verticalLine};
`;

export const StyledInput = styled.input`
  ${tw`rounded py-2 px-4 text-sm w-full mt-4 focus:outline-none focus:shadow-md h-12`}
  border: solid 1px ${(props) => props.theme.grayBorder};
`;

export const GreenButton = styled.button`
  ${tw`font-medium text-lg w-full h-12 px-6 mt-6 rounded focus:outline-none hover:shadow-lg text-white`}
  background-color: ${(props) => props.theme.buttonGreen};
`;

export const BorderedChartContainer = styled.div`
  ${tw`rounded`}
  background-color: ${(props) => props.theme.ratioGraph.background};
  border: 1px solid ${(props) => props.theme.ratioGraph.border};
`;

export const BlurredContent = styled.span`
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
`;
