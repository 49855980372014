import React from 'react';
import RatioGraph from '../../../RatioGraph';
import RatioGraphInsights from '../../../RatioGraphInsights';
import SectionHeader from '../../../SectionHeader';

const PriceToBook = ({ data, symbol, insights }) => {
  const ratios = [
    { name: 'Symbol', value: data.symbol },
    { name: 'Sector', value: data.sector },
    { name: 'Market', value: data.market },
  ];

  return (
    <>
      <SectionHeader value="Price to Book (PB)" infoKey="pb" />
      <RatioGraph ratios={ratios} symbol={symbol} suffix="x" />
      <RatioGraphInsights title="PB" insights={insights} />
    </>
  );
};

export default PriceToBook;
