import React, { useState, useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import {
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Button,
} from '@chakra-ui/react';
import { DefaultTheme, useTheme } from 'styled-components';
import {
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
} from '@chakra-ui/icons';

import { searchIcon } from '../HomepageLogin/icons';
import MyPresetsEmptyLight from '../../../public/images/MyPresetsEmptyLight.png';
import MyPresetsEmptyDark from '../../../public/images/MyPresetsEmptyDark.png';
import WrappedAxios from '../../../utils/WrappedAxios';
import { PLAID_USERS_LINK } from '../../../config/default';
import { isProd } from '../../../config/default';

const presets = [
  {
    name: 'My Presets',
    id: 0,
    presets: [
      // {
      //   name:'testPreset 1',
      //   description: 'Hello I AM A TEST PRESET',
      //   filters:[]
      // }
    ],
  },
  {
    name: 'Verde Presets',
    id: 1,
    presets: [
      {
        name: 'Top Gainers',
        description: 'Stocks with positive returns today.',
        filters: [
          {
            property: 'oneD',
            value: {
              value: {
                title: 'Positive (>0%)',
                value: {
                  min_oneday_return: 0,
                },
              },
              label: 'Positive (>0%)',
            },
          },
        ],
      },
      {
        name: 'Top Losers',
        description: 'Stocks with negative returns today.',
        filters: [
          {
            property: 'oneD',
            value: {
              value: {
                title: 'Negative (<0%)',
                value: {
                  max_oneday_return: 0,
                },
              },
              label: 'Negative (<0%)',
            },
          },
        ],
      },
      {
        name: 'Value Stocks',
        description: 'Companies with low valuation multiples.',
        filters: [
          {
            property: 'valueRating',
            value: {
              value: {
                title: 'A',
                value: {
                  value_ratings: "('A', 'B')",
                },
              },
              label: 'A',
            },
          },
          {
            property: 'peTTM',
            value: {
              value: {
                title: '(< 15x)',
                value: {
                  max_peratio: 15,
                },
              },
              label: '(< 15x)',
            },
          },
          {
            property: 'peratio_fwd',
            value: {
              value: {
                title: '(< 15x)',
                value: {
                  max_peratio_fwd: 15,
                },
              },
              label: '(< 15x)',
            },
          },
          {
            property: 'ev_ebit',
            value: {
              value: {
                title: '(< 15x)',
                value: {
                  max_ev_ebit: 15,
                },
              },
              label: '(< 15x)',
            },
          },
          {
            property: 'ev_ebitda',
            value: {
              value: {
                title: '(< 15x)',
                value: {
                  max_ev_ebitda: 15,
                },
              },
              label: '(< 15x)',
            },
          },
        ],
      },
      {
        name: 'Growth Stocks',
        description: 'Companies with high revenue and earnings growth.',
        filters: [
          {
            property: 'growthRating',
            value: {
              value: {
                title: 'A',
                value: {
                  growth_ratings: "('A', 'B')",
                },
              },
              label: 'A',
            },
          },
          {
            property: 'revenue_growth_fy1',
            value: {
              value: {
                title: '(> 20x)',
                value: {
                  min_revenue_growth_fy1: 20,
                },
              },
              label: '(> 20x)',
            },
          },
          {
            property: 'revenue_growth_fy2',
            value: {
              value: {
                title: '(> 20x)',
                value: {
                  min_revenue_growth_fy2: 20,
                },
              },
              label: '(> 20x)',
            },
          },
          {
            property: 'eps_growth_fy1',
            value: {
              value: {
                title: '(> 20x)',
                value: {
                  min_eps_growth_fy1: 20,
                },
              },
              label: '(> 20x)',
            },
          },
          {
            property: 'eps_growth_fy2',
            value: {
              value: {
                title: '(> 20x)',
                value: {
                  min_eps_growth_fy2: 20,
                },
              },
              label: '(> 20x)',
            },
          },
        ],
      },
      {
        name: 'Compounders',
        description: 'Companies with high margins and profitability.',
        filters: [
          {
            property: 'safetyRating',
            value: {
              value: {
                title: 'A',
                value: {
                  safety_ratings: "('A', 'B')",
                },
              },
              label: 'A',
            },
          },
          {
            property: 'gross',
            value: {
              value: {
                title: '(>30%)',
                value: {
                  min_gross_profit_margin: 30,
                },
              },
              label: '(>30%)',
            },
          },
          {
            property: 'ev_ebit',
            value: {
              value: {
                title: '(>15%)',
                value: {
                  min_ev_ebit: 15,
                },
              },
              label: '(>15%)',
            },
          },
          {
            property: 'netMargin',
            value: {
              value: {
                title: '(>15%)',
                value: {
                  min_net_income_margin: 15,
                },
              },
              label: '(>15%)',
            },
          },
          {
            property: 'roa',
            value: {
              value: {
                title: '(>10%)',
                value: {
                  min_return_on_assets: 10,
                },
              },
              label: '(>10%)',
            },
          },
          {
            property: 'roe',
            value: {
              value: {
                title: '(>20%)',
                value: {
                  min_return_on_equity: 20,
                },
              },
              label: '(>20%)',
            },
          },
          {
            property: 'roic',
            value: {
              value: {
                title: '(>20%)',
                value: {
                  min_return_on_invested_capital: 20,
                },
              },
              label: '(>20%)',
            },
          },
        ],
      },
      {
        name: 'Rocket Ships',
        description:
          'Stocks with positive returns over the past 1M, 3M, and 6M.',
        filters: [
          {
            property: 'oneM',
            value: {
              value: {
                title: 'Positive (>0%)',
                value: {
                  min_onemonth_return: 0,
                },
              },
              label: 'Positive (>0%)',
            },
          },
          {
            property: 'threeM',
            value: {
              value: {
                title: 'Positive (>0%)',
                value: {
                  min_threemonths_return: 0,
                },
              },
              label: 'Positive (>0%)',
            },
          },
          {
            property: 'sixM',
            value: {
              value: {
                title: 'Positive (>0%)',
                value: {
                  min_sixmonths_return: 0,
                },
              },
              label: 'Positive (>0%)',
            },
          },
        ],
      },
      {
        name: 'Falling Knives',
        description:
          'Stocks with negative returns over the past 1M, 3M, and 6M.',
        filters: [
          {
            property: 'oneM',
            value: {
              value: {
                title: 'Negative (<0%)',
                value: {
                  max_onemonth_return: 0,
                },
              },
              label: 'Negative (<0%)',
            },
          },
          {
            property: 'threeM',
            value: {
              value: {
                title: 'Negative (<0%)',
                value: {
                  max_threemonths_return: 0,
                },
              },
              label: 'Negative (<0%)',
            },
          },
          {
            property: 'sixM',
            value: {
              value: {
                title: 'Negative (<0%)',
                value: {
                  max_sixmonths_return: 0,
                },
              },
              label: 'Negative (<0%)',
            },
          },
        ],
      },
    ],
  },
];
const themeObj = {
  dark: {
    filters: {
      searchBorder: '',
      searchBg: '#262626',
      headerLink: 'white',
      selectBoxLabelColor: 'white',
      applyFilterBtnBg: '#36a867',
      applyFilterBtnColor: 'white',
      applyFilterBtnBgHover: '#24b552',
      pillBg: '#272C34',
      pillBorder: '#3B404F ',
      pillColor: 'white',
    },
  },
  light: {
    filters: {
      searchBorder: '',
      searchBg: 'white',
      pillBg: '#def4c4',
      pillColor: '#19233c',
    },
  },
};

const InputSearch = ({ themeStyleObj, searchTerm, onFilterChange }) => (
  <InputGroup
    size="lg"
    w={['full']}
    outline="none"
    borderColor={themeStyleObj.filters.searchBorder}
    bg={themeStyleObj.filters.searchBg}
    position="relative"
    borderRadius="20px"
  >
    <InputLeftElement
      mx={2}
      outline="none"
      ml={4}
      transform="translateY(-50%) scale(0.8)"
      w="max"
      justifyContent="end"
      top="50%"
    >
      {searchIcon}
    </InputLeftElement>
    <Input
      type="text"
      outline="none"
      _hover={{ borderColor: '#24b552' }}
      _focus={{ borderColor: 'none', boxShadow: 'none' }}
      color={themeStyleObj.filters.selectBoxLabelColor}
      placeholder="Search"
      borderWidth="0px"
      fontSize="sm"
      size={'lg'}
      value={searchTerm}
      borderRadius="20px"
      onInput={(event) => {
        const target = event.target;
        onFilterChange(target.value);
      }}
    />
  </InputGroup>
);

export function PresetFilters({
  appliedFilters,
  applyFilter,
  onCloseModal,
  darkMode,
  presetCallback,
  detailsPage,
  selectedPresetCallback,
  createNewFilter,
}) {
  const currentTheme = useTheme();
  const theme = currentTheme.isDark ? themeObj.dark : themeObj.light;
  const sidebarTheme = currentTheme.screener_new.sidebar;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(appliedFilters);
  const [selectedPreset, setSelectedPreset] = useState(presets[1].presets[0]);
  const [selectedUserPreset, setSelectedUserPreset] = useState(
    presets[0].presets[0] ? presets[0].presets[0] : ''
  );
  const savePresetFilter = () => {
    const _selectedFilters = { ...selectedFilters };

    detailsPage.id !== 0 &&
      selectedPreset.filters.forEach((filter) => {
        _selectedFilters[filter.property] = [filter.value];
      });
    // console.log(_selectedFilters);
    applyFilter(_selectedFilters);
  };

  const [userCustomPresetsData, setUserCustomPresetsData] = useState([]);
  //console.log(" HELLO " + userCustomPresetsData)

  useEffect(() => {
    if (detailsPage.id === 0) {
      const response = WrappedAxios.get(`${PLAID_USERS_LINK}/screener-preset`)
        .then((response) => {
          presets[0].data = response.data;
          setUserCustomPresetsData(response.data);
        })
        .catch((error) => console.log(error.message));
    }
  }, [detailsPage.id]);

  return (
    <Box
      bg={darkMode ? '#161616' : '#f4f4f4'}
      px="2"
      py="4"
      className={`h-${
        detailsPage.id !== 0 ? (searchTerm === '' ? 'auto' : 'full') : 'full'
      } flex flex-col`}
    >
      <Box mt="2" mx="2">
        <Text color={sidebarTheme.textColor} fontWeight="bold">
          Preset Filters
        </Text>
        <Flex justifyContent="center" my="4">
          <InputSearch
            searchTerm={searchTerm}
            themeStyleObj={theme}
            onFilterChange={setSearchTerm}
          />
        </Flex>
      </Box>

      <Box>
        <button
          onClick={() => {
            presetCallback({});
          }}
        >
          <Flex alignItems="center" mb="3">
            <ChevronLeftIcon color={darkMode ? '#4adf79' : '#01874e'} />
            <Text
              ml="1"
              textTransform="uppercase"
              fontSize="sm"
              fontWeight="semibold"
              color={darkMode ? '#4adf79' : '#01874e'}
            >
              {detailsPage.name}
            </Text>
          </Flex>
        </button>
        {detailsPage.presets.length !== 0 ||
        userCustomPresetsData.length !== 0 ? (
          <>
            {detailsPage.presets
              .filter((elem) => elem.name.toLowerCase().includes(searchTerm))
              .map((preset) => (
                <Flex
                  gap="2"
                  bg={darkMode ? '#262626' : 'white'}
                  p="3"
                  m="3"
                  alignItems="flex-start"
                  borderRadius="5px"
                >
                  <RadioGroup value={selectedPreset.name} mt="1">
                    <Radio
                      borderColor={darkMode ? '#4adf79' : '#01874e'}
                      colorScheme={darkMode ? '#4adf79' : 'green'}
                      value={preset.name}
                      onChange={(e) => setSelectedPreset(preset)}
                    />
                  </RadioGroup>

                  <Flex flexDir="column">
                    <Text color={sidebarTheme.textColor}>{preset.name}</Text>
                    <Text
                      color={sidebarTheme.textColorSecondary}
                      fontSize="small"
                    >
                      {preset.description}
                    </Text>
                  </Flex>
                </Flex>
              ))}

            {detailsPage.id === 0 && (
              <Flex
                gap="2"
                bg={darkMode ? '#262626' : 'white'}
                p="3"
                m="3"
                textAlign="center"
                justifyContent="center"
                cursor="pointer"
                borderRadius="5px"
                onClick={() => {
                  createNewFilter(true);
                }}
              >
                <Text
                  color={sidebarTheme.textColor}
                  style={{ fontWeight: '600' }}
                >
                  {' '}
                  + Create a new preset
                </Text>
              </Flex>
            )}
          </>
        ) : (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '5px',
              textAlign: 'center',
            }}
          >
            <img
              src={darkMode ? MyPresetsEmptyDark : MyPresetsEmptyLight}
              width="200px"
              height="200px"
              style={{ margin: 'auto' }}
            />
            <Text
              color={darkMode ? '#cccccc' : '#8c8c8c'}
              style={{ marginTop: '25px' }}
            >
              You do not have any custom presets at the moment. Go to "Custom
              Filters" to create your own filter now !
            </Text>
          </div>
        )}
      </Box>
      <div
        className="flex-col h-10"
        style={{ marginTop: 'auto', marginLeft: 'auto', marginRight: '8px' }}
      >
        <Button
          borderRadius="20px"
          border="1px"
          borderColor={currentTheme.screener_new.buttonBorderColor}
          bg={darkMode ? '#4adf79' : '#01874e'}
          color={darkMode ? 'black' : 'white'}
          onClick={() => {
            selectedPresetCallback(selectedPreset.name);
            savePresetFilter();
          }}
          pl="40px"
          pr="40px"
          _hover={{
            color: currentTheme.screener_new.primaryButtonColor,
            bg: currentTheme.screener_new.primaryButtonBg,
          }}
          mr="1"
        >
          Apply
        </Button>
        <Button
          border="1px"
          borderColor={darkMode ? '#4adf79' : '#01874e'}
          color={darkMode ? '#4adf79' : '#01874e'}
          bg={'transparent'}
          borderRadius="20px"
          onClick={onCloseModal}
          pl="40px"
          pr="40px"
          ml="1"
        >
          Cancel
        </Button>
      </div>
    </Box>
  );
}

export function PresetFiltersMainMenu({
  appliedFilters,
  applyFilter,
  onCloseModal,
  darkMode,
  presetCallback,
}) {
  const currentTheme = useTheme();
  const theme = currentTheme.isDark ? themeObj.dark : themeObj.light;
  const sidebarTheme = currentTheme.screener_new.sidebar;
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedFilters, setSelectedFilters] = useState(appliedFilters);
  const [selectedPreset, setSelectedPreset] = useState(presets[1].presets[0]);
  const savePresetFilter = () => {
    const _selectedFilters = { ...selectedFilters };
    selectedPreset.filters.forEach((filter) => {
      _selectedFilters[filter.property] = [filter.value];
    });
    // console.log(_selectedFilters);
    applyFilter(_selectedFilters);
  };
  return (
    <Box
      bg={darkMode ? '#161616' : '#f4f4f4'}
      px="2"
      py="4"
      className="h-full flex flex-col"
    >
      <Box mt="2" mx="2">
        <Text color={sidebarTheme.textColor} fontWeight="bold">
          Preset Filters
        </Text>
        <Flex justifyContent="center" my="4">
          <InputSearch
            searchTerm={searchTerm}
            themeStyleObj={theme}
            onFilterChange={setSearchTerm}
          />
        </Flex>
      </Box>

      <Box>
        {presets
          .filter((elem) => elem.name.toLowerCase().includes(searchTerm))
          .filter((elem) => elem.name !== 'My Presets')
          .map((preset) => (
            <Flex
              gap="2"
              bg={darkMode ? '#262626' : 'white'}
              p="3"
              m="3"
              alignItems="center"
              borderRadius="5px"
              cursor="pointer"
              onClick={() => {
                presetCallback(presets[preset.id]);
              }}
            >
              <Text color={sidebarTheme.textColor} fontWeight="600">
                {preset.name}
              </Text>

              <div style={{ marginLeft: 'auto', marginRight: '8px' }}>
                <ChevronRightIcon
                  color={sidebarTheme.textColor}
                  w="8"
                  h="8"
                  onClick={(evt) => {}}
                />
              </div>
            </Flex>
          ))}
      </Box>
      <div
        className="flex-col h-10"
        style={{ marginTop: 'auto', marginLeft: 'auto', marginRight: '8px' }}
      >
        <Button
          border="1px"
          borderColor={darkMode ? '#4adf79' : '#01874e'}
          color={darkMode ? '#4adf79' : '#01874e'}
          bg={'transparent'}
          borderRadius="20px"
          onClick={() => {
            onCloseModal();
            presetCallback({});
          }}
          pl="40px"
          pr="40px"
          mr="1"
          _hover={{
            color: currentTheme.screener_new.primaryButtonColor,
            bg: currentTheme.screener_new.primaryButtonBg,
          }}
        >
          Cancel
        </Button>
        <Button
          borderRadius="20px"
          border="1px"
          borderColor={currentTheme.screener_new.buttonBorderColor}
          bg={darkMode ? '#4adf79' : '#01874e'}
          onClick={() => {
            presetCallback({});
            savePresetFilter();
          }}
          pl="40px"
          pr="40px"
          ml="1"
        >
          Apply Preset
        </Button>
      </div>
    </Box>
  );
}
