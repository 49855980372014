import React from 'react';
import { connect } from 'react-redux';

const Signal = ({ value, darkMode }) => {
  const emptyBarColor = darkMode ? '#454B5A' : '#E3E3E3';
  switch (value) {
    case 'A':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          fill="none"
          viewBox="0 0 33 33"
        >
          <path
            fill="#36A867"
            d="M31.71 33c-.711 0-1.288-.577-1.288-1.29V1.29c0-.713.577-1.29 1.289-1.29S33 .577 33 1.29v30.42c0 .713-.577 1.29-1.29 1.29zm-3.802-1.29V6.51c0-.712-.577-1.29-1.289-1.29s-1.289.578-1.289 1.29v25.2c0 .713.577 1.29 1.29 1.29.711 0 1.288-.577 1.288-1.29zm-5.092 0V11.667c0-.712-.577-1.289-1.289-1.289s-1.289.577-1.289 1.289v20.045c0 .712.577 1.289 1.29 1.289.711 0 1.288-.577 1.288-1.29zm-5.091 0V16.823c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.577-1.288 1.29V31.71c0 .712.576 1.289 1.289 1.289.712 0 1.289-.577 1.289-1.29zm-5.092 0v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.577-1.288 1.29v5.22c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29z"
          />
        </svg>
      );
    case 'B':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          fill="none"
          viewBox="0 0 33 33"
        >
          <path
            fill={emptyBarColor}
            d="M31.71 33c-.711 0-1.288-.577-1.288-1.29V1.29c0-.713.577-1.29 1.289-1.29S33 .577 33 1.29v30.42c0 .713-.577 1.29-1.29 1.29zm-3.802-1.29V6.51c0-.712-.577-1.29-1.289-1.29s-1.289.578-1.289 1.29v25.2c0 .713.577 1.29 1.29 1.29.711 0 1.288-.577 1.288-1.29zm-5.092 0V11.667c0-.712-.577-1.289-1.289-1.289s-1.289.577-1.289 1.289v20.045c0 .712.577 1.289 1.29 1.289.711 0 1.288-.577 1.288-1.29zm-5.091 0V16.823c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.577-1.288 1.29V31.71c0 .712.576 1.289 1.289 1.289.712 0 1.289-.577 1.289-1.29zm-5.092 0v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.577-1.288 1.29v5.22c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29z"
          />
          <path
            fill="#7FA658"
            d="M27.908 31.71V6.51c0-.712-.577-1.29-1.289-1.29s-1.289.578-1.289 1.29v25.2c0 .713.577 1.29 1.29 1.29.711 0 1.288-.577 1.288-1.29zm-5.092 0V11.667c0-.712-.577-1.289-1.289-1.289s-1.289.577-1.289 1.289v20.045c0 .712.577 1.289 1.29 1.289.711 0 1.288-.577 1.288-1.29zm-5.091 0V16.823c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.577-1.288 1.29V31.71c0 .712.576 1.289 1.289 1.289.712 0 1.289-.577 1.289-1.29zm-5.092 0v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.577-1.288 1.29v5.22c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29z"
          />
        </svg>
      );
    case 'C':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="33"
          fill="none"
          viewBox="0 0 29 33"
        >
          <path
            fill={emptyBarColor}
            d="M27.71 33c-.711 0-1.288-.577-1.288-1.29V1.29c0-.713.577-1.29 1.289-1.29S29 .577 29 1.29v30.42c0 .713-.577 1.29-1.29 1.29zm-3.802-1.29V6.51c0-.712-.577-1.29-1.289-1.29s-1.289.578-1.289 1.29v25.2c0 .713.577 1.29 1.29 1.29.711 0 1.288-.577 1.288-1.29zm-5.092 0V11.667c0-.712-.577-1.289-1.289-1.289s-1.289.577-1.289 1.289v20.045c0 .712.577 1.289 1.29 1.289.711 0 1.288-.577 1.288-1.29zm-5.091 0V16.823c0-.712-.577-1.289-1.29-1.289-.712 0-1.289.577-1.289 1.29V31.71c0 .712.577 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29zm-5.092 0v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.289-1.289s-1.29.577-1.29 1.29v5.22c0 .712.578 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29z"
          />
          <path
            fill="#B7BF45"
            d="M18.816 31.71V11.667c0-.712-.577-1.289-1.289-1.289s-1.289.577-1.289 1.289v20.045c0 .712.577 1.289 1.29 1.289.711 0 1.288-.577 1.288-1.29zm-5.091 0V16.823c0-.712-.577-1.289-1.29-1.289-.712 0-1.289.577-1.289 1.29V31.71c0 .712.577 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29zm-5.092 0v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.289-1.289s-1.29.577-1.29 1.29v5.22c0 .712.578 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29z"
          />
        </svg>
      );
    case 'D':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="33"
          fill="none"
          viewBox="0 0 29 33"
        >
          <path
            fill={emptyBarColor}
            d="M27.71 33c-.711 0-1.288-.577-1.288-1.29V1.29c0-.713.577-1.29 1.289-1.29S29 .577 29 1.29v30.42c0 .713-.577 1.29-1.29 1.29zm-3.802-1.29V6.51c0-.712-.577-1.29-1.289-1.29s-1.289.578-1.289 1.29v25.2c0 .713.577 1.29 1.29 1.29.711 0 1.288-.577 1.288-1.29zm-5.092 0V11.667c0-.712-.577-1.289-1.289-1.289s-1.289.577-1.289 1.289v20.045c0 .712.577 1.289 1.29 1.289.711 0 1.288-.577 1.288-1.29zm-5.091 0V16.823c0-.712-.577-1.289-1.29-1.289-.712 0-1.289.577-1.289 1.29V31.71c0 .712.577 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29zm-5.092 0v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.289-1.289s-1.29.577-1.29 1.29v5.22c0 .712.578 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29z"
          />
          <path
            fill="#ECB305"
            d="M13.725 31.71V16.823c0-.712-.577-1.289-1.29-1.289-.712 0-1.289.577-1.289 1.29V31.71c0 .712.577 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29zm-5.092 0v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.289-1.289s-1.29.577-1.29 1.29v5.22c0 .712.578 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29z"
          />
        </svg>
      );
    case 'E':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="33"
          fill="none"
          viewBox="0 0 29 33"
        >
          <path
            fill={emptyBarColor}
            d="M27.71 33c-.711 0-1.288-.577-1.288-1.29V1.29c0-.713.577-1.29 1.289-1.29S29 .577 29 1.29v30.42c0 .713-.577 1.29-1.29 1.29zm-3.802-1.29V6.51c0-.712-.577-1.29-1.289-1.29s-1.289.578-1.289 1.29v25.2c0 .713.577 1.29 1.29 1.29.711 0 1.288-.577 1.288-1.29zm-5.092 0V11.667c0-.712-.577-1.289-1.289-1.289s-1.289.577-1.289 1.289v20.045c0 .712.577 1.289 1.29 1.289.711 0 1.288-.577 1.288-1.29zm-5.091 0V16.823c0-.712-.577-1.289-1.29-1.289-.712 0-1.289.577-1.289 1.29V31.71c0 .712.577 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29zm-5.092 0v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.289-1.289s-1.29.577-1.29 1.29v5.22c0 .712.578 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29z"
          />
          <path
            fill="#E88126"
            d="M8.633 31.71v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.289-1.289s-1.29.577-1.29 1.29v5.22c0 .712.578 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29z"
          />
        </svg>
      );
    case 'F':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="33"
          fill="none"
          viewBox="0 0 29 33"
        >
          <path
            fill={emptyBarColor}
            d="M27.71 33c-.711 0-1.288-.577-1.288-1.29V1.29c0-.713.577-1.29 1.289-1.29S29 .577 29 1.29v30.42c0 .713-.577 1.29-1.29 1.29zm-3.802-1.29V6.51c0-.712-.577-1.29-1.289-1.29s-1.289.578-1.289 1.29v25.2c0 .713.577 1.29 1.29 1.29.711 0 1.288-.577 1.288-1.29zm-5.092 0V11.667c0-.712-.577-1.289-1.289-1.289s-1.289.577-1.289 1.289v20.045c0 .712.577 1.289 1.29 1.289.711 0 1.288-.577 1.288-1.29zm-5.091 0V16.823c0-.712-.577-1.289-1.29-1.289-.712 0-1.289.577-1.289 1.29V31.71c0 .712.577 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29zm-5.092 0v-9.732c0-.712-.577-1.289-1.29-1.289-.711 0-1.288.578-1.288 1.29v9.732c0 .712.577 1.289 1.289 1.289s1.289-.577 1.289-1.29zm-5.092 0v-5.22c0-.712-.577-1.289-1.289-1.289s-1.29.577-1.29 1.29v5.22c0 .712.578 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29z"
          />
          <path
            fill="#DE5D3C"
            d="M3.541 31.71v-5.22c0-.712-.577-1.289-1.289-1.289s-1.29.577-1.29 1.29v5.22c0 .712.578 1.289 1.29 1.289.712 0 1.289-.577 1.289-1.29z"
          />
        </svg>
      );
    default:
      return null;
  }
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(Signal);
