import styled from 'styled-components/macro';
import tw from 'twin.macro';
import {
  ColoredContentText,
  ColoredHeaderText,
  StyledButton,
} from '../../../StyedComponents';

export const SummaryHighlights = styled.div`
  ${tw`grid grid-cols-3 lg:grid-cols-4 gap-0 py-3.5 rounded rounded-t-none lg:shadow-none`};
  background-color: ${(props) =>
    props.theme.companySummary.summaryHighlights.background};
  border-top: 1px solid
    ${(props) => props.theme.companySummary.summaryHighlights.border};
  box-shadow: ${(props) =>
    props.theme.companySummary.summaryHighlights.boxShadow};
`;

export const Box = styled.div`
  ${tw`text-sm p-2`};
  border-right: ${(props) =>
    props.lastBox
      ? 'none'
      : `1px solid ${props.theme.companySummary.summaryHighlights.divider}`};
`;

export const Description = styled.div`
  ${tw`text-sm leading-snug mb-6`};
  color: ${(props) => props.theme.companyPage.description};
`;

export const BoxHeader = styled(ColoredHeaderText)`
  ${tw`mb-1`};
  color: ${(props) => props.theme.companySummary.summaryHighlights.boxHeader};
  font-size: 13px;
`;

export const BoxContent = styled(ColoredContentText)`
  ${tw`font-medium flex lg:flex-row justify-center items-center text-center`};
  color: ${(props) => props.theme.companySummary.summaryHighlights.boxContent};
`;

export const StockSummary = styled(StyledButton)`
  ${tw`hidden lg:inline-block mr-0`};
`;
