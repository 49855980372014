import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import VerdeScoreSvg from '../../../../../public/images/verde-icon.svg';
import { InfoOutlineIcon, TriangleDownIcon } from '@chakra-ui/icons';
import ReadMore from '../../../ReadMore';
import { showErrorAlert } from '../../../../../actions/alertAction';
import SectorIcon from '../../../SectorIcon';
import SymbolLogo from '../../../SymbolLogo';
import DomainTerm from '../../../DomainTerm';
import { useMediaQuery } from '@chakra-ui/react';
import RatingCode from '../../../RatingCode';
import { Description } from './style';
import SectorLogo from '../../../SectorLogo';

const RatingContainer = styled.div`
  ${tw`md:mx-auto md:max-w-min rounded px-2 md:px-6 py-2 md:py-2.5 justify-between my-4`};
  border-top: 1px solid ${(props) => props.theme.rating.border};
  box-shadow: ${(props) => props.theme.rating.boxShadow};
`;

const RatingDivider = styled.div`
  ${tw`mx-2 mb-4 mt-2 md:mx-8`}
  border-left: solid 1px ${(props) => props.theme.rating.divider};
  background-color: ${(props) => props.theme.rating.divider};
`;

const RatingTitle = styled.div`
  ${tw`mt-3 font-medium text-sm`}
  color: ${(props) => props.theme.rating.title};
`;

const Rating = ({ value, title, darkMode }) => (
  <div
    className="px-2 py-4 md:p-4 flex flex-col items-center justify-center"
    style={{
      backgroundColor: darkMode ? '#434243' : '#F9F9F9',
      width: '140px',
      marginTop: '5px',
      borderRadius: '5px',
    }}
  >
    <RatingCode rating={value} />
    <RatingTitle>
      <DomainTerm infoKey={`${title.toLowerCase()}_rating`}>
        <span className="whitespace-nowrap">{title} Rating</span>
      </DomainTerm>
    </RatingTitle>
  </div>
);

function bigValueSuffix(x) {
  if (Math.abs(x) >= 1000000000000) {
    if (x < 0) {
      return `-${(Math.abs(x) / 1000000000000).toFixed([2])}T`;
    }
    return `${(x / 1000000000000).toFixed([2])}T`;
  } else if (Math.abs(x) >= 1000000000) {
    if (x < 0) {
      return `-${(Math.abs(x) / 1000000000).toFixed([2])}B`;
    }
    return `${(x / 1000000000).toFixed([2])}B`;
  } else if (Math.abs(x) >= 1000000) {
    if (x < 0) {
      return `-${(Math.abs(x) / 1000000).toFixed([2])}M`;
    }

    return `${(x / 1000000).toFixed([2])}M`;
  } else if (Math.abs(x) >= 1000) {
    if (x < 0) {
      return `-${(Math.abs(x) / 1000).toFixed([2])}K`;
    }

    return `${(x / 1000).toFixed([2])}K`;
  } else {
    if (x < 0) {
      return `-${Math.abs(x).toFixed(2)}`;
    }

    return `${parseFloat(x).toFixed(2)}`;
  }
}

const ETFOverview = ({
  data,
  darkMode,
  loading,
  error,
  showErrorAlert,
  sid,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTablet] = useMediaQuery('(max-width: 1150px)');

  let overviewData = data.ETF_Data;

  const descRef = React.useRef();
  const [descWidth, setDescWidth] = React.useState(10);
  const [
    openSimilarCompaniesModal,
    setOpenSimilarCompaniesModal,
  ] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    descRef.current && setDescWidth(Math.round(descRef.current.offsetWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    error && showErrorAlert(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <Flex bg={darkMode ? '#262626' : 'white'} p="3" flexDirection="column">
        <Flex>
          <Box>
            <SectorLogo value={overviewData.ETF_Code} size="Large" />
          </Box>
          <Flex flexDirection="column" ml="2">
            <Text fontWeight="bold" color={darkMode ? 'white' : 'black'}>
              {overviewData.ETF_Code}
            </Text>
            <Text fontSize="small" color={darkMode ? 'white' : 'black'}>
              <a href={overviewData.ETF_URL} target="blank">
                {' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    textDecorationStyle: 'solid',
                    textUnderlineOffset: '3px',
                    color: '#0072db',
                  }}
                >
                  {overviewData.Name}{' '}
                </span>
              </a>
            </Text>
          </Flex>
        </Flex>
        <Flex mt="2" gap="4" wrap="wrap">
          <Flex
            bg={darkMode ? '#434243' : '#f4f4f4'}
            px="3"
            py="1"
            alignItems="center"
            justifyContent="space-between"
            gap="2"
            borderRadius="5px"
          >
            <img
              alt="United States"
              className="w-5 h-4"
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg`}
            />
            <Text fontSize="small" color={darkMode ? 'white' : '#595959'}>
              {overviewData.Country}
            </Text>
          </Flex>
          {/* <a href={overviewData.CompanyURL} target="blank">
            <Flex
              bg={darkMode ? '#e8f3f5' : '#e8f3f5'}
              px="3"
              py="1"
              alignItems="center"
              justifyContent="space-between"
              gap="2"
              borderRadius="5px"
            >
              <SectorLogo
                value={overviewData.ETF_Code}
                size="small"
                fill="black"
              />

              <Text fontSize="small" color={darkMode ? 'black' : '#595959'}>
                Company Url -{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#3852e1',
                    fontWeight: 'bold',
                  }}
                >
                  {overviewData.CompanyURL}
                </span>
              </Text>
            </Flex>
          </a>
          <a href={overviewData.ETF_URL} target="blank">
            <Flex
              bg={darkMode ? '#e8f3f5' : '#e8f3f5'}
              px="2"
              py="1"
              alignItems="center"
              justifyContent="space-between"
              gap="1"
              borderRadius="5px"
            >
              <Text fontSize="small" color={darkMode ? 'black' : '#595959'}>
                ETF Url -{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#3852e1',
                    fontWeight: 'bold',
                  }}
                >
                  {overviewData.ETF_URL}
                </span>
              </Text>
            </Flex>
          </a> */}
        </Flex>
        <Flex my="4" gap="3">
          <Flex flexDirection="column" marginRight="48px">
            <Text
              fontWeight="bold"
              fontSize="2xl"
              color={darkMode ? 'white' : 'black'}
            >
              ${overviewData.CurrentPrice}
            </Text>
            <Flex alignItems="center">
              <TriangleDownIcon color="#be2e2c"></TriangleDownIcon>
              <Text color="#be2e2c" fontFamily="Roboto Medium">
                0.14% (-$5.30)
              </Text>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Flex fontWeight="bold" fontSize="2xl" alignItems="center">
              <img src={VerdeScoreSvg} alt="" className="w-4 h-4 mb-0.5 mr-1" />
              <Text color={darkMode ? 'white' : 'black'}>
                {' '}
                {overviewData.verde_score}
              </Text>
            </Flex>
            <Flex alignItems="center" gap="1">
              <Text fontWeight="bold" color={darkMode ? 'white' : 'black'}>
                Verde Score
              </Text>
              <Box>
                <InfoOutlineIcon
                  color="#0072db"
                  height="15px"
                  width="15px"
                ></InfoOutlineIcon>
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <div>
            <h3 style={{ color: darkMode ? '#cccccc' : '#595959' }}>
              Category :{' '}
              <span
                style={{
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                  fontSize: '18px',
                }}
              >
                {overviewData.Category}
              </span>
            </h3>
          </div>

          <div style={{ marginLeft: '30px', marginRight: '30px' }}>
            <h3 style={{ color: darkMode ? '#cccccc' : '#595959' }}>
              Company Name :{' '}
              <a href={overviewData.CompanyURL} target="blank">
                <span
                  style={{
                    color: darkMode ? 'white' : 'black',
                    fontWeight: '620',
                    fontSize: '18px',
                    textDecoration: 'underline',
                    textDecorationStyle: 'solid',
                    color: '#0072db',
                    textUnderlineOffset: '3px',
                  }}
                >
                  {overviewData.CompanyName}
                </span>
              </a>
            </h3>
          </div>
        </div>

        <div
          style={{
            display: isMobile ? 'block' : 'flex',
            marginTop: '10px',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          <div>
            <div style={{ marginBottom: '20px' }}>
              <h3
                style={{
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                  fontSize: '18px',
                }}
              >
                {bigValueSuffix(overviewData.TotalAssets)}
              </h3>
              <h4 style={{ color: darkMode ? '#cccccc' : '#595959' }}>
                Assets Under Management
              </h4>
            </div>

            <div style={{ marginTop: '20px' }}>
              <h3
                style={{
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                  fontSize: '18px',
                }}
              >
                {parseFloat(overviewData.Beta).toFixed(2)}
              </h3>
              <h4 style={{ color: darkMode ? '#cccccc' : '#595959' }}>Beta</h4>
            </div>
          </div>

          <div
            style={{
              marginLeft: !isMobile || !isTablet ? '80px' : '0px',
              marginRight: !isMobile || !isTablet ? '80px' : '0px',
              marginTop: isMobile ? '30px' : '0px',
            }}
          >
            <div style={{ marginBottom: '20px' }}>
              <h3
                style={{
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                  fontSize: '18px',
                }}
              >
                {parseFloat(overviewData.NetExpenseRation).toFixed(2)}%
              </h3>
              <h4 style={{ color: darkMode ? '#cccccc' : '#595959' }}>
                Expense Ratio
              </h4>
            </div>

            <div style={{ marginTop: '20px' }}>
              <h3
                style={{
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                  fontSize: '18px',
                }}
              >
                {overviewData.InceptionDate}
              </h3>
              <h4 style={{ color: darkMode ? '#cccccc' : '#595959' }}>
                Inception Date
              </h4>
            </div>
          </div>
        </div>

        <Flex mt="4" ref={descRef} color="#8c8c8c">
          <Description ref={descRef} data-testid="company-summary-description">
            <ReadMore
              text={overviewData.Description}
              width={descWidth}
              darkMode={darkMode}
            />
          </Description>
        </Flex>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
            flexWrap: 'wrap',
          }}
        >
          {overviewData.value_rating && (
            <Rating
              value={overviewData.value_rating}
              title="Value"
              darkMode={darkMode}
            />
          )}

          {overviewData.growth_rating && (
            <Rating
              value={overviewData.growth_rating}
              title="Growth"
              darkMode={darkMode}
            />
          )}

          {overviewData.safety_rating && (
            <Rating
              value={overviewData.safety_rating}
              title="Safety"
              darkMode={darkMode}
            />
          )}

          {overviewData.profitability_rating && (
            <Rating
              value={overviewData.profitability_rating}
              title="Profitability"
              darkMode={darkMode}
            />
          )}

          {overviewData.dividend_rating && (
            <Rating
              value={overviewData.dividend_rating}
              title="Dividend"
              darkMode={darkMode}
            />
          )}

          {overviewData.momentum_rating && (
            <Rating
              value={overviewData.momentum_rating}
              title="Momentum"
              darkMode={darkMode}
            />
          )}
        </div>
      </Flex>
    </>
  );
};

const mapStateToProps = (state) => ({
  //   data: state.valuationState.data,
  error: state.valuationState.error,
  loading: state.valuationState.loading,
});

const mapDispatchToProps = {
  showErrorAlert: showErrorAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(ETFOverview);
