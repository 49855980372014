export const getSymbolData = (symbol) => ({
  type: 'FETCH_SYMBOL_DATA',
  payload: symbol,
});

export const getStockInfo = (symbol) => ({
  type: 'FETCH_STOCK_INFO',
  payload: symbol,
});

export const getPastPerformanceData = (symbol) => ({
  type: 'FETCH_PASTPERFORMANCE_DATA',
  payload: symbol,
});

export const getValuationData = (symbol, realTime = false) => ({
  type: 'FETCH_VALUATION_DATA',
  payload: symbol,
  realTime,
});

export const getSimilarCompaniesData = (symbol) => ({
  type: 'FETCH_SIMILAR_COMPANIES_DATA',
  payload: symbol,
});

export const getSymbolNames = () => ({
  type: 'FETCH_SYMBOL_NAMES_DATA',
});

export const getFutureGrowthData = (symbol) => ({
  type: 'FETCH_FUTURE_GROWTH_DATA',
  payload: symbol,
});

export const getDividendData = (symbol) => ({
  type: 'FETCH_DIVIDEND_DATA',
  payload: symbol,
});

export const getInsidersData = (symbol) => ({
  type: 'FETCH_INSIDERS_DATA',
  payload: symbol,
});

export const getHoldersData = (symbol, holderType) => ({
  type: 'FETCH_HOLDERS_DATA',
  symbol,
  holderType,
});

export const getSummaryData = (symbol) => ({
  type: 'FETCH_SUMMARY_DATA',
  symbol,
});

export const getRealTimeData = (symbol) => ({
  type: 'FETCH_REAL_TIME',
  symbol,
});

export const getValuationBasic = (symbol) => ({
  type: 'FETCH_VALUATION_BASIC_DATA',
  payload: symbol,
});

export const getSymbolInsightsBasic = (symbol) => ({
  type: 'FETCH_SYMBOL_INSIGHTS_BASIC_DATA',
  payload: symbol,
});


export const getPastPerformancePublicData = (symbol) => ({
  type: 'FETCH_PASTPERFORMANCE_PUBLIC_DATA',
  payload: symbol,
});

export const getValuationPublicData = (symbol, realTime = false) => ({
  type: 'FETCH_VALUATION_PUBLIC_DATA',
  payload: symbol,
  realTime,
});

export const getSimilarCompaniesPublicData = (symbol) => ({
  type: 'FETCH_SIMILAR_COMPANIES_PUBLIC_DATA',
  payload: symbol,
});

export const getFutureGrowthPublicData = (symbol) => ({
  type: 'FETCH_FUTURE_GROWTH_PUBLIC_DATA',
  payload: symbol,
});

export const getDividendPublicData = (symbol) => ({
  type: 'FETCH_DIVIDEND_PUBLIC_DATA',
  payload: symbol,
});

export const getInsidersPublicData = (symbol) => ({
  type: 'FETCH_INSIDERS_PUBLIC_DATA',
  payload: symbol,
});

export const getHoldersPublicData = (symbol, holderType) => ({
  type: 'FETCH_HOLDERS_PUBLIC_DATA',
  symbol,
  holderType,
});

export const getSummaryPublicData = (symbol) => ({
  type: 'FETCH_SUMMARY_PUBLIC_DATA',
  symbol,
});

export const getPublicSymbols = () => ({
  type: 'FETCH_PUBLIC_SYMBOLS_DATA',
});

export const getLoggedInUser = () => ({
  type: 'FETCH_LOGGED_IN_USER_DATA',
});
