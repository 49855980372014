import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  getDateLabel,
  getFormattedCurrencyValueForTooltip,
  getTooltipDate,
} from '../../../utils/ChartUtils';
import numeral from 'numeral';

const StackedBarChart = ({
  data,
  yAxisLabelPrefix = '',
  yAxisLabelSuffix = '',
  darkMode,
}) => {
  function generateTooltip(_this, mobileMode) {
    const point = _this.point;
    let tooltips = '';
    const padding = mobileMode ? 3 : 5;
    tooltips = tooltips.concat(
      '<div style="position:relative;height:100%;padding:' + padding + 'px;"> '
    );

    const isNegative = point.y < 0;
    const valueColor = isNegative
      ? darkMode
        ? '#ff6e66'
        : '#de5d3c'
      : darkMode
      ? '#86efaf'
      : '#36a867';
    const nameColor = darkMode ? '#d8d9e4' : '#686868';
    const parentMargin = 0;
    const valueFontSize = 16;
    const nameSize = 14;

    tooltips = tooltips.concat(
      '<div style="margin-bottom:' +
        parentMargin +
        'px;font-weight:500;">' +
        '<div style="color:' +
        nameColor +
        ';font-size:' +
        nameSize +
        'px;margin-bottom:5px;">' +
        point.series.name +
        '</div>' +
        '<div style="color:' +
        valueColor +
        ';font-size:' +
        valueFontSize +
        'px;margin-bottom:8px;">Shares ' +
        (isNegative ? 'sold: ' : 'purchased: ') +
        numeral(Math.abs(point.y)).format('0,0') +
        '</div>' +
        '<div style="color:' +
        nameColor +
        ';font-size:' +
        (nameSize - 1) +
        'px;margin-bottom:8px;">' +
        getFormattedCurrencyValueForTooltip(
          Number(
            point.series.userOptions.cost[point.x + '-' + Math.abs(point.y)]
          )
        ) +
        '</div>' +
        '<div style="color:' +
        nameColor +
        ';font-size:' +
        (nameSize - 1) +
        'px;margin-bottom:2px;">' +
        getDateLabel(point.x) +
        '</div>' +
        '</div>'
    );
    return tooltips;
  }

  const options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Roboto',
      },
      backgroundColor: darkMode ? 'transparent' : '#ffffff',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      dateTimeLabelFormats: {
        day: '%b %e, %Y',
        week: '%b %e, %Y',
        month: '%b %Y',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
        formatter: function () {
          return (
            yAxisLabelPrefix +
            this.axis.defaultLabelFormatter.call(this) +
            yAxisLabelSuffix
          );
        },
      },
      gridLineColor: darkMode ? '#3b404f' : '#e6e6e6',
      //min: -12000,
      //max: 2000,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        borderRadius: 3,
        borderWidth: 2,
        borderColor: darkMode ? '#272c34' : '#ffffff',
        negativeColor: darkMode ? '#de5f57' : '#f1736b',
        color: darkMode ? '#7dd182' : '#94cb97',
      },
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shadow: false,
      useHTML: true,
      backgroundColor: darkMode ? '#141920' : '#fff',
      borderColor: darkMode ? '#86efaf' : '#5bae7f',
      borderRadius: 8,
      formatter: function () {
        return generateTooltip(this, false);
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            plotOptions: {
              series: {
                //pointWidth: 30,
              },
            },
            tooltip: {
              formatter: function () {
                return generateTooltip(this, true);
              },
            },
            chart: {
              height: 400,
            },
          },
        },
      ],
    },
    series: data,
  };

  const containerRef = React.useRef(null);
  const chartRef = React.useRef(null);
  React.useEffect(() => {
    chartRef.current = Highcharts.chart(containerRef.current, options);
  }, [data, options]);

  return (
    <div ref={containerRef} className="text-xs font-semibold w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
        ref={chartRef}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(StackedBarChart);
