import React from "react";

export const styles = (darkMode , percentVal) => 
{
    return {'text' : {
        color: darkMode ? 'white' : 'black',
        fontSize:18,
        fontWeight:'600'
    },
    'grayText' : {
        color: 'rgb(140,140,140)',
        fontSize:14
    },
    'percentVal' : {
        color: percentVal > 0
        ? darkMode
          ? '#4adf79'
          : '#01874e'
        : darkMode
        ? '#ec5b55'
        : '#BF2F2C',
        fontWeight:'600',
        fontSize:14
    }}
}