import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import { deserialise } from '../mappers/DividendDeserialiser';
import wrappedAxios from '../utils/WrappedAxios';
import axios from 'axios';

function* fetchDividendData(action) {
  yield put({ type: 'FETCH_DIVIDEND_DATA_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .get(`${SYMBOL_BASE_URL}/dividend/${action.payload.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_DIVIDEND_DATA_SUCCESS',
      data: {
        current: deserialise(
          responseData.dividend,
          responseData.dividend_comparison
        ),
        history: responseData.dividend_history,
      },
      symbol: action.payload.toUpperCase(),
    });
  } catch (error) {
    yield put({
      type: 'FETCH_DIVIDEND_DATA_ERROR',
      error: error.message,
    });
  }
}

function* fetchDividendPublicData(action) {
  yield put({ type: 'FETCH_DIVIDEND_DATA_LOADING' });
  try {
    const responseData = yield axios
      .get(`${SYMBOL_BASE_URL}/dividend-public/${action.payload.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_DIVIDEND_DATA_SUCCESS',
      data: {
        current: deserialise(
          responseData.dividend,
          responseData.dividend_comparison
        ),
        history: responseData.dividend_history,
      },
      symbol: action.payload.toUpperCase(),
    });
  } catch (error) {
    yield put({
      type: 'FETCH_DIVIDEND_DATA_ERROR',
      error: error.message,
    });
  }
}

export function* watchFetchingDividendData() {
  yield takeEvery('FETCH_DIVIDEND_DATA', fetchDividendData);
  yield takeEvery('FETCH_DIVIDEND_PUBLIC_DATA', fetchDividendPublicData);
}
