import React from 'react';
import TradingViewWidget from 'react-tradingview-widget';
import {
  TradingViewEmbed,
  widgetType,
  SymbolOverview,
} from 'react-tradingview-embed';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { Flex } from '@chakra-ui/react';
import SectionHeader from '../../../SectionHeader';

function Performance({ darkMode, symbol, etfData }) {
  const Metric = styled.div`
    ${tw`flex flex-col items-center font-medium`};
  `;

  const List = [
    {
      title: '52 Week High',
      value: parseFloat(etfData.ETF_Data['52weekHigh']).toFixed(2),
    },
    {
      title: '52 Week Low',
      value: parseFloat(etfData.ETF_Data['52weekLow']).toFixed(2),
    },
    {
      title: '50D MDA',
      value: parseFloat(etfData.ETF_Data['50D_MA']).toFixed(2),
    },
    {
      title: '200D MDA',
      value: parseFloat(etfData.ETF_Data['200D_MA']).toFixed(2),
    },
  ];

  return (
    <>
      <SectionHeader value="Performance Chart" />

      <SymbolOverview
        widgetProps={{
          symbols: [`${symbol}`],
          valuesTracking: '1',
          colorTheme: darkMode ? 'dark' : 'light',
          // isTransparent:'1',
          backgroundColor: darkMode
            ? 'rgba(38, 38, 38, 0.999)'
            : 'rgba(255, 255, 255, 0)',
          gridLineColor: 'rgba(152, 152, 152, 0.81)',
          lineColor: darkMode ? '#7bbfff' : '#438df7',
          lineWidth: '2',
          topColor: darkMode ? '#7bbfff60' : '#438df760',
          bottomColor: darkMode ? '#7bbfff20' : '#438df720',
        }}
      />

      <Flex justifyContent="flex-center" marginTop="30px" flexWrap="wrap">
        {List.map((elem) => {
          return (
            <Metric>
              <div
                style={{
                  backgroundColor: darkMode ? '#343434' : '#F9F9F9',
                  padding: '10px',
                  width: '120px',
                  textAlign: 'center',
                  margin: 10,
                  marginLeft: 0,
                }}
              >
                <h6
                  style={{
                    fontWeight: 'bold',
                    color: darkMode ? 'white' : 'black',
                  }}
                >
                  {elem.value}
                </h6>
                <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>
                  {elem.title}
                </h6>
              </div>
            </Metric>
          );
        })}
      </Flex>
    </>
  );
}

export default Performance;
