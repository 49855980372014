import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  forwardRef,
  ChakraProps,
  GridItem,
  chakra,
  SimpleGrid,
  AspectRatio,
} from '@chakra-ui/react';
import tw from 'twin.macro';
import Helmet from 'react-helmet';
import { motion, MotionProps } from 'framer-motion';
import { useAuth } from '../../hocs/AuthProvider';
// @ts-ignore
import VisibilitySensor from 'react-lazyload';
import React, { useState, MouseEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import VerdeHeader from '../../shared/VerdeHeader';
import { Container, h1SizeBreakpoints } from '../../../utils/styling';
import 'animate.css';
import wefunder from '../../../public/images/home/wefunder.png';
import discord from '../../../public/images/home/discord.png';
import wefunderIllustration from '../../../public/images/home/wefunder-illustration.png';
import discordIllustration from '../../../public/images/home/discord-illustration.png';
import { setLoginPhase } from '../../../actions/coreAction';

import { box1, box2, box3, leaf, logo } from './icons';
import Footer from '../HomepageLogin/footer';
import { StyledButton } from '../VerdeHomePage/style';
import styled from 'styled-components';
const JoinButton = styled(StyledButton)`
  width: 255px;
  padding: 10px;
  border-radius: 7px;
  font-weight: bold;
`;
export const GreenButton = styled.button`
  ${tw`min-w-max px-6 py-4 my-8 rounded focus:outline-none hover:shadow-lg text-white`}
  background-color: #32a767;
  font-family: Euclid Circular A Bold;
`;
const threeBoxes = [
  {
    title: 'Stock Research',
    logo: box1,
    text:
      'Make stock & ETF research fun & easy. A complete breakdown of ratios, financial strength, and more!',
  },
  {
    title: 'Portfolio Analytics',
    logo: box2,
    text: `Sync your portfolio(s) and learn how you can become a better investor. You should atleast be beating the S&P500 right? `,
  },
  {
    title: 'Verde Social',
    logo: box3,
    text:
      'Never miss out on the hottest market movements ever again! Discussion rooms, DMs & group chats, leaderboards, and more!',
  },
];
const container = {
  hidden: { opacity: 0, y: 100 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 1,
    },
  },
};

const itemAnim = {
  hidden: { opacity: 0, y: 100 },
  show: {
    opacity: 1,
    y: -10,
    transition: {},
  },
};
const motionVariants = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.1,
      staggerChildren: 0.5,
    },
  },
  hidden: {
    opacity: 0,
  },
};
export type MotionBoxProps = Omit<ChakraProps, keyof MotionProps> &
  MotionProps & {
    as?: React.ElementType;
  };

export const MotionBox = motion(
  forwardRef((props, ref) => {
    return <chakra.div ref={ref} {...props} />;
  })
);
const MotionSimpleGrid = motion(SimpleGrid);
const MotionGridItem = motion(GridItem);
const brandColor = '#24b552';
export default function NewHomePage({ setTheme }: { setTheme: Function }) {
  const [title, setTitle] = useState(
    'Change the way you invest forever with Verde'
  );
  const [addScript, setAddScript] = useState(false);
  const dispatch = useDispatch();
  const { authUser, signout } = useAuth();
  const handleSignUp = () => dispatch(setLoginPhase('signup'));

  return (
    <>
      <Box fontFamily="Plus Jakarta Display">
        <VerdeHeader
          setTheme={setTheme}
          auth={authUser ? true : false}
          hiddenSearch
        ></VerdeHeader>
        <Container mt={['10', '44']}>
          <Flex
            justifyContent="space-between"
            flexDir={['column-reverse', 'column-reverse', 'row']}
          >
            <Flex flexDir="column" position="relative">
              <MotionBox
                animate={{
                  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                }}
                initial={{
                  clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
                }}
                transition={{
                  duration: 2,
                  ease: 'anticipate',
                  delay: 0.5,
                }}
                textAlign={['center', 'left']}
              >
                <Box
                  fontSize={h1SizeBreakpoints}
                  fontWeight="bold"
                  lineHeight={['48px', '85px']}
                  maxW="2xl"
                  className=""
                >
                  {title}
                </Box>
                <Box
                  fontSize={['medium', '2xl']}
                  maxW={['56', '96']}
                  mt="8"
                  mx={['auto', '0']}
                >
                  Be the first to explore Verde’s revolutionary platform{' '}
                </Box>
                <Flex mt={4} gap="2">
                  <Button
                    onClick={handleSignUp}
                    bg={brandColor}
                    color="white"
                    _hover={{
                      bg: { brandColor },
                      color: 'white',
                    }}
                    _focus={{ boxShadow: 'none', outline: 'none' }}
                  >
                    Sign Up
                  </Button>
                  <Button
                    as="a"
                    href="https://form.jotform.com/221214528078049"
                    target="_blank"
                    borderColor={brandColor}
                    border="1"
                    type="reset"
                    color="black"
                    _hover={{
                      color: brandColor,
                    }}
                    _focus={{ boxShadow: 'none', outline: 'none' }}
                  >
                    Signup for Beta
                  </Button>
                </Flex>
              </MotionBox>
            </Flex>
            <VisibilitySensor>
              <MotionBox
                initial={{
                  opacity: 0.2,
                  y: -10,
                }}
                animate={{
                  opacity: ['0', '0.4', '0.6', '0.8', '1'],
                  y: 0,
                }}
                transition={{
                  duration: 1,
                  ease: 'easeOut',
                  delay: 1,
                  y: {
                    repeat: Infinity,
                    duration: 1,
                    ease: 'linear',
                    repeatType: 'reverse',
                  },
                  type: 'spring',
                }}
              >
                <Flex>{logo}</Flex>
              </MotionBox>
            </VisibilitySensor>
          </Flex>
        </Container>
        <VisibilitySensor>
          <MotionBox>
            <Container
              maxW="container.xl"
              borderRadius="lg"
              bg="#f2f6f8"
              mt="10"
              p="10"
              borderColor="#d6dce7"
              borderWidth="1px"
            >
              <Center
                fontSize="3xl"
                fontWeight="bold"
                display={['none', 'flex']}
              >
                What we Offer
              </Center>
              <VisibilitySensor>
                <MotionSimpleGrid
                  columns={[null, null, 3]}
                  spacing="8"
                  mt={['0', '6']}
                  variants={motionVariants}
                  initial="hidden"
                  animate="show"
                >
                  {threeBoxes.map((item) => (
                    <VisibilitySensor>
                      <MotionGridItem
                        key={item.title}
                        variants={itemAnim}
                        initial="hidden"
                        animate="show"
                      >
                        <Box>
                          <Flex flex="1" flexDir="column">
                            <Center>{item.logo}</Center>
                            <Center fontWeight="bold" fontSize="2xl" mt="4">
                              {item.title}
                            </Center>
                            <Center textAlign="center" mt="2">
                              {item.text}
                            </Center>
                          </Flex>
                        </Box>
                      </MotionGridItem>
                    </VisibilitySensor>
                  ))}
                </MotionSimpleGrid>
              </VisibilitySensor>
            </Container>
          </MotionBox>
        </VisibilitySensor>
        <Container
          maxW="container.xl"
          borderRadius="lg"
          my={['4', '10']}
          p={['2', '10']}
          position="relative"
        >
          <Box px={['0', '10']} mt={['10', '20']}>
            <Flex flexDir="column">
              <Center fontSize={['initial', 'xl']}>Explore Verde</Center>
              <Center
                fontWeight="bold"
                fontSize={['3xl', '5xl']}
                my="2"
                textAlign="center"
              >
                Want to know more about Verde?
              </Center>
              <Center
                fontWeight="bold"
                fontSize={['3xl', '5xl']}
                color="#01874f"
              >
                Watch this!
              </Center>
            </Flex>
            <Flex
              flexDir="row-reverse"
              transform={['none', 'translateY(30%)']}
              position={['absolute', 'absolute']}
              right={['0', '10%']}
              bottom="0"
              sx={{
                '@media (max-width:768px)': {
                  transform: `scale(0.5) translate(20%,-50%)`,
                },
              }}
            >
              <Box>{leaf}</Box>
            </Flex>
          </Box>
        </Container>
        <VisibilitySensor once>
          <Container maxW={['container.sm', 'container.md']} p={['4', '4']}>
            <AspectRatio
              justifyContent="center"
              alignItems="center"
              borderRadius="lg"
              ratio={16 / 9}
            >
              <>
                <div
                  style={{
                    position: 'absolute',
                  }}
                >
                  <iframe
                    src="https://player.vimeo.com/video/682674533"
                    width="100%"
                    height="100%"
                    allow="autoplay"
                  ></iframe>

                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </>
            </AspectRatio>
          </Container>
        </VisibilitySensor>
      </Box>
      <Container>
        <Flex justifyContent="space-between" mt="40">
          <Flex
            flexDirection="column"
            flex="1"
            alignItems={['center', 'center', 'start']}
          >
            <img
              src={wefunder}
              height="2rem"
              style={{ height: '60px', width: '200px' }}
            />
            <Text
              fontSize="5xl"
              fontWeight="bold"
              textAlign={['center', 'center', 'left']}
            >
              Invest in Verde Finance
            </Text>
            <Text textAlign={['center', 'center', 'left']}>
              Support our campaign on Wefunder and join us on our journey to
              democratise financial education and transform social literacy.
            </Text>
            <JoinButton
              color="#24b552"
              className="text-white mt-10 text-center"
              as="a"
              href="https://wefunder.com/verdefinance"
              target="_blank"
            >
              Support the Campaign
            </JoinButton>
          </Flex>

          <Flex flex="1" display={['none', 'none', 'flex']}>
            <img src={wefunderIllustration} />
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" my="40">
          <Flex flex="1" display={['none', 'none', 'flex']}>
            <img src={discordIllustration} />
          </Flex>
          <Flex
            flexDirection="column"
            flex="1"
            alignItems={['center', 'center', 'start']}
          >
            <img
              src={discord}
              height="2rem"
              style={{ height: '100px', width: '100px' }}
            />
            <Text
              fontSize="5xl"
              fontWeight="bold"
              textAlign={['center', 'center', 'left']}
            >
              Join our community!
            </Text>
            <Text textAlign={['center', 'center', 'left']}>
              Hop on to our Discord Server and join a community of like-minded
              investors.
            </Text>
            <JoinButton
              color="#24b552"
              className="text-white mt-10 text-center"
              as="a"
              href="https://discord.gg/mtfzdBcFf5"
              target="_blank"
            >
              Join Verde Discord
            </JoinButton>
          </Flex>
        </Flex>
      </Container>
      <Footer isDark={false} />
    </>
  );
}
