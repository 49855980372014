import { deserialise } from '../mappers/ScreenerDeserialiser';
import _ from 'lodash';

const defaultRating = [
  { value: 'A', style: { flexBasis: '33%' } },
  {
    value: 'B',
    style: { flexBasis: '33%', justifyContent: 'center' },
  },
  {
    value: 'C',
    style: { flexBasis: '33%', justifyContent: 'flex-end' },
  },
  { value: 'D', style: { flexBasis: '33%' } },
  {
    value: 'E',
    style: { flexBasis: '33%', justifyContent: 'center' },
  },
  {
    value: 'F',
    style: { flexBasis: '33%', justifyContent: 'flex-end' },
  },
];

const getInitialFitlersState = (sectors, industries, ranges) => ({
  sectors: sectors?.sort() || [],
  industries: industries?.sort() || [],
  verdeScore: [0, 100],
  overvaluedOrUndervaluedPercent: [-100, 100],
  marketCap: [Math.floor(ranges.minMarketcap), Math.ceil(ranges.maxMarketcap)],
  priceToEarnings: [
    Math.floor(ranges.minPeRatio),
    Math.ceil(ranges.maxPeRatio),
  ],
  oneWeekReturnPercent: [
    Math.floor(ranges.minOneWeekReturn),
    Math.ceil(ranges.maxOneWeekReturn),
  ],
  oneMonthReturnPercent: [
    Math.floor(ranges.minOneMonthReturn),
    Math.ceil(ranges.maxOneMonthReturn),
  ],
  sixMonthsReturnPercent: [
    Math.floor(ranges.minSixMonthsReturn),
    Math.ceil(ranges.maxSixMonthsReturn),
  ],
  yearToDateReturnPercent: [
    Math.floor(ranges.minytdReturn),
    Math.ceil(ranges.maxytdReturn),
  ],
  oneYearReturnPercent: [
    Math.floor(ranges.minOneyearReturn),
    Math.ceil(ranges.maxOneYearReturn),
  ],
  fiveYearsReturnPercent: [
    Math.floor(ranges.minFiveYearsReturn),
    Math.ceil(ranges.maxFiveYearsReturn),
  ],
  dividendYield: [
    Math.floor(ranges.minDividendYield),
    Math.ceil(ranges.maxDividendYield),
  ],
  growthRating: _.cloneDeep(defaultRating),
  valueRating: _.cloneDeep(defaultRating),
  safetyRating: _.cloneDeep(defaultRating),
});

export const appliedFiltersInitialState = {
  sectors: [],
  industries: [],
  growthRating: new Set(),
  safetyRating: new Set(),
  valueRating: new Set(),
  sortFields: [{ id: 'marketCap', desc: true }],
};

const initialState = {
  data: [],
  pageNo: 0,
  pageSize: 10,
  totalPages: 0,
  totalCount: 0,
  pageloading: false,
  error: null,
  filterValues: {
    loading: false,
    data: {
      sectors: [],
      industries: [],
    },
    error: null,
  },
  appliedFilters: {
    ...appliedFiltersInitialState,
  },
};

const ScreenerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SCREENER_DATA_LOADING': {
      return {
        ...state,
        pageloading: true,
      };
    }

    case 'FETCH_SCREENER_DATA_SUCCESS': {
      return {
        ...state,
        pageloading: false,
        data: action.data.data.map((symbol) => deserialise(symbol)),
        pageSize: action.pageSize,
        totalPages: action.data.total_pages,
        totalCount: action.data.total_count,
      };
    }

    case 'FETCH_SCREENER_DATA_ERROR': {
      return {
        ...state,
        pageloading: false,
        data: [],
        error: action.error,
      };
    }

    case 'UPDATE_PAGINATION': {
      return {
        ...state,
        pageNo: action.pageNo,
        pageSize: action.pageSize,
      };
    }

    case 'UPDATE_FILTERS': {
      return {
        ...state,
        appliedFilters: {
          ...action.filters,
        },
      };
    }

    case 'RESET_FILTERS': {
      return {
        ...state,
        appliedFilters: {
          ...initialState.appliedFilters,
        },
      };
    }

    case 'FETCH_FILTER_VALUES_LOADING': {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          loading: true,
        },
      };
    }

    case 'FETCH_FILTER_VALUES_SUCCESS': {
      const { sectors, industries, filter } = action.data;
      const ranges = filter.boundaryValues;
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          data: {
            ...state.filterValues.data,
            ...getInitialFitlersState(sectors, industries, ranges),
          },
          loading: false,
        },
      };
    }

    case 'FETCH_FILTER_VALUES_ERROR': {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          error: action.error,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default ScreenerReducer;
