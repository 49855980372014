import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import StockInfo from './StockInfo';
import SectionHeader from '../../../SectionHeader';
import DomainTerm from '../../../DomainTerm';

const Metric = styled.div`
  ${tw`flex flex-col items-center font-medium`};
`;

const MetricHeader = styled.div`
  ${tw`text-sm`};
  color: ${(props) => props.theme.metrics.header};
`;

const MetricValue = styled.div`
  ${tw`text-base`};
  color: ${(props) => props.theme.metrics.value};
`;

const StockInformation = ({ data, valuationData, darkMode }) => {
  const chartData = data
    .map((raw) =>
      Object.keys(raw)
        .filter((key) => ['price_on_date', 'adjusted_price'].includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {})
    )
    .map((item) => Object.values(item))
    .map((item) => [new Date(item[0].split(' ')[0]).getTime(), item[1]])
    .sort((a, b) => a[0] - b[0]);

  return (
    <>
      <SectionHeader value="Performance Chart" />

      <div style={{ marginTop: '10px', paddingTop: '8px' }}>
        <StockInfo
          darkMode={darkMode}
          data={chartData}
          growthMetrics={
            valuationData.growthMetrics.filter(
              (metric) => metric.market === 'Company'
            )[0]
          }
        />
      </div>

      <div className="md:flex md:justify-around grid grid-cols-3 gap-5">
        <Metric>
          <div
            style={{
              backgroundColor: darkMode ? '#343434' : '#F9F9F9',
              padding: '10px',
              width: '125px',
              textAlign: 'center',
            }}
          >
            <h6
              style={{
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              ${valuationData.symbol.fiftyTwoWeekHigh}
            </h6>
            <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>52-Week-High</h6>
          </div>
        </Metric>
        <Metric>
          <div
            style={{
              backgroundColor: darkMode ? '#343434' : '#F9F9F9',
              padding: '10px',
              width: '125px',
              textAlign: 'center',
            }}
          >
            <h6
              style={{
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              ${valuationData.symbol.fiftyTwoWeekLow}
            </h6>
            <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>52-Week-Low</h6>
          </div>
        </Metric>
        <Metric>
          <div
            style={{
              backgroundColor: darkMode ? '#343434' : '#F9F9F9',
              padding: '10px',
              width: '125px',
              textAlign: 'center',
            }}
          >
            <h6
              style={{
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              ${valuationData.symbol.earningsPerShare}
            </h6>
            <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>EPS</h6>
          </div>
        </Metric>
        <Metric>
          <div
            style={{
              backgroundColor: darkMode ? '#343434' : '#F9F9F9',
              padding: '10px',
              width: '125px',
              textAlign: 'center',
            }}
          >
            <h6
              style={{
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              {valuationData.symbol.trailingPriceToEarnings}x
            </h6>
            <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>PE</h6>
          </div>
        </Metric>
        <Metric>
          <div
            style={{
              backgroundColor: darkMode ? '#343434' : '#F9F9F9',
              padding: '10px',
              width: '125px',
              textAlign: 'center',
            }}
          >
            <h6
              style={{
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              {valuationData.symbol.forwardYield}%
            </h6>
            <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>
              Dividend Yield
            </h6>
          </div>
        </Metric>
      </div>
    </>
  );
};

export default StockInformation;
