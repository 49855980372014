import React from 'react';
import { connect } from 'react-redux';
import mainImg from '../../../public/images/error/error.webp';
import mainImgD from '../../../public/images/error/error-d.webp';
import { Box, Text, Container, Center } from '@chakra-ui/layout';
import { UnlockVerdePlus } from '../Sections/UnlockVerdePlus';

const pageStyles = {
  light: {
    bg: '#f1f7fb',
    textColor: 'black',
    bgImg: mainImg,
  },
  dark: {
    bg: '#272c34',
    textColor: 'white',
    bgImg: mainImgD,
  },
};

function PremiumRestrictionComponent({ darkMode }) {
  return (
    <Box fontFamily="Plus Jakarta Display">
      <Box
        bg={darkMode ? pageStyles.dark.bg : pageStyles.light.bg}
        minH="100vh"
      >
        <Container mx="auto" maxW="container.xl">
          <Center flexDirection="column">
            <Box mt="40" mx="auto">
              <UnlockVerdePlus darkMode={darkMode} />
            </Box>
          </Center>
        </Container>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(PremiumRestrictionComponent);
