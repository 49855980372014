const initialState = {
  currentSymbol: {
    data: null,
    loading: false,
    error: null,
  },
  symbolNames: {
    data: [],
    loading: false,
    error: null,
  },
  publicSymbols: {
    data: [],
    loading: false,
    error: null,
  },
};

const SymbolReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SYMBOL_DATA_LOADING':
      return {
        ...state,
        currentSymbol: {
          ...state.currentSymbol,
          loading: true,
          data: {},
          error: null,
        },
      };

    case 'FETCH_SYMBOL_DATA_SUCCESS':
      return {
        ...state,
        currentSymbol: {
          ...state.currentSymbol,
          loading: false,
          data: action.data,
        },
      };

    case 'FETCH_SYMBOL_DATA_ERROR':
      return {
        ...state,
        currentSymbol: {
          ...state.currentSymbol,
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case 'FETCH_SYMBOL_NAMES_LOADING':
      return {
        ...state,
        symbolNames: {
          ...state.currentSymbol,
          loading: true,
          data: [],
          error: null,
        },
      };

    case 'FETCH_SYMBOL_NAMES_SUCCESS':
      return {
        ...state,
        symbolNames: {
          ...state.currentSymbol,
          loading: false,
          data: action.data,
        },
      };

    case 'FETCH_SYMBOL_NAMES_ERROR':
      return {
        ...state,
        symbolNames: {
          ...state.currentSymbol,
          loading: false,
          data: [],
          error: action.error,
        },
      };

    case 'FETCH_TOP_MOVERS_SUCCESS':
      return {
        ...state,
        topMovers: action.data,
        topMoversLoading: false,
      };
    case 'FETCH_TOP_MOVERS_LOADING':
      return {
        ...state,
        topMoversLoading: true,
      };

    case 'FETCH_TOP_MOVERS_ERROR':
      return {
        ...state,
        topMoversLoading: false,
      };

    case 'FETCH_PUBLIC_SYMBOLS_LOADING':
      return {
        ...state,
        publicSymbols: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case 'FETCH_PUBLIC_SYMBOLS_SUCCESS':
      return {
        ...state,
        publicSymbols: {
          loading: false,
          data: action.data,
        },
      };

    case 'FETCH_PUBLIC_SYMBOLS_ERROR':
      return {
        ...state,
        publicSymbols: {
          loading: false,
          data: [],
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default SymbolReducer;
