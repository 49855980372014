import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import BulletPoints from '../../../BulletPoints';
import VerdeScoreMeter from '../../../VerdeScoreMeter';
import RatingCode from '../../../RatingCode';
import SectionHeader from '../../../SectionHeader';
import DomainTerm from '../../../DomainTerm';

const RatingContainer = styled.div`
  ${tw`md:mx-auto md:max-w-min rounded px-2 md:px-6 py-2 md:py-2.5 justify-evenly md:justify-center my-4`};
  background-color: ${(props) => props.theme.rating.background};
  border-top: 1px solid ${(props) => props.theme.rating.border};
  box-shadow: ${(props) => props.theme.rating.boxShadow};
`;

const RatingDivider = styled.div`
  ${tw`mx-2 mb-4 mt-2 md:mx-8`}
  border-left: solid 1px ${(props) => props.theme.rating.divider};
  background-color: ${(props) => props.theme.rating.divider};
`;

const RatingTitle = styled.div`
  ${tw`mt-3 font-medium text-sm`}
  color: ${(props) => props.theme.rating.title};
`;

const Rating = ({ value, title }) => (
  <div className="px-2 py-4 md:p-4 flex flex-col items-center justify-center mb-4">
    <RatingCode rating={value} />
    <RatingTitle>
      <DomainTerm infoKey={`${title.toLowerCase()}_rating`}>
        <span className="whitespace-nowrap">{title} Rating</span>
      </DomainTerm>
    </RatingTitle>
  </div>
);

const ValuationSummary = ({ data: { symbol }, insights }) => {
  return (
    <>
      <SectionHeader value="Valuation Summary" />
      <div className="flex flex-col lg:flex-row lg:justify-evenly lg:mt-6 px-2">
        <div className="mx-auto lg:mx-0">
          <VerdeScoreMeter score={symbol.verdeScore} />
        </div>
        {insights && (
          <div className="-mt-12 lg:ml-4">
            <BulletPoints
              title="Positives"
              points={insights.positives}
              isPositive={true}
            />

            <BulletPoints
              title="Risks"
              points={insights.negatives}
              isPositive={false}
            />
          </div>
        )}
      </div>
      {/* <div className="justify-center hidden md:flex">
        <RatingBox title="Growth" rating={symbol.growthRating} />
        <RatingBox title="Safety" rating={symbol.safetyRating} />
        <RatingBox title="Value" rating={symbol.valueRating} />
      </div>
      <div className="grid grid-cols-4 gap-4 md:hidden">
        <RatingBox
          className="col-span-2"
          title="Growth"
          rating={symbol.growthRating}
        />
        <RatingBox
          className="col-span-2"
          title="Safety"
          rating={symbol.safetyRating}
        />
        <RatingBox
          className={`${
            symbol.growthRating && symbol.safetyRating ? 'col-start-2' : ''
          } col-span-2`}
          title="Value"
          rating={symbol.valueRating}
        />
      </div> */}
      <RatingContainer className="hidden md:flex">
        <Rating value={symbol.growthRating} title="Growth" />
        <RatingDivider />
        <Rating value={symbol.safetyRating} title="Safety" />
        <RatingDivider />
        <Rating value={symbol.valueRating} title="Value" />
        <RatingDivider />
        <Rating value={symbol.dividendRating} title="Dividend" />
        <RatingDivider />
        <Rating value={symbol.momentumRating} title="Momentum" />
      </RatingContainer>

      <RatingContainer className="flex md:hidden">
        <Rating value={symbol.growthRating} title="Growth" />
        <RatingDivider />
        <Rating value={symbol.safetyRating} title="Safety" />
        <RatingDivider />
        <Rating value={symbol.valueRating} title="Value" />
      </RatingContainer>

      <RatingContainer
        className="flex md:hidden"
        style={{ marginTop: '-28px' }}
      >
        <Rating value={symbol.dividendRating} title="Dividend" />
        <RatingDivider />
        <Rating value={symbol.momentumRating} title="Momentum" />
      </RatingContainer>
    </>
  );
};

export default ValuationSummary;
