import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import Switch from 'react-switch';
import _ from 'lodash';

const ColName = styled.div`
  ${tw`mx-2 text-sm font-normal`};
  color: ${(props) => (props.checked ? props.theme.content.color : '#9b9b9b')};
  opacity: ${(props) => (props.checked ? '1' : '0.5')};
`;

export const ColumnToggle = styled.div`
  ${tw`h-auto w-64 px-3 py-4 object-contain absolute rounded right-8`}
  background-color: ${(props) => props.theme.content.background};
  border: 1px solid ${(props) => props.theme.content.border};
  /* box-shadow: 0 0 18px 0 #edf1f7; */
`;

export default function TableColumnSwitch(props) {
  const { columns, setColumns } = props;

  const columnChange = (id, checked) => {
    const updatedColumns = _.cloneDeep(columns);
    updatedColumns[id].checked = checked;
    setColumns(updatedColumns);
  };

  return (
    <ColumnToggle>
      {columns.map(
        (column, i) =>
          i !== 0 && (
            <div className="flex flex-row justify-between my-2">
              <div className="flex">
                <ColName checked={column.checked}>{column.name}</ColName>
              </div>
              <Switch
                className="mx-2 mt-1"
                id={column.id}
                checked={column.checked}
                onChange={() => columnChange(i, !column.checked)}
                onColor="#319961"
                handleDiameter={10}
                disabled={column.disabled}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={26}
              />
            </div>
          )
      )}
    </ColumnToggle>
  );
}
