export default class AggregratedMetricCM {
  constructor(build) {
    this._symbol = build._symbol;
    this._sector = build._sector;
    this._market = build._market;
  }

  get symbol() {
    return this._symbol;
  }

  set symbol(symbol) {
    this._symbol = symbol;
  }

  get sector() {
    return this._sector;
  }

  set sector(sector) {
    this._sector = sector;
  }

  get market() {
    return this._market;
  }

  set market(market) {
    this._market = market;
  }

  static get builder() {
    class Builder {
      symbol(symbol) {
        this._symbol = symbol;
        return this;
      }

      sector(sector) {
        this._sector = sector;
        return this;
      }

      market(market) {
        this._market = market;
        return this;
      }

      build() {
        return new AggregratedMetricCM(this);
      }
    }
    return Builder;
  }
}
