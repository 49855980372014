import React, { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import SectionHeader from '../../shared/SectionHeader';
import { useMediaQuery } from '@chakra-ui/react';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import Tiktok from '../../../public/images/socialmedia/tiktok.svg';
import Instagram from '../../../public/images/socialmedia/instagram.svg';
import Twitter from '../../../public/images/socialmedia/twitter.svg';
import Discord from '../../../public/images/socialmedia/discord.svg';
import Youtube from '../../../public/images/socialmedia/youtube.svg';
import { IntegrationModal } from './IntegrationModal';

function SocialAccounts({ darkMode, data, callback }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [showModal, setShowModal] = useState({ shown: false, handle: null });
  const [currentData, setCurrentData] = useState(null);

  const accounts = [
    {
      name: 'Discord',
      image: Discord,
      key: 'discord_handle',
      account:
        data && currentData
          ? currentData['discord_handle']
          : data['discord_handle'],
    },
    {
      name: 'TikTok',
      image: Tiktok,
      key: 'tiktok_handle',
      account:
        data && currentData
          ? currentData['tiktok_handle']
          : data['tiktok_handle'],
    },
    {
      name: 'Youtube',
      image: Youtube,
      key: 'youtube_handle',
      account:
        data && currentData
          ? currentData['youtube_handle']
          : data['youtube_handle'],
    },
    {
      name: 'Instagram',
      image: Instagram,
      key: 'instagram_handle',
      account:
        data && currentData
          ? currentData['instagram_handle']
          : data['instagram_handle'],
    },
    {
      name: 'Twitter',
      image: Twitter,
      key: 'twitter_handle',
      account:
        data && currentData
          ? currentData['twitter_handle']
          : data['twitter_handle'],
    },
  ];
  const separator = () => {
    return (
      <div
        style={{
          height: '0px',
          width: '95%',
          margin: 'auto',
          marginTop: 20,
          marginBottom: 20,
          border: `0.5px solid ${darkMode ? 'rgb(51,51,51)' : '#d9d9d9'}`,
          borderWidth: 0.5,
        }}
      />
    );
  };
  return (
    <Box>
      <SectionHeader value="Social Accounts" />
      <Box marginTop={10}>
        {showModal.shown && (
          <IntegrationModal
            closeModal={() => setShowModal({ shown: false, handle: null })}
            darkMode={darkMode}
            data={data && currentData ? currentData : data}
            currentHandle={showModal.handle}
            onSubmit={(newData) => {
              setCurrentData(newData);
              setShowModal({ shown: false, handle: null });
              callback(newData);
              //callback
            }}
          />
        )}
        {accounts.map((elem) => {
          return (
            <>
              <Flex justifyContent="flex-start">
                <Box>
                  <img
                    className={`w-${isMobile ? 9 : 7} h-${
                      isMobile ? 9 : 7
                    } cursor-pointer`}
                    src={elem.image}
                    alt=""
                  />
                </Box>
                <Box
                  marginLeft={7}
                  marginRight={isMobile ? 0 : 20}
                  width={isMobile ? 60 : 95}
                >
                  <Text
                    fontWeight="semibold"
                    style={{ color: darkMode ? 'white' : '#595959' }}
                  >
                    {elem.name}
                  </Text>
                </Box>
                <Box
                  marginLeft={isMobile ? 0 : 20}
                  onClick={() => setShowModal({ shown: true, handle: elem })}
                >
                  <Text
                    fontWeight="medium"
                    fontSize={elem.account ? 16 : 14}
                    style={{
                      color: darkMode
                        ? 'white'
                        : elem.account
                        ? 'black'
                        : '#8c8c8c',
                      textDecoration: elem.account && 'underline',
                      textDecoration: 'underline',
                      textDecorationStyle: 'solid',
                      textUnderlineOffset: '3px',
                      cursor: 'pointer',
                    }}
                  >
                    {elem.account === null
                      ? 'Add your profile handle'
                      : elem.account}
                  </Text>
                  {/* {elem.account!== null && <Text fontSize={12} marginTop={1} style = {{color:darkMode ? '#cccccc' : '#8c8c8c', cursor:'pointer'}} onClick={() => setShowModal({'shown' : true , 'handle' : elem})}>Change your handle</Text>} */}
                </Box>
              </Flex>

              {separator()}
            </>
          );
        })}
      </Box>
    </Box>
  );
}
export default SocialAccounts;
