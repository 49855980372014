import React from 'react';
import Modal from 'react-modal';
import { KeyTextButton, Divider } from '../StyedComponents';
import { Header, ActionButton, Container } from './style';

const modalStyles = {
  overlay: {
    top: '20px',
    zIndex: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    bottom: '0',
    left: 'auto',
    right: '0',
    height: 'auto',
    maxWidth: '450px',
    padding: '0px',
    top: '50px',
    width: '100%',
    border: 0,
  },
};

const FilterModal = ({
  open,
  setOpen,
  resetAction,
  applyAction,
  children,
  cancelAction = () => {},
}) => {
  const closeModal = () => {
    cancelAction();
    setOpen(false);
  };
  return (
    <Modal isOpen={open} onRequestClose={closeModal} style={modalStyles}>
      <Container className="flex-col h-full overflow-auto">
        <div className="">
          <div className="flex justify-between p-5 mt-4">
            <Header>All filters</Header>
            <KeyTextButton onClick={resetAction}>Reset</KeyTextButton>
          </div>
          <div className="">{children}</div>
        </div>
        <div className="flex-col justify-between">
          <Divider />
          <div className="flex justify-end px-2">
            <ActionButton onClick={closeModal}>Cancel</ActionButton>
            <ActionButton onClick={applyAction}>Apply</ActionButton>
          </div>
          <Divider />
        </div>
      </Container>
    </Modal>
  );
};

export default FilterModal;
