import React, { useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import './public/css/main.scss';
import VerdeHeader from './components/shared/VerdeHeader';
import CompanyPage from './components/pages/CompanyPage';
import { getMyVerde } from './actions/my-verde';
import { getLoggedInUser, getPublicSymbols } from './actions/action';
import {
  setSubscriptionStatus,
  setSubscriptionStatusLoading,
} from './actions/coreAction';
import { useAppDispatch } from './hooks/store';
import Main from './components/pages/Main';
import Revamp from './components/pages/RevampCompanyPage';
import RevampCompanyPublicPage from './components/pages/RevampCompanyPublicPage';
import RevampCompanyPageBasic from './components/pages/RevampCompanyPageBasic';
import ScreenerPage from './components/pages/ScreenerPage';
import './public/css/app.scss';
import Alert from './components/shared/Alert';
import { ThemeProvider } from 'styled-components';
import { LIGHT_THEME } from './components/core/theme';
import PortfolioPage from './components/pages/PortfolioPage';
import LoadingPageIndicator from './components/shared/LoadingPageIndicator';
import { useAuth } from './components/hocs/AuthProvider';
import HomePage from './components/pages/NewHomePage';
import HomePageNew from './components/pages/HomePageNew';
import HomePage1 from './components/pages/HomePageNew/home-page-new1';
import MaintenancePage from './components/pages/MaintenancePage';
import HomePageLogin from './components/pages/HomepageLogin';
import YetAnotherScreener from './components/pages/YetAnotherScreener';
import ErrorBoundary from './components/shared/ErrorBoundary';
import SymbolSummary from './components/pages/SymbolSummary';
import ETFRevamp from './components/pages/ETFRevamp/ETFRevamp';
import MyVerdeRevamp from './components/pages/MyVerdeRevamp/MyVerdeRevamp';
import MyVerdeRevampMobile from './components/pages/MyVerdeRevamp/MyVerdeRevampMobile';
import SuperInvestors from './components/pages/SuperInvestors/SuperInvestors';
import SuperInvestorProfile from './components/pages/SuperInvestors/SuperInvestorProfile';
import SuperInvestorMobile from './components/pages/SuperInvestors/SuperInvestorMobile';
import SymbolHistory from './components/pages/SuperInvestors/Overview/SymbolHistory';
import EditProfile from './components/pages/EditProfile/EditProfile';
import { Box, Text, useMediaQuery } from '@chakra-ui/react';
import UserView from './components/pages/User/UserView';
import clevertap from 'clevertap-web-sdk';
import NotExists from './components/pages/NotExists';
import _ from 'lodash';
import WrappedAxios from './utils/WrappedAxios';
import { USER_SOCIAL_LINK } from './config/default';

const getPremiumRoutes = (setTheme) => [
  <Route exact path="/screener">
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      {/* <ScreenerPage /> */}
      <YetAnotherScreener />
    </ErrorBoundary>
  </Route>,
];

const getAuthRoutes = (setTheme, isMobile) => [
  <Route exact path="/screener">
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      {/* <ScreenerPage /> */}
      <YetAnotherScreener />
    </ErrorBoundary>
  </Route>,
  <Route exact path="/">
    <HomePageLogin setTheme={setTheme} />
  </Route>,
  // <Route exact path={`/profile`}>
  //   <ErrorBoundary setTheme={setTheme} auth={true}>
  //     <VerdeHeader setTheme={setTheme} auth={true} />
  //     <EditProfile />
  //   </ErrorBoundary>
  // </Route>,
  <Route exact path="/portfolio">
    <VerdeHeader setTheme={setTheme} auth={true} />
    <PortfolioPage self={false} />
  </Route>,
  <Route exact path="/myportfolio">
    <VerdeHeader setTheme={setTheme} auth={true} />
    <PortfolioPage self={true} />
  </Route>,
  <Route path="/plaidandroid">
    <>
      <VerdeHeader setTheme={setTheme} auth={true} />
      <NotExists isAndroid={true} />
    </>
  </Route>,
  <Route exact path="/superinvestors/:cik/:symbol">
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      <SymbolHistory />
    </ErrorBoundary>
  </Route>,

  <Route exact path="/superinvestors/:cik">
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      {isMobile ? <SuperInvestorMobile /> : <SuperInvestorProfile />}
    </ErrorBoundary>
  </Route>,

  <Route exact path="/superinvestors">
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      <SuperInvestors />
    </ErrorBoundary>
  </Route>,

  <Route exact path="/etfs/:sid">
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      <ETFRevamp />
    </ErrorBoundary>
  </Route>,

  // <Route exact path={`/:user`}>
  //   <ErrorBoundary setTheme={setTheme} auth={true}>
  //     <VerdeHeader setTheme={setTheme} auth={true} />
  //     <UserView />
  //     {/* {!isMobile ? <MyVerdeRevamp /> : <MyVerdeRevampMobile />} */}
  //   </ErrorBoundary>
  // </Route>,

  <Route exact path={`/myverde/:user`}>
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      <UserView />
      {/* {!isMobile ? <MyVerdeRevamp /> : <MyVerdeRevampMobile />} */}
    </ErrorBoundary>
  </Route>,
  <Route exact path="/stocks-basic/:sid">
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      <RevampCompanyPageBasic />
    </ErrorBoundary>
  </Route>,
  <Route exact path="/stocks/:sid">
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      <Revamp />
    </ErrorBoundary>
  </Route>,
  <Route exact path="/:sid/summary">
    <ErrorBoundary setTheme={setTheme} auth={true}>
      <VerdeHeader setTheme={setTheme} auth={true} />
      <SymbolSummary />
    </ErrorBoundary>
  </Route>,
  <Route>
    <Redirect to="/" />
  </Route>,
];

const getNonAuthRoutes = (setTheme) => [
  <Route exact path="/">
    <HomePage1 setTheme={setTheme} />
  </Route>,
  <Route exact path="/home1">
    <HomePageNew setTheme={setTheme} />
  </Route>,
  // <Route exact path="/home2">
  //   <HomePage2 setTheme={setTheme} />
  // </Route>,
  <Route exact path="/new">
    <HomePage setTheme={setTheme} />
  </Route>,
  <Route exact path="/signin">
    <HomePage1 setTheme={setTheme} />
  </Route>,
  <Route exact path="/signup">
    <HomePage1 setTheme={setTheme} />
  </Route>,
  <Route path="/plaidandroid">
    <VerdeHeader setTheme={setTheme} plaidAndroid={true} />
    <NotExists isAndroid={true} />
  </Route>,
  <Route exact path="/:user">
    <HomePage1 setTheme={setTheme} />
  </Route>,
  <Route exact path="/stocks-basic/:sid">
    <VerdeHeader setTheme={setTheme} auth={false} hiddenSearch hiddenMenu />
    <RevampCompanyPageBasic />
  </Route>,
  <Route exact path="/stocks-public/:sid">
    <VerdeHeader setTheme={setTheme} auth={false} hiddenSearch hiddenMenu />
    <RevampCompanyPublicPage />
  </Route>,
  <Route>
    <Redirect to="/" />
  </Route>,
];

function App({
  error,
  isPageLoading,
  socialUserName,
  email,
  getPublicSymbols,
  getLoggedInUser,
  subscriptionStatus,
  setSubscriptionStatus,
  subscriptionStatusLoading,
  setSubscriptionStatusLoading,
}) {
  const [theme, setTheme] = React.useState(LIGHT_THEME);
  const { authUser } = useAuth();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  useEffect(() => {
    if (socialUserName) {
      clevertap.event.push('On Home Screen', {
        Username: `${socialUserName}`,
        Email: `${email}`,
      });
      // clevertap.profile.push({
      //   "Site": {
      //     "Name": "Kanishk Chaudhary",                  // String
      //     "Identity": `${socialUserName}`,                    // String or number
      //     "Email": "kanishk@verde.finance",               // Email address of the user
      //     "Phone": "4134094913",                 // Phone (with the country code)
      //     "Gender": "M",                           // Can be either M or F
      //     "DOB": new Date(), // Date of Birth. Javascript Date object
      //        // URL to the Image
      //   }
      //  });
    }
  }, [authUser, email, getPublicSymbols, socialUserName]);

  useEffect(() => {
    getPublicSymbols();
    // if (authUser) {
    //   setSubscriptionStatusLoading(true);
    //   WrappedAxios.get(`${USER_SOCIAL_LINK}/subscriptions/status`)
    //     .then(({ data }) => {
    //       setSubscriptionStatus(data);
    //     })
    //     .finally(() => setSubscriptionStatusLoading(false));
    // }
  }, [authUser]);

  useEffect(() => {
    if (!socialUserName) {
      getMyVerde(authUser && authUser.username);
    }
  }, [authUser, socialUserName]);

  /* useEffect(() => {
    if (authUser?.attributes?.sub) {
      getLoggedInUser(authUser.attributes.sub);
    }
  }, []); */

  const routes = useMemo(
    () =>
      authUser ? getAuthRoutes(setTheme, isMobile) : getNonAuthRoutes(setTheme),
    [authUser, isMobile, subscriptionStatus.active]
  );

  routes.unshift(
    <Route exact path="/maintenance">
      <MaintenancePage setTheme={setTheme} />
    </Route>
  );

  return (
    <ThemeProvider theme={theme}>
      <Alert />
      <LoadingPageIndicator open={isPageLoading || subscriptionStatusLoading} />
      <div
        className={`App`}
        style={{ backgroundColor: theme.homeLogin.background }}
      >
        {error != null ? (
          <div>Something went wrong!!</div>
        ) : (
          <Router>{<Switch>{routes.map((route) => route)}</Switch>}</Router>
        )}
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    error: state.symbolState.error,
    isPageLoading: state.coreState.loading,
    socialUserName: state.myVerde.username,
    email: state.myVerde.email,
    subscriptionStatus: state.coreState.subscriptionStatus,
    subscriptionStatusLoading: state.coreState.subscriptionStatusLoading,
  };
};

const mapDispatchToProps = {
  getMyVerde,
  getPublicSymbols,
  getLoggedInUser,
  //setSubscriptionStatus,
  //setSubscriptionStatusLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
