import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import {
  getAdvancedTimelineChartData,
  getPresentDateForFutureGrowth,
} from '../../../../../utils/ChartUtils';
import AdvancedTimelineChart from '../../../AdvancedTimelineChart';
import SectionHeader from '../../../SectionHeader';

const NetIncomeProjection = ({ data, insights }) => {
  const earningsPositives = insights?.earnings?.positives
    ? insights?.earnings?.positives
    : [];
  const earningsGrowthPositives = insights?.earningsGrowth?.positives
    ? insights?.earningsGrowth?.positives
    : [];
  const earningsNegatives = insights?.earnings?.negatives
    ? insights?.earnings?.negatives
    : [];
  const earningsGrowthNegatives = insights?.earningsGrowth?.negatives
    ? insights?.earningsGrowth?.negatives
    : [];

  return (
    <>
      <SectionHeader
        value="Net Income Projection"
        className="pb-6"
        infoKey="net_income"
      />
      <BorderedChartContainer className="py-4">
        <AdvancedTimelineChart
          data={getAdvancedTimelineChartData(data, [
            'earningsAverage',
            'earningsLow',
            'earningsHigh',
            'earningsActual',
            'evaluatedOn',
          ])}
          chartVariant="Net Income"
          presentDate={getPresentDateForFutureGrowth(data)}
        />
      </BorderedChartContainer>
      {(earningsPositives.length > 0 || earningsGrowthPositives.length > 0) && (
        <BulletPoints
          title="Positives"
          points={[...earningsPositives, ...earningsGrowthPositives]}
          isPositive={true}
        />
      )}
      {(earningsNegatives.length > 0 || earningsGrowthNegatives.length > 0) && (
        <BulletPoints
          title="Risks"
          points={[...earningsNegatives, ...earningsGrowthNegatives]}
          isPositive={false}
        />
      )}
    </>
  );
};

export default NetIncomeProjection;
