import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import InsidersTable from '../InsidersTable';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { KeyTextButton, screenSize } from '../StyedComponents';
import { useCurrentWidth } from '../../core/useCurrentWidth';
import DomainIcon from '../DomainIcon';

export const Title = styled.div`
  ${tw`text-lg font-bold`}
  color: ${(props) => props.theme.title};
`;

Modal.setAppElement('#root');

const InsidersModal = ({ open, setOpen, data, columns, darkMode, title }) => {
  const width = useCurrentWidth();

  const modalStyles = {
    overlay: {
      //top: '15px',
      zIndex: 30,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '0',
      margin: 'auto',
      height: width <= screenSize.tablet ? '500px' : '600px',
      maxWidth: 'max-content',
      //overflow: 'hidden',
      padding: width <= screenSize.tablet ? '16px' : '32px',
      border: 0,
      backgroundColor: darkMode ? '#1d212b' : '#fff',
    },
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <Modal isOpen={open} onRequestClose={onCloseModal} style={modalStyles}>
      <div className="flex justify-between items-center my-4">
        <Title>{title}</Title>
        <div className="cursor-pointer" onClick={onCloseModal}>
          <DomainIcon value="close-modal" />
        </div>
      </div>
      <InsidersTable columns={columns} data={data} />
      <div className="flex items-center justify-center mt-4 md:mt-6">
        <div className="mr-2">
          <DomainIcon value="arrow-left" />
        </div>
        <KeyTextButton onClick={onCloseModal}>Back to Insider</KeyTextButton>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsidersModal);
