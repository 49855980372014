import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import {
  ContentWrapper,
  Subtle,
  Highlight,
  ColoredContentText,
} from '../StyedComponents';
import LoadingIndicator from '../LoadingIndicator';
import SymbolLogo from '../SymbolLogo';
import Moment from 'react-moment';

export const NewsDivider = styled.div`
  ${tw`w-full my-4 h-0`};
  border-top: solid 1px ${(props) => props.theme.similarCompanies.divider};
`;

export const NewsTitle = styled.a`
  ${tw`no-underline font-medium`};
`;

export const NewsDotDivider = styled.div`
  background-color: ${(props) => props.theme.subtle};
`;

const RelatedNews = ({ data: news, loading }) => {
  return (
    <div className="mt-8">
      <ContentWrapper className="shadow-md rounded">
        <Highlight className="font-medium mb-4">Related News</Highlight>
        <div className="flex-row">
          {loading ? (
            <LoadingIndicator />
          ) : !news || news.length === 0 ? (
            <Highlight className="text-sm text-center">
              No matching news found.
            </Highlight>
          ) : (
            news.map((newsItem, index) => (
              <React.Fragment key={index}>
                <div className="text-sm font-medium">
                  <NewsTitle target="_blank" href={newsItem.link}>
                    <div className="flex">
                      <SymbolLogo logoUrl={newsItem.thumbnail} />
                      <div className="flex flex-col ml-4 justify-evenly">
                        <ColoredContentText className="font-medium">
                          {newsItem.title}
                        </ColoredContentText>
                        <div className="flex items-center text-xs mt-2">
                          <Subtle>{newsItem.sitename}</Subtle>
                          <NewsDotDivider className="w-1 h-1 rounded-full mx-2 mt-0.5" />
                          <Subtle>
                            <Moment fromNow>{newsItem.createdAt}</Moment>
                          </Subtle>
                        </div>
                      </div>
                    </div>
                  </NewsTitle>
                </div>
                {index !== news.length - 1 && <NewsDivider />}
              </React.Fragment>
            ))
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.symbolNewsState.data,
    loading: state.symbolNewsState.loading,
  };
};

export default connect(mapStateToProps)(RelatedNews);
