import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import {
  Title,
  StyledLink,
  StyledLinkDiv,
  SignUpButton,
  ProfileMenu,
  MenuContainer,
  StyledExternalLink,
} from './style';
import Select from 'react-select';
import { getMyVerde } from '../../../actions/my-verde';
import { useAppDispatch } from '../../../hooks/store';
import { getGtagURL, gtag } from '../../../utils/analytics';
import VerdeLogo from '../../../public/images/verde-logo.svg';
import VerdeLogoWhite from '../../../public/images/verde-logo-white.svg';
import LoginModal from '../LoginModal';
import { useAuth } from '../../hocs/AuthProvider';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  ColoredContentText,
  Subtle,
  HoriontalLine,
  Highlight,
  HollowBorderedButton,
  KeyTextButton,
  ColoredHeaderText,
} from '../StyedComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import DefaultProfilePic from '../../../public/images/default-profile-picture.jpg';
import CancelIcon from '../../../public/images/alert/cancel.svg';
import {
  showPageLoading,
  setLoginPhase,
  setOpenChangePassword,
  resetLoginPhase,
  resetAllState,
  setDarkMode,
  getFrillToken,
  setOpenYourVerdePlanModal,
  setOpenJoinVerdePlanModal,
} from '../../../actions/coreAction';
import { getSymbolNames } from '../../../actions/action';
import ChangePassword from '../ChangePassword';
import ClickedOutside from '../../../components/shared/ClickOutside';
import Modal from 'react-modal';
import Switch from 'react-switch';
import { LIGHT_THEME, DARK_THEME } from '../../core/theme';
import DomainIcon from '../DomainIcon';
import SymbolSearch from '../SymbolSearch';
import SymbolSearchModal from '../SymbolSearchModal';
import { getSelectStyles } from '../Sections/DefaultFiltersNew/default-filters';
import clevertap from 'clevertap-web-sdk';
import _ from 'lodash';
import YourVerdePlanModal from '../YourVerdePlanModal';
import JoinVerdePlanModal from '../JoinVerdePlanModal';
import { Box, Text } from '@chakra-ui/react';
import PortfolioModal from '../../pages/MyVerdeRevamp/Modal/PortfolioModal';
import {
  PLAID_GENERATE_TOKEN,
  USER_SOCIAL_LINK,
} from '../../../config/default';
import WrappedAxios from '../../../utils/WrappedAxios';

const brandColor = '#24b552';
const VerdeHeader = ({
  currentSection = 'Home',
  plaidAndroid = false,
  callback = {},
  showPageLoading,
  isPageLoading,
  setLoginPhase,
  setOpenChangePassword,
  resetAllState,
  darkMode,
  setDarkMode,
  setTheme,
  auth,
  getSymbolNames,
  customTheme = null,
  hiddenSearch,
  getFrillToken,
  portfolioLinked,
  getMyVerde,
  socialUserName,
  socialImg,
  socialEmail,
  hiddenMenu = false,
  subscriptionStatus,
  setOpenYourVerdePlanModal,
  setOpenJoinVerdePlanModal,
}) => {
  const HeaderWrapper = styled.header`
    ${tw`text-white py-4 px-2  fixed inset-x-0 top-0 h-16 md:h-20`}
    background: ${(props) =>
      darkMode ? '#262626' : props.theme.content.background};
    border: solid 1px ${(props) => props.theme.content.border};
  `;

  const dispatch = useAppDispatch();
  const [openMenu, setOpenMenu] = React.useState(false);
  const location = useLocation();
  const [openHamMenu, setOpenHamMenu] = React.useState(false);
  const [openSymbolSearchModal, setOpenSymbolSearchModal] = React.useState(
    false
  );
  const [showPortfolioModal, setShowPortfolioModal] = React.useState(false);
  const [portfolioData, setPortfolioData] = React.useState([]);
  const [profileData, setProfileData] = React.useState({});
  const history = useHistory();
  const { authUser, signout } = useAuth();

  React.useEffect(() => {
    setTheme(darkMode ? DARK_THEME : LIGHT_THEME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  useEffect(() => {
    if (authUser) {
      /* getFrillToken({
        email: authUser.attributes.email,
        name: authUser.attributes.name,
        id: authUser.username,
      }); */
      getMyVerde(authUser.username);
      gtag('config', getGtagURL(), {
        user_id: [
          ...authUser.attributes.name.split(' '),
          authUser.username,
        ].join('_'),
      });

      WrappedAxios.get(`${USER_SOCIAL_LINK}/users/${authUser.username}`)
        .then((response4) => {
          setProfileData(response4.data);
        })
        .catch((error) => {
          console.error(error);
        });

      WrappedAxios.get(
        `${PLAID_GENERATE_TOKEN}/userholdings?all_portfolios=false`
      )
        .then((response2) => {
          setPortfolioData(response2.data);
        })
        .catch((error) => {
          //alert(error.message);
        });
    }
  }, [authUser, getFrillToken, getMyVerde]);

  React.useEffect(() => {
    if (customTheme) {
      setDarkMode(customTheme === 'dark' ? true : false);
    } else {
      const userPreferenceToDarkMode =
        localStorage.getItem('verde-finance-dark-mode-enabled') == 'true';
      setDarkMode(
        auth
          ? userPreferenceToDarkMode
            ? userPreferenceToDarkMode
            : window.matchMedia &&
              window.matchMedia('(prefers-color-scheme: dark)').matches
          : false
      );
    }

    auth && getSymbolNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  React.useEffect(() => {
    isPageLoading && openSymbolSearchModal && setOpenSymbolSearchModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageLoading]);

  const modalStyles = {
    overlay: {
      zIndex: 30,
      //backgroundColor: 'rgba(33, 59, 98, 0.5)',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 'auto',
      height: 'auto',
      maxWidth: '250px',
      padding: '0px',
      width: '100%',
      border: 0,
      backgroundColor: darkMode
        ? DARK_THEME.content.background
        : LIGHT_THEME.content.background,
    },
  };

  const selectStyles = getSelectStyles(darkMode);

  const handleSignin = () => {
    gtag('event', `signin`);
    setOpenHamMenu(false);
    setLoginPhase('signin');
  };

  const handleSignup = () => {
    gtag('event', `sign-up-header`);
    setOpenHamMenu(false);
    setLoginPhase('signup');
  };

  const handleChangePassword = () => {
    setOpenHamMenu(false);
    resetLoginPhase();
    setOpenChangePassword(true);
  };

  const handleSignout = async () => {
    setOpenHamMenu(false);
    showPageLoading(true);
    await signout();
    resetAllState();
    showPageLoading(false);
    setOpenMenu(false);
    history.push('/');
  };

  useEffect(() => {
    location.pathname.includes('signin') && handleSignin();
    location.pathname.includes('signup') && handleSignup();
  }, [handleSignin, handleSignup, location.pathname]);

  const showFeedbackModal = (evt) => {
    evt.preventDefault();
  };

  const countryOptions = [
    {
      value: 'US',
      label: (
        <div className="flex items-center">
          <img
            alt="United States"
            className="w-5 h-4"
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg`}
          />
          <div className="ml-2">US</div>
        </div>
      ),
    },
    {
      value: 'IN',
      label: (
        <div className="flex items-center">
          <img
            alt="United States"
            className="w-5 h-4"
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg`}
          />
          <div className="ml-2">IN</div>
        </div>
      ),
    },
  ];

  const filteredCountryOption = countryOptions.filter(
    (option) => option.value === localStorage.getItem('verdeLocale')
  );
  const countryDefaultOption =
    filteredCountryOption.length > 0
      ? filteredCountryOption[0]
      : countryOptions[0];

  return (
    <HeaderWrapper className="z-30">
      <nav className="container mx-auto flex justify-between">
        <div className="flex items-center xl:w-8/12 lg:w-3/4 w-3/4">
          <Title className="xl:w-2/12 lg:w-2/6 md:w-3/6 sm:w-full flex flex-col">
            <StyledLink to="/">
              <img
                className="w-24 cursor-pointer"
                src={darkMode ? VerdeLogoWhite : VerdeLogo}
                alt="Logo"
                onClick={() => authUser && history.push('/')}
              />
            </StyledLink>
          </Title>
          {!hiddenMenu && (
            <ul
              className={`xl:w-5/12 lg:w-4/6 hidden lg:flex lg:flex-row gap-1 lg:gap-1 ${
                subscriptionStatus.active ? '' : 'justify-center'
              }`}
            >
              {authUser ? (
                <>
                  <li className="flex">
                    <StyledLink
                      to="/"
                      selected={location.pathname === '/'}
                      brandColor={brandColor}
                      onClick={() =>
                        clevertap.event.push('Header Home Navigation', {
                          Email: socialEmail,
                          Username: socialUserName,
                        })
                      }
                    >
                      Home
                    </StyledLink>
                  </li>
                  {subscriptionStatus.active && (
                    <li className="flex">
                      <StyledLink
                        to="/screener"
                        selected={location.pathname.includes('screener')}
                        brandColor={brandColor}
                        onClick={() =>
                          clevertap.event.push('Header Screener Navigation', {
                            Email: socialEmail,
                            Username: socialUserName,
                          })
                        }
                      >
                        Screener
                      </StyledLink>
                    </li>
                  )}
                  {portfolioLinked && socialUserName ? (
                    <li className="flex">
                      <StyledLink
                        to={socialUserName && `/myverde/${socialUserName}`}
                        selected={location.pathname.includes(
                          `/myverde/${socialUserName}`
                        )}
                        brandColor={brandColor}
                        onClick={() =>
                          clevertap.event.push('Header myVerde Navigation', {
                            Email: socialEmail,
                            Username: socialUserName,
                          })
                        }
                      >
                        myVerde
                      </StyledLink>
                    </li>
                  ) : null}

                  <li className="flex">
                    <StyledLink
                      to="/superinvestors"
                      selected={location.pathname.includes('superinvest')}
                      brandColor={brandColor}
                      onClick={() =>
                        clevertap.event.push(
                          'Header SuperInvestors Navigation',
                          {
                            Email: socialEmail,
                            Username: socialUserName,
                          }
                        )
                      }
                    >
                      SuperInvestors
                    </StyledLink>
                  </li>
                </>
              ) : (
                <>
                  {location.pathname.includes('/') && !plaidAndroid && (
                    <>
                      <li className="flex">
                        <StyledLink
                          selected={currentSection === 'Home'}
                          brandColor={brandColor}
                          onClick={() => {
                            callback('Home');
                            clevertap.event.push('Header Home Navigation');
                          }}
                        >
                          Home
                        </StyledLink>
                      </li>

                      <li className="flex">
                        <StyledLink
                          selected={currentSection === 'Features'}
                          brandColor={brandColor}
                          onClick={() => {
                            callback('Features');
                            clevertap.event.push('Header Features Navigation');
                          }}
                        >
                          Features
                        </StyledLink>
                      </li>
                      {/* <li className="flex">
                  <StyledLink
                    selected={currentSection === 'Pricing'}
                    brandColor={brandColor}
                    onClick={() => callback('Pricing')}
                  >
                    Pricing 
                  </StyledLink>
                </li> */}
                      <li className="flex">
                        <StyledLink
                          selected={currentSection === 'Community'}
                          brandColor={brandColor}
                          onClick={() => {
                            callback('Community');
                            clevertap.event.push('Header Community Navigation');
                          }}
                        >
                          Community
                        </StyledLink>
                      </li>
                      {/* <li className="flex">
                    <StyledExternalLink
                      // selected={currentSection === 'FAQ'}
                      brandColor={brandColor}
                      href="https://verde.notion.site/Website-FAQ-d60c6100ff9b4f569341db4eced885f5"
                      target='_blank'
                      // onClick={() => callback('FAQ')}
                    >
                      FAQ
                    </StyledExternalLink>
                </li> */}
                    </>
                  )}
                </>
              )}
            </ul>
          )}
          <div
            className="xl:w-5/12 lg:w-3/6 md:w-4/6 hidden md:block"
            style={{ borderRadius: '10px', marginLeft: 15 }}
          >
            {authUser && !hiddenSearch && <SymbolSearch darkMode={darkMode} />}
          </div>
        </div>
        <div className="flex xl:w-4/12 lg:w-1/4 w-1/4 justify-end items-center">
          <ul className="hidden lg:flex lg:flex-row gap-1 lg:gap-4 justify-end">
            {authUser ? (
              <div className="flex items-center">
                {/* <div className="text-black mr-4 w-24">
                  <Select
                    className="text-sm max-w-sm"
                    styles={selectStyles}
                    options={countryOptions}
                    defaultValue={countryDefaultOption}
                    onChange={(item) => {
                      localStorage.setItem('verdeLocale', item.value);
                      location.pathname !== '/'
                        ? history.push('/')
                        : history.go(0);
                    }}
                  />
                </div> */}
                {!subscriptionStatus.active && (
                  <li
                    className="flex p-1 items-center cursor-pointer mr-4"
                    onClick={() => setOpenJoinVerdePlanModal(true)}
                  >
                    <KeyTextButton className="text-sm">
                      Join Verde+
                    </KeyTextButton>
                  </li>
                )}
                <li
                  className="flex p-1 items-center cursor-pointer"
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  <img
                    src={socialImg ? socialImg : DefaultProfilePic}
                    alt=""
                    style={{ objectFit: 'cover' }}
                    className="w-10 h-10 rounded-full mr-4"
                  />
                  <ColoredContentText className="text-sm font-medium">
                    {authUser.attributes.name}
                  </ColoredContentText>
                  <Subtle className="ml-2 mt-1">
                    <FontAwesomeIcon
                      className={`text-lg`}
                      icon={openMenu ? faAngleUp : faAngleDown}
                    />
                  </Subtle>
                </li>
                <ClickedOutside onClicked={() => setOpenMenu(false)}>
                  <ProfileMenu className={`${openMenu ? 'block' : 'hidden'}`}>
                    {!authUser.attributes.identities && (
                      <StyledLinkDiv
                        className="py-2"
                        onClick={handleChangePassword}
                      >
                        Change Password
                      </StyledLinkDiv>
                    )}
                    <div className="text-sm font-medium cursor-default p-2 flex justify-between items-center">
                      <div>Dark Mode</div>
                      <div>
                        <Switch
                          className="mt-1"
                          checked={darkMode}
                          onChange={() => setDarkMode(!darkMode)}
                          onColor="#DDF0E5"
                          offColor="#F1F5F6"
                          onHandleColor="#36A867"
                          offHandleColor="#96A1AE"
                          handleDiameter={12}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          //boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          //activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={17}
                          width={34}
                        />
                      </div>
                    </div>

                    <Box style={{ cursor: 'pointer' }}>
                      <StyledLinkDiv
                        className="py-2"
                        onClick={() => {
                          setShowPortfolioModal(true);
                        }}
                      >
                        Manage Portfolio
                      </StyledLinkDiv>
                    </Box>

                    {/* {subscriptionStatus.active && (
                      <Link to={'#'}>
                        <StyledLinkDiv
                          className="py-2"
                          onClick={() => {
                            setOpenYourVerdePlanModal(true);
                            setOpenMenu(false);
                            clevertap.event.push('Your Verde Plan Navigation', {
                              Email: socialEmail,
                              Username: socialUserName,
                            });
                          }}
                        >
                          My Verde+ Plan
                        </StyledLinkDiv>
                      </Link>
                    )} */}

                    <StyledLinkDiv
                      className="py-2"
                      onClick={() => {
                        clevertap.event.push('User Signed Out', {
                          Email: socialEmail,
                          Username: socialUserName,
                        });
                        handleSignout();
                      }}
                    >
                      Sign out
                    </StyledLinkDiv>
                  </ProfileMenu>
                </ClickedOutside>
              </div>
            ) : (
              <>
                <li
                  className="flex p-3"
                  onClick={() => clevertap.event.push('Header Sign In')}
                >
                  <StyledLinkDiv to="" onClick={handleSignin}>
                    Sign In
                  </StyledLinkDiv>
                </li>
                <li
                  className="flex"
                  onClick={() => clevertap.event.push('Header Sign Up')}
                >
                  <StyledLinkDiv to="" onClick={handleSignup}>
                    <SignUpButton>Sign Up</SignUpButton>
                  </StyledLinkDiv>
                </li>
              </>
            )}
          </ul>
          {authUser && (
            <div className="flex md:hidden mr-8">
              <DomainIcon
                value="search"
                onClick={() => setOpenSymbolSearchModal(true)}
              />
            </div>
          )}

          {/* ------------------------------------------------------------------- */}

          <div className="lg:hidden relative">
            <>
              <DomainIcon
                value="hamburger"
                onClick={() => setOpenHamMenu(!openHamMenu)}
              />
              <Modal
                isOpen={openHamMenu}
                onRequestClose={() => setOpenHamMenu(false)}
                style={modalStyles}
              >
                <MenuContainer>
                  {authUser && (
                    <>
                      <div className="flex justify-between">
                        <ColoredContentText className="text-xl font-medium">
                          Menu
                        </ColoredContentText>
                        <img
                          src={CancelIcon}
                          alt=""
                          className="w-4"
                          onClick={() => setOpenHamMenu(false)}
                        />
                      </div>

                      <div className="my-4">
                        <div className="flex my-8 items-center">
                          <DomainIcon value="watchlist" />
                          <ColoredContentText
                            className="mx-3.5"
                            onClick={() => {
                              history.push('/');
                              setOpenHamMenu(false);
                            }}
                          >
                            Home
                          </ColoredContentText>
                        </div>
                        {subscriptionStatus.active && (
                          <div className="flex my-8 items-center">
                            <DomainIcon value="screener" />
                            <ColoredContentText
                              className="mx-4"
                              onClick={() => {
                                history.push('/screener');
                                setOpenHamMenu(false);
                                clevertap.event.push(
                                  'Header Screener Navigation',
                                  {
                                    Email: socialEmail,
                                    Username: socialUserName,
                                  }
                                );
                              }}
                            >
                              Screener
                            </ColoredContentText>
                          </div>
                        )}
                        
                        <div className="flex my-8 items-center">
                          <DomainIcon value="employees" />
                          <ColoredContentText className="mx-3">
                            <StyledLink
                              to="/superinvestors"
                              selected={location.pathname.includes(
                                'superinvest'
                              )}
                              brandColor={brandColor}
                              onClick={() => {
                                clevertap.event.push(
                                  'Header SuperInvestors Navigation',
                                  {
                                    Email: socialEmail,
                                    Username: socialUserName,
                                  }
                                );
                              }}
                            >
                              SuperInvestors
                            </StyledLink>
                          </ColoredContentText>
                        </div>
                        
                        {portfolioLinked && (
                          <div className="flex my-8 items-center">
                            <DomainIcon value="employees" />
                            <ColoredContentText
                              className="mx-4"
                              onClick={() => {
                                history.push(`/myverde/${socialUserName}`);
                                setOpenHamMenu(false);
                                clevertap.event.push(
                                  'Header myVerde Navigation',
                                  {
                                    Email: socialEmail,
                                    Username: socialUserName,
                                  }
                                );
                              }}
                            >
                              MyVerde
                            </ColoredContentText>
                          </div>
                        )}
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => {
                            setShowPortfolioModal(true);
                            setOpenHamMenu(false);
                          }}
                        >
                          <div className="flex items-center">
                            <DomainIcon value="employees" />
                            <ColoredContentText className="mx-4">
                              Manage Portfolio
                            </ColoredContentText>
                          </div>
                        </div>
                        {/* {subscriptionStatus.active ? (
                          <div
                            className="flex my-8 items-center cursor-pointer"
                            onClick={() => setOpenYourVerdePlanModal(true)}
                          >
                            <DomainIcon value="fair-price-marker" />
                            <ColoredContentText className="mx-4">
                              Manage Verde+
                            </ColoredContentText>
                          </div>
                        ) : (
                          <div
                            className="flex my-8 items-center cursor-pointer"
                            onClick={() => setOpenJoinVerdePlanModal(true)}
                          >
                            <DomainIcon value="fair-price-marker" />
                            <ColoredContentText className="mx-4">
                              Join Verde+
                            </ColoredContentText>
                          </div>
                        )} */}
                        <div className="my-8">
                          <HoriontalLine />
                        </div>
                        <div className="flex my-8 items-center">
                          <img
                            src={socialImg ? socialImg : DefaultProfilePic}
                            alt=""
                            style={{ objectFit: 'cover' }}
                            className="w-10 h-10 rounded-full"
                          />
                          <Highlight className="mx-4 font-medium text-lg">
                            {authUser.attributes.name}
                          </Highlight>
                        </div>
                        <ColoredContentText className="my-8">
                          My Account
                        </ColoredContentText>
                        {!authUser.attributes.identities && (
                          <ColoredContentText
                            className="my-8"
                            onClick={handleChangePassword}
                          >
                            Change Password
                          </ColoredContentText>
                        )}

                        <div className="cursor-default flex justify-between items-center">
                          <ColoredContentText>Dark Mode</ColoredContentText>
                          <div>
                            <Switch
                              className="mt-1"
                              checked={darkMode}
                              onChange={() => setDarkMode(!darkMode)}
                              onColor="#DDF0E5"
                              offColor="#F1F5F6"
                              onHandleColor="#36A867"
                              offHandleColor="#96A1AE"
                              handleDiameter={16}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={22}
                              width={44}
                            />
                          </div>
                        </div>

                        <div
                          className="flex my-8 items-center pb-5"
                          onClick={() => {
                            clevertap.event.push('User Signed Out', {
                              Email: socialEmail,
                              Username: socialUserName,
                            });
                            handleSignout();
                          }}
                        >
                          <ColoredContentText className="mr-4">
                            Sign out
                          </ColoredContentText>
                          <DomainIcon value="signout" />
                        </div>
                      </div>
                    </>
                  )}
                  {!authUser && (
                    <>
                      <div className="flex justify-end mt-2 mb-12">
                        <img
                          src={CancelIcon}
                          alt=""
                          className="w-4"
                          onClick={() => setOpenHamMenu(false)}
                        />
                      </div>
                      <div className="my-8">
                        <SignUpButton className="w-full" onClick={handleSignup}>
                          Sign Up
                        </SignUpButton>
                      </div>
                      <div className="my-8">
                        <HollowBorderedButton
                          className="py-3 px-8 w-full"
                          onClick={handleSignin}
                        >
                          Sign In
                        </HollowBorderedButton>
                      </div>
                    </>
                  )}
                </MenuContainer>
              </Modal>
            </>
          </div>

          {/* ------------------------------------------------------------------- */}
        </div>
      </nav>
      <LoginModal />
      <YourVerdePlanModal darkMode={darkMode} />
      <JoinVerdePlanModal darkMode={darkMode} />
      {authUser && <ChangePassword />}
      {authUser && openSymbolSearchModal && (
        <SymbolSearchModal
          open={openSymbolSearchModal}
          setOpen={setOpenSymbolSearchModal}
        />
      )}
      {showPortfolioModal && (
        <PortfolioModal
          data={portfolioData} //data is ind. portfolios
          darkMode={darkMode}
          closeModal={() => setShowPortfolioModal(false)}
          profileData={profileData}
        />
      )}
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  isPageLoading: state.coreState.loading,  
  portfolioLinked: state.myVerde.linked,
  socialUserName: state.myVerde.username,
  socialEmail: state.myVerde.email,
  socialImg: state.myVerde.img,
  subscriptionStatus: state.coreState.subscriptionStatus,
});

const mapDispatchToProps = {
  showPageLoading,
  setLoginPhase,
  setOpenChangePassword,
  resetAllState,
  setDarkMode,
  getSymbolNames,
  getFrillToken,
  getMyVerde,
  setOpenYourVerdePlanModal,
  setOpenJoinVerdePlanModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerdeHeader);
