import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';
import axios from 'axios';

function* fetchSymbolInsightsData(action) {
  yield put({ type: 'FETCH_SYMBOL_INSIGHTS_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .get(`${SYMBOL_BASE_URL}/symbol-insights/${action.symbol.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_SYMBOL_INSIGHTS_SUCCESS',
      data: responseData,
    });
  } catch (error) {
    yield put({ type: 'FETCH_SYMBOL_INSIGHTS_ERROR', error: error.message });
  }
}

function* fetchSymbolInsightsBasicData(action) {
  yield put({ type: 'FETCH_SYMBOL_INSIGHTS_BASIC_LOADING' });
  try {
    const responseData = yield axios
      .get(`${SYMBOL_BASE_URL}/symbol-insights-basic/${action.payload.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_SYMBOL_INSIGHTS_BASIC_SUCCESS',
      data: responseData,
    });
  } catch (error) {
    yield put({ type: 'FETCH_SYMBOL_INSIGHTS_BASIC_ERROR', error: error.message });
  }
}

function* fetchSymbolInsightsPublicData(action) {
  yield put({ type: 'FETCH_SYMBOL_INSIGHTS_LOADING' });
  try {
    const responseData = yield axios
      .get(`${SYMBOL_BASE_URL}/symbol-insights-public/${action.symbol.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_SYMBOL_INSIGHTS_SUCCESS',
      data: responseData,
    });
  } catch (error) {
    yield put({ type: 'FETCH_SYMBOL_INSIGHTS_ERROR', error: error.message });
  }
}

export function* watchFetchingSymbolInsightsData() {
  yield takeEvery('FETCH_SYMBOL_INSIGHTS_DATA', fetchSymbolInsightsData);
  yield takeEvery('FETCH_SYMBOL_INSIGHTS_BASIC_DATA', fetchSymbolInsightsBasicData);
  yield takeEvery('FETCH_SYMBOL_INSIGHTS_PUBLIC_DATA', fetchSymbolInsightsPublicData);
}
