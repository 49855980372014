import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getLogoUrl } from '../../../utils/LogoUtils';
import LoadingIndicator from '../LoadingIndicator';
import ClickedOutside from '../../../components/shared/ClickOutside';
import {
  SymbolSearchWrapper,
  SearchButton,
  SearchItem,
  StyledFontAwesomeSearchIcon,
  StyledInput,
  CompanyDivider,
} from './style';
import { gtag } from '../../../utils/analytics';
import { searchIcon } from '../../pages/HomepageLogin/icons';
import { InputLeftElement, InputGroup, Input } from '@chakra-ui/input';
import {
  ColoredContentText,
  ContentWrapper,
  Highlight,
  Subtle,
} from '../StyedComponents';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SymbolLogo from '../SymbolLogo';
import clevertap from 'clevertap-web-sdk';
import _ from 'lodash';
const brandColor = '#24b552';
const SEARCH_LIMIT = 10;
const themeStyle = (isDark) => ({
  searchBg: isDark ? '#141920' : 'white',
  searchBorder: isDark ? 'transparent' : '#e2e9ee',
  searchText: isDark ? 'white' : 'black',
});

const fireEmptyEvent = (searchTerm) => {
  gtag('event', `header-symbol-search`, {
    event_category: 'empty-results',
    event_label: searchTerm,
  });
};

export function filterBySymbolsAndNames(symbolNames, value) {
  const verdeLocale = localStorage.getItem('verdeLocale');
  /* const markets = [];
  if (verdeLocale === 'US') {
    markets.push('NYSE');
    markets.push('NASDAQ');
  }

  symbolNames = symbolNames.filter(
    (s) => markets.filter((m) => s.market.toUpperCase().includes(m)).length > 0
  ); */
  const equalsMatch = symbolNames.filter(
    (s) =>
      s.id.toUpperCase() === value || (s.fund_name && s.fund_name === value)
  );
  const startsWithMatch = symbolNames.filter(
    (s) =>
      (s.id.toUpperCase().startsWith(value) && s.id.toUpperCase() !== value) ||
      (s.fund_name &&
        s.fund_name.toUpperCase().startsWith(value) &&
        s.fund_name.toUpperCase() !== value)
  );
  const endsWithMatch = symbolNames.filter(
    (s) =>
      (s.id.toUpperCase().endsWith(value) && s.id.toUpperCase() !== value) ||
      (s.fund_name &&
        s.fund_name.toUpperCase().endsWith(value) &&
        s.fund_name.toUpperCase() !== value)
  );

  const subResult = [...equalsMatch, ...startsWithMatch, ...endsWithMatch];

  if (subResult.length <= SEARCH_LIMIT) {
    const includesMatch = symbolNames
      .filter(
        function (s) {
          if (
            this.count < SEARCH_LIMIT &&
            (s.id.toUpperCase().includes(value) ||
              s.fund_name?.toUpperCase()?.includes(value))
          ) {
            this.count++;
            return true;
          }
          return false;
        },
        { count: 0 }
      )
      .filter(
        (s) =>
          !equalsMatch
            .map((y) => y.id.toUpperCase())
            .includes(s.id.toUpperCase()) &&
          !equalsMatch
            .map((y) => y.fund_name?.toUpperCase())
            .includes(s.fund_name?.toUpperCase()?.includes(value)) &&
          !startsWithMatch
            .map((y) => y.id.toUpperCase())
            .includes(s.id.toUpperCase()) &&
          !startsWithMatch
            .map((y) => y.fund_name?.toUpperCase())
            .includes(s.fund_name?.toUpperCase()?.includes(value)) &&
          !endsWithMatch
            .map((y) => y.id.toUpperCase())
            .includes(s.id.toUpperCase()) &&
          !endsWithMatch
            .map((y) => y.fund_name?.toUpperCase())
            .includes(s.fund_name?.toUpperCase()?.includes(value))
      );

    const symbolResult = [...subResult, ...includesMatch];

    if (symbolResult.length <= SEARCH_LIMIT) {
      const symbolNamesResult = symbolNames.filter((s) =>
        s.name.toUpperCase().includes(value)
      );
      const updatedNameResult = symbolNamesResult.filter(
        (s) => symbolResult.filter((s1) => s.id === s1.id).length === 0
      );
      console.log(updatedNameResult);
      const result = [...symbolResult, ...updatedNameResult];
      return result.length <= SEARCH_LIMIT
        ? result
        : result.slice(0, SEARCH_LIMIT);
    } else {
      return symbolResult.slice(0, SEARCH_LIMIT);
    }
  } else {
    return subResult.slice(0, SEARCH_LIMIT);
  }
}

export function getEntityLink(
  entity,
  isPublic = false,
  subscriptionStatus = {}
) {
  if (entity.type === 'equity') {
    return isPublic || subscriptionStatus.active
      ? `/stocks/${entity.id}`
      : `/stocks-basic/${entity.id}`;
  } else if (entity.type === 'etf') {
    return `/etfs/${entity.id}`;
  } else if (entity.type === 'user') {
    return `/${entity.username}`;
  } else {
    return `/superinvestors/${entity.id}`;
  }
}

export function getEntityName(entity) {
  if (entity.type === 'user') return entity.name;
  else if (entity.type === 'superinvestor') return entity.fund_name;
  else return entity.symbol;
}

export function getEntitySubtext(entity) {
  if (entity.type === 'user') return entity.username;
  else return entity.name;
}

const SymbolSearch = ({
  symbolNames,
  symbolNamesLoading,
  setOpenSymbolSearchModal,
  socialUserName,
  socialEmail,
  darkMode,
  subscriptionStatus,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [matchingSymbolNames, setMatchingSymbolNames] = React.useState([]);
  const searchInputRef = React.useRef(null);
  const themeStyleObj = themeStyle(darkMode);
  const publicSymbols = useSelector(
    (state) => state.symbolState.publicSymbols.data
  );

  function ClevertapSearchEvent(entity) {
    if (entity.type === 'equity') {
      clevertap.event.push('Stock Searched', {
        'Stock Name': `${entity.id}`,
        Date: new Date(),
        Username: socialUserName,
        Email: socialEmail,
      });
    } else if (entity.type === 'etf') {
      clevertap.event.push('ETF Searched', {
        'ETF Name': `${entity.id}`,
        Date: new Date(),
        Username: socialUserName,
        Email: socialEmail,
      });
    } else if (entity.type === 'user') {
      clevertap.event.push('User Searched', {
        "Searched User's Name": `${entity.username}`,
        Date: new Date(),
        Username: socialUserName,
        Email: socialEmail,
      });
    } else {
      clevertap.event.push('SuperInvestor Searched', {
        Superinvestor: `${entity.id}`,
        Date: new Date(),
        Username: socialUserName,
        Email: socialEmail,
      });
    }
  }

  useEffect(() => {
    if (!matchingSymbolNames.length && searchTerm) {
      fireEmptyEvent(searchTerm);
    }
  }, [matchingSymbolNames, searchTerm]);

  return (
    <SymbolSearchWrapper className="h-full">
      <InputGroup
        size="md"
        w={['xs', 'md']}
        borderColor={themeStyleObj.searchBorder}
        bg={themeStyleObj.searchBg}
        position="relative"
        mx={['auto', 'initial']}
      >
        <InputLeftElement
          mx={2}
          outline="none"
          ml={4}
          transform="translateY(-50%)"
          w="max"
          justifyContent="end"
          top="50%"
        >
          {searchIcon}
        </InputLeftElement>
        <Input
          type="text"
          ref={searchInputRef}
          color={themeStyleObj.searchText}
          placeholder="Search for Stocks, ETFs, Superinvestors"
          borderWidth="1px"
          size={'md'}
          fontSize="small"
          _focus={{ outline: brandColor }}
          _hover={{ borderColor: 'none' }}
          onInput={(event) => {
            const value = event.target.value.toUpperCase();
            setSearchTerm(value);
            const result = filterBySymbolsAndNames(symbolNames, value);
            //console.log(result);
            value ? setMatchingSymbolNames(result) : setMatchingSymbolNames([]);
          }}
        />
      </InputGroup>

      {searchTerm && (
        <ClickedOutside
          onClicked={() => {
            setSearchTerm('');
            setMatchingSymbolNames([]);
          }}
        >
          <ContentWrapper
            style={{
              width: searchInputRef.current
                ? searchInputRef.current.offsetWidth + 'px'
                : 'auto',
            }}
            className="absolute max-h-80 overflow-y-scroll"
          >
            <div className="flex-row">
              {symbolNamesLoading ? (
                <LoadingIndicator />
              ) : matchingSymbolNames.length === 0 ? (
                <Highlight className="text-sm text-center">
                  No matching symbol found.
                </Highlight>
              ) : (
                matchingSymbolNames.map((entity, index) => (
                  <React.Fragment key={index}>
                    <SearchItem>
                      <Link
                        to={getEntityLink(
                          entity,
                          publicSymbols.includes(entity.id),
                          subscriptionStatus
                        )}
                        onClick={() => {
                          gtag('event', `header-symbol-search`, {
                            event_category: 'click-on-symbol',
                            event_label: searchTerm,
                            event_value: entity.symbol,
                          });
                          ClevertapSearchEvent(entity);
                          setSearchTerm('');
                          setMatchingSymbolNames([]);
                        }}
                        className="no-underline text-black"
                      >
                        <div className="flex">
                          <SymbolLogo
                            logoUrl={getLogoUrl(entity.symbol, entity.image)}
                          />
                          <div className="flex flex-col ml-4 justify-evenly w-full">
                            <ColoredContentText className="font-medium">
                              {getEntityName(entity)}
                            </ColoredContentText>
                            <div className="flex justify-between">
                              <Subtle className="text-xs">
                                {getEntitySubtext(entity)}
                              </Subtle>
                              <Subtle className="text-xs">
                                {entity.market}
                              </Subtle>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </SearchItem>
                    <CompanyDivider />
                  </React.Fragment>
                ))
              )}
            </div>
          </ContentWrapper>
        </ClickedOutside>
      )}
    </SymbolSearchWrapper>
  );
};

const mapStateToProps = (state) => ({
  symbolNames: state.symbolState.symbolNames.data,
  symbolNamesLoading: state.symbolState.symbolNames.loading,
  socialUserName: state.myVerde.username,
  socialEmail: state.myVerde.email,
  subscriptionStatus: state.coreState.subscriptionStatus,
});

export default connect(mapStateToProps)(SymbolSearch);
