import React from 'react';
import { connect } from 'react-redux';
import { ColoredParagraph, StyledLink } from './style';
import {
  ColoredContentText,
  StyledInput,
  GreenButton,
} from '../StyedComponents';
import { showErrorAlert, showSuccessAlert } from '../../../actions/alertAction';
import { showPageLoading, resetLoginPhase } from '../../../actions/coreAction';
import { Auth } from 'aws-amplify';

const VerifyUser = ({
  email,
  showPageLoading,
  resendVerificationCode,
  showErrorAlert,
  showSuccessAlert,
  resetLoginPhase,
}) => {
  const [verificationCode, setVerificationCode] = React.useState('');
  React.useEffect(() => {
    setVerificationCode('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyUser = async () => {
    try {
      showPageLoading(true);
      await Auth.confirmSignUp(email, verificationCode);
      showSuccessAlert(
        'User has been verified successfully! Please signin to continue.'
      );
      resetLoginPhase();
    } catch (error) {
      showErrorAlert(error.message);
      console.log('error verifying user', error);
    } finally {
      showPageLoading(false);
    }
  };

  return (
    <div className="p-8">
      <div className="my-2">
        <ColoredParagraph color="#2b9867">Verify yourself!</ColoredParagraph>
      </div>
      <StyledInput
        id="verificationCode"
        name="verificationCode"
        type="text"
        placeholder="Verification Code"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value.trim())}
        autoFocus={true}
      />
      <div className="flex text-sm mt-1">
        <ColoredContentText className="mr-1 font-normal">
          Didn't receive?
        </ColoredContentText>
        <StyledLink
          onClick={async () => {
            showPageLoading(true);
            await resendVerificationCode();
            showPageLoading(false);
          }}
        >
          Resend code
        </StyledLink>
      </div>
      <GreenButton onClick={verifyUser}>Submit</GreenButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginPhase: state.coreState.loginPhase,
});

const mapDispatchToProps = {
  showErrorAlert,
  showSuccessAlert,
  showPageLoading,
  resetLoginPhase,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);
