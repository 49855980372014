import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useState, FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import VerdeLogo from '../../../public/images/VerdeLogo.png';
import { DefaultTheme, useTheme } from 'styled-components';
import { showPageLoading } from '../../../actions/coreAction';
import PageContainer from '../../shared/PageContainer';
import { useMediaQuery } from '@chakra-ui/react';
import WrappedAxios from '../../../utils/WrappedAxios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import TopProfile from './Overview/TopProfile.js';
import Overview from './Overview/Overview';
import Activity from './Activity';
import AssetClassification from './AssetClassification';
import { SUPER_INVESTOR_URL } from '../../../config/default';
import TopOverview from './Overview/TopOverview';
import { HeaderList } from './Activity/HeaderList';
import clevertap from 'clevertap-web-sdk';
import { getMyVerde } from '../../../actions/my-verde';

const PageContainerW = PageContainer;

const SuperInvestorProfile = ({ darkMode, showPageLoading, socialUserName, socialEmail }) => {
  const currentTheme = useTheme();
  const [data, setData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams();
  const [tabSelected, setTabSelected] = useState('Overview');

  const mainTabs = [
    {
      title: 'Overview',
    },
    {
      title: 'Activity',
    },
    {
      title: 'Allocation',
    },
  ];

  showPageLoading(data.length === 0 || performanceData.length === 0);

  useEffect(() => {
    async function antiPattern() {
      setData(
        (
          await WrappedAxios.get(
            `${SUPER_INVESTOR_URL}/get-superinvestor-details/${params.cik}`
          )
        ).data
      );

      setPerformanceData(
        (
          await WrappedAxios.get(
            `${SUPER_INVESTOR_URL}/get-superinvestor-performance/${params.cik}`
          )
        ).data
      );

      setActivityData(
        (
          await WrappedAxios.get(
            `${SUPER_INVESTOR_URL}/get-superinvestor-overallactivity/${params.cik}`
          )
        ).data
      );

      setAssetData(
        (
          await WrappedAxios.get(
            `${SUPER_INVESTOR_URL}/get-cik-asset-details/${params.cik}`
          )
        ).data
      );
    }

    antiPattern();
  }, [params.cik]);

  useEffect(() => {
    if(data.length > 0)
    {
    clevertap.event.push('SuperInvestor Viewed', {
      SuperInvestorFundName: data[0]['fundName'],
      SuperInvestorFundManagerName: data[0]['fundManagerName'],
      Cik: params.cik,
      Email: socialEmail,
      Username: socialUserName
    })
  }
  },[data])

  // console.log('ind ' + JSON.stringify(data));
  // console.log('performance' , JSON.stringify(performanceData))
  // console.log(JSON.stringify(assetData))

  return (
    <Box backgroundColor={darkMode ? '#161616' : '#f4f4f4'}>
      <PageContainerW>
        <Box>
          <TopProfile profileData={data} />
        </Box>
        <Flex
          //height="76px"
          borderRadius="5px"
          backgroundColor={darkMode ? '#262626' : 'white'}
          marginBottom="25px"
          marginTop="25px"
        >
          {mainTabs.map((elem) => {
            return (
              <div
                style={{
                  borderBottom:
                    tabSelected === elem.title
                      ? `3px solid ${darkMode ? '#4adf79' : '#01874e'}`
                      : '',
                  marginLeft: 20,
                  marginRight: 15,
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: 15,
                }}
                onClick={() => setTabSelected(elem.title)}
              >
                <Text
                  style={{
                    color:
                      tabSelected === elem.title
                        ? darkMode
                          ? '#4adf79'
                          : '#01874e'
                        : darkMode
                        ? '#cccccc'
                        : '#8c8c8c',
                    fontWeight: tabSelected === elem.title ? 'bold' : 'normal',
                    lineHeight: '35px',
                    fontSize: '17px',
                  }}
                >
                  {elem.title}
                </Text>
              </div>
            );
          })}
        </Flex>

        <div style={{ marginBottom: 20 }}>
          <TopOverview
            data={data.length > 0 ? data[0] : []}
            darkMode={darkMode}
          />
        </div>

        <div>
          {tabSelected === 'Overview' ? (
            <Overview
              data={data.length > 0 ? data[0] : []}
              darkMode={darkMode}
              performanceData={performanceData}
              cik={params.cik}
            />
          ) : tabSelected === 'Activity' ? (
            <Activity
              data={activityData.length > 0 ? activityData[0].quarters : []}
              headerData={HeaderList}
              darkMode={darkMode}
              cik={params.cik}
            />
          ) : (
            <AssetClassification
              data={data && data.length > 0 ? data[0].quarters[0] : []}
              assetData={assetData}
              performanceData={performanceData}
              // insightsData={insightsData}
              darkMode={darkMode}
            />
          )}
        </div>
      </PageContainerW>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
  socialUserName: state.myVerde.username,
  socialEmail:state.myVerde.email
});

const mapDispatchToProps = {
  showPageLoading,
  getMyVerde
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperInvestorProfile);
