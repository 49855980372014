import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import {
  ContentWrapper,
  Subtle,
  Highlight,
  ColoredContentText,
} from '../StyedComponents';
import LoadingIndicator from '../LoadingIndicator';
import { getLogoUrl } from '../../../utils/LogoUtils';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import SymbolLogo from '../SymbolLogo';
import DomainIcon from '../DomainIcon';

export const SimilarCompaniesWrapper = styled.div`
  background-color: ${(props) => props.theme.mainBgColor};
`;

export const StyledSpan = styled.span`
  ${tw`font-medium ml-1 no-underline`};
  color: ${(props) => props.theme.keyHighlight};
`;

export const CompanyDivider = styled.div`
  ${tw`w-full my-4 h-0`};
  border-top: solid 1px ${(props) => props.theme.similarCompanies.divider};
`;

export const BackTo = styled.div`
  ${tw`flex lg:hidden justify-center items-center font-medium py-4`};
  color: ${(props) => props.theme.keyHighlight};
`;

const SimilarCompanies = ({
  symbol,
  data: companies,
  loading,
  onCloseModal = () => {},
}) => {
  return (
    <SimilarCompaniesWrapper>
      <Highlight className="font-medium py-4 px-2 md:px-0">
        Companies similar to
        <StyledSpan>
          {symbol && symbol.id && !loading ? symbol.id : ''}
        </StyledSpan>
      </Highlight>
      <ContentWrapper>
        <div className="flex-row">
          {loading ? (
            <LoadingIndicator />
          ) : companies.length === 0 ? (
            <Highlight className="text-sm text-center">
              No matching company name found.
            </Highlight>
          ) : (
            companies.map((company, index) => (
              <React.Fragment key={index}>
                <div className="text-sm font-medium">
                  <Link
                    to={`/stocks/${company.symbol}`}
                    className="no-underline"
                    onClick={() => {
                      setTimeout(onCloseModal, 500);
                    }}
                  >
                    <div className="flex justify-between">
                      <div className="flex">
                        <SymbolLogo logoUrl={getLogoUrl(company.symbol)} />
                        <div className="flex flex-col ml-4 justify-evenly">
                          <ColoredContentText className="font-medium">
                            {company.symbol}
                          </ColoredContentText>

                          <Subtle className="text-xs truncate 2xl:w-52 xl:w-36 md:w-24 w-44">
                            {company.symbolName}
                          </Subtle>
                        </div>
                      </div>
                      {company.verde_score && (
                        <div className="flex items-center">
                          <img
                            src={VerdeScoreSvg}
                            alt=""
                            className="w-3 mb-0.5 mr-1"
                          />
                          <ColoredContentText className="text-base">
                            {company.verde_score}
                          </ColoredContentText>
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
                {index !== companies.length - 1 && <CompanyDivider />}
              </React.Fragment>
            ))
          )}
        </div>
        {/* <div className="text-center text-sm">
          <KeyTextButton
            onClick={() => {
              history.push({
                pathname: '/screener',
                state: {
                  sectors: [
                    {
                      value: symbol.sector,
                      label: symbol.sector,
                    },
                  ],
                },
              });
            }}
          >
            View All
          </KeyTextButton>
        </div> */}
      </ContentWrapper>
      <BackTo>
        <div>
          <DomainIcon value="arrow-left" />
        </div>
        <div className="ml-3 cursor-pointer" onClick={onCloseModal}>
          Back To {symbol && symbol.id ? symbol.id : ''}
        </div>
      </BackTo>
    </SimilarCompaniesWrapper>
  );
};

const mapStateToProps = (state) => ({
  data: state.similarCompaniesState.data,
  symbol: state.valuationState.data.symbol,
  loading: state.similarCompaniesState.loading,
});

export default connect(mapStateToProps)(SimilarCompanies);
