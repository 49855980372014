import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import { ANNUALLY, getChartData } from '../../../../../utils/ChartUtils';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import BarLineComboChart from '../../../BarLineComboChart';
import SectionHeader from '../../../SectionHeader';
//import PayoutChart from '../../../PayoutChart';

const NetIncomePayoutRatio = ({ data, insights, period, setPeriod }) => {
  const chartData = getChartData(
    data,
    ['net_income', 'payout_ratio', 'evaluated_on'],
    ANNUALLY
  ).map((item) => [item[0], item[1], item[2]]);

  return (
    <>
      <SectionHeader
        value="Net Income Payout Ratio"
        className="pb-6"
        infoKey="net_income_payout_ratio"
      />
      <BorderedChartContainer className="py-4">
        {/* <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div> */}
        <BarLineComboChart
          data={[
            {
              name: 'Total Dividends Paid Out',
              data: chartData.map((d) => d[2] * d[1] * 0.01),
            },
            {
              name: 'Net Income',
              data: chartData.map((d) => d[2]),
            },
            {
              name: 'Net Income Payout Ratio',
              data: chartData.map((d) => d[1]),
            },
          ]}
          xAxisData={chartData.map((d) => d[0])}
          name="Net Income Payout Ratio"
        />
      </BorderedChartContainer>

      <BulletPoints
        title="Positives"
        points={insights?.netIncomePayout?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.netIncomePayout?.negatives}
        isPositive={false}
      />
    </>
  );
};

export default NetIncomePayoutRatio;
