import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import {
  ANNUALLY,
  getStackedAreaChartData,
} from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';
import RiskPositives from '../../../RiskPositives';

const EfficiencyRatios = ({ data, insights, period, setPeriod }) => {
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'Return on Assets',
      selected: true,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'Return on Equity',
      selected: true,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'Return on Invested Capital',
      selected: true,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    [
      'evaluated_on',
      'return_on_assets',
      'return_on_equity',
      'return_on_invested_capital',
    ],
    ANNUALLY
  );
  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['Return on Assets'].selected
      ? {
          name: 'Return on Assets',
          data: fullChartData.map((item) => [item[0], item[1]]),
        }
      : [],
    chartVariants['Return on Equity'].selected
      ? {
          name: 'Return on Equity',
          data: fullChartData.map((item) => [item[0], item[2]]),
        }
      : [],
    chartVariants['Return on Invested Capital'].selected
      ? {
          name: 'Return on Invested Capital',
          data: fullChartData.map((item) => [item[0], item[3]]),
        }
      : [],
  ];

  return (
    <>
      <SectionHeader className="mb-6" value="Efficiency Ratios" />
      <div style={{ marginTop: '10px' }}>
        {/* <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div> */}
        <StackedLineChart data={chartData} valueType="percent" />
        <div className="flex justify-center">
          <MultiLineGraphSelectorButton
            values={buttonValues}
            setValues={setButtonValues}
          />
        </div>
      </div>

      <RiskPositives
        positives={insights?.efficiency_ratio?.positives}
        negatives={insights?.efficiency_ratio?.negatives}
      />
    </>
  );
};

export default EfficiencyRatios;
