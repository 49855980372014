import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import { ANNUALLY, getChartData } from '../../../../../utils/ChartUtils';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import SectionHeader from '../../../SectionHeader';
import Chart from './chart';
import RiskPositives from '../../../RiskPositives';

const TotalShareholderReturn = ({
  data,
  insights,
  period,
  setPeriod,
  darkMode,
}) => {
  const chartData = getChartData(
    data,
    [
      'free_cash_flow',
      'total_shareholder_return',
      'sale_purchase_stock',
      'dividend_paid_per_share',
      'shares_data',
      'evaluated_on',
    ],
    ANNUALLY
  ).map((item) => [item[1], item[0], item[4], item[5], item[2], item[3]]);

  return (
    <>
      <SectionHeader
        value="Total Shareholder Payout"
        className="pb-6"
        infoKey="total_shareholder_return_ratio"
      />
      <div style={{ marginTop: '10px' }}>
        {/* <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div> */}
        <Chart data={chartData} name="Total Shareholder Return" />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          height: 'auto',
          flexWrap: 'wrap',
          margin: '10px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: 'rgba(67, 141, 247)',
              borderRadius: 7,
              marginRight: 10,
              marginTop: 5,
            }}
          />
          <p
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Total Dividend Paid Out
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: 'rgba(67, 141, 247)',
              borderRadius: 7,
              marginRight: 10,
              marginTop: 5,
            }}
          />
          <p
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Share Buybacks
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: 'rgb(143, 188, 250)',
              borderRadius: 7,
              marginRight: 10,
              marginTop: 5,
            }}
          />
          <p
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Free Cash Flow
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: '#9e71ff',
              borderRadius: 7,
              marginRight: 10,
              marginTop: 5,
            }}
          />
          <p
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Total Shareholder Return
          </p>
        </div>
      </div>

      <RiskPositives
        positives={insights?.total_shareholder_return?.positives}
        negatives={insights?.total_shareholder_return?.negatives}
      />
    </>
  );
};

export default TotalShareholderReturn;
