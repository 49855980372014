import React from 'react';
import { connect } from 'react-redux';
import { ColoredParagraph } from './style';
import { StyledInput, GreenButton, screenSize } from '../StyedComponents';
import { showErrorAlert, showSuccessAlert } from '../../../actions/alertAction';
import {
  showPageLoading,
  setOpenChangePassword,
} from '../../../actions/coreAction';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useCurrentWidth } from '../../core/useCurrentWidth';
import CancelSvg from '../../../public/images/cancel.svg';
import { DARK_THEME, LIGHT_THEME } from '../../core/theme';
import DomainIcon from '../DomainIcon';

Modal.setAppElement('#root');

const ChangePassword = ({
  showPageLoading,
  showErrorAlert,
  showSuccessAlert,
  openChangePassword,
  setOpenChangePassword,
  darkMode,
}) => {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
  const [revealCurrentPassword, setRevealCurrentPassword] = React.useState(
    false
  );
  const [revealNewPassword, setRevealNewPassword] = React.useState(false);
  const [
    revealConfirmNewPassword,
    setRevealConfirmNewPassword,
  ] = React.useState(false);

  const clearAllInputs = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  React.useEffect(() => {
    clearAllInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const width = useCurrentWidth();
  const modalStyles = {
    overlay: {
      //top: '15px',
      zIndex: 30,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '5%',
      margin: 'auto',
      height: width <= screenSize.tablet ? 'auto' : 'max-content',
      maxWidth: '550px',
      overflow: 'scroll',
      padding: 0,
      border: 0,
      backgroundColor: darkMode
        ? DARK_THEME.content.background
        : LIGHT_THEME.content.background,
    },
  };

  const onCloseModal = () => {
    setOpenChangePassword(false);
    clearAllInputs();
  };

  const changePassword = () => {
    if (!currentPassword)
      return showErrorAlert('Current Password is mandatory!');
    if (!newPassword) return showErrorAlert('New Password is mandatory!');
    if (!confirmNewPassword)
      return showErrorAlert('Confirm New Password is mandatory!');
    if (newPassword !== confirmNewPassword)
      return showErrorAlert(
        'New Password and Confirm New Password must match!'
      );

    showPageLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then((data) => {
        showSuccessAlert('Password has been changed successfully!');
        onCloseModal();
      })
      .catch((error) => {
        if (error.message === 'Incorrect username or password.')
          showErrorAlert('Incorrect current password!');
        else showErrorAlert(error.message);
        clearAllInputs();
      })
      .finally(() => showPageLoading(false));
  };

  return (
    <Modal
      isOpen={openChangePassword}
      //onRequestClose={onCloseModal}
      style={modalStyles}
    >
      <div>
        <div
          className="absolute top-4 right-4 cursor-pointer"
          onClick={onCloseModal}
        >
          <DomainIcon value="close-modal" />
        </div>
      </div>
      <div className="p-8 h-full">
        <div className="mb-2 mt-5">
          <ColoredParagraph color="#2b9867">Change Password!</ColoredParagraph>
        </div>
        <StyledInput
          id="current-password"
          name="current-password"
          type={revealCurrentPassword ? 'text' : 'password'}
          placeholder="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value.trim())}
          autoFocus={true}
        />
        <FontAwesomeIcon
          className="float-right relative bottom-8 mr-4 cursor-pointer text-lg text-gray-400"
          icon={revealCurrentPassword ? faEye : faEyeSlash}
          onClick={() => setRevealCurrentPassword(!revealCurrentPassword)}
        />
        <StyledInput
          id="password"
          name="password"
          type={revealNewPassword ? 'text' : 'password'}
          placeholder="New Password"
          value={newPassword}
          style={{ marginTop: 0 }}
          onChange={(e) => setNewPassword(e.target.value.trim())}
        />
        <FontAwesomeIcon
          className="float-right relative bottom-8 mr-4 cursor-pointer text-lg text-gray-400"
          icon={revealNewPassword ? faEye : faEyeSlash}
          onClick={() => setRevealNewPassword(!revealNewPassword)}
        />
        <StyledInput
          id="confirm-password"
          name="confirm-password"
          type={revealConfirmNewPassword ? 'text' : 'password'}
          placeholder="Confirm New Password"
          value={confirmNewPassword}
          style={{ marginTop: 0 }}
          onChange={(e) => setConfirmNewPassword(e.target.value.trim())}
        />
        <FontAwesomeIcon
          className="float-right relative bottom-8 mr-4 cursor-pointer text-lg text-gray-400"
          icon={revealConfirmNewPassword ? faEye : faEyeSlash}
          onClick={() => setRevealConfirmNewPassword(!revealConfirmNewPassword)}
        />

        <div className="mb-6">
          <GreenButton onClick={changePassword}>Submit</GreenButton>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  openChangePassword: state.coreState.openChangePassword,
  darkMode: state.coreState.darkMode,
});

const mapDispatchToProps = {
  showErrorAlert,
  showSuccessAlert,
  showPageLoading,
  setOpenChangePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
