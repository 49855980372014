import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { Text } from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import PageContainer from '../../shared/PageContainer';
import { useParams, Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { showPageLoading } from '../../../actions/coreAction';
import { useHistory } from 'react-router-dom';
import WrappedAxios from '../../../utils/WrappedAxios';
import { useAppSelector } from '../../../hooks/store';
import bookMarkLogoDark from '../../../public/images/bookMarkDark.png';
import bookMarkLogoWhite from '../../../public/images/bookMarkWhite.png';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';

import { ANNUALLY } from '../../../utils/ChartUtils';

import ETFOverview from '../../shared/Sections/ETFs/ETFOverview/index.js';
import ETFInformation from '../../shared/Sections/ETFs/Chart/ETFHistoryInformation';
import Performance from '../../shared/Sections/ETFs/Performance';
import ETFValuation from '../../shared/Sections/ETFs/ETFValuation';
import ETFGrowth from '../../shared/Sections/ETFs/ETFGrowth';
import PriceToEarnings from '../../shared/Sections/ValuationRevamp/PriceToEarnings';
import PriceToEarningsGrowth from '../../shared/Sections/ValuationRevamp/PriceToEarningsGrowth';
import PriceToBook from '../../shared/Sections/ValuationRevamp/PriceToBook';
import PriceToSales from '../../shared/Sections/ValuationRevamp/PriceToSales';
import { gtag } from '../../../utils/analytics';
import SimilarCompaniesRevamp from '../../shared/SimilarCompaniesRevamp';
import LoadingIndicator from '../../shared/LoadingIndicator';
import ETFDividend from '../../shared/Sections/ETFs/ETFDividend';
import CurrentYield from '../../shared/Sections/DividendRevamp/CurrentYield';
import ETFHoldings from '../../shared/Sections/ETFs/ETFHoldings';
import ETFAssets from '../../shared/Sections/ETFs/ETFAssets';
import SymbolLogo from '../../shared/SymbolLogo';
import SectorLogo from '../../shared/SectorLogo';
import { InfoOutlineIcon, TriangleDownIcon } from '@chakra-ui/icons';
import NotExists from '../NotExists';

type Theme = DefaultTheme & { isDark: boolean };
const themeObject = {
  dark: {
    bg: '#f4f4f4',
    navItemSelectedBg: 'white',
    navItemSelectedBorder: '#01874e',
    navItemColor: '#8c8c8c',
    navItemSelectedColor: '#01874e',
  },
  light: {
    bg: '#f4f4f4',
    navItemSelectedBg: 'white',
    navItemSelectedBorder: '#01874e',
    navItemColor: '#8c8c8c',
    navItemSelectedColor: '#01874e',
  },
};

const PageContainerW: any = PageContainer;
const scrollToElement = (id: string, isMobile: boolean) => {
  var element = document.getElementById(id);
  if (element !== null) {
    var headerOffset = isMobile ? 50 : 100;
    var elementPosition = element!.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

const clickTab = (tabId: string) => {
  const tabElm = document.getElementById(`tab-${decodeURIComponent(tabId)}`);
  if (tabElm) {
    tabElm.dispatchEvent(new Event('click', { bubbles: true }));
  }
};

const inViewChange = (tab: string, state: boolean, sid: string) => {
  if (state) {
    gtag('event', `${tab}-company-scroll`, {
      event_category: tab,
      event_label: sid,
    });
  }
};
const Main: FC<{
  darkMode: any;
  loading: any;
  showPageLoading: any;
}> = ({ darkMode, loading, showPageLoading }) => {
  const [ETFData, setETFData] = useState<any>({
    ETF_Data: {},
    Valuation: {},
    Growth: {},
    'Asset Allocation': {},
    MarketCapitalisation: {},
    'Sector Weights': {},
    'World Regions': {},
    'Top 100 Holdings': {},
  });
  const [historyData, setHistoryData] = useState([]);

  const [spyData, setSpyData] = useState({
    ETF_Data: {},
    Valuation: {},
    Growth: {},
    'Asset Allocation': {},
    MarketCapitalisation: {},
    'Sector Weights': {},
    'World Regions': {},
    'Top 100 Holdings': {},
  });
  const [notExists, setNotExists] = useState(false);
  const currentTheme: Theme = useTheme() as Theme;
  showPageLoading(!notExists ? JSON.stringify(ETFData['ETF_Data']) === '{}' : false);

  const params: { sid: string } = useParams();

  useEffect(() => {
    const response2 = WrappedAxios.get(
      `https://fe54zuu1ni.execute-api.us-east-1.amazonaws.com/prod/get-etf-details/${params.sid}`
    ).then((response2) => {
      if (JSON.stringify(response2.data) === '{}') {
        setNotExists(true);
      }
      else{
        setNotExists(false);
        setETFData(response2.data);
      }
     
    });

    //TODO: Remove SPY hardcoded
    const response3 = WrappedAxios.get(
      `https://fe54zuu1ni.execute-api.us-east-1.amazonaws.com/prod/get-etf-details/SPY.NYSE`
    ).then((response3) => {
      setSpyData(response3.data);
    });

    const response4 = WrappedAxios.get(
      `https://fe54zuu1ni.execute-api.us-east-1.amazonaws.com/prod/get-etf-history/${params.sid}`
    ).then((response4) => {
      setHistoryData(response4.data);
    });
  }, [params.sid]);

  const theme = currentTheme.isDark ? themeObject.dark : themeObject.light;

  const { data: valuationState } = useAppSelector(
    (state) => state.valuationState
  );
  const insights = useAppSelector(
    (state) => state.symbolInsightsState.data?.valuation
  );

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [period, setPeriod] = React.useState(ANNUALLY);

  const history = useHistory();

  const valuation = useMemo(
    () => <ETFValuation data={ETFData} darkMode={darkMode} />,
    [ETFData, darkMode]
  );

  const ETFInfo = useMemo(
    () => (
      <ETFInformation
        data={historyData}
        etfData={ETFData}
        darkMode={darkMode}
      />
    ),
    [darkMode, ETFData, historyData]
  );

  const performance = useMemo(
    () => (
      <Performance
        darkMode={darkMode}
        symbol={ETFData.ETF_Data.ETF_Code}
        etfData={ETFData}
      />
    ),
    [darkMode, ETFData]
  );

  const priceToEanings = useMemo(
    () => (
      <PriceToEarnings
        data={undefined}
        symbol={undefined}
        insights={undefined}
        darkMode={darkMode}
        etfData={ETFData}
        spyData={spyData}
      />
    ),
    [darkMode, ETFData, spyData]
  );

  const priceToEaningsGrowth = useMemo(
    () => (
      <PriceToEarningsGrowth
        data={undefined}
        symbol={undefined}
        insights={undefined}
        darkMode={darkMode}
        etfData={ETFData}
        spyData={spyData}
      />
    ),
    [darkMode, ETFData, spyData]
  );

  const priceToBook = useMemo(
    () => (
      <PriceToBook
        data={undefined}
        symbol={undefined}
        insights={undefined}
        darkMode={darkMode}
        etfData={ETFData}
        spyData={spyData}
      />
    ),
    [darkMode, ETFData, spyData]
  );

  const priceToSales = useMemo(
    () => (
      <PriceToSales
        data={undefined}
        symbol={undefined}
        insights={undefined}
        darkMode={darkMode}
        etfData={ETFData}
        spyData={spyData}
      />
    ),
    [darkMode, ETFData, spyData]
  );

  const growth = useMemo(
    () => ETFData.Growth && <ETFGrowth data={ETFData} darkMode={darkMode} />,
    [ETFData, darkMode]
  );

  const dividend = useMemo(
    () => <ETFDividend data={ETFData} darkMode={darkMode} />,
    [ETFData, darkMode]
  );

  const currentYield = useMemo(
    () => (
      <CurrentYield
        data={undefined}
        insights={{ dividendYield: undefined }}
        darkMode={darkMode}
        etfData={ETFData}
        spyData={spyData}
      />
    ),
    [darkMode, ETFData, spyData]
  );

  const holdings = useMemo(
    () => <ETFHoldings darkMode={darkMode} data={ETFData} />,
    [ETFData, darkMode]
  );

  const assets = useMemo(
    () => <ETFAssets darkMode={darkMode} data={ETFData} />,
    [ETFData, darkMode]
  );

  const similarCompanies = useMemo(() => <SimilarCompaniesRevamp />, []);

  const [overviewRef, overviewInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('overview', inView, params.sid),
  });

  const [etfOverviewRef, etfOverviewInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('etf-overview', inView, params.sid),
  });

  const [ETFInfoRef, ETFInfoInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('etf-info', inView, params.sid),
  });

  const [valuationRef, valuationInView] = useInView({
    threshold: isMobile ? 0.09 : 0.07,
    onChange: (inView) => inViewChange('valuation', inView, params.sid),
  });

  const [ratioRef, ratioInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('ratio', inView, params.sid),
  });

  const [growthRef, growthInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('growth', inView, params.sid),
  });

  const [dividendRef, dividendInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('dividend', inView, params.sid),
  });

  const [currentYieldRef, currentYieldInView] = useInView({
    threshold: 0.09,
    onChange: (inView) => inViewChange('dividend', inView, params.sid),
  });

  const [holdingRef, holdingInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('holding', inView, params.sid),
  });

  const [assetRef, assetInView] = useInView({
    threshold: 0.09,
    onChange: (inView) => inViewChange('asset', inView, params.sid),
  });

  useEffect(() => {}, []);

  const dupTabs = [
    {
      name: 'Overview',
      selected:
        valuationInView ||
        growthInView ||
        dividendInView ||
        holdingInView ||
        assetInView
          ? false
          : true,
      exists: valuationInView ? false : overviewInView,
      tabs: [
        {
          name: 'ETF Description',
          selected: ETFInfoInView ? false : etfOverviewInView,
        },
        {
          name: 'Performance Chart',
          selected: ETFInfoInView,
        },
      ],
    },
    {
      name: 'Holdings',
      selected: assetInView ? false : holdingInView,
      exists: true,
      tabs: [],
    },
    {
      name: 'Asset Allocation',
      selected: valuationInView ? false : assetInView,
      exists: true,
      tabs: [],
    },

    {
      name: 'Valuation',
      selected: dividendInView ? false : valuationInView,
      exists: true,
      tabs: [{ name: 'Valuation Multiples', selected: ratioInView }],
    },
    // {
    //   name: 'Growth',
    //   selected: dividendInView ? false : growthInView,
    //   exists: true,
    //   tabs : []
    // },
    {
      name: 'Dividend',
      selected: dividendInView,
      exists: true,
      tabs: [{ name: 'Dividend Yield (FWD)', selected: currentYieldInView }],
    },
  ];

  return (
    <>
      {!notExists ? (
        <Flex
          bg={darkMode ? '#161616' : '#F4F4F4'}
          minH="90vh"
          mt="60px"
          overflowX={isMobile ? 'hidden' : 'visible'}
        >
          <Container maxW="container.xl">
            <Grid
              templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(4, 1fr)'}
              gap={isMobile ? 2 : 4}
              mt="5"
            >
              <GridItem rowSpan={1} colSpan={1}>
                <Box
                  as="ul"
                  position={isMobile ? 'fixed' : 'sticky'}
                  top={isMobile ? '64px' : '100px'}
                  right={isMobile ? '0.1' : ''}
                  marginBottom={isMobile ? '10px' : ''}
                  backgroundColor={
                    isMobile
                      ? !darkMode
                        ? '#F4F4F4'
                        : '#161616'
                      : 'transparent'
                  }
                  zIndex={9}
                  width={isMobile ? "101%" : '100%'}
                >
                  {!etfOverviewInView && ETFData.ETF_Data && (
                    <Flex
                      bg={
                        darkMode ? (isMobile ? '#262626' : '#262626') : 'white'
                      }
                      p="3"
                      flexDirection={isMobile ? 'row' : 'column'}
                      borderRadius="5px"
                      marginBottom="15px"
                      minW={isMobile ? '100%' : ''}
                    >
                      <Flex>
                        <Flex>
                          <Box>
                            <SectorLogo
                              value={ETFData.ETF_Data.ETF_Code}
                              size="large"
                              fill={darkMode ? 'white' : 'black'}
                            />
                          </Box>
                          <Flex
                            flexDirection="column"
                            ml={isMobile ? '2' : '2'}
                          >
                            <Text
                              fontWeight="bold"
                              color={darkMode ? 'white' : 'black'}
                            >
                              {ETFData.ETF_Data.ETF_Code}
                            </Text>
                            <Text
                              fontSize="small"
                              color={darkMode ? 'white' : 'black'}
                            >
                              <a href={ETFData.ETF_Data.ETF_URL} target="blank">
                                {' '}
                                <span
                                  style={{
                                    textDecoration: 'underline',
                                    textDecorationStyle: 'solid',
                                    color: '#0072db',
                                    textUnderlineOffset: '3px',
                                  }}
                                >
                                  {ETFData.ETF_Data.Name}{' '}
                                </span>
                              </a>
                            </Text>
                          </Flex>
                        </Flex>
                        {!isMobile && (
                          <img
                            src={
                              darkMode ? bookMarkLogoWhite : bookMarkLogoDark
                            }
                            style={{
                              marginLeft: 'auto',
                              width: '35px',
                              height: '35px',
                            }}
                          />
                        )}
                      </Flex>

                      <Flex
                        my={isMobile ? '0' : '4'}
                        gap="3"
                        marginLeft={isMobile ? '4' : '0'}
                      >
                        {!isMobile && (
                          <Flex flexDirection="column" marginRight="1">
                            <Text
                              fontWeight="bold"
                              fontSize="2xl"
                              color={darkMode ? 'white' : 'black'}
                            >
                              ${ETFData.ETF_Data.CurrentPrice}
                            </Text>

                            <Flex alignItems="center">
                              <TriangleDownIcon color="#be2e2c"></TriangleDownIcon>
                              <Text
                                color={darkMode ? '#EC5B55' : '#be2e2c'}
                                fontSize={isMobile ? '12px' : '17px'}
                              >
                                0.14% (-$5.30)
                              </Text>
                            </Flex>
                          </Flex>
                        )}

                        {!isMobile && (
                          <Flex flexDirection="column">
                            <Flex
                              fontWeight="bold"
                              fontSize="2xl"
                              alignItems="center"
                            >
                              <img
                                src={VerdeScoreSvg}
                                alt=""
                                className="w-4 h-4 mb-0.5 mr-1"
                              />
                              <Text color={darkMode ? 'white' : 'black'}>
                                {' '}
                                {ETFData.ETF_Data.verde_score}
                              </Text>
                            </Flex>
                            <Flex alignItems="center" gap="1">
                              <Text
                                fontWeight="bold"
                                color={darkMode ? 'white' : 'black'}
                                fontSize={isMobile ? '12px' : '17px'}
                              >
                                Verde Score
                              </Text>
                              <Box>
                                <InfoOutlineIcon
                                  color={darkMode ? '#58A8F2' : '#0072db'}
                                  height="15px"
                                  width="15px"
                                ></InfoOutlineIcon>
                              </Box>
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                      {isMobile && (
                        <img
                          src={darkMode ? bookMarkLogoWhite : bookMarkLogoDark}
                          style={{
                            marginLeft: 'auto',
                            marginRight: '20px',
                            width: '35px',
                            height: '35px',
                          }}
                        />
                      )}
                    </Flex>
                  )}

                  <Box
                    as="ul"
                    top={isMobile ? '64px' : '100px'}
                    display={isMobile ? 'flex' : 'block'}
                    marginBottom={isMobile ? '10px' : ''}
                    backgroundColor={
                      isMobile
                        ? !darkMode
                          ? '#F4F4F4'
                          : '#161616'
                        : 'transparent'
                    }
                    zIndex={9}
                    minWidth="100%"
                    overflowX={isMobile ? 'scroll' : 'hidden'}
                  >
                    {/* <h1 style={{fontSize:'55px', color:'pink',position:'sticky'}}>{dupTabs[0].name} , {JSON.stringify(dupTabs[0].selected)}</h1> */}

                    {dupTabs.map((tab) => (
                      <Box as="li" textAlign={isMobile ? 'center' : 'start'}>
                        <Text
                          p="2"
                          paddingLeft="16px"
                          borderBottom={
                            isMobile && tab.selected ? '4px' : 'none'
                          }
                          borderLeft={
                            tab.selected && !isMobile ? '4px' : 'none'
                          }
                          borderColor={
                            darkMode ? '#4ADF79' : theme.navItemSelectedBorder
                          }
                          minH="30px"
                          bg={
                            tab.selected
                              ? !isMobile
                                ? darkMode
                                  ? '#262626'
                                  : theme.navItemSelectedBg
                                : 'transparent'
                              : 'transparent'
                          }
                          boxSizing="border-box"
                          fontWeight="bold"
                          fontSize={isMobile ? '11px' : '16px'}
                          color={
                            tab.selected
                              ? darkMode
                                ? 'white'
                                : 'black'
                              : !darkMode
                              ? 'black'
                              : 'white'
                          }
                          width={isMobile ? '140px' : ''}
                        >
                          <div
                            style={{ cursor: 'pointer' }}
                            id={`tab-${tab.name}`}
                            onClick={() => {
                              var element = document.getElementById(
                                `${tab.name}`
                              );
                              if (element !== null) {
                                var headerOffset = isMobile ? 200 : 100;
                                var elementPosition = element!.getBoundingClientRect()
                                  .top;
                                var offsetPosition =
                                  elementPosition +
                                  window.pageYOffset -
                                  headerOffset;
                                history.push(`#${tab.name}`);

                                window.scrollTo({
                                  top: offsetPosition,
                                  behavior: 'smooth',
                                });
                                gtag('event', `${tab.name}-company-tag-click`, {
                                  event_category: tab.name,
                                  event_label: params.sid,
                                });
                              }
                            }}
                          >
                            {tab.name}
                          </div>
                        </Text>
                        {tab.tabs?.length &&
                        tab.selected &&
                        tab.exists &&
                        !isMobile ? (
                          <Box as="ul" ml="10">
                            {tab.tabs.map((childTab) => (
                              <Box
                                as="li"
                                boxSizing="border-box"
                                minH="30px"
                                p="2"
                                mb="14px"
                                pb="0"
                              >
                                <Text
                                  color={
                                    childTab.selected
                                      ? darkMode
                                        ? '#4ADF79'
                                        : theme.navItemSelectedColor
                                      : theme.navItemColor
                                  }
                                  fontWeight={
                                    childTab.selected ? 'bold' : 'normal'
                                  }
                                  fontSize="small"
                                >
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      scrollToElement(childTab.name, isMobile);
                                      gtag(
                                        'event',
                                        `${childTab.name}-company-tag-click`,
                                        {
                                          event_category: childTab.name,
                                          event_label: params.sid,
                                        }
                                      );
                                    }}
                                  >
                                    {childTab.name}
                                  </div>
                                </Text>
                              </Box>
                            ))}
                          </Box>
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={3} bg="" marginTop="20px" marginBottom="20px">
                <Flex flexDirection="column" gap="4">
                  {/* --------------------------------------OVERVIEW---------------------------------- */}

                  {ETFData.ETF_Data ? (
                    <Flex
                      id="Overview"
                      ref={overviewRef}
                      flexDirection="column"
                    >
                      <Flex
                        p="5"
                        id="ETF Description"
                        ref={etfOverviewRef}
                        bg={darkMode ? '#262626' : 'white'}
                        flexDirection="column"
                        borderRadius="5px"
                      >
                        <ETFOverview
                          data={ETFData}
                          darkMode={darkMode}
                          sid={params.sid}
                        />
                      </Flex>

                      {historyData.length &&
                      ETFData.ETF_Data &&
                      ETFData.Performance ? (
                        <Flex flexDirection="column">
                          <Flex
                            bg={darkMode ? '#262626' : 'white'}
                            ref={ETFInfoRef}
                            p="5"
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {performance}
                          </Flex>
                        </Flex>
                      ) : (
                        <LoadingIndicator />
                      )}
                    </Flex>
                  ) : (
                    <LoadingIndicator />
                  )}
                  {/* --------------------------------------OVERVIEW---------------------------------- */}

                  {/* --------------------------------------HOLDINGS---------------------------------- */}

                  {ETFData['Top 100 Holdings'] ? (
                    <Flex id="Holdings" ref={holdingRef} flexDirection="column">
                      <Flex
                        id="Earnings Projection"
                        bg={darkMode ? '#262626' : 'white'}
                        p="5"
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {holdings}
                      </Flex>
                    </Flex>
                  ) : (
                    <LoadingIndicator />
                  )}

                  {/* --------------------------------------HOLDINGS---------------------------------- */}

                  {/* --------------------------------------ASSETS---------------------------------- */}

                  {ETFData['Asset Allocation'] ? (
                    <Flex
                      id="Asset Allocation"
                      ref={assetRef}
                      flexDirection="column"
                    >
                      <Flex
                        bg={darkMode ? '#262626' : 'white'}
                        p="5"
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {assets}
                      </Flex>
                    </Flex>
                  ) : (
                    <LoadingIndicator />
                  )}

                  {/* --------------------------------------ASSETS---------------------------------- */}

                  {/* --------------------------------------VALUATION---------------------------------- */}

                  {ETFData.Valuation ? (
                    <Flex
                      id="Valuation"
                      className="valuation"
                      ref={valuationRef}
                      flexDirection="column"
                    >
                      {/* <Flex
                    bg={darkMode ? '#262626' : 'white'}
                    p="5"
                    flexDir="column"
                    mt="5"
                    borderRadius="5px"
                  >
                    {valuation}
                  </Flex> */}

                      <div id="Valuation Multiples" ref={ratioRef}>
                        {ETFData.Valuation && spyData.Valuation ? (
                          <Flex flexDirection="column">
                            <Flex
                              bg={darkMode ? '#262626' : 'white'}
                              p="5"
                              flexDir="column"
                              mt="5"
                              borderRadius="5px"
                            >
                              {priceToEanings}
                            </Flex>
                          </Flex>
                        ) : (
                          <LoadingIndicator />
                        )}

                        {ETFData.Valuation && spyData.Valuation ? (
                          <Flex flexDirection="column">
                            <Flex
                              bg={darkMode ? '#262626' : 'white'}
                              p="5"
                              flexDir="column"
                              mt="5"
                              borderRadius="5px"
                            >
                              {priceToEaningsGrowth}
                            </Flex>
                          </Flex>
                        ) : (
                          <LoadingIndicator />
                        )}

                        {ETFData.Valuation && spyData.Valuation ? (
                          <Flex flexDirection="column">
                            <Flex
                              bg={darkMode ? '#262626' : 'white'}
                              p="5"
                              flexDir="column"
                              mt="5"
                              borderRadius="5px"
                            >
                              {priceToBook}
                            </Flex>
                          </Flex>
                        ) : (
                          <LoadingIndicator />
                        )}

                        {ETFData.Valuation && spyData.Valuation ? (
                          <Flex flexDirection="column">
                            <Flex
                              bg={darkMode ? '#262626' : 'white'}
                              p="5"
                              flexDir="column"
                              mt="5"
                              borderRadius="5px"
                            >
                              {priceToSales}
                            </Flex>
                          </Flex>
                        ) : (
                          <LoadingIndicator />
                        )}
                      </div>
                    </Flex>
                  ) : (
                    <LoadingIndicator />
                  )}

                  {/* --------------------------------------VALUATION---------------------------------- */}

                  {/* --------------------------------------GROWTH---------------------------------- */}

                  {/* {ETFData.Growth !== {} ? (
                <Flex
                  id="Growth"
                  className="growth"
                  ref={growthRef}
                  flexDirection="column"
                >
                  <Flex
                    bg={darkMode ? '#262626' : 'white'}
                    p="5"
                    flexDir="column"
                    mt="5"
                    borderRadius="5px"
                  >
                    {growth}
                  </Flex>
                </Flex>
              ) : (
                <LoadingIndicator />
              )} */}

                  {/* --------------------------------------GROWTH---------------------------------- */}

                  {/* --------------------------------------DIVIDEND---------------------------------- */}

                  {ETFData.ETF_Data ? (
                    <Flex
                      id="Dividend"
                      ref={dividendRef}
                      flexDirection="column"
                    >
                      {/* <Flex
                    bg={darkMode ? '#262626' : 'white'}
                    p="5"
                    flexDir="column"
                    mt="5"
                    borderRadius="5px"
                  >
                    {dividend}
                  </Flex> */}

                      {ETFData.Valuation && spyData.Valuation ? (
                        <Flex flexDirection="column">
                          <Flex
                            bg={darkMode ? '#262626' : 'white'}
                            id="Dividend Yield (FWD)"
                            ref={currentYieldRef}
                            p="5"
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {currentYield}
                          </Flex>
                        </Flex>
                      ) : (
                        <LoadingIndicator />
                      )}
                    </Flex>
                  ) : (
                    <LoadingIndicator />
                  )}

                  {/* --------------------------------------DIVIDEND---------------------------------- */}
                </Flex>
              </GridItem>
            </Grid>
          </Container>
        </Flex>
      ) : (
        <NotExists isAndroid={false}/>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
