import React from 'react';
import LeaderBoardSvg from '../../../../public/images/leaderboard.svg';
import CrownSvg from '../../../../public/images/crown.svg';
import DefaultProfilePicture from '../../../../public/images/default-profile-picture.jpg';
import {
  Board,
  selectStyles,
  CrownedPersonImgContainer,
  CrownedPersonImg,
} from './style';
import Select from 'react-select';
import NumberBadge from '../../NumberBadge';

const options = [
  { value: 'This Month', label: 'This Month' },
  { value: 'Last 2 Months', label: 'Last 2 Months' },
  { value: 'Last 6 Months ', label: 'Last 6 Months' },
  { value: 'Last 12 Months ', label: 'Last 12 Months' },
];

const CrownedPerson = ({ size, position, className }) => {
  const containerSize = size + 8;
  return (
    <div className={className}>
      <img
        src={CrownSvg}
        alt=""
        className={`mx-auto relative top-3 lg:top-4 ${
          position === 1 ? 'w-7 h-7 lg:w-9 lg:h-9' : 'w-5 h-5 lg:w-7 lg:h-7'
        }`}
      />
      <CrownedPersonImgContainer size={containerSize}>
        <CrownedPersonImg src={DefaultProfilePicture} alt="" size={size} />
      </CrownedPersonImgContainer>
      <NumberBadge value={position} className="mx-auto relative bottom-6" />
      <div
        className={`text-white text-sm font-medium text-center ${
          position === 1 ? 'mt-3 lg:mt-5' : ''
        }`}
        style={{ width: containerSize + 'px' }}
      >
        <p className="truncate">Marian Ross</p>
        <p>83%</p>
      </div>
    </div>
  );
};

const LeaderboardCrown = (props) => {
  const leaderboardRef = React.useRef(null);
  React.useEffect(() => {
    if (leaderboardRef.current) {
      console.log(leaderboardRef.current.offsetWidth);
      console.log(leaderboardRef.current.offsetHeight);
    }
  }, []);
  return (
    <Board ref={leaderboardRef}>
      <img className="h-full w-full" src={LeaderBoardSvg} alt="" />
      <div className="absolute top-0 left-0 p-4 lg:p-6 w-full">
        <div className="flex justify-between">
          <div className="font-medium text-lg text-white">Top Performers</div>
          <div className="w-1/6">
            <Select
              className="text-sm"
              options={options}
              styles={selectStyles}
              defaultValue={options[0]}
            />
          </div>
        </div>
        <div className="flex justify-center relative bottom-8 xl:bottom-0 lg:bottom-11">
          <CrownedPerson size={100} position={2} className="mt-3 lg:mt-6" />
          <CrownedPerson size={100} position={1} className="mx-12" />
          <CrownedPerson size={100} position={3} className="mt-3 lg:mt-6" />
        </div>
      </div>
    </Board>
  );
};

export default LeaderboardCrown;
