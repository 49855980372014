import { put, takeEvery } from 'redux-saga/effects';
import { SCREENER_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';

function* fetchScreenerInfo(action) {
  yield put({ type: 'FETCH_SCREENER_DATA_LOADING' });
  try {
    const url = `${SCREENER_BASE_URL}/screener?offset=${action.offset}&limit=${action.limit}`;
    const response = yield wrappedAxios
      .post(url, action.payload)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_SCREENER_DATA_SUCCESS',
      data: response,
      pageSize: action.limit,
    });
  } catch (error) {
    yield put({ type: 'FETCH_SCREENER_DATA_ERROR', error: error.message });
  }
}

function* fetchFilterValues(action) {
  yield put({ type: 'FETCH_FILTER_VALUES_LOADING' });
  try {
    const url = `${SCREENER_BASE_URL}/filtervalues`;
    const response = yield wrappedAxios.get(url).then((response) => {
      return response.data;
    });
    yield put({
      type: 'FETCH_FILTER_VALUES_SUCCESS',
      data: response,
    });
  } catch (error) {
    yield put({ type: 'FETCH_FILTER_VALUES_ERROR', error: error.message });
  }
}

export function* watchFetchingScreenerData() {
  yield takeEvery('FETCH_SCREENER_DATA', fetchScreenerInfo);
  yield takeEvery('FETCH_FILTER_VALUES', fetchFilterValues);
}
