import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';

const override = css`
  display: block;
  margin: auto auto;
`;

const LoadingIndicator = ({ size = 30 }) => (
  <div className="flex-main">
    <ClipLoader css={override} size={size} loading={true} />
  </div>
);

export default LoadingIndicator;
