
import { createStore, applyMiddleware, compose } from 'redux';
import RootReducer from './reducer/index';
import { rootSaga } from './sagas/saga';
import createSagaMiddleware from 'redux-saga';
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
export const store = createStore(
    RootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);
export type Store = ReturnType<typeof store.getState>;
export type ValuationState = Pick<Store,"valuationState">
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;