import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import {
  getAdvancedTimelineChartData,
  getPresentDateForFutureGrowth,
} from '../../../../../utils/ChartUtils';
import AdvancedTimelineChart from '../../../AdvancedTimelineChart';
import SectionHeader from '../../../SectionHeader';

const EarningsPerShareProjection = ({ data, insights }) => {
  const earningsPerSharePositives = insights?.earningsPerShare?.positives
    ? insights?.earningsPerShare?.positives
    : [];
  const epsGrowthPositives = insights?.epsGrowth?.positives
    ? insights?.epsGrowth?.positives
    : [];
  const earningsPerShareNegatives = insights?.earningsPerShare?.negatives
    ? insights?.earningsPerShare?.negatives
    : [];
  const epsGrowthNegatives = insights?.epsGrowth?.negatives
    ? insights?.epsGrowth?.negatives
    : [];

  return (
    <>
      <SectionHeader
        value="Earnings Per Share Projection"
        className="pb-6"
        infoKey="eps"
      />
      <BorderedChartContainer className="py-4">
        <AdvancedTimelineChart
          data={getAdvancedTimelineChartData(data, [
            'epsAverage',
            'epsLow',
            'epsHigh',
            'epsActual',
            'evaluatedOn',
          ])}
          chartVariant="EPS"
          presentDate={getPresentDateForFutureGrowth(data)}
        />
      </BorderedChartContainer>
      {(earningsPerSharePositives.length > 0 ||
        epsGrowthPositives.length > 0) && (
        <BulletPoints
          title="Positives"
          points={[...earningsPerSharePositives, ...epsGrowthPositives]}
          isPositive={true}
        />
      )}
      {(earningsPerShareNegatives.length > 0 ||
        epsGrowthNegatives.length > 0) && (
        <BulletPoints
          title="Risks"
          points={[...earningsPerShareNegatives, ...epsGrowthNegatives]}
          isPositive={false}
        />
      )}
    </>
  );
};

export default EarningsPerShareProjection;
