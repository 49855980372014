import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import {
  ANNUALLY,
  getStackedAreaChartData,
} from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';

const LeverageRatios = ({ data, insights, period, setPeriod }) => {
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'Debt/Capital',
      selected: true,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'Debt/EBITDA',
      selected: true,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'Debt/Equity',
      selected: true,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    ['evaluated_on', 'debt_capital', 'debt_ebitda', 'debt_equity'],
    ANNUALLY
  );

  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['Debt/Capital'].selected
      ? {
          name: 'Debt/Capital',
          data: fullChartData.map((item) => [item[0], item[2]]),
        }
      : [],
    chartVariants['Debt/EBITDA'].selected
      ? {
          name: 'Debt/EBITDA',
          data: fullChartData.map((item) => [item[0], item[3]]),
        }
      : [],
    chartVariants['Debt/Equity'].selected
      ? {
          name: 'Debt/Equity',
          data: fullChartData.map((item) => [item[0], item[1]]),
        }
      : [],
  ];

  return (
    <>
      <SectionHeader className="mb-6" value="Leverage Ratios" />
      <BorderedChartContainer>
        {/* <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div> */}
        <StackedLineChart data={chartData} valueType="multiple" />
        <div className="flex justify-center">
          <MultiLineGraphSelectorButton
            values={buttonValues}
            setValues={setButtonValues}
          />
        </div>
      </BorderedChartContainer>

      <BulletPoints
        title="Positives"
        points={insights?.leverageRatio?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.leverageRatio?.negatives}
        isPositive={false}
      />
    </>
  );
};

export default LeverageRatios;
