import GrowthMetricCM from '../models/GrowthMetricCM';
import { formatDecimalValue } from '../utils/number';

const determineMarket = (indicator) => {
  if (indicator === 'industry') return 'Sector';
  if (indicator === 'market') return 'S&P 500';
  return 'Company';
};

export const deserialise = (payload) =>
  new GrowthMetricCM.builder()
    .market(determineMarket(payload.indicator))
    .oneWeek(formatDecimalValue(payload.one_week))
    .oneMonth(formatDecimalValue(payload.one_month))
    .threeMonths(formatDecimalValue(payload.three_months))
    .sixMonths(formatDecimalValue(payload.six_months))
    .ytd(formatDecimalValue(payload.ytd))
    .oneYear(formatDecimalValue(payload.one_year))
    .fiveYears(formatDecimalValue(payload.five_years))
    .build();
