const tagIds = {
  'beta.verde.finance': 'G-KEH6H3775J',
  'verde.finance': 'G-ZW4S6W253L',
  'dev.verde.finance': 'G-8M82TGMTFJ',
};
export const getGtagURL = () => {
  const host = window.location.host;
  //console.log('gtag-host', host);
  return tagIds[host] || tagIds['verde.finance'];
};

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

export const gtag = (...args) => {
  if (window.gtag) window.gtag(...args);
  else console.log('gta', ...args);
  return false;
};
