import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const Scale = styled.div`
  ${tw`w-full h-2`};
  background: linear-gradient(
    to right,
    #2acb73,
    #2acb73,
    #f7bb44,
    #f7bb44,
    #dc5f3d,
    #dc5f3d
  );
`;

export const Marker = styled.div`
  ${tw`w-0 h-1.5 border-r`};
  border-color: #515151;
`;

export const MarkerText = styled.div`
  ${tw`md:text-sm relative`};
  font-size: 13px;
  color: ${(props) => props.theme.ratioGraph.color};
`;

export const Bar = styled.div`
  height: 30px;
  border: 1px solid ${(props) => props.borderColor};
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
  background-color: ${(props) => props.bgColor};
`;

export const BarName = styled.div`
  ${tw`ml-2 md:ml-4 my-2 md:my-1 text-sm font-medium`};
  color: ${(props) => props.color};
`;

export const BarValue = styled.div`
  ${tw`absolute text-sm font-medium ml-2 mt-2 md:mt-1.5`};
  color: ${(props) => props.color};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')}px;
`;

export const Container = styled.div`
  ${tw`my-8 pt-8 rounded`};
  background-color: ${(props) => props.theme.ratioGraph.background};
  border: 1px solid ${(props) => props.theme.ratioGraph.border};
`;
