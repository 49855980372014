import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { css } from '@emotion/core';
import LoadingImg from '../../../public/images/loading-icon.svg';

const override = css`
  margin: auto;
`;

const LoadingSectionIndicator = ({ size = 60, open }) => {
  return (
    <div className="flex justify-center items-center mx-auto overflow-hidden relative">
      <MoonLoader color="#54A579" css={override} size={size} loading={true} />
    </div>
  );
};

export default LoadingSectionIndicator;
