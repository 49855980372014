import React from 'react';
import {
  ScaleSegment,
  ScaleStartSection,
  ScaleEndSection,
  CurrentPriceMarker,
  FairPriceMarker,
  TargetPriceMarker,
  VerticalLine,
  ValuedTextMarker,
  PriceTextMarker,
  MarkerImg,
  InnerScaleSegment,
  InnerScaleStartSection,
  InnerScaleEndSection,
  PriceHeader,
  PriceValue,
  UpArrow,
  OvervaluedPercent,
} from './style';
import { ColoredContentText } from '../StyedComponents';
import DomainIcon from '../DomainIcon';
import DomainTerm from '../DomainTerm';

const VerdeScale = ({ data }) => {
  const almondScaleGraphRef = React.useRef();
  const [totalWidth, setTotalWidth] = React.useState(0);

  React.useEffect(() => {
    setTotalWidth(Math.round(almondScaleGraphRef.current.offsetWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fairPrice = data.fairPrice;
  const currentPrice = data.currentPrice;
  const targetPrice = data.targetPrice;

  const startSectionBegin = Math.round(0.8 * fairPrice);
  const startSectionFinish = Math.round(0.94 * fairPrice);
  const endSectionBegin = Math.round(1.06 * fairPrice);
  const endSectionFinish = Math.round(1.2 * fairPrice);

  const startSectionWidth = startSectionFinish - startSectionBegin;
  const midSectionWidth = endSectionBegin - startSectionFinish;
  const endSectionWidth = endSectionFinish - endSectionBegin;
  const totalSectionWidth =
    startSectionWidth + midSectionWidth + endSectionWidth;
  const offsetValue = totalWidth / totalSectionWidth;

  const fairValueMarkerWidth = 38;
  const currentPriceMarkerWidth = 38;
  const priceTextMarkerWidth = 85;
  const targetPriceMarkerWidth = 38;

  const innerStartSectionActualWidth =
    Math.round(offsetValue * startSectionWidth) * 0.92;
  const innerMidSectionActualWidth =
    Math.round(offsetValue * midSectionWidth) * 0.98;
  const innerEndSectionActualWidth =
    Math.round(offsetValue * endSectionWidth) * 0.92;

  const totalInnerWidth =
    innerStartSectionActualWidth +
    innerMidSectionActualWidth +
    innerEndSectionActualWidth;

  const innerOffsetValue = totalInnerWidth / totalSectionWidth;

  const determineCurrentPriceMark = () => {
    const localOffset = currentPriceMarkerWidth / 2;
    if (currentPrice > endSectionFinish) {
      return totalInnerWidth - localOffset;
    } else if (currentPrice < startSectionBegin) {
      return -localOffset;
    } else {
      return (
        Math.round((currentPrice - startSectionBegin) * innerOffsetValue) -
        localOffset
      );
    }
  };

  const determineCurrentPriceVerticalLineMark = () => {
    const localOffset = currentPriceMarkerWidth + 1;
    if (currentPrice > endSectionFinish) {
      return totalInnerWidth - localOffset;
    } else if (currentPrice < startSectionBegin) {
      return -localOffset;
    } else {
      return (
        Math.round((currentPrice - startSectionBegin) * innerOffsetValue) -
        localOffset
      );
    }
  };

  const determineCurrentPriceTextMark = () => {
    if (currentPrice > endSectionFinish) {
      return totalInnerWidth - priceTextMarkerWidth;
    } else if (currentPrice < startSectionBegin) {
      return 0;
    } else if (fairPrice > currentPrice) {
      const minWidth = Math.round(
        (fairPrice - currentPrice) * innerOffsetValue
      );
      return minWidth > priceTextMarkerWidth + currentPriceMarkerWidth
        ? determineCurrentPriceVerticalLineMark() + priceTextMarkerWidth / 2
        : determineCurrentPriceVerticalLineMark() - priceTextMarkerWidth / 2;
    } else if (fairPrice <= currentPrice) {
      const minWidth = Math.round(
        (currentPrice - fairPrice) * innerOffsetValue
      );
      return minWidth > priceTextMarkerWidth + currentPriceMarkerWidth
        ? determineCurrentPriceVerticalLineMark() - priceTextMarkerWidth / 2
        : determineCurrentPriceVerticalLineMark() + priceTextMarkerWidth / 2;
    }
  };

  const determineFairPriceVerticalLineMark = () =>
    Math.round((fairPrice - startSectionBegin) * innerOffsetValue) -
    currentPriceMarkerWidth -
    fairValueMarkerWidth -
    1;

  const determineValuedTextMark = () => {
    const fairPriceMark = determineFairPriceVerticalLineMark();
    const currentPriceMark = determineCurrentPriceVerticalLineMark();
    return fairPriceMark < currentPriceMark
      ? fairPriceMark + (currentPriceMark - fairPriceMark) / 2
      : currentPriceMark + (fairPriceMark - currentPriceMark) / 2;
  };

  const determineTargetPriceVerticalLineMark = () => {
    const localOffset =
      currentPriceMarkerWidth +
      fairValueMarkerWidth +
      targetPriceMarkerWidth +
      6;
    if (targetPrice > endSectionFinish) {
      return totalInnerWidth - localOffset;
    } else if (targetPrice < startSectionBegin) {
      return -localOffset;
    } else {
      return (
        Math.round((targetPrice - startSectionBegin) * innerOffsetValue) -
        localOffset
      );
    }
  };

  const determineTargetPriceMark = () => {
    return determineTargetPriceVerticalLineMark() + targetPriceMarkerWidth / 2;
  };

  const determineTargetPriceTextMark = () => {
    return (
      determineTargetPriceVerticalLineMark() +
      currentPriceMarkerWidth +
      (fairPrice > targetPrice ? fairValueMarkerWidth : 8)
    );
  };

  const determineValuedTextMarkTop = () =>
    Math.abs(currentPrice - fairPrice) * offsetValue >
    priceTextMarkerWidth + priceTextMarkerWidth / 2
      ? -88
      : 0;

  return (
    <div ref={almondScaleGraphRef} className="container mt-16 mb-4">
      <div
        className="flex items-center"
        style={{
          width: totalInnerWidth,
          marginLeft: Math.round(offsetValue * startSectionWidth) * 0.08,
        }}
      >
        <PriceTextMarker
          mark={determineTargetPriceTextMark()}
          width={priceTextMarkerWidth}
          style={{ top: '-50px' }}
        >
          <PriceHeader>
            <DomainTerm infoKey="target_price">Target Price</DomainTerm>
          </PriceHeader>
          <PriceValue>${targetPrice}</PriceValue>
        </PriceTextMarker>
      </div>
      <div className="flex items-center">
        <ScaleStartSection
          width={Math.round(offsetValue * startSectionWidth)}
          height={44}
        >
          <InnerScaleStartSection
            type="greenSection"
            width={Math.round(offsetValue * startSectionWidth) * 0.9}
            height={18}
            style={{
              marginLeft: Math.round(offsetValue * startSectionWidth) * 0.08,
            }}
          />
        </ScaleStartSection>
        <ScaleSegment
          width={Math.round(offsetValue * midSectionWidth)}
          height={44}
        >
          <InnerScaleSegment
            type="amberSection"
            width={Math.round(offsetValue * midSectionWidth) * 0.98}
            height={18}
          />
        </ScaleSegment>
        <ScaleEndSection
          width={Math.round(offsetValue * endSectionWidth)}
          height={44}
        >
          <InnerScaleEndSection
            type="redSection"
            width={Math.round(offsetValue * endSectionWidth) * 0.9}
            height={18}
            style={{
              marginRight: Math.round(offsetValue * endSectionWidth) * 0.08,
            }}
          />
        </ScaleEndSection>
      </div>
      <div
        className="flex items-center"
        style={{
          width: totalInnerWidth,
          marginLeft: Math.round(offsetValue * startSectionWidth) * 0.08,
        }}
      >
        <CurrentPriceMarker
          mark={determineCurrentPriceMark()}
          width={currentPriceMarkerWidth}
        >
          <DomainIcon value="current-price-marker" />
        </CurrentPriceMarker>
        <VerticalLine
          mark={determineCurrentPriceVerticalLineMark()}
          top="-40"
        />
        <FairPriceMarker
          mark={
            Math.round((fairPrice - startSectionBegin) * innerOffsetValue) -
            currentPriceMarkerWidth -
            fairValueMarkerWidth / 2
          }
          width={fairValueMarkerWidth}
        >
          <DomainIcon value="fair-price-marker" />
        </FairPriceMarker>
        <VerticalLine mark={determineFairPriceVerticalLineMark()} top="-40" />
        <TargetPriceMarker
          mark={determineTargetPriceMark()}
          width={targetPriceMarkerWidth}
        >
          <DomainIcon value="target-price-marker" />
        </TargetPriceMarker>
        <VerticalLine mark={determineTargetPriceVerticalLineMark()} top="-53" />
      </div>
      <div
        className="flex items-center"
        style={{
          width: totalInnerWidth,
          marginLeft: Math.round(offsetValue * startSectionWidth) * 0.08,
        }}
      >
        <PriceTextMarker
          mark={determineCurrentPriceTextMark()}
          width={priceTextMarkerWidth}
        >
          <PriceHeader>Current Price</PriceHeader>
          <PriceValue>${currentPrice}</PriceValue>
        </PriceTextMarker>
        <PriceTextMarker
          mark={
            Math.round((fairPrice - startSectionBegin) * innerOffsetValue) -
            (fairPrice > currentPrice
              ? priceTextMarkerWidth
              : priceTextMarkerWidth * 2)
          }
          width={priceTextMarkerWidth}
        >
          <PriceHeader>
            <DomainTerm infoKey="fair_price">Fair Price</DomainTerm>
          </PriceHeader>
          <PriceValue>${fairPrice}</PriceValue>
        </PriceTextMarker>
      </div>
      <div
        className="flex items-center"
        style={{
          width: totalInnerWidth,
          marginLeft: Math.round(offsetValue * startSectionWidth) * 0.08,
        }}
      >
        <ValuedTextMarker
          mark={determineValuedTextMark()}
          width={priceTextMarkerWidth}
          top={determineValuedTextMarkTop()}
        >
          <UpArrow
            visible={determineValuedTextMarkTop() === 0}
            mark={priceTextMarkerWidth / 2}
          />
          <div className="relative bottom-1">
            <OvervaluedPercent
              isOvervalued={data.overvaluedIndicator}
              className="text-sm font-semibold"
            >
              {data.fairPriceDeviation}%
            </OvervaluedPercent>
            <ColoredContentText className="text-xs text-black">
              {data.valuationText}
            </ColoredContentText>
          </div>
        </ValuedTextMarker>
      </div>
    </div>
  );
};

export default VerdeScale;
