import React, { useEffect, useState } from 'react';
import MobileSuperInvestorSummary from '../../../shared/MobileSuperInvestorSummary';
import { Text, useMediaQuery } from '@chakra-ui/react';

export default function MobileSummary({
  data,
  darkMode,
  showPortfolioCallback,
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  console.log(data);
  return (
    <div>
      <MobileSuperInvestorSummary
        data={data}
        darkMode={darkMode}
        showPortfolioCallback={showPortfolioCallback}
      />
    </div>
  );
}
