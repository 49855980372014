import React from 'react';
import { connect } from 'react-redux';
import ValuationSummary from '../../shared/Sections/Valuation/ValuationSummary';
import AnalystRatings from '../../shared/Sections/Valuation/AnalystRatings';
import ValuationPriceComparison from '../../shared/Sections/Valuation/ValuationPriceComparison';
import StockInformation from '../../shared/Sections/Valuation/StockInformation';
import PriceToEarnings from '../../shared/Sections/Valuation/PriceToEarnings';
import PriceToEarningsGrowth from '../../shared/Sections/Valuation/PriceToEarningsGrowth';
import PriceToBook from '../../shared/Sections/Valuation/PriceToBook';
import PriceToSales from '../../shared/Sections/Valuation/PriceToSales';
import { SectionDivider } from '../../shared/StyedComponents';
import { showPageLoading } from '../../../actions/coreAction';
import { showErrorAlert } from '../../../actions/alertAction';
import MarketCapMultiples from '../../shared/Sections/PastPerformance/MarketCapMultiples';
import EnterpriseValueMultiples from '../../shared/Sections/PastPerformance/EnterpriseValueMultiples';
import { ANNUALLY } from '../../../utils/ChartUtils';
import { useParams } from 'react-router-dom';

function ValuationComponent({
  valuationData,
  stockData,
  loading,
  showPageLoading,
  showErrorAlert,
  error,
  insights,
  pastPerformanceInsights,
  pastPerformanceData,
}) {
  const [period, setPeriod] = React.useState(ANNUALLY);
  React.useEffect(() => {
    showPageLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  /* React.useEffect(() => {
    error && showErrorAlert(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]); */

  if (error) throw new Error('Error in valuation or stock history api');
  let { sid } = useParams();

  return valuationData && valuationData.symbol ? (
    <>
      <ValuationSummary data={valuationData} insights={insights?.summary} />
      <SectionDivider />
      <AnalystRatings company={sid} data={valuationData} />
      <SectionDivider />
      <ValuationPriceComparison data={valuationData} />
      <SectionDivider />
      {stockData.length > 0 && (
        <>
          <StockInformation data={stockData} valuationData={valuationData} />
          <SectionDivider />
        </>
      )}
      <PriceToEarnings
        data={valuationData.priceToEarnings}
        symbol={valuationData.symbol.id}
        insights={insights?.priceToEarnings}
      />
      <SectionDivider />
      <PriceToEarningsGrowth
        data={valuationData.priceToEarningsGrowth}
        symbol={valuationData.symbol.id}
        insights={insights?.priceToEarningsGrowth}
      />
      <SectionDivider />
      <PriceToBook
        data={valuationData.priceToBook}
        symbol={valuationData.symbol.id}
        insights={insights?.priceToBook}
      />
      <SectionDivider />
      <PriceToSales
        data={valuationData.priceToSales}
        symbol={valuationData.symbol.id}
        insights={insights?.priceToSales}
      />
      {pastPerformanceData && pastPerformanceData.length > 0 && (
        <>
          <SectionDivider />
          <MarketCapMultiples
            data={pastPerformanceData}
            insights={insights}
            period={period}
            setPeriod={setPeriod}
          />
          <SectionDivider />
          <EnterpriseValueMultiples
            data={pastPerformanceData}
            insights={insights}
            period={period}
            setPeriod={setPeriod}
          />
        </>
      )}
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  valuationData: state.valuationState.data,
  stockData: state.stockHistoryState.data,
  insights: state.symbolInsightsState.data?.valuation,
  pastPerformanceInsights: state.symbolInsightsState.data?.pastPerformance,
  pastPerformanceData: state.pastPerformanceState.data,
  loading:
    state.valuationState.loading ||
    state.stockHistoryState.loading ||
    state.pastPerformanceState.loading ||
    state.realTimeState.loading,
  error: state.stockHistoryState.error || state.valuationState.error,
});

const mapDispatchToProps = {
  showPageLoading,
  showErrorAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValuationComponent);
