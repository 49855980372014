import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import tw from 'twin.macro';
import { styles } from './styles.js';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import { useMediaQuery } from '@chakra-ui/react';
import HoldingsImages from '../HoldingsImages/index.js';

export default function MobilePortfolioSummary({
  data,
  indData,
  darkMode,
  label,
  showPortfolioCallback,
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const percentVal =
    (data.account_details &&
      data.account_details.portfolio_profit_loss /
        (data.account_details &&
          data.account_details.portfolio_invested_amount)) * 100;

  const verdeScore =
    data.account_details && data.account_details.portfolio_verde_score;

  function listPortfolios() {
    let resultArray = [];
    const obj = indData[0];

    for (let portfolioCompanyName in obj) {
      for (let portfolio in obj[portfolioCompanyName]) {
        let resultObj = obj[portfolioCompanyName][portfolio]['account_details'];
        resultObj = {
          portfolioName: `${portfolioCompanyName}: ${obj[portfolioCompanyName][portfolio]['account_details']['account_name']} - ${obj[portfolioCompanyName][portfolio]['account_details']['account_number']}`,
          holdings: [
            ...obj[portfolioCompanyName][portfolio]['account_holdings'],
          ],
          ...resultObj,
        };
        resultArray.push(resultObj);
      }
    }

    return resultArray;
  }
  console.log(data);

  return (
    <div style={{ padding: 20, paddingTop: 5, paddingBottom: 5 }}>
      <div
        style={{
          backgroundColor: darkMode ? 'rgba(38,38,38,0.5)' : 'rgb(246,248,250)',
          padding: 15,
          borderRadius: 10,
          paddingTop: 25,
          paddingBottom: 25,
        }}
      >
        {/* My Portfolio */}
        <Flex justifyContent="flex-start" marginBottom={5}>
          <Box>
            <Text style={{ ...styles(darkMode)['text'] }}>My Portfolio</Text>
            <Text style={{ ...styles(darkMode)['grayText'] }}>
              {listPortfolios().length} Linked Portfolios
            </Text>
          </Box>
          <Box
            marginLeft="auto"
            marginRight={5}
            onClick={() => {
              showPortfolioCallback();
            }}
            cursor="pointer"
          >
            <FontAwesomeIcon
              icon={faArrowRight}
              color={darkMode ? 'white' : 'black'}
              className="ml-2 fa-1x"
            />
          </Box>
        </Flex>
        <Box marginTop={5}>
          <Flex>
            <Box>
              <Text style={{ ...styles(darkMode)['grayText'] }}>
                Portfolio Value
              </Text>
              <Text style={{ ...styles(darkMode)['text'] }}>
                $
                {parseFloat(
                  data.account_details && data.account_details.portfolio_value
                ).toLocaleString()}
              </Text>
              <Flex alignItems="center">
                {percentVal > 0 ? (
                  <TriangleUpIcon
                    color={darkMode ? '#4adf79' : '#01874e'}
                  ></TriangleUpIcon>
                ) : (
                  <TriangleDownIcon
                    color={darkMode ? '#ec5b55' : '#BF2F2C'}
                  ></TriangleDownIcon>
                )}
                <Text
                  marginLeft={2}
                  style={{ ...styles(darkMode,percentVal)['percentVal'] }}
                >
                  {parseFloat(percentVal).toFixed(2)}%
                </Text>
              </Flex>
            </Box>
            <Box marginLeft="auto">
              <Text style={{ ...styles(darkMode)['grayText'] }}>
                Verde Score
              </Text>
              <Flex>
                <img
                  src={VerdeScoreSvg}
                  alt=""
                  className="w-5 h-5 mb-0.5 mr-1 mt-1"
                />
                <Text
                  style={{
                    color: darkMode ? '#f6f6f6' : 'black',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {verdeScore}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </div>

      <Box marginTop={10}>
        <Flex>
          <Text style={{ ...styles(darkMode)['text'] }}>List Portfolios</Text>
          <Box
            backgroundColor={
              darkMode ? 'rgba(1,160,91,0.15)' : 'rgba(1,135,78,0.15)'
            }
            alignItems="center"
            padding={0.5}
            paddingLeft={2}
            paddingRight={2}
            borderRadius={5}
            marginLeft={2}
          >
            <Text style={{ ...styles(darkMode)['text'], fontSize: 14 }}>
              {listPortfolios().length}
            </Text>
          </Box>
        </Flex>

        <Flex marginTop={5} overflowX="scroll">
          {listPortfolios().map((elem) => {
            return (
              <div
                style={{
                  backgroundColor: darkMode
                    ? 'rgba(38,38,38,0.5)'
                    : 'rgb(246,248,250)',
                  padding: 15,
                  borderRadius: 10,
                  marginRight: 10,
                }}
              >
                <Flex justifyContent="flex-start" marginBottom={5} width={275}>
                  <Box>
                    <Text style={{ ...styles(darkMode)['text'] }}>
                      {elem['portfolioName']}
                    </Text>
                  </Box>
                  <Box marginLeft="auto">
                    <Flex>
                      <img
                        src={VerdeScoreSvg}
                        alt=""
                        className="w-3 h-3 mb-0.5 mr-1 mt-1"
                      />
                      <Text
                        style={{
                          color: darkMode ? '#f6f6f6' : 'black',
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                      >
                        {elem.portfolio_verde_score}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
                <Box marginTop={5}>
                  <Flex>
                    <Box>
                      <Text style={{ ...styles(darkMode)['grayText'] }}>
                        Portfolio Value
                      </Text>
                      <Text style={{ ...styles(darkMode)['text'] }}>
                        ${parseFloat(elem.portfolio_value).toLocaleString()}
                      </Text>
                      <Flex alignItems="center">
                        {percentVal > 0 ? (
                          <TriangleUpIcon
                            color={darkMode ? '#4adf79' : '#01874e'}
                          ></TriangleUpIcon>
                        ) : (
                          <TriangleDownIcon
                            color={darkMode ? '#ec5b55' : '#BF2F2C'}
                          ></TriangleDownIcon>
                        )}
                        <Text
                          marginLeft={2}
                          style={{ ...styles(darkMode)['percentVal'], color: percentVal > 0 ? darkMode ? '#4adf79' : '#01874e' :darkMode ? '#ec5b55' : '#BF2F2C' }}
                        >
                          {parseFloat(
                            (elem.portfolio_profit_loss /
                              elem.portfolio_invested_amount) *
                              100
                          ).toFixed(2)}
                          %
                        </Text>
                      </Flex>
                    </Box>
                    <Box marginLeft="auto">
                      <HoldingsImages
                        holdings={elem.holdings.length > 0 && elem.holdings}
                      />
                    </Box>
                  </Flex>
                </Box>
              </div>
            );
          })}
        </Flex>
      </Box>
    </div>
  );
}
