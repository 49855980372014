import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import { getStackedAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';

const EnterpriseValueMultiples = ({ data, insights, period, setPeriod }) => {
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'EV/Revenue',
      selected: false,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'EV/EBITDA',
      selected: true,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'EV/EBIT',
      selected: false,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    ['evaluated_on', 'ev_revenue', 'ev_ebitda', 'ev_ebit'],
    period
  );

  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['EV/Revenue'].selected
      ? {
          name: 'EV/Revenue',
          data: fullChartData.map((item) => [item[0], item[1]]),
        }
      : [],
    chartVariants['EV/EBITDA'].selected
      ? {
          name: 'EV/EBITDA',
          data: fullChartData.map((item) => [item[0], item[2]]),
        }
      : [],
    chartVariants['EV/EBIT'].selected
      ? {
          name: 'EV/EBIT',
          data: fullChartData.map((item) => [item[0], item[3]]),
        }
      : [],
  ];

  const evEbitAveragePositives = insights?.evEbit?.average?.positives
    ? insights?.evEbit?.average?.positives
    : [];
  const evEbitIndustryPositives = insights?.evEbit?.industry?.positives
    ? insights?.evEbit?.industry?.positives
    : [];
  const evEbitMarketPositives = insights?.evEbit?.market?.positives
    ? insights?.evEbit?.market?.positives
    : [];
  const evEbitAverageNegatives = insights?.evEbit?.average?.negatives
    ? insights?.evEbit?.average?.negatives
    : [];
  const evEbitIndustryNegatives = insights?.evEbit?.industry?.negatives
    ? insights?.evEbit?.industry?.negatives
    : [];
  const evEbitMarketNegatives = insights?.evEbit?.market?.negatives
    ? insights?.evEbit?.market?.negatives
    : [];

  const evEbitdaAveragePositives = insights?.evEbitda?.average?.positives
    ? insights?.evEbitda?.average?.positives
    : [];
  const evEbitdaIndustryPositives = insights?.evEbitda?.industry?.positives
    ? insights?.evEbitda?.industry?.positives
    : [];
  const evEbitdaMarketPositives = insights?.evEbitda?.market?.positives
    ? insights?.evEbitda?.market?.positives
    : [];
  const evEbitdaAverageNegatives = insights?.evEbitda?.average?.negatives
    ? insights?.evEbitda?.average?.negatives
    : [];
  const evEbitdaIndustryNegatives = insights?.evEbitda?.industry?.negatives
    ? insights?.evEbitda?.industry?.negatives
    : [];
  const evEbitdaMarketNegatives = insights?.evEbitda?.market?.negatives
    ? insights?.evEbitda?.market?.negatives
    : [];

  const evRevenueAveragePositives = insights?.evRevenue?.average?.positives
    ? insights?.evRevenue?.average?.positives
    : [];
  const evRevenueIndustryPositives = insights?.evRevenue?.industry?.positives
    ? insights?.evRevenue?.industry?.positives
    : [];
  const evRevenueMarketPositives = insights?.evRevenue?.market?.positives
    ? insights?.evRevenue?.market?.positives
    : [];
  const evRevenueAverageNegatives = insights?.evRevenue?.average?.negatives
    ? insights?.evRevenue?.average?.negatives
    : [];
  const evRevenueIndustryNegatives = insights?.evRevenue?.industry?.negatives
    ? insights?.evRevenue?.industry?.negatives
    : [];
  const evRevenueMarketNegatives = insights?.evRevenue?.market?.negatives
    ? insights?.evRevenue?.market?.negatives
    : [];

  return (
    <>
      <SectionHeader className="mb-6" value="Enterprise Value Multiples" />
      <BorderedChartContainer>
        <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div>
        <StackedLineChart data={chartData} valueType="multiple" />
        <div className="flex justify-center">
          <MultiLineGraphSelectorButton
            values={buttonValues}
            setValues={setButtonValues}
          />
        </div>
      </BorderedChartContainer>

      {/* <BulletPoints
        title="Positives"
        points={[
          ...evEbitAveragePositives,
          ...evEbitIndustryPositives,
          ...evEbitMarketPositives,
          ...evEbitdaAveragePositives,
          ...evEbitdaIndustryPositives,
          ...evEbitdaMarketPositives,
          ...evRevenueAveragePositives,
          ...evRevenueIndustryPositives,
          ...evRevenueMarketPositives,
        ]}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={[
          ...evEbitAverageNegatives,
          ...evEbitIndustryNegatives,
          ...evEbitMarketNegatives,
          ...evEbitdaAverageNegatives,
          ...evEbitdaIndustryNegatives,
          ...evEbitdaMarketNegatives,
          ...evRevenueAverageNegatives,
          ...evRevenueIndustryNegatives,
          ...evRevenueMarketNegatives,
        ]}
        isPositive={false}
      /> */}
    </>
  );
};

export default EnterpriseValueMultiples;
