import React from 'react';
import { connect } from 'react-redux';
import Slider from '../../Slider';
import CheckContainer from '../../Checkbox';
import { Divider } from '../../StyedComponents';
import { FilterName } from './style';
import Select from 'react-select';
import { selectStyles } from '../DefaultFilters/style';
import { updateFilters } from '../../../../actions/screenerAction';

const CustomFilters = (props) => {
  const {
    filterValues,
    customFiltersToApply,
    setCustomFiltersToApply,
    appliedFilters,
  } = props;

  return (
    <div className="px-5">
      <FilterName className="mb-4">Sector</FilterName>
      <Select
        className="text-sm"
        options={filterValues.sectors.map((s) => ({
          value: s,
          label: s,
        }))}
        defaultValue={
          appliedFilters.sectors.length > 0 ? appliedFilters.sectors[0] : null
        }
        styles={selectStyles}
        isClearable={true}
        onChange={(selected) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            sectors: selected ? [selected] : [],
          });
        }}
      />
      <Divider />

      <FilterName className="mb-4">Industry</FilterName>
      <Select
        className="text-sm"
        options={filterValues.industries.map((s) => ({
          value: s,
          label: s,
        }))}
        styles={selectStyles}
        isClearable={true}
        onChange={(selected) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            industries: selected ? [selected] : [],
          });
        }}
      />
      <Divider />

      <FilterName className="mb-12">Verde Score</FilterName>
      <Slider
        value={
          customFiltersToApply.verdeScore
            ? customFiltersToApply.verdeScore
            : filterValues.verdeScore
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            verdeScore: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">Overvalued/Undervalued %</FilterName>
      <Slider
        min={filterValues.overvaluedOrUndervaluedPercent[0]}
        max={filterValues.overvaluedOrUndervaluedPercent[1]}
        value={
          customFiltersToApply.overvaluedOrUndervaluedPercent
            ? customFiltersToApply.overvaluedOrUndervaluedPercent
            : filterValues.overvaluedOrUndervaluedPercent
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            overvaluedOrUndervaluedPercent: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">Market Cap</FilterName>
      <Slider
        type="market-cap"
        min={filterValues.marketCap[0]}
        max={filterValues.marketCap[1]}
        value={
          customFiltersToApply.marketCap
            ? customFiltersToApply.marketCap
            : filterValues.marketCap
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            marketCap: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">Price To Earnings</FilterName>
      <Slider
        min={filterValues.priceToEarnings[0]}
        max={filterValues.priceToEarnings[1]}
        value={
          customFiltersToApply.priceToEarnings
            ? customFiltersToApply.priceToEarnings
            : filterValues.priceToEarnings
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            priceToEarnings: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">1W Return %</FilterName>
      <Slider
        min={filterValues.oneWeekReturnPercent[0]}
        max={filterValues.oneWeekReturnPercent[1]}
        value={
          customFiltersToApply.oneWeekReturnPercent
            ? customFiltersToApply.oneWeekReturnPercent
            : filterValues.oneWeekReturnPercent
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            oneWeekReturnPercent: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">1M Return %</FilterName>
      <Slider
        min={filterValues.oneMonthReturnPercent[0]}
        max={filterValues.oneMonthReturnPercent[1]}
        value={
          customFiltersToApply.oneMonthReturnPercent
            ? customFiltersToApply.oneMonthReturnPercent
            : filterValues.oneMonthReturnPercent
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            oneMonthReturnPercent: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">6M Return %</FilterName>
      <Slider
        min={filterValues.sixMonthsReturnPercent[0]}
        max={filterValues.sixMonthsReturnPercent[1]}
        value={
          customFiltersToApply.sixMonthsReturnPercent
            ? customFiltersToApply.sixMonthsReturnPercent
            : filterValues.sixMonthsReturnPercent
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            sixMonthsReturnPercent: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">YTD Return %</FilterName>
      <Slider
        min={filterValues.yearToDateReturnPercent[0]}
        max={filterValues.yearToDateReturnPercent[1]}
        value={
          customFiltersToApply.yearToDateReturnPercent
            ? customFiltersToApply.yearToDateReturnPercent
            : filterValues.yearToDateReturnPercent
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            yearToDateReturnPercent: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">1Y Return %</FilterName>
      <Slider
        min={filterValues.oneYearReturnPercent[0]}
        max={filterValues.oneYearReturnPercent[1]}
        value={
          customFiltersToApply.oneYearReturnPercent
            ? customFiltersToApply.oneYearReturnPercent
            : filterValues.oneYearReturnPercent
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            oneYearReturnPercent: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">5Y Return %</FilterName>
      <Slider
        min={filterValues.fiveYearsReturnPercent[0]}
        max={filterValues.fiveYearsReturnPercent[1]}
        value={
          customFiltersToApply.fiveYearsReturnPercent
            ? customFiltersToApply.fiveYearsReturnPercent
            : filterValues.fiveYearsReturnPercent
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            fiveYearsReturnPercent: values,
          });
        }}
        resetClicked={false}
      />
      <Divider />

      <FilterName className="mb-12">Dividend Yield</FilterName>
      <Slider
        min={filterValues.dividendYield[0]}
        max={filterValues.dividendYield[1]}
        value={
          customFiltersToApply.dividendYield
            ? customFiltersToApply.dividendYield
            : filterValues.dividendYield
        }
        scoreChange={(values) => {
          setCustomFiltersToApply({
            ...customFiltersToApply,
            dividendYield: values,
          });
        }}
        resetClicked={false}
        step={0.1}
      />
      <Divider />

      <FilterName className="mb-2">Growth Rating</FilterName>
      <div className="flex flex-wrap relative">
        {filterValues.growthRating.map(({ style, value }) => {
          return (
            <div className="flex items-center mt-2" style={style}>
              <CheckContainer
                id="growth-rating"
                onClick={(e) => {
                  const rating = new Set(customFiltersToApply.growthRating);
                  rating.has(value) ? rating.delete(value) : rating.add(value);
                  setCustomFiltersToApply({
                    ...customFiltersToApply,
                    growthRating: rating,
                  });
                }}
                checked={customFiltersToApply.growthRating.has(value)}
                label={value}
              />
            </div>
          );
        })}
      </div>
      <Divider />

      <FilterName className="mb-2">Safety Rating</FilterName>
      <div className="flex flex-wrap relative">
        {filterValues.safetyRating.map(({ style, value }) => {
          return (
            <div className="flex items-center mt-2" style={style}>
              <CheckContainer
                id="safety-rating"
                onClick={(e) => {
                  const rating = new Set(customFiltersToApply.safetyRating);
                  rating.has(value) ? rating.delete(value) : rating.add(value);
                  setCustomFiltersToApply({
                    ...customFiltersToApply,
                    safetyRating: rating,
                  });
                }}
                checked={customFiltersToApply.safetyRating.has(value)}
                label={value}
              />
            </div>
          );
        })}
      </div>
      <Divider />

      <FilterName className="mb-2">Value Rating</FilterName>
      <div className="flex flex-wrap relative">
        {filterValues.valueRating.map(({ style, value }) => {
          return (
            <div className="flex items-center mt-2" style={style}>
              <CheckContainer
                id="value-rating"
                onClick={(e) => {
                  const rating = new Set(customFiltersToApply.valueRating);
                  rating.has(value) ? rating.delete(value) : rating.add(value);
                  setCustomFiltersToApply({
                    ...customFiltersToApply,
                    valueRating: rating,
                  });
                }}
                checked={customFiltersToApply.valueRating.has(value)}
                label={value}
              />
            </div>
          );
        })}
      </div>
      <Divider />
    </div>
  );
};

const mapStateToProps = (state) => ({
  filterValues: state.screenerState.filterValues.data,
  appliedFilters: state.screenerState.appliedFilters,
});

const mapDispatchToProps = { updateFilters };

export default connect(mapStateToProps, mapDispatchToProps)(CustomFilters);
