import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import { deserialise } from '../mappers/ValuationDeserialiser';
import wrappedAxios from '../utils/WrappedAxios';
import axios from 'axios';

function* fetchValuationData(action) {
  yield put({ type: 'FETCH_VALUATION_DATA_LOADING' });
  try {
    const response = yield wrappedAxios
      .get(
        `${SYMBOL_BASE_URL}/valuation/${action.payload.toUpperCase()}${
          action.realTime ? '?real_time=true' : ''
        }`
      )
      .then((response) => {
        return response;
      });
    yield put({
      type: 'FETCH_VALUATION_DATA_SUCCESS',
      data: deserialise(response.data),
    });
  } catch (error) {
    console.log(error.message);
    yield put({ type: 'FETCH_VALUATION_DATA_ERROR', error: error.message });
  }
}

function* fetchValuationBasicData(action) {
  yield put({ type: 'FETCH_VALUATION_BASIC_DATA_LOADING' });
  try {
    const response = yield axios
      .get(`${SYMBOL_BASE_URL}/valuation-basic/${action.payload.toUpperCase()}`)
      .then((response) => {
        return response;
      });
    yield put({
      type: 'FETCH_VALUATION_BASIC_DATA_SUCCESS',
      data: deserialise(response.data),
    });
  } catch (error) {
    console.log(error);
    yield put({ type: 'FETCH_VALUATION_BASIC_DATA_ERROR', error: error });
  }
}

function* fetchValuationPublicData(action) {
  yield put({ type: 'FETCH_VALUATION_DATA_LOADING' });
  try {
    const response = yield axios
      .get(
        `${SYMBOL_BASE_URL}/valuation-public/${action.payload.toUpperCase()}${
          action.realTime ? '?real_time=true' : ''
        }`
      )
      .then((response) => {
        return response;
      });
    yield put({
      type: 'FETCH_VALUATION_DATA_SUCCESS',
      data: deserialise(response.data),
    });
  } catch (error) {
    console.log(error.message);
    yield put({ type: 'FETCH_VALUATION_DATA_ERROR', error: error.message });
  }
}

export function* watchFetchingValuationData() {
  yield takeEvery('FETCH_VALUATION_DATA', fetchValuationData);
  yield takeEvery('FETCH_VALUATION_BASIC_DATA', fetchValuationBasicData);
  yield takeEvery('FETCH_VALUATION_PUBLIC_DATA', fetchValuationPublicData);
}
