import React from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import './style.css';
import styled from 'styled-components/macro';

const ShortTip = styled.p`
  color: ${(props) => props.theme.bulletPoints.positive};
`;

const Tooltip = (props) => {
  const key = props.for + '-' + (Date.now() + Math.random()).toString();
  return (
    <>
      <span className={'cursor-help'} data-tip={props.data} data-for={key}>
        {props.children}
      </span>
      <ReactTooltip
        id={key}
        aria-haspopup="true"
        effect="float"
        data-class="z-10"
        backgroundColor={props.darkMode ? '#444955' : '#32363f'}
      >
        <p className={'w-56 lg:w-64 leading-snug text-xs font-normal'}>
          {props.data}
          {/* {props.tldr && (
            <ShortTip className="mt-2 font-medium">{`TLDR: ${props.tldr}`}</ShortTip>
          )} */}
        </p>
      </ReactTooltip>
    </>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(Tooltip);
