import React, { useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Select from 'react-select';
import { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@chakra-ui/icons';
import { selectStyles } from '../../../../shared/Sections/ScreenerTableNew/screener-table';
import { useMediaQuery } from '@chakra-ui/react';
import { formatDecimalValue } from '../../../../../utils/number';

import { Link } from 'react-router-dom';

function bigValueSuffix(x, prefix, suffix) {
  if (Math.abs(x) >= 1000000000000) {
    if (x < 0) {
      return `-${prefix}${formatDecimalValue(
        Math.abs(x) / 1000000000000
      )}T${suffix}`;
    }
    return `${prefix}${formatDecimalValue(
      parseFloat(x / 1000000000000)
    )}T${suffix}`;
  } else if (Math.abs(x) >= 1000000000) {
    if (x < 0) {
      return `-${prefix}${formatDecimalValue(
        Math.abs(x) / 1000000000
      )}B${suffix}`;
    }
    return `${prefix}${formatDecimalValue(x / 1000000000)}B${suffix}`;
  } else if (Math.abs(x) >= 1000000) {
    if (x < 0) {
      return `-${prefix}${formatDecimalValue(Math.abs(x) / 1000000)}M${suffix}`;
    }

    return `${prefix}${formatDecimalValue(x / 1000000)}M${suffix}`;
  } else if (Math.abs(x) >= 1000) {
    if (x < 0) {
      return `-${prefix}${formatDecimalValue(Math.abs(x) / 1000)}K${suffix}`;
    }

    return `${prefix}${formatDecimalValue(x / 1000)}K${suffix}`;
  } else {
    if (x < 0) {
      return `-${prefix}${formatDecimalValue(Math.abs(x))}${suffix}`;
    }

    return `${prefix}${x}${suffix}`;
  }
}

const HistoryTable = ({ darkMode, data, headerData, cik }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [upDownArray, setUpDownArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(
    data ? Math.round(data.length % rowsPerPage) : 1
  );
  let numberArray = [];

  for (let i = 1; i < headerData.length; i++) {
    numberArray.push(i);
  }

  const tableStrings = ['Sector', 'Industry'];

  const options = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ];

  useEffect(() => {
    // alert( data && data.length + " " + rowsPerPage + " " + Math.ceil(data.length/rowsPerPage))

    setTotalPages(
      data
        ? Math.ceil(
            data.filter((elem) => elem['symbol'] !== null).length / rowsPerPage
          )
        : 0
    );
    setCurrentPage(1);
  }, [rowsPerPage, data]);

  function upDownFilter() {
    function sortUpDown(newData, elem) {
      // console.log(newData);

      if (elem.ascend) {
        newData = newData.slice(0).sort((a, b) => a[elem.key] - b[elem.key]);
      } else if (elem.descend) {
        newData = newData.slice(0).sort((a, b) => b[elem.key] - a[elem.key]);
      }

      return newData;
    }

    let newData = data ? data : [];
    newData = newData
      .slice(0)
      .filter((elem) => elem['symbol'] !== null)
      .sort((a, b) => b['portfolio_percent'] - a['portfolio_percent']);

    for (let i = 0; i < upDownArray.length; i++) {
      newData = sortUpDown(newData, upDownArray[i]);
    }

    return headerData.length !== 0
      ? newData.slice(
          currentPage === 1 ? 0 : rowsPerPage * (currentPage - 1),
          rowsPerPage * currentPage
        )
      : [];
  }

  return (
    <>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          overflowX: 'scroll',
        }}
      >
        {/* ------------------------------------- HEADER --------------------------------------*/}

        <div
          style={{
            display: 'flex',
            width: 'fit-content',
            minWidth: '100%',
            backgroundColor: darkMode ? '#434243' : '#f8f8f8',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          {headerData.map((elem) => {
            return (
              <div
                style={{
                  display: 'flex',
                  minWidth: elem.id === 'Period' ? '135px' : '180px',
                  maxWidth: elem.id === 'Period' ? '135px' : '180px',
                  justifyContent: 'flex-start',
                  position: elem.id === 'Period' ? 'sticky' : '',
                  left: elem.id === 'Period' ? '0px' : '',
                  backgroundColor:
                    elem.id === 'Period'
                      ? darkMode
                        ? '#434243'
                        : '#f8f8f8'
                      : '',
                }}
              >
                <Text
                  style={{
                    fontWeight: '500',
                    color: darkMode ? 'white' : 'black',
                  }}
                >
                  {elem.id}
                </Text>
                {elem.upDownIcon && (
                  <div
                    style={{ cursor: 'pointer' }}
                    className="flex flex-col ml-2"
                    onClick={() => {
                      if (elem.ascend) {
                        elem.ascend = false;
                        elem.descend = true;

                        setUpDownArray([...upDownArray, elem]);
                      } else if (elem.descend) {
                        elem.descend = false;
                        let dupArray = upDownArray.filter(
                          (obj) => obj !== elem
                        );
                        setUpDownArray([...dupArray]);
                      } else {
                        elem.ascend = true;
                        setUpDownArray([...upDownArray, elem]);
                      }

                      //here call the filter function or dont you can use the filter function down and map it for tickers
                    }}
                  >
                    <ChevronUpIcon
                      w="4"
                      h="4"
                      fontSize="large"
                      color={
                        elem.ascend
                          ? darkMode
                            ? 'white'
                            : 'black'
                          : 'gray.500'
                      }
                    />
                    <ChevronDownIcon
                      w="4"
                      h="4"
                      mt="-2"
                      fontSize="large"
                      color={
                        elem.descend
                          ? darkMode
                            ? 'white'
                            : 'black'
                          : 'gray.500'
                      }
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* ------------------------------------- HEADER --------------------------------------*/}

        <div
          style={{
            width: 'auto',
            justifyContent: 'space-between',
            padding: '5px',
            alignItems: 'center',
          }}
        >
          {upDownFilter().map((elem) => {
            return (
              <div style={{ width: 'fit-content', minWidth: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <Flex
                    minW="135px"
                    maxW="135px"
                    position="sticky"
                    left="0px"
                    backgroundColor={darkMode ? '#262626' : 'white'}
                    onClick={() => {}}
                  >
                    {/* <SymbolLogo
                      logoUrl={`http://images.verde.finance/${(elem.symbol).slice(0,(elem.symbol).indexOf('.'))}.png`}
                    /> */}
                    <div style={{ marginLeft: '8px' }}>
                      <Flex alignItems="center">
                        <Text style={{ color: darkMode ? 'white' : 'black' }}>
                          {elem.quarterName}
                        </Text>
                      </Flex>
                    </div>
                  </Flex>

                  {numberArray.map((number) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          minWidth: '180px',
                          maxWidth: '180px',
                          textAlign: 'center',
                          marginBottom: '10px',
                          marginTop: '10px',
                          justifyContent: 'flex-center',
                          alignItems: 'center',
                        }}
                      >
                        <Text
                          style={{
                            color:
                              headerData[number].id.includes('change') ||
                              headerData[number].id === 'Activity'
                                ? elem[headerData[number].key] > 0 ||
                                  elem[headerData[number].key] === 'Add'
                                  ? darkMode
                                    ? '#4adf79'
                                    : '#01874e'
                                  : darkMode
                                  ? '#ec5b55'
                                  : '#BF2F2C'
                                : darkMode
                                ? 'white'
                                : 'black',

                            textOverflow: 'ellipsis',
                            inlineSize: tableStrings.includes(
                              headerData[number].id
                            )
                              ? '150px'
                              : '',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {bigValueSuffix(
                            elem[headerData[number].key],
                            headerData[number].prefix,
                            headerData[number].suffix
                          )}
                        </Text>
                      </div>
                    );
                  })}
                </div>

                <div
                  style={{
                    backgroundColor: darkMode ? '#8c8c8c' : '#cccccc',
                    height: '1px',
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>

      {headerData.length !== 0 && (
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <div
            style={{
              marginRight: 'auto',
              width: '200px',
              visibility: 'hidden',
            }}
          />

          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{ cursor: currentPage === 1 ? '' : 'pointer' }}
              onClick={() => {
                if (currentPage !== 1) {
                  setCurrentPage(currentPage - 1);
                }
              }}
            >
              <ChevronLeftIcon
                w="7"
                h="7"
                fontSize="large"
                color={
                  currentPage === 1 ? 'gray.500' : darkMode ? 'white' : 'black'
                }
              />
            </div>

            <Text
              style={{
                color: darkMode ? '#cccccc' : '#8c8c8c',
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: isMobile ? '14px' : '16px',
              }}
            >
              Page {currentPage}/{totalPages}
            </Text>

            <div
              style={{ cursor: currentPage === totalPages ? '' : 'pointer' }}
              onClick={() => {
                if (currentPage !== totalPages) {
                  setCurrentPage(currentPage + 1);
                }
              }}
            >
              <ChevronRightIcon
                w="7"
                h="7"
                fontSize="large"
                color={
                  currentPage === totalPages
                    ? 'gray.500'
                    : darkMode
                    ? 'white'
                    : 'black'
                }
              />
            </div>
          </div>

          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                color: darkMode ? '#cccccc' : '#8c8c8c',
                marginRight: isMobile ? '0px' : '5px',
                fontSize: isMobile ? '14px' : '16px',
              }}
            >
              Rows Per Page
            </Text>

            <div style={{ borderRadius: '15px', width: '85px' }}>
              <Select
                className="text-sm ml-3"
                options={options}
                styles={{
                  ...selectStyles,
                  control: (styles) => ({
                    ...styles,
                    height: 'initial',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    border: darkMode
                      ? '0.5px solid #ececed'
                      : '0.5px solid black',
                    minHeight: '0',
                    borderRadius: '15px',
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    minWidth: '2em',
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    color: darkMode ? 'white' : 'black',
                  }),
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    color: darkMode ? 'white' : 'black',
                  }),
                  indicatorSeparator: (styles) => ({
                    ...styles,
                    display: 'none',
                  }),
                }}
                defaultValue={options[0]}
                onChange={(value) => {
                  setRowsPerPage(value.value);
                }}
                menuPlacement="top"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HistoryTable;
