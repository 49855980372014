import React from 'react';
import { ColoredContentText, Subtle } from '../StyedComponents';
import DefaultProfilePicture from '../../../public/images/default-profile-picture.jpg';

const PersonCard = ({ name, followers }) => {
  return (
    <div className="flex">
      <img className="w-10 h-10 mr-4" src={DefaultProfilePicture} alt="" />
      <div className="my-auto">
        <ColoredContentText className="text-sm font-medium">
          {name}
        </ColoredContentText>
        <Subtle className="text-xs mt-0.5 font-normal">
          {followers} followers
        </Subtle>
      </div>
    </div>
  );
};

export default PersonCard;
