const ScreenerBackgroundColor = {
  dark: '#1d212b',
};
export const LIGHT_THEME = {
  isDark: false,
  title: '#383b54',
  highlight: '#101010',
  keyHighlight: '#319961',
  header: '#787878',
  subtle: '#8492a2',
  sectionBorder: '#e7e9ef',
  divider: '#484f64',
  trend: {
    upward: '#36A867',
    downward: '#de5d3c',
  },
  likeAndComment: {
    color: '#1a2838',
    background: '#F8FAFF',
    clicked: '#e5ecf6',
    likeSectionBorder: '#f7faff',
  },
  news: {
    filterButton: {
      border: '#dadde7',
      background: '#e5ecf6',
    },
  },
  pastComment: {
    background: '#e5ecf6',
    user: '#41546e',
    commentText: '#66778e',
  },
  pageTab: {
    name: '#6f927e',
    background: '#ddf0e5',
    border: '#d3e2de',
  },
  order: {
    profit: '#54a579',
    loss: '#de5d3c',
    description: '#868686',
  },
  companyPage: {
    description: '#697788',
  },
  table: {
    border: '#eeeff2',
    headerColor: '#000',
    headerBgColor: '#f0f5fc',
    headerBorder: '#e7e9ef',
    background: '#fff',
    color: '#3a3a3a',
    buttonHover: '#fff',
    evenRow: '#f8fafd',
  },
  screener: {
    headerBgColor: '#ffffff',
    color: '#3a3a3a',
    evenRow: '#f8fafd',
  },
  toggleButton: {
    color: '#8090aa',
    background: '#f0f5fc',
  },
  green: '#36A867',
  red: '#de5d3c',
  paleBlack: '#454545',
  disabled: '#97cbaf',
  unchecked: {
    border: '#969ba0',
    color: '#454545',
  },
  leaderboard: '#59bb88',
  numberBadge: '#29765a',
  verticalLine: '#eaebf1',
  grayBorder: '#cbcbcb',
  buttonGreen: '#2b9867',
  imageBorder: '#e3e3e3',
  sectionDivider: '#c0c0c0',
  positive: '#54a579',
  negative: '#de5d3c',
  verdeHeader: {
    searchInputColor: '#3a3a3a',
    searchButtonColor: '#3a3a3a',
  },
  mainBgColor: '#fafafa',
  content: {
    color: '#3a3a3a',
    background: '#fff',
    border: '#fff',
  },
  verdeScale: {
    background: '#f0f5fc',
    markerBackground: '#fff',
    valuedTextBackground: '#f0f5fc',
    markerBorder: '#fff',
    markerLine: '#3a3a3a',
    greenSection: {
      background: '#94cb97',
      border: '#4fb254',
    },
    amberSection: {
      background: '#f8daad',
      border: '#f0b561',
    },
    redSection: {
      background: '#f1736b',
      border: '#ee4e42',
    },
  },
  ratioGraph: {
    background: 'transparent',
    border: '#dbe4f0',
    color: '#3a3a3a',
  },
  screener: {
    defaultFilters: {
      background: '#fff',
      border: '#d8d8d8',
    },
    filters: {
      searchBorder: '#484f64',
      searchBg: '#272c34',
      headerLink: 'white',
      selectBoxLabelColor: 'white',
      applyFilterBtnBg: '#36a867',
      applyFilterBtnColor: 'white',
      applyFilterBtnBgHover: '#24b552',
      pillBg: '#272C34',
      pillBorder: '#3B404F ',
      pillColor: 'white',
    },
    columnSelectorBg: '#ffffff',
    columnSelectorBorder: '#ccd3d6',
  },
  symbolLogo: {
    background: '#fcfdfe',
    boxShadow: '0 0 4px 0 rgba(69, 80, 93, 0.25)',
  },
  companySummary: {
    summaryHighlights: {
      border: '#ececed',
      background: '#fff',
      divider: '#e3e3e7',
      boxHeader: '#697787',
      boxContent: '#3a3a3a',
      boxShadow: '0 0 16px 0 rgba(50, 53, 76, 0.1)',
    },
  },
  bulletPoints: {
    positive: '#36a867',
    negative: '#de5d3c',
    title: '#383b54',
  },
  rating: {
    divider: '#e3e3e7',
    title: '#383b54',
    background: '#fff',
    border: '#ececed',
    boxShadow: '0 0 16px 0 rgba(50, 53, 76, 0.1)',
  },
  similarCompanies: {
    divider: '#e7e9ef',
  },
  containerBoxShadow: '0 0 4px 0 #edf1f7',
  priceGrowth: {
    button: {
      color: '#000',
      selectedColor: '#fff',
      border: '#e7e9ef',
      selectedBorder: '#36a867',
      background: 'transparent',
      selectedBackground: '#36a867',
    },
  },
  metrics: {
    header: '#36a867',
    value: '#383b54',
  },
  dividend: {
    ratingBorder: '#e3e3e7',
    ratingText: '#3a3a3a',
    currentYield: '#383b54',
    currentYieldText: '#3a3a3a',
  },
  homeLogin: {
    background: 'white',
  },
  tooltip: {
    underline: '#3a3a3a',
  },
  screener_new: {
    textColor: 'black',
    sidebar: {
      bg: 'white',
    },
  },
};

export const DARK_THEME = {
  ...LIGHT_THEME,
  isDark: true,
  verdeHeader: {
    searchInputColor: '#fff',
    searchButtonColor: '#b6becc',
  },
  content: {
    color: '#fff',
    background: '#1d212b',
    border: '#15181f',
  },
  mainBgColor: '#141920',
  pageTab: {
    name: '#a4b6ac',
    background: '#272c34',
    border: '#272c34',
  },
  highlight: '#fff',
  subtle: '#a3a6b3',
  header: '#d8d9e4',
  title: '#fff',
  sectionBorder: '#3b404f',
  imageBorder: '#15181f',
  keyHighlight: '#86efaf',
  positive: '#86efaf',
  negative: '#de5d3c',
  companyPage: {
    description: '#989baa',
  },
  sectionDivider: '#5a5d74',
  verdeScale: {
    background: '#272c34',
    markerBackground: '#45475e',
    valuedTextBackground: '#343947',
    markerBorder: '#5a5d74',
    markerLine: '#d8d9e4',
    greenSection: {
      background: '#36A867',
      border: '#2d7c51',
    },
    amberSection: {
      background: '#fbc746',
      border: '#f0b561',
    },
    redSection: {
      background: '#db7d48',
      border: '#b74325',
    },
  },
  table: {
    background: '#1d212b',
    border: '#3b404f',
    headerColor: '#fff',
    headerBorder: '#404358',
    headerBgColor: '#272c34',
    color: '#eaebef',
    buttonHover: '#141920',
    evenRow: '#272c34',
  },

  ratioGraph: {
    background: '#272c34',
    border: '#3b404f',
    color: '#b3b4c6',
  },
  screener: {
    defaultFilters: {
      background: '#1d212b',
      border: '#15181f',
    },
    headerBgColor: '#272c34',
    color: '#eaebef',
    evenRow: '#404358',
    columnSelectorItemBg: '#151a21',
    columnSelectorBorder: '#3b404f',
    columnSelectorBg: '#1d212b',
  },
  unchecked: {
    border: '#fff',
    color: '#fff',
  },
  symbolLogo: {
    background: '#272c34',
    boxShadow: '0 0 13px 0 rgba(0, 0, 0, 0.18)',
  },
  companySummary: {
    summaryHighlights: {
      background: '#272c34',
      border: '#272c34',
      divider: '#484f64',
      boxHeader: '#a3a6b3',
      boxContent: '#d8d9e5',
      boxShadow: '0 0 16px 0 rgba(50, 53, 76, 0.1)',
    },
  },
  bulletPoints: {
    positive: '#86efaf',
    negative: '#ff6e66',
    title: '#e8e8ee',
  },
  rating: {
    divider: '#484f64',
    title: '#e8e8ee',
    background: '#272c34',
    border: '#272c34',
    boxShadow: '0 0 16px 0 rgba(50, 53, 76, 0.1)',
  },
  similarCompanies: {
    divider: '#303542',
  },
  containerBoxShadow: '0 0 13px 0 rgba(0, 2, 5, 0.16)',
  priceGrowth: {
    button: {
      color: '#eaebef',
      selectedColor: '#fff',
      border: '#5a5d74',
      selectedBorder: '#36a867',
      background: 'transparent',
      selectedBackground: '#36a867',
    },
  },
  metrics: {
    header: '#86efaf',
    value: '#e8e8ee',
  },
  dividend: {
    ratingBorder: '#484f64',
    ratingText: '#d8d9e5',
    currentYield: '#fff',
    currentYieldText: '#d8d9e5',
  },
  homeLogin: {
    background: 'black',
  },
  tooltip: {
    underline: '#ebebef',
  },
  toggleButton: {
    color: '#8090aa',
    background: '#141920',
  },
  screener_new: {
    textColor: 'white',
    backgroundColor: ScreenerBackgroundColor.dark,
    tabSelectedBg: '#01874e',
    tabSelectedColor: 'white',
    tabBg: '#272c34',
    tabColor: 'white',
    searchBg: '#141920',
    searchBorder: 'none',
    primaryButtonColor: 'white',
    secondaryButtonColor: '#01874e',
    primaryButtonBg: '#01874e',
    secondaryButtonBg: 'transparent',
    buttonBorderColor: '#01874e',
    sidebar: {
      bg: '#161616',
      textColor: '#f6f6f6',
      textColorSecondary: '#cccccc',
      columnItemBg: '#262626',
      columnItemBgSecondary: '#343434',
      textGreen: '#4adf79',
    },
  },
};
