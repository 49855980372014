import React from 'react';
import RatioGraph from '../../../RatioGraphRevamp';
import RatioGraphInsights from '../../../RatioGraphInsights';
import SectionHeader from '../../../SectionHeader';
import RiskPositives from '../../../RiskPositives';

const PriceToEarnings = ({
  data,
  symbol,
  insights,
  darkMode,
  etfData,
  spyData,
}) => {
  const ratios =
    data !== undefined
      ? [
          { name: 'Symbol', value: data.symbol },
          { name: 'Sector', value: data.sector },
          { name: 'Market', value: data.market },
        ]
      : [
          {
            name: 'Symbol',
            value: parseFloat(etfData.Valuation['P/E(TTM)']).toFixed(2),
          },
          {
            name: 'Category',
            value: parseFloat(etfData.Valuation['P/E Category(TTM)']).toFixed(
              2
            ),
          },
          {
            name: 'Market',
            value: parseFloat(spyData.Valuation['P/E(TTM)']).toFixed(2),
          },
        ];

  const peIndustryPositives = insights?.pe_ratio?.Industry?.positives
    ? insights?.pe_ratio?.Industry?.positives
    : [];
  const peMarketPositives = insights?.pe_ratio?.Market?.positives
    ? insights?.pe_ratio?.Market?.positives
    : [];
  const peIndustryNegatives = insights?.pe_ratio?.Industry?.negatives
    ? insights?.pe_ratio?.Industry?.negatives
    : [];
  const peMarketNegatives = insights?.pe_ratio?.Market?.negatives
    ? insights?.pe_ratio?.Market?.negatives
    : [];

  return (
    <>
      <SectionHeader value="Price to Earnings (PE)" infoKey="pe" />

      {symbol !== undefined ? (
        <RatioGraph ratios={ratios} symbol={symbol.id} suffix="x" />
      ) : etfData.ETF_Data !== {} ? (
        <RatioGraph
          ratios={ratios}
          symbol={etfData.ETF_Data.ETF_Code}
          suffix="x"
        />
      ) : (
        <div></div>
      )}

      {peIndustryPositives.length === 0 && peIndustryNegatives.length === 0 ? (
        <div></div>
      ) : (
        <div>
          <h3
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: darkMode ? 'white' : 'black',
            }}
          >
            PE vs Sector
          </h3>

          <RiskPositives
            positives={peIndustryPositives}
            negatives={peIndustryNegatives}
          />
        </div>
      )}

      {peMarketPositives.length === 0 && peMarketNegatives.length === 0 ? (
        <div></div>
      ) : (
        <>
          <h3
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: darkMode ? 'white' : 'black',
            }}
          >
            PE vs Market
          </h3>
          <RiskPositives
            positives={peMarketPositives}
            negatives={peMarketNegatives}
          />
        </>
      )}
    </>
  );
};

export default PriceToEarnings;
