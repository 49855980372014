import {
  Flex,
  Box,
  Grid,
  GridItem,
  Badge,
  useMediaQuery,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import HorizontalBar from '../Chart/HorizontalBar';
import { toTitleCase } from '../../../../core/StringUtils';
import SectionHeader from '../../../SectionHeader';
import AssetClassification from '../AssetClassification';
import numeral from 'numeral';
import LoadingSectionIndicator from '../../../LoadingSectionIndicator';

const DOWNSIDE_PROTECTION_HEADERS = {
  credit_risk: 'Credit Risk',
  liquidity_risk: 'Liquidity Risk',
  inflation_driver_risk: 'Inflation Driver Risk',
  interest_rate_risk: 'Interest Rate Risk',
  commodity_driver_risk: 'Commodity Driver Risk',
  country_diversification: 'Country Diversification',
  growth_driver_risk: 'Growth Driver Risk',
  sector_diversification: 'Sector Diversification',
  holdings_diversification: 'Holdings Diversification',
  asset_class_diversification: 'Asset Class Diversification',
};

export default function Analysis(props) {
  const { data, darkMode, label, insightKey, assetData, insightsData } = props;

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const downsideProtection = data['downside_protection']
    ? data['downside_protection']['downside_protection']
    : {};
  const downsideProtectionRecommendations = data['downside_protection']
    ? data['downside_protection']['downside_protection_recommendations']
    : {};
  const riskAdjustedReturnsRecommendations = data['risk_adjusted_returns']
    ? data['risk_adjusted_returns']['risk_adjusted_returns_recommendations']
    : {};

  function getBadgeColor(type) {
    switch (type) {
      case 'risky':
        return '#F34723';
      case 'neutral':
        return '#FBC60E';
      case 'safe':
        return '#237D63';
      default:
        return '#237D63';
    }
  }

  let topFive = data?.risk_adjusted_returns
    ? Object.keys(data?.risk_adjusted_returns?.top_5).map((key) => ({
        name: data?.risk_adjusted_returns?.top_5[key].symbol,
        y: Number(data?.risk_adjusted_returns?.top_5[key].sharpe_ratio),
        color: '#16803c',
      }))
    : [];

  let bottomFive = data?.risk_adjusted_returns
    ? Object.keys(data?.risk_adjusted_returns?.bottom_5).map((key) => ({
        name: data?.risk_adjusted_returns?.bottom_5[key].symbol,
        y: Number(data?.risk_adjusted_returns?.bottom_5[key].sharpe_ratio),
        color: '#b91c1b',
      }))
    : [];

  const barChartData = topFive.concat(bottomFive);

  if (!data['downside_protection'] || !data['risk_adjusted_returns'])
    return <LoadingSectionIndicator />;

  return (
    <Box>
      <Box
        textColor={darkMode ? '#f6f6f6' : '#3a3a3a'}
        bg={darkMode ? '#262626' : !isMobile ? 'white' : 'rgb(246,248,250)'}
        p="6"
      >
        <SectionHeader
          value={`Downside Protection: ${
            data?.downside_protection
              ? data?.downside_protection?.downside_protection_score
              : ''
          }`}
          className="pb-6"
        />
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {Object.keys(downsideProtection).map((key) => (
            <GridItem p="1">
              <Box fontSize="lg" fontWeight={'bold'}>
                {DOWNSIDE_PROTECTION_HEADERS[key]}
              </Box>
              <Box fontSize="md">
                <Flex alignItems={'center'}>
                  <Badge
                    borderRadius="full"
                    w="3"
                    h="3"
                    bg={getBadgeColor(downsideProtection[key]['risk_level'])}
                    mr="2"
                  >
                    &nbsp;
                  </Badge>
                  <Box>
                    {toTitleCase(downsideProtection[key]['risk_level'])}
                  </Box>
                </Flex>
              </Box>
              <Box fontSize="sm">{downsideProtection[key]['explanation']}</Box>
            </GridItem>
          ))}
        </Grid>
      </Box>

      <Box
        textColor={darkMode ? '#f6f6f6' : '#3a3a3a'}
        bg={darkMode ? '#262626' : !isMobile ? 'white' : 'rgb(246,248,250)'}
        p="6"
        my="6"
      >
        <SectionHeader
          value="Downside Protection Recommendations"
          className="pb-4"
        />
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {Object.keys(downsideProtectionRecommendations).map((key, index) => (
            <GridItem
              bg={darkMode ? '#434243' : '#F9F9F9'}
              mt="5px"
              borderRadius="5px"
              p={4}
            >
              <Badge borderRadius={2} mb={2}>
                #{index + 1} Recommendation
              </Badge>
              <Box fontSize="sm">{downsideProtectionRecommendations[key]}</Box>
            </GridItem>
          ))}
        </Grid>
      </Box>

      <Box
        textColor={darkMode ? '#f6f6f6' : '#3a3a3a'}
        bg={darkMode ? '#262626' : !isMobile ? 'white' : 'rgb(246,248,250)'}
        p="6"
        my="6"
      >
        <SectionHeader
          value={`Risk Adjusted Returns: ${
            data?.risk_adjusted_returns
              ? data?.risk_adjusted_returns?.risk_adjusted_returns_score
              : ''
          }`}
        />
        <Text>
          How each item in your portfolio contributes to your risk-adjusted
          return (Sharpe Ratio)
        </Text>
        <Flex alignItems={'center'} my={4} direction={'column'}>
          <SectionHeader
            value={`Portfolio Sharpe Ratio: ${numeral(
              data?.risk_adjusted_returns?.portfolio_sharpe_ratio
            ).format('0,0.000')}`}
          />
        </Flex>
        <Box>
          <HorizontalBar
            data={barChartData}
            title="Sharpe Ratio"
            darkMode={darkMode}
          />
        </Box>
      </Box>

      <Box
        textColor={darkMode ? '#f6f6f6' : '#3a3a3a'}
        bg={darkMode ? '#262626' : !isMobile ? 'white' : 'rgb(246,248,250)'}
        p="6"
        my="6"
      >
        <SectionHeader
          value={`Risk Adjusted Returns Recommendations`}
          className="pb-4"
        />
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {Object.keys(riskAdjustedReturnsRecommendations).map((key, index) => (
            <GridItem
              bg={darkMode ? '#434243' : '#F9F9F9'}
              mt="5px"
              borderRadius="5px"
              p={4}
            >
              <Badge borderRadius={2} mb={2}>
                #{index + 1} Recommendation
              </Badge>
              <Box fontSize="sm">{riskAdjustedReturnsRecommendations[key]}</Box>
            </GridItem>
          ))}
        </Grid>
      </Box>

      <AssetClassification
        data={data}
        assetData={assetData}
        insightsData={insightsData}
        currentKey={insightKey}
        darkMode={darkMode}
        label={label}
      />
    </Box>
  );
}
