import { Box, Divider, Flex } from '@chakra-ui/layout';
import { Text, Button, useMediaQuery } from '@chakra-ui/react';
import VerdeHeader from '../../shared/VerdeHeader';
import styled from 'styled-components/macro';
import React, { useState, MouseEvent, useEffect, ReactNode } from 'react';
import { useAuth } from '../../hocs/AuthProvider';
import Hero from '../../../public/images/newhome/hero.webp';
import TestOur from '../../../public/images/newhome/testour.svg';
import Offer1 from '../../../public/images/newhome/offer1.svg';
import Offer2 from '../../../public/images/newhome/offer2.svg';
import Offer3 from '../../../public/images/newhome/offer3.svg';
import wefunder from '../../../public/images/newhome/wefunder.webp';
import discord from '../../../public/images/newhome/discord.webp';
import wefunderIllustration from '../../../public/images/newhome/wefunder-illustration.svg';
import VerdeLogoSvg from '../../../public/images/newhome/footer-logo.svg';
import Tiktok from '../../../public/images/newhome/tiktok.svg';
import Instagram from '../../../public/images/newhome/instagram.svg';
import Twitter from '../../../public/images/newhome/twitter.svg';
import Linkedin from '../../../public/images/newhome/linkedin.svg';
import CancelSvg from '../../../public/images/cancel.svg';
import { gtag } from '../../../utils/analytics';
import Modal from 'react-modal';
import { Container } from '../../../utils/styling';
import { motion, useAnimation } from 'framer-motion';
import { useAppDispatch } from '../../../hooks/store';
import { setLoginPhase } from '../../../actions/coreAction';
import { Link } from 'react-router-dom';
import { MobileAppModal } from '../../shared/GetTheApp/MobileAppModal';
import PlayStore from '../../../public/images/GetOnGooglePlay.png';
import AppleStore from '../../../public/images/GetOnAppleStore.png';

declare global {
  interface Window {
    gtag: any;
  }
}
const SocialLink = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => (
  <a href={href} target="_blank">
    {children}
  </a>
);
const offers = [
  {
    id: 1,
    img: Offer1,
    title: 'Stock Research',
    description:
      'Make stock & ETF research fun & easy. A complete breakdown of ratios, financial strength, and more!',
  },
  {
    id: 2,
    img: Offer2,
    title: 'Portfolio Analytics',
    description:
      'Sync your portfolio(s) securely and learn how you can improve your asset allocation. Identify risks, and manage all your investments in one place!',
  },
  {
    id: 3,
    img: Offer3,
    title: 'Social Investing Community',
    description:
      'Follow the portfolios and trades of your friends & family, discover & share ideas with like-minded investors, and track the investments of notable super-investors!',
  },
];
const brandColor = '#01874e';
const modalStyles = (isMobile = false) => ({
  overlay: {
    zIndex: 10,
  },
  content: {
    background: 'white',
    borderRadius: '10px',
    padding: '10px',
    border: 0,
    marginTop: '100px',
    height: isMobile ? '50vh' : 'calc(40vw)',
    width: '90vw',
    right: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
});
const Player = styled(Box)``;
const animText = ['Analytics', 'People', 'Power', 'Style', 'Verde'];
const timeDuration = 1.5;
export default function HomePage({ setTheme }: { setTheme: Function }) {
  const { authUser, signout } = useAuth();
  const [playVideo, setPlayVideo] = useState(false);
  const controls = useAnimation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    controls.start({
      scale: 0.8,
      transition: { repeat: Infinity, duration: 0.9 },
    });
  }, []);

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [getTheAppModal,setGetTheAppModal] = useState(true);
  const trackViralLoops = (event: MouseEvent) => {
    gtag('event', `join-wait-list`);
    const text = (event.target as HTMLElement).innerText;
    let type = 'new_user_prelaunch';
    if (text.includes('position')) {
      type = 'user_check_position';
    }
  };
  const [animIndex, setAnimationIndex] = useState(0);
  useEffect(() => {
    setInterval(() => {
      let index = animIndex;

      setAnimationIndex((index) => {
        if (index + 1 >= animText.length) {
          index = -1;
        }
        return index + 1;
      });
    }, timeDuration * 1000);
  }, []);
  const handleSignUp = () => {};
  return (
    <Box fontFamily="Manrope" backgroundColor="#f4f4f4">
      <VerdeHeader
        setTheme={setTheme}
        auth={authUser ? true : false}
        hiddenSearch
      ></VerdeHeader>
      <Box
        backgroundImage="linear-gradient(to top, #62da6a -430%, #fff 190%)"
        py="10"
      >
        { isMobile && getTheAppModal && 
        <MobileAppModal darkMode = {false} closeModal={() => setGetTheAppModal(false)}/>
        }     
        <Container>
          <Flex
            alignItems="center"
            flexDirection={['column-reverse', 'column-reverse', 'row']}
          >
            <Flex flex="1" flexDirection="column" zIndex={1}>
              <Text
                fontSize={['4xl', '4xl', '6xl']}
                fontWeight="extrabold"
                textAlign={['center', 'center', 'left']}
              >
                Start Investing with{' '}
                {/* <motion.span
                  style={{ display: 'inline-block', color: '#01874e' }}
                  initial={{
                    rotateX: 90,
                    opacity: 0,
                    perspective: '1000px',
                    y: 0,
                  }}
                  animate={{
                    rotateX: 0,
                    opacity: 1,

                    transition: {
                      repeat: Infinity,
                      duration: timeDuration,
                      ease: 'easeInOut',
                    },
                  }}
                >
                  {animText[animIndex]}
                </motion.span> */}
                {/* <Typist
                  typingSpeed={120}
                  deletingSpeed={120}
                  style={{ color: '#01884D' }}
                  sentences={['Analytics', 'People', 'Power', 'Verde']}
                  loop={true}
                /> */}
                <span style={{ color: '#01884D' }}>
                  {/* <Typed
                    strings={['Analytics', 'People', 'Power', 'Verde']}
                    typeSpeed={120}
                    backSpeed={60}
                    loop
                  /> */}
                </span>
              </Text>
              <Text
                mt="5"
                textAlign={['center', 'center', 'left']}
                fontSize="xl"
              >
                Be the first to explore Verde’s revolutionary platform
              </Text>
              <Flex
                mt="5"
                alignItems="center"
                flexDirection={['column', 'column', 'row']}
                fontSize="xl"
              >
                <Link to="/signup">
                <Button
                  // className="vrlps-trigger"
                  bg={brandColor}
                  fontSize="lg"
                  // onClick={trackViralLoops}
                  color="white"
                  fontFamily="roboto"
                  _hover={{
                    bg: { brandColor },
                    color: 'white',
                  }}
                  _focus={{ boxShadow: 'none', outline: 'none' }}
                >
                  Sign up
                </Button>
                </Link>
                <Flex
                  ml="5"
                  alignItems="center"
                  mt={['4', '4', '0']}
                  cursor="pointer"
                  zIndex={1}
                  onClick={() => {
                    setPlayVideo(!playVideo);
                    controls.stop();
                  }}
                >
                  <motion.div animate={controls}>
                    <Box
                      borderRadius="50%"
                      borderColor="#01874e"
                      borderWidth="1px"
                      padding="1"
                      height="50px"
                      width="50px"
                    >
                      <Flex
                        borderRadius="50%"
                        backgroundColor="#01874e"
                        height="full"
                        width="full"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          transform="rotate(-270deg) translateY(-2px)"
                          borderRadius="2px"
                          border="10px solid transparent"
                          borderTop="0"
                          borderBottom="20px solid white"
                        ></Box>
                      </Flex>
                    </Box>
                  </motion.div>
                  <Text ml="2">See how it works</Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex flex="1.5" w="full" justifyContent="center">
              <img
                src={Hero}
                style={{ height: isMobile ? '300px' : 'auto' }}
                alt="Hero Image"
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Container>
        {/* <Flex
          mt={['20', '20', '32']}
          flexDirection={['column-reverse', 'column-reverse', 'row']}
        >
          <Flex flexDirection="column" flex="1" mt={['10', '10', '0']}>
            <Text
              fontSize="5xl"
              fontWeight="bold"
              textAlign={['center', 'center', 'left']}
            >
              Test our Beta product
            </Text>
            <Text
              mt="5"
              maxW="md"
              textAlign={['center', 'center', 'left']}
              fontSize="2xl"
            >
              Can’t wait for the public launch? Start exploring Verde’s Beta
              right now!
            </Text>
            <Flex
              mt="5"
              justifyContent={['center', 'center', 'left']}
              fontSize="lg"
            >
              <Button
                as="a"
                //href="https://form.jotform.com/221214528078049"
                bg={brandColor}
                target="_blank"
                color="white"
                onClick={() => {
                  gtag('event', `sign-up-beta`);
                  dispatch(setLoginPhase('signup'));
                }}
                _hover={{
                  bg: { brandColor },
                  color: 'white',
                }}
                _focus={{ boxShadow: 'none', outline: 'none' }}
              >
                Get Beta Access
              </Button>
            </Flex>
          </Flex>
          <Flex flex="1" justifyContent="flex-end">
            <img src={TestOur} alt="test our product" />
          </Flex>
        </Flex> */}
        <Flex mt={['20', '20', '32']} flexDirection="column">
          <Text
            fontSize="5xl"
            fontWeight="bold"
            textAlign={['center', 'center', 'left']}
          >
            What we offer
          </Text>
          <Flex gap="5" mt="4" flexDirection={['column', 'column', 'row']}>
            {offers.map((offer, index) => (
              <Flex
                key={offer.id}
                flexDirection="column"
                backgroundColor="white"
                borderRadius="20px"
                border="solid 1px #dbe4e9"
                flex="1"
                p="10"
              >
                <Text fontSize="xl" fontWeight="bold" m="0">
                  {index + 1}
                </Text>
                <Flex flexDirection="column" alignItems="center">
                  <img
                    src={offer.img}
                    height="220"
                    width="200"
                    style={{ height: '220px', width: '200px' }}
                  />
                  <Text
                    fontWeight="semibold"
                    fontSize="3xl"
                    mt="4"
                    maxW="2xs"
                    textAlign="center"
                  >
                    {offer.title}
                  </Text>
                  <Text mt="4" fontSize="lg" textAlign="center" maxW="2xs">
                    {offer.description}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Flex
          mt={['20', '20', '32']}
          flexDirection={['column', 'column', 'row']}
        >
          <Flex flexDirection="column" flex="1">
            <Flex justifyContent={['center', 'center', 'left']}>
              <img src={wefunder} style={{ height: '60px', width: '200px' }} />
            </Flex>
            <Text
              fontSize="5xl"
              fontWeight="bold"
              mt="5"
              textAlign={['center', 'center', 'left']}
            >
              Invest with Verde Finance
            </Text>
            <Text
              mt="5"
              maxW="lg"
              textAlign={['center', 'center', 'left']}
              fontSize="2xl"
            >
              Click below to learn more about our campaign on Wefunder and join
              us on our journey to democratize financial education and transform
              social investing!
            </Text>
            <Flex mt="5" justifyContent={['center', 'center', 'left']}>
              <Button
                as="a"
                target="_blank"
                fontSize="lg"
                href="https://wefunder.com/verdefinance"
                bg={brandColor}
                onClick={() => {
                  gtag('event', `wefunder`);
                }}
                color="white"
                _hover={{
                  bg: { brandColor },
                  color: 'white',
                }}
                _focus={{ boxShadow: 'none', outline: 'none' }}
              >
                Support the campaign
              </Button>
            </Flex>
          </Flex>
          <Flex flex="1" justifyContent="flex-end" mt={['10', '10', '0']}>
            <img src={wefunderIllustration} alt="test our product" />
          </Flex>
        </Flex>
      </Container>
      <Box backgroundColor="#01874f" mt={['20', '20', '32']}>
        <Container py="10">
          <Flex justifyContent="center">
            <Flex flexDirection="column" color="white">
              <Flex justifyContent="center">
                <img src={discord} style={{ height: '65px', width: '65px' }} />
              </Flex>
              <Text fontSize="5xl" fontWeight="bold" textAlign="center" mt="5">
                Join our community !
              </Text>
              <Text fontSize="2xl" textAlign="center" mt="5">
                Hop on to our Discord Server and join a community of like-minded
                investors.
              </Text>
              <Flex justifyContent="center" mt="5">
                <Button
                  as="a"
                  href="https://discord.gg/mtfzdBcFf5"
                  target="_blank"
                  bg={'white'}
                  fontWeight="normal"
                  fontSize="lg"
                  color="#01874e"
                  onClick={() => {
                    gtag('event', `discord`);
                  }}
                  _hover={{
                    fontWeight: 'bold',
                  }}
                  _focus={{ boxShadow: 'none', outline: 'none' }}
                >
                  Join Verde's Discord
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Container py="10">
        <Flex
          justifyContent="space-between"
          flexDirection={['column', 'column', 'row']}
        >
          <Flex
            flexDirection="column"
            fontSize="small"
            color="#64607d"
            alignItems={['center', 'center', 'flex-start']}
          >
            <img src={VerdeLogoSvg} style={{ width: '100px' }} />
            <Text mt="5">2021 Guadalupe Street, Suite 260,</Text>
            <Text mt="2">Austin, Texas, 78705</Text>
          </Flex>
          <Flex direction='column'>
          <Flex
            gap="2"
            justifyContent={['center', 'center', 'end']}
            alignItems="flex-end"
            mt={['10', '10', '0']}
            mb={0}
          >
            <SocialLink href="https://www.tiktok.com/@verde.finance">
              <img src={Tiktok}></img>
            </SocialLink>
            <SocialLink href="https://instagram.com/verde.finance">
              <img src={Instagram}></img>
            </SocialLink>
            <SocialLink href="https://twitter.com/VerdeFinance">
              <img src={Twitter}></img>
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/verdefinance">
              <img src={Linkedin}></img>
            </SocialLink>
          </Flex>
          { !isMobile && 
          <Box mt={3}>
            <a href="https://apps.apple.com/us/app/verde-finance/id1630554888" target='_blank'>
          <div style={{height:50,width:150, cursor:'pointer',marginBottom:5,
        }}>
            <img src={AppleStore} style={{borderRadius:10}} ></img>
          </div>
          </a>
          <a target='_blank' href="https://play.google.com/store/apps/details?id=com.verde.finance">
          <div style={{height:50,width:150, cursor:'pointer', marginTop:5, 
        }}>
            <img src={PlayStore} style={{borderRadius:10}} ></img>
          </div>
          </a>
          </Box>
          }
          </Flex>
        </Flex>
        <Divider mt={['2', '2', '10']} size="10" />
        <Text
          py={['2', '2', '10']}
          textAlign="center"
          color="#181433"
          fontSize={['small', 'small', 'normal']}
        >
          Copyright @ {new Date().getFullYear()}. All rights reserved.
        </Text>
      </Container>
      <Modal
        isOpen={playVideo}
        onRequestClose={() => setPlayVideo(false)}
        style={modalStyles(isMobile)}
      >
        <>
          <Flex flexDir="row-reverse">
            <Box cursor="pointer" onClick={() => setPlayVideo(false)} p="4">
              <img src={CancelSvg} />
            </Box>
          </Flex>
          <Player
            position="relative"
            w="full"
            h={['auto', 'auto', 'auto', '80%']}
          >
            <iframe
              src="https://player.vimeo.com/video/682674533?autoplay=1"
              width="100%"
              height="100%"
              allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
            {/* <iframe
              src="https://player.vimeo.com/video/682674533?h=a3c50f1860&title=0&byline=0&portrait=0&autoplay=1"
              style={{
                position: 'absolute',

                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: isMobile ? 'auto' : '100%',
              }}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe> */}
          </Player>
        </>
      </Modal>
    </Box>
  );
}
