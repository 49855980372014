import React from 'react';
import styled from 'styled-components/macro';
import { INFO } from '../../../config/info-tags';
import Tooltip from '../Tooltip';

const Term = styled.span`
  border-bottom: 1px dashed ${(props) => props.theme.tooltip.underline};
`;

const DomainTerm = ({ children, mark = true, infoKey }) => {
  return (
    <>
      {infoKey && INFO[infoKey] && (
        <div>
          <Tooltip
            for={infoKey}
            data={INFO[infoKey].data}
            tldr={INFO[infoKey].tldr}
          >
            {mark ? <Term>{children}</Term> : children}
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default DomainTerm;
