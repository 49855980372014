import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import SectionHeader from '../../../SectionHeader';

function ETFValuation({ data, darkMode }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTablet] = useMediaQuery('(max-width: 1150px)');

  let valuationData = data.Valuation;

  return (
    <>
      <div>
        <div style={{ display: 'flex' }}>
          <SectionHeader value={'Valuation'} infoKey="val" />

          <div style={{ marginTop: '2px', marginLeft: '2px' }}>
            <InfoOutlineIcon
              color="#0072db"
              height="16px"
              width="16px"
            ></InfoOutlineIcon>
          </div>
        </div>

        <div
          style={{
            display: isMobile || isTablet ? 'block' : 'flex',
            marginTop: '10px',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            marginTop: '35px',
          }}
        >
          <div style={{ marginTop: isMobile ? '30px' : '0px' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  P/E Category (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['P/E Category(TTM)']).toFixed(2)}
              </h4>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  P/B Category (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['P/B Category(TTM)']).toFixed(2)}
              </h4>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  P/S Category (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['P/S Category(TTM)']).toFixed(2)}
              </h4>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  P/CF Category (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['P/CF Category(TTM)']).toFixed(2)}
              </h4>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  P/E (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['P/E(TTM)']).toFixed(2)}
              </h4>
            </div>
          </div>

          <div
            style={{
              marginTop: isMobile || isTablet ? '30px' : '0px',
              marginLeft: !isMobile && !isTablet ? '150px' : '',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  P/B (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['P/B(TTM)']).toFixed(2)}
              </h4>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  P/S (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['P/S(TTM)']).toFixed(2)}
              </h4>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  P/CF (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['P/CF(TTM)']).toFixed(2)}
              </h4>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  PEG (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['PEG(TTM)']).toFixed(2)}
              </h4>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '175px' }}>
                <h4
                  style={{
                    marginBottom: '8px',
                    color: darkMode ? '#cccccc' : '#595959',
                  }}
                >
                  PEG Category (TTM)
                </h4>
              </div>
              <h4
                style={{
                  marginLeft: '20px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '620',
                }}
              >
                {parseFloat(valuationData['PEG Category(TTM)']).toFixed(2)}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ETFValuation;
