import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import AreaChart from '../../../AreaChart';
import { getAreaChartData } from '../../../../../utils/ChartUtils';
import ToggleButton from '../../../ToggleButton';
import SectionHeader from '../../../SectionHeader';

const YieldOnCost = ({ data, period, setPeriod }) => {
  return (
    <>
      <SectionHeader
        value="Yield on Cost"
        className="pb-6"
        infoKey="yield_on_cost"
      />
      <BorderedChartContainer className="py-4">
        <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div>
        <AreaChart
          data={getAreaChartData(
            data,
            ['yield_on_cost', 'evaluated_on'],
            period
          )}
          name="Yield on Cost"
          color="#29b1bf"
          gradientColor="#29b1bf"
          yAxisLabelSuffix="%"
          valueType="percent"
        />
      </BorderedChartContainer>
    </>
  );
};

export default YieldOnCost;
