import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
  Text,
} from '@chakra-ui/layout';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import { useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons';
import LoadingIndicator from '../../shared/LoadingIndicator';
import SectorLogo from '../../shared/SectorLogo';
import LetterLogo from '../../shared/LetterLogo';

function MyVerdeWidget({ data, darkMode, link, username }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [notExistsObj, setNotExistsObj] = useState({});

  return (
    <Box
      backgroundColor={darkMode ? '#262626' : 'white'}
      padding={8}
      minH={500}
      position="relative"
    >
      {data[1] ? (
        <>
          <Flex>
            <Box>
              <Text style={{ color: darkMode ? 'white' : 'black' }}>
                Portfolio Value
              </Text>
              <Text
                style={{
                  color: darkMode ? 'white' : 'black',
                  fontSize: 26,
                  fontWeight: 550,
                }}
              >
                $
                {data[1] &&
                  parseFloat(data[1]['portfolio_value']).toLocaleString()}
              </Text>
              <Flex alignItems="center">
                {data[1] && data[1]['portfolio_profit_loss_percent'] > 0 ? (
                  <TriangleUpIcon
                    color={darkMode ? '#4adf79' : '#01874e'}
                  ></TriangleUpIcon>
                ) : (
                  <TriangleDownIcon
                    color={darkMode ? '#ec5b55' : '#BF2F2C'}
                  ></TriangleDownIcon>
                )}
                <Text
                  ml={2}
                  style={{
                    color:
                      data[1] && data[1]['portfolio_profit_loss_percent'] > 0
                        ? darkMode
                          ? '#4adf79'
                          : '#01874e'
                        : darkMode
                        ? '#ec5b55'
                        : '#BF2F2C',
                  }}
                >
                  {data[1] &&
                    parseFloat(
                      data[1]['portfolio_profit_loss_percent']
                    ).toFixed(2)}
                  %
                </Text>
              </Flex>
            </Box>
            <Box ml={isMobile ? 10 : 20}>
              <Text style={{ color: darkMode ? 'white' : 'black' }}>
                Verde Score
              </Text>
              <Flex alignItems="center">
                <img
                  src={VerdeScoreSvg}
                  alt=""
                  className="w-6 h-6 mb-0.5 mr-1 mt-1"
                />
                <Text
                  style={{
                    color: darkMode ? 'white' : 'black',
                    fontSize: 26,
                    fontWeight: 550,
                  }}
                >
                  {data[1] && data[1]['portfolio_verde_score']}
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Box mt={5}>
            {data[0] &&
              data[0]['all_portfolios']
                .sort((a, b) => b['portfolio_percent'] - a['portfolio_percent'])
                .slice(0, 3)
                .map((elem) => {
                  return (
                    elem.symbol_market && (
                      <Flex
                        padding="5px"
                        mt={2}
                        borderRadius="5px"
                        border="0.5px solid gray"
                      >
                        <Box padding="1" display="flex">
                          <Link
                            to={`/${
                              elem.type === 'equity' ? 'stocks' : 'etfs'
                            }/${elem.symbol_market}`}
                            className="flex-1"
                          >
                            <Flex>
                              {elem.type === 'equity' ? (
                                <>
                                  {!notExistsObj[
                                    elem.symbol_market.split('.')[0]
                                  ] ? (
                                    <img
                                      style={{
                                        width: isMobile ? '30px' : '40px',
                                        height: isMobile ? '30px' : '40px',
                                        marginTop: isMobile ? '5px' : '0px',
                                      }}
                                      src={`http://images.verde.finance/${
                                        elem.symbol_market.split('.')[0]
                                      }.png`}
                                      onError={() =>
                                        setNotExistsObj({
                                          ...notExistsObj,
                                          [elem.symbol_market.split('.')[0]]: 1,
                                        })
                                      }
                                    />
                                  ) : (
                                    <LetterLogo
                                      symbol={elem.symbol_market.split('.')[0]}
                                      darkMode={darkMode}
                                    />
                                  )}
                                </>
                              ) : (
                                //   <SectorLogo
                                //     value={elem.symbol_market.split('.')[0]}
                                //     size="large"
                                //     fill={undefined}
                                //   />
                                <LetterLogo
                                  symbol={elem.symbol_market.split('.')[0]}
                                  darkMode={darkMode}
                                />
                              )}

                              <Box display="block" marginLeft="10px">
                                <Text
                                  fontWeight="bold"
                                  color={darkMode ? 'white' : 'black'}
                                >
                                  {elem.symbol_market.split('.')[0]}
                                </Text>
                                <Text
                                  fontSize="xs"
                                  color="#6b737c"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  inlineSize={isMobile ? '55px' : '85px'}
                                  overflow="hidden"
                                >
                                  {elem.symbol}
                                </Text>
                              </Box>
                            </Flex>
                          </Link>
                        </Box>

                        <Box
                          marginLeft="auto"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text
                            style={{
                              color: darkMode ? 'white' : 'black',
                              width: 70,
                            }}
                          >
                            ${parseFloat(elem.current_price).toFixed(2)}
                          </Text>
                          {/* <Text
                          style={{
                            color:
                              elem.value > 0
                                ? darkMode
                                  ? '#4adf79'
                                  : '#01874e'
                                : darkMode
                                ? '#ec5b55'
                                : '#BF2F2C',
                          }}
                        >
                          {elem.percent_change_in_price
                            ? elem.percent_change_in_price
                            : 0}
                          %
                        </Text> */}
                        </Box>
                      </Flex>
                    )
                  );
                })}
          </Box>
          <Link to={`/${username}`}>
            <Text
              mt={8}
              style={{
                color: darkMode ? '#4adf79' : '#01874e',
                textDecoration: 'underline',
                textUnderlineOffset: 3,
                textAlign: 'center',
              }}
            >
              View All
            </Text>
          </Link>

          <Box>
            <button
              onClick={() => link()}
              style={{
                position: 'absolute',
                bottom: 15,
                padding: 5,
                width: '85%',
                color: darkMode ? '#4adf79' : '#01874e',
                border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                borderRadius: 20,
                marginTop: 20,
                fontSize: 15,
                fontWeight: 500,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Link New Portfolio
            </button>
          </Box>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Box>
  );
}

export default MyVerdeWidget;
