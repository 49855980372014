import React from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import styled from 'styled-components';
import { getLogoUrl } from '../../../utils/LogoUtils';
import { formatDecimalValue } from '../../../utils/number';

import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import SymbolLogo from '../../shared/SymbolLogo';
import { ColorCode } from '../../shared/StockTable/columns';
import Tooltip from '../../shared/Tooltip';
import { Subtle, ColoredContentText } from '../../shared/StyedComponents';

import { Text } from '@chakra-ui/layout';

export const TableColumn = styled(ColoredContentText)`
  ${tw`text-sm font-medium flex justify-center`}
`;

export const columns = [
  {
    Header: 'Company Name',
    sticky: 'left',
    accessor: (properties) => {
      return {
        symbol: properties.symbol,
        name: properties.symbol_name,
        logoUrl: getLogoUrl(properties.symbol),
      };
    },
    Cell: ({ value: { symbol, name, logoUrl } }) => {
      return (
        <TableColumn>
          <Link to={`/${symbol}`} className="no-underline text-black">
            <div className="flex text-left">
              <SymbolLogo logoUrl={logoUrl} />
              <div className="flex flex-col ml-4 justify-evenly">
                <Text className="font-medium">{symbol}</Text>
                <Tooltip for={name} data={name} small={true}>
                  <Subtle className="text-xs break-words truncate w-24">
                    {name}
                  </Subtle>
                </Tooltip>
              </div>
            </div>
          </Link>
        </TableColumn>
      );
    },
  },
  {
    Header: 'Stock Price',
    accessor: 'current_price',
    Cell: (row) => <TableColumn>${row.value}</TableColumn>,
  },
  {
    Header: 'Overvalued/Undervalued %',
    accessor: 'fair_price_deviation',
    Cell: (row) => (
      <TableColumn>
        <ColorCode value={formatDecimalValue(row.value)}></ColorCode>
      </TableColumn>
    ),
  },
  {
    Header: 'Verde Score',
    accessor: 'verde_score',
    sticky: 'left',
    Cell: (row) => (
      <TableColumn>
        <img src={VerdeScoreSvg} alt="" className="w-3 mr-1" />
        {row.value}
      </TableColumn>
    ),
  },
  {
    Header: '1M Return %',
    accessor: 'one_month',
    Cell: (row) => (
      <TableColumn className="w-14">
        <ColorCode value={formatDecimalValue(row.value)}></ColorCode>
      </TableColumn>
    ),
  },
  {
    Header: '1Y Return %',
    accessor: 'one_year',
    Cell: (row) => (
      <TableColumn className="w-14">
        <ColorCode value={formatDecimalValue(row.value)}></ColorCode>
      </TableColumn>
    ),
  },
  {
    Header: 'Value',
    accessor: 'market_value',
    Cell: (row) => (
      <TableColumn className="w-14">
        ${formatDecimalValue(row.value)}
      </TableColumn>
    ),
  },
  {
    Header: 'Value %',
    accessor: 'holding_percent',
    Cell: (row) => <TableColumn className="w-14">{row.value}%</TableColumn>,
  },
];
