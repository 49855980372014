const initialState = {
  data: null,
  loading: false,
  error: null,
};

const RealTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_REAL_TIME_LOADING': {
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    }

    case 'FETCH_REAL_TIME_SUCCESS': {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }

    case 'FETCH_REAL_TIME_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default RealTimeReducer;
