import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import { getStackedAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';

const CashflowStatement = ({ data, insights, period, setPeriod }) => {
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'Cash from Operations',
      selected: true,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'Capital Expenditures',
      selected: true,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'Free Cash Flow',
      selected: true,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
    {
      name: 'Dividends Paid',
      selected: false,
      darkColor: '#DDDDDD',
      lightColor: '#012749',
    },
    {
      name: 'Share Buybacks',
      selected: false,
      darkColor: '#FF7979',
      lightColor: '#F26970',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    [
      'evaluated_on',
      'cash_from_ops',
      'capital_expenditures',
      'free_cash_flow',
      'dividend_paid',
      'sale_purchase_stock',
    ],
    period
  );
  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['Cash from Operations'].selected
      ? {
          name: 'Cash from Operations',
          data: fullChartData.map((item) => [item[0], item[1]]),
        }
      : [],
    chartVariants['Capital Expenditures'].selected
      ? {
          name: 'Capital Expenditures',
          data: fullChartData.map((item) => [item[0], item[3]]),
        }
      : [],
    chartVariants['Free Cash Flow'].selected
      ? {
          name: 'Free Cash Flow',
          data: fullChartData.map((item) => [item[0], item[2]]),
        }
      : [],
    chartVariants['Dividends Paid'].selected
      ? {
          name: 'Dividends Paid',
          data: fullChartData.map((item) => [item[0], item[5]]),
        }
      : [],
    chartVariants['Share Buybacks'].selected
      ? {
          name: 'Share Buybacks',
          data: fullChartData.map((item) => [item[0], item[4]]),
        }
      : [],
  ];

  return (
    <>
      <SectionHeader className="mb-6" value="Cash Flow Statement" />
      <BorderedChartContainer>
        <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div>
        <StackedLineChart data={chartData} valueType="currency" />
        <div className="flex justify-center">
          <MultiLineGraphSelectorButton
            values={buttonValues}
            setValues={setButtonValues}
          />
        </div>
      </BorderedChartContainer>

      <BulletPoints
        title="Positives"
        points={insights?.cashFlowStatement?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.cashFlowStatement?.negatives}
        isPositive={false}
      />
    </>
  );
};

export default CashflowStatement;
