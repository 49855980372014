import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const data = [
  { name: 'Founders', y: 25, color: '#4ab0d7' },
  { name: 'Public', y: 13, color: '#F1A741' },
  { name: 'Institutions', y: 24, color: '#F37247' },
  { name: 'Funds', y: 56, color: '#4ad7ad' },
];

const PieChart = ({ darkMode }) => {
  const options = {
    chart: {
      type: 'pie',
      height: 450,
      style: {
        fontFamily: 'Roboto',
      },
      margin: [0, 0, 60, 0],
      backgroundColor: 'transparent',
    },
    title: {
      text: null,
    },
    yAxis: {},
    plotOptions: {
      pie: {
        shadow: false,
        allowPointSelect: false,
      },
    },
    series: [
      {
        name: 'Ownership',
        data: data,
        size: '90%',
        innerSize: '65%',
        borderWidth: 5,
        borderColor: darkMode ? '#1d212b' : '#fff',
        showInLegend: false,
        dataLabels: {
          connectorWidth: 0,
          useHTML: true,
          formatter: function () {
            const point = this.point;
            const textColor = darkMode ? '#d8d9e4' : '#3a3a3a';
            const valueColor = darkMode ? '#86efaf' : '#5bae7f';
            const iconSize = 28;
            return (
              '<div style="font-size:18px;font-weight:500;color:' +
              textColor +
              ';">' +
              '<span style="font-size:' +
              iconSize +
              'px;line-height:1;position:relative;top:2px;margin-right:4px;color:' +
              point.color +
              '">\u25CF</span>' +
              point.name +
              '</div>' +
              '<div style="font-size:24px;font-weight:500;margin-top:4px;color:' +
              valueColor +
              ';">' +
              point.y +
              '%</div>'
            );
          },
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    ],
    /* legend: {
      enabled: false,
    }, */
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="-my-12 sm:-my-8 md:-my-20 lg:-my-20 xl:-my-8">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(PieChart);
