import React, { useState } from 'react';
import { Box, Flex, Text, RadioGroup, Radio } from '@chakra-ui/react';
import SectionHeader from '../../shared/SectionHeader';
import { useMediaQuery } from '@chakra-ui/react';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortfolioModal  from '../MyVerdeRevamp/Modal/PortfolioModal';
import LightBulb from '../../../public/images/LightBulb.png';
import portfolioSettings from '../../../public/images/portfolioSettings.png';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import portfolio from '../../tabs/portfolio/portfolio';

function AccountVisibility({ darkMode, data, callback, portfolioData }) {
  const visibility = [
    {
      id: 'Private',
      description:
        'All verde users must send you a follow request to follow you',
      key: 'PRIVATE',
    },
    {
      id: 'SEPARATOR',
    },
    {
      id: 'Public',
      description:
        'Any Verde user can follow your account,and view your linked portfolio(s)',
      key: 'PUBLIC',
    },
    // {
    //   id: 'Friendly',
    //   description:
    //     'Anyone who you can accept as a follower will be able to see your portfolio holdings as well as your trades',
    // },
  ];

  const portfolioVisibility = [
    {
      id: 'Visible Portfolio',
      description:
        'Your porfolio is only visible to your Verde followers (or all Verde users if Profile is set to public) ',
      key: 'VISIBLE',
    },
    {
      id: 'SEPARATOR',
    },
    {
      id: 'Hidden Portfolio',
      description: 'Your Portfolio will only be visible to you',
      key: 'HIDDEN',
    },
  ];

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const initialSelected = data && data['profile_visibility'];
  const initialPortfolioSelected = data && data['portfolio_visibility'];
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({ key: null });
  const [portfolioSelected, setPortfolioSelected] = useState({ key: null });

  const separator = () => {
    return (
      <div
        style={{
          height: '0px',
          width: '90%',
          margin: 'auto',
          marginTop: 25,
          border: `0.5px solid ${darkMode ? 'rgb(51,51,51)' : '#d9d9d9'}`,
          borderWidth: 0.5,
        }}
      />
    );
  };

  return (
    <Box>
      <Flex>
        <Box>
          <SectionHeader value="Privacy Settings" />
          {isMobile && (
            <Flex marginLeft="auto" alignItems="center" mt={1}>
              <img
                src={LightBulb}
                width={isMobile ? 22 : 28}
                height={isMobile ? 22 : 28}
              />
              <Text
                color={darkMode ? '#cccccc' : '#8c8c8c'}
                fontSize={isMobile ? 12 : 15}
                marginLeft={1}
              >
                $ amounts of your accounts are never displayed to your followers
              </Text>
            </Flex>
          )}
        </Box>

        {showModal && (
          <PortfolioModal
            data={portfolioData} //data is ind. portfolios
            darkMode={darkMode}
            closeModal={() => setShowModal(false)}
            profileData={data}
          />
        )}

        {!isMobile && (
          <Flex marginLeft="auto" alignItems="center">
            <img src={LightBulb} width={28} height={28} />
            <Text color={darkMode ? '#cccccc' : '#8c8c8c'} marginLeft={1}>
              $ accounts of your accounts are never displayed to your followers
            </Text>
          </Flex>
        )}
      </Flex>
      <Box>
        <Box>
          <Flex alignItems="center" mt={5} mb={5}>
            <FontAwesomeIcon
              icon={faUser}
              color={darkMode ? 'white' : 'black'}
              width={55}
              height={55}
            />
            <Text fontSize={18} color={darkMode ? 'white' : 'black'} ml={2}>
              Profile Settings
            </Text>
          </Flex>
        </Box>

        <Flex direction={isMobile ? 'column' : 'row'}>
          {visibility.map((elem) => {
            return (
              <>
                {elem.id !== 'SEPARATOR' ? (
                  <Box mt={2} width={isMobile ? '' : '50%'}>
                    <Flex>
                      <Box>
                        <RadioGroup
                          value={selected.key ? selected.key : initialSelected}
                          color={darkMode ? 'white' : 'black'}
                        >
                          <Radio
                            borderColor={darkMode ? '#4adf79' : '#01874e'}
                            colorScheme={darkMode ? '#4adf79' : 'green'}
                            color={darkMode ? 'white' : 'black'}
                            value={elem.key}
                            onChange={(e) => {
                              setSelected(elem);
                              callback({
                                ...data,
                                profile_visibility: elem.key,
                                portfolio_visibility: portfolioSelected.key
                                  ? portfolioSelected.key
                                  : initialPortfolioSelected,
                              });
                            }}
                          >
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: 17,
                                marginLeft: 5,
                              }}
                            >
                              {elem.id}
                            </span>
                          </Radio>
                        </RadioGroup>
                      </Box>
                      {elem.id === 'Private' && (
                        <Text
                          marginLeft="auto"
                          marginRight={5}
                          color={darkMode ? '#4adf79' : '#01874e'}
                        >
                          Default
                        </Text>
                      )}
                      {elem.id !== 'Private' &&
                        (selected.id
                          ? selected.id === elem.id
                          : elem.key === initialSelected) && (
                          <Text
                            marginLeft="auto"
                            marginRight={5}
                            color={darkMode ? '#4adf79' : '#01874e'}
                          >
                            Selected
                          </Text>
                        )}
                    </Flex>

                    <Text
                      mb={5}
                      mt={2}
                      ml={7}
                      style={{ color: darkMode ? 'white' : 'black' }}
                    >
                      {elem.description}
                    </Text>
                  </Box>
                ) : (
                  !isMobile && (
                    <div
                      style={{
                        height: '90px',
                        width: '0px',
                        marginTop: '5px',
                        border: `0.5px solid ${
                          darkMode ? 'rgb(51,51,51)' : '#8c8c8c'
                        }`,
                        marginRight: 15,
                        marginLeft: 15,
                        borderWidth: '1px',
                      }}
                    />
                  )
                )}
              </>
            );
          })}
        </Flex>

        {separator()}

        <Box>
          <Flex alignItems={'center'}>
            <Flex alignItems="center" mt={5} mb={5}>
              <img src={portfolioSettings} width={30} height={30} />
              <Text fontSize={18} color={darkMode ? 'white' : 'black'} ml={2}>
                Portfolio Settings
              </Text>
            </Flex>
            <Box ml="auto">
              <button
                onClick={() => setShowModal(true)}
                style={{
                  padding: 5,
                  width: '160px',
                  color: darkMode ? '#4adf79' : '#01874e',
                  border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                  borderRadius: 20,
                  // marginTop: 10,
                  fontSize: 15,
                  fontWeight: 500,
                  padding: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Manage Portfolio
              </button>
            </Box>
          </Flex>

          <Flex direction={isMobile ? 'column' : 'row'}>
            {portfolioVisibility.map((elem) => {
              return (
                <>
                  {elem.id !== 'SEPARATOR' ? (
                    <Box mt={2} width={isMobile ? '' : '50%'}>
                      <Flex>
                        <Box>
                          <RadioGroup
                            value={
                              portfolioSelected.key
                                ? portfolioSelected.key
                                : initialPortfolioSelected
                            }
                            color={darkMode ? 'white' : 'black'}
                          >
                            <Radio
                              borderColor={darkMode ? '#4adf79' : '#01874e'}
                              colorScheme={darkMode ? '#4adf79' : 'green'}
                              color={darkMode ? 'white' : 'black'}
                              value={elem.key}
                              onChange={(e) => {
                                setPortfolioSelected(elem);
                                callback({
                                  ...data,
                                  portfolio_visibility: elem.key,
                                  profile_visibility: selected.key
                                    ? selected.key
                                    : initialSelected,
                                });
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 17,
                                  marginLeft: 5,
                                }}
                              >
                                {elem.id}
                              </span>
                            </Radio>
                          </RadioGroup>
                        </Box>
                        {elem.id === 'Visible Portfolio' && (
                          <Text
                            marginLeft="auto"
                            marginRight={5}
                            color={darkMode ? '#4adf79' : '#01874e'}
                          >
                            Default
                          </Text>
                        )}
                        {elem.id !== 'Visible Portfolio' &&
                          (portfolioSelected.id
                            ? portfolioSelected.id === elem.id
                            : elem.key === initialPortfolioSelected) && (
                            <Text
                              marginLeft="auto"
                              marginRight={5}
                              color={darkMode ? '#4adf79' : '#01874e'}
                            >
                              Selected
                            </Text>
                          )}
                      </Flex>

                      <Text
                        mb={5}
                        mt={2}
                        ml={7}
                        style={{ color: darkMode ? 'white' : 'black' }}
                      >
                        {elem.description}
                      </Text>
                    </Box>
                  ) : (
                    !isMobile && (
                      <div
                        style={{
                          height: '90px',
                          width: '0px',
                          marginTop: '5px',
                          border: `0.5px solid ${
                            darkMode ? 'rgb(51,51,51)' : '#8c8c8c'
                          }`,
                          marginRight: 15,
                          marginLeft: 15,
                          borderWidth: '1px',
                        }}
                      />
                    )
                  )}
                </>
              );
            })}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}
export default AccountVisibility;
