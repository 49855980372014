import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
  Button,
} from '@chakra-ui/react';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/layout';
import Select from 'react-select';
import { connect } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';
import { searchIcon } from '../HomepageLogin/icons';
import WrappedAxios from '../../../utils/WrappedAxios';
import { isProd, PLAID_USERS_LINK } from '../../../config/default';

const Pill = ({ type, theme = {}, first, value, onCloseClick }) => (
  <Flex
    bg={theme.filters.pillBg}
    color={theme.filters.pillColor}
    borderColor={theme.filters.pillBorder}
    borderWidth={'1px'}
    fontSize="sm"
    alignItems="center"
    justify={'space-between'}
    p="4"
    pt="2.5"
    pb="2.5"
    borderRadius="20px"
    my={'2'}
    mr="2"
    whiteSpace="nowrap"
  >
    <Box>
      <Text style={{ fontSize: '15px' }}>
        {type}, <b>{value}</b>
      </Text>
    </Box>
    <div style={{ cursor: 'pointer', marginLeft: '10px' }}>
      <CloseIcon w="8px" h="8px" onClick={onCloseClick} />
    </div>
  </Flex>
);

function FilterOptions({
  selectedMainMenu,
  theme = {},
  filterOptions = [],
  onBackClick,
  onFilterSelect,
  selectedFilters = {},
  onFilterChange,
  searchTerm,
  setSearchTerm,
  selectStyles,
  darkMode,
}) {
  return (
    <Container padding="0">
      <Flex color={theme.addFilterText} alignItems="end" mt="2">
        <button
          onClick={() => {
            onBackClick();
            setSearchTerm('');
          }}
        >
          <Flex alignItems="center">
            <ChevronLeftIcon />
            <Text
              color={darkMode ? '#4adf79' : '#01874e'}
              ml="1"
              textTransform="uppercase"
              fontSize="sm"
              fontWeight="semibold"
            >
              {selectedMainMenu.label}
            </Text>
          </Flex>
        </button>
      </Flex>
      <Box mt="2">
        <InputSearch
          searchTerm={searchTerm}
          themeStyleObj={theme}
          onFilterChange={onFilterChange}
        />
      </Box>
      <Flex direction={'column'} mt="4">
        {filterOptions
          .filter((elem) => elem.title.toLowerCase().includes(searchTerm))
          .map((option) => {
            return (
              <Box mb="6" key={option.title}>
                <Text
                  mb="2"
                  color={theme.filters.selectBoxLabelColor}
                  fontSize="sm"
                >
                  {option.title}
                </Text>
                <Select
                  isSearchable={true}
                  isMulti={option.isMulti}
                  styles={selectStyles}
                  name="color"
                  options={option.options.map((s) => ({
                    value: s,
                    label: s.title ?? s,
                  }))}
                  height="42px"
                  value={selectedFilters[option.property]?.[0] ?? null}
                  onChange={(selected) =>
                    onFilterSelect(selected, option, selectedMainMenu.label)
                  }
                />
              </Box>
            );
          })}
      </Flex>
    </Container>
  );
}

const InputSearch = ({ themeStyleObj, searchTerm, onFilterChange }) => (
  <InputGroup
    size="lg"
    w={['full']}
    outline="none"
    borderColor={themeStyleObj.filters.searchBorder}
    bg={themeStyleObj.filters.searchBg}
    position="relative"
    borderRadius="20px"
  >
    <InputLeftElement
      mx={2}
      outline="none"
      ml={4}
      transform="translateY(-50%) scale(0.8)"
      w="max"
      justifyContent="end"
      top="50%"
    >
      {searchIcon}
    </InputLeftElement>
    <Input
      type="text"
      outline="none"
      _hover={{ borderColor: '#24b552' }}
      _focus={{ borderColor: 'none', boxShadow: 'none' }}
      color={themeStyleObj.filters.selectBoxLabelColor}
      placeholder="Search"
      borderWidth="0px"
      fontSize="sm"
      size={'lg'}
      value={searchTerm}
      borderRadius="20px"
      onInput={(event) => {
        const target = event.target;
        onFilterChange(target.value);
      }}
    />
  </InputGroup>
);

function FiltersMainMenu({
  setSelectedMainMenu,
  filterMenuOptions,
  onFilterChange,
  searchTerm,
  setSearchTerm,
  themeStyleObj = {},
  darkMode,
}) {
  const onClickOfMenu = (menu) => {
    setSelectedMainMenu(menu);
  };

  return (
    <>
      <Box bg={darkMode ? '#161616' : '#f4f4f4'} mb="5">
        <Text
          mb="2"
          textTransform="uppercase"
          fontSize="x-small"
          color={themeStyleObj.filters.headerLink}
        >
          Filter stocks by
        </Text>

        <InputSearch
          themeStyleObj={themeStyleObj}
          searchTerm={searchTerm}
          onFilterChange={onFilterChange}
        />
      </Box>
      {filterMenuOptions
        .filter((elem) =>
          elem.label.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((menu) => (
          <Box overflow={'auto'} key={menu.label} alignItems="center">
            <div
              style={{
                display: 'flex',
                marginBottom: '6px',
                marginTop: '6px',
                borderRadius: '5px',
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: darkMode ? '#262626' : 'white',
              }}
              onClick={() => {
                onClickOfMenu(menu);
                setSearchTerm('');
              }}
            >
              <Text
                color={themeStyleObj.filters.headerLink}
                style={{ fontWeight: 'bold' }}
              >
                {menu.label}
              </Text>

              <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                <ChevronRightIcon
                  color={darkMode ? 'white' : 'black'}
                  w="8"
                  h="8"
                />
              </div>
            </div>
          </Box>
        ))}
    </>
  );
}

function FiltersCustomPreset({
  setSelectedMainMenu,
  filterMenuOptions,
  onFilterChange,
  searchTerm,
  setSearchTerm,
  themeStyleObj = {},
  darkMode,
  setUserPresetName,
  setUserDesc,
}) {
  const onClickOfMenu = (menu) => {
    setSelectedMainMenu(menu);
  };

  return (
    <>
      <div>
        <div style={{ marginBottom: '10px' }}>
          <Text
            style={{ color: darkMode ? 'white' : 'black', marginBottom: '5px' }}
          >
            Preset Name<sup style={{ color: 'red' }}>*</sup>
          </Text>
          <input
            style={{
              width: '100%',
              padding: '5px',
              borderRadius: '5px',
              color: darkMode ? 'white' : 'black',
              backgroundColor: darkMode ? '#262626' : 'white',
              borderColor: darkMode ? '#595959' : '',
              borderWidth: '1px',
            }}
            placeholder="Add a preset name"
            onChange={(e) => setUserPresetName(e.target.value)}
          />
        </div>

        <div style={{ marginTop: '10px' }}>
          <Text
            style={{ color: darkMode ? 'white' : 'black', marginBottom: '5px' }}
          >
            Description
          </Text>
          <textarea
            style={{
              width: '100%',
              padding: '10px',
              height: '150px',
              borderRadius: '5px',
              color: darkMode ? 'white' : 'black',
              backgroundColor: darkMode ? '#262626' : 'white',
              borderColor: darkMode ? '#595959' : '',
              borderWidth: '1px',
            }}
            placeholder="Add a short description for your preset"
            onChange={(e) => setUserDesc(e.target.value)}
          />
        </div>
      </div>
    </>
  );
}

const filterMap = {
  overview: { title: 'Overview' },
  ratings: { title: 'Ratings' },
  valuation: { title: 'Valuation' },
  financials: { title: 'Growth' },
  profitability: { title: 'Profitability' },
  performance: { title: 'Performance' },
};

const filterMenuOptions = Object.keys(filterMap).map((item) => ({
  label: filterMap[item].title,
  value: item,
}));

function NewFilters({
  theme,
  applyFilter,
  filterOptions,
  selectStyles,
  filterValues,
  getPillFilters,
  allSelectedFilters,
  darkMode,
  closeModal,
}) {
  const [selectedMainMenu, setSelectedMainMenu] = useState({});
  const showFilterOptions = selectedMainMenu.value;
  const [selectedFilters, setSelectedFilters] = useState(allSelectedFilters);
  const [tempSelection, setTempSelection] = useState(allSelectedFilters);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFilterOptions, setFilteredFilterOptions] = useState(
    filterOptions
  );
  const [pillFilters, setPillFilters] = useState(getPillFilters(tempSelection));

  const [createCustomPreset, setCreateCustomPreset] = useState(false);

  const [presetName, setPresetName] = useState('');
  const [presetDesc, setPresetDesc] = useState('');

  function saveUserPreset(name, desc) {
    WrappedAxios.post(`${PLAID_USERS_LINK}/screener-preset`, {
      name: name,
      description: desc,
      rule: selectedFilters,
    }).catch((e) => alert(e.message));
  }

  const onFilterSelect = (selectedOption, { property }, category) => {
    const _selectedFilters = {
      ...tempSelection,
      [property]:
        selectedOption.value || Array.isArray(selectedOption)
          ? [selectedOption]
          : [],
    };
    setTempSelection(_selectedFilters);
  };

  const onFilterRemove = (item, category) => {
    delete selectedFilters[category];
    // alert(JSON.stringify(selectedFilters))
    setSelectedFilters({ ...selectedFilters });
    //addFilter(selectedFilters);
  };

  useEffect(() => {
    setPillFilters(getPillFilters(tempSelection));
  }, [getPillFilters, tempSelection]);

  const onAddClick = () => {
    setSelectedFilters(tempSelection);
    setSelectedMainMenu({});
  };

  // useEffect(() => {
  //   if (!searchTerm) {
  //     setFilteredFilterOptions(filterOptions);
  //     return;
  //   }
  //   const _filteredFilterOptions = Object.keys(filteredFilterOptions).reduce(
  //     (accumulator, option) => {
  //       accumulator[option] = filteredFilterOptions[option].filter((item) =>
  //         item.title?.toLowerCase().includes(searchTerm?.toLowerCase())
  //       );
  //       return accumulator;
  //     },
  //     {}
  //   );
  //   setFilteredFilterOptions(_filteredFilterOptions);
  // }, [filterOptions, filteredFilterOptions, searchTerm]);

  return (
    <Container padding="0" mt="1.5" className="h-full flex flex-col">
      {showFilterOptions ? null : (
        <Flex overflow="auto" w="full" mb="3" flexWrap="wrap">
          {pillFilters.map((filter) => {
            return (
              <Pill
                key={filter.type}
                type={filter.type}
                value={filter.value}
                theme={theme}
                onCloseClick={() => {
                  setPillFilters(pillFilters.filter((elem) => elem !== filter));
                  onFilterRemove(filter.value, filter.property);
                }}
                // onCloseClick={() => onFilterRemove(filter)}
              />
            );
          })}
        </Flex>
      )}
      <div className="flex-1" style={{ marginBottom: 10 }}>
        {createCustomPreset ? (
          <FiltersCustomPreset
            themeStyleObj={theme}
            filterMenuOptions={filterMenuOptions}
            searchTerm={searchTerm}
            onFilterChange={(value) => setSearchTerm(value)}
            onMenuClick={() => {}}
            setSelectedMainMenu={setSelectedMainMenu}
            darkMode={darkMode}
            setUserPresetName={(value) => setPresetName(value)}
            setUserDesc={(value) => setPresetDesc(value)}
            setSearchTerm={() => setSearchTerm('')}
          />
        ) : selectedMainMenu.value ? (
          <FilterOptions
            theme={theme}
            selectedMainMenu={selectedMainMenu}
            filterOptions={filteredFilterOptions[selectedMainMenu.value]}
            onBackClick={() => setSelectedMainMenu({})}
            onFilterSelect={onFilterSelect}
            selectedFilters={tempSelection}
            themeStyleObj={theme}
            searchTerm={searchTerm}
            selectStyles={selectStyles}
            onFilterChange={(value) => setSearchTerm(value)}
            darkMode={darkMode}
            setSearchTerm={() => setSearchTerm('')}
          />
        ) : (
          <FiltersMainMenu
            themeStyleObj={theme}
            filterMenuOptions={filterMenuOptions}
            searchTerm={searchTerm}
            onFilterChange={(value) => setSearchTerm(value)}
            onMenuClick={() => {}}
            setSelectedMainMenu={setSelectedMainMenu}
            darkMode={darkMode}
            setSearchTerm={() => setSearchTerm('')}
          />
        )}
      </div>
      <div
        className="flex-col h-10"
        style={{ marginTop: 'auto', marginLeft: 'auto' }}
      >
        {createCustomPreset ? (
          <div
            style={{ display: 'flex', marginTop: 'auto', marginLeft: 'auto' }}
          >
            <Button
              // _hover={{
              //   backgroundColor: theme.filters.applyFilterBtnBgHover,
              // }}
              borderColor={darkMode ? '#4adf79' : '#01874e'}
              borderWidth="1px"
              bg={'transparent'}
              color={darkMode ? 'white' : 'black'}
              onClick={() => {
                setCreateCustomPreset(false);
                closeModal();
              }}
              px="40px"
              py="10px"
              mr="5px"
              borderRadius="20px"
            >
              Cancel
            </Button>

            <Button
              _hover={{
                backgroundColor: theme.filters.applyFilterBtnBgHover,
              }}
              bg={darkMode ? '#4adf79' : '#01874e'}
              color={darkMode ? 'black' : 'white'}
              px="40px"
              ml="5px"
              disabled={presetName === ''}
              borderRadius="20px"
              py="10px"
              onClick={() => {
                if (presetName !== '') {
                  saveUserPreset(presetName, presetDesc);
                  applyFilter(selectedFilters);
                  closeModal();
                } else {
                  // alert('NO NO NO NO')
                }
              }}
            >
              Save {'&'} Apply
            </Button>
          </div>
        ) : showFilterOptions ? (
          <Button
            onClick={onAddClick}
            w="100%"
            bg={darkMode ? '#4adf79' : '#01874e'}
            _hover={{
              backgroundColor: theme.filters.applyFilterBtnBgHover,
            }}
            color={darkMode ? 'black' : 'white'}
            px="40px"
            mr="2px"
            borderRadius="20px"
            py="10px"
          >
            Add
          </Button>
        ) : (
          <div
            style={{ display: 'flex', marginTop: 'auto', marginLeft: 'auto' }}
          >
            {/* <Button
              // _hover={{
              //   backgroundColor: theme.filters.applyFilterBtnBgHover,
              // }}
              borderColor={darkMode ? '#4adf79' : '#01874e'}
              borderWidth="1px"
              bg={'transparent'}
              color={darkMode ? 'white' : 'black'}
              onClick={() => {
                setCreateCustomPreset(true);
              }}
              px="40px"
              py="10px"
              mr="5px"
              borderRadius="20px"
            >
              Save Preset
            </Button> */}

            <Button
              _hover={{
                backgroundColor: theme.filters.applyFilterBtnBgHover,
              }}
              bg={darkMode ? '#4adf79' : '#01874e'}
              color={darkMode ? 'black' : 'white'}
              px="40px"
              ml="5px"
              borderRadius="20px"
              py="10px"
              onClick={() => applyFilter(selectedFilters)}
            >
              Apply Filter
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  filterValues: state.screenerState.filterValues.data,
});

export default connect(mapStateToProps, null)(NewFilters);
