import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import ETFHistoryInfo from './ETFHistoryInfo';
import SectionHeader from '../../../../SectionHeader';
import moment from 'moment';
const Metric = styled.div`
  ${tw`flex flex-col items-center font-medium`};
`;

const ETFInformation = ({ data, darkMode, etfData }) => {
  const chartData = data
    .map((raw) =>
      Object.keys(raw)
        .filter((key) => ['price_on_date', 'adjusted_price'].includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {})
    )
    .map((item) => Object.values(item))
    .map((item) => {
      let timestamp = moment(item[0], 'DD-MM-YYYY').valueOf();

      return [timestamp, item[1]];
    })
    .sort((a, b) => a[0] - b[0]);
  debugger;
  for (let i = 0; i < chartData.length; i++) {
    let elem = chartData[i];

    if (elem[0] === undefined || elem[0] === null || elem[0] === NaN) {
      chartData[i][0] = -1;
    }
    if (elem[1] === undefined || elem[1] === null || elem[1] === NaN) {
      chartData[i][1] = -1;
    }
  }

  return (
    <>
      <SectionHeader value="Performance Chart" />

      <div style={{ marginTop: '10px', paddingTop: '8px' }}>
        {etfData.Performance && (
          <ETFHistoryInfo
            data={chartData}
            growthMetrics={{
              fiveYears: etfData.Performance.fiveyears
                ? etfData.Performance.fiveyears.toFixed(2)
                : '---',
              oneMonth: etfData.Performance.onemonth
                ? etfData.Performance.onemonth.toFixed(2)
                : '---',
              oneWeek: etfData.Performance.oneweek
                ? etfData.Performance.oneweek.toFixed(2)
                : '---',
              oneYear: etfData.Performance.oneyear
                ? etfData.Performance.oneyear.toFixed(2)
                : '---',
              sixMonths: etfData.Performance.sixmonths
                ? etfData.Performance.sixmonths.toFixed(2)
                : '---',
              threeMonths: etfData.Performance.threeyears
                ? etfData.Performance.threeyears.toFixed(2)
                : '---',
              ytd: etfData.Performance.ytd.toFixed(2)
                ? etfData.Performance.ytd.toFixed(2)
                : '---',
            }}
          />
        )}
      </div>

      <div className="md:flex md:justify-around grid grid-cols-3 gap-6">
        <Metric>
          <div
            style={{
              backgroundColor: darkMode ? '#343434' : '#F9F9F9',
              padding: '10px',
              width: '125px',
              textAlign: 'center',
            }}
          >
            <h6
              style={{
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              ${parseFloat(etfData.ETF_Data['52weekHigh']).toFixed(2)}
            </h6>
            <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>52-Week-High</h6>
          </div>
        </Metric>
        <Metric>
          <div
            style={{
              backgroundColor: darkMode ? '#343434' : '#F9F9F9',
              padding: '10px',
              width: '125px',
              textAlign: 'center',
            }}
          >
            <h6
              style={{
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              ${parseFloat(etfData.ETF_Data['52weekLow']).toFixed(2)}
            </h6>
            <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>52-Week-Low</h6>
          </div>
        </Metric>
        <Metric>
          <div
            style={{
              backgroundColor: darkMode ? '#343434' : '#F9F9F9',
              padding: '10px',
              width: '125px',
              textAlign: 'center',
            }}
          >
            <h6
              style={{
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              ${parseFloat(etfData.ETF_Data['50D_MA']).toFixed(2)}
            </h6>
            <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>50D MDA</h6>
          </div>
        </Metric>
        <Metric>
          <div
            style={{
              backgroundColor: darkMode ? '#343434' : '#F9F9F9',
              padding: '10px',
              width: '125px',
              textAlign: 'center',
            }}
          >
            <h6
              style={{
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              ${parseFloat(etfData.ETF_Data['200D_MA']).toFixed(2)}
            </h6>
            <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>200D MDA</h6>
          </div>
        </Metric>
      </div>
    </>
  );
};

export default ETFInformation;
