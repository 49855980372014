export default class ValuationCM {
  constructor(build) {
    this._symbol = build._symbol;
    this._growthMetrics = build._growthMetrics;
    this._priceToEarnings = build._priceToEarnings;
    this._priceToEarningsGrowth = build._priceToEarningsGrowth;
    this._priceToBook = build._priceToBook;
    this._priceToSales = build._priceToSales;
  }

  get symbol() {
    return this._symbol;
  }

  set symbol(symbol) {
    this._symbol = symbol;
  }

  get growthMetrics() {
    return this._growthMetrics;
  }

  set growthMetrics(growthMetrics) {
    this._growthMetrics = growthMetrics;
  }

  get priceToEarnings() {
    return this._priceToEarnings;
  }

  set priceToEarnings(priceToEarnings) {
    this._priceToEarnings = priceToEarnings;
  }

  get priceToEarningsGrowth() {
    return this._priceToEarningsGrowth;
  }

  set priceToEarningsGrowth(priceToEarningsGrowth) {
    this._priceToEarningsGrowth = priceToEarningsGrowth;
  }

  get priceToBook() {
    return this._priceToBook;
  }

  set priceToBook(priceToBook) {
    this._priceToBook = priceToBook;
  }

  get priceToSales() {
    return this._priceToSales;
  }

  set priceToSales(priceToSales) {
    this._priceToSales = priceToSales;
  }

  static get builder() {
    class Builder {
      symbol(symbol) {
        this._symbol = symbol;
        return this;
      }

      growthMetrics(growthMetrics) {
        this._growthMetrics = growthMetrics;
        return this;
      }

      priceToEarnings(priceToEarnings) {
        this._priceToEarnings = priceToEarnings;
        return this;
      }

      priceToEarningsGrowth(priceToEarningsGrowth) {
        this._priceToEarningsGrowth = priceToEarningsGrowth;
        return this;
      }

      priceToBook(priceToBook) {
        this._priceToBook = priceToBook;
        return this;
      }

      priceToSales(priceToSales) {
        this._priceToSales = priceToSales;
        return this;
      }

      build() {
        return new ValuationCM(this);
      }
    }
    return Builder;
  }
}
