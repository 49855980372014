import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import { getStackedAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';

const ProfitabilityRatios = ({ data, insights, period, setPeriod }) => {
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'Gross Margin',
      selected: false,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'EBITDA Margin',
      selected: false,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'EBIT Margin',
      selected: true,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
    {
      name: 'Net Margin',
      selected: true,
      darkColor: '#DDDDDD',
      lightColor: '#012749',
    },
    {
      name: 'FCF Margin',
      selected: true,
      darkColor: '#FF7979',
      lightColor: '#F26970',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    [
      'evaluated_on',
      'free_cash_flow_margin',
      'gross_profit_margin',
      'ebitda_margin',
      'ebit_margin',
      'net_income_margin',
    ],
    period
  );
  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['Gross Margin'].selected
      ? {
          name: 'Gross Margin',
          data: fullChartData.map((item) => [item[0], item[1]]),
        }
      : [],
    chartVariants['EBITDA Margin'].selected
      ? {
          name: 'EBITDA Margin',
          data: fullChartData.map((item) => [item[0], item[2]]),
        }
      : [],
    chartVariants['EBIT Margin'].selected
      ? {
          name: 'EBIT Margin',
          data: fullChartData.map((item) => [item[0], item[3]]),
        }
      : [],
    chartVariants['Net Margin'].selected
      ? {
          name: 'Net Margin',
          data: fullChartData.map((item) => [item[0], item[4]]),
        }
      : [],
    chartVariants['FCF Margin'].selected
      ? {
          name: 'FCF Margin',
          data: fullChartData.map((item) => [item[0], item[5]]),
        }
      : [],
  ];

  return (
    <>
      <SectionHeader className="mb-6" value="Profitability Ratios" />
      <BorderedChartContainer>
        <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div>
        <StackedLineChart data={chartData} valueType="percent" />
        <div className="flex justify-center">
          <MultiLineGraphSelectorButton
            values={buttonValues}
            setValues={setButtonValues}
          />
        </div>
      </BorderedChartContainer>

      <BulletPoints
        title="Positives"
        points={insights?.profitabilityRatios?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.profitabilityRatios?.negatives}
        isPositive={false}
      />
    </>
  );
};

export default ProfitabilityRatios;
