import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import {
  getAdvancedTimelineChartData,
  getPresentDateForFutureGrowth,
} from '../../../../../utils/ChartUtils';
import AdvancedTimelineChart from '../../../AdvancedTimelineChart';
import SectionHeader from '../../../SectionHeader';

const RevenueProjection = ({ data, insights }) => {
  const revenuePositives = insights?.revenue?.positives
    ? insights?.revenue?.positives
    : [];
  const revenueGrowthPositives = insights?.revenueGrowth?.positives
    ? insights?.revenueGrowth?.positives
    : [];
  const revenueNegatives = insights?.revenue?.negatives
    ? insights?.revenue?.negatives
    : [];
  const revenueGrowthNegatives = insights?.revenueGrowth?.negatives
    ? insights?.revenueGrowth?.negatives
    : [];

  return (
    <>
      <SectionHeader
        value="Revenue Projection"
        className="pb-6"
        infoKey="revenue"
      />
      <BorderedChartContainer className="py-4">
        <AdvancedTimelineChart
          data={getAdvancedTimelineChartData(data, [
            'revenueAverage',
            'revenueLow',
            'revenueHigh',
            'revenueActual',
            'evaluatedOn',
          ])}
          chartVariant="Revenue"
          presentDate={getPresentDateForFutureGrowth(data)}
        />
      </BorderedChartContainer>

      {(revenuePositives.length > 0 || revenueGrowthPositives.length > 0) && (
        <BulletPoints
          title="Positives"
          points={[...revenuePositives, ...revenueGrowthPositives]}
          isPositive={true}
        />
      )}
      {(revenueNegatives.length > 0 || revenueGrowthNegatives.length > 0) && (
        <BulletPoints
          title="Risks"
          points={[...revenueNegatives, ...revenueGrowthNegatives]}
          isPositive={false}
        />
      )}
    </>
  );
};

export default RevenueProjection;
