import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import DomainTerm from '../DomainTerm';

const StyledButton = styled.button`
  ${tw`bg-transparent font-medium p-1 min-w-full border hover:border-transparent focus:outline-none rounded m-2 text-xs border-gray-400 text-gray-500`}
  color: ${(props) =>
    props.selected ? props.theme.content.color : props.theme.table.color};
  border: 1px solid
    ${(props) => (props.selected ? props.color : props.theme.imageBorder)};
  height: 32px;
  font-family: Roboto;
  font-weight: ${(props) => (props.selected ? 'bolder' : 'normal')};
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${(props) => (props.selected ? props.color + '30' : '')};
  border-color: ${(props) =>
    props.selected ? props.color : props.theme.imageBorder};
  &:hover {
    border-color: ${(props) =>
      props.selected ? props.color : props.theme.imageBorder};
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem !important;
  color: ${(props) => props.color} !important;
`;

const MultiLineGraphSelectorButton = (props) => {
  const { values, setValues, darkMode } = props;

  const updateSelected = (index) => {
    const filtered = values.filter((t) => t.selected);
    if (filtered.length === 1 && filtered[0].name === values[index].name) {
      return;
    }
    values[index].selected = !values[index].selected;
    setValues([...values]);
  };
  return (
    <div className="flex md:mt-0 mt-4">
      <ul className="flex w-full flex-wrap justify-center">
        {values.map((v, index) => (
          <li key={index} className="flex mr-2 md:m-3">
            <StyledButton
              key={index}
              selected={v.selected}
              onClick={() => updateSelected(index)}
              color={darkMode ? v.darkColor : v.lightColor}
            >
              <div className="flex justify-evenly">
                <div>
                  <StyledFontAwesomeIcon
                    icon={faCircle}
                    color={darkMode ? v.darkColor : v.lightColor}
                  />
                </div>
                <DomainTerm
                  infoKey={v.name
                    .toLowerCase()
                    .replaceAll(' ', '_')
                    .replaceAll('/', '_')}
                >
                  {v.name}
                </DomainTerm>
              </div>
            </StyledButton>
          </li>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(MultiLineGraphSelectorButton);
