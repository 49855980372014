import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import ValueLogo from '../../../../public/images/Value.png';
import MarginsLogo from '../../../../public/images/Profitability.png';
import { gtag } from '../../../../utils/analytics';
import { showErrorAlert } from '../../../../actions/alertAction';
import { useMediaQuery } from '@chakra-ui/react';
import { formatDecimalValue } from '../../../../utils/number';
import { useAuth } from '../../../hocs/AuthProvider';
import {
  setLoginPhase,
  showPageLoading,
  setOpenJoinVerdePlanModal,
} from '../../../../actions/coreAction';
import _ from 'lodash';
import WrappedAxios from '../../../../utils/WrappedAxios';
import { USER_SOCIAL_LINK } from '../../../../config/default';

const ViewSummary = ({
  darkMode,
  summaryData,
  loading,
  data: { symbol },
  error,
  showErrorAlert,
  setLoginPhase,
  subscriptionStatus,
  showPageLoading,
  setOpenJoinVerdePlanModal,
  publicSymbols,
}) => {
  const descRef = React.useRef();
  const [descWidth, setDescWidth] = React.useState(0);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [
    openSimilarCompaniesModal,
    setOpenSimilarCompaniesModal,
  ] = React.useState(false);
  const history = useHistory();
  const { authUser } = useAuth();

  React.useEffect(() => {
    descRef.current &&
      !loading &&
      setDescWidth(Math.round(descRef.current.offsetWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    error && showErrorAlert(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const gotoSummary = () => {
    history.push(`/${symbol.symbolMarket}/summary`);
    gtag('event', `company-page-view-summary-btn-click`, {
      event_label: symbol.id,
    });
  };

  return (
    <>
      <Flex
        style={{
          justifyContent: isMobile ? 'space-between' : 'space-around',
          position: 'relative',
          margin: isMobile ? '3px' : '10px',
        }}
      >
        <Box
          style={{
            borderRadius: '10px',
            border: '1px solid #DEDCDC',
            width: isMobile ? '55%' : '45%',
            padding: isMobile ? '5px' : '10px',
            marginRight: isMobile ? '10px' : '0px',
          }}
        >
          <Flex>
            <img src={ValueLogo} style={{ width: '25px', height: '25px' }} />
            <h2
              style={{
                fontWeight: 'bold',
                marginLeft: '5px',
                color: darkMode ? 'white' : 'black',
              }}
            >
              Value
            </h2>
          </Flex>

          <Flex style={{ justifyContent: 'space-between', marginTop: '10px' }}>
            <Box>
              <h5
                style={{
                  fontSize: isMobile ? '8px' : '15px',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                Market Capital
              </h5>
              <h5
                style={{
                  fontSize: isMobile ? '9px' : '16px',
                  fontWeight: 'bold',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                {symbol.marketCap}
              </h5>
            </Box>
            <Box>
              <h5
                style={{
                  fontSize: isMobile ? '8px' : '15px',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                PE (FWD)
              </h5>
              <h5
                style={{
                  fontSize: isMobile ? '9px' : '16px',
                  fontWeight: 'bold',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                {symbol.forwardPriceToEarnings}x
              </h5>
            </Box>
            <Box>
              <h5
                style={{
                  fontSize: isMobile ? '8px' : '15px',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                PE (TTM)
              </h5>
              <h5
                style={{
                  fontSize: isMobile ? '9px' : '16px',
                  fontWeight: 'bold',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                {symbol.trailingPriceToEarnings}x
              </h5>
            </Box>
          </Flex>
        </Box>

        <Box
          style={{
            borderRadius: '10px',
            border: '1px solid #DEDCDC',
            width: isMobile ? '55%' : '45%',
            padding: isMobile ? '10px' : '10px',
          }}
        >
          <Flex>
            <img src={MarginsLogo} style={{ width: '25px', height: '25px' }} />
            <h2
              style={{
                fontWeight: 'bold',
                marginLeft: '5px',
                color: darkMode ? 'white' : 'black',
              }}
            >
              Margins
            </h2>
          </Flex>

          <Flex style={{ marginTop: '10px' }}>
            <Box>
              <h5
                style={{
                  fontSize: isMobile ? '8px' : '15px',
                  color: darkMode ? 'white' : 'black',
                  marginLeft: isMobile ? '5px' : '0px',
                }}
              >
                Profit Margin
              </h5>
              <h5
                style={{
                  fontSize: isMobile ? '9px' : '16px',
                  fontWeight: 'bold',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                {formatDecimalValue(summaryData.gross_profit_margin)}%
              </h5>
            </Box>
            <Box style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <h5
                style={{
                  fontSize: isMobile ? '8px' : '15px',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                Operating Margin
              </h5>
              <h5
                style={{
                  fontSize: isMobile ? '9px' : '16px',
                  fontWeight: 'bold',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                {formatDecimalValue(summaryData.operating_margin)}%
              </h5>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <div style={{ position: 'relative', bottom: '25px' }}>
        <div
          style={{
            backgroundColor: !darkMode ? 'white' : '#262626',
            filter: 'blur(8px)',
            position: 'absolute',
            height: '30px',
            width: '100%',
          }}
        />
      </div>

      {authUser ? (
        <button
          onClick={() => {
            subscriptionStatus.active ||
            publicSymbols.includes(symbol.symbolMarket)
              ? gotoSummary()
              : setOpenJoinVerdePlanModal(true);
          }}
          style={{
            backgroundColor: darkMode ? '#4adf79' : '#04874E',
            color: darkMode ? 'black' : 'white',
            fontSize: '15px',
            fontWeight: 'bold',
            alignSelf: 'center',
            padding: '10px',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginTop: '20px',
            marginBottom: '20px',
            borderRadius: '20px',
          }}
        >
          {subscriptionStatus.active ||
          publicSymbols.includes(symbol.symbolMarket)
            ? 'View Summary'
            : 'Join Verde+'}
        </button>
      ) : (
        <button
          onClick={() => {
            setLoginPhase('signup');
          }}
          style={{
            backgroundColor: darkMode ? '#4adf79' : '#04874E',
            color: darkMode ? 'black' : 'white',
            fontSize: '15px',
            fontWeight: 'bold',
            alignSelf: 'center',
            padding: '10px',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginTop: '20px',
            marginBottom: '20px',
            borderRadius: '20px',
          }}
        >
          Sign up to view insights
        </button>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.valuationState.data,
  error: state.valuationState.error,
  loading: state.valuationState.loading,
  subscriptionStatus: state.coreState.subscriptionStatus,
  publicSymbols: state.symbolState.publicSymbols?.data,
});

const mapDispatchToProps = {
  showErrorAlert: showErrorAlert,
  setLoginPhase: setLoginPhase,
  showPageLoading: showPageLoading,
  setOpenJoinVerdePlanModal: setOpenJoinVerdePlanModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSummary);
