const initialState = {
  symbol: null,
  data: null,
  loading: false,
  error: null,
};

const FutureGrowthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_FUTURE_GROWTH_DATA_LOADING': {
      return {
        ...state,
        loading: true,
        symbol: null,
        data: null,
        error: null,
      };
    }

    case 'FETCH_FUTURE_GROWTH_DATA_SUCCESS': {
      return {
        ...state,
        loading: false,
        symbol: action.symbol,
        data: action.data,
      };
    }

    case 'FETCH_FUTURE_GROWTH_DATA_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default FutureGrowthReducer;
