import React from 'react';
import TradingViewWidget from 'react-tradingview-widget';
import {
  TradingViewEmbed,
  widgetType,
  SymbolOverview,
} from 'react-tradingview-embed';
import styled from 'styled-components/macro';
import { Flex } from '@chakra-ui/react';
import tw from 'twin.macro';
import SectionHeader from '../../../SectionHeader';

function Performance({ darkMode, symbol, valuationData }) {
  const Metric = styled.div`
    ${tw`flex flex-col items-center font-medium`};
  `;

  const List = [
    {
      title: '52 Week High',
      value: valuationData.symbol.fiftyTwoWeekHigh,
    },
    {
      title: '52 Week Low',
      value: valuationData.symbol.fiftyTwoWeekLow,
    },
    {
      title: 'EPS',
      value: valuationData.symbol.earningsPerShare,
    },
    {
      title: 'PE Ratio',
      value: valuationData.symbol.trailingPriceToEarnings,
    },
    {
      title: 'Dividend Yield',
      value: valuationData.symbol.forwardYield,
    },
  ];

  return (
    <>
      <SectionHeader value="Performance Chart" />

      {symbol && (
        <SymbolOverview
          widgetProps={{
            symbols: [`${symbol.id}`],
            valuesTracking: '1',
            // isTransparent:'1',
            backgroundColor: darkMode
              ? 'rgba(38, 38, 38, 0.999)'
              : 'rgba(255, 255, 255, 0)',
            colorTheme: darkMode ? 'dark' : 'light',
            gridLineColor: 'rgba(152, 152, 152, 0.81)',
            lineColor: darkMode ? '#7bbfff' : '#438df7',
            lineWidth: '2',
            topColor: darkMode ? '#7bbfff60' : '#438df760',
            bottomColor: darkMode ? '#7bbfff20' : '#438df720',
          }}
        />
      )}

      <Flex justifyContent="flex-center" marginTop="30px" flexWrap="wrap">
        {List.map((elem) => {
          return (
            <Metric>
              <div
                style={{
                  backgroundColor: darkMode ? '#343434' : '#F9F9F9',
                  padding: '10px',
                  width: '120px',
                  textAlign: 'center',
                  margin: 10,
                  marginLeft: 0,
                }}
              >
                <h6
                  style={{
                    fontWeight: 'bold',
                    color: darkMode ? 'white' : 'black',
                  }}
                >
                  {elem.value}
                </h6>
                <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>
                  {elem.title}
                </h6>
              </div>
            </Metric>
          );
        })}
      </Flex>
    </>
  );
}

export default Performance;
