import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const Container = styled.div`
  ${tw`h-4 w-4 relative inline-block mt-0.5 rounded`};
  border: 1px solid
    ${(props) =>
      props.checked ? props.theme.keyHighlight : props.theme.unchecked.border};
`;

export const CheckIcon = styled.div`
  ${tw`h-full w-full absolute text-xs bottom-0.5`};
  color: ${(props) => props.theme.keyHighlight};
  padding: 1px;
`;
export const CheckboxLabel = styled.div`
  ${tw`text-sm ml-4 font-medium`}
  color: ${(props) =>
    props.checked ? props.theme.keyHighlight : props.theme.unchecked.color};
`;
const Checkbox = ({ id, checked, className, style, onClick, label }) => {
  const [check, setCheck] = React.useState(checked);
  React.useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <>
      <Container
        id={id}
        onClick={onClick}
        className={className}
        style={style}
        checked={checked}
      >
        <CheckIcon hidden={!check}>
          <FontAwesomeIcon className="fa-w-14" icon={faCheck} />
        </CheckIcon>
      </Container>
      <CheckboxLabel checked={checked}>{label}</CheckboxLabel>
    </>
  );
};

export default Checkbox;
