import React, { useEffect, useState } from 'react';
import { Spinner, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Flex, Box } from '@chakra-ui/layout';
import VerdeScoreSvg from '../../../../../public/images/verde-icon.svg';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import DomainTerm from '../../../DomainTerm';
import RatingCode from '../../../RatingCode';
import { useMediaQuery } from '@chakra-ui/react';
import Table from './Table';
import { filterList } from './Table/FilterList';
import HeatMapChart from '../Chart/HeatMapChart';

export default function Overview({
  data,
  darkMode,
  insightKey,
  overviewData,
  analysisData,
  setTabSelected,
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const [filterSelected, setFilterSelected] = useState({
    id: 'Holdings',
    tableHeader: [
      { id: 'Ticker', key: 'symbol', upDownIcon: false },
      {
        id: 'Shares',
        key: 'quantity',
        prefix: '',
        suffix: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
      {
        id: 'Average Price',
        key1: 'cost_basis',
        key2: 'quantity',
        prefix: '$',
        suffix: '',
        upDownIcon: false,
      },

      {
        id: 'Current Price',
        key: 'current_price',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      // {
      //   id: 'Book Value',
      //   key: '',
      //   upDownIcon: true,
      //   ascend: false,
      //   descend: false,
      // },
      {
        id: 'Market Value',
        key: 'market_value',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '% of Portfolio',
        key: 'portfolio_percent',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'P&L',
        key: 'profit_loss',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '%P&L',
        key: 'profit_loss_percent',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Quarter Low',
        key: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
      {
        id: 'Quarter High',
        key: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
    ],
  });

  const topList = [
    {
      id: 'Present Value',
      value: data.account_details && data.account_details.portfolio_value,
    },
    {
      id: 'divider',
    },
    {
      id: 'Invested',
      value:
        data.account_details && data.account_details.portfolio_invested_amount,
    },
    {
      id: 'divider',
    },
    {
      id: 'mobileDivider',
    },
    {
      id: 'P&L',
      value: data.account_details && data.account_details.portfolio_profit_loss,
      percentVal:
        (data.account_details &&
          data.account_details.portfolio_profit_loss /
            (data.account_details &&
              data.account_details.portfolio_invested_amount)) * 100,
    },
    {
      id: 'divider',
    },
    {
      id: 'Verde Score',
      value: data.account_details && data.account_details.portfolio_verde_score,
    },
  ];

  const nextList = [
    {
      id: 'Downside Protection Score',
      value:
        analysisData?.downside_protection &&
        analysisData?.downside_protection?.downside_protection_score,
    },
    {
      id: 'divider',
    },
    {
      id: 'Risk Adjusted Returns Score',
      value:
        analysisData?.risk_adjusted_returns &&
        analysisData?.risk_adjusted_returns?.risk_adjusted_returns_score,
    },
    {
      id: 'divider',
    },
    {
      id: 'mobileDivider',
    },
    {
      id: 'Overall Expected Return',
      value: overviewData?.total_expected_returns,
    },
    {
      id: 'divider',
    },
    {
      id: 'Fees',
      value: overviewData?.Fees,
    },
  ];

  const ratingList = [
    {
      rating: 'Growth Rating',
      title: 'Growth',
      value:
        data.account_details && data.account_details.portfolio_growth_rating,
    },
    {
      rating: 'Safety Rating',
      title: 'Safety',
      value:
        data.account_details && data.account_details.portfolio_safety_rating,
    },
    {
      rating: 'Value Rating',
      title: 'Value',
      value:
        data.account_details && data.account_details.portfolio_value_rating,
    },
    {
      rating: 'Momentum Rating',
      title: 'Momentum',
      value:
        data.account_details && data.account_details.portfolio_momentum_rating,
    },
    {
      rating: 'Dividend Rating',
      title: 'Dividend',
      value:
        data.account_details && data.account_details.portfolio_dividend_rating,
    },
    {
      rating: 'Profitabilty Rating',
      title: 'Profitability',
      value:
        data.account_details &&
        data.account_details.portfolio_profitability_rating,
    },
  ];

  const RatingTitle = styled.div`
    ${tw`mt-3 font-medium text-sm`}
    color: ${(props) => props.theme.rating.title};
  `;

  const Rating = ({ value, title, darkMode }) => (
    <div
      className="px-2 py-4  flex flex-col items-center justify-center"
      style={{
        backgroundColor: darkMode ? '#434243' : '#F9F9F9',
        width: '140px',
        marginTop: '5px',
        borderRadius: '5px',
        alignSelf: 'center',
      }}
    >
      <RatingCode rating={value} />
      <RatingTitle>
        <DomainTerm infoKey={`${title.toLowerCase()}_rating`}>
          <span className="whitespace-nowrap">{title} Rating</span>
        </DomainTerm>
      </RatingTitle>
    </div>
  );

  return (
    <div
      style={{
        paddingLeft: isMobile ? 10 : '',
        paddingRight: isMobile ? 10 : '',
      }}
    >
      <div
        style={{
          position: 'relative',
          paddingTop: 20,
          paddingBottom: 25,
          borderRadius: 5,
          backgroundColor: darkMode
            ? '#262626'
            : !isMobile
            ? 'white'
            : 'rgb(246,248,250)',
          justifyContent: 'space-around',
          borderBottom: isMobile
            ? ''
            : `1px solid ${darkMode ? 'rgb(51,51,51)' : '#8c8c8c'}`,
          width: '100%',
          display: isMobile ? 'inline-grid' : 'flex',
          gridTemplateColumns: isMobile ? 'auto auto' : null,
        }}
      >
        {topList.map((elem, index) => {
          return elem.id !== 'divider' && elem.id !== 'mobileDivider' ? (
            <div
              style={{
                textAlign: 'left',
                paddingLeft: isMobile ? 10 : '',
                paddingRight: isMobile ? 10 : '',
                paddingTop: isMobile ? 10 : '',
                paddingBottom: isMobile ? 10 : '',
              }}
            >
              <Text
                style={{
                  color: darkMode ? '#cccccc' : '#8c8c8c',
                  marginTop: '10px',
                }}
              >
                {elem.id}
              </Text>
              {elem.id === 'Verde Score' ? (
                <Flex justifyContent="flex-start">
                  <img
                    src={VerdeScoreSvg}
                    alt=""
                    className="w-5 h-5 mb-0.5 mr-1 mt-1"
                  />
                  <Text
                    style={{
                      color: darkMode ? '#f6f6f6' : 'black',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    {elem.value}
                  </Text>
                </Flex>
              ) : elem.id === 'P&L' ? (
                <div
                  style={{
                    display: isMobile ? 'block' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      color:
                        // elem.id === 'P&L'
                        //   ? elem.value > 0
                        //     ? darkMode
                        //       ? '#4adf79'
                        //       : '#01874e'
                        //     : darkMode
                        //     ? '#ec5b55'
                        //     : '#BF2F2C'
                        darkMode ? 'white' : 'black',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      marginRight: '8px',
                    }}
                  >
                    {elem.value < 0
                      ? `-$${
                          elem.value &&
                          Math.abs(elem.value)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }`
                      : `+$${
                          elem.value &&
                          elem.value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }`}
                  </Text>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '20px',
                      // backgroundColor:
                      //   elem.value > 0
                      //     ? darkMode
                      //       ? '#4adf7920'
                      //       : '#01874e20'
                      //     : darkMode
                      //     ? '#ec5b5520'
                      //     : '#BF2F2C20',
                      paddingLeft: 7,
                    }}
                  >
                    {elem.value > 0 ? (
                      <TriangleUpIcon
                        color={darkMode ? '#4adf79' : '#01874e'}
                      ></TriangleUpIcon>
                    ) : (
                      <TriangleDownIcon
                        color={darkMode ? '#ec5b55' : '#BF2F2C'}
                      ></TriangleDownIcon>
                    )}

                    <Text
                      style={{
                        color:
                          elem.value > 0
                            ? darkMode
                              ? '#4adf79'
                              : '#01874e'
                            : darkMode
                            ? '#ec5b55'
                            : '#BF2F2C',
                        marginLeft: '3px',
                        fontWeight: '600',
                      }}
                    >
                      {elem.val < 0
                        ? `+${parseFloat(elem.percentVal).toFixed(2)}`
                        : `${parseFloat(elem.percentVal).toFixed(2)}`}
                      %
                    </Text>
                  </div>
                </div>
              ) : elem.id === 'Present Value' || elem.id === 'Invested' ? (
                <Text
                  style={{
                    color:
                      elem.id === 'P&L'
                        ? elem.value > 0
                          ? '#36A867'
                          : 'rgb(190,46,44)'
                        : darkMode
                        ? 'white'
                        : 'black',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {`$${
                    elem.value &&
                    elem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }`}
                </Text>
              ) : (
                <Text
                  style={{
                    color:
                      elem.id === 'P&L'
                        ? elem.value > 0
                          ? '#36A867'
                          : 'rgb(190,46,44)'
                        : darkMode
                        ? 'white'
                        : 'black',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {`${
                    elem.value &&
                    elem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }`}
                </Text>
              )}
            </div>
          ) : elem.id === 'mobileDivider' ? (
            isMobile && (
              <div
                style={{
                  position: 'absolute',
                  width: '80%',
                  left: '10%',
                  top: '48%',
                  height: 0,
                  border: `0.5px solid ${
                    darkMode ? 'rgb(51,51,51)' : '#8c8c8c'
                  }`,
                }}
              />
            )
          ) : (
            !isMobile && (
              <div
                style={{
                  height: '70px',
                  width: '0px',
                  marginTop: '5px',
                  border: `0.5px solid ${
                    darkMode ? 'rgb(51,51,51)' : '#8c8c8c'
                  }`,
                }}
              />
            )
          );
        })}
      </div>

      <div
        style={{
          position: 'relative',
          paddingTop: 20,
          paddingBottom: 25,
          borderRadius: 5,
          backgroundColor: darkMode
            ? '#262626'
            : !isMobile
            ? 'white'
            : 'rgb(246,248,250)',
          justifyContent: 'space-around',
          borderBottom: isMobile
            ? ''
            : `1px solid ${darkMode ? 'rgb(51,51,51)' : '#8c8c8c'}`,
          width: '100%',
          display: isMobile ? 'inline-grid' : 'flex',
          gridTemplateColumns: isMobile ? 'auto auto' : null,
        }}
      >
        {nextList.map((elem, index) => {
          return elem.id !== 'divider' && elem.id !== 'mobileDivider' ? (
            <div
              style={{
                textAlign: 'left',
                paddingLeft: isMobile ? 10 : '',
                paddingRight: isMobile ? 10 : '',
                paddingTop: isMobile ? 10 : '',
                paddingBottom: isMobile ? 10 : '',
              }}
            >
              <Text
                style={{
                  color: darkMode ? '#cccccc' : '#8c8c8c',
                  marginTop: '10px',
                }}
              >
                {elem.id}
              </Text>

              <Flex alignItems="left" direction={'column'}>
                <Text
                  style={{
                    color: darkMode ? '#f6f6f6' : 'black',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {elem.value ? elem.value : <Spinner size='xs' />}
                </Text>
                <button
                  onClick={() => {
                    elem.id === 'Overall Expected Return'
                      ? setTabSelected('Recommendations')
                      : elem.id === 'Fees'
                      ? setTabSelected('Fees')
                      : setTabSelected('Analysis');
                  }}
                  style={{
                    backgroundColor: darkMode ? '#4adf79' : '#04874E',
                    color: darkMode ? 'black' : 'white',
                    fontSize: '15px',
                    fontWeight: 'bold',
                    //alignSelf: 'center',
                    padding: '5px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '5px',
                    //marginBottom: '20px',
                    borderRadius: '20px',
                  }}
                >
                  {elem.id === 'Overall Expected Return'
                    ? 'View Details'
                    : elem.id === 'Fees'
                    ? 'View All Fees'
                    : 'Improve Score'}
                </button>
              </Flex>
            </div>
          ) : elem.id === 'mobileDivider' ? (
            isMobile && (
              <div
                style={{
                  position: 'absolute',
                  width: '80%',
                  left: '10%',
                  top: '48%',
                  height: 0,
                  border: `0.5px solid ${
                    darkMode ? 'rgb(51,51,51)' : '#8c8c8c'
                  }`,
                }}
              />
            )
          ) : (
            !isMobile && (
              <div
                style={{
                  height: '70px',
                  width: '0px',
                  marginTop: '5px',
                  border: `0.5px solid ${
                    darkMode ? 'rgb(51,51,51)' : '#8c8c8c'
                  }`,
                }}
              />
            )
          );
        })}
      </div>

      <div
        style={{
          display: isMobile ? 'block' : 'flex',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          justifyContent: 'flex-start',
          backgroundColor: darkMode
            ? isMobile
              ? 'transparent'
              : '#262626'
            : isMobile
            ? 'transparent'
            : 'white',
          marginTop: isMobile ? 20 : '',
          marginBottom: '25px',
        }}
      >
        <div
          style={{
            borderRadius: '5px',
            backgroundColor: darkMode
              ? isMobile
                ? 'transparent'
                : '#262626'
              : isMobile
              ? 'transparent'
              : 'white',
            padding: '20px',
            marginBottom: '25px',
          }}
        >
          <Text
            style={{
              color: darkMode ? '#f6f6f6' : 'black',
              fontSize: '20px',
              marginLeft: isMobile ? '' : '50px',
              fontWeight: 'bold',
              marginBottom: 4,
            }}
          >
            Valuation Summary
          </Text>

          <div
            style={{
              marginTop: isMobile ? '25px' : '',
              display: isMobile ? 'inline-grid' : 'block',
              gridTemplateColumns: isMobile ? 'auto auto auto' : null,
              justifyContent: isMobile ? 'flex-start' : 'center',
              width: '100%',
              overflowX: 'scroll',
            }}
          >
            {ratingList.map((elem) => {
              return (
                <div
                  style={{
                    marginBottom: '25px',
                    marginLeft: isMobile ? 0 : 25,
                    marginRight: isMobile ? 25 : 0,
                  }}
                >
                  <Rating
                    value={elem.value}
                    title={elem.title}
                    darkMode={darkMode}
                  />
                </div>
              );
            })}
          </div>
        </div>

        {!isMobile && (
          <div
            style={{
              height: 'auto',
              width: '0px',
              marginBottom: 60,
              marginTop: 30,

              border: isMobile
                ? ''
                : `0.5px solid ${darkMode ? 'rgb(51,51,51)' : '#8c8c8c'}`,
              borderWidth: 0.5,
            }}
          />
        )}

        {!isMobile && (
          <div
            style={{
              borderRadius: '5px',
              backgroundColor: darkMode ? '#262626' : 'white',
              padding: '50px',
              marginLeft: isMobile ? '0px' : '10px',
              width: '100%',
              marginBottom: '25px',
            }}
          >
            <Text
              color={darkMode ? '#f6f6f6' : 'black'}
              fontSize="20px"
              fontWeight="bold"
              mb={2}
              ml={2}
            >
              Portfolio Performance
            </Text>
            <Text color={darkMode ? '#f6f6f6' : 'black'} fontSize="16px" ml={2}>
              Stock Returns over the last 30 days
            </Text>
            <Box mt={6}>
              <HeatMapChart data={overviewData} />
            </Box>
            {/* <PortfolioPerformance darkMode={darkMode} data={chartData} /> */}
          </div>
        )}
      </div>
      <div
        style={{
          borderRadius: '5px',
          backgroundColor: darkMode ? '#262626' : 'white',
          padding: '30px',
          width: '100%',
          marginBottom: '25px',
        }}
      >
        <div style={{ display: 'flex', overflowX: 'scroll', marginBottom: 25 }}>
          {filterList.map((elem) => {
            return (
              <div
                style={{
                  borderRight: '1px solid rgba(140, 140, 140, 0.5)',
                  padding: '5px',
                  paddingLeft: isMobile ? 7 : '10px',
                  paddingRight: isMobile ? 15 : '25px',
                  marginLeft: isMobile ? 5 : '10px',
                  marginRight: isMobile ? 5 : '10px',
                  cursor: 'pointer',
                }}
                onClick={() => setFilterSelected(elem)}
              >
                <Text
                  style={{
                    fontWeight:
                      filterSelected.id === elem.id ? 'bold' : 'normal',

                    color:
                      filterSelected.id === elem.id
                        ? darkMode
                          ? '#4adf79'
                          : '#01874e'
                        : darkMode
                        ? '#f8f8f8'
                        : '#343434',
                    fontSize:
                      filterSelected.id === elem.id
                        ? isMobile
                          ? 17
                          : 20
                        : isMobile
                        ? 15
                        : 18,
                  }}
                >
                  {elem.id}
                </Text>
              </div>
            );
          })}
        </div>

        <Table
          insightKey={insightKey}
          darkMode={darkMode}
          data={data.account_holdings && data.account_holdings}
          headerData={filterSelected.tableHeader}
        />
      </div>
    </div>
  );
}
