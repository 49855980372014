import React, { useState } from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import VerdeLogo from '../../../public/images/VerdeLogo.png';
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import clevertap from 'clevertap-web-sdk';

export const MobileAppModal = ({ closeModal, darkMode }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [error, setError] = useState(false);
  let inputString = '';
  const styles = {
    mainModel: {
      position: 'fixed',
      zIndex: 10,
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',

      overflow: 'auto',
      backgroundColor: 'rgb(0,0,0)',
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    modalContent: {
      //    margin: isMobile ? '30% auto' : '15% auto',
      padding: '20',
      borderRadius: '8px',
      border: '1px solid #888',
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      width: '30%',
      height: '35%',
      alignItems: 'center',
    },
  };
  return (
    <div style={styles.mainModel}>
      <div
        style={{
          ...styles.modalContent,
          backgroundColor: darkMode ? '#161616' : '#f4f4f4',
          padding: 20,
          position: 'fixed',
          bottom: 0,
          width: isMobile ? '100%' : 400,
          height: isMobile ? 'auto' : 200,
        }}
      >
        <Box position={'relative'} mb={3}>
          <Box
            position="absolute"
            top={-3}
            right={-2}
            onClick={() => closeModal()}
          >
            <FontAwesomeIcon icon={faTimes} color="gray" />
          </Box>
        </Box>
        <Flex>
          <Box justifyContent="center" alignContent="center" borderRadius={5}>
            <img
              style={{
                backgroundColor: '#161616',
                padding: 10,
                paddingBottom: 13,
                paddingTop: 13,
                borderRadius: 10,
              }}
              src={VerdeLogo}
            ></img>
          </Box>

          <Box marginLeft={5}>
            <Text style={{ color: 'black', fontSize: 18 }} fontWeight="bold">
              Verde Finance{' '}
              <span style={{ fontSize: 20, position: 'relative', top: 2 }}>
                |
              </span>{' '}
              Social Investing
            </Text>
            <Rating
              name="half-rating-read"
              value={5}
              precision={0.1}
              size="large"
              readOnly
            />
          </Box>
        </Flex>

        <Box
          marginLeft="auto"
          marginRight="auto"
          alignItems="center"
          justifyContent="center"
          width="120px"
          mt={3}
        >
          <a href="https://verde.onelink.me/k6ac/367a3468" target="_blank">
            <button
              style={{
                padding: 7,
                width: '120px',
                backgroundColor: darkMode ? '#4adf79' : '#01874e',
                color: darkMode ? 'black' : 'white',
                borderRadius: 10,
                marginTop: 20,
                marginRight: 5,
                margin: 'auto',
                alignContent: 'center',
                justifySelf: 'center',
                fontSize: 16,
                fontWeight: 'bold',
              }}
              onClick={() =>
                clevertap.event.push('MB Modal Get The App Button Clicked')
              }
            >
              Get the App
            </button>
          </a>
        </Box>
      </div>
    </div>
  );
};
