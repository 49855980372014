import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import { getStackedAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';
import RiskPositives from '../../../RiskPositives';
import { useMediaQuery } from '@chakra-ui/react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';

const SharesOutstanding = ({ data, insights, period, setPeriod, darkMode }) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const fullChartData = getStackedAreaChartData(
    data,
    ['evaluated_on', 'shares_data'],
    period
  );

  const chartData = [
    {
      name: 'Shares Outstanding',
      data: fullChartData.map((item) => [item[0], item[1]]),
    },
  ];

  return (
    <>
      <Flex style={{ marginBottom: '25px' }}>
        <SectionHeader value="Shares Outstanding" />
        {!isMobile && (
          <Box
            style={{
              marginLeft: 'auto',
              backgroundColor: darkMode ? '#161616' : '#f8f8f8',
              borderRadius: '15px',
              height: 'auto',
            }}
          >
            <ToggleButton period={period} setPeriod={setPeriod} />
          </Box>
        )}
      </Flex>

      {isMobile && (
        <Box
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: darkMode ? '#161616' : '#f8f8f8',
            borderRadius: '15px',
            marginBottom: '5px',
            height: 'auto',
          }}
        >
          <ToggleButton period={period} setPeriod={setPeriod} />
        </Box>
      )}

      <div style={{ marginTop: '10px' }}>
        <StackedLineChart data={chartData} />
      </div>

      <RiskPositives
        positives={insights?.shares_outstanding?.positives}
        negatives={insights?.shares_outstanding?.negatives}
      />
    </>
  );
};

export default SharesOutstanding;
