const initialState = {
  symbol: null,
  data: {
    valuation: {},
    past_performance: {},
    future_growth: {},
    dividend: {},
  },
  loading: false,
  error: null,
};

const InsightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SYMBOL_INSIGHTS_BASIC_LOADING':
    case 'FETCH_SYMBOL_INSIGHTS_LOADING': {
      return {
        ...state,
        loading: true,
        symbol: null,
        data: initialState.data,
        error: null,
      };
    }
    
    case 'FETCH_SYMBOL_INSIGHTS_SUCCESS': {
      return {
        ...state,
        loading: false,
        symbol: action.data.symbol_market,
        data: action.data.insights ? action.data.insights : initialState.data,
      };
    }

    case 'FETCH_SYMBOL_INSIGHTS_BASIC_SUCCESS': {
      return {
        ...state,
        loading: false,
        symbol: action.data.symbol_market,
        data: action.data ? {...initialState.data, valuation: action.data} : initialState.data,
      };
    }

    case 'FETCH_SYMBOL_INSIGHTS_BASIC_ERROR':
    case 'FETCH_SYMBOL_INSIGHTS_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default InsightsReducer;
