import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { device } from '../../StyedComponents';

export const Board = styled.div`
  ${tw`-m-4 lg:-m-6 relative`};
`;

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#59bb88',
    color: '#fff',
    borderColor: '#fff',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#fff',
      boxShadow: '0 0 0 1px #fff',
      outline: 'none',
    },
  }),
  singleValue: (styles) => ({ ...styles, color: '#fff' }),
  placeholder: (styles) => ({ ...styles, color: '#fff' }),
  dropdownIndicator: (styles) => ({ ...styles, color: '#fff' }),
  indicatorSeparator: (styles) => ({ ...styles, backgroundColor: '#fff' }),
};

export const CrownedPersonImg = styled.img`
  ${tw`rounded-full`};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  @media ${device.mobileS} {
    width: ${(props) => props.size - 40}px;
    height: ${(props) => props.size - 40}px;
  }
  @media ${device.laptopL} {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
`;

export const CrownedPersonImgContainer = styled.div`
  ${tw`rounded-full bg-white flex items-center justify-center mx-auto`};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  @media ${device.mobileS} {
    width: ${(props) => props.size - 40}px;
    height: ${(props) => props.size - 40}px;
  }
  @media ${device.laptopL} {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
`;
