import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import StockInfo from './StockInfo';
import SectionHeader from '../../../../../SectionHeader';
import moment from 'moment';
const Metric = styled.div`
  ${tw`flex flex-col items-center font-medium`};
`;

const StockInformation = ({ data, darkMode }) => {
  const chartData = data
    .map((raw) =>
      Object.keys(raw)
        .filter((key) => ['date', 'account_value'].includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {})
    )
    .map((item) => Object.values(item))
    .map((item) => [new Date(item[0]).getTime(), parseFloat(item[1])])
    .sort((a, b) => a[0] - b[0]);

  return (
    <>
      <div style={{ marginTop: '10px', paddingTop: '8px' }}>
        {data.length !== 0 && (
          <StockInfo
            darkMode={darkMode}
            data={chartData}
            // growthMetrics={
            //   valuationData.growthMetrics.filter(
            //     (metric) => metric.market === 'Company'
            //   )[0]
            // }
          />
        )}
      </div>
    </>
  );
};

export default StockInformation;
