import React from 'react';
import RatioGraph from '../../../RatioGraphRevamp';
import RatioGraphInsights from '../../../RatioGraphInsights';
import SectionHeader from '../../../SectionHeader';
import RiskPositives from '../../../RiskPositives';

const PriceToEarningsGrowth = ({
  data,
  symbol,
  insights,
  darkMode,
  etfData,
  spyData,
}) => {
  const ratios =
    data !== undefined
      ? [
          { name: 'Symbol', value: data.symbol },
          { name: 'Sector', value: data.sector },
          { name: 'Market', value: data.market },
        ]
      : [
          {
            name: 'Symbol',
            value: parseFloat(etfData.Valuation['PEG(TTM)']).toFixed(2),
          },
          {
            name: 'Category',
            value: parseFloat(etfData.Valuation['PEG Category(TTM)']).toFixed(
              2
            ),
          },
          {
            name: 'Market',
            value: parseFloat(spyData.Valuation['PEG(TTM)']).toFixed(2),
          },
        ];

  const pegIndustryPositives = insights?.peg_ratio?.Industry?.positives
    ? insights?.peg_ratio?.Industry?.positives
    : [];
  const pegMarketPositives = insights?.peg_ratio?.Market?.positives
    ? insights?.peg_ratio?.Market?.positives
    : [];

  const pegIndustryNegatives = insights?.peg_ratio?.Industry?.negatives
    ? insights?.peg_ratio?.Industry?.negatives
    : [];
  const pegMarketNegatives = insights?.peg_ratio?.Market?.negatives
    ? insights?.peg_ratio?.Market?.negatives
    : [];

  return (
    <>
      <SectionHeader value="Price to Earning Growth (PEG)" infoKey="peg" />
      {symbol !== undefined ? (
        <RatioGraph ratios={ratios} symbol={symbol.id} suffix="x" />
      ) : etfData.ETF_Data !== {} ? (
        <RatioGraph
          ratios={ratios}
          symbol={etfData.ETF_Data.ETF_Code}
          suffix="x"
        />
      ) : (
        <div></div>
      )}

      {insights &&
        (pegIndustryPositives.length === 0 &&
        pegIndustryNegatives.length === 0 ? (
          <div></div>
        ) : (
          <div>
            <h3
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: darkMode ? 'white' : 'black',
              }}
            >
              PEG vs Sector
            </h3>

            <RiskPositives
              positives={pegIndustryPositives}
              negatives={pegIndustryNegatives}
            />
          </div>
        ))}

      {pegMarketPositives.length === 0 && pegMarketNegatives.length === 0 ? (
        <div></div>
      ) : (
        <>
          <h3
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: darkMode ? 'white' : 'black',
            }}
          >
            PEG vs Market
          </h3>
          <RiskPositives
            positives={pegMarketPositives}
            negatives={pegMarketNegatives}
          />
        </>
      )}
    </>
  );
};

export default PriceToEarningsGrowth;
