import React from 'react';
import { connect } from 'react-redux';
import StockTable from '../../StockTable';
import { defaultColumns, additionalColumns } from '../../StockTable/columns';
import {
  getScreenerData,
  updateFilters,
  updatePagination,
} from '../../../../actions/screenerAction';
import { showPageLoading } from '../../../../actions/coreAction';
import { showErrorAlert } from '../../../../actions/alertAction';
import { buildFilterPayload } from '../../../../utils/ScreenerUtils';

function ScreenerTable(props) {
  const {
    data,
    columns,
    loading,
    pageNo,
    pageSize,
    totalPages,
    updatePagination,
    getScreenerData,
    appliedFilters,
    updateFilters,
  } = props;

  React.useEffect(() => {
    getScreenerData(
      pageSize * pageNo,
      pageSize,
      buildFilterPayload(appliedFilters)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNo, appliedFilters]);

  const handleSort = (sortFields) => {
    sortFields &&
      sortFields.length > 0 &&
      updateFilters({ ...appliedFilters, sortFields });
  };

  const handlePagination = (pageNo, pageSize) => {
    updatePagination(pageNo, pageSize);
  };

  const tableColumns = [
    ...defaultColumns,
    ...additionalColumns,
  ].filter((column) => columns.includes(column['Header']));

  return (
    <StockTable
      columns={React.useMemo(() => [...tableColumns], [tableColumns])}
      data={React.useMemo(() => data, [data])}
      onSort={handleSort}
      onPagination={handlePagination}
      defaultPageSize={pageSize}
      loading={loading}
      pageCount={totalPages}
    />
  );
}

const mapStateToProps = (state) => ({
  data: state.screenerState.data,
  totalPages: state.screenerState.totalPages,
  pageNo: state.screenerState.pageNo,
  pageSize: state.screenerState.pageSize,
  appliedFilters: state.screenerState.appliedFilters,
});

const mapDispatchToProps = {
  getScreenerData,
  showPageLoading,
  updateFilters,
  showErrorAlert,
  updatePagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerTable);
