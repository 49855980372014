const countryList = require('country-list');

const marketCap = (prop = 'marketcap') => [
  {
    title: 'Mega Cap (> 100B)',
    query: {
      [`min_${prop}`]: 100000,
    },
  },
  {
    title: 'Large Cap (10B - 100B)',
    query: {
      [`max_${prop}`]: 100000,
      [`min_${prop}`]: 10000,
    },
  },
  {
    title: 'Mid Cap (2B - 10B)',
    query: {
      [`max_${prop}`]: 10000,
      [`min_${prop}`]: 2000,
    },
  },
  {
    title: 'Small Cap (300M - 2B)',
    query: {
      [`max_${prop}`]: 2000,
      [`min_${prop}`]: 300,
    },
  },
  {
    title: 'Micro Cap (< 300m)',
    query: {
      [`max_${prop}`]: 300,
    },
  },
];

const valuation = (prop = 'price') => [
  {
    title: 'Profitable (> 0x)',
    query: {
      [`min_${prop}`]: 0,
    },
  },
  {
    title: '(< 10x)',
    query: {
      [`max_${prop}`]: 10,
    },
  },
  {
    title: '(< 15x)',
    query: {
      [`max_${prop}`]: 15,
    },
  },
  {
    title: '(< 20x)',
    query: {
      [`max_${prop}`]: 20,
    },
  },
  {
    title: '(< 25x)',
    query: {
      [`max_${prop}`]: 25,
    },
  },
  {
    title: '(< 30x)',
    query: {
      [`max_${prop}`]: 30,
    },
  },
];
const valuation_point = (prop = 'price') => [
  {
    title: '(< 0.5x)',
    query: {
      [`max_${prop}`]: 0.5,
    },
  },
  {
    title: '(< 1.0x)',
    query: {
      [`max_${prop}`]: 1,
    },
  },
  {
    title: '(< 1.5x)',
    query: {
      [`max_${prop}`]: 1.5,
    },
  },
  {
    title: '(< 2.0x)',
    query: {
      [`max_${prop}`]: 2,
    },
  },
  {
    title: '(< 2.5x)',
    query: {
      [`max_${prop}`]: 2.5,
    },
  },
  {
    title: '(< 3.0x)',
    query: {
      [`max_${prop}`]: 3,
    },
  },
];

const financials = (options:Array<number>, prop:string) =>
  options.map((option, index) => {
    const title = `${index ? '' : 'Positive '}(>${option}%)`;
    return {
      title,
      query: {
        [`min_${prop}`]: 0,
      },
    };
  });
const performance = (options:Array<number>, prop:string) => {
  const result:Array<{title:string,query?:any}> = [];
  const lessResult:Array<{title:string,query?:any}> = [];
  options.forEach((option, index) => {
    const title = index ? `> ${option}%` : `Positive (>${option}%)`;
    result.push({
      title,
      query: {
        [`min_${prop}`]: option,
      },
    });
    if (!index) {
      result.push({
        title: `Negative (<${option}%)`,
        query: {
          [`max_${prop}`]: option,
        },
      });
    } else {
      lessResult.push({
        title: `< ${option}%`,
        query: {
          [`max_${prop}`]: option,
        },
      });
    }
  });
  return result.concat(lessResult);
};
type Rating = 'A' | 'B' | 'C' | 'D' | 'E' | 'F';
const rating = (options:Array<Rating> = ['A','B','C','D','E','F'],prop:string) => {
  return options.map(item=>({
    title:item,
    query:{
      [prop]:`('${item}')`
    }
  }))
}
export const staticFilters = {
  marketCap: marketCap(),
  enterprise: marketCap('enterprise_value'),
  peTTM: valuation('peratio'),
  peFWD: valuation('peratio_fwd'),
  evEBITDA: valuation('evEBITDA'),
  evEBIT: valuation('evEBIT'),
  evRevenue:valuation('ev_revenue'),
  pegFWD: valuation_point('peg_ratio'),
  psTTM: valuation_point('price_to_sale'),
  pbTTM: valuation_point('price_to_book'),
  evTTM: valuation_point('evTTM'),
  rg5: performance([0, 10, 15, 20, 25, 30], 'revenue_growth'),
  eps5: financials([0, 10, 15, 20, 25, 30], 'eps_growth'),
  gross: financials([0, 10, 20, 30, 40, 50], 'gross_profit_margin'),
  operating: financials([0, 10, 15, 20, 25, 30], 'operating_margin'),
  net: financials([0, 10, 15, 20, 25, 30], 'net_income_margin'),
  roa: financials([0, 10, 15, 20, 25, 30], 'return_on_assets'),
  roe: financials([0, 10, 15, 20, 25, 30], 'return_on_equity'),
  roic: financials([0, 10, 15, 20, 25, 30], 'return_on_invested_capital'),
  oneD: performance([0,1,1.5,2,2.5,3], 'oneday_return'),
  oneM: performance([0, 10, 15, 20, 25, 30], 'onemonth_return'),
  threeM: performance([0, 10, 15, 20, 25, 30], 'threemonths_return'),
  sixM: performance([0, 10, 15, 20, 25, 30], 'sixmonths_return'),
  qtdR: performance([0, 10, 15, 20, 25, 30], 'qtdR'),
  ytdR: performance([0, 10, 15, 20, 25, 30], 'ytd_return'),
  oneY: performance([0, 10, 20, 30, 40, 50], 'oneyear_return'),
  threeY: performance([0, 10, 20, 30, 40, 50], 'threeyears_return'),
  fiveY: performance([0, 10, 20, 30, 40, 50], 'fiveyears_return'),
  valueRating:rating(undefined,'value_ratings'),
  growthRating:rating(undefined,'growth_ratings'),
  momentumRating:rating(undefined,'momentum_ratings'),
  dividendRating:rating(undefined,'dividend_ratings'),
  safetyRating:rating(undefined,'safety_ratings'),
  profitabilityRating:rating(undefined,'profitability_ratings')
};


type AllFilters = {
  sectors: Array<object>;
  industries: Array<object>;
  marketCap: Array<object>;
};

export const getFilters = (
  allFilters: AllFilters = { sectors: [], industries: [], marketCap: [] },
  type: string
) => {
  const filters: {
    overview: Array<object>;
    ratings: Array<object>;
    valuation: Array<object>;
    financials: Array<object>; //growth
    profitability: Array<object>;
    performance: Array<object>;
  } = {
    overview: [
      {
        title: 'Sector',
        options: allFilters['sectors'] ?? [],
        property: 'sectors',
        testId:'sectors',
        column: 'sector',
        default: true,
        isMulti:true
      },
      {
        title: 'Industry',
        options: allFilters['industries'] ?? [],
        testId:'industry',
        property: 'industries',
        column: 'industry',
        default: true,
        isMulti:true
      },
      {
        title: 'Market Cap',
        options: (staticFilters['marketCap'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'marketCap',
        testId:'marketCap',
        column: 'marketCap',
        default: true,
      },
      {
        title: 'Enterprise Value',
        options: (staticFilters['enterprise'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'enterprise',
        testId:'enterprise',
        column: 'enterPrise',
        default: true,
      },
      // {
      //   title: 'Country',
      //   options: countryList.getData().map((item:any) => ({
      //     title: item.name,
      //     value: item.code,
      //   })),
      //   property: 'country',
      //   column: 'country',
      // },
      
    ],
    ratings: [
      {
        title: 'Value Rating',
        options: (staticFilters['valueRating'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        testId:'valueRating',
        property: 'valueRating',
        column: 'valueRating',
        isMulti:true
      },
      {
        title: 'Growth Rating',
        options: (staticFilters['growthRating'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'growthRating',
        testId:'growthRating',
        isMulti:true,
        column: 'growthRating',

      },
      {
        title: 'Profitability Rating',
        options: (staticFilters['profitabilityRating'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'profitabilityRating',
        testId:'profitabilityRating',
        column: 'profitabilityRating',
        isMulti:true
      },
      {
        title: 'Safety Rating',
        options: (staticFilters['safetyRating'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'safetyRating',
        testId:'safetyRating',
        column: 'safetyRating',
        isMulti:true
      },

      {
        title: 'Dividend Rating',
        options: (staticFilters['dividendRating'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'dividendRating',
        testId:'dividendRating',
        column: 'dividendRating',
        isMulti:true
      },
      {
        title: 'Momentum Rating',
        options: (staticFilters['momentumRating'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'momentumRating',
        testId:'momentumRating',
        column: 'momentumRating',
        isMulti:true
      },
    ],
    valuation: [
      {
        title: 'Price/Earnings (TTM)',
        options: (staticFilters['peTTM'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'peTTM',
        column: 'priceToEarnings',
        testId:'peTTM',
        default: true,
      },
      {
        title: 'PEG (FWD)',
        options: (staticFilters['pegFWD'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'pegFWD',
        testId:'pegFWD',
        column: 'pegRatio',
        default: true,
      },
      {
        title: 'Price/Sales (TTM)',
        options: (staticFilters['psTTM'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'psTTM',
        testId:'psTTM',
        column: 'priceToSale',
        default: true,
      },
      {
        title: 'Price/Book (TTM)',
        options: (staticFilters['pbTTM'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'pbTTM',
        column: 'priceToBook',
        testId:'pbTTM',
        default: true,
      },
      {
        title: 'Price/Earnings (FWD)',
        options: (staticFilters['peFWD'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'peratio_fwd',
        testId:'peRatioFWD',
        column: 'priceToEarningsFWD',
        default: true,
      },
      // {
      //   title: 'EV/Revenue (TTM)',
      //   options: (staticFilters['evRevenue'] ?? []).map((item) => ({
      //     title: item.title,
      //     value: item.query,
      //   })),
      //   property: 'ev_revenue',
      //   column: 'evRevenue',
      //   default: true,
      // },
      {
        title: 'EV/EBITDA (TTM)',
        options: (staticFilters['evEBITDA'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'ev_ebitda',
        column: 'evEbitda',
        default: true,
      },
      {
        title: 'EV/EBIT (TTM)',
        options: (staticFilters['evEBIT'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'ev_ebit',
        column: 'evEbit',
        default: true,
      },

    ],
    financials: [
      {
        title: 'Revenue Growth (TTM)',
        options: (staticFilters['rg5'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'revenue_growth_ttm',
        column: 'revenueGrowth',
        default: true,
      },
      {
        title: 'Revenue Growth (FWD)',
        options: (staticFilters['rg5'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'revenue_growth_fwd',
        column: 'revenueGrowth',
        default: true,
      },
      {
        title: 'Revenue Growth (FY1)',
        options: (staticFilters['rg5'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'revenue_growth_fy1',
        column: 'revenueGrowth',
        default: true,
      },
      {
        title: 'Revenue Growth (FY2)',
        options: (staticFilters['rg5'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'revenue_growth_fy2',
        column: 'revenueGrowth',
        default: true,
      },
      {
        title: 'EPS Growth (TTM)',
        options: (staticFilters['eps5'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'eps_growth_ttm',
        column: 'epsGrowth',
        default: true,
      },
      {
        title: 'EPS Growth (FWD)',
        options: (staticFilters['eps5'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'eps_growth_fwd',
        column: 'epsGrowth',
        default: true,
      },
      {
        title: 'EPS Growth (FY1)',
        options: (staticFilters['eps5'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'eps_growth_fy1',
        column: 'epsGrowth',
        default: true,
      },
      {
        title: 'EPS Growth (FY2)',
        options: (staticFilters['eps5'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'eps_growth_fy2',
        column: 'epsGrowth',
        default: true,
      },
      
    ],
    profitability : [
      {
        title: 'Gross Margin (%)',
        options: (staticFilters['gross'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'gross',
        column: 'grossMargin',
        testId:'grossMargin',
        default: true,
      },
      {
        title: 'Operating Margin (%)',
        options: (staticFilters['operating'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'operating',
        column: 'operatingMargin',
        testId:'operatingMargin',
        default: true,
      },
      {
        title: 'Net Margin (%)',
        options: (staticFilters['net'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'netMargin',
        testId:'netMargin',
        column: 'netMargin',
        default: true,
      },
      {
        title: 'Return on Asset (ROA)',
        options: (staticFilters['roa'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'roa',
        column: 'roa',
        testId:'roa',
        default: true,
      },
      {
        title: 'Return on Equity (ROE)',
        options: (staticFilters['roe'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'roe',
        testId:'roe',
        column: 'roe',
        default: true,
      },
      {
        title: 'Return on Invested Capital (ROIC)',
        options: (staticFilters['roic'] ?? []).map((item: any) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'roic',
        testId:'roic',
        column: 'roic',
        default: true,
      },
    ],
    performance: [
      {
        title: '1D Return (%)',
        options: (staticFilters['oneD'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'oneD',
        testId:'oneD',
        column: 'oneDay',
        default: true,
      },
      {
        title: '1M Return (%)',
        options: (staticFilters['oneM'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'oneM',
        column: 'oneMonth',
        testId:'oneMonth',
        default: true,
      },
      {
        title: '3M Return (%)',
        options: (staticFilters['threeM'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'threeM',
        testId:'threeMonth',
        column: 'threeMonth',
        default: true,
      },
      {
        title: '6M Return (%)',
        options: (staticFilters['sixM'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'sixM',
        column: 'sixMonth',
        testId:'sixMonth',
        default: true,
      },
      {
        title: 'YTD (%)',
        options: (staticFilters['ytdR'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'ytdR',
        testId:'ytdR',
        column: 'ytdR',
        default: true,
      },
      // {
      //   title: 'QTD (%)',
      //   options: (staticFilters['qtdR'] ?? []).map((item) => ({
      //     title: item.title,
      //     value: item.query,
      //   })),
      //   property: 'qtdR',
      //   column: 'qtdR',
      //   default: true,
      // },
      
      {
        title: '1Y Return (%)',
        options: (staticFilters['oneY'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'oneY',
        column: 'oneYear',
        testId:'oneYear',
        default: true,
      },
      {
        title: '5Y Return (%)',
        options: (staticFilters['fiveY'] ?? []).map((item) => ({
          title: item.title,
          value: item.query,
        })),
        property: 'fiveY',
        column: 'fiveYears',
        testId:'fiveYears',
        default: true,
      },
    ],
  };
  return type === 'all' ? filters : filters[type as keyof typeof filters];
};