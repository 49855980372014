export default class SymbolCM {
  constructor(build) {
    this._id = build._id;
    this._name = build._name;
    this._description = build._description;
    this._logoUrl = build._logoUrl;
    this._country = build._country;
    this._industry = build._industry;
    this._sector = build._sector;
    this._sectorIcon = build._sectorIcon;
    this._market = build._market;
    this._noOfEmployees = build._noOfEmployees;
    this._currentPrice = build._currentPrice;
    this._absolutePriceChange = build._absolutePriceChange;
    this._relativePriceChange = build._relativePriceChange;
    this._marketCap = build._marketCap;
    this._marketCapValue = build._marketCapValue;
    this._fairPrice = build._fairPrice;
    this._targetPrice = build._targetPrice;
    this._verdeScore = build._verdeScore;
    this._growthRating = build._growthRating;
    this._safetyRating = build._safetyRating;
    this._valueRating = build._valueRating;
    this._dividendRating = build._dividendRating;
    this._momentumRating = build._momentumRating;
    this._forwardYield = build._forwardYield;
    this._overvaluedIndicator = build._overvaluedIndicator;
    this._fairPriceDeviation = build._fairPriceDeviation;
    this._valuationText = build._valuationText;
    this._earningsPerShare = build._earningsPerShare;
    this._forwardPriceToEarnings = build._forwardPriceToEarnings;
    this._forwardDividendRate = build._forwardDividendRate;
    this._trailingPriceToEarnings = build._trailingPriceToEarnings;
    this._fiftyTwoWeekHigh = build._fiftyTwoWeekHigh;
    this._fiftyTwoWeekLow = build._fiftyTwoWeekLow;
    this._enterprise = build._enterprise;
    this._isAdr = build._isAdr;
    this._profitabilityRating = build._profitabilityRating;
    this._symbolMarket = build._symbolMarket;
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get description() {
    return this._description;
  }

  set description(description) {
    this._description = description;
  }

  get logoUrl() {
    return this._logoUrl;
  }

  set logoUrl(logoUrl) {
    this._logoUrl = logoUrl;
  }

  get country() {
    return this._country;
  }

  set country(country) {
    this._country = country;
  }

  get industry() {
    return this._industry;
  }

  set industry(industry) {
    this._industry = industry;
  }

  get sector() {
    return this._sector;
  }

  set sector(sector) {
    this._sector = sector;
  }

  get sectorIcon() {
    return this._sectorIcon;
  }

  set sectorIcon(sectorIcon) {
    this._sectorIcon = sectorIcon;
  }

  get market() {
    return this._market;
  }

  set market(market) {
    this._market = market;
  }

  get noOfEmployees() {
    return this._noOfEmployees;
  }

  set noOfEmployees(noOfEmployees) {
    this._noOfEmployees = noOfEmployees;
  }

  get currentPrice() {
    return this._currentPrice;
  }

  set currentPrice(currentPrice) {
    this._currentPrice = currentPrice;
  }

  get absolutePriceChange() {
    return this._absolutePriceChange;
  }

  set absolutePriceChange(absolutePriceChange) {
    this._absolutePriceChange = absolutePriceChange;
  }

  get relativePriceChange() {
    return this._relativePriceChange;
  }

  set relativePriceChange(relativePriceChange) {
    this._relativePriceChange = relativePriceChange;
  }

  get marketCap() {
    return this._marketCap;
  }

  set marketCap(marketCap) {
    this._marketCap = marketCap;
  }

  get marketCapValue() {
    return this._marketCapValue;
  }

  set marketCapValue(marketCapValue) {
    this._marketCapValue = marketCapValue;
  }

  get fairPrice() {
    return this._fairPrice;
  }

  set fairPrice(fairPrice) {
    this._fairPrice = fairPrice;
  }

  get targetPrice() {
    return this._targetPrice;
  }

  set targetPrice(targetPrice) {
    this._targetPrice = targetPrice;
  }

  get verdeScore() {
    return this._verdeScore;
  }

  set verdeScore(verdeScore) {
    this._verdeScore = verdeScore;
  }

  get growthRating() {
    return this._growthRating;
  }

  set growthRating(growthRating) {
    this._growthRating = growthRating;
  }

  get safetyRating() {
    return this._safetyRating;
  }

  set safetyRating(safetyRating) {
    this._safetyRating = safetyRating;
  }

  get valueRating() {
    return this._valueRating;
  }

  set valueRating(valueRating) {
    this._valueRating = valueRating;
  }

  get dividendRating() {
    return this._dividendRating;
  }

  set dividendRating(dividendRating) {
    this._dividendRating = dividendRating;
  }

  get momentumRating() {
    return this._momentumRating;
  }

  set momentumRating(momentumRating) {
    this._momentumRating = momentumRating;
  }

  get forwardYield() {
    return this._forwardYield;
  }

  set forwardYield(forwardYield) {
    this._forwardYield = forwardYield;
  }

  get overvaluedIndicator() {
    return this._overvaluedIndicator;
  }

  set overvaluedIndicator(overvaluedIndicator) {
    this._overvaluedIndicator = overvaluedIndicator;
  }

  get fairPriceDeviation() {
    return this._fairPriceDeviation;
  }

  set fairPriceDeviation(fairPriceDeviation) {
    this._fairPriceDeviation = fairPriceDeviation;
  }

  get valuationText() {
    return this._valuationText;
  }

  set valuationText(valuationText) {
    this._valuationText = valuationText;
  }

  get earningsPerShare() {
    return this._earningsPerShare;
  }

  set earningsPerShare(earningsPerShare) {
    this._earningsPerShare = earningsPerShare;
  }

  get forwardPriceToEarnings() {
    return this._forwardPriceToEarnings;
  }

  set forwardPriceToEarnings(forwardPriceToEarnings) {
    this._forwardPriceToEarnings = forwardPriceToEarnings;
  }

  get forwardDividendRate() {
    return this._forwardDividendRate;
  }

  set forwardDividendRate(forwardDividendRate) {
    this._forwardDividendRate = forwardDividendRate;
  }

  get trailingPriceToEarnings() {
    return this._trailingPriceToEarnings;
  }

  set trailingPriceToEarnings(trailingPriceToEarnings) {
    this._trailingPriceToEarnings = trailingPriceToEarnings;
  }

  get fiftyTwoWeekHigh() {
    return this._fiftyTwoWeekHigh;
  }

  set fiftyTwoWeekHigh(fiftyTwoWeekHigh) {
    this._fiftyTwoWeekHigh = fiftyTwoWeekHigh;
  }

  get fiftyTwoWeekLow() {
    return this._fiftyTwoWeekLow;
  }

  set fiftyTwoWeekLow(fiftyTwoWeekLow) {
    this._fiftyTwoWeekLow = fiftyTwoWeekLow;
  }

  get enterprise() {
    return this._enterprise;
  }

  set enterprise(enterprise) {
    this._enterprise = enterprise;
  }

  get isAdr() {
    return this._isAdr;
  }

  set isAdr(isAdr) {
    this._isAdr = isAdr;
  }

  get profitabilityRating() {
    return this._profitabilityRating;
  }

  set profitabilityRating(profitabilityRating) {
    this._profitabilityRating = profitabilityRating;
  }

  get symbolMarket() {
    return this._symbolMarket;
  }

  set symbolMarket(symbolMarket) {
    this._symbolMarket = symbolMarket;
  }

  static get builder() {
    class Builder {
      id(id) {
        this._id = id;
        return this;
      }

      name(name) {
        this._name = name;
        return this;
      }

      description(description) {
        this._description = description;
        return this;
      }

      logoUrl(logoUrl) {
        this._logoUrl = logoUrl;
        return this;
      }

      country(country) {
        this._country = country;
        return this;
      }

      industry(industry) {
        this._industry = industry;
        return this;
      }

      sector(sector) {
        this._sector = sector;
        return this;
      }

      sectorIcon(sectorIcon) {
        this._sectorIcon = sectorIcon;
        return this;
      }

      market(market) {
        this._market = market;
        return this;
      }

      noOfEmployees(noOfEmployees) {
        this._noOfEmployees = noOfEmployees;
        return this;
      }

      currentPrice(currentPrice) {
        this._currentPrice = currentPrice;
        return this;
      }

      absolutePriceChange(absolutePriceChange) {
        this._absolutePriceChange = absolutePriceChange;
        return this;
      }

      relativePriceChange(relativePriceChange) {
        this._relativePriceChange = relativePriceChange;
        return this;
      }

      marketCap(marketCap) {
        this._marketCap = marketCap;
        return this;
      }

      marketCapValue(marketCapValue) {
        this._marketCapValue = marketCapValue;
        return this;
      }

      fairPrice(fairPrice) {
        this._fairPrice = fairPrice;
        return this;
      }

      targetPrice(targetPrice) {
        this._targetPrice = targetPrice;
        return this;
      }

      verdeScore(verdeScore) {
        this._verdeScore = verdeScore;
        return this;
      }

      growthRating(growthRating) {
        this._growthRating = growthRating;
        return this;
      }

      safetyRating(safetyRating) {
        this._safetyRating = safetyRating;
        return this;
      }

      valueRating(valueRating) {
        this._valueRating = valueRating;
        return this;
      }

      dividendRating(dividendRating) {
        this._dividendRating = dividendRating;
        return this;
      }

      momentumRating(momentumRating) {
        this._momentumRating = momentumRating;
        return this;
      }

      forwardYield(forwardYield) {
        this._forwardYield = forwardYield;
        return this;
      }

      overvaluedIndicator(overvaluedIndicator) {
        this._overvaluedIndicator = overvaluedIndicator;
        return this;
      }

      fairPriceDeviation(fairPriceDeviation) {
        this._fairPriceDeviation = fairPriceDeviation;
        return this;
      }

      valuationText(valuationText) {
        this._valuationText = valuationText;
        return this;
      }

      earningsPerShare(earningsPerShare) {
        this._earningsPerShare = earningsPerShare;
        return this;
      }

      forwardPriceToEarnings(forwardPriceToEarnings) {
        this._forwardPriceToEarnings = forwardPriceToEarnings;
        return this;
      }

      forwardDividendRate(forwardDividendRate) {
        this._forwardDividendRate = forwardDividendRate;
        return this;
      }

      trailingPriceToEarnings(trailingPriceToEarnings) {
        this._trailingPriceToEarnings = trailingPriceToEarnings;
        return this;
      }

      fiftyTwoWeekHigh(fiftyTwoWeekHigh) {
        this._fiftyTwoWeekHigh = fiftyTwoWeekHigh;
        return this;
      }

      fiftyTwoWeekLow(fiftyTwoWeekLow) {
        this._fiftyTwoWeekLow = fiftyTwoWeekLow;
        return this;
      }

      enterprise(enterprise) {
        this._enterprise = enterprise;
        return this;
      }

      isAdr(isAdr) {
        this._isAdr = isAdr;
        return this;
      }

      profitabilityRating(profitabilityRating) {
        this._profitabilityRating = profitabilityRating;
        return this;
      }

      profitabilityRating(profitabilityRating) {
        this._profitabilityRating = profitabilityRating;
        return this;
      }

      symbolMarket(symbolMarket) {
        this._symbolMarket = symbolMarket;
        return this;
      }

      build() {
        return new SymbolCM(this);
      }
    }
    return Builder;
  }
}
