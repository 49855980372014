import React from 'react';
import { connect } from 'react-redux';
import { ColoredParagraph, StyledLink } from './style';
import {
  ColoredContentText,
  StyledInput,
  GreenButton,
} from '../StyedComponents';
import { showErrorAlert, showSuccessAlert } from '../../../actions/alertAction';
import {
  showPageLoading,
  resetLoginPhase,
  setLoginPhase,
} from '../../../actions/coreAction';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ForgotPassword = ({
  email: emailToRecover,
  showPageLoading,
  showErrorAlert,
  showSuccessAlert,
  resetLoginPhase,
  setLoginPhase,
  loginPhase,
  forgotPasswordEmail,
}) => {
  const [email, setEmail] = React.useState(forgotPasswordEmail);
  const [verificationCode, setVerificationCode] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [revealPassword, setRevealPassword] = React.useState(false);
  const [revealConfirmPassword, setRevealConfirmPassword] = React.useState(
    false
  );
  //const [isCodeSent, setCodeSent] = React.useState(false);

  const clearPassword = () => {
    setPassword('');
    setConfirmPassword('');
  };

  React.useEffect(() => {
    setVerificationCode('');
    clearPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCodeSent = loginPhase === 'verifypasswordreset';

  const forgotPassword = async () => {
    if (!email) return showErrorAlert('Email is mandatory!');
    try {
      showPageLoading(true);
      await Auth.forgotPassword(email);
      showSuccessAlert('Verification code has been sent to your email!');
      setLoginPhase('verifypasswordreset');
    } catch (error) {
      showErrorAlert(error.message);
      console.log('error recovering account', error);
    } finally {
      showPageLoading(false);
    }
  };

  const forgotPasswordSubmit = async () => {
    if (!verificationCode)
      return showErrorAlert('Enter verification code sent to your email.');
    if (!password) return showErrorAlert('Password is mandatory!');
    if (!confirmPassword)
      return showErrorAlert('Confirm Password is mandatory!');
    if (password !== confirmPassword)
      return showErrorAlert('Password and Confirm Password must match!');

    try {
      showPageLoading(true);
      await Auth.forgotPasswordSubmit(email, verificationCode, password);
      showSuccessAlert(
        'Password has been changed successfully! Please signin with the new password to continue.'
      );
      resetLoginPhase();
    } catch (error) {
      showErrorAlert(error.message);
      console.log('error confirming sign up', error);
      clearPassword();
    } finally {
      showPageLoading(false);
    }
  };

  return (
    <div className="p-8">
      <div className="my-2">
        <ColoredParagraph color="#2b9867">
          Recover your account!
        </ColoredParagraph>
      </div>
      <StyledInput
        id="email"
        name="email"
        type="email"
        placeholder="E-Mail"
        value={email}
        onChange={(e) => setEmail(e.target.value.trim())}
        disabled={isCodeSent}
        autoFocus={!isCodeSent}
      />
      {isCodeSent && (
        <>
          <StyledInput
            id="verificationCode"
            name="verificationCode"
            type="text"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value.trim())}
            autoFocus={isCodeSent}
          />
          <StyledInput
            id="password"
            name="password"
            type={revealPassword ? 'text' : 'password'}
            autoComplete={'off'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value.trim())}
          />
          <FontAwesomeIcon
            className="float-right relative bottom-8 mr-4 cursor-pointer text-lg text-gray-400"
            icon={revealPassword ? faEye : faEyeSlash}
            onClick={() => setRevealPassword(!revealPassword)}
          />
          <StyledInput
            id="confirm-password"
            name="confirm-password"
            type={revealConfirmPassword ? 'text' : 'password'}
            placeholder="Confirm Password"
            autoComplete={'off'}
            value={confirmPassword}
            style={{ marginTop: 0 }}
            onChange={(e) => setConfirmPassword(e.target.value.trim())}
          />
          <FontAwesomeIcon
            className="float-right relative bottom-8 mr-4 cursor-pointer text-lg text-gray-400"
            icon={revealConfirmPassword ? faEye : faEyeSlash}
            onClick={() => setRevealConfirmPassword(!revealConfirmPassword)}
          />

          <div className="flex text-sm mt-1">
            <ColoredContentText className="mr-1 font-normal">
              Didn't receive?
            </ColoredContentText>
            <StyledLink onClick={forgotPassword}>Resend code</StyledLink>
          </div>
        </>
      )}
      <GreenButton onClick={isCodeSent ? forgotPasswordSubmit : forgotPassword}>
        {isCodeSent ? 'Submit Code' : 'Get Code'}
      </GreenButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginPhase: state.coreState.loginPhase,
  forgotPasswordEmail: state.coreState.forgotPasswordEmail,
});

const mapDispatchToProps = {
  showErrorAlert,
  showSuccessAlert,
  showPageLoading,
  resetLoginPhase,
  setLoginPhase,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
