import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  buildTooltipByValue,
  getFormattedCurrencyValueForTooltip,
  getTooltipDate,
} from '../../../../../utils/ChartUtils';

const Chart = ({ data, darkMode }) => {
  function generateTooltip(_this, mobileMode) {
    const points = _this.points;
    let tooltips = '';
    const padding = mobileMode ? 3 : 5;
    tooltips = tooltips.concat(
      '<div style="position:relative;height:100%;padding:' + padding + 'px;"> '
    );

    const dividendPaidOut = points[0];
    const buyback = points[1];
    const freeCashFlow = points[2];
    const totalShareholderReturn = points[3];
    tooltips = tooltips.concat(
      buildTooltipByValue(
        getFormattedCurrencyValueForTooltip(dividendPaidOut.y),
        dividendPaidOut.series.color.stops[0][1],
        dividendPaidOut.series.name,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(
      buildTooltipByValue(
        getFormattedCurrencyValueForTooltip(buyback.y),
        buyback.series.color.stops[0][1],
        buyback.series.name,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(
      buildTooltipByValue(
        getFormattedCurrencyValueForTooltip(freeCashFlow.y),
        freeCashFlow.series.color.stops[0][1],
        freeCashFlow.series.name,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(
      buildTooltipByValue(
        totalShareholderReturn.y + '%',
        totalShareholderReturn.series.color,
        totalShareholderReturn.series.name,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(getTooltipDate(_this.x, darkMode, mobileMode));
    return tooltips;
  }

  const options = {
    chart: {
      zoomType: 'xy',
      style: {
        fontFamily: 'Roboto',
      },
      backgroundColor: darkMode ? 'transparent' : '#ffffff',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: data.map((d) => {
        return Highcharts.dateFormat('%b %Y', new Date(d[0]).getTime());
      }),
      type: 'datetime',
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      dateTimeLabelFormats: {
        day: '%e%b%y',
        month: '%b %Y',
      },
    },
    yAxis: [
      {
        title: {
          useHTML: true,
          text:
            '<span style="color:rgb(101, 235, 238);font-size:20px;margin-right:2px;">\u25CF</span> Total Dividends Paid Out <span style="color:rgb(225, 130, 222);font-size:20px;margin-left:12px;margin-right:2px;">\u25CF</span> Net Income',
          style: {
            fontSize: '14px',
            fontWeight: 500,
            color: darkMode ? '#d8d9e4' : '#686868',
          },
        },
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 500,
            color: darkMode ? '#d8d9e4' : '#3a3a3a',
          },
          formatter: function () {
            return '$' + this.axis.defaultLabelFormatter.call(this);
          },
        },
        gridLineColor: darkMode ? '#3b404f' : '#e6e6e6',
      },
      {
        title: {
          useHTML: true,
          text:
            '<span style="color:#9e71ff;font-size:20px;margin-right:2px;">\u25CF</span> Payout Ratio',
          style: {
            fontSize: '14px',
            fontWeight: 500,
            color: darkMode ? '#d8d9e4' : '#686868',
          },
          rotation: 270,
        },
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 500,
            color: darkMode ? '#d8d9e4' : '#3a3a3a',
          },
          formatter: function () {
            return this.axis.defaultLabelFormatter.call(this) + '%';
          },
        },
        gridLineWidth: 0,
        opposite: true,
      },
    ],
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        borderRadius: 4,
        lineColor: 'transparent',
        borderWidth: 0,
      },
      spline: {
        lineColor: '#9e71ff',
      },
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shadow: false,
      useHTML: true,
      backgroundColor: darkMode ? '#141920' : '#fff',
      borderColor: darkMode ? '#86efaf' : '#5bae7f',
      borderRadius: 8,
      followPointer: true,
      formatter: function () {
        return generateTooltip(this, false);
      },
      shared: true,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            plotOptions: {
              //pointWidth: 20,
            },
            tooltip: {
              formatter: function () {
                return generateTooltip(this, true);
              },
            },
            chart: {
              height: 400,
            },
          },
        },
      ],
    },
    series: [
      {
        name: 'Total Dividends Paid Out',
        data: data.map((d) => d[1] * d[2]),
        type: 'column',
        threshold: null,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: darkMode
            ? [
                [0, 'rgb(101, 235, 238)'],
                [1, 'rgb(6, 132, 144)'],
              ]
            : [
                [0, 'rgb(101, 235, 238)'],
                [1, 'rgb(161, 240, 249)'],
              ],
        },
        fillOpacity: 0.3,
        yAxis: 0,
        stack: 'dividend',
        borderRadius: 0,
      },
      {
        name: 'Share Buybacks',
        data: data.map((d) => Math.abs(d[5])),
        type: 'column',
        threshold: null,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: darkMode
            ? [
                [0, 'rgb(101, 235, 238)'],
                [1, 'rgb(6, 132, 144)'],
              ]
            : [
                [0, 'rgb(101, 235, 238)'],
                [1, 'rgb(161, 240, 249)'],
              ],
        },
        fillOpacity: 0.3,
        yAxis: 0,
        stack: 'dividend',
        borderRadius: 0,
      },
      {
        name: 'Free Cash Flow',
        data: data.map((d) => d[4]),
        type: 'column',
        threshold: null,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: darkMode
            ? [
                [0, 'rgb(225, 130, 222)'],
                [1, 'rgb(121, 19, 118)'],
              ]
            : [
                [0, 'rgb(225, 130, 222)'],
                [1, 'rgb(239, 181, 238)'],
              ],
        },
        fillOpacity: 0.3,
        yAxis: 0,
        stack: 'fcf',
      },
      {
        name: 'Total Shareholder Return',
        data: data.map((d) => d[3]),
        type: 'spline',
        threshold: null,
        color: '#9e71ff',
        yAxis: 1,

        //fillOpacity: 0.3,
      },
    ],
  };

  return (
    <div className="text-xs font-semibold w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(Chart);
