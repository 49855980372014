import styled from 'styled-components/macro';
import { ColoredContentText, HollowButton } from '../StyedComponents';
import tw from 'twin.macro';
import { LIGHT_THEME as Theme } from '../../core/theme';
export const TableContainer = styled.div`
  ${tw`overflow-x-auto`}
  table {
    border-spacing: 0;
    background-color: ${(props) => props.theme.table.background};
    border: 1px solid ${(props) => props.theme.table.border};
    tr {
      color: ${(props) => props.theme.table.color};
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      height: 50px;
    }
    th,
    td {
      padding: 0.5rem 35px 0.5rem;
      border-bottom: 1px solid ${(props) => props.theme.table.border};
      :last-child {
        border-right: 0;
      }
    }
  }
`;

export const PaginationButton = styled(HollowButton)`
  ${tw`w-20 md:w-28 px-1 h-10 py-0.5`}
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  border: ${(props) =>
    props.disabled
      ? `1.4px solid ${Theme.disabled}`
      : `1.4px solid ${Theme.keyHighlight}`};
  color: ${(props) => (props.disabled ? Theme.disabled : Theme.keyHighlight)};
`;

export const StyledTableHead = styled.thead`
  background-color: ${(props) => props.theme.table.headerBgColor};
  border: 1px solid ${(props) => props.theme.table.border};
  border-left: 0;
  border-right: 0;
  color: ${(props) => props.theme.table.color};
`;

export const StyledTableFirst = styled.div`
  background-color: ${(props) =>
    props.theme.table.background === '#1d212b' ? '#262626' : 'white'};
  border-left: 0;
  border-right: 0;
  color: ${(props) => props.theme.table.color};
`;

export const TableColumn = styled(ColoredContentText)`
  ${tw`text-sm font-medium flex`}
  ${({ align = 'left' }) =>
    align === 'left'
      ? tw`text-left justify-start`
      : tw`text-right justify-start mr-6`}
`;
