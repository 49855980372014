import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

const Wrapper = styled.div`
  ${tw`p-2`}
  font-family: Roboto;
`;

const ContainerWrapper = styled.div`
  ${tw`container min-h-screen mt-20 mx-auto`}
`;

export default function PageContainer({ children, className }) {
  return (
    <Wrapper>
      <ContainerWrapper className={className}>{children}</ContainerWrapper>
    </Wrapper>
  );
}
