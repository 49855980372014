import React from 'react';
import SectionHeader from '../SectionHeader';
import {
  faThumbsDown,
  faThumbsUp,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@chakra-ui/react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';

function Highlights({ title, darkMode, keys, callback, scroll }) {
  function trueFalse(string) {
    let negatives = [
      'overvalued',
      'overpriced',
      'decreased',
      'below',
      'low',
      'decline',
    ];

    let negative = false;

    let splitString = string.split(' ');
    let payout = false;
    let total = false; //financial statement debt & liabilities
    let outstanding = false; //shares outstanding

    splitString.map((elem) => {
      if (negatives.includes(elem)) {
        negative = true;
        if (string.includes('Payout')) {
          payout = true;
        }
        if (string.includes('Total Debt')) {
          total = true;
        }
        if (string.includes('Outstanding')) {
          outstanding = true;
        }
      }
    });

    //TODO: Simplify highlights logic
    if (outstanding && title === 'Financial Ratio Highlights') {
      return splitString.filter((str) => negatives.includes(str)).length > 0
        ? 'green'
        : 'red';
    }

    if (string.includes('No Data Available')) {
      return 'none';
    }

    if (payout) {
      return 'green';
    }
    if (
      !total &&
      string.includes('Total Debt') &&
      title === 'Financial Statement Highlights'
    ) {
      return 'red';
    }
    if (
      !outstanding &&
      string.includes('Outstanding') &&
      title === 'Financial Ratio Highlights'
    ) {
      return 'red';
    }

    if (negative && !total) {
      return 'red';
    }

    return 'green';
  }

  function decorate(string) {
    let before = '';
    let imp = '';
    let after = '';

    let keyWords = [
      'overvalued',
      'undervalued',
      'overpriced',
      'underpriced',
      'grow',
      'increased',
      'decreased',
      'below',
      'low',
      'decline',
    ];
    let negatives = [
      'overvalued',
      'overpriced',
      'decreased',
      'below',
      'low',
      'decline',
    ];

    let seen = false;
    let negative = false;

    let splitString = string.split(' ');

    splitString.map((elem) => {
      if (seen) {
        after += elem + ' ';
      } else if (keyWords.includes(elem)) {
        imp = elem + ' ';
        seen = true;
        if (negatives.includes(elem)) {
          negative = true;
        }
      } else {
        before += elem + ' ';
      }
    });

    if (title === 'Dividend Highlights') {
      imp = '';
    }
    if (string.includes('Sector') && string.charAt(0) === 'P') {
      imp = '';
    }

    let firstChar = imp.charAt(0);
    firstChar = firstChar.toUpperCase();

    imp = imp.replace(imp.charAt(0), firstChar);

    if (
      (imp.includes('Grow') || imp.includes('Decline')) &&
      title === 'Future Growth Highlights'
    ) {
      before = before + after;

      if (imp.includes('Grow')) {
        imp = 'Growth';
      } else {
        imp = 'Decline';
      }

      after = '';
    }

    return (
      <>
        <Text style={{ pointerEvents: 'none' }}>
          {before}
          <span
            style={{
              color: !darkMode
                ? trueFalse(string) === 'red'
                  ? '#be2e2c'
                  : '#569c30'
                : trueFalse(string) === 'red'
                ? '#ec5b55'
                : '#8acf55',
              fontWeight: '600',
              pointerEvents: 'none',
            }}
          >
            {imp}
          </span>
          {after}{' '}
          {title === 'Financial Statement Highlights' &&
          string.includes('No Data Available')
            ? ''
            : title === 'Financial Statement Highlights' &&
              !string.includes('No Data Available')
            ? ' CAGR'
            : ''}
        </Text>
      </>
    );
  }

  return (
    <>
      <SectionHeader value={title} />

      <div
        style={{
          backgroundColor: darkMode ? '#262626' : 'white',
          margin: '10px',
          marginTop: '20px',
          overflow: 'hidden',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
      >
        {keys[0] !== 'empty' && (
          <Flex
            gap="1"
            alignItems="center"
            paddingRight="2"
            backgroundColor={
              darkMode
                ? trueFalse(keys[0]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[0]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[0]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[0]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9'
            }
            marginTop="10px"
            marginBottom="10px"
            borderRadius="5px"
            onClick={() => callback(scroll[0])}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[0]) === 'green'
                  ? 'rgba(138, 207, 85, 0.25)'
                  : trueFalse(keys[0]) === 'red'
                  ? 'rgba(236, 91, 85, 0.25)'
                  : '#434243'
                : trueFalse(keys[0]) === 'green'
                ? 'rgba(86, 156, 48, 0.12)'
                : trueFalse(keys[0]) === 'red'
                ? 'rgba(190, 46, 44, 0.12)'
                : '#f4f4f4';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[0]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[0]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[0]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[0]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9';
            }}
          >
            <Flex
              style={{
                marginTop: '8px',
                marginLeft: '10px',
                marginBottom: '8px',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              <FontAwesomeIcon
                icon={
                  trueFalse(keys[0]) === 'green'
                    ? faThumbsUp
                    : trueFalse(keys[0]) === 'red'
                    ? faThumbsDown
                    : faTimesCircle
                }
                color={
                  darkMode
                    ? trueFalse(keys[0]) === 'green'
                      ? '#8acf55'
                      : trueFalse(keys[0]) === 'red'
                      ? '#ec5b55'
                      : 'rgba(246, 246, 246, 0.5)'
                    : trueFalse(keys[0]) === 'green'
                    ? '#569c30'
                    : trueFalse(keys[0]) === 'red'
                    ? '#be2e2c'
                    : 'rgba(28, 28, 30, 0.5)'
                }
              ></FontAwesomeIcon>
              <Text
                style={{
                  marginLeft: '15px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '600',
                  pointerEvents: 'none',
                }}
              >
                {decorate(keys[0])}
              </Text>
            </Flex>
          </Flex>
        )}

        {keys[1] !== 'empty' && (
          <Flex
            gap="1"
            alignItems="center"
            paddingRight="2"
            backgroundColor={
              darkMode
                ? trueFalse(keys[1]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[1]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[1]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[1]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9'
            }
            marginTop="10px"
            marginBottom="10px"
            borderRadius="5px"
            onClick={() => callback(scroll[1])}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[1]) === 'green'
                  ? 'rgba(138, 207, 85, 0.25)'
                  : trueFalse(keys[1]) === 'red'
                  ? 'rgba(236, 91, 85, 0.25)'
                  : '#434243'
                : trueFalse(keys[1]) === 'green'
                ? 'rgba(86, 156, 48, 0.12)'
                : trueFalse(keys[1]) === 'red'
                ? 'rgba(190, 46, 44, 0.12)'
                : '#f4f4f4';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[1]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[1]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[1]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[1]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9';
            }}
          >
            <Flex
              style={{
                marginTop: '8px',
                marginLeft: '10px',
                marginBottom: '8px',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              <FontAwesomeIcon
                icon={
                  trueFalse(keys[1]) === 'green'
                    ? faThumbsUp
                    : trueFalse(keys[1]) === 'red'
                    ? faThumbsDown
                    : faTimesCircle
                }
                color={
                  darkMode
                    ? trueFalse(keys[1]) === 'green'
                      ? '#8acf55'
                      : trueFalse(keys[1]) === 'red'
                      ? '#ec5b55'
                      : 'rgba(246, 246, 246, 0.5)'
                    : trueFalse(keys[1]) === 'green'
                    ? '#569c30'
                    : trueFalse(keys[1]) === 'red'
                    ? '#be2e2c'
                    : 'rgba(28, 28, 30, 0.5)'
                }
              ></FontAwesomeIcon>
              <Text
                style={{
                  marginLeft: '15px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '600',
                  pointerEvents: 'none',
                }}
              >
                {decorate(keys[1])}
              </Text>
            </Flex>
          </Flex>
        )}

        {keys[2] !== 'empty' && (
          <Flex
            gap="1"
            alignItems="center"
            paddingRight="2"
            backgroundColor={
              darkMode
                ? trueFalse(keys[2]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[2]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[2]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[2]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9'
            }
            marginTop="10px"
            marginBottom="10px"
            borderRadius="5px"
            onClick={() => callback(scroll[2])}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[2]) === 'green'
                  ? 'rgba(138, 207, 85, 0.25)'
                  : trueFalse(keys[2]) === 'red'
                  ? 'rgba(236, 91, 85, 0.25)'
                  : '#434243'
                : trueFalse(keys[2]) === 'green'
                ? 'rgba(86, 156, 48, 0.12)'
                : trueFalse(keys[2]) === 'red'
                ? 'rgba(190, 46, 44, 0.12)'
                : '#f4f4f4';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[2]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[2]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[2]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[2]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9';
            }}
          >
            <Flex
              style={{
                marginTop: '8px',
                marginLeft: '10px',
                marginBottom: '8px',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              <FontAwesomeIcon
                icon={
                  trueFalse(keys[2]) === 'green'
                    ? faThumbsUp
                    : trueFalse(keys[2]) === 'red'
                    ? faThumbsDown
                    : faTimesCircle
                }
                color={
                  darkMode
                    ? trueFalse(keys[2]) === 'green'
                      ? '#8acf55'
                      : trueFalse(keys[2]) === 'red'
                      ? '#ec5b55'
                      : 'rgba(246, 246, 246, 0.5)'
                    : trueFalse(keys[2]) === 'green'
                    ? '#569c30'
                    : trueFalse(keys[2]) === 'red'
                    ? '#be2e2c'
                    : 'rgba(28, 28, 30, 0.5)'
                }
              ></FontAwesomeIcon>
              <Text
                style={{
                  marginLeft: '15px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '600',
                  pointerEvents: 'none',
                }}
              >
                {decorate(keys[2])}
              </Text>
            </Flex>
          </Flex>
        )}

        {keys[3] !== 'empty' && (
          <Flex
            gap="1"
            paddingRight="2"
            alignItems="center"
            backgroundColor={
              darkMode
                ? trueFalse(keys[3]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[3]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[3]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[3]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9'
            }
            marginTop="10px"
            marginBottom="10px"
            borderRadius="5px"
            onClick={() => callback(scroll[3])}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[3]) === 'green'
                  ? 'rgba(138, 207, 85, 0.25)'
                  : trueFalse(keys[3]) === 'red'
                  ? 'rgba(236, 91, 85, 0.25)'
                  : '#434243'
                : trueFalse(keys[3]) === 'green'
                ? 'rgba(86, 156, 48, 0.12)'
                : trueFalse(keys[3]) === 'red'
                ? 'rgba(190, 46, 44, 0.12)'
                : '#f4f4f4';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[3]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[3]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[0]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[3]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9';
            }}
          >
            <Flex
              style={{
                marginTop: '8px',
                marginLeft: '10px',
                marginBottom: '8px',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              <FontAwesomeIcon
                icon={
                  trueFalse(keys[3]) === 'green'
                    ? faThumbsUp
                    : trueFalse(keys[3]) === 'red'
                    ? faThumbsDown
                    : faTimesCircle
                }
                color={
                  darkMode
                    ? trueFalse(keys[3]) === 'green'
                      ? '#8acf55'
                      : trueFalse(keys[3]) === 'red'
                      ? '#ec5b55'
                      : 'rgba(246, 246, 246, 0.5)'
                    : trueFalse(keys[3]) === 'green'
                    ? '#569c30'
                    : trueFalse(keys[3]) === 'red'
                    ? '#be2e2c'
                    : 'rgba(28, 28, 30, 0.5)'
                }
              ></FontAwesomeIcon>
              <Text
                style={{
                  marginLeft: '15px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '600',
                  pointerEvents: 'none',
                }}
              >
                {decorate(keys[3])}
              </Text>
            </Flex>
          </Flex>
        )}

        {keys[4] !== 'empty' && (
          <Flex
            gap="1"
            alignItems="center"
            paddingRight="2"
            backgroundColor={
              darkMode
                ? trueFalse(keys[4]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[4]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[4]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[4]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9'
            }
            marginTop="10px"
            marginBottom="10px"
            borderRadius="5px"
            onClick={() => callback(scroll[4])}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[4]) === 'green'
                  ? 'rgba(138, 207, 85, 0.25)'
                  : trueFalse(keys[4]) === 'red'
                  ? 'rgba(236, 91, 85, 0.25)'
                  : '#434243'
                : trueFalse(keys[4]) === 'green'
                ? 'rgba(86, 156, 48, 0.12)'
                : trueFalse(keys[4]) === 'red'
                ? 'rgba(190, 46, 44, 0.12)'
                : '#f4f4f4';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[4]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[4]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[0]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[4]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9';
            }}
          >
            <Flex
              style={{
                marginTop: '8px',
                marginLeft: '10px',
                marginBottom: '8px',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              <FontAwesomeIcon
                icon={
                  trueFalse(keys[4]) === 'green'
                    ? faThumbsUp
                    : trueFalse(keys[4]) === 'red'
                    ? faThumbsDown
                    : faTimesCircle
                }
                color={
                  darkMode
                    ? trueFalse(keys[4]) === 'green'
                      ? '#8acf55'
                      : trueFalse(keys[4]) === 'red'
                      ? '#ec5b55'
                      : 'rgba(246, 246, 246, 0.5)'
                    : trueFalse(keys[4]) === 'green'
                    ? '#569c30'
                    : trueFalse(keys[4]) === 'red'
                    ? '#be2e2c'
                    : 'rgba(28, 28, 30, 0.5)'
                }
              ></FontAwesomeIcon>
              <Text
                style={{
                  marginLeft: '15px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '600',
                  pointerEvents: 'none',
                }}
              >
                {decorate(keys[4])}
              </Text>
            </Flex>
          </Flex>
        )}

        {keys[5] !== 'empty' && (
          <Flex
            gap="1"
            alignItems="center"
            paddingRight="2"
            backgroundColor={
              darkMode
                ? trueFalse(keys[5]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[5]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[5]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[5]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9'
            }
            marginTop="10px"
            marginBottom="10px"
            borderRadius="5px"
            onClick={() => callback(scroll[5])}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[5]) === 'green'
                  ? 'rgba(138, 207, 85, 0.25)'
                  : trueFalse(keys[5]) === 'red'
                  ? 'rgba(236, 91, 85, 0.25)'
                  : '#434243'
                : trueFalse(keys[5]) === 'green'
                ? 'rgba(86, 156, 48, 0.12)'
                : trueFalse(keys[5]) === 'red'
                ? 'rgba(190, 46, 44, 0.12)'
                : '#f4f4f4';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = darkMode
                ? trueFalse(keys[5]) === 'green'
                  ? 'rgba(138, 207, 85, 0.1)'
                  : trueFalse(keys[5]) === 'red'
                  ? 'rgba(236, 91, 85, 0.1)'
                  : '#343434'
                : trueFalse(keys[5]) === 'green'
                ? 'rgba(86, 156, 48, 0.05)'
                : trueFalse(keys[5]) === 'red'
                ? 'rgba(190, 46, 44, 0.05)'
                : '#f9f9f9';
            }}
          >
            <Flex
              style={{
                marginTop: '8px',
                marginLeft: '10px',
                marginBottom: '8px',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              <FontAwesomeIcon
                icon={
                  trueFalse(keys[5]) === 'green'
                    ? faThumbsUp
                    : trueFalse(keys[5]) === 'red'
                    ? faThumbsDown
                    : faTimesCircle
                }
                color={
                  darkMode
                    ? trueFalse(keys[5]) === 'green'
                      ? '#8acf55'
                      : trueFalse(keys[5]) === 'red'
                      ? '#ec5b55'
                      : 'rgba(246, 246, 246, 0.5)'
                    : trueFalse(keys[5]) === 'green'
                    ? '#569c30'
                    : trueFalse(keys[5]) === 'red'
                    ? '#be2e2c'
                    : 'rgba(28, 28, 30, 0.5)'
                }
              ></FontAwesomeIcon>
              <Text
                style={{
                  marginLeft: '15px',
                  color: darkMode ? 'white' : 'black',
                  fontWeight: '600',
                  pointerEvents: 'none',
                }}
              >
                {decorate(keys[5])}
              </Text>
            </Flex>
          </Flex>
        )}
      </div>
    </>
  );
}

export default Highlights;
