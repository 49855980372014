import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Container, Header } from './style';
import ClickedOutside from '../../shared/ClickOutside';
import ScreenerTableRevamp from './screenerTableRevamp';
import ColumnSelectorImg from '../../../public/images/screener/column-choose.svg';
import PageContainer from '../../shared/PageContainer';
import TableColumnToggle from '../../shared/TableColumnToggle';
import { ColumnSelector } from '../ScreenerPage/style';
import { SearchIcon } from '../HomepageLogin/icons';
import styled from 'styled-components';
import BlackCustomFilter from '../../../public/images/BlackCustomFilter.png';
import WhiteCustomFilter from '../../../public/images/WhiteCustomFilter.png';

import AppliedPresetDark from '../../../public/images/AppliedPresetDark.png';
import AppliedPresetLight from '../../../public/images/AppliedPresetLight.png';

import {
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  SlideFade,
} from '@chakra-ui/react';

import {
  defaultColumns,
  additionalColumns,
} from '../../shared/StockTable/columns';
import DefaultFiltersNew, {
  getFilters,
  FilterTabs,
} from '../../shared/Sections/DefaultFiltersNew';

import {
  fetchFilterValues,
  getScreenerData,
  updateFilters,
} from '../../../actions/screenerAction';
import { showPageLoading } from '../../../actions/coreAction';
import { showErrorAlert } from '../../../actions/alertAction';
import { appliedFiltersInitialState } from '../../../reducer/screenerReducer';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { useTheme } from 'styled-components';
import PageTab from './page-tab';
import { tabs as columnTabs } from './constants';
import TableSidebar from './table-sidebar';
import CustomTableColumnSelector from './custom-table-column';
import { PresetFilters, PresetFiltersMainMenu } from './preset-filters';
import NewFilters from './newFilters';
import {
  getSelectStyles,
  themeObj as defaultFiltersTheme,
} from '../../shared/Sections/DefaultFiltersNew/default-filters';
import PremiumRestrictionComponent from '../../shared/PremiumRestrictionComponent';

const tabs = [...columnTabs, { name: 'Customize Table', type: 'custom' }];

// const accessorMap = [..defaul]

const SearchIconComponent = styled(Box)`
  g {
    fill: ${(props) => props.color};
  }
`;

const themeObject = {
  light: {
    pageBg: '#f4f4f4',
    pillBg: '#def4c4',
    pillColor: '#19233c',
    addFilterText: '#36a867',
  },
  dark: {
    pillBg: '#393b3f',
    pageBg: '#161616',
    pillBorder: '#3B404F ',
    pillColor: 'white',
    companiesColor: 'white',
    addFilterText: '#36a867',
  },
};

// const defaultColumnNames = defaultColumns.map((column) => column['Header']);

const filterDropDowns = getFilters([], 'all');
const allFilters = Object.keys(filterDropDowns).reduce((acc, item) => {
  return acc.concat(filterDropDowns[item]);
}, []);
export const getPillFilters = (appliedFilters = []) => {
  const filters = [];
  Object.keys(appliedFilters).forEach((filter) => {
    const filterObject = appliedFilters[filter]?.[0];
    const allFiltersItem = allFilters.find((item) => item.property === filter);
    if (filter !== 'sortFields' && filterObject) {
      const isArray = Array.isArray(filterObject);
      if (isArray && !filterObject.length) {
        return;
      }
      filters.push({
        type: allFiltersItem.title,
        value: Array.isArray(filterObject)
          ? filterObject
              .map((item) => {
                if (typeof item.value == 'string') return item.label;
                else {
                  return item.value.title;
                }
              })
              .join(',')
          : filterObject.value?.title ?? filterObject.value,
        property: filter,
        column: allFiltersItem.column,
      });
    }
  });
  return filters;
};
const getColumns = (defaultColumnNames, isMobile) => {
  const columnArray = defaultColumnNames.map((item) => item.id);

  let resultArr = [];

  defaultColumnNames.map((elem1) => {
    [...defaultColumns, ...additionalColumns].map((elem2) => {
      if (elem1.id === elem2.id || elem1.id === elem2.accessor) {
        resultArr.push({
          ...elem2,
          name: elem2['Header'],
          Header: elem1.text,
          checked:
            columnArray.includes(elem2['accessor']) ||
            columnArray.includes(elem2['id']),
          sticky: elem2.sticky,
        });
      }
    });
  });

  return resultArr;

  // [...defaultColumns, ...additionalColumns]
  //   .map((column) => ({
  //     ...column,
  //     name: column['Header'],
  //     checked:
  //       columnArray.includes(column['accessor']) ||
  //       columnArray.includes(column['id']),
  //     sticky: isMobile ? null : column.sticky,
  //   }))
  //   .filter(
  //     (item) =>
  //       columnArray.includes(item.id) || columnArray.includes(item.accessor)
  //   );
};

function ScreenerPage({
  fetchFilterValues,
  error,
  loading,
  showPageLoading,
  appliedFilters,
  showErrorAlert,
  updateFilters,
  filterValues,
  darkMode,
  isNew,
  totalPages = 0,
  totalCount,
  subscriptionStatus,
}) {
  const [customTableApplied, setCustomTableApplied] = useState(false);

  const currentTheme = useTheme();
  const [currentTabs, setCurrentTabs] = useState(tabs);
  const [tab, setTab] = useState(tabs[0]);
  const [isPresetShown, setIsPresetShown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCustomFilterShown, setIsCustomFilterShown] = useState(false);
  const theme = currentTheme.isDark ? themeObject.dark : themeObject.light;
  const defaultFiltersThemeObj = currentTheme.isDark
    ? defaultFiltersTheme.dark
    : defaultFiltersTheme.light;
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const searchInputRef = React.useRef(null);
  const [tableToggle, setTableToggle] = React.useState(false);
  const [pillFilters, setPillFilters] = useState(
    getPillFilters(appliedFilters)
  );
  const [isCustomTableSelectorOpen, setCustomTableSelectorOpen] = useState(
    false
  );

  const [showPresetMainMenu, setShowPresetMainMenu] = useState(true);
  const [detailsPage, setIsDetailsPage] = useState({});
  const [selectedPresetFromCallback, setSelectedPresetFromCallback] = useState(
    ''
  );
  const [companiesFoundCallback, setCompaniesFoundCallback] = useState(0);

  const dispatch = useAppDispatch();
  const [table, setTable] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  let defaultColumnNames = tab.columns || [];
  const [columns, setColumns] = React.useState(
    getColumns(defaultColumnNames, isMobile)
  );
  useEffect(() => {
    defaultColumnNames = tab.columns || [];
    setColumns(getColumns(defaultColumnNames, isMobile));
  }, [isMobile, tab]);

  const [openFilterModal, setOpenFilterModal] = React.useState(true);

  const location = useLocation();

  React.useEffect(() => {
    document.title = 'Verde Finance - Screener';
    filterValues.sectors.length === 0 && fetchFilterValues();
  }, [fetchFilterValues, filterValues.sectors.length]);

  React.useEffect(() => {
    showPageLoading(loading);
  }, [loading, showPageLoading]);

  React.useEffect(() => {
    error && showErrorAlert(error);
  }, [error, showErrorAlert]);

  React.useEffect(() => {
    location.state
      ? updateFilters({
          ...appliedFiltersInitialState,
          sectors: location.state.sectors,
        })
      : updateFilters({
          ...appliedFiltersInitialState,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const onCustomTabsSave = (tabs) => {
    const columns = tabs.reduce((acc, tab) => {
      if (tab.selectedColumns) {
        tab.selectedColumns.forEach((item) => acc.add(item));
      }
      return acc;
    }, new Set());
    const filteredColumns = getColumns([...columns]);
    setColumns(filteredColumns);
    setCurrentTabs(tabs);
  };

  useEffect(() => {
    const filters = getPillFilters(appliedFilters);
    const filterColumns = filters.map((item) => item.column);
    setColumns((columns) => {
      const _columns = [...columns];
      _columns.forEach((column) => {
        if (filterColumns.includes(column.accessor)) {
          column.checked = true;
        }
      });
      return _columns;
    });
    setPillFilters(filters);
  }, [appliedFilters]);

  function customTableAppliedCallback(value) {
    setCustomTableApplied(value);
  }

  const Pill = ({ type, theme, first, value, clearPill }) => (
    <Flex
      bg={theme.pillBg}
      color={theme.pillColor}
      alignItems="center"
      p="4"
      pt="2.5"
      pb="2.5"
      borderRadius="20px"
      ml={first ? '0' : '2'}
    >
      <Box>
        <Text style={{ fontSize: '15px' }}>
          {type}, <b>{value}</b>
        </Text>
      </Box>
      <Box
        ml="2"
        cursor="pointer"
        onClick={() => {
          clearPill(type);
          setSelectedPresetFromCallback('');
        }}
        _hover={{
          transform: 'scale(1.2)',
        }}
      >
        <CloseIcon w="8px" h="8px" />
      </Box>
    </Flex>
  );

  return !subscriptionStatus?.active ? (
    <PremiumRestrictionComponent />
  ) : (
    <PageContainer style={{ backgroundColor: theme.pageBg }}>
      <div className="flex overflow-x-auto">
        <PageTab
          tabs={tabs}
          customApplied={customTableApplied}
          setTab={(tab) => {
            setTab(tab);
            if (tab.type === 'custom') {
              setCustomTableSelectorOpen(true);
            }
          }}
          selectedTab={tab}
          darkMode={darkMode}
        />
      </div>

      <div
        style={{
          padding: '20px',
          borderRadius: '5px',
          backgroundColor: darkMode ? '#262626' : 'white',
        }}
      >
        <Flex my="4" hidden={!pillFilters.length}>
          {pillFilters.map((filter, index) => (
            <Pill
              type={filter.type}
              value={filter.value}
              theme={theme}
              first={index === 0}
              clearPill={() => {
                const filters = {
                  ...appliedFilters,
                  [filter.property]: [],
                };
                dispatch(updateFilters(filters));
              }}
            />
          ))}
        </Flex>

        <div className="flex justify-between items-center my-2 mb-4">
          <div className="lg:flex hidden">
            <Text color={darkMode ? '#cccccc' : '#8c8c8c'}>
              <span style={{ fontWeight: 'bold' }}>
                {loading
                  ? '-'
                  : searchTerm !== ''
                  ? companiesFoundCallback
                  : totalCount}
              </span>{' '}
              companies found
            </Text>
          </div>
          {/* <div className="hidden">
            <Flex
              alignItems="center"
              cursor="pointer"
              onClick={() => setShowFilter(true)}
            >
              <AddIcon color={theme.addFilterText} />
              <Text
                color={theme.companiesColor}
                ml="2"
                fontSize="sm"
                fontWeight="bold"
              >
                Filters
              </Text>
            </Flex>
          </div> */}

          <Flex gap="2" style={{ flexWrap: 'wrap' }}>
            <InputGroup
              size="xs"
              mt="0"
              w={['xs']}
              outline="none"
              border={`0.5px solid ${
                darkMode ? '#343434' : 'rgba(245, 245, 245, 0.7)'
              }`}
              borderRadius="20px"
              bg={currentTheme.searchBg}
              position="relative"
              alignItems="center"
              mr="10px"
            >
              <InputLeftElement
                mx={2}
                outline="none"
                ml={1}
                transform="translateY(-50%) scale(0.7)"
                w="max"
                justifyContent="end"
                top="50%"
              >
                <SearchIconComponent color="#e2e2e2">
                  <SearchIcon />
                </SearchIconComponent>
              </InputLeftElement>
              <Input
                type="text"
                outline="none"
                _hover={{ borderColor: '#24b552' }}
                _focus={{ borderColor: 'none', boxShadow: 'none' }}
                ref={searchInputRef}
                color={darkMode ? 'white' : 'black'}
                placeholder="Search for Stocks"
                borderWidth="0px"
                size={'sm'}
                onInput={(event) => {
                  const target = event.target;
                  setSearchTerm(target.value);
                }}
              />
            </InputGroup>

            <div
              style={{
                cursor: 'pointer',
                borderWidth: '1px',
                display: 'flex',
                borderColor: darkMode ? '#4adf79' : '#01874e',
                color: darkMode ? '#4adf79' : '#01874e',
                backgroundColor:
                  selectedPresetFromCallback !== ''
                    ? darkMode
                      ? '#4adf7930'
                      : '#e6f8f0'
                    : currentTheme.screener_new.secondaryButtonBg,
                borderRadius: '20px',
                alignItems: 'center',
                padding: '5px',
                paddingLeft: '16px',
                paddingRight: '16px',
                fontWeight: '600',
              }}
              onClick={() => setIsPresetShown(true)}
            >
              <Text>
                {selectedPresetFromCallback === ''
                  ? 'Preset Filters'
                  : selectedPresetFromCallback}
              </Text>

              {selectedPresetFromCallback !== '' && (
                <img
                  src={darkMode ? AppliedPresetDark : AppliedPresetLight}
                  width="25"
                  height="25"
                />
              )}
            </div>

            <div
              style={{
                cursor: 'pointer',
                borderWidth: '1px',
                display: 'flex',
                borderColor: currentTheme.screener_new.buttonBorderColor,
                color: darkMode ? 'black' : 'white',
                backgroundColor: darkMode ? '#4adf79' : '#01874e',
                borderRadius: '20px',
                alignItems: 'center',
                padding: '5px',
                paddingLeft: '16px',
                paddingRight: '16px',
                fontWeight: '600',
              }}
              onClick={() => setIsCustomFilterShown(true)}
            >
              Custom Filter
              <img
                width="30px"
                height="30px"
                src={darkMode ? BlackCustomFilter : WhiteCustomFilter}
              />
            </div>
          </Flex>

          <ClickedOutside
            className="relative hidden"
            onClicked={() => {
              setTableToggle(false);
            }}
          >
            <Flex
              onClick={() => setTableToggle(!tableToggle)}
              alignItems="center"
              cursor="pointer"
            >
              <Text
                color={theme.companiesColor}
                fontSize="small"
                fontWeight="bold"
                mr="2"
              >
                Add Column{' '}
              </Text>
              <ColumnSelector selected={tableToggle}>
                <img src={ColumnSelectorImg} alt="" className="h-6 w-6" />
              </ColumnSelector>
            </Flex>
            {tableToggle ? (
              <TableColumnToggle columns={columns} setColumns={setColumns} />
            ) : null}
          </ClickedOutside>
        </div>

        <div className="mb-4">
          <ScreenerTableRevamp
            updateTable={(table) => setTable(table)}
            columns={columns.filter((column) =>
              tab.type === 'custom' ? column.checked : true
            )}
            darkMode={darkMode}
            searchTerm={searchTerm}
            companiesFoundCallback={(value) => {
              setCompaniesFoundCallback(value.length);
            }}
          />
        </div>
      </div>
      <TableSidebar
        isOpen={isCustomTableSelectorOpen}
        closeModal={() => setCustomTableSelectorOpen(false)}
        styles={{
          content: {
            backgroundColor: darkMode ? '#161616' : '#f4f4f4',
          },
        }}
      >
        <CustomTableColumnSelector
          theme={currentTheme}
          tabs={currentTabs}
          setTabs={onCustomTabsSave}
          closeModal={() => setCustomTableSelectorOpen(false)}
          darkMode={darkMode}
          customTableCallback={customTableAppliedCallback}
        />
      </TableSidebar>

      <TableSidebar
        isOpen={isPresetShown}
        closeModal={() => setIsPresetShown(false)}
      >
        {showPresetMainMenu ? (
          <PresetFiltersMainMenu
            appliedFilters={appliedFilters}
            filterValues={filterValues}
            darkMode={darkMode}
            onCloseModal={() => setIsPresetShown(false)}
            presetCallback={(detailsPage) => {
              setIsDetailsPage(detailsPage);

              if (detailsPage.name) {
                setShowPresetMainMenu(false);
              } else {
                setShowPresetMainMenu(true);
              }
            }}
            applyFilter={(selectedFilters) => {
              setShowFilter(false);
              dispatch(
                updateFilters({
                  ...selectedFilters,
                })
              );
            }}
          />
        ) : (
          <PresetFilters
            appliedFilters={appliedFilters}
            filterValues={filterValues}
            darkMode={darkMode}
            detailsPage={detailsPage}
            selectedPresetCallback={(name) => {
              setSelectedPresetFromCallback(name);
            }}
            presetCallback={(detailsPage) => {
              if (detailsPage.name) {
                setShowPresetMainMenu(false);
              } else {
                setShowPresetMainMenu(true);
              }
            }}
            onCloseModal={() => setIsPresetShown(false)}
            createNewFilter={(value) => {
              setIsPresetShown(false);
              setIsCustomFilterShown(value);
            }}
            applyFilter={(selectedFilters) => {
              setShowFilter(false);
              dispatch(
                updateFilters({
                  ...selectedFilters,
                })
              );
            }}
          />
        )}
      </TableSidebar>
      <TableSidebar
        isOpen={isCustomFilterShown}
        closeModal={() => setIsCustomFilterShown(false)}
      >
        <Container
          className="flex-col h-full overflow-auto flex p-6"
          style={{ backgroundColor: darkMode ? '#161616' : '#f4f4f4' }}
        >
          <div className="justify-between flex-1 flex flex-col">
            <Flex
              className="mt-4 h-7"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header>Filter</Header>
              <Button
                background="transparent"
                color="#FFF"
                borderRadius="50%"
                size="sm"
                border="1px solid"
                borderColor={defaultFiltersThemeObj.searchBorder}
                onClick={() => setIsCustomFilterShown(false)}
              >
                <CloseIcon w={2} h={2} transform="scale(1.2)" />
              </Button>
            </Flex>
            <div className="flex-1">
              <NewFilters
                theme={defaultFiltersThemeObj}
                selectStyles={getSelectStyles(currentTheme.isDark)}
                darkMode={darkMode}
                applyFilter={(selectedFilters) => {
                  setShowFilter(false);
                  dispatch(
                    updateFilters({
                      ...updateFilters,
                      ...selectedFilters,
                    })
                  );
                }}
                getPillFilters={getPillFilters}
                filterValues={{}}
                allSelectedFilters={appliedFilters}
                filterOptions={getFilters(filterValues, 'all')}
                closeModal={() => {
                  setIsCustomFilterShown(false);
                }}
              />
            </div>
          </div>
        </Container>
      </TableSidebar>
    </PageContainer>
  );
}

const mapStateToProps = (state) => ({
  filterValues: state.screenerState.filterValues.data,
  error: state.screenerState.error,
  appliedFilters: state.screenerState.appliedFilters,
  totalPages: state.screenerState.totalPages,
  totalCount: state.screenerState.totalCount,
  loading:
    state.screenerState.pageloading || state.screenerState.filterValues.loading,
  darkMode: state.coreState.darkMode,
  subscriptionStatus: state.coreState.subscriptionStatus,
});

const mapDispatchToProps = {
  fetchFilterValues,
  getScreenerData,
  showPageLoading,
  showErrorAlert,
  updateFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerPage);
