import React from 'react';
import { KeyTextButton } from '../../../StyedComponents';
import InsidersTable from '../../../InsidersTable';
import { holdersColumns as columns } from '../../../InsidersTable/columns';
import InsidersModal from '../../../InsidersModal';
import SectionHeader from '../../../SectionHeader';
import { getPercent } from '../../../../../utils/number';

const FundsShareholders = ({
  darkMode,
  data,
  symbol: { currentPrice, marketCapValue },
}) => {
  const [openInsidersModal, setOpenInsidersModal] = React.useState(false);
  return (
    <>
      <SectionHeader value="Top Funds Shareholders" className="pb-6" />
      <div>
        <div className="flex justify-end mb-2">
          <KeyTextButton onClick={() => setOpenInsidersModal(true)}>
            View All
          </KeyTextButton>
        </div>
        <InsidersTable
          darkMode={darkMode}
          columns={columns}
          data={data
            .filter((d, i) => i < 5)
            .map((d) => ({
              ...d,
              ownership_percent: getPercent(
                d.current_shares / (marketCapValue / currentPrice)
              ),
            }))}
        />
      </div>
      {openInsidersModal && (
        <InsidersModal
          title="Funds Shareholders"
          data={data}
          open={openInsidersModal}
          setOpen={setOpenInsidersModal}
          columns={columns}
        />
      )}
    </>
  );
};

export default FundsShareholders;
