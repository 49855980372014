import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import {
  getAdvancedTimelineChartData,
  getPresentDateForFutureGrowth,
} from '../../../../../utils/ChartUtils';
import AdvancedTimelineChart from '../../../AdvancedTimelineChart';
import SectionHeader from '../../../SectionHeader';
import RiskPositives from '../../../RiskPositives';

const NetIncomeProjection = ({ data, insights }) => {
  const earningsPositives = insights?.earnings?.positives
    ? insights?.earnings?.positives
    : [];
  const earningsGrowthPositives = insights?.earnings_growth?.positives
    ? insights?.earnings_growth?.positives
    : [];
  const earningsNegatives = insights?.earnings?.negatives
    ? insights?.earnings?.negatives
    : [];
  const earningsGrowthNegatives = insights?.earnings_growth?.negatives
    ? insights?.earnings_growth?.negatives
    : [];

  const chartData = getAdvancedTimelineChartData(data, [
    'earnings_average',
    'earnings_low',
    'earnings_high',
    'earnings_actual',
    'evaluated_on',
  ]).map((c) => [c[4], c[3], c[2], c[1], c[0]]);

  return (
    <>
      <SectionHeader
        value="Net Income Projection"
        className="pb-6"
        infoKey="net_income"
      />
      <div style={{ marginTop: '10px' }}>
        <AdvancedTimelineChart
          data={chartData}
          chartVariant="Net Income"
          presentDate={getPresentDateForFutureGrowth(data)}
        />
      </div>

      <RiskPositives
        positives={[...earningsPositives, ...earningsGrowthPositives]}
        negatives={[...earningsNegatives, ...earningsGrowthNegatives]}
      />
    </>
  );
};

export default NetIncomeProjection;
