import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JsxParser from 'react-jsx-parser';
import { components, decorate } from '../../../../shared/BulletPoints';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import { BorderedChartContainer } from '../../../StyedComponents';
import AreaChart from '../../../AreaChart';
import { getAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import RiskPositives from '../../../RiskPositives';

const TTMDividendYield = ({ data, insights }) => {
  const averagePositives = insights?.dividend_yield?.Average?.positives
    ? insights?.dividend_yield?.Average?.positives
    : [];
  const industryPositives = insights?.dividend_yield?.Industry?.positives
    ? insights?.dividend_yield?.Industry?.positives
    : [];
  const marketPositives = insights?.dividend_yield?.Market?.positives
    ? insights?.dividend_yield?.Market?.positives
    : [];
  const averageNegatives = insights?.dividend_yield?.Average?.negatives
    ? insights?.dividend_yield?.Average?.negatives
    : [];
  const industryNegatives = insights?.dividend_yield?.Industry?.negatives
    ? insights?.dividend_yield?.Industry?.negatives
    : [];
  const marketNegatives = insights?.dividend_yield?.Market?.negatives
    ? insights?.dividend_yield?.Market?.negatives
    : [];

  return (
    <>
      <SectionHeader
        value="Dividend Yield(TTM)"
        className="pb-6"
        infoKey="dividend_yield"
      />
      <div style={{ marginTop: '10px' }}>
        <AreaChart
          data={getAreaChartData(data, [
            'dividend_yield_ttm',
            'evaluated_on',
          ]).map((c) => [c[1], c[0]])}
          name="TTM Dividend Yield"
          color="#438DF7"
          gradientColor="#438DF7"
          yAxisLabelSuffix="%"
          valueType="percent"
        />
      </div>
      <RiskPositives
        positives={[
          ...averagePositives,
          ...industryPositives,
          ...marketPositives,
        ]}
        negatives={[
          ...averageNegatives,
          ...industryNegatives,
          ...marketNegatives,
        ]}
      />
    </>
  );
};

export default TTMDividendYield;
