import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import DomainIcon from '../DomainIcon';

const TwButton = styled.button`
  ${tw`font-bold w-16 h-8 focus:outline-none rounded m-1.5 text-xs flex justify-around items-center`}
`;

const PriceGrowthButtonGroup = (props) => {
  const { values, setValues, darkMode } = props;

  const StyledButton = styled(TwButton)`
    width: 40px;
    font-size: 15.5px;
    border-radius: 15px;
    border: 0.1px solid ${(propy) => (propy.selected ? 'white' : 'transparent')};
    background-color: ${(propy) =>
      propy.selected
        ? darkMode
          ? '#434243'
          : '#e6f8f0'
        : !darkMode
        ? '#F4F4F4'
        : '#161616'};
    color: ${(propy) =>
      propy.selected
        ? darkMode
          ? 'white'
          : 'black'
        : !darkMode
        ? 'black'
        : 'white'};
    font-weight: ${(propy) => (propy.selected ? 'bold' : 'bold')};
  `;

  const updateSelected = (index) => {
    const filtered = values.filter((t) => t.selected);
    if (filtered.length === 1 && filtered[0].name === values[index].name) {
      return;
    }

    values[index].selected = !values[index].selected;
    setValues([...values]);
  };

  return (
    <ul className="flex w-full overflow-x-scroll">
      {values.map((v, index) => (
        <li key={index} className="flex">
          <StyledButton
            key={index}
            selected={v.selected}
            onClick={() => updateSelected(index)}
          >
            {v.name}
            {v.selected}
          </StyledButton>
        </li>
      ))}
    </ul>
  );
};

export default PriceGrowthButtonGroup;
