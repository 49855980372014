import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import VerdeScoreSvg from '../../../../public/images/verde-icon.svg';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import DomainTerm from '../../../shared/DomainTerm';
import RatingCode from '../../../shared/RatingCode';
import { useMediaQuery } from '@chakra-ui/react';
import PortfolioPerformance from './PortfolioPerformance';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { filterList } from './FilterList';
import Table from './Table';
import { formatDecimalValue } from '../../../../utils/number';

export default function Overview({
  data,
  darkMode,
  performanceData,
  cik,
  showPortfolioCallback,
}) {
  function bigValueSuffix(x, prefix, suffix) {
    if (Math.abs(x) >= 1000000000000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(
          Math.abs(x) / 1000000000000
        )}T${suffix}`;
      }
      return `${prefix}${formatDecimalValue(
        parseFloat(x / 1000000000000)
      )}T${suffix}`;
    } else if (Math.abs(x) >= 1000000000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(
          Math.abs(x) / 1000000000
        )}B${suffix}`;
      }
      return `${prefix}${formatDecimalValue(x / 1000000000)}B${suffix}`;
    } else if (Math.abs(x) >= 1000000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(
          Math.abs(x) / 1000000
        )}M${suffix}`;
      }

      return `${prefix}${formatDecimalValue(x / 1000000)}M${suffix}`;
    }

    return `${prefix}${x}${suffix}`;
  }

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const [filterSelected, setFilterSelected] = useState(filterList[0]);

  const topList = [
    {
      id: 'Present Value',
      value:
        data['quarters'] &&
        data.quarters.length > 0 &&
        data.quarters[0]['portfolioValue'],
    },
    // {
    //   id: 'divider',
    // },
    // {
    //   id: 'Invested',
    //   value:
    //     data['quarters'] &&
    //     data.quarters.length > 0 &&
    //     data.quarters[0]['investedValue'],
    // },
    // {
    //   id: 'divider',
    // },
    // {
    //   id: 'mobileDivider',
    // },
    // {
    //   id: 'P&L',
    //   value:
    //     data['quarters'] && data.quarters.length > 0 && data.quarters[0]['pl'],
    //   percentVal:
    //     data['quarters'] &&
    //     data.quarters.length > 0 &&
    //     data.quarters[0]['pl_percent'],
    // },
    // {
    //   id: 'divider',
    // },
    {
      id: 'Verde Score',
      value: data.verdeScore,
    },
  ];

  const ratingList = [
    {
      rating: 'Growth Rating',
      title: 'Growth',
      value: data.growthRating,
    },
    {
      rating: 'Safety Rating',
      title: 'Safety',
      value: data.safetyRating,
    },
    {
      rating: 'Value Rating',
      title: 'Value',
      value: data.valueRating,
    },
    {
      rating: 'Momentum Rating',
      title: 'Momentum',
      value: data.momtmRating,
    },
    {
      rating: 'Dividend Rating',
      title: 'Dividend',
      value: data.divRating,
    },
    {
      rating: 'Profitabilty Rating',
      title: 'Profitability',
      value: data.profitRating,
    },
  ];

  const RatingTitle = styled.div`
    ${tw`mt-3 font-medium text-sm`}
    color: ${(props) => props.theme.rating.title};
  `;

  console.log(data);

  const Rating = ({ value, title, darkMode }) => (
    <div
      className="py-2.5 flex flex-col items-center justify-center"
      style={{
        backgroundColor: darkMode ? '#434243' : '#F9F9F9',
        width: '100px',
        marginTop: '1px',
        borderRadius: '5px',
        alignSelf: 'center',
      }}
    >
      <RatingCode rating={value} />
      <RatingTitle>
        <DomainTerm infoKey={`${title.toLowerCase()}_rating`}>
          <span className="whitespace-nowrap">{title}</span>
        </DomainTerm>
      </RatingTitle>
    </div>
  );

  return (
    <div
      style={{
        paddingLeft: isMobile ? 10 : '',
        paddingRight: isMobile ? 10 : '',
      }}
    >
      {isMobile && (
        <div
          style={{ cursor: 'pointer', marginBottom: 10 }}
          onClick={() => showPortfolioCallback()}
        >
          <ChevronLeftIcon
            w="10"
            h="10"
            fontSize="large"
            marginRight={10}
            color={darkMode ? 'white' : 'black'}
          />
        </div>
      )}

      {isMobile && (
        <div
          style={{
            position: 'relative',
            paddingTop: 20,
            paddingBottom: 25,
            borderRadius: 5,
            backgroundColor: darkMode
              ? '#262626'
              : !isMobile
              ? 'white'
              : 'rgb(246,248,250)',
            justifyContent: 'space-between',
            // borderBottom: isMobile
            //   ? ''
            //   : `1px solid ${darkMode ? 'rgb(51,51,51)' : '#8c8c8c'}`,
            width: '100%',
            display: 'flex',
            // gridTemplateColumns: isMobile ? 'auto auto' : null,
          }}
        >
          {topList.map((elem, index) => {
            return elem.id !== 'divider' && elem.id !== 'mobileDivider' ? (
              <div
                style={{
                  textAlign: 'left',
                  paddingLeft: isMobile ? 25 : '',
                  paddingRight: isMobile ? 25 : '',
                  paddingTop: isMobile ? 5 : '',
                  paddingBottom: isMobile ? 5 : '',
                }}
              >
                <Text
                  style={{
                    color: darkMode ? '#cccccc' : '#8c8c8c',
                    marginTop: '10px',
                    fontSize: 18,
                  }}
                >
                  {elem.id}
                </Text>
                {elem.id === 'Verde Score' ? (
                  <Flex
                    // marginTop="10px"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <img
                      src={VerdeScoreSvg}
                      alt=""
                      className="w-5 h-5 mb-0.5 mr-1"
                    />
                    <Text
                      style={{
                        textAlign: 'left',
                        color: darkMode ? '#f6f6f6' : 'black',
                        fontSize: '22px',
                        fontWeight: 'bold',
                      }}
                    >
                      {elem.value}
                    </Text>
                  </Flex>
                ) : elem.id === 'P&L' ? (
                  <div
                    style={{
                      display: isMobile ? 'block' : 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      style={{
                        color: darkMode ? 'white' : 'black',
                        fontSize: isMobile ? 17 : '20px',
                        fontWeight: 'bold',
                        marginRight: '13px',
                      }}
                    >
                      {elem.value < 0
                        ? `-$${
                            elem.value &&
                            bigValueSuffix(elem.value, '', '')
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }`
                        : `+$${
                            elem.value &&
                            bigValueSuffix(elem.value, '', '')
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }`}
                    </Text>

                    <div
                      style={{
                        display: 'flex',
                        borderRadius: '20px',
                        // backgroundColor:
                        //   elem.value > 0
                        //     ? darkMode
                        //       ? '#4adf7920'
                        //       : '#01874e20'
                        //     : darkMode
                        //     ? '#ec5b5520'
                        //     : '#BF2F2C20',
                        paddingRight: 10,
                        paddingLeft: 10,
                        alignItems: 'center',
                      }}
                    >
                      {elem.value > 0 ? (
                        <TriangleUpIcon
                          color={darkMode ? '#4adf79' : '#01874e'}
                        ></TriangleUpIcon>
                      ) : (
                        <TriangleDownIcon
                          color={darkMode ? '#ec5b55' : '#BF2F2C'}
                        ></TriangleDownIcon>
                      )}

                      <Text
                        style={{
                          color:
                            elem.value > 0
                              ? darkMode
                                ? '#4adf79'
                                : '#01874e'
                              : darkMode
                              ? '#ec5b55'
                              : '#BF2F2C',
                          marginLeft: '10px',
                          fontWeight: '600',
                          fontSize: isMobile ? 14 : 15,
                        }}
                      >
                        {elem.val < 0
                          ? `+${formatDecimalValue(elem.percentVal)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                          : `${formatDecimalValue(elem.percentVal)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                        %
                      </Text>
                    </div>
                  </div>
                ) : (
                  <Text
                    style={{
                      color:
                        elem.id === 'P&L'
                          ? elem.value > 0
                            ? '#36A867'
                            : 'rgb(190,46,44)'
                          : darkMode
                          ? 'white'
                          : 'black',
                      fontSize: '22px',
                      fontWeight: 'bold',
                    }}
                  >
                    {`$${
                      elem.value &&
                      bigValueSuffix(elem.value, '', '')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }`}
                  </Text>
                )}
              </div>
            ) : elem.id === 'mobileDivider' ? (
              isMobile && (
                <div
                  style={{
                    position: 'absolute',
                    width: '80%',
                    left: '10%',
                    top: '46%',
                    height: 0,
                    border: `0.5px solid ${
                      darkMode ? 'rgb(51,51,51)' : '#8c8c8c'
                    }`,
                  }}
                />
              )
            ) : (
              !isMobile && (
                <div
                  style={{
                    height: '70px',
                    width: '0px',
                    marginTop: '5px',
                    border: `0.5px solid ${
                      darkMode ? 'rgb(51,51,51)' : '#8c8c8c'
                    }`,
                    borderWidth: '0.5px',
                  }}
                />
              )
            );
          })}
        </div>
      )}

      <div
        style={{
          display: isMobile ? 'block' : 'block',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          justifyContent: 'flex-start',
          backgroundColor: darkMode
            ? isMobile
              ? 'transparent'
              : '#262626'
            : isMobile
            ? 'transparent'
            : 'white',
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        {isMobile && (
          <div
            style={{
              borderRadius: '5px',
              backgroundColor: darkMode
                ? isMobile
                  ? 'transparent'
                  : '#262626'
                : isMobile
                ? 'transparent'
                : 'white',
              padding: !isMobile ? '15px' : '20px',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                color: darkMode ? '#f6f6f6' : 'black',
                fontSize: '20px',
                marginLeft: isMobile ? '0px' : '30px',
                fontWeight: 'bold',
                marginBottom: 7,
              }}
            >
              Valuation Ratings
            </Text>

            <Flex overflowX="scroll">
              {ratingList.map((elem) => {
                return (
                  <div
                    style={{
                      marginBottom: isMobile ? '20' : 10,
                      marginRight: 30,
                    }}
                  >
                    <Rating
                      value={elem.value}
                      title={elem.title}
                      darkMode={darkMode}
                    />
                  </div>
                );
              })}
            </Flex>
          </div>
        )}

        {/* {!isMobile && (
          <div
            style={{
              height: 'auto',
              width: '0px',
              marginBottom: 60,
              marginTop: 30,

              border: isMobile
                ? ''
                : `0.5px solid ${darkMode ? 'rgb(51,51,51)' : '#8c8c8c'}`,
              borderWidth: 0.5,
            }}
          />
        )} */}

        {/* {!isMobile && (
          <div
            style={{
              borderRadius: '5px',
              backgroundColor: darkMode ? '#262626' : 'white',
              padding: '50px',
              width: '100%',
              marginBottom: '25px',
            }}
          >
            <Text
              style={{
                color: darkMode ? '#f6f6f6' : 'black',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Portfolio Performance
            </Text>
            {performanceData[0] && (
              <PortfolioPerformance
                darkMode={darkMode}
                data={performanceData[0].quarters}
                valuationData={[]}
              />
            )}
          </div>
        )} */}
      </div>

      <div
        style={{
          borderRadius: '5px',
          backgroundColor: darkMode ? '#262626' : 'white',
          padding: '30px',
          width: '100%',
          marginBottom: '25px',
        }}
      >
        <div style={{ display: 'flex', overflowX: 'scroll', marginBottom: 25 }}>
          {filterList.map((elem) => {
            return (
              <div
                style={
                  {
                    // borderRight: '1px solid rgba(140, 140, 140, 0.5)',
                    // padding: '5px',
                    // paddingLeft: '5px',
                    // paddingRight: '25px',
                    // marginLeft: '5px',
                    // marginRight: '10px',
                    // cursor: 'pointer',
                  }
                }
                onClick={() => setFilterSelected(elem)}
              >
                <Text
                  style={{
                    fontWeight:
                      filterSelected.id === elem.id ? '549' : 'normal',

                    color: darkMode ? '#f8f8f8' : '#343434',
                    // filterSelected.id === elem.id
                    //   ? darkMode
                    //     ? '#4adf79'
                    //     : '#01874e'
                    //   : darkMode
                    //   ? '#f8f8f8'
                    //   : '#343434',

                    fontSize: filterSelected.id === elem.id ? 18 : 16,
                  }}
                >
                  {elem.id}
                </Text>
              </div>
            );
          })}
        </div>

        <Table
          darkMode={darkMode}
          data={
            data['quarters'] &&
            data.quarters.length > 0 &&
            data.quarters[0]['holdings']
          }
          headerData={filterSelected.tableHeader}
          cik={cik}
        />
      </div>
    </div>
  );
}
