import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { MONTHS } from '../../../utils/ChartUtils';

Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B'],
  },
});

const LineChart = ({ data, seriesType }) => {
  const options = {
    chart: {
      type: 'line',
      style: {
        fontFamily: 'Roboto',
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontWeight: 500,
          color: '#9E9E9E',
        },
      },
      //tickInterval: 24 * 3600 * 1000 * 365,
      dateTimeLabelFormats: {
        day: '%e%b%y',
        month: '%b %Y',
      },
      crosshair: {
        enabled: true,
        width: 25 * 2,
        color: '#F5F5F5',
      },
      tickLength: 0,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          color: '#9E9E9E',
          fontWeight: 500,
        },
        formatter: function () {
          return this.isFirst || this.isLast
            ? '$' + this.axis.defaultLabelFormatter.call(this)
            : '';
        },
      },
      stackLabels: {
        enabled: true,
        align: 'center',
      },
    },
    /* plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: false,
        },
      },
    }, */
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          style: {
            color: '#9E9E9E',
            fontWeight: 'bolder',
          },
          formatter: function () {
            return '$' + this.y;
          },
        },
        enableMouseTracking: false,
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shape: 'callout',
      shadow: false,
      useHTML: true,
      backgroundColor: '#F6FDFF',
      formatter: function () {
        const points = this.points;
        let currentDate =
          MONTHS[new Date(this.x).getMonth()] +
          ' ' +
          new Date(
            new Date(this.x).getFullYear(),
            new Date(this.x).getMonth() + 1,
            0
          ).getDate() +
          ', ' +
          new Date(this.x).getFullYear();

        let tooltips = [];
        tooltips = tooltips.concat(
          '<div style="position:relative;height:100%;padding:5px"> ' +
            '<div>' +
            currentDate +
            '</div>' +
            '<div style="width:70%;float:left;">'
        );
        tooltips = tooltips.concat(
          points.map((v, i) => {
            return (
              '<div style="padding-bottom:2px">' +
              '<span style="font-size:16px;color:' +
              v.color +
              '">\u25CF</span>' +
              ' ' +
              '<span style="color:#686868;font-size:0.7rem;font-weight:500">' +
              ' &ensp;' +
              v.series.name +
              '</span>' +
              '<br> <span style="color:#3A3A3A;font-size: 0.8rem;font-weight:500">&emsp;&nbsp;&nbsp;$' +
              v.y.toFixed(1)
            );
          })
        );

        return tooltips;
      },
      shared: true,
    },
    legend: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            yAxis: {
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#9E9E9E',
                  fontWeight: 500,
                },
                useHTML: true,
                formatter: function () {
                  return this.isFirst || this.isLast
                    ? '$' + this.axis.defaultLabelFormatter.call(this)
                    : '';
                },
              },
            },
            xAxis: {
              type: 'datetime',
              tickInterval: 31536000000,
              dateTimeLabelFormat: '%b %Y',
              labels: {
                style: {
                  fontWeight: 500,
                  color: '#9E9E9E',
                },
              },
              //tickInterval: 24 * 3600 * 1000 * 365,
              crosshair: {
                enabled: true,
                width: 25 * 2,
                color: '#F5F5F5',
              },
            },
            chart: {
              height: 300,
              style: {
                fontFamily: 'Roboto',
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: 'Earnings Per Share',
        data: data,
        type: seriesType,
        marker: {
          enabled: true,
          symbol: 'circle',
        },
        color: '#319AA7',
      },
      /* {
        name: 'Sub Industry',
        data: subIndustryData,
        type: 'spline',
        marker: {
          enabled: false,
          symbol: 'circle',
        },
        fill: '#3E2ABE',
        color: '#3E2ABE',
        fillOpacity: 0.4,
        dashStyle: 'ShortDash',
      }, */
    ],
  };

  return (
    <div className="text-xs font-semibold w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default LineChart;
