import React from 'react';
import Modal from 'react-modal';
import { Header, Container } from './style';
import { CloseIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { Box, Flex } from '@chakra-ui/layout';

const modalStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    bottom: '0',
    left: 'auto',
    right: '0',
    height: 'auto',
    maxWidth: '450px',
    padding: '0px',
    top: '50px',
    width: '100%',
    border: 0,
  },
};

const FilterModal = ({ open, setOpen, children, theme }) => {
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal isOpen={open} onRequestClose={closeModal} style={modalStyles}>
      <Container className="flex-col h-full overflow-auto flex p-6">
        <div className="justify-between flex-1 flex flex-col">
          <Flex
            className="mt-4 h-7"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header>Filter</Header>
            <Button
              background="transparent"
              color="#FFF"
              borderRadius="50%"
              size="sm"
              border="1px solid"
              borderColor={theme.filters.searchBorder}
              onClick={closeModal}
            >
              <CloseIcon w={2} h={2} transform="scale(1.2)" />
            </Button>
          </Flex>
          <div className="flex-1">{children}</div>
        </div>
      </Container>
    </Modal>
  );
};

export default FilterModal;
