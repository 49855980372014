import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw from 'twin.macro';

export const SymbolSearchWrapper = styled.div`
  background-color: ${(props) => props.theme.content.background};
`;

export const StyledInput = styled.input`
  ${tw`rounded py-2 pl-4 md:pr-10 pr-8 text-sm md:w-11/12 w-full focus:outline-none focus:shadow-md h-12 relative`}
  background-color: ${(props) => props.theme.content.background};
  border: solid 1px ${(props) => props.theme.sectionBorder};
  color: ${(props) => props.theme.verdeHeader.searchInputColor};
`;

export const SearchButton = styled.button`
  ${tw`focus:outline-none text-lg md:relative absolute md:top-4 top-6 md:right-8 right-4 text-opacity-20`}
  color: ${(props) => props.theme.verdeHeader.searchButtonColor};
`;
export const StyledFontAwesomeSearchIcon = styled(FontAwesomeIcon)`
  ${tw`relative bottom-3 h-6`};
  color: ${(props) => props.theme.content.color};
`;

export const CompanyDivider = styled.div`
  ${tw`w-full my-4 h-0`};
  border-top: solid 1px ${(props) => props.theme.similarCompanies.divider};
`;

export const SearchItem = styled.div`
  ${tw`text-sm font-medium`};
  &:hover {
    color: ${(props) => props.theme.keyHighlight};
  }
`;
