import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import {
  BorderedContentWrapper,
  Highlight,
  HollowGreenButton,
  BorderedSection,
  ColoredContentText,
  KeyTextButton,
} from '../../StyedComponents';
import PersonCard from '../../PersonCard';
import Modal from 'react-modal';

export const FollowButton = styled(HollowGreenButton)`
  ${tw`py-2 px-8`}
`;

export const ViewAllButton = styled(HollowGreenButton)`
  ${tw`py-3 w-full border-0`}
`;

export const Header = styled(ColoredContentText)`
  ${tw`text-lg font-medium`};
`;

export const ActionButton = styled(HollowGreenButton)`
  ${tw`py-2 px-8 mx-2`}
`;

const modalStyles = {
  overlay: {
    top: '20px',
  },
  content: {
    bottom: '0',
    left: 'auto',
    right: '0',
    height: 'auto',
    maxWidth: '450px',
    padding: '0px',
    top: '50px',
    width: '100%',
  },
};

const people = Array.from(Array(4).keys());

const PeopleToFollow = () => {
  const [follow, setFollow] = React.useState(false);
  return (
    <div className="flex mt-4 justify-between">
      <PersonCard name="Roger sinstsinst sinstsinst" followers={25} />
      <div className="text-center w-1/3">
        {follow ? (
          <KeyTextButton
            className="text-sm py-2"
            onClick={() => setFollow(false)}
          >
            Following
          </KeyTextButton>
        ) : (
          <FollowButton onClick={() => setFollow(true)}>Follow</FollowButton>
        )}
      </div>
    </div>
  );
};

const PeopleYouMayKnow = () => {
  const [open, setOpen] = React.useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <BorderedContentWrapper className="my-8 rounded">
      <Highlight className="font-medium">People you may know</Highlight>
      <div className="my-8">
        {people.map((person, index) => (
          <PeopleToFollow key={index} />
        ))}
      </div>
      <BorderedSection className="rounded">
        <ViewAllButton onClick={() => setOpen(true)}>View All</ViewAllButton>
      </BorderedSection>
      <Modal isOpen={open} onRequestClose={closeModal} style={modalStyles}>
        <div className="flex-col h-full overflow-hidden">
          <div className="p-5">
            <div className="flex justify-between py-4 mt-4">
              <Header>People you may know</Header>
            </div>
            <div className="overflow-auto">
              <div className="my-4">
                {people.map((person, index) => (
                  <PeopleToFollow key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </BorderedContentWrapper>
  );
};

export default PeopleYouMayKnow;
