import React from 'react';
import { Link } from 'react-router-dom';
import RatingCode from '../RatingCode';
import { ColoredContentText, Subtle } from '../StyedComponents';
import { StyledTableFirst, StyledTableHead, TableColumn } from './style';
import Tooltip from './tooltip';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import SymbolLogo from '../SymbolLogo';
import { Text } from '@chakra-ui/layout';

export const ColorCode = ({ value, suffix = '%' }) => (
  <Text color={value > 0 ? '#24b552' : '#e01d18'}>
    {value}
    {value !== '' ? suffix : ''}
  </Text>
);

export const defaultColumns = [
  {
    Header: 'Company',
    sticky: 'left',
    id: 'company',
    accessor: (properties) => ({
      symbol: properties.symbol,
      symbolMarket: properties.symbolMarket,
      name: properties.name,
      logoUrl: properties.logoUrl,
    }),
    Cell: ({ value: { symbol, symbolMarket, name, logoUrl } }) => {
      return (
        <StyledTableFirst>
          <TableColumn style={{ justifyContent: 'flex-start' }}>
            <Link
              to={`/stocks/${symbolMarket}`}
              className="no-underline text-black"
            >
              <div
                className="flex text-left"
                style={{ marginLeft: -14.5, paddingLeft: 5 }}
              >
                <SymbolLogo logoUrl={logoUrl} />
                <div className="flex flex-col ml-4 justify-evenly">
                  <Text className="font-medium">{symbol}</Text>

                  <Tooltip
                    position="top"
                    arrow="center"
                    parent="#text"
                    tooltip={name}
                    id={symbol}
                  >
                    <Subtle className="text-xs break-words truncate w-24">
                      {name}
                    </Subtle>
                  </Tooltip>
                </div>
              </div>
            </Link>
          </TableColumn>
        </StyledTableFirst>
      );
    },
  },
  {
    Header: 'Verde Score',
    accessor: 'verdeScore',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <img src={VerdeScoreSvg} alt="" className="w-3 mr-1" />
        {row.value ? row.value : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Sector',
    accessor: 'sector',
    Cell: (row) => (
      <TableColumn
        style={{
          display: 'block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '140px',
          overflow: 'hidden',
        }}
      >
        {row.value ? row.value : ''}
      </TableColumn>
    ),
  },

  {
    Header: 'Market Cap',
    accessor: 'marketCap',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">{row.value ? row.value : ''}</TableColumn>
    ),
  },

  {
    Header: 'P/E (TTM)',
    accessor: 'priceToEarnings',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Fair Price ($)',
    accessor: 'fairPrice',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `$${parseFloat(row.value).toFixed(2)}` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'EV/EBITDA (TTM)',
    accessor: 'evEbita',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${parseFloat(row.value).toFixed(2)}` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'EV/EBIT (TTM)',
    accessor: 'evEbit',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${parseFloat(row.value).toFixed(2)}` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Analyst Rating',
    accessor: 'analystRating',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },
  {
    Header: '% Upside',
    accessor: 'fairPriceUpside',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {' '}
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },

  {
    Header: '% Upside',
    accessor: 'targetPriceUpside',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {' '}
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Target Price ($)',
    accessor: 'targetPrice',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `$${parseFloat(row.value).toFixed(2)}` : ''}
      </TableColumn>
    ),
  },

  {
    Header: 'Current Price ($)',
    accessor: 'currentPrice',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `$${parseFloat(row.value).toFixed(2)}` : ''}
      </TableColumn>
    ),
  },
  {
    Header: '1D Return (%)',
    accessor: 'oneDay',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'YTD Return (%)',
    accessor: 'ytd',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
];

export const additionalColumns = [
  {
    Header: '1W Return %',
    accessor: 'oneWeek',
    Cell: (row) => (
      <TableColumn className="w-14">
        {' '}
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Overvalued/Undervalued %',
    accessor: 'percentChangeInPrice',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Growth Rating',
    accessor: 'growthRating',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right" className="flex justify-center">
        <RatingCode rating={row.value ? row.value : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Value Rating',
    accessor: 'valueRating',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right" className="flex justify-center">
        <RatingCode rating={row.value ? row.value : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Safety Rating',
    accessor: 'safetyRating',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right" className="flex justify-center">
        <RatingCode rating={row.value ? row.value : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Dividend Rating',
    accessor: 'dividendRating',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right" className="flex justify-center">
        <RatingCode rating={row.value ? row.value : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Momentum Rating',
    accessor: 'momentumRating',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right" className="flex justify-center">
        <RatingCode rating={row.value ? row.value : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Dividend Yield',
    accessor: 'forwardDividendRate',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">{row.value ? row.value : ''}</TableColumn>
    ),
  },
  {
    Header: 'Industry',
    accessor: 'industry',
    Cell: (row) => (
      <TableColumn
        style={{
          display: 'block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          inlineSize: '140px',
          overflow: 'hidden',
          justifyContent: 'flex-start',
        }}
      >
        {row.value ? row.value : ''}
      </TableColumn>
    ),
  },

  {
    Header: '6M Return %',
    accessor: 'sixMonths',
    Cell: (row) => (
      <TableColumn className="w-14">
        {' '}
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
  {
    Header: '1M Return %',
    accessor: 'oneMonth',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
  {
    Header: '1Y Return %',
    accessor: 'oneYear',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
  {
    Header: '5Y Return %',
    accessor: 'fiveYears',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Enterprise Value',
    accessor: 'enterPrise',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">{row.value ? row.value : ''}</TableColumn>
    ),
  },
  {
    Header: 'P/E (FWD)',
    accessor: 'priceToEarningsFWD',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'PEG Ratio',
    accessor: 'pegRatio',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Price To Sale',
    accessor: 'priceToSale',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },

  {
    Header: 'EV_Sales(TTM)',
    accessor: 'evRevenue',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },

  {
    Header: 'Price To Book',
    accessor: 'priceToBook',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Gross Margin',
    accessor: 'grossMargin',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${(parseFloat(row.value) * 100).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Net Margin',
    accessor: 'netMargin',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${(parseFloat(row.value) * 100).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Operating Margin',
    accessor: 'operatingMargin',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${(parseFloat(row.value) * 100).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Revenue Growth (TTM)',
    accessor: 'revenueGrowth',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${parseFloat(row.value).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Revenue Growth (FWD)',
    accessor: 'revenueGrowthFWD',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Revenue Growth (FY1)',
    accessor: 'revenueGrowthFY1',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${parseFloat(row.value).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Revenue Growth (FY2)',
    accessor: 'revenueGrowthFY2',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${parseFloat(row.value).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'EPS Growth (TTM)',
    accessor: 'epsGrowth',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${parseFloat(row.value).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'EPS Growth (FWD)',
    accessor: 'epsGrowthFWD',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'EPS Growth (FY1)',
    accessor: 'epsGrowthFY1',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${parseFloat(row.value).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'EPS Growth (FY2)',
    accessor: 'epsGrowthFY2',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${parseFloat(row.value).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: '3M Return %',
    accessor: 'threeMonth',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Profitability Rating',
    accessor: 'profitabilityRating',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <RatingCode rating={row.value ? row.value : ''} />
      </TableColumn>
    ),
  },
  {
    Header: 'Net Income Margin',
    accessor: 'netIncomeMargin',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? parseFloat(row.value).toFixed(2) : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Return on Assets',
    accessor: 'returnOnAssets',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${(parseFloat(row.value) * 100).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Return on Equity',
    accessor: 'returnonEquity',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${(parseFloat(row.value) * 100).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Return on Investment Capital',
    accessor: 'returnOnInvestmentCapital',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${(parseFloat(row.value) * 100).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Debt/Equity',
    accessor: 'debtEquity',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${(parseFloat(row.value) * 100).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: 'Debt/Capital',
    accessor: 'debtCapital',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        {row.value ? `${(parseFloat(row.value) * 100).toFixed(2)}%` : ''}
      </TableColumn>
    ),
  },
  {
    Header: '3Y Return %',
    accessor: 'threeYears',
    align: 'right',
    Cell: (row) => (
      <TableColumn align="right">
        <ColorCode value={row.value ? parseFloat(row.value).toFixed(2) : ''} />
      </TableColumn>
    ),
  },
];
