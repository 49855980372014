import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsTreemap from 'highcharts/modules/treemap';
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';

HighchartsHeatmap(Highcharts);
HighchartsTreemap(Highcharts);

const getColorValue = (value) => {
  if (value > 25) return '#00994d';
  else if (value > 20 && value <= 25) return '#00b359';
  else if (value > 15 && value <= 20) return '#00cc66';
  else if (value > 10 && value <= 15) return '#00e673';
  else if (value > 5 && value <= 10) return '#00ff80';
  else if (value > 0 && value <= 5) return '#4dffa6';
  else if (value > -5 && value <= 0) return '#ff6666';
  else if (value > -10 && value <= -5) return '#ff4d4d';
  else if (value > -15 && value <= -10) return '#ff3333';
  else if (value > -20 && value <= -15) return '#ff1a1a';
  else if (value > -25 && value <= -20) return '#ff0000';
  else if (value <= -25) return '#e60000';

  return '#00994d';
};

const HeatMapChart = ({ data, darkMode }) => {
  const chartData = Object.keys(
    data?.individual_returns ? data?.individual_returns : []
  ).map((key) => ({
    name: data.individual_returns[key].stock_name,
    realValue: data.individual_returns[key].return_perc,
    value: Math.abs(data.individual_returns[key].market_value),
    color: getColorValue(data.individual_returns[key].return_perc),
  }));

  const options = useMemo(() => {
    const option = {
      chart: {
        backgroundColor: darkMode ? 'transparent' : '#ffffff',
        style: {
          fontFamily: 'Roboto',
        },
        height: '660',
      },
      title: {
        text: '',
      },
      colorAxis: null,
      tooltip: {
        valueDecimals: 2,
        hideDelay: 600,
        shadow: false,
        useHTML: true,
        borderRadius: 8,
        //followPointer: true,
        shared: true,
        backgroundColor: darkMode ? '#141920' : '#fff',
        borderColor: darkMode ? '#86efaf' : '#5bae7f',
        formatter: function () {
          const point = this.point;
          const valueColor = darkMode ? '#86efaf' : '#5bae7f';
          const nameColor = darkMode ? '#d8d9e4' : '#686868';
          return ` <div>
              <div style="color:${nameColor}">${point.name}</div>
              <div style="color:${valueColor}">${point.realValue}%</div>
              </div>`;
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          clip: false,
          data: chartData,
          colorByPoint: true,
          borderWidth: 1,
          borderColor: '#161616',
          dataLabels: {
            formatter: function () {
              return `<div class="flex flex-col">
                <div>${this.point.name}</div>
                <div>${this.point.realValue}%</div>
                </div>`;
            },
            useHTML: true,
            color: '#161616',
          },
        },
      ],
    };

    return option;
  }, [chartData, darkMode]);

  return (
    <div className="text-xs font-semibold">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(HeatMapChart);
