import React from 'react';
import PageContainer from '../../shared/PageContainer';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import MyInvestments from '../../shared/Sections/MyInvestments';
import {
  ColoredContentText,
  ContentWrapper,
  StyledTabList,
  Subtle,
  GreenText,
  VerticalLine,
} from '../../shared/StyedComponents';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import PeopleYouMayKnow from '../../shared/Sections/PeopleYouMayKnow';
import ActivityFeed from '../../tabs/portfolio/activity';
import NewsFeed from '../../tabs/portfolio/news';
import Leaderboard from '../../tabs/portfolio/leaderboard';
import Portfolio from '../../tabs/portfolio/portfolio';
import DefaultProfilePicture from '../../../public/images/default-profile-picture.jpg';
import FacebookSvg from '../../../public/images/socialmedia/facebook.svg';
import InstagramSvg from '../../../public/images/socialmedia/instagram.svg';
import LinkedinSvg from '../../../public/images/socialmedia/linkedin.svg';
import DiscordSvg from '../../../public/images/socialmedia/discord.svg';

export const PageInfoSpan = styled.span`
  ${tw`font-medium ml-2`};
  color: ${(props) => props.theme.content.color};
`;

const portfolioTabs = [
  { title: 'Portfolio', comp: <Portfolio self={false} /> },
  { title: 'Activity Feeds', comp: <ActivityFeed self={false} /> },
  { title: 'News', comp: <NewsFeed /> },
  { title: 'Leaderboard', comp: <Leaderboard /> },
];

const myPortfolioTabs = [
  { title: 'Portfolio', comp: <Portfolio self={true} /> },
  { title: 'Activity Feeds', comp: <ActivityFeed self={true} /> },
];

const socialMediaLinks = [FacebookSvg, LinkedinSvg, InstagramSvg, DiscordSvg];

const PortfolioPage = (props) => {
  const tabsData = props.self ? myPortfolioTabs : portfolioTabs;
  return (
    <PageContainer>
      <div className="block md:hidden">
        <MyInvestments />
      </div>
      <div className="grid grid-cols-3 gap-6 md:py-2">
        <div className="col-span-3 md:col-span-2">
          <div className="hidden md:block my-4">
            <FontAwesomeIcon icon={faArrowLeft} />
            <PageInfoSpan>Portfolio Page</PageInfoSpan>
          </div>
          {props.self && (
            <ContentWrapper className="flex justify-between -mb-4">
              <div className="flex">
                <img
                  className="w-14 h-14 mr-4"
                  src={DefaultProfilePicture}
                  alt=""
                />
                <div className="flex-col my-auto">
                  <ColoredContentText className="text-sm font-medium mb-2">
                    Marian Ross
                  </ColoredContentText>
                  <div className="flex">
                    {socialMediaLinks.map((icon) => (
                      <img
                        className="w-5 h-5 mr-2 cursor-pointer"
                        src={icon}
                        alt=""
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex justify-around font-medium mt-2 text-sm">
                <div className="text-center">
                  <Subtle>following</Subtle>
                  <GreenText>230</GreenText>
                </div>
                <VerticalLine />
                <div className="text-center">
                  <Subtle>followers</Subtle>
                  <GreenText>230</GreenText>
                </div>
              </div>
            </ContentWrapper>
          )}
          <Tabs className="my-4 rounded">
            <div className="sticky top-16 md:top-20 z-10">
              <StyledTabList className="hide-scrollbar">
                {tabsData.map(({ title }, index) => {
                  return <Tab key={index}>{title}</Tab>;
                })}
              </StyledTabList>
            </div>
            <ContentWrapper className="overflow-x-scroll">
              {tabsData.map(({ comp }, index) => {
                return <TabPanel key={index}>{comp}</TabPanel>;
              })}
            </ContentWrapper>
          </Tabs>
        </div>
        <div className="hidden md:block">
          <div className="sticky top-24">
            <MyInvestments />
            <PeopleYouMayKnow />
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <PeopleYouMayKnow />
      </div>
    </PageContainer>
  );
};

export default PortfolioPage;
