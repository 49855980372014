import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useState, FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { showPageLoading } from '../../../../../actions/coreAction';
import PageContainer from '../../../../shared/PageContainer';
import { useMediaQuery } from '@chakra-ui/react';
import WrappedAxios from '../../../../../utils/WrappedAxios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import SymbolLogo from '../../../../shared/SymbolLogo';
import { ArrowBackIcon } from '@chakra-ui/icons';
import DomainIcon from '../../../../shared/DomainIcon';
import HistoryTable from './HistoryTable';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { SUPER_INVESTOR_URL } from '../../../../../config/default';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from '../../../../shared/LoadingIndicator';

const PageContainerW = PageContainer;

function SymbolHistory({ darkMode, symbol, cik, closeHistory }) {
  const [data, setData] = useState([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams();

  const headerData = [
    { id: 'Period', key: 'quarterName', upDownIcon: false },
    {
      id: 'Shares',
      key: 'currentShares',
      prefix: '',
      suffix: '',
      upDownIcon: true,
      ascend: false,
      descend: false,
    },
    {
      id: 'Activity',
      key: 'activity',
      prefix: '',
      suffix: '',
      upDownIcon: false,
    },
    {
      id: '% of Portfolio',
      key: 'portfolioPercent',
      upDownIcon: true,
      ascend: false,
      descend: false,
      prefix: '',
      suffix: '%',
    },
    {
      id: '% change to portfolio',
      key: 'changePercent',
      upDownIcon: true,
      ascend: false,
      descend: false,
      prefix: '',
      suffix: '%',
    },
    {
      id: 'Reported Price',
      key: 'reportedPrice',
      upDownIcon: true,
      ascend: false,
      descend: false,
      prefix: '$',
      suffix: '',
    },
  ];

  useEffect(() => {
    async function antiPattern() {
      setData(
        (
          await WrappedAxios.get(
            `${SUPER_INVESTOR_URL}/get-superinvestor-holding/${cik}/${symbol}`
          )
        ).data
      );
    }

    antiPattern();
  }, [cik, symbol]);

  return (
    <>
      <Box
        padding={10}
        paddingTop={2}
        backgroundColor={darkMode ? '#262626' : 'white'}
      >
        <Text
          color={darkMode ? 'white' : 'black'}
          style={{
            textDecoration: 'underline',
            textUnderlineOffset: '3px',
            fontSize: 18,
            marginBottom: 15,
          }}
        >
          Activity
        </Text>

        <Flex alignItems="center" justifyContent={'flex-start'} marginLeft={10}>
          {/* <ChevronLeftIcon
                w="8"
                h="8"
                fontSize="large"
                color={darkMode ? 'white' : 'black'}
              /> */}
          <img
            style={{
              width: 40,
              height: 40,
            }}
            src={`http://images.verde.finance/${symbol.split('.')[0]}.png`}
          />
          <Text
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: 20,
              marginLeft: 10,
            }}
          >
            {symbol.slice(0, symbol.indexOf('.'))}
          </Text>

          <Box
            style={{ marginLeft: 'auto', marginRight: 10, cursor: 'pointer' }}
            onClick={() => closeHistory()}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="lg"
              color={darkMode ? '#f4f4f4' : 'gray'}
            />
          </Box>
        </Flex>

        <Box marginTop={5}>
          {data.length === 0 ? (
            <LoadingIndicator />
          ) : (
            <HistoryTable
              darkMode={darkMode}
              headerData={headerData}
              data={data}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolHistory);
