import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  buildTooltipByValue,
  getFormattedCurrencyValueForTooltip,
  getTooltipDate,
} from '../../../utils/ChartUtils';

const BarChart = ({
  data,
  name,
  color,
  barColor,
  yAxisLabelPrefix = '',
  yAxisLabelSuffix = '',
  darkMode,
}) => {
  function generateTooltip(_this, mobileMode) {
    const points = _this.points;
    let tooltips = '';
    const padding = mobileMode ? 3 : 5;
    tooltips = tooltips.concat(
      '<div style="position:relative;height:100%;padding:' + padding + 'px;"> '
    );
    const point = points[0];

    tooltips = tooltips.concat(
      buildTooltipByValue(
        getFormattedCurrencyValueForTooltip(point.y),
        color,
        name,
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(getTooltipDate(_this.x, darkMode, mobileMode));
    return tooltips;
  }

  const options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Roboto',
      },
      backgroundColor: darkMode ? 'transparent' : '#ffffff',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      //categories: data.map((d) => d[0]),
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      dateTimeLabelFormats: {
        day: '%e%b%y',
        month: '%b %Y',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
        formatter: function () {
          return (
            yAxisLabelPrefix +
            this.axis.defaultLabelFormatter.call(this) +
            yAxisLabelSuffix
          );
        },
      },
      gridLineColor: darkMode ? '#3b404f' : '#e6e6e6',
    },
    plotOptions: {
      series: {
        //pointWidth: 40,
        borderRadius: 4,
        lineColor: 'transparent',
        borderWidth: 0,
        /* dataLabels: {
          useHTML: true,
          enabled: true,
          format:
            ' <div><div style="font-size: 12px;font-weight:500;font-family:Roboto;">$ {y} </div> </div>',
        }, */
      },
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shadow: false,
      useHTML: true,
      backgroundColor: darkMode ? '#141920' : '#fff',
      borderColor: darkMode ? '#86efaf' : '#5bae7f',
      borderRadius: 8,
      formatter: function () {
        return generateTooltip(this, false);
      },
      shared: true,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            plotOptions: {
              series: {
                //pointWidth: 30,
              },
            },
            tooltip: {
              formatter: function () {
                return generateTooltip(this, true);
              },
            },
            chart: {
              height: 400,
            },
          },
        },
      ],
    },
    series: [
      {
        name,
        data: data,
        type: 'column',
        threshold: null,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: darkMode
            ? [
                [0, 'rgba(123,191,255,1)'],
                [1, 'rgba(123,191,255,1)'],
              ]
            : [
                [0, 'rgba(67, 141, 247)'],
                [1, 'rgba(67, 141, 247)'],
              ],
        },
        fillOpacity: 0.3,
      },
    ],
  };

  return (
    <div className="text-xs font-semibold w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(BarChart);
