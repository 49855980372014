import { Box } from '@chakra-ui/layout';
import React, { useState } from 'react';
import ToolTip from 'react-portal-tooltip';
import { useAppSelector } from '../../../hooks/store';

export default function Tooltip({ children, tooltip, id }) {
  const [isTooltipActive, setTooltipActive] = useState(false);
  const darkMode = useAppSelector((state) => state.coreState.darkMode);
  return (
    <Box>
      <p
        id={id}
        onMouseEnter={() => setTooltipActive(true)}
        onMouseLeave={() => setTooltipActive(false)}
      >
        {children}
      </p>

      <ToolTip
        active={isTooltipActive}
        style={{
          style: {
            backgroundColor: darkMode ? '#444955' : '#32363f',
            color: 'white',
            fontSize: '12px',
          },
          arrowStyle: { color: darkMode ? '#444955' : '#32363f' },
        }}
        position="top"
        arrow="center"
        parent={`#${id}`}
      >
        <div>
          <p>{tooltip}</p>
        </div>
      </ToolTip>
    </Box>
  );
}
