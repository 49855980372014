import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import RatingCode from '../RatingCode';
import Signal from '../Signal';
//import Trend from '../Trend';

export const Box = styled.div`
  ${tw`rounded md:w-40 p-2 md:mx-4`};
  border: 1px solid ${(props) => props.theme.sectionBorder};
`;

export const Title = styled.div`
  color: ${(props) => props.theme.content.color};
`;

/* const TrendLine = ({ color, status }) => (
  <div className="flex mt-3 items-end">
    <Trend trend={true} color={color} />
    <span
      className="text-xs ml-2 relative top-1 font-semibold"
      style={{ color }}
    >
      {status}
    </span>
  </div>
);

const RATING_VALUES = {
  A: 6,
  B: 5,
  C: 4,
  D: 3,
  E: 2,
  F: 1,
};

const determineStatus = ({ current, previous }) => {
  const currentValue = RATING_VALUES[current];
  const previousValue = RATING_VALUES[previous];

  if (currentValue > previousValue) return `Increased from ${previous}`;
  else if (currentValue < previousValue) return `Decreased from ${previous}`;

  return 'No change';
}; */

export const RatingBox = ({ className, title, rating }) => {
  if (!rating) return null;
  //const { /* color, */ ref } = RATING_COLOR_CODE[rating];
  return (
    <Box className={className}>
      <Title className="text-sm font-medium mb-2">{title} Rating</Title>
      <div className="flex justify-between items-end">
        <div>
          <RatingCode rating={rating} />
          {/* <TrendLine color={color} status={determineStatus(rating)} /> */}
        </div>
        <Signal value={rating} />
      </div>
    </Box>
  );
};

export default RatingBox;
