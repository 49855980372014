import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import VerdeScoreSvg from '../../../../public/images/verde-icon.svg';
import {
  InfoOutlineIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons';
import {
  BoxContent,
  BoxHeader,
  Description,
  StockSummary,
  SummaryHighlights,
} from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ReadMore from '../../ReadMore';
import {
  Highlight,
  KeyTextButton,
  Subtle,
  Wrapper,
  StyledButton,
  BlurredContent,
} from '../../StyedComponents';
import LoadingIndicator from '../../LoadingIndicator';
import DomainIcon from '../../DomainIcon';
import { showErrorAlert } from '../../../../actions/alertAction';
import SectorIcon from '../../SectorIcon';
import SymbolLogo from '../../SymbolLogo';
import { getCountryNameFromCode } from '../../../../utils/CountryNameFinder';
import SimilarCompaniesModal from '../../SimilarCompaniesModal';
import DomainTerm from '../../DomainTerm';
import { ConsoleLogger } from '@aws-amplify/core';
import { symbolName } from 'typescript';
import { formatDecimalValue } from '../../../../utils/number';
import { useAuth } from '../../../hocs/AuthProvider';
import BlurVerdeScore from '../../../../public/images/BlurredValue.png';

const CompanySummary = ({
  summaryData,
  darkMode,
  loading,
  data: { symbol },
  error,
  showErrorAlert,
  valuationData,
  subscriptionStatus,
  publicSymbols,
}) => {
  const { authUser } = useAuth();
  const descRef = React.useRef();
  const [descWidth, setDescWidth] = React.useState(0);
  const [
    openSimilarCompaniesModal,
    setOpenSimilarCompaniesModal,
  ] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    descRef.current &&
      !loading &&
      setDescWidth(Math.round(descRef.current.offsetWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    error && showErrorAlert(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  //console.log(symbol);
  //console.log('ValuationData', valuationData);

  return (
    <>
      <Flex bg={darkMode ? '#262626' : 'white'} p="3" flexDirection="column">
        <Flex>
          <Flex>
            <Box>
              <SymbolLogo
                logoUrl={`https://images.verde.finance/${symbol.id}.png`}
              />
            </Box>
            <Flex flexDirection="column" ml="2">
              <Text fontWeight="bold" color={darkMode ? 'white' : 'black'}>
                {symbol.id}
              </Text>
              <Text fontSize="small" color={darkMode ? 'white' : 'black'}>
                {symbol.name}
              </Text>
            </Flex>
          </Flex>
          <Box></Box>
        </Flex>
        <Flex mt="2" gap="4" flexWrap="wrap">
          <Flex
            bg={darkMode ? '#434243' : '#f4f4f4'}
            px="3"
            py="1"
            alignItems="center"
            justifyContent="space-between"
            gap="2"
            borderRadius="15px"
          >
            <img
              alt="United States"
              className="w-5 h-4"
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg`}
            />
            <Text fontSize="small" color={darkMode ? 'white' : '#595959'}>
              USA
            </Text>
          </Flex>
          <Flex
            bg={darkMode ? '#434243' : '#f4f4f4'}
            px="3"
            py="1"
            alignItems="center"
            justifyContent="space-between"
            gap="2"
            borderRadius="15px"
          >
            <SectorIcon
              value={symbol.sector}
              fill={darkMode ? 'white' : 'black'}
            />
            <Text fontSize="small" color={darkMode ? 'white' : '#595959'}>
              {symbol.sector}
            </Text>
          </Flex>
          <Flex
            bg={darkMode ? '#434243' : '#f4f4f4'}
            px="3"
            py="1"
            alignItems="center"
            justifyContent="space-between"
            gap="2"
            borderRadius="15px"
          >
            <Text fontSize="small" color={darkMode ? 'white' : '#595959'}>
              Large Cap
            </Text>
          </Flex>
          <Flex
            bg={darkMode ? '#434243' : '#f4f4f4'}
            px="3"
            py="1"
            alignItems="center"
            justifyContent="space-between"
            gap="2"
            borderRadius="15px"
          >
            <Text fontSize="small" color={darkMode ? 'white' : '#595959'}>
              {symbol.market}
            </Text>
          </Flex>
        </Flex>
        <Flex my="4" gap="3">
          <Flex flexDirection="column" marginRight="48px">
            <Text
              fontWeight="bold"
              fontSize="2xl"
              color={darkMode ? 'white' : 'black'}
            >
              ${symbol.currentPrice}
            </Text>
            <Flex alignItems="center">
              {symbol.relativePriceChange > 0 ? (
                <>
                  <TriangleDownIcon
                    color={darkMode ? '#8acf55' : '#569c30'}
                  ></TriangleDownIcon>
                  <Text color={darkMode ? '#8acf55' : '#569c30'}>
                    {symbol.relativePriceChange}% ({symbol.absolutePriceChange})
                  </Text>
                </>
              ) : (
                <>
                  <TriangleDownIcon
                    color={darkMode ? '#ec5b55' : '#be2e2c'}
                  ></TriangleDownIcon>
                  <Text color={darkMode ? '#ec5b55' : '#be2e2c'}>
                    {symbol.relativePriceChange}% ({symbol.absolutePriceChange})
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Flex fontWeight="bold" fontSize="2xl" alignItems="center">
              {' '}
              {(authUser && subscriptionStatus.active) ||
              publicSymbols?.data.includes(
                valuationData?.symbol?.symbolMarket
              ) ? (
                <>
                  <img
                    src={VerdeScoreSvg}
                    alt=""
                    className="w-4 h-4 mb-0.5 mr-1"
                  />
                  <Text color={darkMode ? 'white' : 'black'}>
                    {symbol.verdeScore}
                  </Text>
                </>
              ) : (
                <img
                  src={BlurVerdeScore}
                  style={{ height: '30px', marginBottom: 4 }}
                />
              )}
            </Flex>
            <Flex alignItems="center" gap="1">
              <Text fontWeight="bold" color={darkMode ? 'white' : 'black'}>
                Verde Score
              </Text>
              <Box>
                <InfoOutlineIcon
                  color={darkMode ? '#58a8f2' : '#0072db'}
                  height="15px"
                  width="15px"
                ></InfoOutlineIcon>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex mt="1" ref={descRef} color="#8c8c8c">
          <Description ref={descRef} data-testid="company-summary-description">
            <ReadMore
              text={symbol.description}
              width={descWidth}
              darkMode={darkMode}
            />
          </Description>
        </Flex>
      </Flex>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.valuationState.data,
  error: state.valuationState.error,
  loading: state.valuationState.loading,
  realTimeData: state.realTimeState.data,
  subscriptionStatus: state.coreState.subscriptionStatus,
  publicSymbols: state.symbolState.publicSymbols,
});

const mapDispatchToProps = {
  showErrorAlert: showErrorAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySummary);
