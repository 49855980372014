import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const StyledButton = styled.button`
  ${tw`bg-transparent font-medium p-1 min-w-full border hover:border-transparent focus:outline-none rounded m-2 text-xs border-gray-400 text-gray-500`}
  color: ${(props) =>
    props.selected ? props.theme.content.color : props.theme.table.color};
  border: 2px solid
    ${(props) => (props.selected ? props.color : props.theme.imageBorder)};
  height: 32px;
  font-family: Roboto;

  border-color: ${(props) =>
    props.selected ? props.color : props.theme.imageBorder};
  &:hover {
    border-color: ${(props) =>
      props.selected ? props.color : props.theme.imageBorder};
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem !important;
  color: ${(props) => props.color} !important;
`;

const MultiSelectButtonGroup = (props) => {
  const { values, setValues } = props;

  const updateSelected = (index) => {
    const filtered = values.filter((t) => t.selected);
    if (filtered.length === 1 && filtered[0].name === values[index].name) {
      return;
    }
    values[index].selected = !values[index].selected;
    setValues([...values]);
  };
  return (
    <div className="flex md:mt-0 mt-4">
      <ul className="flex w-full flex-wrap justify-center">
        {values.map((v, index) => (
          <li key={index} className="flex mr-2 md:m-3">
            <StyledButton
              key={index}
              selected={v.selected}
              onClick={() => updateSelected(index)}
              color={v.color}
            >
              <div className="flex justify-evenly">
                <div>
                  <StyledFontAwesomeIcon icon={faCircle} color={v.color} />
                </div>
                {v.name}
              </div>
            </StyledButton>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MultiSelectButtonGroup;
