import { Icon } from '@chakra-ui/react';
import { connect, useSelector } from 'react-redux';
import React from 'react';

export default function ChakraIcon(props: any) {
  const { value, onClick = () => {} } = props;
  const darkMode = useSelector(
    (state: any) => state.coreState.darkMode as Boolean
  );

  switch (value) {
    case 'YourVerdePlanCheck':
      return (
        <Icon viewBox="0 0 20 20" {...props}>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.14682 0.0223023C6.08355 0.300296 3.30256 1.96805 1.62994 4.53022C-0.0691079 7.13281 -0.463407 10.3574 0.55807 13.2953C1.80697 16.8872 4.94806 19.4343 8.74442 19.9336C9.33975 20.0118 10.5825 20.0232 11.1354 19.9555C13.3241 19.6873 15.3285 18.7474 16.8859 17.2587C18.5579 15.6607 19.5926 13.6311 19.9175 11.3121C19.9652 10.9714 19.9909 10.4855 19.994 9.8609L19.9987 8.92187L19.8803 8.72028C19.5783 8.20603 18.8832 8.11516 18.4643 8.5352C18.2257 8.77444 18.1866 8.94146 18.1866 9.7226C18.1866 10.5402 18.1301 11.1229 17.9894 11.7553C17.6431 13.3121 16.9009 14.669 15.7788 15.7965C13.3562 18.2309 9.65368 18.8824 6.53455 17.4232C4.20738 16.3345 2.53592 14.2197 1.99124 11.6749C1.40823 8.95102 2.31434 6.02579 4.33836 4.09754C6.69145 1.85583 10.0522 1.20902 13.0775 2.41558C13.5613 2.60855 13.8325 2.63181 14.0846 2.50201C14.6615 2.20494 14.7788 1.46691 14.3181 1.03397C13.9687 0.705614 12.4011 0.213394 11.2369 0.0664791C10.7256 0.00194249 9.62695 -0.021277 9.14682 0.0223023ZM18.7835 1.86458C18.6653 1.89771 17.7389 2.80203 14.318 6.22392L10.0018 10.5415L8.88193 9.42783C7.65592 8.2086 7.63448 8.19344 7.18177 8.22622C6.86843 8.24889 6.62008 8.41027 6.47234 8.68711C6.32188 8.96912 6.34729 9.3589 6.53361 9.62614C6.60724 9.73178 7.28797 10.4365 8.04634 11.1921C9.05567 12.1978 9.47653 12.5893 9.61689 12.653C9.8904 12.7773 10.1563 12.7678 10.4202 12.6246C10.5812 12.5372 11.8234 11.3122 15.2788 7.8332C19.7988 3.28234 19.9226 3.15344 19.9728 2.94399C20.141 2.24203 19.5033 1.66325 18.7835 1.86458Z"
            fill={darkMode ? '#D0D0D0' : '#6b737c'}
          />
        </Icon>
      );
    case 'JoinVerdeCheck':
      return (
        <Icon viewBox="0 0 20 20" {...props}>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.14682 0.0223023C6.08355 0.300296 3.30256 1.96805 1.62994 4.53022C-0.0691079 7.13281 -0.463407 10.3574 0.55807 13.2953C1.80697 16.8872 4.94806 19.4343 8.74442 19.9336C9.33975 20.0118 10.5825 20.0232 11.1354 19.9555C13.3241 19.6873 15.3285 18.7474 16.8859 17.2587C18.5579 15.6607 19.5926 13.6311 19.9175 11.3121C19.9652 10.9714 19.9909 10.4855 19.994 9.8609L19.9987 8.92187L19.8803 8.72028C19.5783 8.20603 18.8832 8.11516 18.4643 8.5352C18.2257 8.77444 18.1866 8.94146 18.1866 9.7226C18.1866 10.5402 18.1301 11.1229 17.9894 11.7553C17.6431 13.3121 16.9009 14.669 15.7788 15.7965C13.3562 18.2309 9.65368 18.8824 6.53455 17.4232C4.20738 16.3345 2.53592 14.2197 1.99124 11.6749C1.40823 8.95102 2.31434 6.02579 4.33836 4.09754C6.69145 1.85583 10.0522 1.20902 13.0775 2.41558C13.5613 2.60855 13.8325 2.63181 14.0846 2.50201C14.6615 2.20494 14.7788 1.46691 14.3181 1.03397C13.9687 0.705614 12.4011 0.213394 11.2369 0.0664791C10.7256 0.00194249 9.62695 -0.021277 9.14682 0.0223023ZM18.7835 1.86458C18.6653 1.89771 17.7389 2.80203 14.318 6.22392L10.0018 10.5415L8.88193 9.42783C7.65592 8.2086 7.63448 8.19344 7.18177 8.22622C6.86843 8.24889 6.62008 8.41027 6.47234 8.68711C6.32188 8.96912 6.34729 9.3589 6.53361 9.62614C6.60724 9.73178 7.28797 10.4365 8.04634 11.1921C9.05567 12.1978 9.47653 12.5893 9.61689 12.653C9.8904 12.7773 10.1563 12.7678 10.4202 12.6246C10.5812 12.5372 11.8234 11.3122 15.2788 7.8332C19.7988 3.28234 19.9226 3.15344 19.9728 2.94399C20.141 2.24203 19.5033 1.66325 18.7835 1.86458Z"
            fill={darkMode ? '#86efaf' : '#319961'}
          />
        </Icon>
      );
    default:
      return <Icon viewBox="0 0 20 20" {...props}></Icon>;
  }
}
