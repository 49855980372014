import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { formatDecimalValue } from '../../../../utils/number';
import VerdeScoreSvg from '../../../../public/images/verde-icon.svg';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import DomainTerm from '../../../shared/DomainTerm';
import RatingCode from '../../../shared/RatingCode';
import styled from 'styled-components';
import tw from 'twin.macro';

const TopOverview = ({ data, darkMode }) => {
  function bigValueSuffix(x, prefix, suffix) {
    if (Math.abs(x) >= 1000000000000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(
          Math.abs(x) / 1000000000000
        )}T${suffix}`;
      }
      return `${prefix}${formatDecimalValue(
        parseFloat(x / 1000000000000)
      )}T${suffix}`;
    } else if (Math.abs(x) >= 1000000000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(
          Math.abs(x) / 1000000000
        )}B${suffix}`;
      }
      return `${prefix}${formatDecimalValue(x / 1000000000)}B${suffix}`;
    } else if (Math.abs(x) >= 1000000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(
          Math.abs(x) / 1000000
        )}M${suffix}`;
      }

      return `${prefix}${formatDecimalValue(x / 1000000)}M${suffix}`;
    }

    return `${prefix}${x}${suffix}`;
  }

  const topList = [
    {
      id: 'Portfolio Value',
      value:
        data['quarters'] &&
        data.quarters.length > 0 &&
        data.quarters[0]['portfolioValue'],
    },
    {
      id: 'divider',
    },
    {
      id: 'Verde Score',
      value: data.verdeScore,
    },
    {
      id: 'divider',
    },
  ];

  const ratingList = [
    {
      rating: 'Growth Rating',
      title: 'Growth',
      value: data.growthRating,
    },
    {
      rating: 'Safety Rating',
      title: 'Safety',
      value: data.safetyRating,
    },
    {
      rating: 'Value Rating',
      title: 'Value',
      value: data.valueRating,
    },
    {
      rating: 'Momentum Rating',
      title: 'Momentum',
      value: data.momtmRating,
    },
    {
      rating: 'Dividend Rating',
      title: 'Dividend',
      value: data.divRating,
    },
    {
      rating: 'Profitabilty Rating',
      title: 'Profitability',
      value: data.profitRating,
    },
  ];

  const Rating = ({ value, title, darkMode }) => (
    <div
      className="py-2.5 flex flex-col items-center justify-center"
      style={{
        backgroundColor: darkMode ? '#434243' : '#F9F9F9',
        width: '100px',
        marginTop: '1px',
        borderRadius: '5px',
        alignSelf: 'center',
      }}
    >
      <RatingCode rating={value} />
      <RatingTitle>
        <DomainTerm infoKey={`${title.toLowerCase()}_rating`}>
          <span className="whitespace-nowrap">{title}</span>
        </DomainTerm>
      </RatingTitle>
    </div>
  );

  const RatingTitle = styled.div`
    ${tw`mt-3 font-medium text-sm`}
    color: ${(props) => props.theme.rating.title};
  `;

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <div
        style={{
          position: 'relative',
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 5,
          backgroundColor: darkMode
            ? '#262626'
            : !isMobile
            ? 'white'
            : 'rgb(246,248,250)',
          justifyContent: 'space-around',
          borderBottom: isMobile
            ? ''
            : `1px solid ${darkMode ? 'rgb(51,51,51)' : '#8c8c8c'}`,
          width: '100%',
          display: isMobile ? 'inline-grid' : 'flex',
          gridTemplateColumns: isMobile ? 'auto auto' : null,
          flexWrap: 'wrap',
        }}
      >
        {topList.map((elem, index) => {
          return elem.id !== 'divider' ? (
            <div
              style={{
                textAlign: 'left',
                paddingLeft: isMobile ? 15 : '',
                paddingRight: isMobile ? 15 : '',
                paddingTop: isMobile ? 10 : '',
                paddingBottom: isMobile ? 10 : '',
              }}
            >
              <Text
                style={{
                  color: darkMode ? '#cccccc' : '#8c8c8c',
                  marginTop: '10px',
                }}
              >
                {elem.id}
              </Text>
              {elem.id === 'Verde Score' ? (
                <Flex
                  marginTop="30px"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <img
                    src={VerdeScoreSvg}
                    alt=""
                    className="w-6 h-6 mb-0.5 mr-1"
                  />
                  <Text
                    style={{
                      textAlign: 'left',
                      color: darkMode ? '#f6f6f6' : 'black',
                      fontSize: isMobile ? 17 : '22px',
                      fontWeight: 'bold',
                    }}
                  >
                    {elem.value}
                  </Text>
                </Flex>
              ) : (
                <Text
                  style={{
                    color: darkMode ? 'white' : 'black',
                    fontSize: isMobile ? 17 : '22px',
                    fontWeight: 'bold',
                    marginTop: 30,
                  }}
                >
                  {`$${
                    elem.value &&
                    bigValueSuffix(elem.value, '', '')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }`}
                </Text>
              )}
            </div>
          ) : (
            !isMobile && (
              <div
                style={{
                  height: '100px',
                  width: '0px',
                  marginTop: '5px',
                  border: `0.5px solid ${
                    darkMode ? 'rgb(51,51,51)' : '#8c8c8c'
                  }`,
                  borderWidth: '0.5px',
                }}
              />
            )
          );
        })}

        <Box>
          <Text
            mb={3}
            textAlign="center"
            color={darkMode ? 'white' : 'black'}
            fontWeight="550"
          >
            Valuation Ratings
          </Text>
          <Flex>
            {ratingList.map((elem) => {
              return (
                <div
                  style={{
                    marginBottom: isMobile ? '20' : 10,
                    marginRight: 30,
                  }}
                >
                  <Rating
                    value={elem.value}
                    title={elem.title}
                    darkMode={darkMode}
                  />
                </div>
              );
            })}
          </Flex>
        </Box>
      </div>
    </>
  );
};

export default TopOverview;
