export default class GrowthMetricCM {
  constructor(build) {
    this._market = build._market;
    this._oneWeek = build._oneWeek;
    this._oneMonth = build._oneMonth;
    this._threeMonths = build._threeMonths;
    this._sixMonths = build._sixMonths;
    this._ytd = build._ytd;
    this._oneYear = build._oneYear;
    this._fiveYears = build._fiveYears;
  }

  get market() {
    return this._market;
  }

  set market(market) {
    this._market = market;
  }

  get oneWeek() {
    return this._oneWeek;
  }

  set oneWeek(oneWeek) {
    this._oneWeek = oneWeek;
  }

  get oneMonth() {
    return this._oneMonth;
  }

  set oneMonth(oneMonth) {
    this._oneMonth = oneMonth;
  }

  get threeMonths() {
    return this._threeMonths;
  }

  set threeMonths(threeMonths) {
    this._threeMonths = threeMonths;
  }

  get sixMonths() {
    return this._sixMonths;
  }

  set sixMonths(sixMonths) {
    this._sixMonths = sixMonths;
  }

  get ytd() {
    return this._ytd;
  }

  set ytd(ytd) {
    this._ytd = ytd;
  }

  get oneYear() {
    return this._oneYear;
  }

  set oneYear(oneYear) {
    this._oneYear = oneYear;
  }

  get fiveYears() {
    return this._fiveYears;
  }

  set fiveYears(fiveYears) {
    this._fiveYears = fiveYears;
  }

  static get builder() {
    class Builder {
      market(market) {
        this._market = market;
        return this;
      }

      oneWeek(oneWeek) {
        this._oneWeek = oneWeek;
        return this;
      }

      oneMonth(oneMonth) {
        this._oneMonth = oneMonth;
        return this;
      }

      threeMonths(threeMonths) {
        this._threeMonths = threeMonths;
        return this;
      }

      sixMonths(sixMonths) {
        this._sixMonths = sixMonths;
        return this;
      }

      ytd(ytd) {
        this._ytd = ytd;
        return this;
      }

      oneYear(oneYear) {
        this._oneYear = oneYear;
        return this;
      }

      fiveYears(fiveYears) {
        this._fiveYears = fiveYears;
        return this;
      }

      build() {
        return new GrowthMetricCM(this);
      }
    }
    return Builder;
  }
}
