import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import CancelSvg from '../../../public/images/cancel.svg';
import {
  ColoredParagraph,
  Checkbox,
  CbIcon,
  StyledSpan,
  StyledLink,
  Line,
  ButtonIcon,
  ButtonIconText,
  StyleIconButton,
  Anchor,
} from './style';
import { gtag } from '../../../utils/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { showErrorAlert, showSuccessAlert } from '../../../actions/alertAction';
import { validateEmail } from '../../core/InputValidations';
import { USER_SOCIAL_LINK } from '../../../config/default';
import GoogleIcon from '../../../public/images/google-icon.svg';
import { Text } from '@chakra-ui/react';
import WrappedAxios from '../../../utils/WrappedAxios';
import {
  ColoredContentText,
  StyledInput,
  GreenButton,
  screenSize,
} from '../StyedComponents';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import {
  showPageLoading,
  setLoginPhase,
  resetLoginPhase,
  setForgotPasswordEmail,
} from '../../../actions/coreAction';
import { useAuth } from '../../hocs/AuthProvider';
import { useCurrentWidth } from '../../core/useCurrentWidth';
import VerifyUser from './verifyUser';
import ForgotPassword from './forgotPassword';
import { isProd } from '../../../config/default';
import DomainIcon from '../DomainIcon';
import Axios from 'axios';
import clevertap from 'clevertap-web-sdk';

Modal.setAppElement('#root');

const LoginModal = ({
  openLoginModal,
  loginPhase,
  setLoginPhase,
  resetLoginPhase,
  showErrorAlert,
  showSuccessAlert,
  showPageLoading,
  setForgotPasswordEmail,
}) => {
  const [username, setUsername] = React.useState('');
  const [socialUsername, setSocialUsername] = useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [referral, setReferral] = useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [termsAndConditions, setTermsAndConditions] = React.useState(false);
  const [revealPassword, setRevealPassword] = React.useState(false);
  const [revealConfirmPassword, setRevealConfirmPassword] = React.useState(
    false
  );
  const [socialUsernameError, setSocialUsernameError] = useState('');
  const passwordErrors = [
    {
      error: 'Password must be atleast 8 characters long',
      regex: /[0-9a-zA-Z!@#$&()\\-`.+,/\)]{8,100}/,
    },
    {
      error: 'Password must include atleast 1 numeric character',
      regex: /.*[0-9].*/,
    },
    {
      error: 'Password must include atleast 1 uppercase character',
      regex: /.*[A-Z].*/,
    },
    {
      error: 'Password must include atleast 1 special character',
      regex: /.*[!@#$%^&*();'[].*/,
    },
  ];
  //const [inviteCode, setInviteCode] = React.useState('');
  const history = useHistory();
  const { signin } = useAuth();

  useEffect(() => {
    if (openLoginModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openLoginModal]);

  const onCloseModal = () => {
    setLoginPhase('');
    clearAllInputs();
    history.push('/');
  };

  const clearPassword = () => {
    setPassword('');
    setConfirmPassword('');
  };

  const clearAllInputs = () => {
    setUsername('');
    setEmail('');
    setReferral('');
    setSocialUsername('');
    clearPassword();
    setTermsAndConditions(false);
    setRevealPassword(false);
    setRevealConfirmPassword(false);
  };

  const isSignup = () => loginPhase === 'signup';
  const isSignin = () => loginPhase === 'signin';
  const isVerify = () => loginPhase === 'verify';
  const isForgotPassword = () =>
    loginPhase === 'forgotpassword' || loginPhase === 'verifypasswordreset';

  React.useEffect(() => {
    isSignup() && clearAllInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginPhase]);

  useEffect(() => {
    if (socialUsername === '') {
      setSocialUsernameError('');
    }
    async function checkForUserName() {
      Axios.head(`${USER_SOCIAL_LINK}/users/${socialUsername}`)
        .then((res) =>
          setSocialUsernameError(
            'Looks like someone beat you to it, this username is already taken'
          )
        )
        .catch((err) => setSocialUsernameError(''));
    }
    const debounce = setTimeout(
      () => socialUsername !== '' && checkForUserName(),
      1000
    );
    return () => clearTimeout(debounce);
  }, [socialUsername]);

  const resendVerificationCode = async () => {
    try {
      await Auth.resendSignUp(email);
      showSuccessAlert('Verification code has been sent to ' + email);
    } catch (error) {
      showErrorAlert(`Error resending code. ${error.message}`);
      console.log('error resending code: ', error);
    }
  };

  const initiateSignup = async () => {
    try {
      showPageLoading(true);
      /* const inviteCodeAttr = true
        ? { 'custom:invitecode': inviteCode.trim() }
        : {}; */
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          name: username.trim(),
          'custom:verdeusername': socialUsername.trim(),
          'custom:invitecode': referral,
          // username:socialUsername.trim(),
          // inviteCode:referral,
          //...inviteCodeAttr,
        },
      });

      /* showSuccessAlert(
        'User created successfully! Please enter verification code sent to your email.'
      );
      setLoginPhase('verify'); */
      if (gtag)
        gtag('event', `signup`, {
          event_category: 'signup',
          event_label: email,
        });
      showSuccessAlert(
        'User created successfully! Please sign in to continue.'
      );

      clevertap.event.push('Signed Up', {
        Name: `${username}`, // String
        Username: `${socialUsername}`, // String or number
        Email: `${email}`, // Email address of the user
        InviteCode: `${referral}`,
      });
      //  navigator.geolocation.getCurrentPosition((pos) =>
      //  {
      //   let lat = pos.coords.latitude;
      //   let lng = pos.coords.longitude;
      //   clevertap.getLocation(lat, lng)
      //  });

      setLoginPhase('signin');
      // await signin(email, password);
      // resetLoginPhase();
      // showPageLoading(false);
      // history.push('/');
      // onCloseModal();
    } catch (error) {
      console.log('error confirming sign up', error);
      if (error.message === 'An account with the given email already exists.') {
        showErrorAlert(error.message);
        setLoginPhase('signin');
      } else if (error.message === 'Unrecognizable lambda output') {
        showErrorAlert('Invalid Invite Code.');
      } else {
        showErrorAlert(error.message);
      }
    } finally {
      clearPassword();
      showPageLoading(false);
    }
  };

  const initiateSignin = async () => {
    try {
      showPageLoading(true);
      await signin(email, password);
      resetLoginPhase();
      clevertap.onUserLogin.push({
        Site: {
          Email: `${email}`,
        },
      });
      clevertap.event.push('Logged In', { Email: `${email}` });
      history.push('/');
      showPageLoading(false);
      onCloseModal();
    } catch (error) {
      console.log('error signing in', error);
      if (error.message === 'User is not confirmed.') {
        await resendVerificationCode();
        setLoginPhase('verify');
      } else {
        showErrorAlert(error.message);
      }
      showPageLoading(false);
    } finally {
      clearPassword();
    }
  };

  const authenticateUser = () => {
    if (!email) return showErrorAlert('E-Mail is mandatory!');
    if (!validateEmail(email))
      return showErrorAlert('Enter a valid E-Mail Id!');
    if (!password) return showErrorAlert('Password is mandatory!');

    if (isSignup()) {
      if (socialUsername === '')
        return showErrorAlert('Username cannot be empty');
      if (socialUsernameError !== '')
        return showErrorAlert('Username is Already Taken! ');
      if (!username) return showErrorAlert('Username is mandatory!');
      if (!confirmPassword)
        return showErrorAlert('Confirm Password is mandatory!');
      if (password !== confirmPassword)
        return showErrorAlert('Password and Confirm Password must match!');
      if (!termsAndConditions)
        return showErrorAlert('Check Terms and Conditions!');
    }

    return isSignup() ? initiateSignup() : initiateSignin();
  };

  const loginViaGoogle = () => {
    try {
      showPageLoading(true);
      Auth.federatedSignIn({ provider: 'Google' });
    } catch (error) {
      showErrorAlert(error.message);
      console.log('error signing in via google', error);
    } finally {
      showPageLoading(false);
    }
  };

  const width = useCurrentWidth();

  const modalStyles = {
    overlay: {
      top: '0',
      zIndex: 30,
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
    },
    content: {
      top: '5%',
      margin: 'auto',
      maxWidth: '550px',
      padding: 0,
      bottom: 'auto',
      maxHeight: '90vh',
      fontFamily: 'Plus Jakarta Display',
    },
  };

  return (
    <Modal
      isOpen={openLoginModal}
      //onRequestClose={onCloseModal}
      style={modalStyles}
    >
      <div>
        <div
          className=" top-4 right-4 cursor-pointer absolute"
          onClick={onCloseModal}
        >
          <DomainIcon value="close-modal" />
        </div>
      </div>
      {(isSignin() || isSignup()) && (
        <>
          <div className="px-6 md:px-12 py-8">
            <div className="my-2">
              <ColoredParagraph className="font-bold" color="#38a876">
                Welcome,
              </ColoredParagraph>
              <ColoredParagraph className="font-medium" color="#333333">
                {isSignup() ? 'Sign up today' : 'Get Started'}
              </ColoredParagraph>
            </div>
            <div className="my-2">
              {isSignup() && (
                <StyledInput
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autoFocus={isSignup()}
                />
              )}
              <StyledInput
                id="email"
                name="email"
                type="email"
                placeholder="E-Mail"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                autoFocus={isSignin()}
              />
              {isSignup() && (
                <>
                  <StyledInput
                    id="socialusername"
                    name="socialusername"
                    placeholder="Username"
                    value={socialUsername}
                    onChange={(e) => {
                      setSocialUsername(e.target.value.trim());
                    }}
                    style={{
                      border: `1.5px solid ${
                        isSignup() && socialUsernameError !== ''
                          ? '#be2e2c'
                          : '#e5e5e5'
                      }`,
                    }}
                  />
                  {socialUsernameError !== '' && (
                    <Text mt={1} fontSize={14} color="#be2e2c">
                      {socialUsernameError}
                    </Text>
                  )}
                </>
              )}

              <StyledInput
                id="password"
                name="password"
                type={revealPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                style={{
                  border: `1.5px solid ${
                    isSignup() &&
                    passwordErrors.filter(
                      (elem) => password !== '' && !password.match(elem.regex)
                    ).length > 0
                      ? '#be2e2c'
                      : '#e5e5e5'
                  }`,
                }}
                onChange={(e) => setPassword(e.target.value.trim())}
                onKeyUp={(e) => e.key === 'Enter' && authenticateUser()}
              />
              <FontAwesomeIcon
                className="float-right relative bottom-8 mr-4 cursor-pointer text-lg text-gray-400"
                icon={revealPassword ? faEye : faEyeSlash}
                onClick={() => setRevealPassword(!revealPassword)}
              />
              <div style={{ marginTop: 5, marginBottom: 10 }}>
                {isSignup() &&
                  passwordErrors.map((elem) => {
                    return (
                      password !== '' &&
                      !password.match(elem.regex) && (
                        <Text fontSize={14} color="#be2e2c">
                          {elem.error}
                        </Text>
                      )
                    );
                  })}
              </div>
              {isSignup() && (
                <>
                  <StyledInput
                    id="confirm-password"
                    name="confirm-password"
                    type={revealConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    style={{
                      marginTop: 0,
                      border: `1.5px solid ${
                        password !== '' && password !== confirmPassword
                          ? '#be2e2c'
                          : '#e5e5e5'
                      }`,
                    }}
                    onChange={(e) => setConfirmPassword(e.target.value.trim())}
                    onKeyUp={(e) => e.key === 'Enter' && authenticateUser()}
                  />
                  <FontAwesomeIcon
                    className="float-right relative bottom-8 mr-4 cursor-pointer text-lg text-gray-400"
                    icon={revealConfirmPassword ? faEye : faEyeSlash}
                    onClick={() =>
                      setRevealConfirmPassword(!revealConfirmPassword)
                    }
                  />
                  <div style={{ marginTop: 5, marginBottom: 10 }}>
                    {isSignup() &&
                      password !== '' &&
                      password !== confirmPassword && (
                        <Text fontSize={14} color="#be2e2c">
                          Passwords do not match
                        </Text>
                      )}
                  </div>

                  <StyledInput
                    id="referral"
                    name="referral"
                    placeholder="Referral Code (Optional)"
                    value={referral}
                    style={{ marginTop: 0 }}
                    onChange={(e) => setReferral(e.target.value.trim())}
                  />

                  {/* {true && (
                    <StyledInput
                      id="invite-code"
                      name="invite-code"
                      type="text"
                      placeholder="Invite Code"
                      style={{ marginTop: 0 }}
                      value={inviteCode}
                      onChange={(e) => setInviteCode(e.target.value.trim())}
                    />
                  )} */}
                </>
              )}

              {isSignup() && (
                <div className="mt-6">
                  <label htmlFor="remember_me">
                    <Checkbox
                      id="termsAndConditions"
                      onClick={() => setTermsAndConditions(!termsAndConditions)}
                    >
                      <CbIcon hidden={!termsAndConditions}>
                        <FontAwesomeIcon
                          className="relative left-0.5"
                          icon={faCheck}
                        />
                      </CbIcon>
                    </Checkbox>
                    <StyledSpan>
                      I agree with the
                      <Anchor target="_blank" href="/terms-and-conditions.html">
                        Terms & Conditions
                      </Anchor>
                    </StyledSpan>
                  </label>
                </div>
              )}

              <GreenButton
                onClick={() => {
                  // onCloseModal();
                  authenticateUser();
                }}
              >
                {isSignup() ? 'Sign Up' : 'Sign In'}
              </GreenButton>
            </div>

            <>
              {/* <div className="grid grid-cols-4 xl:grid-cols-3 mt-6 text-sm">
                <Line />
                <div
                  className="text-center col-span-2 xl:col-span-1"
                  style={{ color: '#4d4d4d' }}
                >
                  or continue with
                </div>
                <Line />
              </div>
              <div className="my-6">
                <StyleIconButton
                  onClick={() => {
                    // onCloseModal();
                    loginViaGoogle();
                  }}
                >
                  <div className="flex justify-center items-center">
                    <ButtonIcon height="1.6rem" alt="google" src={GoogleIcon} />
                    <ButtonIconText>Sign in with Google</ButtonIconText>
                  </div>
                </StyleIconButton>
              </div> */}
            </>

            <div className="mt-6 flex">
              <ColoredContentText className="mr-1 font-normal">
                {isSignup() ? 'Already a Member?' : 'New to Verde?'}
              </ColoredContentText>
              <StyledLink
                onClick={() => {
                  setLoginPhase(isSignup() ? 'signin' : 'signup');
                }}
              >
                {isSignup() ? 'Sign In' : 'Sign Up'}
              </StyledLink>
            </div>
            <div className="mt-2 flex -ml-1">
              <StyledLink
                onClick={() => {
                  setLoginPhase('forgotpassword');
                  setForgotPasswordEmail(email);
                }}
              >
                Forgot Password?
              </StyledLink>
            </div>
          </div>
        </>
      )}
      {isVerify() && (
        <VerifyUser
          email={email}
          resendVerificationCode={resendVerificationCode}
        />
      )}
      {isForgotPassword() && <ForgotPassword email={email} />}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loginPhase: state.coreState.loginPhase,
  openLoginModal: state.coreState.openLoginModal,
});

const mapDispatchToProps = {
  showErrorAlert,
  showSuccessAlert,
  showPageLoading,
  setLoginPhase,
  resetLoginPhase,
  setForgotPasswordEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
