import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import { getStackedAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';
import RiskPositives from '../../../RiskPositives';
import { useMediaQuery } from '@chakra-ui/react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';

const CashflowStatement = ({ data, insights, period, setPeriod, darkMode }) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'Cash from Operations',
      selected: true,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'Capital Expenditures',
      selected: true,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'Free Cash Flow',
      selected: true,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
    {
      name: 'Dividends Paid',
      selected: false,
      darkColor: '#DDDDDD',
      lightColor: '#012749',
    },
    {
      name: 'Share Buybacks',
      selected: false,
      darkColor: '#FF7979',
      lightColor: '#F26970',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    [
      'evaluated_on',
      'cash_from_ops',
      'capital_expenditures',
      'free_cash_flow',
      'dividend_paid',
      'sale_purchase_stock',
    ],
    period
  );

  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['Cash from Operations'].selected
      ? {
          name: 'Cash from Operations',
          data: fullChartData.map((item) => [item[3], item[1]]),
        }
      : [],
    chartVariants['Capital Expenditures'].selected
      ? {
          name: 'Capital Expenditures',
          data: fullChartData.map((item) => [item[3], item[0]]),
        }
      : [],
    chartVariants['Free Cash Flow'].selected
      ? {
          name: 'Free Cash Flow',
          data: fullChartData.map((item) => [item[3], item[4]]),
        }
      : [],
    chartVariants['Dividends Paid'].selected
      ? {
          name: 'Dividends Paid',
          data: fullChartData.map((item) => [item[3], item[2]]),
        }
      : [],
    chartVariants['Share Buybacks'].selected
      ? {
          name: 'Share Buybacks',
          data: fullChartData.map((item) => [item[3], item[5]]),
        }
      : [],
  ];

  return (
    <>
      <Flex style={{ marginBottom: '25px' }}>
        <SectionHeader value="Cash Flow Statement" />
        {!isMobile && (
          <Box
            style={{
              marginLeft: 'auto',
              backgroundColor: darkMode ? '#161616' : '#f8f8f8',
              borderRadius: '15px',
              height: 'auto',
            }}
          >
            <ToggleButton period={period} setPeriod={setPeriod} />
          </Box>
        )}
      </Flex>

      {isMobile && (
        <Box
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: darkMode ? '#161616' : '#f8f8f8',
            borderRadius: '15px',
            marginBottom: '5px',
            height: 'auto',
          }}
        >
          <ToggleButton period={period} setPeriod={setPeriod} />
        </Box>
      )}
      <div style={{ marginTop: '10px' }}>
        <StackedLineChart data={chartData} valueType="currency" />
        <div className="flex justify-center">
          <MultiLineGraphSelectorButton
            values={buttonValues}
            setValues={setButtonValues}
          />
        </div>
      </div>

      <RiskPositives
        positives={insights?.cash_flow_statement?.positives}
        negatives={insights?.cash_flow_statement?.negatives}
      />
    </>
  );
};

export default CashflowStatement;
