import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  getFormattedCurrencyValueForTooltip,
  MONTHS,
} from '../../../../../utils/ChartUtils';
import { chartValues } from './constants';

Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B'],
  },
});

const StackedAreaChart = ({ chartVariants, data, darkMode }) => {
  const reveneueData = chartVariants['revenue'].selected
    ? data.map((item) => [item[1], item[2]])
    : [];
  const earningsData = chartVariants['earnings'].selected
    ? data.map((item) => [item[1], item[3]])
    : [];
  const cashFromOpsData = chartVariants['cashFromOps'].selected
    ? data.map((item) => [item[1], item[4]])
    : [];
  const operationExpData = chartVariants['operationExp'].selected
    ? data.map((item) => [item[1], item[6]])
    : [];
  const freeCashFlowData = chartVariants['freeCashFlow'].selected
    ? data.map((item) => [item[1], item[5]])
    : [];

  const options = {
    chart: {
      type: 'spline',
      style: {
        fontFamily: 'Roboto',
      },
      backgroundColor: darkMode ? 'transparent' : '#ffffff',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      dateTimeLabelFormats: {
        day: '%e%b%y',
        month: '%b %Y',
      },
      crosshair: {
        enabled: true,
        width: 62,
        color: darkMode
          ? {
              linearGradient: [0, 0, 100, 300],
              stops: [
                [0, 'rgba(52, 94, 103, 0.4)'],
                [0.5, 'rgba(52, 94, 103, 0.4)'],
                [1, 'rgba(87, 103, 128, 0.4)'],
              ],
            }
          : {
              linearGradient: [0, 0, 100, 300],
              stops: [
                [0, 'rgba(225, 241, 243, 0.5)'],
                [1, 'rgba(225, 241, 243, 0.5)'],
              ],
            },
      },
      tickLength: 0,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
        formatter: function () {
          return '$' + this.axis.defaultLabelFormatter.call(this);
        },
      },
      gridLineColor: darkMode ? '#3b404f' : '#e6e6e6',
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          //lineColor: 'white',
          //lineWidth: 1,
        },
      },
      // areaspline: {
      //   fillOpacity: 0.3,
      // },
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shadow: false,
      useHTML: true,
      backgroundColor: darkMode ? '#141920' : '#fff',
      borderColor: darkMode ? '#86efaf' : '#5bae7f',
      borderRadius: 8,
      formatter: function () {
        const points = this.points;
        let profitMargin;
        data.map((elem) => {
          if (elem[1] === this.x)
            profitMargin = parseInt((elem[3] / elem[2]) * 100);
        });

        let currentDate =
          MONTHS[new Date(this.x).getMonth()] +
          ' ' +
          new Date(
            new Date(this.x).getFullYear(),
            new Date(this.x).getMonth() + 1,
            0
          ).getDate() +
          ', ' +
          new Date(this.x).getFullYear();
        const dateColor = darkMode ? '#d8d9e4' : '#3a3a3a';
        const valueColor = darkMode ? '#86efaf' : '#5bae7f';
        const nameColor = darkMode ? '#d8d9e4' : '#686868';
        let tooltips = [];
        tooltips = tooltips.concat(
          '<div style="width:200px;position:relative;height:100%;padding-left:8px;padding-top:5px"> ' +
            '<div style="color:' +
            dateColor +
            '">' +
            currentDate +
            '</div>' +
            '<div style="width:70%;float:left;">'
        );
        tooltips = tooltips.concat(
          points.map((v, i) => {
            return (
              '<div style="padding-bottom:5px">' +
              '<span style="font-size:16px;color:' +
              v.color +
              '">\u25CF</span>' +
              ' ' +
              '<span style="color:' +
              nameColor +
              ';font-size:0.7rem;font-weight:500">' +
              ' &ensp;' +
              v.series.name +
              '</span>' +
              '<br> <span style="color:' +
              valueColor +
              ';font-size: 0.8rem;font-weight:500">&emsp;&nbsp;&nbsp;' +
              getFormattedCurrencyValueForTooltip(v.y) +
              'B/year<span><br> </div>'
            );
          })
        );

        tooltips = tooltips.concat(
          '</div><div  style="width:30%;float:right;text-align:center; font-weight:500;display:flex;' +
            ' flex-direction:column;justify-content:center; height:100%' +
            '"> <span style="font-size:medium;padding-left:5px;font-weight:500;color:' +
            valueColor +
            '"> ' +
            profitMargin +
            '%</span>' +
            ' <span style="margin-top:5px;color:' +
            nameColor +
            '">Profit' +
            ' <br> Margin </span></div></div>'
        );

        return tooltips;
      },
      shared: true,
    },
    legend: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            yAxis: {
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#9E9E9E',
                  fontWeight: 500,
                },
                useHTML: true,
                formatter: function () {
                  return this.isFirst || this.isLast
                    ? '$' + this.axis.defaultLabelFormatter.call(this)
                    : '';
                },
              },
            },
            xAxis: {
              type: 'datetime',
              tickInterval: 31536000000,
              dateTimeLabelFormat: '%b %Y',
              labels: {
                useHTML: true,
                style: {
                  style: {
                    fontWeight: 500,
                    color: '#9E9E9E',
                  },
                },
              },
              crosshair: {
                enabled: true,
                width: 25 * 2,
                color: darkMode ? 'rgba(235,235,235,0.1)' : '#F5F5F5',
              },
            },
            chart: {
              height: 400,
              style: {
                fontFamily: 'Roboto',
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: 'Revenue',
        data: reveneueData,
        color: chartValues['revenue'].color,
        lineWidth: 2,
        fillColor: {
          linearGradient: [0, 100, 0, 300],
          stops: [
            [
              0,
              Highcharts.color(chartValues['revenue'].fill)
                .setOpacity(0.3)
                .get('rgba'),
            ],
            [
              1,
              Highcharts.color(chartValues['revenue'].fill)
                .setOpacity(0.03)
                .get('rgba'),
            ],
          ],
        },
        fillOpacity: 0.2,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
      {
        name: 'Earnings',
        data: earningsData,
        lineWidth: 2,
        color: chartValues['earnings'].color,
        fillColor: {
          linearGradient: [0, 100, 0, 300],
          stops: [
            [
              0,
              Highcharts.color(chartValues['earnings'].fill)
                .setOpacity(0.3)
                .get('rgba'),
            ],
            [
              1,
              Highcharts.color(chartValues['earnings'].fill)
                .setOpacity(0.03)
                .get('rgba'),
            ],
          ],
        },
        fillOpacity: 0.2,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
      {},
      {
        name: 'Cash From Ops',
        data: cashFromOpsData,
        lineWidth: 2,
        color: chartValues['cash_from_ops'].color,
        fillColor: {
          linearGradient: [0, 100, 0, 300],
          stops: [
            [
              0,
              Highcharts.color(chartValues['cash_from_ops'].fill)
                .setOpacity(0.3)
                .get('rgba'),
            ],
            [
              1,
              Highcharts.color(chartValues['cash_from_ops'].fill)
                .setOpacity(0.03)
                .get('rgba'),
            ],
          ],
        },
        fillOpacity: 0.2,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
      {
        name: 'Operation Exp',
        data: operationExpData,
        lineWidth: 2,
        color: chartValues['operation_exp'].color,
        fillColor: {
          linearGradient: [0, 100, 0, 300],
          stops: [
            [
              0,
              Highcharts.color(chartValues['operation_exp'].fill)
                .setOpacity(0.3)
                .get('rgba'),
            ],
            [
              1,
              Highcharts.color(chartValues['operation_exp'].fill)
                .setOpacity(0.03)
                .get('rgba'),
            ],
          ],
        },
        fillOpacity: 0.2,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
      {
        name: 'Free Cash Flow',
        data: freeCashFlowData,
        lineWidth: 2,
        color: chartValues['free_cash_flow'].color,
        fillColor: {
          linearGradient: [0, 100, 0, 300],
          stops: [
            [
              0,
              Highcharts.color(chartValues['free_cash_flow'].fill)
                .setOpacity(0.3)
                .get('rgba'),
            ],
            [
              1,
              Highcharts.color(chartValues['free_cash_flow'].fill)
                .setOpacity(0.03)
                .get('rgba'),
            ],
          ],
        },
        fillOpacity: 0.2,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
    ],
  };

  const containerRef = React.useRef(null);
  const chartRef = React.useRef(null);
  React.useEffect(() => {
    chartRef.current = Highcharts.chart(containerRef.current, options);
  }, [data, options]);

  return (
    <div ref={containerRef} className="text-xs font-semibold w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
        ref={chartRef}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(StackedAreaChart);
