import numeral from 'numeral';
import SymbolCM from '../models/SymbolCM';
import { getLogoUrl } from '../utils/LogoUtils';
import { formatDecimalValue } from '../utils/number';

export const deserialise = (payload) =>
  new SymbolCM.builder()
    .id(payload.symbol)
    .name(payload.symbol_name)
    .description(payload.description)
    .logoUrl(getLogoUrl(payload.symbol))
    .country(payload.country)
    .industry(payload.industry)
    .sector(payload.gic_sector_display)
    .market(payload.market)
    .noOfEmployees(payload.number_of_employees)
    .currentPrice(formatDecimalValue(payload.current_price))
    .absolutePriceChange(formatDecimalValue(payload.abs_change_in_price))
    .relativePriceChange(formatDecimalValue(payload.rel_change_in_price))
    .marketCap(
      numeral(payload.marketcap_mln + '000000')
        .format('($0.00a)')
        .toUpperCase()
    )
    .marketCapValue(Number(payload.marketcap_mln + '000000'))
    .fairPrice(
      payload.fair_price && payload.fair_price < 0 ? null : payload.fair_price
    )
    .targetPrice(payload.target_price)
    .verdeScore(payload.verde_score)
    .growthRating(payload.growth_rating)
    .safetyRating(payload.safety_rating)
    .valueRating(payload.value_rating)
    .dividendRating(payload.dividend_rating)
    .momentumRating(payload.momentum_rating)
    .overvaluedIndicator(payload.over_valued)
    .valuationText(payload.over_valued ? 'Overvalued' : 'Undervalued')
    .fairPriceDeviation(payload.fair_price_deviation)
    .earningsPerShare(payload.earnings_per_share)
    .forwardPriceToEarnings(formatDecimalValue(payload.forward_pe_ratio))
    .forwardDividendRate(payload.dividend_rate_fwd)
    .forwardYield(payload.yield_fwd)
    .trailingPriceToEarnings(formatDecimalValue(payload.trailing_pe_ratio))
    .fiftyTwoWeekHigh(payload.fiftytwo_week_high)
    .fiftyTwoWeekLow(payload.fiftytwo_week_low)
    .enterprise(payload.enterprise_value)
    .isAdr(payload.country !== 'US')
    .market(payload.market)
    .profitabilityRating(payload.profitability_rating)
    .symbolMarket(payload.symbol_market)
    .build();
