import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  getFormattedCurrencyValueForTooltip,
  getFormattedCurrencyValueForAxis,
  getFormattedNumberForTooltip,
  MONTHS,
} from '../../../utils/ChartUtils';
import { formatDecimalValue } from '../../../utils/number';

Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B'],
  },
});

const StackedLineChart = ({ valueType, data, darkMode }) => {
  const seriesColor = darkMode
    ? ['#D49CFF', '#FFC176', '#7BBFFF', '#DDDDDD', '#FF7979']
    : ['#B47FFF', '#DDAD07', '#099AFB', '#012749', '#F26970'];

  const options = {
    chart: {
      type: 'spline',
      style: {
        fontFamily: 'Roboto',
      },
      backgroundColor: darkMode ? 'transparent' : '#ffffff',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      dateTimeLabelFormats: {
        day: '%e%b%y',
        month: '%b %Y',
      },
      crosshair: {
        enabled: true,
        width: 62,
        color: darkMode
          ? {
              linearGradient: [0, 0, 100, 300],
              stops: [
                [0, 'rgba(52, 94, 103, 0.4)'],
                [0.5, 'rgba(52, 94, 103, 0.4)'],
                [1, 'rgba(87, 103, 128, 0.4)'],
              ],
            }
          : {
              linearGradient: [0, 0, 100, 300],
              stops: [
                [0, 'rgba(225, 241, 243, 0.5)'],
                [1, 'rgba(225, 241, 243, 0.5)'],
              ],
            },
      },
      tickLength: 0,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
        formatter: function () {
          return (
            //(valueType === 'currency' ? '$' : '') +
            (valueType === 'currency'
              ? getFormattedCurrencyValueForAxis(this.value)
              : valueType === 'multiple' || valueType === 'percent'
              ? this.value
              : getFormattedNumberForTooltip(this.value)) +
            (valueType === 'percent'
              ? '%'
              : valueType === 'multiple'
              ? 'x'
              : '')
          );
        },
      },
      gridLineColor: darkMode ? '#3b404f' : '#e6e6e6',
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
        },
      },
      series: {
        lineWidth: 2,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shadow: false,
      useHTML: true,
      backgroundColor: darkMode ? '#141920' : '#fff',
      borderColor: darkMode ? '#86efaf' : '#5bae7f',
      borderRadius: 8,
      formatter: function () {
        const points = this.points;
        const currentDate =
          MONTHS[new Date(this.x).getMonth()] +
          ' ' +
          new Date(
            new Date(this.x).getFullYear(),
            new Date(this.x).getMonth() + 1,
            0
          ).getDate() +
          ', ' +
          new Date(this.x).getFullYear();
        const dateColor = darkMode ? '#d8d9e4' : '#3a3a3a';
        const valueColor = darkMode ? '#86efaf' : '#5bae7f';
        const nameColor = darkMode ? '#d8d9e4' : '#686868';
        let tooltips = [];
        tooltips = tooltips.concat(
          '<div style="width:200px;position:relative;height:100%;padding-left:8px;padding-top:5px;"> ' +
            '<div style="color:' +
            dateColor +
            '">' +
            currentDate +
            '</div>' +
            '<div style="width:70%;float:left;">'
        );
        tooltips = tooltips.concat(
          points.map((v, i) => {
            return (
              '<div style="padding-bottom:5px">' +
              '<span style="font-size:16px;color:' +
              v.color +
              '">\u25CF</span>' +
              ' ' +
              '<span style="color:' +
              nameColor +
              ';font-size:0.7rem;font-weight:500">' +
              ' &ensp;' +
              v.series.name +
              '</span>' +
              '<br> <span style="color:' +
              valueColor +
              ';font-size: 0.8rem;font-weight:500">&emsp;&nbsp;&nbsp;' +
              ((valueType === 'currency'
                ? getFormattedCurrencyValueForTooltip(v.y)
                : //                : valueType === 'multiple' || valueType === 'percent'
                  //                ? v.y
                  getFormattedNumberForTooltip(v.y)) +
                (valueType === 'percent'
                  ? '%'
                  : valueType === 'multiple'
                  ? 'x'
                  : '')) +
              '<span><br> </div>'
            );
          })
        );

        return tooltips;
      },
      shared: true,
    },
    legend: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            xAxis: {
              crosshair: {
                width: 45,
              },
            },
            chart: {
              height: 400,
            },
          },
        },
      ],
    },
    series: data.map((d, i) => ({ ...d, color: seriesColor[i] })),
  };

  const containerRef = React.useRef(null);
  const chartRef = React.useRef(null);
  React.useEffect(() => {
    chartRef.current = Highcharts.chart(containerRef.current, options);
  }, [data, options]);

  return (
    <div ref={containerRef} className="text-xs font-semibold w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
        ref={chartRef}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(StackedLineChart);
