import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import { getAreaChartData } from '../../../../../utils/ChartUtils';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import BarChart from '../../../BarChart';
import SectionHeader from '../../../SectionHeader';

const DividendHistory = ({ data, insights, period, setPeriod }) => {
  return (
    <>
      <SectionHeader value="Dividend History" className="pb-6" />
      <BorderedChartContainer className="py-4">
        <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div>
        <BarChart
          data={getAreaChartData(
            data,
            ['dividend_paid_per_share', 'evaluated_on'],
            period
          )}
          name="Dividend History"
          color="#65e1ee"
          yAxisLabelPrefix="$"
        />
      </BorderedChartContainer>

      <BulletPoints
        title="Positives"
        points={insights?.dividendPerShare?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.dividendPerShare?.negatives}
        isPositive={false}
      />
    </>
  );
};

export default DividendHistory;
