import React from 'react';
import Modal from 'react-modal';

const modalStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    bottom: '0',
    left: 'auto',
    right: '0',
    height: 'auto',
    maxWidth: '450px',
    padding: '0px',
    top: '80px',
    width: '100%',
    border: 0,
  },
};
export default function TableSidebar({
  children,
  isOpen,
  closeModal = () => {},
  styles = {},
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: { ...modalStyles.overlay, ...styles.overlay },
        content: { ...modalStyles.content, ...styles.content },
      }}
    >
      {children}
    </Modal>
  );
}
