import React from 'react';
import { Text, Tab, TabItem } from './style';

import CustomTableRectLight from '../../../public/images/CustomTableRectLight.png';
import CustomTableRectDark from '../../../public/images/CustomTableRectDark.png';
import CustomTableRectGreenLight from '../../../public/images/CustomTableRectGreenLight.png';
import CustomTableRectGreenDark from '../../../public/images/CustomTableRectGreenDark.png';
import { useMediaQuery } from '@chakra-ui/react';

export default function PageTab({
  setTab,
  selectedTab,
  tabs,
  darkMode,
  customApplied,
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          borderRadius: '5px',
          backgroundColor: darkMode ? '#262626' : 'white',
          padding: '25px',
          width: isMobile ? 'auto' : '100%',
          marginBottom: '20px',
          //overflowX: 'scroll',
          height: 'auto',
        }}
      >
        {tabs.map((tab) => (
          <div
            style={{
              display: 'flex',
              backgroundColor:
                tab.name !== 'Customize Table'
                  ? tab.name === selectedTab.name
                    ? darkMode
                      ? '#4adf79'
                      : '#01874e'
                    : darkMode
                    ? '#343434'
                    : '#f8f8f8'
                  : '',
              padding: '10px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '25px',
              marginLeft: '10px',
              marginRight: '10px',
              cursor: 'pointer',
              maxHeight: '45px',
            }}
            onClick={() => setTab(tab)}
          >
            <Text
              style={{
                fontWeight: 'bold',
                marginRight: tab.name === 'Customize Table' ? '4px' : '',
                color:
                  tab.name === 'Customize Table'
                    ? customApplied || tab.name === selectedTab.name
                      ? darkMode
                        ? '#4adf79'
                        : '#01874e'
                      : darkMode
                      ? 'white'
                      : 'black'
                    : tab.name === selectedTab.name
                    ? darkMode
                      ? 'black'
                      : 'white'
                    : darkMode
                    ? 'white'
                    : 'black',
              }}
            >
              {tab.name}
            </Text>

            {tab.name === 'Customize Table' && (
              <img
                src={
                  customApplied || tab.name === selectedTab.name
                    ? darkMode
                      ? CustomTableRectGreenDark
                      : CustomTableRectGreenLight
                    : darkMode
                    ? CustomTableRectLight
                    : CustomTableRectDark
                }
                width="25px"
                height="25px"
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
}
