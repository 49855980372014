import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { RatingValue } from '../RatingCode/style';
import { ALLOWED_VALUES } from '../RatingCode';
import SectionHeader from '../SectionHeader';

const SectionRatingContainer = styled.div`
  ${tw`flex flex-col items-center py-2.5 px-3 rounded`};
`;

const SectionRatingText = styled.div`
  ${tw`text-xs mt-2.5`};
  color: ${(props) => props.theme.dividend.ratingText};
`;

const SectionRatingValue = styled(RatingValue)`
  ${tw`w-8 h-8 text-xl`};
`;

const SectionRating = ({
  title,
  infoKey,
  rating,
  showTitle = false,
  darkMode,
}) => {
  const RATING_COLOR_CODE = {
    A: { color: darkMode ? '#8acf55' : '#559C30' },
    B: { color: darkMode ? '#adcd57' : '#B5A93B' },
    C: { color: darkMode ? '#f6c759' : '#EFB241' },
    D: { color: darkMode ? '#f18a57' : '#E0833A' },
    E: { color: '#D36535' },
    F: { color: darkMode ? '#ec5b55' : '#BF2F2C' },
  };

  return (
    <>
      {showTitle && (
        <SectionHeader value={title} className="mb-6" infoKey={infoKey} />
      )}
      <div className="flex">
        <SectionRatingContainer>
          <SectionRatingValue
            bgColor={
              ALLOWED_VALUES.includes(rating)
                ? RATING_COLOR_CODE[rating].color
                : null
            }
          >
            {rating ? rating : '-'}
          </SectionRatingValue>
          <SectionRatingText>{title}</SectionRatingText>
        </SectionRatingContainer>
      </div>
    </>
  );
};

export default SectionRating;
