import React from 'react';
import { connect } from 'react-redux';

import { RatingValue } from './style';
import { Text } from '@chakra-ui/react';

export const ALLOWED_VALUES = ['A', 'B', 'C', 'D', 'E', 'F'];

function RatingCode({ rating, darkMode }) {
  const RATING_COLOR_CODE = {
    A: { color: darkMode ? '#8acf55' : '#559C30' },
    B: { color: darkMode ? '#adcd57' : '#B5A93B' },
    C: { color: darkMode ? '#f6c759' : '#EFB241' },
    D: { color: darkMode ? '#f18a57' : '#E0833A' },
    E: { color: '#D36535' },
    F: { color: darkMode ? '#ec5b55' : '#BF2F2C' },
  };

  const { color } = RATING_COLOR_CODE[rating] || { color: undefined };
  return (
    <>
      <RatingValue bgColor={color}>
        {ALLOWED_VALUES.includes(rating) ? rating : '-'}
      </RatingValue>
    </>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(RatingCode);
