import React from 'react';
import { Text } from '@chakra-ui/react';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import StockInfo from './PerformanceChart/';

const Metric = styled.div`
  ${tw`flex flex-col items-center font-medium`};
`;

const PortfolioPerformance = ({ data, darkMode, valuationData }) => {
  const List = [
    {
      title: '52 Week High',
      value: 'VALUE',
    },
    {
      title: '52 Week Low',
      value: 'VALUE',
    },
    {
      title: 'EPS',
      value: 'VALUE',
    },
    {
      title: 'PE Ratio',
      value: 'VALUE',
    },
    {
      title: 'Dividend Yield',
      value: 'VALUE',
    },
  ];

  return (
    <>
      <Box justifyContent="space-between" flexWrap="wrap">
        <StockInfo
          data={data}
          darkMode={darkMode}
          valuationData={valuationData}
        />

        {/* <Flex justifyContent="space-between" flexWrap="wrap">
          {List.map((elem) => {
            return (
              <Metric>
                <div
                  style={{
                    backgroundColor: darkMode ? '#343434' : '#F9F9F9',
                    padding: '20px',
                    width: '125px',
                    textAlign: 'center',
                  }}
                >
                  <h6
                    style={{
                      fontWeight: 'bold',
                      color: darkMode ? 'white' : 'black',
                    }}
                  >
                    {elem.value}
                  </h6>
                  <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>
                    {elem.title}
                  </h6>
                </div>
              </Metric>
            );
          })}
        </Flex> */}
      </Box>
    </>
  );
};

export default PortfolioPerformance;
