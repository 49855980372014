if (!localStorage.getItem('verdeLocale'))
  localStorage.setItem('verdeLocale', 'US');

const initialState = {
  loading: false,
  openLoginModal: false,
  loginPhase: '',
  openChangePassword: false,
  darkMode: false,
  verdeLocale: localStorage.getItem('verdeLocale')
    ? localStorage.getItem('verdeLocale')
    : 'US',
  subscriptionStatusLoading: false,
  subscriptionStatus: { active: true },
  openYourVerdePlanModal: false,
  openJoinVerdePlanModal: false,
  forgotPasswordEmail: '',
};

const CoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_PAGE': {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case 'CHANGE_LOGIN_PHASE': {
      return {
        ...state,
        loginPhase: action.loginPhase,
        openLoginModal: action.loginPhase ? true : false,
      };
    }

    case 'CHANGE_PASSWORD': {
      return {
        ...state,
        openChangePassword: action.openChangePassword,
      };
    }

    case 'SET_DARK_MODE': {
      localStorage.setItem('verde-finance-dark-mode-enabled', action.darkMode);
      return {
        ...state,
        darkMode: action.darkMode,
      };
    }

    case 'FETCH_FRILL_TOKEN_SUCCESS': {
      return {
        ...state,
        frillToken: action.payload.data,
      };
    }

    case 'SET_SUBSCRIPTION_STATUS_LOADING': {
      return {
        ...state,
        subscriptionStatusLoading: action.payload,
      };
    }

    case 'SET_SUBSCRIPTION_STATUS': {
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    }

    case 'SET_OPEN_YOUR_VERDE_PLAN_MODAL': {
      return {
        ...state,
        openYourVerdePlanModal: action.payload,
      };
    }

    case 'SET_OPEN_JOIN_VERDE_PLAN_MODAL': {
      return {
        ...state,
        openJoinVerdePlanModal: action.payload,
      };
    }

    case 'SET_FORGOT_PASSWORD_EMAIL': {
      return {
        ...state,
        forgotPasswordEmail: action.payload,
      };
    }

    default:
      return state;
  }
};

export default CoreReducer;
