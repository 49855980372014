export const formatToUSDollars = (value, decimalPlaces = 2) => {
  if (!value) return 0;
  const SI = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: '' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e20, symbol: 'T' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const val = Math.abs(value);
  const index = SI.findIndex((x) => val < x.value) - 1;
  return `${value < 0 ? '-' : ''}$${(val / SI[index].value)
    .toFixed(decimalPlaces)
    .replace(rx, '$1')} ${SI[index].symbol}`;
};
