import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  forwardRef,
  ChakraProps,
  GridItem,
  chakra,
  SimpleGrid,
  InputLeftElement,
  useMediaQuery,
} from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { ArrowForwardIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { motion, MotionProps } from 'framer-motion';
import { useAuth } from '../../hocs/AuthProvider';
import React, { FormEvent, RefObject, useEffect, useState } from 'react';
import { Container } from '../../../utils/styling';
import 'animate.css';
import Footer from './footer';
import VerdeHeader from '../../shared/VerdeHeader';
import { PLAID_GENERATE_TOKEN } from '../../../config/default';
import { searchIcon } from './icons';
import ClickedOutside from '../../shared/ClickOutside/click-outside';
import usePlaidLink from '../../../hooks/usePlaid';
import VerdeLogo from '../../../public/images/VerdeLogo.png';
import WrappedAxios from '../../../utils/WrappedAxios';
import { SYMBOL_BASE_URL } from '../../../config/default';
import portfolioImage from '../../../public/images/Portfolio.png';
import { getMyVerde } from '../../../actions/my-verde';
import clevertap from 'clevertap-web-sdk';

import {
  ColoredContentText,
  ContentWrapper,
  Highlight,
  Subtle,
} from '../../shared/StyedComponents';
import LoadingIndicator from '../../shared/LoadingIndicator';
import { CompanyDivider, SearchItem } from '../../shared/SymbolSearch/style';
import { getLogoUrl } from '../../../utils/LogoUtils';
import SymbolLogo from '../../shared/SymbolLogo';
import { useMergeRefs } from '@chakra-ui/react';

import { DefaultTheme, useTheme } from 'styled-components';
import { formatDecimalValue } from '../../../utils/number';
import { arrayBuffer } from 'stream/consumers';
import { SentryError } from '@sentry/utils';
import SectorLogo from '../../shared/SectorLogo';
import {
  filterBySymbolsAndNames,
  getEntityLink,
  getEntitySubtext,
  getEntityName,
} from '../../shared/SymbolSearch';
import MyVerdeWidget from './MyVerdeWidget';
import LetterLogo from '../../shared/LetterLogo';
import _ from 'lodash';

declare type Footer = ({ isDark }: { isDark?: boolean }) => void;

export type MotionBoxProps = Omit<ChakraProps, keyof MotionProps> &
  MotionProps & {
    as?: React.ElementType;
  };

export type MotionFlexProps = Omit<ChakraProps, keyof MotionProps> &
  MotionProps & {
    as?: React.ElementType;
  };
export const MotionBox = motion(
  forwardRef((props, ref) => {
    return <chakra.div ref={ref} {...props} />;
  })
);

const MotionFlex = motion(Flex);
const themeStyle = (isDark: boolean) => ({
  heroText: isDark ? 'white' : 'black',
  heroDescription: isDark ? 'white' : '#465057',
  heroBg: isDark ? '#272c34' : '#f1f7fb',
  searchBg: isDark ? '#141920' : 'white',
  searchBorder: isDark ? 'none' : '#e2e9ee',
  trendingColor: isDark ? 'white' : '#465057',
  trendingBg: isDark ? 'black' : 'white',
});
const SEARCH_LIMIT = 10;
type TopMovers = {
  symbol: string;
  symbol_name: string;
  current_price: number;
  one_day: number;
};

const themeObj = {
  dark: {
    myVerdeBorderColor: '#d6e5c5',
    myVerdeBg: '#ecf6e1',
    applyBtnColor: 'white',
    applyBtnBg: '#24b552',
  },
  light: {
    applyBtnColor: 'white',
    applyBtnBg: '#24b552',
  },
};
type Theme = DefaultTheme & { isDark: boolean };
export function NewHomePage({
  setTheme,
  darkMode,
  symbolNames,
  symbolNamesLoading,
  portfolioLinked,
  socialUserName,
  email,
  subscriptionStatus,
  publicSymbols,
}: {
  setTheme: Function;
  darkMode: boolean;
  symbolNames: any;
  symbolNamesLoading: boolean;
  portfolioLinked: boolean;
  socialUserName: any;
  email: any;
  subscriptionStatus: any;
  publicSymbols: any;
}) {
  const { authUser, signout } = useAuth();
  const currentTheme: Theme = useTheme() as Theme;
  const theme: any = currentTheme.isDark ? themeObj.dark : themeObj.light;
  const themeStyleObj = themeStyle(darkMode);
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [matchingSymbolNames, setMatchingSymbolNames] = React.useState([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTablet] = useMediaQuery('(max-width: 1150px)');
  const [notExistsObj, setNotExistsObj] = useState<any>({});
  const [portfolioData, setPortfolioData] = useState([]);

  const [gainersActive, setGainersActive] = useState(true);
  const [losersActive, setLosersActive] = useState(false);
  const history = useHistory();

  if (window.location.href.includes('oauth_state_id')) {
    history.push('/plaidandroid');
  }

  // -------Filter Values for styling , filtering

  const [oneDayGL, setOneDayGL] = useState<any>({ selected: true, value: '' });
  const [sevenDayGL, setSevenDayGL] = useState<any>({
    selected: false,
    value: '',
  });
  const [oneMonthGL, setOneMonthGL] = useState<any>({
    selected: false,
    value: '',
  });
  const [threeMonthGL, setThreeMonthGL] = useState<any>({
    selected: false,
    value: '',
  });
  const [oneYearGL, setOneYearGL] = useState<any>({
    selected: false,
    value: '',
  });
  const [fiveYearGL, setFiveYearGL] = useState<any>({
    selected: false,
    value: '',
  });

  const [tSectorsActive, setTSectorsActive] = useState(true);
  const [wSectorsActive, setWSectorsActive] = useState(false);

  const [oneDayS, setOneDayS] = useState<any>({ selected: true, value: '' });
  const [sevenDayS, setSevenDayS] = useState<any>({
    selected: false,
    value: '',
  });
  const [oneMonthS, setOneMonthS] = useState<any>({
    selected: false,
    value: '',
  });
  const [threeMonthS, setThreeMonthS] = useState<any>({
    selected: false,
    value: '',
  });
  const [oneYearS, setOneYearS] = useState<any>({ selected: false, value: '' });
  const [fiveYearS, setFiveYearS] = useState<any>({
    selected: false,
    value: '',
  });

  let activeTime = 0;

  //----------------------------------------------------------

  const [errorGL, setErrorGL] = useState<any>('');
  const [errorS, setErrorS] = useState<any>('');

  const [mainObjGL, setMainObjGL] = useState<any[]>([]);
  const [mainObjS, setMainObjS] = useState<any[]>([]);

  const [apiLoadingGL, setApiLoadingGL] = useState(false);
  const [apiLoadingS, setApiLoadingS] = useState(false);

  const { loading, data, token, open } = usePlaidLink(socialUserName, email);
  const refs = useMergeRefs(searchInputRef);
  const verdeLocale = localStorage.getItem('verdeLocale');
  const dispatch = useDispatch();
  useEffect(() => {
    getMyVerde(authUser && authUser.username);
  }, [authUser]);

  useEffect(() => {
    if (portfolioLinked) {
      const response2 = WrappedAxios.get(
        `${PLAID_GENERATE_TOKEN}/userholdings?all_portfolios=true`
      )
        .then((response2) => {
          setPortfolioData(response2.data);
        })
        .catch((error) => {
          // alert(error.message);
        });
    }
  }, [portfolioLinked]);

  useEffect(() => {
    if (searchTerm) {
      const value = searchTerm.toUpperCase();
      let count = 0;
      setMatchingSymbolNames(filterBySymbolsAndNames(symbolNames, value));
    } else {
      setMatchingSymbolNames([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    document.title = `Verde Finance - Home`;

    const response = WrappedAxios.get(
      `${SYMBOL_BASE_URL}/topgainers?interval=one_day&country=${verdeLocale}`
    )
      .then((response) => {
        if (typeof response.data !== 'undefined') {
          setOneDayGL({ selected: oneDayGL.selected, value: response.data });
          setMainObjGL(response.data);
        } else {
          setErrorGL('Temporarily unavailable, hang on while we fix it');
        }
      })
      .catch((error) => {
        setErrorGL('Temporarily unavailable, hang on while we fix it');
      });

    const response2 = WrappedAxios.get(
      `${SYMBOL_BASE_URL}/topsectors?interval=one_day&country=${verdeLocale}`
    )
      .then((response2) => {
        if (typeof response2.data !== 'undefined') {
          setMainObjS(response2.data);
          setOneDayS({ selected: oneDayS.selected, value: response2.data });
        } else {
          setErrorS('Temporarily unavailable, hang on while we fix it');
        }
      })
      .catch((error) => {
        setErrorS('Temporarily unavailable, hang on while we fix it');
      });
  }, []);

  const [title, setTitle] = useState(
    'Change the way you invest forever with Verde'
  );

  async function makeApiCall(suffix: any, param: any) {
    if (suffix.includes('gainers') || suffix.includes('losers')) {
      setApiLoadingGL(true);
    } else {
      setApiLoadingS(true);
    }

    let value = 'placeholder';

    const response = await WrappedAxios.get(
      `${SYMBOL_BASE_URL}/${suffix}?interval=${param}&country=${verdeLocale}`
    )
      .then((response) => {
        if (typeof response.data !== 'undefined') {
          value = response.data;
        } else {
          value = 'Temporarily unavailable, hang on while we fix it';
        }
      })
      .catch((error) => {
        value = 'Temporarily unavailable, hang on while we fix it';
      });

    if (suffix.includes('gainers') || suffix.includes('losers')) {
      setApiLoadingGL(false);
    } else {
      setApiLoadingS(false);
    }
    return value;
  }

  async function setParamsGL(suffix: any) {
    if (oneDayGL.selected) {
      let result = await makeApiCall(`${suffix}`, 'one_day');
      if (typeof result !== 'object') {
        setErrorGL(result);
      } else {
        if (result === []) {
          setErrorGL('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjGL(result);
          setOneDayGL({ selected: true, value: result });
        }
      }
    } else if (sevenDayGL.selected) {
      let result = await makeApiCall(`${suffix}`, 'one_week');
      if (typeof result !== 'object') {
        setErrorGL(result);
      } else {
        if (result === []) {
          setErrorGL('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjGL(result);
          setSevenDayGL({ selected: true, value: result });
        }
      }
    } else if (oneMonthGL.selected) {
      let result = await makeApiCall(`${suffix}`, 'one_month');
      if (typeof result !== 'object') {
        setErrorGL(result);
      } else {
        if (result === []) {
          setErrorGL('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjGL(result);
          setOneMonthGL({ selected: true, value: result });
        }
      }
    } else if (threeMonthGL.selected) {
      let result = await makeApiCall(`${suffix}`, 'three_months');
      if (typeof result !== 'object') {
        setErrorGL(result);
      } else {
        if (result === []) {
          setErrorGL('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjGL(result);
          setThreeMonthGL({ selected: true, value: result });
        }
      }
    } else if (oneYearGL.selected) {
      let result = await makeApiCall(`${suffix}`, 'one_year');
      if (typeof result !== 'object') {
        setErrorGL(result);
      } else {
        if (result === []) {
          setErrorGL('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjGL(result);
          setOneYearGL({ selected: true, value: result });
        }
      }
    } else {
      let result = await makeApiCall(`${suffix}`, 'five_years');
      if (typeof result !== 'object') {
        setErrorGL(result);
      } else {
        if (result === []) {
          setErrorGL('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjGL(result);
          setFiveYearGL({ selected: true, value: result });
        }
      }
    }
  }

  async function setParamsS(suffix: any) {
    if (oneDayS.selected) {
      let result = await makeApiCall(`${suffix}`, 'one_day');
      if (typeof result !== 'object') {
        setErrorS(result);
      } else {
        if (result === []) {
          setErrorS('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjS(result);
          setOneDayS({ selected: true, value: result });
        }
      }
    } else if (sevenDayS.selected) {
      let result = await makeApiCall(`${suffix}`, 'one_week');
      if (typeof result !== 'object') {
        setErrorS(result);
      } else {
        if (result === []) {
          setErrorS('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjS(result);
          setSevenDayS({ selected: true, value: result });
        }
      }
    } else if (oneMonthS.selected) {
      let result = await makeApiCall(`${suffix}`, 'one_month');
      if (typeof result !== 'object') {
        setErrorS(result);
      } else {
        if (result === []) {
          setErrorS('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjS(result);
          setOneMonthS({ selected: true, value: result });
        }
      }
    } else if (threeMonthS.selected) {
      let result = await makeApiCall(`${suffix}`, 'three_months');
      if (typeof result !== 'object') {
        setErrorS(result);
      } else {
        if (result === []) {
          setErrorS('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjS(result);
          setThreeMonthS({ selected: true, value: result });
        }
      }
    } else if (oneYearS.selected) {
      let result = await makeApiCall(`${suffix}`, 'one_year');
      if (typeof result !== 'object') {
        setErrorS(result);
      } else {
        if (result === []) {
          setErrorGL('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjS(result);
          setOneYearS({ selected: true, value: result });
        }
      }
    } else {
      let result = await makeApiCall(`${suffix}`, 'five_years');
      if (typeof result !== 'object') {
        setErrorS(result);
      } else {
        if (result === []) {
          setErrorS('Temporarily unavailable, hang on while we fix it');
        } else {
          setMainObjS(result);
          setFiveYearS({ selected: true, value: result });
        }
      }
    }
  }

  return (
    <>
      <Box backgroundColor={darkMode ? '#161616' : '#f4f4f4'}>
        <Box fontFamily="Plus Jakarta Display">
          <VerdeHeader
            setTheme={setTheme}
            auth={authUser ? true : false}
            hiddenSearch
          />
          <Box>
            <Box pb="50px">
              <Container mt="50px" p="10" pb="0">
                <Flex
                  flexDir="column"
                  position="relative"
                  flex="1"
                  justifyItems="center"
                  alignItems="center"
                >
                  <MotionBox
                    display="flex"
                    flexDirection="column"
                    textAlign={['center']}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      fontSize={['30px', '40px']}
                      fontWeight="bold"
                      maxW="xl"
                      lineHeight={['1.33']}
                      className=""
                      alignItems="center"
                      color={themeStyleObj.heroText}
                    >
                      Welcome, {authUser.attributes.name}
                    </Box>
                    {/* <Box
                    maxW="56"
                    mt="2"
                    textAlign="center"
                    color={themeStyleObj.heroDescription}
                  >
                    Be the first to explore Verde’s revolutionary platform{' '}
                  </Box> */}
                  </MotionBox>
                  <MotionBox zIndex="11">
                    <InputGroup
                      size="lg"
                      mt="8"
                      w={['xs', 'md']}
                      outline="none"
                      border="0.5px solid gray"
                      bg={themeStyleObj.searchBg}
                      position="relative"
                      mx={['auto', 'initial']}
                    >
                      <InputLeftElement
                        mx={2}
                        outline="none"
                        ml={4}
                        transform="translateY(-50%)"
                        w="max"
                        justifyContent="end"
                        top="50%"
                      >
                        {searchIcon}
                      </InputLeftElement>
                      <Input
                        type="text"
                        outline="none"
                        _hover={{ borderColor: '#24b552' }}
                        _focus={{ borderColor: 'none', boxShadow: 'none' }}
                        ref={searchInputRef}
                        color={themeStyleObj.heroText}
                        placeholder="Search for Stocks, ETFs, Superinvestors"
                        borderWidth="0px"
                        size={'md'}
                        onInput={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const target = event.target;
                          setSearchTerm(target.value.toUpperCase());
                        }}
                      />
                    </InputGroup>
                    {searchTerm && (
                      <>
                        <ClickedOutside
                          onClicked={() => {
                            setSearchTerm('');
                            setMatchingSymbolNames([]);
                          }}
                        >
                          <ContentWrapper
                            style={{
                              width: searchInputRef.current
                                ? searchInputRef.current.offsetWidth + 'px'
                                : 'auto',
                              backgroundColor: darkMode ? '#262626' : 'white',
                            }}
                            className="absolute max-h-80 overflow-y-scroll z-10"
                          >
                            <div className="flex-row">
                              {symbolNamesLoading ? (
                                <LoadingIndicator />
                              ) : matchingSymbolNames.length === 0 ? (
                                <Highlight className="text-sm text-center">
                                  No matching symbol found.
                                </Highlight>
                              ) : (
                                matchingSymbolNames.map(
                                  (
                                    entity: {
                                      symbol: string;
                                      id: string;
                                      name: string;
                                      type: string;
                                      market: string;
                                      fund_name: string;
                                      image: string;
                                    },
                                    index
                                  ) => (
                                    <React.Fragment key={index}>
                                      <SearchItem>
                                        <Link
                                          to={getEntityLink(
                                            entity,
                                            publicSymbols.includes(entity.id),
                                            subscriptionStatus
                                          )}
                                          onClick={() => {
                                            if (entity.type === 'equity') {
                                              clevertap.event.push(
                                                'Stock Searched',
                                                {
                                                  'Stock Name': `${entity.symbol}`,
                                                  Id: entity.id,
                                                  Date: new Date(),
                                                  Username: socialUserName,
                                                  Email: email,
                                                }
                                              );
                                            } else if (entity.type === 'etf') {
                                              clevertap.event.push(
                                                'ETF Searched',
                                                {
                                                  'ETF Name': `${entity.market}`,
                                                  Id: entity.id,
                                                  Date: new Date(),
                                                  Username: socialUserName,
                                                  Email: email,
                                                }
                                              );
                                            } else if (entity.type === 'user') {
                                              clevertap.event.push(
                                                'User Searched',
                                                {
                                                  "Searched User's Name": `${entity.name}`,
                                                  Id: entity.id,
                                                  Date: new Date(),
                                                  Username: socialUserName,
                                                  Email: email,
                                                }
                                              );
                                            } else {
                                              clevertap.event.push(
                                                'SuperInvestor Searched',
                                                {
                                                  Superinvestor: `${entity.fund_name}`,
                                                  Id: entity.id,
                                                  Date: new Date(),
                                                  Username: socialUserName,
                                                  Email: email,
                                                }
                                              );
                                            }

                                            setSearchTerm('');
                                            setMatchingSymbolNames([]);
                                          }}
                                          className="no-underline text-black"
                                        >
                                          <div className="flex">
                                            <SymbolLogo
                                              logoUrl={getLogoUrl(
                                                entity.symbol,
                                                entity.image
                                              )}
                                            />
                                            <div className="flex flex-col ml-4 justify-evenly w-full">
                                              <ColoredContentText className="font-medium">
                                                {getEntityName(entity)}
                                              </ColoredContentText>

                                              <div className="flex justify-between">
                                                <Subtle className="text-xs">
                                                  {getEntitySubtext(entity)}
                                                </Subtle>
                                                <Subtle className="text-xs">
                                                  {entity.market}
                                                </Subtle>
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      </SearchItem>
                                      <CompanyDivider />
                                    </React.Fragment>
                                  )
                                )
                              )}
                            </div>
                          </ContentWrapper>
                        </ClickedOutside>
                      </>
                    )}
                  </MotionBox>
                </Flex>
              </Container>
            </Box>
          </Box>
        </Box>

        {/* My verde */}
        <Container
          maxW="95%"
          position="relative"
          zIndex="0"
          display="flex"
          justifyContent="space-between"
          justifyItems="center"
          flexDirection={isTablet ? 'column' : 'row'}
          marginBottom="10"

          // hidden={!token}
        >
          {portfolioLinked ? (
            <Box width={isTablet ? '100%' : '31%'} margin="5px">
              <MyVerdeWidget
                data={portfolioData}
                username={socialUserName}
                darkMode={darkMode}
                link={() => {
                  clevertap.event.push('User Clicked on Link Portfolio', {
                    Email: email,
                    Username: socialUserName,
                  });
                  open();
                }}
              />
            </Box>
          ) : (
            <Flex
              border="1px solid"
              p={10}
              borderRadius="md"
              borderColor={theme.myVerdeborderColor}
              bg={theme.myVerdeBg}
              flexDirection="column"
              width={isTablet ? '100%' : '30%'}
              mx="auto"
              margin="5px"
            >
              <img width="150px" height="150px" src={portfolioImage}></img>
              <Text fontSize="25px" fontWeight="bold" marginTop="15px">
                Link your portfolio for the full experience
              </Text>
              <Text my="2">
                Analyze your portfolio, discover areas for improvement, and read
                the latest news to make informed decisions.
              </Text>
              <Button
                my="2"
                //borderColor={theme.applyBtnBg}
                borderWidth="1px"
                color={theme.applyBtnColor}
                bg={theme.applyBtnBg}
                _hover={{
                  bg: theme.applyBtnHover,
                  color: 'white',
                }}
                onClick={() => {
                  clevertap.event.push('User Clicked on Link Portfolio', {
                    Email: email,
                    Username: socialUserName,
                  });
                  open();
                }}
              >
                {'Link Portfolio'}
              </Button>
            </Flex>
          )}

          <Flex
            p={5}
            borderColor={theme.myVerdeborderColor}
            bg={darkMode ? '#262626' : 'white'}
            flexDirection="column"
            width={isTablet ? '100%' : '35%'}
            flex="1"
            margin="5px"
          >
            <div>
              <Container
                display="flex"
                padding="0"
                color={!darkMode ? '#1d212b' : 'white'}
                borderBottom="0.5px solid gray"
                mb="10px"
              >
                <button
                  style={
                    gainersActive
                      ? {
                          borderBottom: '1.5px solid #24b552',
                          color: '#24b552',
                          fontWeight: 'bolder',
                        }
                      : { fontWeight: 'bold', color: 'gray' }
                  }
                  onClick={(e) => {
                    if (!gainersActive) {
                      setGainersActive(true);
                      setLosersActive(false);

                      setParamsGL('topgainers');
                    }
                  }}
                >
                  Top Gainers
                </button>
                <button
                  style={
                    losersActive
                      ? {
                          borderBottom: '1.5px solid #24b552',
                          color: '#24b552',
                          marginLeft: '20px',
                          fontWeight: 'bolder',
                        }
                      : {
                          marginLeft: '20px',
                          fontWeight: 'bold',
                          color: 'gray',
                        }
                  }
                  onClick={() => {
                    if (!losersActive) {
                      setLosersActive(true);
                      setGainersActive(false);

                      setParamsGL('toplosers');
                    }
                  }}
                >
                  Top Losers
                </button>
              </Container>
            </div>

            <Container
              display="flex"
              justifyItems="center"
              padding="0"
              justifyContent="space-between"
              color={!darkMode ? '#1d212b' : 'white'}
              bg={darkMode ? '#141920' : 'white'}
              borderRadius="5px"
              border="0.5px solid gray"
              height="30px"
              mt="2"
            >
              <button
                style={
                  oneDayGL.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!oneDayGL.selected) {
                    setOneDayGL({ selected: true, value: oneDayGL.value });
                    setSevenDayGL({ selected: false, value: sevenDayGL.value });
                    setOneMonthGL({ selected: false, value: oneMonthGL.value });
                    setThreeMonthGL({
                      selected: false,
                      value: threeMonthGL.value,
                    });
                    setOneYearGL({ selected: false, value: oneYearGL.value });
                    setFiveYearGL({ selected: false, value: fiveYearGL.value });

                    let result = await makeApiCall(
                      gainersActive ? 'topgainers' : 'toplosers',
                      'one_day'
                    );
                    if (typeof result !== 'object') {
                      setErrorGL(result);
                    } else {
                      if (result === []) {
                        setErrorGL(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjGL(result);
                        setOneDayGL({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                1D
              </button>

              <button
                style={
                  sevenDayGL.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!sevenDayGL.selected) {
                    setSevenDayGL({ selected: true, value: sevenDayGL.value });

                    setOneDayGL({ selected: false, value: oneDayGL.value });
                    setOneMonthGL({ selected: false, value: oneMonthGL.value });
                    setThreeMonthGL({
                      selected: false,
                      value: threeMonthGL.value,
                    });
                    setOneYearGL({ selected: false, value: oneYearGL.value });
                    setFiveYearGL({ selected: false, value: fiveYearGL.value });

                    let result = await makeApiCall(
                      gainersActive ? 'topgainers' : 'toplosers',
                      'one_week'
                    );

                    if (typeof result !== 'object') {
                      setErrorGL(result);
                    } else {
                      if (result === []) {
                        setErrorGL(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjGL(result);
                        setSevenDayGL({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                7D
              </button>

              <button
                style={
                  oneMonthGL.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!oneMonthGL.selected) {
                    setOneMonthGL({ selected: true, value: oneMonthGL.value });

                    setOneDayGL({ selected: false, value: oneDayGL.value });
                    setSevenDayGL({ selected: false, value: sevenDayGL.value });
                    setThreeMonthGL({
                      selected: false,
                      value: threeMonthGL.value,
                    });
                    setOneYearGL({ selected: false, value: oneYearGL.value });
                    setFiveYearGL({ selected: false, value: fiveYearGL.value });

                    let result = await makeApiCall(
                      gainersActive ? 'topgainers' : 'toplosers',
                      'one_month'
                    );
                    if (typeof result !== 'object') {
                      setErrorGL(result);
                    } else {
                      if (result === []) {
                        setErrorGL(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjGL(result);
                        setOneMonthGL({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                1M
              </button>

              <button
                style={
                  threeMonthGL.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!threeMonthGL.selected) {
                    setThreeMonthGL({
                      selected: true,
                      value: threeMonthGL.value,
                    });

                    setOneDayGL({ selected: false, value: oneDayGL.value });
                    setSevenDayGL({ selected: false, value: sevenDayGL.value });
                    setOneMonthGL({ selected: false, value: oneMonthGL.value });
                    setOneYearGL({ selected: false, value: oneYearGL.value });
                    setFiveYearGL({ selected: false, value: fiveYearGL.value });

                    let result = await makeApiCall(
                      gainersActive ? 'topgainers' : 'toplosers',
                      'three_months'
                    );
                    if (typeof result !== 'object') {
                      setErrorGL(result);
                    } else {
                      if (result === []) {
                        setErrorGL(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjGL(result);
                        setThreeMonthGL({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                3M
              </button>

              <button
                style={
                  oneYearGL.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!oneYearGL.selected) {
                    setOneYearGL({ selected: true, value: oneYearGL.value });

                    setOneDayGL({ selected: false, value: oneDayGL.value });
                    setSevenDayGL({ selected: false, value: sevenDayGL.value });
                    setOneMonthGL({ selected: false, value: oneMonthGL.value });
                    setThreeMonthGL({
                      selected: false,
                      value: threeMonthGL.value,
                    });
                    setFiveYearGL({ selected: false, value: fiveYearGL.value });

                    let result = await makeApiCall(
                      gainersActive ? 'topgainers' : 'toplosers',
                      'one_year'
                    );
                    if (typeof result !== 'object') {
                      setErrorGL(result);
                    } else {
                      if (result === []) {
                        setErrorGL(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjGL(result);
                        setOneYearGL({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                1Y
              </button>

              <button
                style={
                  fiveYearGL.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!fiveYearGL.selected) {
                    setFiveYearGL({ selected: true, value: fiveYearGL.value });

                    setOneDayGL({ selected: false, value: oneDayGL.value });
                    setSevenDayGL({ selected: false, value: sevenDayGL.value });
                    setOneMonthGL({ selected: false, value: oneMonthGL.value });
                    setThreeMonthGL({
                      selected: false,
                      value: threeMonthGL.value,
                    });
                    setOneYearGL({ selected: false, value: oneYearGL.value });

                    let result = await makeApiCall(
                      gainersActive ? 'topgainers' : 'toplosers',
                      'five_years'
                    );
                    if (typeof result !== 'object') {
                      setErrorGL(result);
                    } else {
                      if (result === []) {
                        setErrorGL(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjGL(result);
                        setFiveYearGL({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                {' '}
                5Y
              </button>
            </Container>

            <div>
              <MotionFlex mt="4" display="block">
                {errorGL !== '' ? (
                  <h1
                    style={
                      darkMode
                        ? { margin: '0', color: 'white', textAlign: 'center' }
                        : { margin: '0', color: '#24b552', textAlign: 'center' }
                    }
                  >
                    {' '}
                    {errorGL}
                  </h1>
                ) : apiLoadingGL ? (
                  <LoadingIndicator />
                ) : (
                  mainObjGL.map((stock) => (
                    <Link
                      to={
                        subscriptionStatus.active
                          ? `/stocks/${stock.symbol_market}`
                          : `/stocks-basic/${stock.symbol_market}`
                      }
                      className="flex-1"
                    >
                      <MotionFlex
                        _hover={{
                          transform: 'scale(1.05)',
                          transition: '0.1s all ease-in',
                        }}
                        padding="5px"
                        mt={2}
                        borderRadius="5px"
                        border="0.5px solid gray"
                        hover
                        bg={darkMode ? '#262626' : 'white'}
                      >
                        <Box padding="1" display="flex">
                          <>
                            {!notExistsObj[
                              stock.symbol_market.split('.')[0]
                            ] ? (
                              <img
                                style={{
                                  width: isMobile ? '30px' : '40px',
                                  height: isMobile ? '30px' : '40px',
                                  marginTop: isMobile ? '5px' : '0px',
                                }}
                                src={`http://images.verde.finance/${
                                  stock.symbol_market.split('.')[0]
                                }.png`}
                                onError={() =>
                                  setNotExistsObj({
                                    ...notExistsObj,
                                    [stock.symbol_market.split('.')[0]]: 1,
                                  })
                                }
                              />
                            ) : (
                              <LetterLogo
                                symbol={stock.symbol_market.split('.')[0]}
                                darkMode={darkMode}
                              />
                            )}
                          </>
                          <Box display="block" marginLeft="10px">
                            <Text
                              fontWeight="bold"
                              color={darkMode ? 'white' : 'black'}
                            >
                              {stock.symbol_market.split('.')[0]}
                            </Text>
                            <Text
                              fontSize="xs"
                              color="#6b737c"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              inlineSize={isMobile ? '55px' : '85px'}
                              overflow="hidden"
                            >
                              {stock.symbol_name}
                            </Text>
                          </Box>
                        </Box>

                        <Box mt="2" margin="auto" display="block">
                          <Text color={darkMode ? 'white' : 'black'}>
                            ${formatDecimalValue(stock.current_price)}
                          </Text>
                          <Text
                            color={
                              stock.change_percentage > 0
                                ? '#559C30'
                                : 'red.500'
                            } // was stock.one_day
                          >
                            {stock.change_percentage}%
                          </Text>
                        </Box>

                        <Box
                          display="block"
                          mt="2"
                          justifyContent="space-between"
                          ml="auto"
                          mr="10px"
                        >
                          <Container display="flex" padding="0">
                            <img
                              src={VerdeLogo}
                              style={{
                                width: isMobile ? '15px' : '18px',
                                height: isMobile ? '15px' : '18px',
                                marginTop: isMobile ? '5px' : '2px',
                              }}
                            />
                            <Text
                              color={darkMode ? 'white' : 'black'}
                              marginLeft="5px"
                            >
                              {stock.verde_score}
                            </Text>
                          </Container>
                          <Text color="gray" marginLeft="1">
                            Score
                          </Text>
                        </Box>
                      </MotionFlex>
                    </Link>
                  ))
                )}
              </MotionFlex>
            </div>
          </Flex>

          <Flex
            p={5}
            borderColor={theme.myVerdeborderColor}
            bg={darkMode ? '#262626' : 'white'}
            flexDirection="column"
            width={isTablet ? '100%' : '35%'}
            flex="1"
            margin="5px"
          >
            <div>
              <Container
                display="flex"
                padding="0"
                color={!darkMode ? '#1d212b' : 'white'}
                borderBottom="0.5px solid gray"
                mb="10px"
              >
                <button
                  style={
                    tSectorsActive
                      ? {
                          borderBottom: '1.5px solid #24b552',
                          color: '#24b552',
                          fontWeight: 'bolder',
                        }
                      : { fontWeight: 'bold', color: 'gray' }
                  }
                  onClick={(e) => {
                    if (!tSectorsActive) {
                      setTSectorsActive(true);
                      setWSectorsActive(false);

                      setParamsS('topsectors');
                    }
                  }}
                >
                  Top Sectors
                </button>
                <button
                  style={
                    wSectorsActive
                      ? {
                          borderBottom: '1.5px solid #24b552',
                          color: '#24b552',
                          marginLeft: '20px',
                          fontWeight: 'bolder',
                        }
                      : {
                          fontWeight: 'bold',
                          color: 'gray',
                          marginLeft: '20px',
                        }
                  }
                  onClick={() => {
                    if (!wSectorsActive) {
                      setWSectorsActive(true);
                      setTSectorsActive(false);

                      setParamsS('worstsectors');
                    }
                  }}
                >
                  Worst Sectors
                </button>
              </Container>
            </div>

            <Container
              display="flex"
              justifyContent="space-between"
              color={!darkMode ? '#1d212b' : 'white'}
              bg={darkMode ? '#141920' : 'white'}
              borderRadius="5px"
              border="0.5px solid gray"
              height="30px"
              padding="0"
              mt="2"
            >
              <button
                style={
                  oneDayS.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!oneDayS.selected) {
                    setOneDayS({ selected: true, value: oneDayGL.value });

                    setSevenDayS({ selected: false, value: sevenDayS.value });
                    setOneMonthS({ selected: false, value: oneMonthS.value });
                    setThreeMonthS({
                      selected: false,
                      value: threeMonthS.value,
                    });
                    setOneYearS({ selected: false, value: oneYearS.value });
                    setFiveYearS({ selected: false, value: fiveYearS.value });

                    let result = await makeApiCall(
                      tSectorsActive ? 'topsectors' : 'worstsectors',
                      'one_day'
                    );
                    if (typeof result !== 'object') {
                      setErrorS(result);
                    } else {
                      if (result === []) {
                        setErrorS(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjS(result);
                        setOneDayS({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                1D
              </button>

              <button
                style={
                  sevenDayS.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!sevenDayS.selected) {
                    setSevenDayS({ selected: true, value: sevenDayS.value });

                    setOneDayS({ selected: false, value: oneDayGL.value });
                    setOneMonthS({ selected: false, value: oneMonthS.value });
                    setThreeMonthS({
                      selected: false,
                      value: threeMonthS.value,
                    });
                    setOneYearS({ selected: false, value: oneYearS.value });
                    setFiveYearS({ selected: false, value: fiveYearS.value });

                    let result = await makeApiCall(
                      tSectorsActive ? 'topsectors' : 'worstsectors',
                      'one_week'
                    );
                    if (typeof result !== 'object') {
                      setErrorS(result);
                    } else {
                      if (result === []) {
                        setErrorS(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjS(result);
                        setSevenDayS({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                7D
              </button>

              <button
                style={
                  oneMonthS.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!oneMonthS.selected) {
                    setOneMonthS({ selected: true, value: oneMonthS.value });

                    setOneDayS({ selected: false, value: oneDayGL.value });
                    setSevenDayS({ selected: false, value: sevenDayS.value });
                    setThreeMonthS({
                      selected: false,
                      value: threeMonthS.value,
                    });
                    setOneYearS({ selected: false, value: oneYearS.value });
                    setFiveYearS({ selected: false, value: fiveYearS.value });

                    let result = await makeApiCall(
                      tSectorsActive ? 'topsectors' : 'worstsectors',
                      'one_month'
                    );
                    if (typeof result !== 'object') {
                      setErrorS(result);
                    } else {
                      if (result === []) {
                        setErrorS(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjS(result);
                        setOneMonthS({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                1M
              </button>

              <button
                style={
                  threeMonthS.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!threeMonthS.selected) {
                    setThreeMonthS({
                      selected: true,
                      value: threeMonthS.value,
                    });

                    setOneDayS({ selected: false, value: oneDayGL.value });
                    setSevenDayS({ selected: false, value: sevenDayS.value });
                    setOneMonthS({ selected: false, value: oneMonthS.value });
                    setOneYearS({ selected: false, value: oneYearS.value });
                    setFiveYearS({ selected: false, value: fiveYearS.value });

                    let result = await makeApiCall(
                      tSectorsActive ? 'topsectors' : 'worstsectors',
                      'three_months'
                    );
                    if (typeof result !== 'object') {
                      setErrorS(result);
                    } else {
                      if (result === []) {
                        setErrorS(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjS(result);
                        setThreeMonthS({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                3M
              </button>

              <button
                style={
                  oneYearS.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!oneYearS.selected) {
                    setOneYearS({ selected: true, value: oneYearS.value });

                    setOneDayS({ selected: false, value: oneDayGL.value });
                    setSevenDayS({ selected: false, value: sevenDayS.value });
                    setOneMonthS({ selected: false, value: oneMonthS.value });
                    setThreeMonthS({
                      selected: false,
                      value: threeMonthS.value,
                    });
                    setFiveYearS({ selected: false, value: fiveYearS.value });

                    let result = await makeApiCall(
                      tSectorsActive ? 'topsectors' : 'worstsectors',
                      'one_year'
                    );
                    if (typeof result !== 'object') {
                      setErrorS(result);
                    } else {
                      if (result === []) {
                        setErrorS(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjS(result);
                        setOneYearS({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                1Y
              </button>

              <button
                style={
                  fiveYearS.selected
                    ? {
                        backgroundColor: '#24b552',
                        flex: '0.2',
                        borderRadius: '5px',
                      }
                    : { flex: '0.2' }
                }
                onClick={async () => {
                  if (!fiveYearS.selected) {
                    setFiveYearS({ selected: true, value: fiveYearS.value });

                    setOneDayS({ selected: false, value: oneDayGL.value });
                    setSevenDayS({ selected: false, value: sevenDayS.value });
                    setOneMonthS({ selected: false, value: oneMonthS.value });
                    setThreeMonthS({
                      selected: false,
                      value: threeMonthS.value,
                    });
                    setOneYearS({ selected: false, value: oneYearS.value });

                    let result = await makeApiCall(
                      tSectorsActive ? 'topsectors' : 'worstsectors',
                      'five_years'
                    );
                    if (typeof result !== 'object') {
                      setErrorS(result);
                    } else {
                      if (result === []) {
                        setErrorS(
                          'Temporarily unavailable, hang on while we fix it'
                        );
                      } else {
                        setMainObjS(result);
                        setFiveYearS({ selected: true, value: result });
                      }
                    }
                  }
                }}
              >
                {' '}
                5Y
              </button>
            </Container>

            <div>
              <MotionFlex mt="4" display="block">
                {errorS !== '' ? (
                  <h1
                    style={
                      darkMode
                        ? { margin: '0', color: 'white', textAlign: 'center' }
                        : { margin: '0', color: '#24b552', textAlign: 'center' }
                    }
                  >
                    {' '}
                    {errorS}
                  </h1>
                ) : apiLoadingS ? (
                  <LoadingIndicator />
                ) : (
                  mainObjS.map((stock) => (
                    <Link to={`/etfs/${stock.symbol_market}`}>
                      <MotionFlex
                        _hover={{
                          transform: 'scale(1.04)',
                          transition: '0.1s all ease-in',
                        }}
                        padding="5px"
                        mt={2}
                        borderRadius="5px"
                        border="0.5px solid gray"
                        hover
                        bg={darkMode ? '#262626' : 'white'}
                      >
                        <Box padding="1" display="flex">
                          <SectorLogo
                            value={stock.symbol_market.split('.')[0]}
                            size="large"
                            fill={undefined}
                          />

                          <Box display="block" marginLeft="10px">
                            <Text
                              fontWeight="bold"
                              color={darkMode ? 'white' : 'black'}
                            >
                              {stock.symbol_market.split('.')[0]}
                            </Text>
                            <Text
                              fontSize="xs"
                              color="#6b737c"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              // inlineSize={isMobile ? "55px" : "85px"}

                              overflow="hidden"
                            >
                              {stock.symbol_name}
                            </Text>
                          </Box>
                        </Box>

                        <Box
                          mt="2"
                          justifyContent="space-between"
                          ml="auto"
                          mr="10px"
                        >
                          <Text color={darkMode ? 'white' : 'black'}>
                            ${formatDecimalValue(stock.current_price)}
                          </Text>
                          <Text
                            color={
                              stock.change_percentage > 0
                                ? '#36a867'
                                : 'red.500'
                            }
                          >
                            {stock.change_percentage}%
                          </Text>
                        </Box>
                      </MotionFlex>
                    </Link>
                  ))
                )}
              </MotionFlex>
            </div>
          </Flex>
        </Container>
        <Footer isDark={darkMode} />
      </Box>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    darkMode: state.coreState.darkMode,
    isPageLoading: state.coreState.loading,
    symbolNames: state.symbolState.symbolNames.data,
    symbolNamesLoading: state.symbolState.symbolNames.loading,
    portfolioLinked: state.myVerde.linked,
    socialUserName: state.myVerde.username,
    email: state.myVerde.email,
    subscriptionStatus: state.coreState.subscriptionStatus,
    publicSymbols: state.symbolState.publicSymbols?.data,
  };
};
export default connect(mapStateToProps)(NewHomePage);
