import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { PaginationButton, TableContainer, StyledTableHead } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderColor: '#59bb88',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    height: '2.5rem',
    fontWeight: 500,
    '&:hover': {
      borderColor: '#59bb88',
      boxShadow: '0 0 0 1px #fff',
      outline: 'none',
      cursor: 'pointer',
    },
  }),
  singleValue: (styles) => ({ ...styles, color: '#59bb88' }),
  placeholder: (styles) => ({ ...styles, color: '#59bb88' }),
  dropdownIndicator: (styles) => ({ ...styles, color: '#59bb88' }),
  indicatorSeparator: (styles) => ({ ...styles, backgroundColor: '#59bb88' }),
};

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
];

export const StockTable = ({
  columns,
  data,
  defaultPageSize,
  onSort,
  onPagination,
  pageCount: controlledPageCount,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageSize, sortBy, pageIndex },
  } = useTable(
    {
      columns,
      data,
      maxMultiSortColCount: 1,
      manualSortBy: true,
      manualPagination: true,
      initialState: {
        pageSize: defaultPageSize,
        pageIndex: 0,
        sortBy: [{ id: 'marketCap', desc: true }],
      },
      pageCount: controlledPageCount,
      noDataText: 'No Data',
    },
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    onSort(sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  React.useEffect(() => {
    onPagination(pageIndex, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageIndex]);

  return (
    <>
      <TableContainer>
        <table {...getTableProps()} className="w-full">
          <StyledTableHead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, id) => (
                  <th
                    {...column.getHeaderProps()}
                    className="text-left font-medium text-sm"
                  >
                    <div
                      className="text-center flex justify-center items-center"
                      {...column.getSortByToggleProps()}
                      onClick={(e) => {
                        column.isSortedDesc === undefined
                          ? column.toggleSortBy(false, true)
                          : column.isSortedDesc === true
                          ? column.clearSortBy()
                          : column.toggleSortBy(true, true);
                      }}
                    >
                      <div>{column.render('Header')}</div>
                      <div>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon
                              className="ml-2"
                              icon={faArrowDown}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ml-2"
                              icon={faArrowUp}
                            />
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </StyledTableHead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
            {page.length === 0 && (
              <tr>
                <td className="text-center text-sm font-medium" colSpan="99">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </TableContainer>
      <div className="my-4">
        <div className="flex justify-center">
          <PaginationButton
            className="md:mx-4"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </PaginationButton>
          <div className="mx-4">
            <Select
              className="text-sm w-20"
              options={options}
              styles={selectStyles}
              defaultValue={options[0]}
              onChange={({ value }) => {
                setPageSize(value);
              }}
              menuPlacement="top"
            />
          </div>

          <PaginationButton
            className="md:mx-4"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </PaginationButton>
        </div>
      </div>
    </>
  );
};

export default StockTable;
