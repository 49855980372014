import React from 'react';
import RatioGraph from '../../../RatioGraph';
import RatioGraphInsights from '../../../RatioGraphInsights';
import SectionHeader from '../../../SectionHeader';

const PriceToEarnings = ({ data, symbol, insights }) => {
  const ratios = [
    { name: 'Symbol', value: data.symbol },
    { name: 'Sector', value: data.sector },
    { name: 'Market', value: data.market },
  ];

  return (
    <>
      <SectionHeader value="Price to Earnings (PE)" infoKey="pe" />
      <RatioGraph ratios={ratios} symbol={symbol} suffix="x" />
      <RatioGraphInsights title="PE" insights={insights} />
    </>
  );
};

export default PriceToEarnings;
