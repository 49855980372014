import React from 'react';
import {
  BorderedChartContainer,
  KeyTextButton,
} from '../../../StyedComponents';
import { getInsidersChartData } from '../../../../../utils/ChartUtils';
import StackedBarChart from '../../../StackedBarChart';
import InsidersTable from '../../../InsidersTable';
import { insidersColumns as columns } from '../../../InsidersTable/columns';
import InsidersModal from '../../../InsidersModal';
import SectionHeader from '../../../SectionHeader';
import BulletPoints from '../../../BulletPoints';

const InsiderTransactions = ({ data, insights }) => {
  const [openInsidersModal, setOpenInsidersModal] = React.useState(false);
  return (
    <>
      <SectionHeader value="Insiders Transaction" className="pb-6" />
      <BorderedChartContainer className="py-4">
        <StackedBarChart
          data={getInsidersChartData(data)}
          name="Insiders"
          color="#65e1ee"
        />
      </BorderedChartContainer>

      <BulletPoints
        title="Positives"
        points={insights?.netInsiderTransaction?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.netInsiderTransaction?.negatives}
        isPositive={false}
      />

      <div className="mt-12">
        <div className="flex justify-end mb-2">
          <KeyTextButton onClick={() => setOpenInsidersModal(true)}>
            View All
          </KeyTextButton>
        </div>
        <InsidersTable columns={columns} data={data.filter((d, i) => i < 5)} />
      </div>
      {openInsidersModal && (
        <InsidersModal
          title="Insiders Transaction"
          data={data}
          open={openInsidersModal}
          setOpen={setOpenInsidersModal}
          columns={columns}
        />
      )}
    </>
  );
};

export default InsiderTransactions;
