import React from 'react';
import { connect } from 'react-redux';
import { getFutureGrowthData } from '../../../actions/action';
import { showPageLoading } from '../../../actions/coreAction';
import { useParams } from 'react-router-dom';
import EarningsProjection from '../../shared/Sections/FutureGrowth/EarningsPerShareProjection';
import { SectionDivider } from '../../shared/StyedComponents';
import ProjectedEarnings from '../../shared/Sections/FutureGrowth/NetIncomeProjection';
import RevenueProjection from '../../shared/Sections/FutureGrowth/RevenueProjection';
import SectionRating from '../../shared/SectionRating';

function FutureGrowth({
  symbol,
  loading,
  showPageLoading,
  getFutureGrowthData,
  data,
  insights,
  error,
  symbolData,
}) {
  const params = useParams();
  React.useEffect(() => {
    params.sid !== symbol && getFutureGrowthData(params.sid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    showPageLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (error) throw new Error('Error in future growth api');

  return (
    data &&
    data.length > 0 && (
      <>
        <SectionRating
          title="Growth Rating"
          infoKey="growth_rating"
          showTitle={true}
          rating={symbolData?.symbol?.growthRating}
        />
        <SectionDivider />
        <EarningsProjection data={data} insights={insights} />
        <SectionDivider />
        <RevenueProjection data={data} insights={insights} />
        <SectionDivider />
        <ProjectedEarnings data={data} insights={insights} />
        <SectionDivider />
      </>
    )
  );
}

const mapStateToProps = (state) => ({
  symbol: state.futureGrowthState.symbol,
  loading: state.futureGrowthState.loading || state.valuationState.loading,
  data: state.futureGrowthState.data,
  insights: state.symbolInsightsState.data?.futureGrowthInsights,
  error: state.futureGrowthState.error,
  symbolData: state.valuationState.data,
});
const mapDispatchToProps = {
  showPageLoading,
  getFutureGrowthData,
};

export default connect(mapStateToProps, mapDispatchToProps)(FutureGrowth);
