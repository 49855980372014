import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';

function* fetchStockInfo(action) {
  yield put({ type: 'FETCH_STOCK_INFO_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .get(
        `${SYMBOL_BASE_URL}/stockpricehistory?symbol=${action.payload.toUpperCase()}`
      )
      .then((response) => {
        return response.data;
      });
    yield put({ type: 'FETCH_STOCK_INFO_SUCCESS', data: responseData });
  } catch (error) {
    yield put({ type: 'FETCH_STOCK_INFO_ERROR', error: error });
  }
}

export function* watchFetchingStockData() {
  yield takeEvery('FETCH_STOCK_INFO', fetchStockInfo);
}
