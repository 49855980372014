import React from 'react';
import { KeyTextButton } from '../StyedComponents';

const ReadMore = ({
  text,
  width,
  noOfLines = 2,
  fontWidth = 6.5,
  darkMode,
}) => {
  const readMoreWidth = 70;
  const [showMore, setShowMore] = React.useState(false);
  const [endOfDesc, setEndOfDesc] = React.useState(
    width / fontWidth - readMoreWidth / fontWidth
  );
  const totalLength = text ? text.length : 0;
  const isFull = totalLength === endOfDesc;

  React.useEffect(() => {
    setEndOfDesc(
      showMore ? totalLength : width / fontWidth - readMoreWidth / fontWidth
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMore, width]);

  return text ? (
    <div
      className="text-justify leading-normal"
      style={{ textAlignLast: isFull ? 'start' : 'justify' }}
    >
      {text
        .substr(0, endOfDesc * noOfLines)
        .trim()
        .concat(endOfDesc < text.length ? '...' : '')}

      <KeyTextButton
        className="underline ml-1"
        style={{
          minWidth: readMoreWidth,
          color: darkMode ? '#58a8f2' : '#3852e1',
        }}
        onClick={() => setShowMore(!showMore)}
      >
        Read {isFull ? 'less' : 'more'}
      </KeyTextButton>
    </div>
  ) : (
    <div>'No Description Available.'</div>
  );
};

export default ReadMore;
