import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

const Badge = styled.div`
  ${tw`text-sm font-medium flex justify-center items-center rounded-full`}
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.theme.numberBadge};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const NumberBadge = ({ value, size = 24, bgColor = '#fff', className }) => {
  return (
    <Badge size={size} bgColor={bgColor} className={className}>
      {value}
    </Badge>
  );
};

export default NumberBadge;
