import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useState, FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import VerdeLogo from '../../../../public/images/VerdeLogo.png';
import { DefaultTheme, useTheme } from 'styled-components';
import { showPageLoading } from '../../../../actions/coreAction';
import PageContainer from '../../../shared/PageContainer';
import { useMediaQuery } from '@chakra-ui/react';
import WrappedAxios from '../../../../utils/WrappedAxios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import VerdeScoreSvg from '../../../../public/images/verde-icon.svg';
import defaultPic from '../../../../public/images/logo-not-found.png';
import { USER_SOCIAL_LINK } from '../../../../config/default';

const PageContainerW = PageContainer;

function TopProfile({ profileData, darkMode, showPageLoading }) {
  const [data, setData] = useState([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams();
  const [isFollowing, setIsFollowing] = useState(null);

  useEffect(() => {
    WrappedAxios.head(
      `${USER_SOCIAL_LINK}/superinvestor-friendship/${params.cik}`
    )
      .then((res) => setIsFollowing(true))
      .catch((error) => {
        setIsFollowing(false);
      });
  }, [params.cik]);

  //showPageLoading(data.length === 0);

  return (
    <Box>
      <Flex justifyContent={isMobile ? 'center' : null}>
        <img
          id="profilePicture"
          // src={`${`https://images.verde.finance/${params.cik}.jpeg`}`}
          src={profileData.length > 0 && profileData[0]['imageUrl']}
          style={{
            justifyContent: 'center',
            marginTop: 25,
            borderRadius: 100,
            maxWidth: isMobile ? 110 : 150,
            maxHeight: isMobile ? 105 : 150,
            minWidth: isMobile ? 110 : 150,
            minHeight: isMobile ? 105 : 150,
            objectFit: 'cover',
          }}
        />

        <Box marginLeft={10} marginTop={10} width={isMobile ? '60%' : '90%'}>
          <Text
            style={{
              color: darkMode ? 'white' : 'black',
              fontWeight: 500,

              textAlign: 'left',
              fontSize: 26,
            }}
          >
            {profileData.length > 0 && profileData[0]['fundManagerName']}
          </Text>
          <Text
            style={{
              color: darkMode ? '#cccccc' : '#8c8c8c',

              textAlign: 'left',
              fontSize: 15,
            }}
          >
            {profileData.length > 0 && profileData[0]['fundName']}
          </Text>
          <Text
            style={{
              color: 'gray',
              marginTop: 1,
              textAlign: 'left',
              fontSize: 15,
            }}
          >
            {profileData.length > 0 && profileData[0]['description']}
          </Text>

          <Flex justifyContent="flex-start" mt={1}>
            <Box marginRight={30}>
              <Flex marginTop={2}>
                <img
                  src={VerdeScoreSvg}
                  alt=""
                  className="w-5 h-5 mb-0.5 mr-1 mt-1"
                />
                <Text
                  style={{
                    color: darkMode ? '#f6f6f6' : 'black',
                    fontSize: isMobile ? 18 : '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {profileData.length > 0 && profileData[0]['verdeScore']}
                </Text>
              </Flex>
              <Text
                style={{
                  color: darkMode ? '#cccccc' : 'black',
                  fontSize: isMobile ? 14 : 16,
                  marginTop: 5,
                }}
              >
                Verde Score
              </Text>
            </Box>
            <Box marginTop={2}>
              <Text
                style={{
                  color: darkMode ? 'white' : 'black',
                  fontSize: isMobile ? 18 : 20,
                  fontWeight: 500,
                }}
              >
                -
              </Text>
              <Text
                style={{
                  color: darkMode ? '#cccccc' : 'black',
                  fontSize: isMobile ? 14 : 16,
                  marginTop: 5,
                }}
              >
                Followers
              </Text>
            </Box>
          </Flex>
          <button
            style={{
              padding: 5,
              width: isMobile ? '60%' : '120px',
              color: darkMode ? '#4adf79' : '#01874e',
              border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
              borderRadius: 20,
              marginTop: 20,
              fontSize: 15,
              fontWeight: 500,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              if (isFollowing !== null) {
                if (!isFollowing) {
                  // trying to follow
                  setIsFollowing(true);
                  WrappedAxios.post(
                    `${USER_SOCIAL_LINK}/superinvestor-friendship/${params.cik}`
                  ).catch((err) => {
                    setIsFollowing(false);
                    alert('Unable to follow, please try again later');
                  });
                } else {
                  // remove from following
                  setIsFollowing(false);
                  WrappedAxios.delete(
                    `${USER_SOCIAL_LINK}/superinvestor-friendship/${params.cik}`
                  ).catch((err) => {
                    setIsFollowing(true);
                    alert('Unable to remove following, please try again later');
                  });
                }
              }
            }}
          >
            {isFollowing === null ? '-' : isFollowing ? 'Following' : 'Follow'}
          </button>
        </Box>
      </Flex>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopProfile);
