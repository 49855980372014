import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { ColoredHeaderText } from '../../StyedComponents';

export const customStyles = {
  overlay: {
    top: '20px',
  },
  content: {
    bottom: '0',
    left: 'auto',
    right: '0',
    height: 'auto',
    maxWidth: '450px',
    padding: '0px',
    top: '50px',
    width: '100%',
  },
};

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#fff',
    color: '#3a3a3a',
    borderColor: '#3a3a3a',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#3a3a3a',
      boxShadow: '0 0 0 1px #3a3a3a',
      outline: 'none',
    },
  }),
  singleValue: (styles) => ({ ...styles, color: '#3a3a3a' }),
  placeholder: (styles) => ({ ...styles, color: '#3a3a3a' }),
  dropdownIndicator: (styles) => ({ ...styles, color: '#3a3a3a' }),
  indicatorSeparator: (styles) => ({ ...styles, backgroundColor: '#3a3a3a' }),
  menu: (styles) => ({ ...styles, zIndex: '10' }),
};

export const FilterBox = styled.div`
  ${tw`p-2 outline-none rounded flex justify-center items-center mr-2`}
  background-color: ${(props) =>
    props.theme.screener.defaultFilters.background};
  border: solid 1px ${(props) => props.theme.screener.defaultFilters.border};
`;

export const IconBox = styled.div`
  ${tw`h-12 w-12 outline-none rounded flex items-center my-auto`}
  border: solid 1px #e8e6e6;
  background-color: #f6f6f6;
`;

export const FilterBoxName = styled(ColoredHeaderText)`
  ${tw`text-sm font-medium mb-2`}
`;

export const AllFiltersBox = styled.div`
  ${tw`rounded`}
  border: solid 1px #498b67;
`;
