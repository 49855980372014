import React from 'react';
import { ColoredContentText } from '../StyedComponents';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import JsxParser from 'react-jsx-parser';
import Tooltip from '../Tooltip';
import DomainIcon from '../DomainIcon';
import { INFO } from '../../../config/info-tags';

const BulletTitle = styled.div`
  color: ${(props) => props.theme.bulletPoints.title};
`;

const positive = styled.span`
  ${tw`font-medium`}
  color: #569C30;
`;

const negative = styled.span`
  ${tw`font-medium`}
  color: ${(props) => props.theme.bulletPoints.negative};
`;

const DomainTerm = styled.span`
  border-bottom: 1px solid ${(props) => props.theme.tooltip.underline};
  font-weight: 600;
`;

const peratio = styled(DomainTerm)``;
const pegratio = styled(DomainTerm)``;
const pbratio = styled(DomainTerm)``;
const psratio = styled(DomainTerm)``;
const roe = styled(DomainTerm)``;
const roa = styled(DomainTerm)``;
const eps = styled(DomainTerm)``;
const revenue = styled(DomainTerm)``;
const net_income = styled(DomainTerm)``;
const dividend_yield = styled(DomainTerm)``;
const payout_ratio = styled(DomainTerm)``;
const dividend_per_share = styled(DomainTerm)``;
const cash_from_ops = styled(DomainTerm)``;
const capital_expenditures = styled(DomainTerm)``;
const total_liabilities = styled(DomainTerm)``;
const total_debt = styled(DomainTerm)``;
const total_assets = styled(DomainTerm)``;
const total_equity = styled(DomainTerm)``;
const return_on_assets = styled(DomainTerm)``;
const return_on_equity = styled(DomainTerm)``;
const return_on_invested_capital = styled(DomainTerm)``;
const gross_profit_margin = styled(DomainTerm)``;
const ebit_margin = styled(DomainTerm)``;
const ebitda_margin = styled(DomainTerm)``;
const free_cash_flow = styled(DomainTerm)``;
const free_cash_flow_margin = styled(DomainTerm)``;
const net_income_margin = styled(DomainTerm)``;
const ebit = styled(DomainTerm)``;
const ebitda = styled(DomainTerm)``;
const gross_profit = styled(DomainTerm)``;
const ev_ebit = styled(DomainTerm)``;
const ev_ebitda = styled(DomainTerm)``;
const ev_revenue = styled(DomainTerm)``;
const fcf_payout_ratio = styled(DomainTerm)``;
const ni_payout_ratio = styled(DomainTerm)``;
const total_shareholder_return = styled(DomainTerm)``;
const gross_margin = styled(DomainTerm)``;
const total_cash = styled(DomainTerm)``;
const dividend_paid = styled(DomainTerm)``;
const shares_outstanding = styled(DomainTerm)``;
const shares_data = styled(DomainTerm)``;

export const components = {
  Tooltip,
  positive,
  negative,
  peratio,
  pegratio,
  pbratio,
  psratio,
  roe,
  roa,
  eps,
  revenue,
  net_income,
  dividend_yield,
  payout_ratio,
  dividend_per_share,
  cash_from_ops,
  free_cash_flow,
  capital_expenditures,
  total_liabilities,
  total_debt,
  total_assets,
  total_equity,
  return_on_assets,
  return_on_equity,
  return_on_invested_capital,
  gross_profit_margin,
  ebit_margin,
  ebitda_margin,
  net_income_margin,
  free_cash_flow_margin,
  ebit,
  ebitda,
  gross_profit,
  ev_ebit,
  ev_ebitda,
  ev_revenue,
  fcf_payout_ratio,
  ni_payout_ratio,
  total_shareholder_return,
  gross_margin,
  total_cash,
  dividend_paid,
  shares_outstanding,
  shares_data,
};

export const DOMAIN_TERMS = [
  'peratio',
  'pegratio',
  'pbratio',
  'psratio',
  'roe',
  'roa',
  'eps',
  'net_income',
  'revenue',
  'dividend_yield',
  'payout_ratio',
  'dividend_per_share',
  'cash_from_ops',
  'free_cash_flow',
  'capital_expenditures',
  'total_liabilities',
  'total_debt',
  'total_assets',
  'total_equity',
  'return_on_assets',
  'return_on_equity',
  'return_on_invested_capital',
  'gross_profit_margin',
  'ebit_margin',
  'ebitda_margin',
  'net_income_margin',
  'free_cash_flow_margin',
  'ebit',
  'ebitda',
  'gross_profit',
  'ev_ebit',
  'ev_ebitda',
  'ev_revenue',
  'fcf_payout_ratio',
  'ni_payout_ratio',
  'total_shareholder_return',
  'gross_margin',
  'total_cash',
  'dividend_paid',
  'shares_outstanding',
  'shares_data',
];

export const decorate = (point) => {
  DOMAIN_TERMS.forEach((term) => {
    if (point.includes(`<${term}>`) && INFO[term]) {
      point = point
        .replaceAll(
          `<${term}>`,
          `<${term}><Tooltip for="${term}" data="${INFO[term].data}" tldr="${INFO[term].tldr}">`
        )
        .replaceAll(`</${term}>`, `</Tooltip></${term}>`);
    }
  });
  return point;
};

const BulletPoints = ({ title, points = [], isPositive }) => {
  if (!points || points.length === 0) return null;
  return (
    <div className="my-8">
      {title && (
        <BulletTitle className="text-base font-medium mb-3.5">
          {title}
        </BulletTitle>
      )}
      {points.map((point, index) => (
        <ColoredContentText className="text-sm mb-3" key={index}>
          <div className="flex">
            <div className="flex mr-2 mt-0.5">
              <DomainIcon
                value={isPositive ? 'positive-bullet' : 'negative-bullet'}
              />
            </div>
            <div className="flex">
              <JsxParser components={components} jsx={decorate(point)} />
            </div>
          </div>
        </ColoredContentText>
      ))}
    </div>
  );
};

export default BulletPoints;
