import { Checkbox } from '@chakra-ui/checkbox';
import { Input } from '@chakra-ui/input';
import { Box, Flex, Text } from '@chakra-ui/layout';
import React, { FC } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';

const themeObj = {
  dark: {
    bg: '#1d212b',
    columnText: 'white',
    columnBg: '#3b404f',
    columnBorder: 'none',
    inputCheckColor: '#319961',
    iconColor: 'white',
    columnSelectorItemBg: '#151a21',
    columnSelectorItemBgHover: '#000000',
    columnSelectorBorder: '#3b404f',
    columnSelectorBg: '#1d212b',
  },
  light: {
    bg: 'white',
    columnText: 'black',
    columnBg: 'white',
    columnBorder: '#ccd3d6',
    iconColor: 'white',
    inputCheckColor: '#319961',
    columnSelectorBg: '#ffffff',
    columnSelectorItemBg: '#ffffff',
    columnSelectorBorder: '#ccd3d6',
    columnSelectorItemBgHover: '#f8fafd',
  },
};
type Theme = DefaultTheme & { isDark: boolean };

const TableColumnToggle = ({
  setColumns,
  columns,
}: {
  setColumns: Function;
  columns: Array<{ checked: boolean; name: string }>;
}) => {
  const currentTheme: Theme = useTheme() as Theme;
  const theme: typeof themeObj.dark = currentTheme.isDark
    ? themeObj.dark
    : themeObj.light;

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      p="2"
      px="4"
      right="0"
      maxHeight="50vh"
      zIndex="11"
      border="1px solid"
      borderColor={theme.columnBorder}
      overflow="auto"
      bg={theme.columnSelectorBg}
      transform="translateY(5px)"
    >
      {columns.map((column) => (
        <Flex
          bg={theme.columnSelectorItemBg}
          my="1"
          p="2"
          alignItems="center"
          borderColor={theme.columnSelectorBorder}
          _hover={{ bg: theme.columnSelectorItemBgHover }}
          border="0.5px solid"
        >
          <Checkbox
            isChecked={column.checked}
            iconColor={theme.iconColor}
            colorScheme={'green'}
            borderColor={theme.inputCheckColor}
            onChange={(event) => {
              column.checked = event.target.checked;
              setColumns([...columns]);
            }}
          >
            <Text
              ml={4}
              fontSize="small"
              fontWeight="medium"
              color={theme.columnText}
              whiteSpace="nowrap"
            >
              {column.name}
            </Text>
          </Checkbox>
        </Flex>
      ))}
    </Flex>
  );
};

export default TableColumnToggle;
