import React from 'react';
import { connect } from 'react-redux';
import SectorIcon from '../../../../public/images/filters/sector.svg';
import VerdeScoreIcon from '../../../../public/images/filters/verde-score.svg';
import MarketCapIcon from '../../../../public/images/filters/market-cap.svg';
import AddFiltersIcon from '../../../../public/images/filters/add-filters.svg';
import Slider from '../../Slider';
import CustomFilters from '../CustomFilters';
import { ColoredContentText } from '../../StyedComponents';
import Select from 'react-select';
import {
  selectStyles,
  FilterBox,
  FilterBoxName,
  IconBox,
  AllFiltersBox,
} from './style';
import FilterModal from '../../FilterModal';
import {
  updateFilters,
  resetFilters,
} from '../../../../actions/screenerAction';
import { appliedFiltersInitialState } from '../../../../reducer/screenerReducer';

const DefaultFilters = (props) => {
  const {
    openFilterModal,
    setOpenFilterModal,
    filterValues,
    appliedFilters,
    updateFilters,
    resetFilters,
  } = props;
  const [customFiltersToApply, setCustomFiltersToApply] = React.useState(
    appliedFilters
  );

  return (
    <>
      <div className="flex my-8">
        <FilterBox>
          <div className="flex justify-center mx-2">
            {/* <IconBox>
              <img alt="" src={SectorIcon} className="m-auto" />
            </IconBox> */}
            <div className="flex flex-col  justify-evenly">
              <FilterBoxName>Sector</FilterBoxName>
              <div className="w-48">
                <Select
                  className="text-sm"
                  options={filterValues.sectors.map((s) => ({
                    value: s,
                    label: s,
                  }))}
                  value={
                    appliedFilters.sectors.length > 0
                      ? appliedFilters.sectors[0]
                      : null
                  }
                  styles={selectStyles}
                  isClearable={true}
                  onChange={(selected) => {
                    const fitlersToApply = {
                      ...appliedFilters,
                      sectors: selected ? [selected] : [],
                    };
                    updateFilters(fitlersToApply);
                  }}
                />
              </div>
            </div>
          </div>
        </FilterBox>

        <FilterBox>
          <div className="flex justify-center mx-2">
            {/* <IconBox>
              <img alt="" src={VerdeScoreIcon} className="m-auto" />
            </IconBox> */}
            <div className="flex flex-col  justify-evenly">
              <FilterBoxName>Verde Score</FilterBoxName>
              {filterValues.verdeScore && (
                <div className="w-48">
                  <Slider
                    value={
                      appliedFilters.verdeScore
                        ? appliedFilters.verdeScore
                        : filterValues.verdeScore
                    }
                    scoreChange={(values) => {
                      updateFilters({
                        ...appliedFilters,
                        verdeScore: values,
                      });
                    }}
                    resetClicked={false}
                  />
                </div>
              )}
            </div>
          </div>
        </FilterBox>

        <FilterBox>
          <div className="flex justify-center mx-2">
            {/* <IconBox>
              <img alt="" src={MarketCapIcon} className="m-auto" />
            </IconBox> */}
            <div className="flex flex-col justify-evenly">
              <FilterBoxName>Market Cap</FilterBoxName>
              {filterValues.marketCap && (
                <div className="w-48">
                  <Slider
                    type="market-cap"
                    min={filterValues.marketCap[0]}
                    max={filterValues.marketCap[1]}
                    value={
                      appliedFilters.marketCap
                        ? appliedFilters.marketCap
                        : filterValues.marketCap
                    }
                    scoreChange={(values) => {
                      updateFilters({
                        ...appliedFilters,
                        marketCap: values,
                      });
                    }}
                    resetClicked={false}
                  />
                </div>
              )}
            </div>
          </div>
        </FilterBox>

        <AllFiltersBox
          className="flex justify-center w-48 cursor-pointer"
          onClick={() => {
            setOpenFilterModal(true);
          }}
        >
          <div className="flex justify-center items-center m-2 w-full">
            <img alt="" src={AddFiltersIcon} className="mr-2" />
            <div className="text-sm font-medium" style={{ color: '#498b67' }}>
              All Filters
            </div>
          </div>
        </AllFiltersBox>
        <FilterModal
          open={openFilterModal}
          setOpen={setOpenFilterModal}
          applyAction={() => {
            updateFilters({ ...appliedFilters, ...customFiltersToApply });
            setOpenFilterModal(false);
          }}
          resetAction={() => {
            resetFilters();
            setCustomFiltersToApply({ ...appliedFiltersInitialState });
            setOpenFilterModal(false);
          }}
          cancelAction={() => {
            console.log({ ...appliedFilters });
            setCustomFiltersToApply({ ...appliedFilters });
          }}
        >
          <CustomFilters
            customFiltersToApply={customFiltersToApply}
            setCustomFiltersToApply={setCustomFiltersToApply}
          />
        </FilterModal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  filterValues: state.screenerState.filterValues.data,
  appliedFilters: state.screenerState.appliedFilters,
});

const mapDispatchToProps = { updateFilters, resetFilters };

export default connect(mapStateToProps, mapDispatchToProps)(DefaultFilters);
