import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import numeral from 'numeral';
import { formatDecimalValue } from '../../../utils/number';

const ColorCoded = styled.div`
  color: ${(props) =>
    props.negative
      ? props.theme.bulletPoints.negative
      : props.theme.bulletPoints.positive};
`;

export const insidersColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (row) => <div>{row.value}</div>,
  },
  {
    Header: 'Shares',
    accessor: 'shares',
    Cell: (row) => <div>{numeral(row.value).format('0,0')}</div>,
  },
  {
    Header: 'Cost',
    accessor: 'cost',
    Cell: (row) => <div>${formatDecimalValue(row.value)}</div>,
  },
  {
    Header: 'Transaction',
    accessor: 'transaction_type',
    Cell: (row) => (
      <ColorCoded negative={row.value === 'S'}>
        {row.value === 'S' ? 'Sell' : 'Buy'}
      </ColorCoded>
    ),
  },
  {
    Header: 'Date',
    accessor: 'transaction_date',
    Cell: (row) => <div>{moment(row.value).format('MMMM d, YYYY')}</div>,
  },
];

export const holdersColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (row) => <div>{row.value}</div>,
  },
  {
    Header: 'Shares',
    accessor: 'current_shares',
    Cell: (row) => <div>{numeral(row.value).format('0,0')}</div>,
  },
  {
    Header: 'Ownership %',
    accessor: 'ownership_percent',
    Cell: (row) => <div>{row.value}%</div>,
  },
];
