import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function LetterLogo({ symbol, darkMode }) {
  return (
    <Box
      minWidth={10}
      minHeight={10}
      maxH={10}
      maxW={10}
      backgroundColor={darkMode ? '#4adf79' : '#01874e'}
      borderRadius={100}
    >
      <Text
        fontSize={19}
        textAlign={'center'}
        mt={1.5}
        color={darkMode ? 'black' : 'white'}
        fontWeight={549}
      >
        {symbol ? symbol.split('')[0] : '-'}
      </Text>
    </Box>
  );
}

export default LetterLogo;
