import styled from 'styled-components';
import React from 'react';
import ReactSlider from 'react-slider';
import tw from 'twin.macro';
import numeral from 'numeral';

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
`;

const styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '40px',
  height: '25px',
  fontSize: '12px',
  backgroundColor: '#fff',
  border: '1px solid #c1c1c1',
  boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
  top: '-30px',

  borderRadius: '5px',
  '&::before': {
    content: '',
    position: ' absolute',
    width: '0',
    height: '0',
    right: '0',
    bottom: '-42px',
    border: '10px solid transparent',
    borderTop: '32px solid rgb(193,193,193)',
    zIndex: '2',
  },
  '&::after': {
    content: '',
    position: 'absolute',
    width: '0',
    height: '0',
    left: ' 14px',
    bottom: '-10px',
    borderColor: '#c1c1c1',
    border: '5px solid transparent',
    borderTop: '5px solid white',
    zIndex: '3',
  },
};

const StyledThumb = styled.div`
  ${tw`h-4 w-4 text-center outline-none object-contain -mt-1.5 font-medium`}
  background-color: white;
  border-radius: 50%;
  border: 1px solid green;
  cursor: grab;
  .thumb-0 {
    left: 0;
  }
  .thumb-1 {
    right: 23px;
  }
`;
export default function Slider(ownProps) {
  const Thumb = (props, state) => (
    <>
      <StyledThumb {...props}>
        <div className={`callout thumb-${state.index}`} style={styles}>
          {ownProps.type === 'market-cap'
            ? numeral(state.valueNow + '000000')
                .format('($0a)')
                .toUpperCase()
            : state.valueNow}
        </div>
      </StyledThumb>
    </>
  );

  const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${(props) =>
      props.index === 2 ? '#ddd' : props.index === 1 ? '#319961' : '#ddd'};
    border-radius: 999px;
  `;

  const Track = (props, state) => {
    return <StyledTrack {...props} index={state.index} />;
  };

  return (
    <div className="mt-8">
      <StyledSlider
        min={ownProps.min ? ownProps.min : 0}
        max={ownProps.max ? ownProps.max : 100}
        //defaultValue={[...ownProps.value]}
        value={[...ownProps.value]}
        renderTrack={Track}
        renderThumb={Thumb}
        onAfterChange={(value) => {
          ownProps.scoreChange(value);
        }}
        step={ownProps.step}
      />
    </div>
  );
}
