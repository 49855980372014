import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { VerticalLine, Subtle, GreenText } from '../../shared/StyedComponents';
import Trend from '../../shared/Trend';
import { getStockInfo } from '../../../actions/action';
import PortfolioChart from '../../shared/PortfolioChart';
import VerdeScore from '../../shared/VerdeScore';
import { RatingBox } from '../../shared/RatingBox';
import StockTable from '../../shared/StockTable';
import { defaultColumns } from '../../shared/StockTable/columns';

const RelativeIncrease = styled.div`
  ${tw`mx-1.5`}
  color: ${(props) => props.theme.trend.upward};
`;

const Portfolio = ({ stockData, loading, getStockInfo, self }) => {
  React.useEffect(() => {
    //getStockInfo('MSFT');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex justify-between">
          <div className="font-medium mr-8">
            <div className="text-3xl">$95,287</div>
            <div className="flex text-xs sm:text-sm mt-1">
              <Trend trend={true} color="#36A867" className="mt-1" />
              <RelativeIncrease>5419 (+6.41%)</RelativeIncrease>
            </div>
          </div>
          {!self && <PortfolioChart />}
        </div>
        {self ? (
          <PortfolioChart />
        ) : (
          <div className="hidden md:flex justify-around font-medium">
            <div className="text-center">
              <Subtle>following</Subtle>
              <GreenText>230</GreenText>
            </div>
            <VerticalLine />
            <div className="text-center">
              <Subtle>followers</Subtle>
              <GreenText>230</GreenText>
            </div>
          </div>
        )}
      </div>
      <div className="flex-col xl:flex xl:flex-row items-center justify-around mt-4">
        <div>
          <VerdeScore variant="portfolio" />
        </div>
        <div
          style={{ zoom: 0.88, '-moz-transform': 'scale(0.88)' }}
          className="justify-around hidden md:flex mt-12"
        >
          <RatingBox title="Growth" className="h-24" />
          <RatingBox title="Safety" className="h-24" />
          <RatingBox title="Value" className="h-24" />
        </div>
        <div
          style={{ zoom: 0.8, '-moz-transform': 'scale(0.8)' }}
          className="grid grid-cols-4 gap-4 md:hidden mt-12"
        >
          <RatingBox className="col-span-2 h-24" title="Growth" />
          <RatingBox className="col-span-2 h-24" title="Safety" />
          <RatingBox className="col-start-2 col-span-2 h-24" title="Value" />
        </div>
      </div>
      <div className="mt-12 -mx-6 overflow-x-scroll">
        <StockTable
          columns={[...defaultColumns]}
          data={[]}
          onSort={() => {}}
          defaultPageSize={10}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = {
  getStockInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
