import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import {
  Highlight,
  Subtle,
  BorderedSection,
  ColoredContentText,
} from '../../shared/StyedComponents';
import DefaultProfilePicture from '../../../public/images/default-profile-picture.jpg';
import LogoNotFound from '../../../public/images/logo-not-found.png';
import LikeSvg from '../../../public/images/like.svg';
import CommentSvg from '../../../public/images/comment.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { Emoji } from 'emoji-mart';

const people = Array.from(Array(2).keys());

export const ContentText = styled(ColoredContentText)`
  ${tw`text-sm my-4`};
`;

export const Reaction = styled.div`
  ${tw`text-xs font-thin flex py-1 px-2 rounded mr-8 cursor-pointer my-auto`};
  color: ${(props) => props.theme.likeAndComment.color};
  background-color: ${(props) =>
    props.isClicked ? props.theme.likeAndComment.clicked : 'transparent'};
`;

export const CommentSection = styled.div`
  background-color: ${(props) => props.theme.likeAndComment.background};
`;

export const LikeAndComment = styled(CommentSection)`
  ${tw`flex p-2 rounded-lg rounded-t-none h-12`};
  border-top: 1px solid ${(props) => props.theme.pageTab.border};
`;

export const PastComment = styled.div`
  ${tw`text-xs p-3 rounded-md mt-3 mb-1`};
  background-color: ${(props) => props.theme.pastComment.background};
`;

export const PastCommentUser = styled.div`
  ${tw`font-medium`};
  color: ${(props) => props.theme.pastComment.user};
`;

export const PastCommentText = styled.div`
  ${tw`mt-1`};
  color: ${(props) => props.theme.pastComment.commentText};
`;

export const PastCommentMeta = styled.div`
  ${tw`text-xs`};
  color: ${(props) => props.theme.pastComment.commentText};
`;

export const CommentInput = styled.input`
  ${tw`rounded-full text-sm focus:outline-none px-3 py-2`};
  color: ${(props) => props.theme.likeAndComment.color};
  border: 1px solid ${(props) => props.theme.pageTab.border};
`;

export const Order = styled(BorderedSection)`
  ${tw`rounded-md w-full lg:w-1/2 my-4 text-sm font-medium`};
  border-left: 10px solid ${(props) => props.theme.order[props.type]};
`;

export const OrderTypeText = styled.div`
  color: ${(props) => props.theme.order[props.type]};
`;

export const OrderSymbol = styled(ColoredContentText)`
  ${tw`text-xs`};
`;

export const OrderProfit = styled.div`
  ${tw`text-xs`};
  color: ${(props) => props.theme.order[props.type]};
`;

export const OrderDescription = styled(CommentSection)`
  ${tw`flex p-4 text-xs font-normal rounded-lg rounded-t-none`};
  color: ${(props) => props.theme.order.description};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  ${tw`cursor-pointer relative right-6 my-auto text-gray-400`};
`;

const LikeSection = styled.div`
  ${tw`flex px-1 bg-white shadow-md pt-1.5`};
  border: solid 1px ${(props) => props.theme.likeAndComment.likeSectionBorder};
`;

const LIKE_EMOJIS = [
  'thumbsup',
  'thumbsdown',
  'heart',
  'partying_face',
  'sunglasses',
  'clap',
];

const ActivityHeader = () => (
  <div className="flex mt-4 justify-between">
    <div className="flex">
      <img className="w-10 h-10 mr-4" src={DefaultProfilePicture} alt="" />
      <div className="my-auto">
        <Highlight className="text-sm font-medium">Marian Ross</Highlight>
        <Subtle className="text-xs mt-0.5">2 hours ago</Subtle>
      </div>
    </div>
    <div>
      <Subtle className="text-xs mt-0.5">22 followers</Subtle>
    </div>
  </div>
);

const LikeEmoji = ({ emoji, onClick }) => (
  <div
    className="mx-1.5 cursor-pointer transform hover:-translate-y-1 hover:scale-110"
    onClick={onClick}
  >
    <Emoji emoji={emoji} size={24} />
  </div>
);

const Activity = ({ index, type, profitOrLoss }) => {
  const [showComment, setShowComment] = React.useState(false);
  const [showLike, setShowLike] = React.useState(false);
  const [like, setLike] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [emojiPickerPosition, setEmojiPickerPosition] = React.useState({
    left: 0,
  });
  const [likePickerPosition, setLikePickerPosition] = React.useState({
    left: 0,
  });

  const addEmoji = (e) => {
    setComment(comment + e.native);
    setShowEmojiPicker(false);
  };

  return (
    <BorderedSection className="my-4 rounded-lg">
      <div className="px-4">
        <ActivityHeader key={index} />
        {type === 'social' ? (
          <ContentText>
            PLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor MSFT for $221
          </ContentText>
        ) : (
          <Order type={profitOrLoss}>
            <div className="flex justify-between p-4">
              <div className="flex">
                <img className="w-9 h-9 mr-4" src={LogoNotFound} alt="" />
                <div className="flex-row my-auto">
                  <OrderTypeText type={profitOrLoss}>Bought</OrderTypeText>
                  <OrderSymbol>AAPL</OrderSymbol>
                </div>
              </div>
              <div>
                <div className="flex-row my-auto">
                  <ColoredContentText>$110.05</ColoredContentText>
                  <OrderProfit type={profitOrLoss}>Upto 17%</OrderProfit>
                </div>
              </div>
            </div>
            <OrderDescription>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt.
            </OrderDescription>
          </Order>
        )}
      </div>
      <LikeAndComment>
        <Reaction
          onClick={(event) => {
            setShowLike(!showLike);
            setLikePickerPosition({
              left: event.screenX,
            });
          }}
          isClicked={showLike}
        >
          {like === '' ? (
            <>
              <img className="w-4 h-4 mr-2" src={LikeSvg} alt="" />
              <div>Like</div>
            </>
          ) : (
            <Emoji emoji={like} size={24} />
          )}
        </Reaction>
        <Reaction
          onClick={() => setShowComment(!showComment)}
          isClicked={showComment}
        >
          <img className="w-4 h-4 mr-2" src={CommentSvg} alt="" />
          <div>Comment</div>
        </Reaction>
      </LikeAndComment>

      {showLike && (
        <LikeSection
          style={{
            zIndex: 6,
            position: 'absolute',
            ...likePickerPosition,
          }}
        >
          {LIKE_EMOJIS.map((emoji) => (
            <LikeEmoji
              emoji={emoji}
              key={emoji}
              onClick={() => {
                setLike(emoji);
                setShowLike(false);
              }}
            />
          ))}
        </LikeSection>
      )}
      {showComment && (
        <CommentSection className="px-4 pt-2 pb-4 rounded-lg rounded-t-none">
          <div className="flex">
            <img
              className="w-7 h-7 my-auto mr-2 rounded-full"
              src={DefaultProfilePicture}
              alt=""
            />
            <CommentInput
              type="text"
              className="w-3/4"
              placeholder="Write a comment..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <StyledFontAwesomeIcon
              icon={faSmile}
              onClick={(event) => {
                setEmojiPickerPosition({
                  left: event.screenX + 20,
                });
                setShowEmojiPicker(!showEmojiPicker);
              }}
            />
            {showEmojiPicker && (
              <Picker
                style={{
                  zIndex: 6,
                  position: 'absolute',
                  ...emojiPickerPosition,
                }}
                onSelect={addEmoji}
                theme="auto"
                title=""
              />
            )}
          </div>
          <div className="flex">
            <img
              className="w-7 h-7 my-auto mr-2 rounded-full"
              src={DefaultProfilePicture}
              alt=""
            />
            <div>
              <PastComment>
                <PastCommentUser>Rojer Fernandes</PastCommentUser>
                <PastCommentText>
                  Well done dude, I am also planning to buy MSFT stock
                </PastCommentText>
              </PastComment>
              <div className="flex justify-between px-2">
                <div></div>
                <PastCommentMeta>1W</PastCommentMeta>
              </div>
            </div>
          </div>
        </CommentSection>
      )}
    </BorderedSection>
  );
};

const ActivityFeed = () => {
  return (
    <div className="my-2">
      {people.map((person, index) => (
        <Activity key={index} type="social" />
      ))}
      {people.map((person, index) => (
        <Activity key={index} type="order" profitOrLoss="loss" />
      ))}
    </div>
  );
};

export default ActivityFeed;
