import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw from 'twin.macro';

export const StyleIconButton = styled.button`
  ${tw`font-medium text-lg w-full h-full rounded focus:outline-none hover:shadow-lg py-4 px-2 xl:px-8`}
  border: solid 1px #e0e0e0;
`;

export const ColoredParagraph = styled.p`
  ${tw`my-auto xl:text-3xl text-2xl`}
  color: ${(props) => props.color};
`;

export const Checkbox = styled.div`
  border-radius: 2px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border: 1px solid #2b9867;
  position: relative;
  display: inline-block;
  margin-top: 2px;
  background-color: #fff;
`;

export const CbIcon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  top: 0;
  left: 0;
  z-index: 1;
  color: white;
  background-color: #2b9867;
  padding: 1px;
`;

export const StyledSpan = styled.span`
  ${tw`ml-2 relative`}
  color: rgba(77, 77, 77, 0.87);
  bottom: 4px;
`;

export const StyledLink = styled.span`
  ${tw`no-underline font-medium ml-1 cursor-pointer`}
  color: #2b9867;
`;

export const Anchor = styled.a`
  ${tw`no-underline font-medium ml-1 cursor-pointer`}
  color: #2b9867;
`;

export const Line = styled.div`
  ${tw`w-full h-0 relative`}
  border: solid 0.5px #707070;
  top: 0.6rem;
  opacity: 0.3;
`;

export const ButtonIcon = styled.img`
  height: ${(props) => props.height};
`;

export const ButtonIconText = styled.span`
  ${tw`ml-4 text-sm md:text-base font-normal`}
  color: #666666;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  ${tw`text-3xl m-auto`}
  color: ${(props) => props.color} !important;
`;
