import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

const options = {
  chart: {
    type: 'area',
    margin: [0, 0, 5, 0],
    spacingTop: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0,
    width: 200,
  },
  title: {
    text: null,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  plotOptions: {
    area: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  },
  series: [
    {
      fillColor: {
        linearGradient: [0, 0, 0, 300],
        stops: [
          [0, Highcharts.color('#84E8F4').setOpacity(0.2).get('rgba')],
          [1, Highcharts.color('#84E8F4').setOpacity(0.5).get('rgba')],
        ],
      },
      fillOpacity: 0.1,
      dashStyle: 'ShortDash',
      color: '#319AA7',
      opacity: 0.5,
      data: [
        235,
        1005,
        2063,
        3057,
        6444,
        9822,
        20434,
        24126,
        29459,
        31982,
        31233,
        27342,
        27912,
        28965,
        25579,
        24826,
        24304,
        23708,
        24357,
        24401,
        17287,
        13076,
        12144,
        11009,
        10950,
        10824,
        10527,
        10421,
        10295,
        10104,
        9914,
        9620,
        9326,
        5113,
        5113,
        4954,
        4804,
        4761,
        4717,
        4368,
        4018,
      ],
    },
  ],
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
};

const PortfolioChart = () => {
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PortfolioChart;
