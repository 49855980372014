import React from 'react';
import { connect } from 'react-redux';
import { removeAlert } from '../../../actions/alertAction';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import CancelSvg from '../../../public/images/alert/cancel.svg';
import SuccessSvg from '../../../public/images/alert/success.svg';
import ErrorSvg from '../../../public/images/alert/error.svg';

const AlertDiv = styled.div`
  ${tw`p-2 pr-4 rounded max-w-max mx-auto text-sm flex items-center font-medium`}
  color: ${(props) => (props.isSuccess ? '#516757' : '#6a544f')};
  background-color: ${(props) => (props.isSuccess ? '#ebf6ee' : '#f5e8e5')};
  border: 1px solid ${(props) => (props.isSuccess ? '#b2d1b9' : '#FF5700')};
`;

const AlertIcon = styled.div`
  ${tw`rounded-full w-8 h-8 flex justify-center items-center`}
  background-color: ${(props) => (props.isSuccess ? '#5ab367' : '#dc5839')};
`;

const Alert = ({ message, alertType, removeAlert }) => {
  const timeout = setTimeout(() => removeAlert(), 5000);
  const isSuccess = alertType === 'success';

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return message ? (
    <div className="fixed top-24 z-40 w-full mx-auto px-4">
      <AlertDiv isSuccess={isSuccess} role="alert">
        <AlertIcon isSuccess={isSuccess} className="rounded-full">
          <img
            alt=""
            src={isSuccess ? SuccessSvg : ErrorSvg}
            onClick={removeAlert}
          />
        </AlertIcon>
        <div className="ml-3 mr-4">{message}</div>
        <img
          alt=""
          className="cursor-pointer"
          src={CancelSvg}
          onClick={removeAlert}
        />
      </AlertDiv>
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    message: state.alertState.message,
    alertType: state.alertState.alertType,
  };
};

const mapDispatchToProps = {
  removeAlert: removeAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
