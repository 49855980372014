import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';

function* fetchSymbolNewsData(action) {
  yield put({ type: 'FETCH_SYMBOL_NEWS_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .get(`${SYMBOL_BASE_URL}/news/${action.symbol.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_SYMBOL_NEWS_SUCCESS',
      data: responseData,
    });
  } catch (error) {
    yield put({ type: 'FETCH_SYMBOL_NEWS_ERROR', error: error.message });
  }
}

export function* watchFetchingSymbolNewsData() {
  yield takeEvery('FETCH_SYMBOL_NEWS_DATA', fetchSymbolNewsData);
}
