import React from 'react';
import { Text } from '@chakra-ui/react';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import ChartCard from './ChartCard';
import { useMediaQuery } from '@chakra-ui/react';
import SectionHeader from '../../../shared/SectionHeader';
import PortfolioPerformance from '../Overview/PortfolioPerformance';
import LoadingIndicator from '../../../shared/LoadingIndicator';

export default function AssetClassification({
  data,
  darkMode,
  assetData,
  performanceData,
  label,
  insightsData,
  currentKey,
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  data.holdings = data.holdings.filter((elem) => elem['symbol'] !== null);

  return (
    <>
      {assetData.length > 0 ? (
        <div>
          <div style={{ display: !isMobile ? 'flex' : 'block' }}>
            {isMobile && <SectionHeader value="Tickers" />}
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: darkMode ? '#262626' : 'white',
                padding: '20px',
                width: !isMobile ? '50%' : '100%',
                marginTop: isMobile ? '10px' : '',
                marginBottom: '25px',
              }}
            >
              <ChartCard
                data={data.holdings ? data.holdings : []}
                darkMode={darkMode}
                title="Tickers"
                isNested={true}
                symbolHidden={true}
                nestedKeyTitle={['marketCapMln', 'symbol']}
              />

              {/* <RiskPositives
            // positives={currentPortfolioInsights.filter((elem) => elem['type'] === "SECTOR" && elem['insight'].includes('negative')).map(elem => elem.insight)}
            negatives={currentPortfolioInsights
              .filter(
                (elem) =>
                  elem['type'] === 'TICKER' || elem['type'] === 'ASSET_CLASS'
              )
              .map((elem) => elem.insight)}
            isInsight={true}
          /> */}
            </div>
            {isMobile && <SectionHeader value="Sectors" />}
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: darkMode ? '#262626' : 'white',
                marginLeft: isMobile ? '0' : '25px',
                padding: '20px',
                marginTop: isMobile ? '10px' : '',
                width: !isMobile ? '48%' : '100%',
                marginBottom: '25px',
              }}
            >
              <ChartCard
                data={assetData.length > 0 && assetData[1]['assetItem']}
                darkMode={darkMode}
                title="Sectors"
                keyTitle="name"
                isNested={false}
                symbolHidden={true}
              />

              {/* <RiskPositives
            // positives={currentPortfolioInsights.filter((elem) => elem['type'] === "SECTOR" && elem['insight'].includes('negative')).map(elem => elem.insight)}
            negatives={currentPortfolioInsights
              .filter((elem) => elem['type'] === 'SECTOR')
              .map((elem) => elem.insight)}
            isInsight={true}
          /> */}
            </div>
          </div>

          {isMobile && <SectionHeader value="Industry" />}

          <div style={{ display: !isMobile ? 'flex' : 'block' }}>
            <div
              style={{
                borderRadius: '5px',
                width: !isMobile ? '50%' : '100%',
                backgroundColor: darkMode ? '#262626' : 'white',
                marginRight: '25px',
                marginTop: isMobile ? '10px' : '',
                padding: '20px',
                marginTop: '25px',
              }}
            >
              <ChartCard
                data={assetData.length > 0 && assetData[2]['assetItem']}
                darkMode={darkMode}
                title="Industry"
                keyTitle="name"
                isNested={false}
                symbolHidden={true}
              />
              {/* <RiskPositives
            // positives={currentPortfolioInsights.filter((elem) => elem['type'] === "SECTOR" && elem['insight'].includes('negative')).map(elem => elem.insight)}
            negatives={currentPortfolioInsights
              .filter((elem) => elem['type'] === 'INDUSTRY')
              .map((elem) => elem.insight)}
            isInsight={true}
          /> */}
            </div>

            {isMobile && <SectionHeader value="Country" />}
            <div
              style={{
                borderRadius: '5px',
                width: !isMobile ? '48%' : '100%',
                backgroundColor: darkMode ? '#262626' : 'white',
                padding: '20px',
                marginTop: isMobile ? '10px' : '',
                marginTop: '25px',
              }}
            >
              <ChartCard
                data={assetData.length > 0 && assetData[3]['assetItem']}
                darkMode={darkMode}
                title="Country"
                keyTitle="name"
                isNested={false}
                symbolHidden={true}
              />

              {/* <RiskPositives
            // positives={currentPortfolioInsights.filter((elem) => elem['type'] === "SECTOR" && elem['insight'].includes('negative')).map(elem => elem.insight)}
            negatives={currentPortfolioInsights
              .filter(
                (elem) =>
                  elem['type'] === 'COUNTRY' || elem['type'] === 'CURRENCY'
              )
              .map((elem) => elem.insight)}
            isInsight={true}
          /> */}
            </div>
          </div>

          {!isMobile && (
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: darkMode ? '#262626' : 'white',
                padding: '50px',
                width: '100%',
                marginTop: '25px',
                marginBottom: '25px',
              }}
            >
              <Text
                style={{
                  color: darkMode ? '#f6f6f6' : 'black',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                Portfolio Performance
              </Text>
              {performanceData[0] && (
                <PortfolioPerformance
                  darkMode={darkMode}
                  data={performanceData[0].quarters}
                  valuationData={[]}
                />
              )}
            </div>
          )}

          <div
            style={{
              borderRadius: '5px',
              backgroundColor: darkMode ? '#262626' : 'white',
              marginRight: '25px',
              padding: '20px',
              width: !isMobile ? '50%' : '100%',
              marginTop: '25px',
              width: '100%',
            }}
          >
            {/* <RiskReturn darkMode={darkMode} /> */}
          </div>
        </div>
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
}
