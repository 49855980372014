import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import { ANNUALLY, getChartData } from '../../../../../utils/ChartUtils';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import SectionHeader from '../../../SectionHeader';
import Chart from './chart';

const TotalShareholderReturn = ({ data, insights, period, setPeriod }) => {
  const chartData = getChartData(
    data,
    [
      'free_cash_flow',
      'total_shareholder_return',
      'sale_purchase_stock',
      'dividend_paid_per_share',
      'shares_data',
      'evaluated_on',
    ],
    ANNUALLY
  ).map((item) => [item[0], item[1], item[2], item[3], item[4], item[5]]);

  return (
    <>
      <SectionHeader
        value="Total Shareholder Return"
        className="pb-6"
        infoKey="total_shareholder_return_ratio"
      />
      <BorderedChartContainer className="py-4">
        {/* <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div> */}
        <Chart data={chartData} name="Total Shareholder Return" />
      </BorderedChartContainer>
      <BulletPoints
        title="Positives"
        points={insights?.totalShareholderReturn?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.totalShareholderReturn?.negatives}
        isPositive={false}
      />
    </>
  );
};

export default TotalShareholderReturn;
