import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';

export const IntegrationModal = ({
  closeModal,
  data,
  darkMode,
  currentHandle,
  onSubmit,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [error, setError] = useState(false);
  let inputString = '';
  const styles = {
    mainModel: {
      position: 'fixed',
      zIndex: 10,
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      padding: 20,
      overflow: 'auto',
      backgroundColor: 'rgb(0,0,0)',
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    modalContent: {
      margin: isMobile ? '50% auto' : '15% auto',
      padding: '20',
      borderRadius: '8px',
      border: '1px solid #888',
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      width: '30%',
      height: '35%',
      alignItems: 'center',
    },
  };
  return (
    <div style={styles.mainModel}>
      <div
        style={{
          ...styles.modalContent,
          backgroundColor: darkMode ? '#161616' : '#f4f4f4',
          padding: 20,
          width: isMobile ? '80%' : 400,
          height: isMobile ? '30%' : 200,
        }}
      >
        <Text
          fontWeight="bold"
          fontSize={18}
          color={darkMode ? 'white' : 'black'}
        >
          Social Handle
        </Text>
        <div style={{ width: '100%', marginBottom: 10 }}>
          <Text
            fontWeight="medium"
            fontSize={14}
            color={darkMode ? 'white' : 'black'}
          >
            Enter your {currentHandle && currentHandle.name} Handle
          </Text>
        </div>
        <Flex alignItems="center">
          <img
            className={`w-${isMobile ? 8 : 7} h-${
              isMobile ? 8 : 7
            } mr-2 cursor-pointer`}
            src={currentHandle['image']}
            alt=""
          />
          <input
            style={{
              fontSize: '16',
              fontWeight: 550,
              borderRadius: 6,
              backgroundColor: 'transparent',
              width: '85%',
              height: 40,
              padding: 10,
              border: '1px solid #d9d9d9',
              color: darkMode ? 'white' : 'black',
            }}
            id="handleInput"
            type="text"
            placeholder={data && data[currentHandle['key']]}
            onChange={(e) => (inputString = e.target.value)}
          />
        </Flex>
        {error && (
          <Text
            fontSize={11}
            marginLeft={35}
            marginTop={2}
            style={{ color: darkMode ? '#EC5B55' : '#be2e2c' }}
          >
            Handle cannot be an empty string
          </Text>
        )}
        <Flex>
          <button
            onClick={() => {
              if (inputString !== '') {
                onSubmit({ ...data, [currentHandle['key']]: inputString });
                inputString = '';
              } else {
                setError(true);
              }
            }}
            style={{
              padding: 5,
              width: '120px',
              backgroundColor: darkMode ? '#4adf79' : '#01874e',
              color: darkMode ? 'black' : 'white',
              borderRadius: 20,
              marginTop: 20,
              marginRight: 5,
              fontSize: 15,
              fontWeight: 550,
            }}
          >
            Submit
          </button>
          <button
            onClick={() => closeModal()}
            style={{
              padding: 5,
              width: '120px',
              color: darkMode ? '#4adf79' : '#01874e',
              border: `1.5px solid ${darkMode ? '#4adf79' : '#01874e'}`,
              borderRadius: 20,
              marginTop: 20,
              marginLeft: 5,
              fontSize: 15,
              fontWeight: 550,
            }}
          >
            Cancel
          </button>
        </Flex>
      </div>
    </div>
  );
};
