const initialState = {
  data: [],
  loading: false,
  error: null,
};

const NewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SYMBOL_NEWS_LOADING': {
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    }

    case 'FETCH_SYMBOL_NEWS_SUCCESS': {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }

    case 'FETCH_SYMBOL_NEWS_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default NewsReducer;
