import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledTable = styled.table`
  ${tw`border-collapse table-auto w-full mb-1 text-sm`}
`;

export const StyledTableData = styled.td`
  ${tw`py-2.5 px-8 border-0`}
  color: ${(props) => props.theme.table.color};
  font-weight: ${(props) => (props.first ? '500' : 'normal')};
`;

const InsidersTable = (props) => {
  const { columns, data, darkMode } = props;

  const StyledTableHead = styled.th`
    ${tw`py-2 px-8 font-medium border-0 text-left`}
    background-color: ${(props) => (darkMode ? '#434243' : '#F4F4F4')};
    border: 1px solid ${(props) => props.theme.table.headerBorder};
    border-left: 0;
    border-right: 0;
    color: ${darkMode ? 'white' : 'black'};
  `;

  const BorderedDiv = styled.div`
    background-color: ${(props) =>
      darkMode ? '#262626' : props.theme.table.background};
    border: 0px solid ${(props) => props.theme.table.border};
    color: ${(props) => props.theme.content.color};
  `;
  const tableInstance = useTable({ columns, data });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <BorderedDiv className="overflow-x-scroll hide-scrollbar">
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <StyledTableHead
                  firstCell={index === 0}
                  lastCell={index === headerGroup.headers.length - 1}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </StyledTableHead>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <StyledTableData {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </StyledTableData>
                  );
                })}
              </tr>
            );
          })}
          {rows.length === 0 && (
            <tr>
              <StyledTableData
                className="text-center text-sm font-medium"
                colSpan="99"
              >
                No records found
              </StyledTableData>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </BorderedDiv>
  );
};

export default InsidersTable;
