export default class DividendCM {
  constructor(build) {
    this._symbol = build._symbol;
    this._rating = build._rating;
    this._yieldPercent = build._yieldPercent;
    this._comparison = build._comparison;
  }

  get symbol() {
    return this._symbol;
  }

  set symbol(symbol) {
    this._symbol = symbol;
  }

  get rating() {
    return this._rating;
  }

  set rating(rating) {
    this._rating = rating;
  }

  get yieldPercent() {
    return this._yieldPercent;
  }

  set yieldPercent(yieldPercent) {
    this._yieldPercent = yieldPercent;
  }

  get comparison() {
    return this._comparison;
  }

  set comparison(comparison) {
    this._comparison = comparison;
  }

  static get builder() {
    class Builder {
      symbol(symbol) {
        this._symbol = symbol;
        return this;
      }

      rating(rating) {
        this._rating = rating;
        return this;
      }

      yieldPercent(yieldPercent) {
        this._yieldPercent = yieldPercent;
        return this;
      }

      comparison(comparison) {
        this._comparison = comparison;
        return this;
      }

      build() {
        return new DividendCM(this);
      }
    }
    return Builder;
  }
}
