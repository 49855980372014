import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { css } from '@emotion/core';
import Modal from 'react-modal';
import LoadingImg from '../../../public/images/loading-icon.svg';

const override = css`
  margin: auto;
`;

const modalStyles = {
  overlay: {
    zIndex: 40,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    inset: 0,
  },
  content: {
    background: 'transparent',
    border: 0,
    display: 'flex',
    alignItems: 'center',
  },
};

Modal.setAppElement('#root');

const LoadingPageIndicator = ({ size = 60, open }) => {
  return (
    <Modal isOpen={open} style={modalStyles}>
      <div className="flex justify-center items-center mx-auto overflow-hidden relative md:bottom-8">
        <img
          src={LoadingImg}
          alt=""
          className="absolute left-10 top-8 transform scale-150"
        />
        <MoonLoader color="#54A579" css={override} size={size} loading={true} />
      </div>
    </Modal>
  );
};

export default LoadingPageIndicator;
