import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import tw from 'twin.macro';
import { styles } from './styles.js';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import { formatDecimalValue } from '../../../utils/number.js';

export default function MobileSuperInvestorSummary({
  data,
  darkMode,
  showPortfolioCallback,
}) {
  function bigValueSuffix(x, prefix, suffix) {
    if (Math.abs(x) >= 1000000000000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(
          Math.abs(x) / 1000000000000
        )}T${suffix}`;
      }
      return `${prefix}${formatDecimalValue(
        parseFloat(x / 1000000000000)
      )}T${suffix}`;
    } else if (Math.abs(x) >= 1000000000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(
          Math.abs(x) / 1000000000
        )}B${suffix}`;
      }
      return `${prefix}${formatDecimalValue(x / 1000000000)}B${suffix}`;
    } else if (Math.abs(x) >= 1000000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(
          Math.abs(x) / 1000000
        )}M${suffix}`;
      }

      return `${prefix}${formatDecimalValue(x / 1000000)}M${suffix}`;
    } else if (Math.abs(x) >= 1000) {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(Math.abs(x) / 1000)}K${suffix}`;
      }

      return `${prefix}${formatDecimalValue(x / 1000)}K${suffix}`;
    } else {
      if (x < 0) {
        return `-${prefix}${formatDecimalValue(Math.abs(x))}${suffix}`;
      }

      return `${prefix}${x}${suffix}`;
    }
  }

  const percentVal = data[0] && data[0]['quarters'].length > 0 &&  data[0]['quarters'][0]['pl_percent'];
  const verdeScore = data[0] && data[0]['verdeScore'];

  const headerData = {
    id: 'Holdings',
    tableHeader: [
      { id: 'Holdings', key: 'symbol', upDownIcon: false },
      {
        id: 'Shares',
        key: 'currentShares',
        prefix: '',
        suffix: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
      {
        id: 'Average Price',
        key1: 'cost_basis',
        key2: 'quantity',
        prefix: '$',
        suffix: '',
        upDownIcon: false,
      },
      {
        id: 'Current Price',
        key: 'currentPrice',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: 'Market Value',
        key: 'marketCapMln',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '% of Portfolio',
        key: 'portfolioPercent',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'P&L',
        key: 'pl',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '%P&L',
        key: 'pl_percent',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Quarter Low',
        key: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
      {
        id: 'Quarter High',
        key: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
    ],
  };

  console.log(data);

  return (
    <div style={{ padding: 15, paddingTop: 5, paddingBottom: 5 }}>
      <div
        style={{
          backgroundColor: darkMode ? 'rgba(38,38,38,0.5)' : 'rgb(246,248,250)',
          padding: 20,
          marginLeft: 5,
          borderRadius: 10,
          paddingTop: 25,
          paddingBottom: 25,
        }}
      >
        {/* My Portfolio */}
        <Flex justifyContent="flex-start" marginBottom={5}>
          <Box>
            <Text style={{ ...styles(darkMode)['text'], fontSize: 18 }}>
              Portfolio Details
            </Text>
          </Box>
          <Box
            marginLeft="auto"
            marginRight={5}
            onClick={() => {
              showPortfolioCallback();
            }}
            cursor="pointer"
          >
            <FontAwesomeIcon
              icon={faArrowRight}
              color={darkMode ? 'white' : 'black'}
              className="ml-2 fa-1x"
            />
          </Box>
        </Flex>
        <Box marginTop={5}>
          <Flex>
            <Box>
              <Text style={{ ...styles(darkMode)['grayText'] }}>
                Portfolio Value
              </Text>
              <Text style={{ ...styles(darkMode)['text'] }}>
                $
                {parseFloat(
                data[0] && data[0]['quarters'].length > 0 && data[0]['quarters'][0]['portfolioValue']
                ).toLocaleString()}
              </Text>
              <Flex alignItems="center">
                {percentVal > 0 ? (
                  <TriangleUpIcon
                    color={darkMode ? '#4adf79' : '#01874e'}
                  ></TriangleUpIcon>
                ) : (
                  <TriangleDownIcon
                    color={darkMode ? '#ec5b55' : '#BF2F2C'}
                  ></TriangleDownIcon>
                )}
                <Text
                  marginLeft={2}
                  style={{ ...styles(darkMode,percentVal)['percentVal'] }}
                >
                  {parseFloat(percentVal).toFixed(2)}%
                </Text>
              </Flex>
            </Box>
            <Box marginLeft="auto">
              <Text style={{ ...styles(darkMode)['grayText'] }}>
                Verde Score
              </Text>
              <Flex>
                <img
                  src={VerdeScoreSvg}
                  alt=""
                  className="w-5 h-5 mb-0.5 mr-1 mt-1"
                />
                <Text
                  style={{
                    color: darkMode ? '#f6f6f6' : 'black',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {verdeScore}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </div>

      <Box marginTop={10}>
        <Flex>
          <Text style={{ ...styles(darkMode)['text'] }}>List Portfolios</Text>
          <Box
            backgroundColor={
              darkMode ? 'rgba(1,160,91,0.15)' : 'rgba(1,135,78,0.15)'
            }
            alignItems="center"
            padding={0.5}
            paddingLeft={2}
            paddingRight={2}
            borderRadius={5}
            marginLeft={2}
          >
            <Text style={{ ...styles(darkMode)['text'], fontSize: 14 }}>
              {data[0] && data[0]['quarters'].length > 0 && data[0]['quarters'][0]['holdings'].length}
            </Text>
          </Box>
        </Flex>

        <div
          style={{
            marginLeft: 5,
            borderRadius: 10,
            paddingTop: 25,
            paddingBottom: 25,
            alignItems: 'center',
          }}
        >
          {data[0] && data[0]['quarters'].length > 0 &&
            data[0]['quarters'][0]['holdings']
              .sort((a, b) => b.portfolioPercent - a.portfolioPercent)
              .map((elem) => {
                return (
                  <Flex
                    alignItems="center"
                    marginBottom={4}
                    padding={4}
                    paddingLeft={8}
                    paddingRight={8}
                    borderRadius={5}
                    backgroundColor={
                      darkMode ? 'rgba(38,38,38,0.5)' : 'rgb(246,248,250)'
                    }
                  >
                    <Flex alignItems="center">
                      <img
                        style={{
                          width: '30px',
                          height: '30px',
                          marginTop: '5px',
                        }}
                        src={`http://images.verde.finance/${elem.symbol.slice(
                          0,
                          elem.symbol.indexOf('.')
                        )}.png`}
                      />
                      <Text
                        style={{
                          marginLeft: 10,
                          color: darkMode ? 'white' : 'black',
                          fontWeight: 500,
                          textAlign: 'center',
                        }}
                      >
                        {elem.symbol.split('.')[0]}
                      </Text>
                    </Flex>

                    <Box marginLeft="auto">
                      <Text style={{ color: darkMode ? 'white' : 'black' }}>
                        {bigValueSuffix(elem.currentValue, '$', '')}{' '}
                        <span style={{ color: 'gray' }}>
                          ({elem.portfolioPercent}%)
                        </span>
                      </Text>
                    </Box>
                  </Flex>
                );
              })}
        </div>
      </Box>
    </div>
  );
}
