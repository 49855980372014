export const buttonOptions = [
  {
    type: 'day',
    count: 7,
    text: '1W',
  },
  {
    type: 'month',
    count: 1,
    text: '1M',
  },
  {
    type: 'month',
    count: 6,
    text: '6M',
  },
  {
    type: 'ytd',
    count: 1,
    text: 'YTD',
  },
  {
    type: 'year',
    count: 1,
    text: '1Y',
  },
  {
    type: 'year',
    count: 5,
    text: '5Y',
  },
];

export const accessors = {
  '1W': 'oneWeek',
  '1M': 'oneMonth',
  '6M': 'sixMonths',
  YTD: 'ytd',
  '1Y': 'oneYear',
  '5Y': 'fiveYears',
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
