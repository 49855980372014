import React from 'react';
import { connect } from 'react-redux';
import EarningsAndRevenueHistory from '../../shared/Sections/PastPerformance/EarningsAndRevenueHistory';
import EarningsPerShare from '../../shared/Sections/PastPerformance/EarningsPerShare';
import PriceToEarnings from '../../shared/Sections/PastPerformance/PriceToEarnings';
import PriceToEarningsGrowth from '../../shared/Sections/PastPerformance/PriceToEarningsGrowth';
import ReturnOnEquity from '../../shared/Sections/PastPerformance/ReturnOnEquity';
import ReturnOnAssets from '../../shared/Sections/PastPerformance/ReturnOnAssets';
import { SectionDivider } from '../../shared/StyedComponents';
import { getPastPerformanceData } from '../../../actions/action';
import { useParams } from 'react-router-dom';
import { showPageLoading } from '../../../actions/coreAction';
import { showErrorAlert } from '../../../actions/alertAction';
import { QUARTERLY } from '../../../utils/ChartUtils';
import IncomeStatement from '../../shared/Sections/PastPerformance/IncomeStatement';
import BalanceSheet from '../../shared/Sections/PastPerformance/BalanceSheet';
import ProfitabilityRatios from '../../shared/Sections/PastPerformance/ProfitabilityRatios';
import EfficiencyRatios from '../../shared/Sections/PastPerformance/EfficiencyRatios';
import MarketCapMultiples from '../../shared/Sections/PastPerformance/MarketCapMultiples';
import CashflowStatement from '../../shared/Sections/PastPerformance/CashflowStatement';
import LeverageRatios from '../../shared/Sections/PastPerformance/LeverageRatios';
import SharesOutstanding from '../../shared/Sections/PastPerformance/SharesOutstanding';
import SectionRating from '../../shared/SectionRating';

function PastPerformance(props) {
  const {
    symbol,
    data,
    error,
    loading,
    getPastPerformanceData,
    showPageLoading,
    showErrorAlert,
    insights,
    symbolData,
  } = props;
  const params = useParams();
  const [period, setPeriod] = React.useState(QUARTERLY);

  /* React.useEffect(() => {
    params.sid !== symbol && getPastPerformanceData(params.sid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  React.useEffect(() => {
    showPageLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    error && showErrorAlert(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (error) throw new Error('Error in past performance api');

  return (
    data &&
    data.length > 0 && (
      <>
        <SectionRating
          title="Safety Rating"
          infoKey="safety_rating"
          showTitle={true}
          rating={symbolData?.symbol?.safetyRating}
        />
        <SectionDivider />
        <IncomeStatement
          data={data}
          insights={insights}
          period={period}
          setPeriod={setPeriod}
        />
        <SectionDivider />
        <BalanceSheet
          data={data}
          insights={insights}
          period={period}
          setPeriod={setPeriod}
        />
        <SectionDivider />
        <CashflowStatement
          data={data}
          insights={insights}
          period={period}
          setPeriod={setPeriod}
        />
        <SectionDivider />
        <ProfitabilityRatios
          data={data}
          insights={insights}
          period={period}
          setPeriod={setPeriod}
        />
        <SectionDivider />
        <EfficiencyRatios
          data={data}
          insights={insights}
          period={period}
          setPeriod={setPeriod}
        />
        <SectionDivider />
        <LeverageRatios
          data={data}
          insights={insights}
          period={period}
          setPeriod={setPeriod}
        />
        <SectionDivider />
        <SharesOutstanding
          data={data}
          insights={insights}
          period={period}
          setPeriod={setPeriod}
        />
      </>
    )
  );
}

const mapStateToProps = (state) => ({
  symbol: state.pastPerformanceState.symbol,
  data: state.pastPerformanceState.data,
  error: state.pastPerformanceState.error,
  loading: state.pastPerformanceState.loading || state.valuationState.loading,
  insights: state.symbolInsightsState.data?.pastPerformance,
  error: state.pastPerformanceState.error,
  symbolData: state.valuationState.data,
});
const mapDispatchToProps = {
  getPastPerformanceData,
  showPageLoading,
  showErrorAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(PastPerformance);
