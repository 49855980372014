import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import SectionHeader from '../../../SectionHeader';
import { useMediaQuery } from '@chakra-ui/react';
import WrappedAxios from '../../../../../utils/WrappedAxios';
import { Auth } from 'aws-amplify';
import { SYMBOL_BASE_URL } from '../../../../../config/default';
import Rating from '@material-ui/lab/Rating';
import LoadingIndicator from '../../../LoadingIndicator';
import { formatDecimalValue } from '../../../../../utils/number';

const box = {
  width: '125px',
  height: '125px',
  backgroundColor: 'pink',
  padding: '5px',
  position: 'relative',
};

const line = {
  width: '100px',
  height: '5px',
  display: 'flex',
  alignSelf: 'center',
};
const circle = {
  borderRadius: '50%',
  width: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontWeight: 'bolder',
  fontSize: '20px',
  margin: '0',
};

const boxAfter = {
  position: 'absolute',
  right: '30px',
  bottom: '-15px',
  borderTop: '15px solid black',
  borderRight: '15px solid transparent',
  borderLeft: '15px solid transparent',
  borderBottom: 'none',
};

const Text = styled.div`
  ${tw`text-base`};
  color: ${(props) => props.theme.metrics.value};
`;

const Container = styled.div`
  ${tw`text-base`};
  background-color: ${(props) => props.theme.metrics.value};
`;

function AnalystRatings(props) {
  const [valuationData, setValuationData] = useState('');

  const [error, setError] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [isTablet] = useMediaQuery('(max-width: 1150px)');
  const [isSmall] = useMediaQuery('(max-width: 655px)');

  const maximum = Math.max(
    valuationData.analysts_strong_buy,
    valuationData.analysts_buy,
    valuationData.analysts_hold,
    valuationData.analysts_sell,
    valuationData.analysts_strong_sell
  );

  useEffect(() => {
    // fetch("https://xu60arr7p9.execute-api.us-east-1.amazonaws.com/prod/valuation/" + props.company, {
    //     headers : {
    //       'Content-Type': 'application/json',
    //       'Accept': 'application/json',
    //       'Authorization': `Bearer ${token}`

    //     }

    //   })
    // .then((response) => response.json())
    // .then((data) => setValuationData(data))
    // .catch((error) => {setError("Temporarily unavailable, hang on while we fix it")})

    const response2 = WrappedAxios.get(
      `${SYMBOL_BASE_URL}/valuation/${props.company}`
    )
      .then((response2) => {
        setValuationData(response2.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [props.company]);

  function responsive(value) {
    let nonDecimal = Math.floor(value);
    let decimal = (value - nonDecimal) * 100;

    return nonDecimal * 150 - 150 + 1.5 * decimal;
  }

  let total =
    valuationData.analysts_strong_buy +
    valuationData.analysts_buy +
    valuationData.analysts_hold +
    valuationData.analysts_sell +
    valuationData.analysts_strong_sell;

  console.log('ValuationData', valuationData);
  return (
    <div>
      <SectionHeader value="Analyst Ratings" />

      {valuationData === '' ? (
        <LoadingIndicator />
      ) : (
        <div
          style={{
            width: '100%',
            padding: isMobile ? '1px' : '10px',
            marginTop: '20px',
          }}
        >
          <div style={{ margin: isMobile ? '3px' : '10px' }}>
            <div style={{ display: 'flex' }}>
              <Text
                style={{
                  color: props.darkMode ? '#8acf55' : '#559C30',
                  fontSize: '35px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  marginRight: '20px',
                }}
              >
                {formatDecimalValue(valuationData.analyst_rating)}
              </Text>
              <Rating
                name="half-rating-read"
                value={valuationData.analyst_rating}
                precision={0.1}
                size="large"
                readOnly
              />
            </div>
            <Text style={{ fontSize: '20px' }}>Strong Buy</Text>
            <Text style={{ fontSize: '16px', color: 'gray' }}>
              Based on{' '}
              {valuationData.analysts_strong_buy +
                valuationData.analysts_buy +
                valuationData.analysts_hold +
                valuationData.analysts_sell +
                valuationData.analysts_strong_sell}{' '}
              Analyst Ratings
            </Text>
          </div>

          <div>
            <div
              style={{
                margin: '10px',
                marginTop: '20px',
              }}
            >
              <Text style={{ fontSize: '15px', marginBottom: '5px' }}>
                Strong Buy ({valuationData.analysts_strong_buy})
              </Text>
              <div
                style={{
                  padding: '0',
                  margin: '0',
                  display: 'flex',
                  height: '18px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: isMobile ? '85%' : '75%',
                    backgroundColor: props.darkMode ? '#343434' : '#F4F4F4',
                    borderRadius: '2px',
                  }}
                >
                  <div
                    style={{
                      width:
                        valuationData.analysts_strong_buy >= 1
                          ? total <= 100
                            ? `${
                                valuationData.analysts_strong_buy *
                                (100 / total)
                              }%`
                            : `${valuationData.analysts_strong_buy}`
                          : '1%',
                      backgroundColor: props.darkMode ? '#8acf55' : '#559C30',
                      height: '18px',
                      borderRadius: '2px',
                    }}
                  />
                </div>
                <Text style={{ textAlign: 'center', marginLeft: '10px' }}>
                  {Math.round(
                    (valuationData.analysts_strong_buy * 100) / total
                  )}
                  %
                </Text>
              </div>
            </div>

            <div style={{ margin: '10px' }}>
              <Text style={{ fontSize: '15px', marginBottom: '5px' }}>
                Buy ({valuationData.analysts_buy})
              </Text>
              <div
                style={{
                  padding: '0',
                  margin: '0',
                  display: 'flex',
                  height: '18px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: isMobile ? '85%' : '75%',
                    backgroundColor: props.darkMode ? '#343434' : '#F4F4F4',
                    borderRadius: '2px',
                  }}
                >
                  <div
                    style={{
                      width:
                        valuationData.analysts_buy >= 1
                          ? total <= 100
                            ? `${(valuationData.analysts_buy * 100) / total}%`
                            : `${valuationData.analysts_buy}`
                          : '1%',
                      backgroundColor: props.darkMode ? '#adcd57' : '#B5A93B',
                      height: '18px',
                      borderRadius: '2px',
                    }}
                  />
                </div>
                <Text style={{ textAlign: 'center', marginLeft: '10px' }}>
                  {Math.round((valuationData.analysts_buy * 100) / total)}%
                </Text>
              </div>
            </div>

            <div style={{ margin: '10px' }}>
              <Text style={{ fontSize: '15px', marginBottom: '5px' }}>
                Hold ({valuationData.analysts_hold})
              </Text>
              <div
                style={{
                  padding: '0',
                  margin: '0',
                  display: 'flex',
                  height: '10px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: isMobile ? '85%' : '75%',
                    backgroundColor: props.darkMode ? '#343434' : '#F4F4F4',
                    borderRadius: '2px',
                  }}
                >
                  <div
                    style={{
                      width:
                        valuationData.analysts_hold >= 1
                          ? total <= 100
                            ? `${(valuationData.analysts_hold * 100) / total}%`
                            : `${valuationData.analysts_hold}`
                          : '1%',
                      backgroundColor: props.darkMode ? '#f6c759' : '#EFB241',
                      height: '18px',
                      borderRadius: '2px',
                    }}
                  />
                </div>
                <Text style={{ textAlign: 'center', marginLeft: '10px' }}>
                  {Math.round((valuationData.analysts_hold * 100) / total)}%
                </Text>
              </div>
            </div>

            <div style={{ margin: '10px' }}>
              <Text style={{ fontSize: '15px', marginBottom: '5px' }}>
                Sell ({valuationData.analysts_sell})
              </Text>
              <div
                style={{
                  padding: '0',
                  margin: '0',
                  display: 'flex',
                  height: '10px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: isMobile ? '85%' : '75%',
                    backgroundColor: props.darkMode ? '#343434' : '#F4F4F4',
                    borderRadius: '2px',
                  }}
                >
                  <div
                    style={{
                      width:
                        valuationData.analysts_sell >= 1
                          ? total <= 100
                            ? `${(valuationData.analysts_sell * 100) / total}%`
                            : `${valuationData.analysts_sell}`
                          : '1%',
                      backgroundColor: props.darkMode ? '#f18a57' : '#E0833A',
                      height: '18px',
                      borderRadius: '2px',
                    }}
                  />
                </div>
                <Text style={{ textAlign: 'center', marginLeft: '10px' }}>
                  {Math.round((valuationData.analysts_sell * 100) / total)}%
                </Text>
              </div>
            </div>

            <div style={{ margin: '10px' }}>
              <Text style={{ fontSize: '15px', marginBottom: '5px' }}>
                Strong Sell ({valuationData.analysts_strong_sell})
              </Text>

              <div
                style={{
                  padding: '0',
                  margin: '0',
                  display: 'flex',
                  height: '10px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: isMobile ? '85%' : '75%',
                    backgroundColor: props.darkMode ? '#343434' : '#F4F4F4',
                    borderRadius: '2px',
                    height: '18px',
                  }}
                >
                  <div
                    style={{
                      width:
                        valuationData.analysts_strong_sell >= 1
                          ? total <= 100
                            ? `${
                                (valuationData.analysts_strong_sell * 100) /
                                total
                              }%`
                            : `${valuationData.analysts_strong_sell}`
                          : '1%',
                      backgroundColor: props.darkMode ? '#ec5b55' : '#BF2F2C',
                      height: '18px',
                      borderRadius: '2px',
                    }}
                  />
                </div>
                <Text style={{ textAlign: 'center', marginLeft: '10px' }}>
                  {Math.round(
                    (valuationData.analysts_strong_sell * 100) / total
                  )}
                  %
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AnalystRatings;
