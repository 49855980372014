import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import { getStackedAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';

const BalanceSheet = ({ data, insights, period, setPeriod, darkMode }) => {
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'Total Assets',
      selected: true,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'Total Liabilities',
      selected: true,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'Total Equity',
      selected: true,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
    {
      name: 'Cash and ST Investments',
      selected: false,
      darkColor: '#DDDDDD',
      lightColor: '#012749',
    },
    {
      name: 'Total Debt',
      selected: false,
      darkColor: '#FF7979',
      lightColor: '#F26970',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    [
      'evaluated_on',
      'total_assets',
      'total_liabilities',
      'total_equity',
      'cash_short_term_investments',
      'total_debt',
    ],
    period
  );
  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['Total Assets'].selected
      ? {
          name: 'Total Assets',
          data: fullChartData.map((item) => [item[0], item[2]]),
        }
      : [],
    chartVariants['Total Liabilities'].selected
      ? {
          name: 'Total Liabilities',
          data: fullChartData.map((item) => [item[0], item[3]]),
        }
      : [],
    chartVariants['Total Equity'].selected
      ? {
          name: 'Total Equity',
          data: fullChartData.map((item) => [item[0], item[4]]),
        }
      : [],
    chartVariants['Cash and ST Investments'].selected
      ? {
          name: 'Cash and ST Investments',
          data: fullChartData.map((item) => [item[0], item[5]]),
        }
      : [],
    chartVariants['Total Debt'].selected
      ? {
          name: 'Total Debt',
          data: fullChartData.map((item) => [item[0], item[1]]),
        }
      : [],
  ];

  return (
    <>
      <SectionHeader className="mb-6" value="Balance Sheet" />
      <BorderedChartContainer>
        <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div>

        <StackedLineChart data={chartData} valueType="currency" />
        <div className="flex justify-center">
          <MultiLineGraphSelectorButton
            values={buttonValues}
            setValues={setButtonValues}
          />
        </div>
      </BorderedChartContainer>

      <BulletPoints
        title="Positives"
        points={insights?.balanceSheet?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.balanceSheet?.negatives}
        isPositive={false}
      />
    </>
  );
};

export default BalanceSheet;
