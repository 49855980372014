import React, { useEffect, useState } from 'react';
import SectionHeader from '../../../SectionHeader';
import { useMediaQuery } from '@chakra-ui/react';

// props.valuationData current_price fair_price target_price

function CurrentPrice(props) {
  let currentPrice = props.data.symbol.currentPrice;
  let fairPrice = props.data.symbol.fairPrice;
  let targetPrice = props.data.symbol.targetPrice;

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTablet] = useMediaQuery('(max-width: 1150px)');

  let gapValue = 0.165 * currentPrice;

  const leftCurrent = currentPrice - 3 * gapValue;
  const midCurrent = currentPrice - 2 * gapValue;

  const leftFair = currentPrice - gapValue;
  const rightFair = parseFloat(currentPrice) + parseFloat(gapValue);

  const midTarget = parseFloat(currentPrice) + parseFloat(2 * gapValue);
  const rightTarget = parseFloat(currentPrice) + parseFloat(3 * gapValue);

  const darkMode = props.darkMode;

  const styles = {
    p: { fontSize: isMobile ? 10 : '16px', color: 'gray', marginTop: '8px' },
    value: {
      fontSize: isMobile ? 12 : '20px',
      fontWeight: 'bold',
      color: darkMode ? 'white' : 'black',
    },
  };

  return (
    <>
      {currentPrice !== null ? (
        <>
          <SectionHeader value="Current Price vs Fair Price vs Target Price" />

          <div
            style={{
              marginTop:
                (fairPrice < leftCurrent && targetPrice < leftCurrent) ||
                Math.abs(fairPrice - currentPrice) <=
                  (isMobile ? gapValue : gapValue / 2) ||
                (fairPrice > rightTarget && targetPrice > rightTarget) ||
                Math.abs(fairPrice - targetPrice) <=
                  (isMobile ? gapValue : gapValue / 2) ||
                Math.abs(currentPrice - targetPrice) <=
                  (isMobile ? gapValue : gapValue / 1.5)
                  ? '80px'
                  : '20px',
            }}
          >
            {currentPrice !== null && fairPrice !== null ? (
              parseFloat(fairPrice) < parseFloat(currentPrice) ? (
                <p
                  style={{
                    marginBottom: '2px',
                    fontWeight: 'bold',
                    fontSize: isMobile ? '14px' : isTablet ? '15px' : '17px',

                    color: darkMode ? '#ec5b55' : '#BE2E2B',

                    marginLeft:
                      fairPrice < leftCurrent
                        ? '16%'
                        : fairPrice < midCurrent
                        ? `${
                            ((fairPrice - leftCurrent) /
                              (midCurrent - leftCurrent)) *
                            32
                          }%`
                        : fairPrice < leftFair
                        ? `${
                            17 +
                            ((fairPrice - midCurrent) /
                              (leftFair - midCurrent)) *
                              17
                          }%`
                        : `${
                            32 +
                            ((fairPrice - leftFair) / (leftFair - midCurrent)) *
                              17
                          }%`,
                  }}
                >
                  Overvalued By{' '}
                  {Math.abs(
                    (parseFloat(fairPrice) / parseFloat(currentPrice) - 1) * 100
                  ).toFixed(2)}
                  %
                </p>
              ) : (
                <p
                  style={{
                    marginBottom: '2px',
                    fontWeight: 'bold',
                    fontSize: isMobile ? '14px' : isTablet ? '15px' : '17px',
                    color: darkMode ? '#8acf55' : '#559C30',

                    marginLeft:
                      fairPrice > rightTarget
                        ? '65%'
                        : fairPrice > midTarget
                        ? `${
                            51 +
                            ((fairPrice - midTarget) /
                              (rightTarget - midTarget)) *
                              17
                          }%`
                        : fairPrice > rightFair
                        ? `${52}%`
                        : `${50}%`,
                  }}
                >
                  Undervalued by{' '}
                  {Math.abs(
                    (parseFloat(fairPrice) / parseFloat(currentPrice) - 1) * 100
                  ).toFixed(2)}
                  %
                </p>
              )
            ) : (
              <div></div>
            )}

            {currentPrice !== null && fairPrice !== null ? (
              parseFloat(fairPrice) < parseFloat(currentPrice) ? (
                <div
                  style={{
                    width:
                      fairPrice < leftCurrent
                        ? `48%`
                        : fairPrice < midCurrent
                        ? `${
                            34 +
                            15 -
                            ((fairPrice - leftCurrent) /
                              (midCurrent - leftCurrent)) *
                              16.5
                          }%`
                        : fairPrice < leftFair
                        ? `${
                            34 -
                            ((fairPrice - midCurrent) /
                              (leftFair - midCurrent)) *
                              17
                          }%`
                        : `${
                            17 -
                            ((fairPrice - leftFair) / (leftFair - midCurrent)) *
                              17
                          }%`,

                    marginLeft: 'auto',
                    marginRight: '51%',
                    height: 3,
                    backgroundColor: darkMode ? '#ec5b55' : '#BE2E2B',
                    marginBottom: 20,
                  }}
                />
              ) : (
                <div
                  style={{
                    width:
                      fairPrice > rightTarget
                        ? '48.5%'
                        : fairPrice > midTarget
                        ? `${
                            33 +
                            ((fairPrice - midTarget) /
                              (rightTarget - midTarget)) *
                              16.5
                          }%`
                        : fairPrice > rightFair
                        ? `${
                            16 +
                            ((fairPrice - rightFair) /
                              (midTarget - rightFair)) *
                              17
                          }%`
                        : `${
                            ((fairPrice - currentPrice) /
                              (rightFair - currentPrice)) *
                            16
                          }%`,
                    marginLeft: '50%',
                    height: 3,

                    backgroundColor: darkMode ? '#8acf55' : '#559C30',
                    marginBottom: 20,
                  }}
                />
              )
            ) : (
              <div></div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 45,
              }}
            >
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: 13,
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      width: '33%',
                      backgroundColor: darkMode ? '#8acf55' : '#559C30',
                      marginLeft: 3,
                      marginRight: 3,
                    }}
                  />

                  <div
                    style={{
                      width: '33%',
                      backgroundColor: darkMode ? '#f6c759' : '#EEB141',
                      marginLeft: 3,
                      marginRight: 3,
                    }}
                  />

                  <div
                    style={{
                      width: '33%',
                      backgroundColor: darkMode ? '#ec5b55' : '#BE2E2B',
                      marginLeft: 3,
                      marginRight: 3,
                    }}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: 50,
                    marginTop: 25,
                    justifyContent: 'space-around',
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      textAlign: 'center',
                      bottom:
                        (fairPrice < leftCurrent &&
                          targetPrice < leftCurrent) ||
                        (fairPrice < leftCurrent && targetPrice < midCurrent) ||
                        (fairPrice < midCurrent && targetPrice < leftCurrent) ||
                        Math.abs(fairPrice - currentPrice) <=
                          (isMobile ? gapValue / 1.25 : gapValue / 2)
                          ? 175
                          : '',

                      right:
                        fairPrice <= leftCurrent
                          ? '15%'
                          : fairPrice < midCurrent
                          ? `${
                              16 -
                              ((fairPrice - leftCurrent) /
                                (midCurrent - leftCurrent)) *
                                17
                            }%`
                          : '',

                      left:
                        fairPrice > rightTarget
                          ? '83%'
                          : fairPrice > midTarget
                          ? `${
                              68 +
                              ((fairPrice - midTarget) /
                                (rightTarget - midTarget)) *
                                17
                            }%`
                          : fairPrice > rightFair
                          ? `${
                              51 +
                              ((fairPrice - rightFair) /
                                (midTarget - rightFair)) *
                                17
                            }%`
                          : fairPrice > currentPrice
                          ? `${
                              34 +
                              ((fairPrice - currentPrice) /
                                (rightFair - currentPrice)) *
                                17
                            }%`
                          : fairPrice > leftFair
                          ? `${
                              17 +
                              ((fairPrice - leftFair) /
                                (currentPrice - leftFair)) *
                                17
                            }%`
                          : fairPrice > midCurrent
                          ? `${
                              ((fairPrice - midCurrent) /
                                (leftFair - midCurrent)) *
                              17
                            }%`
                          : '',
                    }}
                  >
                    {fairPrice && (
                      <div
                        style={{
                          height:
                            (fairPrice < leftCurrent &&
                              targetPrice < leftCurrent) ||
                            (fairPrice < leftCurrent &&
                              targetPrice < midCurrent) ||
                            (fairPrice < midCurrent &&
                              targetPrice < leftCurrent) ||
                            Math.abs(fairPrice - currentPrice) <=
                              (isMobile ? gapValue / 1.25 : gapValue / 2)
                              ? 30
                              : 90,
                          position: 'absolute',
                          bottom:
                            (fairPrice < leftCurrent &&
                              targetPrice < leftCurrent) ||
                            (fairPrice < leftCurrent &&
                              targetPrice < midCurrent) ||
                            (fairPrice < midCurrent &&
                              targetPrice < leftCurrent) ||
                            Math.abs(fairPrice - currentPrice) <=
                              (isMobile ? gapValue / 1.25 : gapValue / 2)
                              ? -40
                              : 40,
                          borderRadius: 1,
                          border: 'none',
                          borderLeft: '2px dashed gray',
                          left: 30,
                        }}
                      />
                    )}

                    {fairPrice && (
                      <>
                        <p style={styles.p}>Fair Price</p>
                        <p style={styles.value}>${fairPrice}</p>
                      </>
                    )}
                  </div>

                  <div
                    style={{
                      textAlign: 'center',
                      position: 'relative',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        height: fairPrice ? 90 : 30,
                        position: 'absolute',
                        bottom: 40,
                        borderRadius: 1,
                        border: 'none',
                        borderLeft: '2px dashed gray',
                        left: 45,
                      }}
                    />
                    <p style={styles.p}>Current Price</p>
                    <p style={styles.value}>${currentPrice}</p>
                  </div>

                  <div
                    style={{
                      position: 'relative',
                      bottom:
                        (fairPrice > rightTarget &&
                          targetPrice > rightTarget) ||
                        (fairPrice > midTarget && targetPrice > rightTarget) ||
                        (fairPrice > rightTarget && targetPrice > midTarget) ||
                        Math.abs(fairPrice - targetPrice) <= gapValue / 1.5 ||
                        Math.abs(currentPrice - targetPrice) <= gapValue / 1.5
                          ? 175
                          : '',

                      textAlign: 'center',

                      left:
                        targetPrice >= rightTarget
                          ? '16.5%'
                          : targetPrice > midTarget
                          ? `${
                              ((targetPrice - midTarget) /
                                (rightTarget - midTarget)) *
                              17
                            }%`
                          : '',

                      right:
                        targetPrice < leftCurrent
                          ? '83%'
                          : targetPrice < midCurrent
                          ? `${
                              83 -
                              ((targetPrice - leftCurrent) /
                                (midCurrent - leftCurrent)) *
                                17
                            }%`
                          : targetPrice < leftFair
                          ? `${
                              68 -
                              ((targetPrice - midCurrent) /
                                (leftFair - midCurrent)) *
                                17
                            }%`
                          : targetPrice < currentPrice
                          ? `${
                              51 -
                              ((targetPrice - leftFair) /
                                (currentPrice - leftFair)) *
                                17
                            }%`
                          : targetPrice < rightFair
                          ? `${
                              34 -
                              ((targetPrice - currentPrice) /
                                (rightFair - currentPrice)) *
                                17
                            }%`
                          : targetPrice < midTarget
                          ? `${
                              17 -
                              ((targetPrice - rightFair) /
                                (midTarget - rightFair)) *
                                17
                            }%`
                          : '',
                    }}
                  >
                    <div
                      style={{
                        height:
                          (fairPrice > rightTarget &&
                            targetPrice > rightTarget) ||
                          (fairPrice > midTarget &&
                            targetPrice > rightTarget) ||
                          (fairPrice > rightTarget &&
                            targetPrice > midTarget) ||
                          Math.abs(fairPrice - targetPrice) <= gapValue / 1.5 ||
                          Math.abs(currentPrice - targetPrice) <= gapValue / 1.5
                            ? 80
                            : 30,
                        position: 'absolute',
                        bottom:
                          (fairPrice > rightTarget &&
                            targetPrice > rightTarget) ||
                          (fairPrice > midTarget &&
                            targetPrice > rightTarget) ||
                          (fairPrice > rightTarget &&
                            targetPrice > midTarget) ||
                          Math.abs(fairPrice - targetPrice) <= gapValue / 1.5 ||
                          Math.abs(currentPrice - targetPrice) <= gapValue / 1.5
                            ? -100
                            : 40,
                        borderRadius: 1,
                        border: 'none',
                        borderLeft: '2px dashed gray',
                        left: 30,
                      }}
                    />
                    <p style={styles.p}>Target Price</p>
                    <p style={styles.value}>${targetPrice}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <SectionHeader value="Data not Available" />
        </div>
      )}
    </>
  );
}

export default CurrentPrice;
