import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import SectionHeader from '../../../SectionHeader';
import { RATING_COLOR_CODE } from '../../../RatingCode/style';
import RatioGraph from '../../../RatioGraphRevamp';
import RatioGraphInsights from '../../../RatioGraphInsights';
import SectionRating from '../../../SectionRating';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JsxParser from 'react-jsx-parser';
import { components, decorate } from '../../../../shared/BulletPoints';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import RiskPositives from '../../../RiskPositives';
import data from '../../../../../data/cpy';

const CurrentYieldPercent = styled.div`
  ${tw`text-2xl font-bold`};
  color: ${(props) => props.theme.dividend.currentYield};
`;

const CurrentYieldText = styled.div`
  ${tw`flex text-sm text-white items-center`};
  color: ${(props) => props.theme.dividend.currentYieldText};
`;

const CurrentYield = ({ data, insights, darkMode, etfData, spyData }) => {
  const ratios =
    data !== undefined
      ? [
          { name: 'Symbol', value: data.comparison.symbol },
          { name: 'Sector', value: data.comparison.sector },
          { name: 'Market', value: data.comparison.market },
        ]
      : [
          {
            name: 'Symbol',
            value: parseFloat(etfData.ETF_Data['Dividend Yield']).toFixed(2),
          },
          {
            name: 'Category',
            value: parseFloat(
              etfData.Valuation['Dividend_Yield_Factor']
            ).toFixed(2),
          },
          {
            name: 'Market',
            value: parseFloat(spyData.ETF_Data['Dividend Yield']).toFixed(2),
          },
        ];

  const industryPositives = insights?.dividend_yield?.Industry?.positives
    ? insights?.dividend_yield?.Industry?.positives
    : [];
  const marketPositives = insights?.dividend_yield?.Market?.positives
    ? insights?.dividend_yield?.Market?.positives
    : [];
  const industryNegatives = insights?.dividend_yield?.Industry?.negatives
    ? insights?.dividend_yield?.Industry?.negatives
    : [];
  const marketNegatives = insights?.dividend_yield?.Market?.negatives
    ? insights?.dividend_yield?.Market?.negatives
    : [];

  return (
    <>
      <SectionHeader
        value="Dividend Yield(FWD)"
        className="mb-6"
        infoKey="dividend_yield"
      />
      <div className="flex">
        <div style={{ backgroundColor: darkMode ? '#343434' : '#f9f9f9' }}>
          {data !== undefined ? (
            <SectionRating
              title="Dividend Rating"
              rating={data.rating}
              darkMode={darkMode}
            />
          ) : (
            <SectionRating
              title="Dividend Rating"
              rating={etfData.ETF_Data.dividend_rating}
              darkMode={darkMode}
            />
          )}
        </div>
        <div
          className="flex flex-col justify-center ml-5"
          style={{
            backgroundColor: darkMode ? '#343434' : '#f9f9f9',
            padding: '10px',
            borderRadius: '5px',
            alignItems: 'center',
          }}
        >
          {data !== undefined ? (
            <CurrentYieldPercent>{data.yieldPercent}%</CurrentYieldPercent>
          ) : (
            <CurrentYieldPercent>
              {etfData.ETF_Data['Dividend Yield']}%
            </CurrentYieldPercent>
          )}
          <CurrentYieldText>
            {data !== undefined ? (
              <div className="font-medium text-base mr-1">{data.symbol}</div>
            ) : (
              <div className="font-medium text-base mr-1">
                {etfData.ETF_Data.ETF_Code}
              </div>
            )}
            <div className="mt-0.5">Current Dividend Yield</div>
          </CurrentYieldText>
        </div>
      </div>
      {data !== undefined ? (
        <RatioGraph ratios={ratios} symbol={data.symbol} suffix="%" />
      ) : etfData.ETF_Data !== {} ? (
        <RatioGraph
          ratios={ratios}
          symbol={etfData.ETF_Data.ETF_Code}
          suffix="x"
        />
      ) : (
        <div></div>
      )}

      {industryPositives.length === 0 && industryNegatives.length === 0 ? (
        <div></div>
      ) : (
        <div>
          <h3
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: darkMode ? 'white' : 'black',
            }}
          >
            PE vs Sector
          </h3>

          <RiskPositives
            positives={industryPositives}
            negatives={industryNegatives}
          />
        </div>
      )}

      {marketPositives.length === 0 && marketNegatives.length === 0 ? (
        <div></div>
      ) : (
        <>
          <h3
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: darkMode ? 'white' : 'black',
            }}
          >
            PE vs Market
          </h3>
          <RiskPositives
            positives={marketPositives}
            negatives={marketNegatives}
          />
        </>
      )}
    </>
  );
};

export default CurrentYield;
