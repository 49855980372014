import { usePlaidLink } from 'react-plaid-link';
import { useCallback, useEffect, useState } from 'react';
import wrappedAxios from '../utils/WrappedAxios';
import { useAuth } from '../components/hocs/AuthProvider';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { PLAID_GENERATE_TOKEN } from '../config/default';
import clevertap from 'clevertap-web-sdk';

function usePlaid(username,email) { 
  const [token, setToken] = useState(null);
  const { authUser } = useAuth();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const onSuccess = useCallback(
    async (publicToken) => {
      setLoading(true);
      const { data } = await wrappedAxios.get(
        `${PLAID_GENERATE_TOKEN}/generatetoken?token=${publicToken}&sub=${authUser.attributes.sub}`
      );
      // socialUsername && history.push(`/myverde/${socialUsername}`);
      clevertap.event.push('Portfolio Linked', {
        Email:email,
        Username:username,
      })
      window.location.reload();
    },
    [authUser.attributes.sub]
  );
  // Creates a Link token
  const createLinkToken = useCallback(async () => {
    // For OAuth, use previously generated Link token
    if (window.location.href.includes('?oauth_state_id=') || token) {
      const linkToken = localStorage.getItem('link_token');
      setToken(linkToken);
    } else {
      const response = await wrappedAxios.post(
        `${PLAID_GENERATE_TOKEN}/linktoken`
      );
      const { data } = response;
      setToken(data.token);
      localStorage.setItem('link_token', data.link_token);
    }
  }, [token]);

  // Fetch balance data
  const getBalance = useCallback(
    async (token) => {
      setLoading(true);
      const response = await fetch('/api/balance', {});
      const data = await response.json();
      setData(data);
      setLoading(false);
    },
    [setData, setLoading]
  );

  let isOauth = false;

  const config = {
    token,
    onSuccess,
  };

  // For OAuth, configure the received redirect URI
  if (window.location.href.includes('?oauth_state_id=')) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }
  const { open, ready } = usePlaidLink(config);
  useEffect(() => {
    if (token == null) {
      createLinkToken();
    }
  }, [createLinkToken, token]);
  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  return { loading, data, token, open };
}

export default usePlaid;
