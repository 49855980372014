import { InfoOutlineIcon, TriangleDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { DarkMode, useMediaQuery } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SingleTicker, SymbolInfo } from 'react-tradingview-embed';
import { Text } from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import PageContainer from '../../shared/PageContainer';
import SectorIcon from '../../shared/SectorIcon';
import SymbolLogo from '../../shared/SymbolLogo';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import { useParams, Link } from 'react-router-dom';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import SectionHeader from '../../shared/SectionHeader';
import { useInView } from 'react-intersection-observer';
import bookMarkLogoDark from '../../../public/images/bookMarkDark.png';
import bookMarkLogoWhite from '../../../public/images/bookMarkWhite.png';
import { showPageLoading } from '../../../actions/coreAction';
import { useHistory } from 'react-router-dom';
import {
  getSimilarCompaniesData,
  getStockInfo,
  getValuationData,
  getPastPerformanceData,
  getFutureGrowthData,
  getDividendData,
  getInsidersData,
  getHoldersData,
  getSummaryData,
  getRealTimeData,
} from '../../../actions/action';
import { gtag } from '../../../utils/analytics';
import { fetchSymbolInsights } from '../../../actions/insightsAction';
import { fetchSymbolNews } from '../../../actions/newsAction';
import { SYMBOL_BASE_URL } from '../../../config/default';
import WrappedAxios from '../../../utils/WrappedAxios';
import { useAppSelector } from '../../../hooks/store';
import { components, decorate } from '../../shared/BulletPoints';
import JsxParser from 'react-jsx-parser';
import { ANNUALLY } from '../../../utils/ChartUtils';
import { showErrorAlert } from '../../../actions/alertAction';
import RiskPositives from '../../shared/RiskPositives';

import CompanySummary from '../../shared/Sections/CompanySummaryRevamp';
import Highlights from '../../shared/Highlights';
import ValuationSummary from '../../shared/Sections/ValuationRevamp/ValuationSummary';
import CurrentPrice from '../../shared/Sections/ValuationRevamp/CurrentPrice/CurrentPrice';
import AnalystRatings from '../../shared/Sections/ValuationRevamp/AnalystRatings';
import PriceGrowth from '../../shared/Sections/ValuationRevamp/PriceGrowth';
import StockInformation from '../../shared/Sections/ValuationRevamp/StockInformation';
import Performance from '../../shared/Sections/ValuationRevamp/Performance';
import PriceToEarnings from '../../shared/Sections/ValuationRevamp/PriceToEarnings';
import PriceToEarningsGrowth from '../../shared/Sections/ValuationRevamp/PriceToEarningsGrowth';
import PriceToBook from '../../shared/Sections/ValuationRevamp/PriceToBook';
import PriceToSales from '../../shared/Sections/ValuationRevamp/PriceToSales';
import MarketCapMultiples from '../../shared/Sections/PastPerformanceRevamp/MarketCapMultiples';
import EnterpriseValueMultiples from '../../shared/Sections/PastPerformanceRevamp/EnterpriseValueMultiples';
import IncomeStatement from '../../shared/Sections/PastPerformanceRevamp/IncomeStatement';
import BalanceSheet from '../../shared/Sections/PastPerformanceRevamp/BalanceSheet';
import ProfitabilityRatios from '../../shared/Sections/PastPerformanceRevamp/ProfitabilityRatios';
import EfficiencyRatios from '../../shared/Sections/PastPerformanceRevamp/EfficiencyRatios';
import CashflowStatement from '../../shared/Sections/PastPerformanceRevamp/CashflowStatement';
import LeverageRatios from '../../shared/Sections/PastPerformanceRevamp/LeverageRatios';
import SharesOutstanding from '../../shared/Sections/PastPerformanceRevamp/SharesOutstanding';
import EarningsProjection from '../../shared/Sections/FutureGrowthRevamp/EarningsPerShareProjection';
import ProjectedEarnings from '../../shared/Sections/FutureGrowthRevamp/NetIncomeProjection';
import RevenueProjection from '../../shared/Sections/FutureGrowthRevamp/RevenueProjection';
import CurrentYield from '../../shared/Sections/DividendRevamp/CurrentYield';
import TTMDividendYield from '../../shared/Sections/DividendRevamp/TTMDividendYield';
import YieldOnCost from '../../shared/Sections/DividendRevamp/YieldOnCost';
import DividendHistory from '../../shared/Sections/DividendRevamp/DividendHistory';
import NetIncomePayoutRatio from '../../shared/Sections/DividendRevamp/NetIncomePayoutRatio';
import FreeCashFlowPayoutRatio from '../../shared/Sections/DividendRevamp/FreeCashFlowPayoutRatio';
import TotalShareholderReturn from '../../shared/Sections/DividendRevamp/TotalShareholderReturn';
import InsiderTransactions from '../../shared/Sections/OwnershipRevamp/InsidersTransactions';
import InstitutionalShareholders from '../../shared/Sections/OwnershipRevamp/InstitutionalShareholders';
import FundsShareholders from '../../shared/Sections/OwnershipRevamp/FundsShareholders';

import SimilarCompaniesRevamp from '../../shared/SimilarCompaniesRevamp';
import LoadingIndicator from '../../shared/LoadingIndicator';

import { ColoredContentText } from '../../shared/StyedComponents';
import { tabsUnstyledClasses } from '@mui/base';
import ViewSummaryRevamp from '../../shared/Sections/ViewSummaryRevamp';
import { isFunction, values } from 'lodash';
import NoDataFound from '../../shared/NoDataFound';
import SectionDisplay from '../../hocs/SectionDisplay';
import NotExists from '../NotExists';
import PremiumRestrictionComponent from '../../shared/PremiumRestrictionComponent';

type Theme = DefaultTheme & { isDark: boolean };
const themeObject = {
  dark: {
    bg: '#f4f4f4',
    navItemSelectedBg: 'white',
    navItemSelectedBorder: '#01874e',
    navItemColor: '#8c8c8c',
    navItemSelectedColor: '#01874e',
  },
  light: {
    bg: '#f4f4f4',
    navItemSelectedBg: 'white',
    navItemSelectedBorder: '#01874e',
    navItemColor: '#8c8c8c',
    navItemSelectedColor: '#01874e',
  },
};

const PageContainerW: any = PageContainer;
const scrollToElement = (id: string, isMobile: boolean) => {
  var element = document.getElementById(id);
  if (element !== null) {
    var headerOffset = isMobile ? 50 : 100;
    var elementPosition = element!.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

const clickTab = (tabId: string) => {
  const tabElm = document.getElementById(`tab-${decodeURIComponent(tabId)}`);
  if (tabElm) {
    tabElm.dispatchEvent(new Event('click', { bubbles: true }));
  }
};

const inViewChange = (tab: string, state: boolean, sid: string) => {
  if (state) {
    gtag('event', `${tab}-company-scroll`, {
      event_category: tab,
      event_label: sid,
    });
  }
};
const Main: FC<{
  darkMode: any;
  getValuationData: (arg1: string, arg2: boolean) => void;
  getStockInfo: (arg: string) => void;
  getSimilarCompaniesData: (arg: string) => void;
  fetchSymbolInsights: (arg: string) => void;
  fetchSymbolNews: (arg: string) => void;
  getPastPerformanceData: (arg: string) => void;
  getDividendData: (arg: string) => void;
  getFutureGrowthData: (arg: string) => void;
  getHoldersData: (arg1: string, arg2: string) => void;
  getInsidersData: (arg: string) => void;
  getSummaryData: (arg: string) => void;
  getRealTimeData: (arg: string) => void;
  futureGrowthData: any;
  futureGrowthInsights: any;
  dividendData: any;
  dividendInsights: any;
  pastPerformanceData: any;
  pastPerformanceInsights: any;
  valuationData: any;
  valuationDataError: any;
  valuationInsights: any;
  stockInfoData: any;
  insidersData: any;
  ownershipInsights: any;
  summaryData: any;
  loading: any;
  showPageLoading: any;
  holdersData: any;
  valuationDataLoading: any;
  pastPerformanceDataLoading: any;
  insightsLoading: any;
  futureGrowthDataLoading: any;
  dividendDataLoading: any;
  insidersDataLoading: any;
  similarCompaniesData: any;
  similarCompaniesDataLoading: any;
  insightsData: any;
  subscriptionStatus: any;
  publicSymbols: any;
}> = ({
  darkMode,
  getValuationData,
  getStockInfo,
  getSimilarCompaniesData,
  fetchSymbolInsights,
  fetchSymbolNews,
  getPastPerformanceData,
  getDividendData,
  getSummaryData,
  getFutureGrowthData,
  getHoldersData,
  getInsidersData,
  getRealTimeData,
  futureGrowthData,
  futureGrowthInsights,
  dividendData,
  dividendInsights,
  pastPerformanceData,
  pastPerformanceInsights,
  valuationData,
  valuationDataError,
  valuationInsights,
  stockInfoData,
  insidersData,
  ownershipInsights,
  summaryData,
  loading,
  showPageLoading,
  holdersData,
  valuationDataLoading,
  pastPerformanceDataLoading,
  insightsLoading,
  futureGrowthDataLoading,
  dividendDataLoading,
  insidersDataLoading,
  similarCompaniesData,
  similarCompaniesDataLoading,
  insightsData,
  subscriptionStatus,
  publicSymbols,
}) => {
  useEffect(() => {
    showPageLoading(
      valuationDataLoading ||
        pastPerformanceDataLoading ||
        insightsLoading ||
        futureGrowthDataLoading ||
        dividendDataLoading ||
        insidersDataLoading
    );
  }, [
    valuationDataLoading,
    pastPerformanceDataLoading,
    insightsLoading,
    futureGrowthDataLoading,
    dividendDataLoading,
    insidersDataLoading,
  ]);

  const currentTheme: Theme = useTheme() as Theme;
  const theme = currentTheme.isDark ? themeObject.dark : themeObject.light;
  const params: { sid: string } = useParams();
  const { data: valuationState } = useAppSelector(
    (state) => state.valuationState
  );
  const insights = useAppSelector(
    (state) => state.symbolInsightsState.data?.valuation
  );

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTablet] = useMediaQuery('(max-width: 1150px)');

  const [backButton, setBackButton] = useState(false); // The widget glitches out sometimes and does not let the page to load
  const [notExists, setNotExists] = useState(false);
  const descRef = useRef<HTMLDivElement>(null);
  const [descWidth, setDescWidth] = React.useState(0);

  const [period, setPeriod] = React.useState(ANNUALLY);

  useEffect(() => {
    if (valuationDataError) {
      setNotExists(true);
    }
  }, [valuationDataError]);

  // console.log('DIVIDEND DATA' , dividendData)

  useEffect(() => {
    setNotExists(false);
    getValuationData(params.sid, true);
    //getRealTimeData(params.sid);
    getPastPerformanceData(params.sid);
    getStockInfo(params.sid);
    fetchSymbolInsights(params.sid);
    getSimilarCompaniesData(params.sid);
    // fetchSymbolNews(params.sid);de
    getFutureGrowthData(params.sid);
    getDividendData(params.sid);
    getInsidersData(params.sid);
    getHoldersData(params.sid, 'INSTITUTIONS');
    getHoldersData(params.sid, 'FUNDS');
    getSummaryData(params.sid);
    setBackButton(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sid]);

  const history = useHistory();

  function futureYear(insights: any, string: any) {
    const years = ['2022', '2023', '2024', '2025', '2026'];
    let resultArray: any = [];

    insights.map((elem: any) => {
      for (let i = 0; i < years.length; i++) {
        if (elem.includes(years[i])) {
          resultArray.push(`${string} (FY${years[i]})`);
          if (i !== years.length - 1) {
            resultArray.push(`${string} (FY${years[i + 1]})`);
          }
        }
        if (resultArray.length > 1) {
          return resultArray;
        }
      }
    });

    return resultArray;
  }

  function insightHighlight(value: any, insights: any) {
    let keyWords = [
      'overvalued',
      'undervalued',
      'overpriced',
      'underpriced',
      'grow',
      'increased',
      'decreased',
      'below',
      'low',
      'decline',
    ];

    let years = ['2022', '2023', '2024', '2025', '2026'];
    let result = value + ':';

    let notBroken = true; // check in one insight only
    let sawKey = false; // saw a keyword
    let fiveYear = false; // for dividend vs 5Y
    let chooseSecond = false; // choose second value not the first one from insight
    let override = false; // override for future growth
    let sawValue = false; // saw a value

    let valueSplit = [];
    let year = '2022';

    if (value !== undefined) {
      valueSplit = value.split(' ');
      if (value.includes('Target Price')) {
        let currentPrice =
          valuationData.symbol && valuationData.symbol.currentPrice;
        let targetPrice =
          valuationData.symbol && valuationData.symbol.targetPrice;

        let overvaluedUndervalued = Math.abs(
          (parseFloat(targetPrice) / parseFloat(currentPrice) - 1) * 100
        ).toFixed(2);

        if (targetPrice < currentPrice) {
          return `${result} overvalued ${overvaluedUndervalued}%`;
        } else {
          return `${result} undervalued ${overvaluedUndervalued}%`;
        }
      }

      if (value.includes('Fair Value')) {
        let currentPrice =
          valuationData.symbol && valuationData.symbol.currentPrice;
        let fairPrice = valuationData.symbol && valuationData.symbol.fairPrice;

        if (fairPrice && currentPrice) {
          let overvaluedUndervalued = Math.abs(
            (parseFloat(fairPrice) / parseFloat(currentPrice) - 1) * 100
          ).toFixed(2);

          if (fairPrice < currentPrice) {
            return `${result} overvalued ${overvaluedUndervalued}%`;
          } else {
            return `${result} undervalued ${overvaluedUndervalued}%`;
          }
        }
      }

      if (insights.length === 0) {
        return value + ': No Data Available';
      }

      if (
        valueSplit[valueSplit.length - 1] !== undefined &&
        valueSplit[valueSplit.length - 1].includes('FY')
      ) {
        year = valueSplit[valueSplit.length - 1]
          .replace('(FY', '')
          .replace(')', '');
      }

      if (
        value === 'P/E' ||
        value === 'PEG' ||
        value === 'P/B' ||
        value === 'P/S'
      ) {
        result = value + ' ' + 'vs' + ' ' + 'Sector:';
      }

      if (years.includes(year)) {
        override = true;
      }
      if (value === 'Dividend Yield') {
        result = 'Dividend Yield vs Sector:';
      }
      if (value === 'Dividend Per Share') {
        result = 'Dividend Growth:';
      }

      if (
        value === 'Debt/EBITDA' ||
        value === 'Debt/Equity' ||
        value === 'Net Income Payout'
      ) {
        chooseSecond = true;
      }
      if (value === 'FCF') {
        result = 'Free Cash Flow Payout:';
        chooseSecond = true;
      }
      if (value === 'Total Shareholder Return') {
        result = 'Total Shareholder Payout';
        chooseSecond = true;
      }

      if (value.includes('5Y')) {
        fiveYear = true;
      }

      if (insights !== undefined) {
        insights.map((elem: any) => {
          if (
            (value !== 'Fair Value' &&
              (elem.includes(value.toLowerCase()) || elem.includes(value)) &&
              notBroken) ||
            (override && elem.includes(year)) ||
            (fiveYear && elem.includes('five year'))
          ) {
            let splitArray = elem.split(' ');

            for (let i = 0; i < splitArray.length; i++) {
              if (
                keyWords.includes(
                  splitArray[i]
                    .replace('<positive>', '')
                    .replace('</positive>', '')
                    .replace('<negative>', '')
                    .replace('</negative>', '')
                    .toLowerCase()
                )
              ) {
                sawKey = true;
                result +=
                  ' ' +
                  splitArray[i]
                    .replace('<positive>', '')
                    .replace('</positive>', '')
                    .replace('<negative>', '')
                    .replace('</negative>', '');
              }
              if (splitArray[i].includes('%')) {
                if (!chooseSecond && !sawValue) {
                  let value = splitArray[i]
                    .replace('<positive>', '')
                    .replace('</positive>', '')
                    .replace('<negative>', '')
                    .replace('</negative>', '')
                    .replace('(', '')
                    .replace(')', '')
                    .replace('-', '');
                  result += ' ' + parseFloat(value).toFixed(2) + '%';
                  sawValue = true;
                  notBroken = false;

                  if (sawKey) {
                    break;
                  }
                } else {
                  chooseSecond = false;
                }
              }
              if (
                splitArray[i].includes('(') &&
                splitArray[i].includes(')') &&
                sawKey &&
                !sawValue
              ) {
                let newString = splitArray[i];

                newString = newString.replace('(', '').replace(')', '');

                result += ' ' + newString + 'x';
                sawValue = true;
                notBroken = false;
                break;
              }

              if (splitArray[i].charAt(splitArray[i].length - 1) === 'x') {
                if (!chooseSecond && !sawValue) {
                  result += ' ' + splitArray[i];
                  sawValue = true;
                } else {
                  chooseSecond = false;
                }
              }
            }
          }
        });
      }
      if (result === value + ':') {
        return `${result} No Data Available`;
      }
    }
    return result;
  }

  function callback(section: any) {
    var element = document.getElementById(`${section}`);
    if (element !== null) {
      var headerOffset = isMobile ? 200 : 100;
      var elementPosition = element!.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      history.push(`#${section}`);

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      gtag('event', `${section}-company-tag-click`, {
        event_category: section,
        event_label: params.sid,
      });
    }
  }

  const vSummary = useMemo(
    () => (
      <ValuationSummary
        data={valuationData}
        insights={valuationInsights.summary}
        darkMode={darkMode}
      />
    ),
    [valuationData, valuationInsights, darkMode]
  );

  const singleTicker = useMemo(
    () => (
      <div style={{ height: isMobile ? 100 : 185, overflow: 'scroll' }}>
        {isMobile ? (
          <SingleTicker
            widgetPropsAny={{
              symbol: `${params.sid}`,
              isTransparent: true,
              colorTheme: darkMode ? 'dark' : 'light',
              autoSize: true,
              width: window.innerWidth,
            }}
          />
        ) : (
          <SymbolInfo
            widgetPropsAny={{
              symbol: `${params.sid}`,
              isTransparent: true,
              width: isMobile ? 'auto' : 250,

              colorTheme: darkMode ? 'dark' : 'light',
            }}
          />
        )}

        {!isMobile && (
          <Flex flexDirection="column" paddingLeft={5}>
            <Flex fontWeight="bold" fontSize="2xl" alignItems="center">
              <img src={VerdeScoreSvg} alt="" className="w-4 h-4 mb-0.5 mr-1" />
              <Text
                color={darkMode ? '#D1D4DB' : 'black'}
                fontSize={isMobile ? 'xl' : '2xl'}
              >
                {' '}
                {valuationData.symbol && valuationData.symbol.verdeScore}
              </Text>
            </Flex>
            <Flex alignItems="center" gap="1">
              <Text
                fontWeight="bold"
                color={darkMode ? '#D1D4DB' : 'black'}
                fontSize={isMobile ? '11px' : '15px'}
                marginRight={1}
              >
                Verde Score
              </Text>
              <Box>
                <InfoOutlineIcon
                  color={darkMode ? '#58A8F2' : '#0072db'}
                  height="15px"
                  width="15px"
                ></InfoOutlineIcon>
              </Box>
            </Flex>
          </Flex>
        )}
      </div>
    ),
    [darkMode, valuationData]
  );

  const vHighlights = useMemo(() => {
    const peAveragePositives = valuationInsights?.pe_ratio?.Average?.positives
      ? valuationInsights?.pe_ratio?.Average?.positives
      : [];
    const peIndustryPositives = valuationInsights?.pe_ratio?.Industry?.positives
      ? valuationInsights?.pe_ratio?.Industry?.positives
      : [];
    const peMarketPositives = valuationInsights?.pe_ratio?.Market?.positives
      ? valuationInsights?.pe_ratio?.Market?.positives
      : [];
    const peAverageNegatives = valuationInsights?.pe_ratio?.Average?.negatives
      ? valuationInsights?.pe_ratio?.Average?.negatives
      : [];
    const peIndustryNegatives = valuationInsights?.pe_ratio?.Industry?.negatives
      ? valuationInsights?.pe_ratio?.Industry?.negatives
      : [];
    const peMarketNegatives = valuationInsights?.pe_ratio?.Market?.negatives
      ? valuationInsights?.pe_ratio?.Market?.negatives
      : [];

    const pegAveragePositives = valuationInsights?.peg_ratio?.Average?.positives
      ? valuationInsights?.peg_ratio?.Average?.positives
      : [];
    const pegIndustryPositives = valuationInsights?.peg_ratio?.Industry
      ?.positives
      ? valuationInsights?.peg_ratio?.Industry?.positives
      : [];
    const pegMarketPositives = valuationInsights?.peg_ratio?.Market?.positives
      ? valuationInsights?.peg_ratio?.Market?.positives
      : [];
    const pegAverageNegatives = valuationInsights?.peg_ratio?.Average?.negatives
      ? valuationInsights?.peg_ratio?.Average?.negatives
      : [];
    const pegIndustryNegatives = valuationInsights?.peg_ratio?.Industry
      ?.negatives
      ? valuationInsights?.peg_ratio?.Industry?.negatives
      : [];
    const pegMarketNegatives = valuationInsights?.peg_ratio?.Market?.negatives
      ? valuationInsights?.peg_ratio?.Market?.negatives
      : [];

    const pbAveragePositives = valuationInsights?.pb_ratio?.Average?.positives
      ? valuationInsights?.pb_ratio?.Average?.positives
      : [];
    const pbIndustryPositives = valuationInsights?.pb_ratio?.Industry?.positives
      ? valuationInsights?.pb_ratio?.Industry?.positives
      : [];
    const pbMarketPositives = valuationInsights?.pb_ratio?.Market?.positives
      ? valuationInsights?.pb_ratio?.Market?.positives
      : [];
    const pbAverageNegatives = valuationInsights?.pb_ratio?.Average?.negatives
      ? valuationInsights?.pb_ratio?.Average?.negatives
      : [];
    const pbIndustryNegatives = valuationInsights?.pb_ratio?.Industry?.negatives
      ? valuationInsights?.pb_ratio?.Industry?.negatives
      : [];
    const pbMarketNegatives = valuationInsights?.pb_ratio?.Market?.negatives
      ? valuationInsights?.pb_ratio?.Market?.negatives
      : [];

    const psAveragePositives = valuationInsights?.ps_ratio?.Average?.positives
      ? valuationInsights?.ps_ratio?.Average?.positives
      : [];
    const psIndustryPositives = valuationInsights?.ps_ratio?.Industry?.positives
      ? valuationInsights?.ps_ratio?.Industry?.positives
      : [];
    const psMarketPositives = valuationInsights?.ps_ratio?.Market?.positives
      ? valuationInsights?.ps_ratio?.Market?.positives
      : [];
    const psAverageNegatives = valuationInsights?.ps_ratio?.Average?.negatives
      ? valuationInsights?.ps_ratio?.Average?.negatives
      : [];
    const psIndustryNegatives = valuationInsights?.ps_ratio?.Industry?.negatives
      ? valuationInsights?.ps_ratio?.Industry?.negatives
      : [];
    const psMarketNegatives = valuationInsights?.ps_ratio?.Market?.negatives
      ? valuationInsights?.ps_ratio?.Market?.negatives
      : [];

    const summaryPositives = valuationInsights?.summary?.positives
      ? valuationInsights?.summary?.positives
      : [];
    const summaryNegatives = valuationInsights?.summary?.negatives
      ? valuationInsights?.summary?.negatives
      : [];

    return (
      <Highlights
        darkMode={darkMode}
        title="Valuation Highlights"
        keys={[
          valuationInsights.summary
            ? insightHighlight('Fair Value', [
                ...summaryPositives,
                ...summaryNegatives,
              ])
            : 'Fair Value: No Data Available',
          valuationInsights.summary
            ? insightHighlight('Target Price', [
                ...summaryPositives,
                ...summaryNegatives,
              ])
            : insightHighlight('Target Price', []),
          valuationInsights.pe_ratio
            ? insightHighlight('P/E', [
                ...peIndustryPositives,
                ...peIndustryNegatives,
              ])
            : 'P/E: No Data Available',
          valuationInsights.peg_ratio
            ? insightHighlight('PEG', [
                ...pegIndustryPositives,
                ...pegIndustryNegatives,
              ])
            : 'PEG: No Data Available',
          valuationInsights.pb_ratio
            ? insightHighlight('P/B', [
                ...pbIndustryPositives,
                ...pbIndustryNegatives,
              ])
            : 'P/B: No Data Available',
          valuationInsights.ps_ratio
            ? insightHighlight('P/S', [
                ...psIndustryPositives,
                ...psIndustryNegatives,
              ])
            : 'P/S: No Data Available',
        ]}
        callback={callback}
        scroll={['Fair Value', 'Fair Value', 'PE', 'PEG', 'PB', 'PS']}
      />
    );
  }, [valuationData, valuationInsights, darkMode]);

  const fairValue = useMemo(
    () => <CurrentPrice data={valuationData} darkMode={darkMode} />,
    [valuationData, darkMode]
  );
  const analystRatings = useMemo(
    () => (
      <AnalystRatings
        company={params.sid}
        data={valuationData}
        darkMode={darkMode}
      />
    ),
    [darkMode]
  );
  const priceGrowth = useMemo(
    () => <PriceGrowth data={valuationData} darkMode={darkMode} />,
    [valuationData, darkMode]
  );
  const stockInfo = useMemo(
    () =>
      stockInfoData.length > 0 &&
      valuationData.symbol && (
        <StockInformation
          data={stockInfoData}
          valuationData={valuationData}
          darkMode={darkMode}
        />
      ),
    [stockInfoData, darkMode, valuationData]
  );

  const performance = useMemo(
    () => (
      <Performance
        darkMode={darkMode}
        symbol={valuationData.symbol}
        valuationData={valuationData}
      />
    ),
    [darkMode, valuationData]
  );
  const priceToEanings = useMemo(
    () => (
      <PriceToEarnings
        data={valuationData.priceToEarnings}
        symbol={valuationData.symbol}
        insights={valuationInsights}
        darkMode={darkMode}
        etfData={undefined}
        spyData={undefined}
      />
    ),
    [valuationData.symbol, darkMode, valuationInsights]
  );
  const priceToEarningsGrowth = useMemo(
    () => (
      <PriceToEarningsGrowth
        data={valuationData.priceToEarningsGrowth}
        symbol={valuationData.symbol}
        insights={valuationInsights}
        darkMode={darkMode}
        etfData={undefined}
        spyData={undefined}
      />
    ),
    [valuationData, darkMode, valuationInsights]
  );
  const priceToBook = useMemo(
    () => (
      <PriceToBook
        data={valuationData.priceToBook}
        symbol={valuationData.symbol}
        insights={valuationInsights}
        darkMode={darkMode}
        etfData={undefined}
        spyData={undefined}
      />
    ),
    [valuationData, darkMode, valuationInsights]
  );
  const priceToSales = useMemo(
    () => (
      <PriceToSales
        data={valuationData.priceToSales}
        symbol={valuationData.symbol}
        insights={valuationInsights}
        darkMode={darkMode}
        etfData={undefined}
        spyData={undefined}
      />
    ),
    [valuationData, darkMode, valuationInsights]
  );
  const marketCapMultiples = useMemo(
    () =>
      pastPerformanceData && (
        <MarketCapMultiples
          data={pastPerformanceData}
          insights={valuationInsights}
          period={period}
          showOnlyGraph={false}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [pastPerformanceData, period, darkMode]
  );
  const enterpriseMultiples = useMemo(
    () =>
      pastPerformanceData && (
        <EnterpriseValueMultiples
          data={pastPerformanceData}
          insights={valuationInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [pastPerformanceData, period, darkMode]
  );

  const financialStatementHighlights = useMemo(() => {
    const incomeStatementPositives = pastPerformanceInsights?.income_statement
      ?.positives
      ? pastPerformanceInsights?.income_statement?.positives
      : [];
    const incomeStatementNegatives = pastPerformanceInsights?.income_statement
      ?.negatives
      ? pastPerformanceInsights?.income_statement?.negatives
      : [];
    const cashFlowStatementPositives = pastPerformanceInsights
      ?.cash_flow_statement?.positives
      ? pastPerformanceInsights?.cash_flow_statement?.positives
      : [];
    const cashFlowStatementNegatives = pastPerformanceInsights
      ?.cash_flow_statement?.negatives
      ? pastPerformanceInsights?.cash_flow_statement?.negatives
      : [];
    const balanceSheetPositives = pastPerformanceInsights?.balance_sheet
      ?.positives
      ? pastPerformanceInsights?.balance_sheet?.positives
      : [];
    const balanceSheetNegatives = pastPerformanceInsights?.balance_sheet
      ?.negatives
      ? pastPerformanceInsights?.balance_sheet?.negatives
      : [];

    return (
      <Highlights
        darkMode={darkMode}
        title="Financial Statement Highlights"
        keys={[
          pastPerformanceInsights.income_statement
            ? insightHighlight('Revenue', [
                ...incomeStatementPositives,
                ...incomeStatementNegatives,
              ])
            : 'Revenue: No Data Available',
          pastPerformanceInsights.income_statement
            ? insightHighlight('EBIT', [
                ...incomeStatementPositives,
                ...incomeStatementNegatives,
              ])
            : 'EBIT: No Data Available',
          pastPerformanceInsights.income_statement
            ? insightHighlight('Net Income', [
                ...incomeStatementPositives,
                ...incomeStatementNegatives,
              ])
            : 'Net Income: No Data Available',
          pastPerformanceInsights.cash_flow_statement
            ? insightHighlight('Free Cash Flow', [
                ...cashFlowStatementPositives,
                ...cashFlowStatementNegatives,
              ])
            : 'Free Cash Flow: No Data Available',
          pastPerformanceInsights.balance_sheet
            ? insightHighlight('Total Debt', [
                ...balanceSheetPositives,
                ...balanceSheetNegatives,
              ])
            : 'Total Debt: No Data Available',
          pastPerformanceInsights.balance_sheet
            ? insightHighlight('Total Equity', [
                ...balanceSheetPositives,
                ...balanceSheetNegatives,
              ])
            : 'Total Equity: No Data Available',
        ]}
        callback={callback}
        scroll={[
          'Income Statement',
          'Income Statement',
          'Income Statement',
          'Cashflow Statement',
          'Balance Sheet',
          'Balance Sheet',
        ]}

        // values = {[parseFloat(currentPrice) < parseFloat(fairPrice) , parseFloat(currentPrice) < parseFloat(targetPrice) , parseFloat(PESymbol) < parseFloat(PESector)
        //   , parseFloat(PEGSymbol) < parseFloat(PEGSector) , parseFloat(PBSymbol) < parseFloat(PBSector) , parseFloat(PSSymbol) < parseFloat(PSSector) ]}
      />
    );
  }, [pastPerformanceData, pastPerformanceInsights, darkMode]);

  const incomeStatement = useMemo(
    () =>
      pastPerformanceData && (
        <IncomeStatement
          data={pastPerformanceData}
          insights={pastPerformanceInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [pastPerformanceData, period, setPeriod, pastPerformanceInsights, darkMode]
  );

  const balanceSheet = useMemo(
    () =>
      pastPerformanceData && (
        <BalanceSheet
          data={pastPerformanceData}
          insights={pastPerformanceInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [pastPerformanceData, period, setPeriod, pastPerformanceInsights, darkMode]
  );

  const cashFlow = useMemo(
    () =>
      pastPerformanceData && (
        <CashflowStatement
          data={pastPerformanceData}
          insights={pastPerformanceInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [pastPerformanceData, period, setPeriod, pastPerformanceInsights, darkMode]
  );

  const financialRatioHighlights = useMemo(() => {
    const efficienyRatiosPositives = pastPerformanceInsights?.efficiency_ratio
      ?.positives
      ? pastPerformanceInsights?.efficiency_ratio?.positives
      : [];
    const efficienyRatiosNegatives = pastPerformanceInsights?.efficiency_ratio
      ?.negatives
      ? pastPerformanceInsights?.efficiency_ratio?.negatives
      : [];
    const sharesOutstandingPositives = pastPerformanceInsights
      ?.shares_outstanding?.positives
      ? pastPerformanceInsights?.shares_outstanding?.positives
      : [];
    const sharesOutstandingNegatives = pastPerformanceInsights
      ?.shares_outstanding?.negatives
      ? pastPerformanceInsights?.shares_outstanding?.negatives
      : [];

    return (
      <Highlights
        darkMode={darkMode}
        title="Financial Ratio Highlights"
        keys={[
          pastPerformanceInsights.efficiency_ratio
            ? insightHighlight('Return on Assets', [
                ...efficienyRatiosPositives,
                ...efficienyRatiosNegatives,
              ])
            : 'Return On Assets: No Data Available',
          pastPerformanceInsights.efficiency_ratio
            ? insightHighlight('Return on Equity', [
                ...efficienyRatiosPositives,
                ...efficienyRatiosNegatives,
              ])
            : 'Return on Equity: No Data Available',
          pastPerformanceInsights.efficiency_ratio
            ? insightHighlight('Return on Invested Capital', [
                ...efficienyRatiosPositives,
                ...efficienyRatiosNegatives,
              ])
            : 'Return on Invested Capital: No Data Available',
          // pastPerformanceInsights.leverageRatio ?  insightHighlight('Debt/EBITDA' , [... pastPerformanceInsights.leverageRatio.positives , ...pastPerformanceInsights.leverageRatio.negatives]) : "Debt/EBITDA: No Data Available" ,
          'empty',
          'empty',
          // pastPerformanceInsights.leverageRatio ?  insightHighlight('Debt/Equity' , [...pastPerformanceInsights.leverageRatio.positives , ...pastPerformanceInsights.leverageRatio.negatives]) : "Debt/Equity: No Data Available" ,
          pastPerformanceInsights.shares_outstanding
            ? insightHighlight('Shares Outstanding', [
                ...sharesOutstandingPositives,
                ...sharesOutstandingNegatives,
              ])
            : 'Shares Outstanding: No Data Available',
        ]}
        callback={callback}
        scroll={[
          'Efficiency Ratios',
          'Efficiency Ratios',
          'Efficiency Ratios',
          'Leverage Ratios',
          'Leverage Ratios',
          'Shares Outstanding',
        ]}
      />
    );
  }, [pastPerformanceData, pastPerformanceInsights, darkMode]);

  const profitability = useMemo(
    () =>
      pastPerformanceData && (
        <ProfitabilityRatios
          data={pastPerformanceData}
          insights={pastPerformanceInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [pastPerformanceData, period, setPeriod, pastPerformanceInsights, darkMode]
  );
  const efficiency = useMemo(
    () =>
      pastPerformanceData && (
        <EfficiencyRatios
          data={pastPerformanceData}
          insights={pastPerformanceInsights}
          period={period}
          setPeriod={setPeriod}
        />
      ),
    [pastPerformanceData, period, setPeriod, pastPerformanceInsights]
  );
  const leverage = useMemo(
    () =>
      pastPerformanceData && (
        <LeverageRatios
          data={pastPerformanceData}
          insights={pastPerformanceInsights}
          period={period}
          setPeriod={setPeriod}
        />
      ),
    [pastPerformanceData, period, setPeriod, pastPerformanceInsights]
  );
  const sharesOutstanding = useMemo(
    () =>
      pastPerformanceData && (
        <SharesOutstanding
          data={pastPerformanceData}
          insights={pastPerformanceInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [pastPerformanceData, period, setPeriod, pastPerformanceInsights, darkMode]
  );

  const futureGrowthHighlights = useMemo(() => {
    let insights = futureGrowthInsights;

    const revenuePositives = insights?.revenue?.positives
      ? insights?.revenue?.positives
      : [];
    const revenueGrowthPositives = insights?.revenue_growth?.positives
      ? insights?.revenue_growth?.positives
      : [];
    const revenueNegatives = insights?.revenue?.negatives
      ? insights?.revenue?.negatives
      : [];
    const revenueGrowthNegatives = insights?.revenue_growth?.negatives
      ? insights?.revenue_growth?.negatives
      : [];

    const earningsPositives = insights?.earnings?.positives
      ? insights?.earnings?.positives
      : [];
    const earningsGrowthPositives = insights?.earnings_growth?.positives
      ? insights?.earnings_growth?.positives
      : [];
    const earningsNegatives = insights?.earnings?.negatives
      ? insights?.earnings?.negatives
      : [];
    const earningsGrowthNegatives = insights?.earnings_growth?.negatives
      ? insights?.earnings_growth?.negatives
      : [];

    const earningsPerSharePositives = insights?.eps?.positives
      ? insights?.eps?.positives
      : [];
    const epsGrowthPositives = insights?.eps_growth?.positives
      ? insights?.eps_growth?.positives
      : [];
    const earningsPerShareNegatives = insights?.eps?.negatives
      ? insights?.eps?.negatives
      : [];
    const epsGrowthNegatives = insights?.eps_growth?.negatives
      ? insights?.eps_growth?.negatives
      : [];

    let revenueArray = futureYear(
      [
        ...revenuePositives,
        ...revenueGrowthPositives,
        ...revenueNegatives,
        ...revenueGrowthNegatives,
      ],
      'Revenue'
    );
    let netIncomeArray = futureYear(
      [
        ...earningsPositives,
        ...earningsGrowthPositives,
        ...earningsNegatives,
        ...earningsGrowthNegatives,
      ],
      'Net Income'
    );
    let epsArray = futureYear(
      [
        ...earningsPerSharePositives,
        ...epsGrowthPositives,
        ...earningsPerShareNegatives,
        ...epsGrowthNegatives,
      ],
      'EPS'
    );

    return (
      <Highlights
        darkMode={darkMode}
        title="Future Growth Highlights"
        keys={[
          insightHighlight(revenueArray[0], [
            ...revenuePositives,
            ...revenueGrowthPositives,
            ...revenueNegatives,
            ...revenueGrowthNegatives,
          ]),
          insightHighlight(revenueArray[1], [
            ...revenuePositives,
            ...revenueGrowthPositives,
            ...revenueNegatives,
            ...revenueGrowthNegatives,
          ]),
          insightHighlight(netIncomeArray[0], [
            ...earningsPositives,
            ...earningsGrowthPositives,
            ...earningsNegatives,
            ...earningsGrowthNegatives,
          ]),
          insightHighlight(netIncomeArray[1], [
            ...earningsPositives,
            ...earningsGrowthPositives,
            ...earningsNegatives,
            ...earningsGrowthNegatives,
          ]),
          insightHighlight(epsArray[0], [
            ...earningsPerSharePositives,
            ...epsGrowthPositives,
            ...earningsPerShareNegatives,
            ...epsGrowthNegatives,
          ]),
          insightHighlight(epsArray[1], [
            ...earningsPerSharePositives,
            ...epsGrowthPositives,
            ...earningsPerShareNegatives,
            ...epsGrowthNegatives,
          ]),
        ]}
        callback={callback}
        scroll={[
          'Revenue Projections',
          'Revenue Projections',
          'Net Income Projections',
          'Net Income Projections',
          'EPS Projections',
          'EPS Projections',
        ]}
      />
    );
  }, [futureGrowthData, futureGrowthInsights, darkMode]);

  const earningsProjection = useMemo(
    () => (
      <EarningsProjection
        data={futureGrowthData}
        insights={futureGrowthInsights}
      />
    ),
    [futureGrowthData, futureGrowthInsights]
  );
  const revenueProjection = useMemo(
    () => (
      <RevenueProjection
        data={futureGrowthData}
        insights={futureGrowthInsights}
      />
    ),
    [futureGrowthData, futureGrowthInsights]
  );
  const projectedEarnings = useMemo(
    () => (
      <ProjectedEarnings
        data={futureGrowthData}
        insights={futureGrowthInsights}
      />
    ),
    [futureGrowthData, futureGrowthInsights]
  );

  const dividendHighlights = useMemo(() => {
    const averagePositives = dividendInsights?.dividend_yield?.Average
      ?.positives
      ? dividendInsights?.dividend_yield?.Average?.positives
      : [];
    const industryPositives = dividendInsights?.dividend_yield?.Industry
      ?.positives
      ? dividendInsights?.dividend_yield?.Industry?.positives
      : [];
    const marketPositives = dividendInsights?.dividend_yield?.Market?.positives
      ? dividendInsights?.dividend_yield?.Market?.positives
      : [];
    const averageNegatives = dividendInsights?.dividend_yield?.Average
      ?.negatives
      ? dividendInsights?.dividend_yield?.Average?.negatives
      : [];
    const industryNegatives = dividendInsights?.dividend_yield?.Industry
      ?.negatives
      ? dividendInsights?.dividend_yield?.Industry?.negatives
      : [];
    const marketNegatives = dividendInsights?.dividend_yield?.Market?.negatives
      ? dividendInsights?.dividend_yield?.Market?.negatives
      : [];

    const dividendPerSharePositives = dividendInsights?.dividend_per_share
      ?.positives
      ? dividendInsights?.dividend_per_share?.positives
      : [];
    const dividendPerShareNegatives = dividendInsights?.dividend_per_share
      ?.negatives
      ? dividendInsights?.dividend_per_share?.negatives
      : [];
    const netIncomePayoutPositives = dividendInsights?.net_income_payout
      ?.positives
      ? dividendInsights?.net_income_payout?.positives
      : [];
    const netIncomePayoutNegatives = dividendInsights?.net_income_payout
      ?.negatives
      ? dividendInsights?.net_income_payout?.negatives
      : [];
    const fcfPayoutPositives = dividendInsights?.fcf_payout?.positives
      ? dividendInsights?.fcf_payout?.positives
      : [];
    const fcfPayoutNegatives = dividendInsights?.fcf_payout?.negatives
      ? dividendInsights?.fcf_payout?.negatives
      : [];
    const totalShareholderReturnPositives = dividendInsights
      ?.total_shareholder_return?.positives
      ? dividendInsights?.total_shareholder_return?.positives
      : [];
    const totalShareholderReturnNegatives = dividendInsights
      ?.total_shareholder_return?.negatives
      ? dividendInsights?.total_shareholder_return?.negatives
      : [];

    return (
      <Highlights
        darkMode={darkMode}
        title="Dividend Highlights"
        keys={[
          insightHighlight('Dividend Yield', [
            ...averagePositives,
            ...industryPositives,
            ...marketPositives,
            ...averageNegatives,
            ...industryNegatives,
            ...marketNegatives,
          ]),
          insightHighlight('Dividend Yield vs 5Y Average', [
            ...averagePositives,
            ...industryPositives,
            ...marketPositives,
            ...averageNegatives,
            ...industryNegatives,
            ...marketNegatives,
          ]),
          dividendInsights?.dividend_per_share
            ? insightHighlight('Dividend Per Share', [
                ...dividendPerSharePositives,
                ...dividendPerShareNegatives,
              ])
            : 'Dividend Per Share: No Data Available',
          dividendInsights?.net_income_payout
            ? insightHighlight('Net Income Payout', [
                ...netIncomePayoutPositives,
                ...netIncomePayoutNegatives,
              ])
            : 'Net Income Payout: No Data Available',
          dividendInsights?.fcf_payout
            ? insightHighlight('FCF', [
                ...fcfPayoutPositives,
                ...fcfPayoutNegatives,
              ])
            : 'FCF: No Data Available',
          dividendInsights?.total_shareholder_return
            ? insightHighlight('Total Shareholder Return', [
                ...totalShareholderReturnPositives,
                ...totalShareholderReturnNegatives,
              ])
            : 'Total Shareholder Return: No Data Available',
        ]}
        callback={callback}
        scroll={[
          'Dividend Yield (TTM)',
          'Dividend Yield (TTM)',
          'Dividend History',
          'Net Income Payout Ratio',
          'FCF Payout Ratio',
          'Total Shareholder Payout',
        ]}
      />
    );
  }, [dividendInsights, darkMode]);

  const currentYield = useMemo(
    () =>
      dividendData && (
        <CurrentYield
          data={dividendData.current}
          insights={dividendInsights}
          darkMode={darkMode}
          etfData={undefined}
          spyData={undefined}
        />
      ),
    [dividendData, dividendInsights, darkMode]
  );
  const TTM = useMemo(
    () =>
      dividendData && (
        <TTMDividendYield
          data={dividendData.history}
          insights={dividendInsights}
        />
      ),
    [dividendData, dividendInsights, darkMode]
  );
  const yieldOnCost = useMemo(
    () =>
      dividendData && (
        <YieldOnCost
          data={dividendData.history}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [dividendData, dividendInsights, period, darkMode]
  );
  const dividendHistory = useMemo(
    () =>
      dividendData && (
        <DividendHistory
          data={dividendData.history}
          insights={dividendInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [dividendData, dividendInsights, period, darkMode]
  );
  const netIncome = useMemo(
    () =>
      dividendData && (
        <NetIncomePayoutRatio
          data={dividendData.history}
          insights={dividendInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [dividendData, dividendInsights, period, darkMode]
  );
  const fcf = useMemo(
    () =>
      dividendData && (
        <FreeCashFlowPayoutRatio
          data={dividendData.history}
          insights={dividendInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [dividendData, dividendInsights, darkMode]
  );
  const totalShareholder = useMemo(
    () =>
      dividendData && (
        <TotalShareholderReturn
          data={dividendData.history}
          insights={dividendInsights}
          period={period}
          setPeriod={setPeriod}
          darkMode={darkMode}
        />
      ),
    [dividendData, dividendInsights, darkMode]
  );

  const insidersTransactions = useMemo(
    () =>
      insidersData.insiders && (
        <InsiderTransactions
          data={insidersData.insiders}
          insights={ownershipInsights}
          darkMode={darkMode}
        />
      ),
    [insidersData, darkMode, ownershipInsights]
  );
  const institutionsHolders = useMemo(
    () =>
      valuationData.symbol && (
        <InstitutionalShareholders
          data={insidersData?.institutionsHolders}
          symbol={valuationData?.symbol}
          darkMode={darkMode}
        />
      ),
    [insidersData.institutionsHolders, valuationData, darkMode]
  );
  const fundsHolders = useMemo(
    () =>
      valuationData.symbol && (
        <FundsShareholders
          data={insidersData.fundsHolders}
          symbol={valuationData?.symbol}
          darkMode={darkMode}
        />
      ),
    [insidersData.fundsHolders, valuationData, darkMode]
  );

  const similarCompanies = useMemo(() => <SimilarCompaniesRevamp />, []);

  const [overviewRef, overviewInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('overview', inView, params.sid),
  });

  const [stockIntroRef, stockIntroInView] = useInView({
    threshold: 0.99,
    onChange: (inView) => {
      inViewChange('stock description', inView, params.sid);
    },
  });

  const [stockSummaryRef, stockSummaryInView] = useInView({
    threshold: 0.99,
    onChange: (inView) => {
      inViewChange('stock summary', inView, params.sid);
    },
  });

  const [valuationRef, valuationInView] = useInView({
    threshold: isMobile ? 0.09 : 0.07,
    onChange: (inView) => inViewChange('valuation', inView, params.sid),
  });

  const [vHighlightsRef, vHighlightsInView] = useInView({
    threshold: 0.45,
    onChange: (inView) =>
      inViewChange('valuation-highlights', inView, params.sid),
  });

  const [vSummaryRef, vSummaryInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('valuation-summary', inView, params.sid),
  });
  const [analystRef, analystInView] = useInView({
    threshold: 0.65,
    onChange: (inView) => inViewChange('analyst-ratings', inView, params.sid),
  });
  const [fairRef, fairInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('fair-value', inView, params.sid),
  });
  const [priceGrowthRef, priceGrowthInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('price-growth', inView, params.sid),
  });
  const [stockInfoRef, stockInfoInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('stock-info', inView, params.sid),
  });
  const [ratiosRef, ratioInView] = useInView({
    threshold: 0.09,
    onChange: (inView) => inViewChange('ratios', inView, params.sid),
  });

  const [peRef, peInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('fair-value', inView, params.sid),
  });

  const [pegRef, pegInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('fair-value', inView, params.sid),
  });

  const [pbRef, pbInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('fair-value', inView, params.sid),
  });

  const [psRef, psInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('fair-value', inView, params.sid),
  });

  // const [valuationMultiplesRef, valuationMultiplesInView] = useInView({
  //   threshold: 0.09,
  //   onChange: (inView) =>
  //     inViewChange('valuation-multiples', inView, params.sid),
  // });

  const [marketCapRef, marketCapInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('market-cap', inView, params.sid),
  });

  const [enterpriseCapRef, enterpriseCapInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('stock-info', inView, params.sid),
  });

  const [pastRef, pastInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('past-performance', inView, params.sid),
  });

  const [
    financialStatementsHighlightsRef,
    financialStatementsHighlightsInView,
  ] = useInView({
    threshold: 0.05,
    onChange: (inView) =>
      inViewChange('financial-statements-highlights', inView, params.sid),
  });

  const [incomeStatementRef, incomeStatementInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('income-statement', inView, params.sid),
  });
  const [BalanceSheetRef, balanceSheetInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('balance-sheet', inView, params.sid),
  });
  const [cashFlowRef, cashFlowInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('cash-flow', inView, params.sid),
  });

  const [pastRatioRef, pastRatioInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('past-performance', inView, params.sid),
  });
  const [
    financialRatioHighlightsRef,
    financialRatioHighlightsInView,
  ] = useInView({
    threshold: 0.05,
    onChange: (inView) =>
      inViewChange('financial-ratio-highlights', inView, params.sid),
  });

  const [profitabilityRef, profibalityInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('profitability', inView, params.sid),
  });
  const [efficienyRef, efficiencyInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('efficiency', inView, params.sid),
  });
  const [leverageRef, leverageInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('leverage', inView, params.sid),
  });
  const [sharesOutRef, sharesOutInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('shares-out', inView, params.sid),
  });

  const [futureRef, futureInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('future-growth', inView, params.sid),
  });

  const [futureHighilightsRef, futureHighilightsInView] = useInView({
    threshold: 0.45,
    onChange: (inView) =>
      inViewChange('future-growth-highlights', inView, params.sid),
  });
  const [earningsProjRef, earningsProjInView] = useInView({
    threshold: 0.45,
    onChange: (inView) =>
      inViewChange('earnings-projection', inView, params.sid),
  });
  const [revenueProjRef, revenueProjInView] = useInView({
    threshold: 0.45,
    onChange: (inView) =>
      inViewChange('revenue-projection', inView, params.sid),
  });
  const [projEarningsRef, projEarningsInView] = useInView({
    threshold: 0.45,
    onChange: (inView) =>
      inViewChange('projected-earnings', inView, params.sid),
  });

  const [dividendRef, dividendInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('dividend', inView, params.sid),
  });

  const [dividendHighlightsRef, dividendHighlightsInView] = useInView({
    threshold: 0.45,
    onChange: (inView) =>
      inViewChange('dividend-Highlights', inView, params.sid),
  });
  const [currentYieldRef, currentYieldInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('current-yield', inView, params.sid),
  });
  const [ttmDivRef, ttmDivInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('ttm', inView, params.sid),
  });
  const [yieldOnCostRef, yieldOnCostInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('yield-on-cost', inView, params.sid),
  });
  const [divHistRef, divHistInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('dividend-history', inView, params.sid),
  });
  const [netIncomeRef, netIncomeInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('net-income', inView, params.sid),
  });
  const [fcfRef, fcfInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('fc', inView, params.sid),
  });
  const [totalShareRef, totalShareInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('total-share', inView, params.sid),
  });

  const [ownershipRef, ownershipInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('ownership', inView, params.sid),
  });
  const [insidersRef, insidersInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('insiders', inView, params.sid),
  });
  const [instiutionalRef, institutionalInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('institutional', inView, params.sid),
  });
  const [fundsRef, fundsInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('funds', inView, params.sid),
  });

  const [otherRef, otherInView] = useInView({
    threshold: 0.45,
    onChange: (inView) => inViewChange('others', inView, params.sid),
  });

  useEffect(() => {}, []);

  const dupTabs = [
    {
      name: 'Overview',
      selected:
        valuationInView ||
        pastInView ||
        pastRatioInView ||
        futureInView ||
        dividendInView ||
        ownershipInView ||
        otherInView
          ? false
          : true,
      exists: valuationInView ? false : overviewInView,
      tabs: [
        {
          name: 'Stock Description',
          selected: stockSummaryInView ? false : stockIntroInView,
        },
        {
          name: 'Stock Summary',
          selected: vSummaryInView ? false : stockSummaryInView,
        },
        {
          name: 'Stock Highlights',
          selected: priceGrowthInView ? false : vSummaryInView,
        },

        {
          name: 'Performance Table',
          selected: stockInfoInView ? false : priceGrowthInView,
        },
        {
          name: 'Performance Chart',
          selected: stockInfoInView,
        },
      ],
    },
    {
      name: 'Valuation',
      selected: pastInView ? false : valuationInView,
      exists: true,
      tabs: [
        {
          name: 'Valuation Highlights',
          selected: fairInView ? false : vHighlightsInView,
        },
        {
          name: 'Fair Value',
          selected: analystInView ? false : fairInView,
        },

        {
          name: 'Analyst Ratings',
          selected: ratioInView ? false : analystInView,
        },

        {
          name: 'Key Valuation Multiples',
          selected: marketCapInView ? false : ratioInView,
        },
        {
          name: 'Market Cap Multiples',
          selected: enterpriseCapInView ? false : marketCapInView,
        },
        {
          name: 'Enterprise Cap Multiples',
          selected: enterpriseCapInView,
        },
      ],
    },
    {
      name: 'Financial Statements',
      selected: pastRatioInView ? false : pastInView,
      exists: true,
      tabs: [
        {
          name: 'Financial Statement Highlights',
          selected: incomeStatementInView
            ? false
            : financialStatementsHighlightsInView,
        },
        {
          name: 'Income Statement',
          selected: balanceSheetInView ? false : incomeStatementInView,
        },
        {
          name: 'Balance Sheet',
          selected: cashFlowInView ? false : balanceSheetInView,
        },
        {
          name: 'Cashflow Statement',
          selected: profibalityInView ? false : cashFlowInView,
        },
      ],
    },
    {
      name: 'Financial Ratios',
      selected: futureInView ? false : pastRatioInView,
      exists: true,
      tabs: [
        {
          name: 'Financial Ratio Highlights',
          selected: profibalityInView ? false : financialRatioHighlightsInView,
        },
        {
          name: 'Profitability Ratios',
          selected: efficiencyInView ? false : profibalityInView,
        },
        {
          name: 'Efficiency Ratios',
          selected: leverageInView ? false : efficiencyInView,
        },
        {
          name: 'Leverage Ratios',
          selected: sharesOutInView ? false : leverageInView,
        },
        {
          name: 'Shares Outstanding',
          selected: sharesOutInView,
        },
      ],
    },
    {
      name: 'Future Growth',
      selected: dividendInView ? false : futureInView,
      exists: true,
      tabs: [
        {
          name: 'Future Growth Highlights',
          selected: revenueProjInView ? false : futureHighilightsInView,
        },
        {
          name: 'Revenue Projections',
          selected: earningsProjInView ? false : revenueProjInView,
        },
        {
          name: 'EPS Projections',
          selected: projEarningsInView ? false : earningsProjInView,
        },

        {
          name: 'Net Income Projections',
          selected: projEarningsInView,
        },
      ],
    },
    {
      name: 'Dividend',
      selected: ownershipInView ? false : dividendInView,
      exists: dividendData
        ? !(
            !dividendData.current.yieldPercent &&
            Number(dividendData.current.yieldPercent) == 0
          )
        : true,
      tabs: [
        {
          name: 'Dividend Highlights',
          selected: currentYieldInView ? false : dividendHighlightsInView,
        },
        {
          name: 'Dividend Yield (FWD)',
          selected: ttmDivInView ? false : currentYieldInView,
        },
        {
          name: 'Dividend Yield (TTM)',
          selected: yieldOnCostInView ? false : ttmDivInView,
        },
        {
          name: 'Yield On Cost',
          selected: divHistInView ? false : yieldOnCostInView,
        },
        {
          name: 'Dividend History',
          selected: netIncomeInView ? false : divHistInView,
        },
        {
          name: 'Net Income Payout Ratio',
          selected: fcfInView ? false : netIncomeInView,
        },
        {
          name: 'FCF Payout Ratio',
          selected: totalShareInView ? false : fcfInView,
        },
        {
          name: 'Total Shareholder Payout',
          selected: totalShareInView,
        },
      ],
    },
    {
      name: 'Ownership',
      selected: otherInView ? false : ownershipInView,
      exists: true,
      tabs: [
        {
          name: 'Insiders Transaction',
          selected: institutionalInView ? false : insidersInView,
        },
        {
          name: 'Top Institutional Shareholders',
          selected: fundsInView ? false : institutionalInView,
        },
        {
          name: 'Top Funds Shareholders',
          selected: fundsInView,
        },
      ],
    },
    { name: 'Other Information', selected: otherInView },
    //  { name: 'Discussion' },
  ];

  return (
    <>
      {!subscriptionStatus?.active && !publicSymbols.includes(params.sid) ? (
        <PremiumRestrictionComponent />
      ) : !notExists ? (
        <Flex bg={darkMode ? '#161616' : '#F4F4F4'} minH="90vh" mt="60px">
          <Container maxW="container.xl">
            <Grid
              templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(4, 1fr)'}
              gap={isMobile ? 3 : 4}
              mt="5"
            >
              <GridItem rowSpan={1} colSpan={1}>
                <Box
                  as="ul"
                  position={isMobile ? 'fixed' : 'sticky'}
                  top={isMobile ? '64px' : '100px'}
                  right={isMobile ? '0.1' : ''}
                  marginBottom={isMobile ? '10px' : ''}
                  backgroundColor={
                    isMobile
                      ? !darkMode
                        ? '#F4F4F4'
                        : '#161616'
                      : 'transparent'
                  }
                  zIndex={9}
                  width="100%"
                >
                  {!stockIntroInView && valuationData.symbol && (
                    <Flex
                      bg={
                        darkMode ? (isMobile ? '#262626' : '#262626') : 'white'
                      }
                      p="3"
                      flexDirection={isMobile ? 'row' : 'column'}
                      borderRadius="5px"
                      marginBottom="20px"
                      paddingBottom="1"
                    >
                      <Flex>
                        <Flex>
                          <Box>
                            <SymbolLogo
                              logoUrl={`https://images.verde.finance/${valuationData.symbol.id}.png`}
                            />
                          </Box>
                          <Flex
                            flexDirection="column"
                            ml={isMobile ? '2' : '2'}
                          >
                            <Text
                              fontWeight="bold"
                              color={darkMode ? 'white' : 'black'}
                            >
                              {valuationData.symbol.id}
                            </Text>
                            <Text
                              fontSize="small"
                              color={darkMode ? 'white' : 'black'}
                            >
                              {valuationData.symbol.name}
                            </Text>
                          </Flex>
                        </Flex>
                        {!isMobile && (
                          <img
                            src={
                              darkMode ? bookMarkLogoWhite : bookMarkLogoDark
                            }
                            style={{
                              marginLeft: 'auto',
                              width: '35px',
                              height: '35px',
                            }}
                          />
                        )}
                      </Flex>

                      <Flex
                        my={isMobile ? '0' : '4'}
                        gap="3"
                        marginLeft={isMobile ? 'auto' : '0'}
                        marginRight={isMobile ? 'auto' : '0'}
                      >
                        <Flex flexDirection="column" marginRight="1">
                          <Text
                            fontWeight="bold"
                            fontSize={isMobile ? 'l' : '2xl'}
                            color={darkMode ? 'white' : 'black'}
                          >
                            ${valuationData.symbol.currentPrice}
                          </Text>

                          <Flex alignItems="center">
                            {valuationData.symbol.relativePriceChange > 0 ? (
                              <>
                                <TriangleDownIcon
                                  color={darkMode ? '#8acf55' : '#569c30'}
                                ></TriangleDownIcon>
                                <Text
                                  color={darkMode ? '#8acf55' : '#569c30'}
                                  fontSize={isMobile ? '10px' : '15px'}
                                >
                                  {valuationData.symbol.relativePriceChange}% (
                                  {valuationData.symbol.absolutePriceChange})
                                </Text>
                              </>
                            ) : (
                              <>
                                <TriangleDownIcon
                                  color={darkMode ? '#ec5b55' : '#be2e2c'}
                                ></TriangleDownIcon>
                                <Text
                                  color={darkMode ? '#ec5b55' : '#be2e2c'}
                                  fontSize={isMobile ? '10px' : '15px'}
                                >
                                  {valuationData.symbol.relativePriceChange}% (
                                  {valuationData.symbol.absolutePriceChange})
                                </Text>
                              </>
                            )}
                            {/* <TriangleDownIcon color="#be2e2c"></TriangleDownIcon>
                        <Text
                          color={darkMode ? '#EC5B55' : '#be2e2c'}
                          fontSize={isMobile ? '11px' : '15px'}
                        >
                          0.14% (-$5.30)
                        </Text> */}
                          </Flex>
                        </Flex>

                        <Flex flexDirection="column">
                          <Flex
                            fontWeight="bold"
                            fontSize={isMobile ? 'l' : '2xl'}
                            alignItems="center"
                          >
                            <img
                              src={VerdeScoreSvg}
                              alt=""
                              className="w-4 h-4 mb-0.5 mr-1"
                            />
                            <Text
                              color={darkMode ? 'white' : 'black'}
                              fontSize={isMobile ? 'l' : '2xl'}
                            >
                              {' '}
                              {valuationData.symbol.verdeScore}
                            </Text>
                          </Flex>
                          <Flex alignItems="center">
                            <Text
                              fontWeight="bold"
                              color={darkMode ? 'white' : 'black'}
                              fontSize={isMobile ? '10px' : '15px'}
                              marginRight={2}
                            >
                              Verde Score
                            </Text>
                            <Box>
                              <InfoOutlineIcon
                                color={darkMode ? '#58A8F2' : '#0072db'}
                                height={isMobile ? '13px' : '15px'}
                                width={isMobile ? '13px' : '15px'}
                              ></InfoOutlineIcon>
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>

                      {isMobile && (
                        <img
                          src={darkMode ? bookMarkLogoWhite : bookMarkLogoDark}
                          style={{
                            marginLeft: 'auto',
                            width: '35px',
                            height: '35px',
                          }}
                        />
                      )}

                      {/* {singleTicker} */}
                    </Flex>
                  )}

                  <Box
                    as="ul"
                    top={isMobile ? '64px' : '100px'}
                    display={isMobile ? 'flex' : 'block'}
                    marginBottom={isMobile ? '10px' : ''}
                    backgroundColor={
                      isMobile
                        ? !darkMode
                          ? '#F4F4F4'
                          : '#161616'
                        : 'transparent'
                    }
                    zIndex={9}
                    width="100%"
                    overflowX={isMobile ? 'scroll' : 'hidden'}
                    overflowY={isMobile ? 'auto' : 'scroll'}
                    height={isMobile ? 'auto' : window.innerHeight - 300}
                  >
                    {/* <h1 style={{fontSize:'55px', color:'pink',position:'sticky'}}>{dupTabs[0].name} , {JSON.stringify(dupTabs[0].selected)}</h1> */}

                    {dupTabs.map((tab) => (
                      <Box as="li" textAlign={isMobile ? 'center' : 'start'}>
                        <Text
                          p="2"
                          paddingLeft="16px"
                          borderBottom={
                            isMobile && tab.selected ? '4px' : 'none'
                          }
                          borderLeft={
                            tab.selected && !isMobile ? '4px' : 'none'
                          }
                          borderColor={
                            darkMode ? '#4ADF79' : theme.navItemSelectedBorder
                          }
                          minH="30px"
                          bg={
                            tab.selected
                              ? !isMobile
                                ? darkMode
                                  ? '#262626'
                                  : theme.navItemSelectedBg
                                : 'transparent'
                              : 'transparent'
                          }
                          boxSizing="border-box"
                          fontWeight="bold"
                          fontSize={isMobile ? '14px' : '16px'}
                          color={
                            tab.selected
                              ? darkMode
                                ? 'white'
                                : 'black'
                              : !darkMode
                              ? 'black'
                              : 'white'
                          }
                          width={isMobile ? '170px' : ''}
                        >
                          <div
                            style={{ cursor: 'pointer' }}
                            id={`tab-${tab.name}`}
                            onClick={() => {
                              if (tab.name === 'Future Growth') {
                                tab.exists =
                                  futureGrowthData &&
                                  futureGrowthData.length > 0;
                              } else if (tab.name === 'Dividend') {
                                tab.exists = dividendData
                                  ? !(
                                      !dividendData.current.yieldPercent &&
                                      Number(
                                        dividendData.current.yieldPercent
                                      ) == 0
                                    )
                                  : false;
                              } else if (tab.name === 'Ownership') {
                                tab.exists =
                                  insidersData.insiders.length > 0 ||
                                  insidersData.institutionsHolders.length > 0 ||
                                  insidersData.fundsHolders.length > 0;
                              }

                              var element = document.getElementById(
                                `${tab.name}`
                              );
                              if (element !== null) {
                                var headerOffset = isMobile ? 200 : 100;
                                var elementPosition = element!.getBoundingClientRect()
                                  .top;
                                var offsetPosition =
                                  elementPosition +
                                  window.pageYOffset -
                                  headerOffset;
                                history.push(`#${tab.name}`);

                                window.scrollTo({
                                  top: offsetPosition,
                                  behavior: 'smooth',
                                });
                                gtag('event', `${tab.name}-company-tag-click`, {
                                  event_category: tab.name,
                                  event_label: params.sid,
                                });
                              }
                            }}
                          >
                            {tab.name}
                          </div>
                        </Text>

                        {tab.tabs?.length &&
                        tab.selected &&
                        tab.exists &&
                        !isMobile ? (
                          <Box as="ul" ml="10">
                            {tab.tabs.map((childTab) => (
                              <Box
                                as="li"
                                boxSizing="border-box"
                                minH="30px"
                                p="2"
                                mb="14px"
                                pb="0"
                              >
                                <Text
                                  color={
                                    childTab.selected
                                      ? darkMode
                                        ? '#4ADF79'
                                        : theme.navItemSelectedColor
                                      : theme.navItemColor
                                  }
                                  fontWeight={
                                    childTab.selected ? 'bold' : 'normal'
                                  }
                                  fontSize="small"
                                >
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      scrollToElement(childTab.name, isMobile);
                                      gtag(
                                        'event',
                                        `${childTab.name}-company-tag-click`,
                                        {
                                          event_category: childTab.name,
                                          event_label: params.sid,
                                        }
                                      );
                                    }}
                                  >
                                    <Text style={{ fontSize: '14px' }}>
                                      {childTab.name}
                                    </Text>
                                  </div>
                                </Text>
                              </Box>
                            ))}
                          </Box>
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={3} bg="" marginTop="20px" marginBottom="20px">
                <Flex flexDirection="column" gap="4">
                  {/* --------------------------------------OVERVIEW---------------------------------- */}

                  <SectionDisplay
                    loading={valuationDataLoading}
                    condition={valuationData.symbol && summaryData}
                    displayText="No summary data available"
                    darkMode={darkMode}
                  >
                    <Flex
                      id="Overview"
                      ref={overviewRef}
                      flexDirection="column"
                    >
                      <Flex
                        id="Stock Description"
                        p={isMobile ? '3' : '8'}
                        ref={stockIntroRef}
                        bg={darkMode ? '#262626' : 'white'}
                        flexDirection="column"
                        borderRadius="5px"
                      >
                        <CompanySummary
                          summaryData={summaryData}
                          valuationData={valuationData}
                          darkMode={darkMode}
                        />
                      </Flex>

                      <Flex
                        id="Stock Summary"
                        ref={stockSummaryRef}
                        p={isMobile ? '3' : '8'}
                        bg={darkMode ? '#262626' : 'white'}
                        marginTop="20px"
                        flexDirection="column"
                        borderRadius="5px"
                      >
                        <ViewSummaryRevamp
                          summaryData={summaryData}
                          darkMode={darkMode}
                        />
                      </Flex>

                      <Flex
                        id="Stock Highlights"
                        ref={vSummaryRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {vSummary}
                      </Flex>

                      <Flex
                        id="Performance Table"
                        ref={priceGrowthRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {priceGrowth}
                      </Flex>

                      {/* {stockInfoData.length > 0 ? (
                    <Flex
                      id="Performance Chart"
                      ref={stockInfoRef}
                      bg={darkMode ? '#262626' : 'white'}
                       p = {isMobile ? "3" : "8"}
                      flexDir="column"
                      mt="5"
                      borderRadius="5px"
                    >
                      {stockInfo}
                    </Flex>
                  ) : (
                    <div></div>
                  )} */}

                      {valuationData.symbol !== undefined && backButton ? (
                        <Flex
                          id="Performance Chart"
                          bg={darkMode ? '#262626' : 'white'}
                          ref={stockInfoRef}
                          p="5"
                          flexDir="column"
                          mt="5"
                          borderRadius="5px"
                        >
                          {performance}
                        </Flex>
                      ) : (
                        <LoadingIndicator />
                      )}
                    </Flex>
                  </SectionDisplay>
                  {/* --------------------------------------OVERVIEW---------------------------------- */}

                  {/* --------------------------------------VALUATION---------------------------------- */}

                  <Flex
                    id="Valuation"
                    className="Valuation"
                    ref={valuationRef}
                    flexDirection="column"
                  >
                    <SectionDisplay
                      loading={insightsLoading}
                      condition={insightsData.symbol}
                      displayText="No valuation highlights available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Valuation Highlights"
                        ref={vHighlightsRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {vHighlights}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={valuationDataLoading}
                      condition={valuationData.symbol}
                      displayText="No fair value scale available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Fair Value"
                        ref={fairRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {fairValue}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={valuationDataLoading}
                      condition={valuationData.symbol}
                      displayText="No analyst ratings available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Analyst Ratings"
                        ref={analystRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {analystRatings}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={valuationDataLoading}
                      condition={valuationData.symbol}
                      displayText="No Key Valuation Multiples available"
                      darkMode={darkMode}
                    >
                      <div id="Key Valuation Multiples" ref={ratiosRef}>
                        <Flex
                          id="PE"
                          bg={darkMode ? '#262626' : 'white'}
                          p={isMobile ? '3' : '8'}
                          ref={peRef}
                          flexDir="column"
                          mt="5"
                          borderRadius="5px"
                        >
                          {priceToEanings}
                        </Flex>

                        <Flex
                          id="PEG"
                          bg={darkMode ? '#262626' : 'white'}
                          p={isMobile ? '3' : '8'}
                          flexDir="column"
                          mt="5"
                          ref={pegRef}
                          borderRadius="5px"
                        >
                          {priceToEarningsGrowth}
                        </Flex>

                        <Flex
                          id="PB"
                          bg={darkMode ? '#262626' : 'white'}
                          p={isMobile ? '3' : '8'}
                          flexDir="column"
                          mt="5"
                          ref={pbRef}
                          borderRadius="5px"
                        >
                          {priceToBook}
                        </Flex>

                        <Flex
                          id="PS"
                          bg={darkMode ? '#262626' : 'white'}
                          p={isMobile ? '3' : '8'}
                          flexDir="column"
                          mt="5"
                          ref={psRef}
                          borderRadius="5px"
                        >
                          {priceToSales}
                        </Flex>
                      </div>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={pastPerformanceDataLoading}
                      condition={pastPerformanceData}
                      displayText="No Market Cap Multiples chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Market Cap Multiples"
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        ref={marketCapRef}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {marketCapMultiples}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={pastPerformanceDataLoading}
                      condition={pastPerformanceData}
                      displayText="No Enterprise Cap Multiples chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Enterprise Cap Multiples"
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        ref={enterpriseCapRef}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {enterpriseMultiples}
                      </Flex>
                    </SectionDisplay>
                  </Flex>

                  {/* --------------------------------------VALUATION---------------------------------- */}

                  {/* --------------------------------------PASTPERFORMANCE---------------------------------- */}

                  <Flex
                    id="Financial Statements"
                    className="Past-Performance"
                    ref={pastRef}
                    flexDirection="column"
                  >
                    <SectionDisplay
                      loading={insightsLoading}
                      condition={insightsData.symbol}
                      displayText="No Financial Statement Highlights available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Financial Statement Highlights"
                        ref={financialStatementsHighlightsRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {financialStatementHighlights}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={pastPerformanceDataLoading}
                      condition={pastPerformanceData}
                      displayText="No Income Statement chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Income Statement"
                        ref={incomeStatementRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {incomeStatement}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={pastPerformanceDataLoading}
                      condition={pastPerformanceData}
                      displayText="No Balance Sheet chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Balance Sheet"
                        ref={BalanceSheetRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {balanceSheet}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={pastPerformanceDataLoading}
                      condition={pastPerformanceData}
                      displayText="No Cashflow Statement chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Cashflow Statement"
                        ref={cashFlowRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {cashFlow}
                      </Flex>
                    </SectionDisplay>
                  </Flex>

                  {/* --------------------------------------PASTPERFORMANCE---------------------------------- */}

                  {/* --------------------------------------PASTPERFORMANCE RATIOS ---------------------------------- */}

                  <Flex
                    id="Financial Ratios"
                    className="Past-Performance"
                    ref={pastRatioRef}
                    flexDirection="column"
                  >
                    <SectionDisplay
                      loading={insightsLoading}
                      condition={insightsData.symbol}
                      displayText="No Financial Ratio Highlights available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Financial Ratio Highlights"
                        ref={financialRatioHighlightsRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {financialRatioHighlights}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={pastPerformanceDataLoading}
                      condition={pastPerformanceData}
                      displayText="No Profitability Ratios chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Profitability Ratios"
                        ref={profitabilityRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {profitability}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={pastPerformanceDataLoading}
                      condition={pastPerformanceData}
                      displayText="No Efficiency Ratios chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Efficiency Ratios"
                        ref={efficienyRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {efficiency}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={pastPerformanceDataLoading}
                      condition={pastPerformanceData}
                      displayText="No Leverage Ratios chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Leverage Ratios"
                        ref={leverageRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {leverage}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={pastPerformanceDataLoading}
                      condition={pastPerformanceData}
                      displayText="No Shares Outstanding chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Shares Outstanding"
                        ref={sharesOutRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {sharesOutstanding}
                      </Flex>
                    </SectionDisplay>
                  </Flex>

                  {/* --------------------------------------PASTPERFORMANCE RATIOS ---------------------------------- */}

                  {/* --------------------------------------FUTUREGROWTH---------------------------------- */}

                  <Flex
                    id="Future Growth"
                    ref={futureRef}
                    flexDirection="column"
                  >
                    <SectionDisplay
                      loading={insightsLoading}
                      condition={insightsData.symbol}
                      displayText="No Future Growth Highlights available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Future Growth Highlights"
                        ref={futureHighilightsRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {futureGrowthHighlights}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={futureGrowthDataLoading}
                      condition={
                        futureGrowthData && futureGrowthData.length > 0
                      }
                      displayText="No Revenue Projections chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Revenue Projections"
                        ref={revenueProjRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {revenueProjection}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={futureGrowthDataLoading}
                      condition={
                        futureGrowthData && futureGrowthData.length > 0
                      }
                      displayText="No EPS Projections chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="EPS Projections"
                        ref={earningsProjRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {earningsProjection}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={futureGrowthDataLoading}
                      condition={
                        futureGrowthData && futureGrowthData.length > 0
                      }
                      displayText="No Net Income Projections chart available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Net Income Projections"
                        ref={projEarningsRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {projectedEarnings}
                      </Flex>
                    </SectionDisplay>
                  </Flex>

                  {/* --------------------------------------FUTUREGROWTH---------------------------------- */}

                  {/* --------------------------------------DIVIDEND---------------------------------- */}

                  {dividendData ? (
                    <Flex
                      id="Dividend"
                      ref={dividendRef}
                      flexDirection="column"
                    >
                      {!dividendData.current.yieldPercent &&
                      Number(dividendData.current.yieldPercent) == 0 ? (
                        <Flex
                          bg={darkMode ? '#262626' : 'white'}
                          p={isMobile ? '3' : '8'}
                          flexDir="column"
                          mt="5"
                          borderRadius="5px"
                        >
                          <ColoredContentText className="text-center">
                            <h1 style={{ fontSize: '25px' }}>
                              <span className="font-medium">
                                {params.sid.toUpperCase()}
                              </span>{' '}
                              does not currently pay a dividend.
                            </h1>
                          </ColoredContentText>
                        </Flex>
                      ) : (
                        <>
                          <Flex
                            id="Dividend Highlights"
                            ref={dividendHighlightsRef}
                            bg={darkMode ? '#262626' : 'white'}
                            p={isMobile ? '3' : '8'}
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {dividendHighlights}
                          </Flex>

                          <Flex
                            id="Dividend Yield (FWD)"
                            ref={currentYieldRef}
                            bg={darkMode ? '#262626' : 'white'}
                            p={isMobile ? '3' : '8'}
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {currentYield}
                          </Flex>
                          <Flex
                            id="Dividend Yield (TTM)"
                            ref={ttmDivRef}
                            bg={darkMode ? '#262626' : 'white'}
                            p={isMobile ? '3' : '8'}
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {TTM}
                          </Flex>
                          <Flex
                            id="Yield On Cost"
                            ref={yieldOnCostRef}
                            bg={darkMode ? '#262626' : 'white'}
                            p={isMobile ? '3' : '8'}
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {yieldOnCost}
                          </Flex>
                          <Flex
                            id="Dividend History"
                            ref={divHistRef}
                            bg={darkMode ? '#262626' : 'white'}
                            p={isMobile ? '3' : '8'}
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {dividendHistory}
                          </Flex>
                          <Flex
                            id="Net Income Payout Ratio"
                            ref={netIncomeRef}
                            bg={darkMode ? '#262626' : 'white'}
                            p={isMobile ? '3' : '5'}
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {netIncome}
                          </Flex>
                          <Flex
                            id="FCF Payout Ratio"
                            ref={fcfRef}
                            bg={darkMode ? '#262626' : 'white'}
                            p={isMobile ? '3' : '5'}
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {fcf}
                          </Flex>
                          <Flex
                            id="Total Shareholder Payout"
                            ref={totalShareRef}
                            bg={darkMode ? '#262626' : 'white'}
                            p={isMobile ? '3' : '5'}
                            flexDir="column"
                            mt="5"
                            borderRadius="5px"
                          >
                            {totalShareholder}
                          </Flex>
                        </>
                      )}
                    </Flex>
                  ) : (
                    <LoadingIndicator />
                  )}

                  {/* --------------------------------------DIVIDEND---------------------------------- */}

                  {/* --------------------------------------OWNERSHIP---------------------------------- */}

                  <Flex
                    id="Ownership"
                    ref={ownershipRef}
                    flexDirection="column"
                  >
                    <SectionDisplay
                      loading={insidersDataLoading}
                      condition={
                        insidersData.insiders &&
                        insidersData.insiders.length > 0
                      }
                      displayText="No Insiders Transaction available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Insiders Transaction"
                        ref={insidersRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {insidersTransactions}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={insidersDataLoading}
                      condition={
                        valuationData.symbol &&
                        insidersData?.institutionsHolders
                      }
                      displayText="No Top Institutional Shareholders available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Top Institutional Shareholders"
                        ref={instiutionalRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {institutionsHolders}
                      </Flex>
                    </SectionDisplay>
                    <SectionDisplay
                      loading={insidersDataLoading}
                      condition={
                        valuationData.symbol && insidersData?.fundsHolders
                      }
                      displayText="No Top Funds Shareholders available"
                      darkMode={darkMode}
                    >
                      <Flex
                        id="Top Funds Shareholders"
                        ref={fundsRef}
                        bg={darkMode ? '#262626' : 'white'}
                        p={isMobile ? '3' : '8'}
                        flexDir="column"
                        mt="5"
                        borderRadius="5px"
                      >
                        {fundsHolders}
                      </Flex>
                    </SectionDisplay>
                  </Flex>

                  {/* --------------------------------------OWNERSHIP---------------------------------- */}

                  <SectionDisplay
                    loading={similarCompaniesDataLoading}
                    condition={similarCompaniesData}
                    displayText="No Top Funds Shareholders available"
                    darkMode={darkMode}
                  >
                    <Flex
                      id="Other Information"
                      ref={otherRef}
                      bg={darkMode ? '#262626' : 'white'}
                      p={isMobile ? '3' : '8'}
                      flexDir="column"
                      borderRadius="5px"
                    >
                      {similarCompanies}
                    </Flex>
                  </SectionDisplay>
                </Flex>
              </GridItem>
            </Grid>
          </Container>
        </Flex>
      ) : (
        <NotExists isAndroid={false} />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  darkMode: state.coreState.darkMode,
  valuationError: state.valuationState.error,
  futureGrowthData: state.futureGrowthState.data,
  futureGrowthDataLoading: state.futureGrowthState.loading,
  futureGrowthInsights: state.symbolInsightsState.data?.future_growth,
  dividendData: state.dividendState.data,
  dividendDataLoading: state.dividendState.loading,
  dividendInsights: state.symbolInsightsState.data?.dividend,
  pastPerformanceData: state.pastPerformanceState.data,
  pastPerformanceDataLoading: state.pastPerformanceState.loading,
  pastPerformanceInsights: state.symbolInsightsState.data?.past_performance,
  valuationData: state.valuationState.data,
  valuationDataLoading: state.valuationState.loading,
  valuationDataError: state.valuationState.error,
  valuationInsights: state.symbolInsightsState.data?.valuation,
  stockInfoData: state.stockHistoryState.data,
  insidersData: state.insidersState.data,
  insidersDataLoading: state.insidersState.loading,
  ownershipInsights: state.symbolInsightsState.data?.insiders,
  summaryData: state.summaryState.data,
  loading: state.summaryState.data,
  holdersData: state.insidersState.data.fundsHolders,
  insightsLoading: state.symbolInsightsState.loading,
  insightsData: state.symbolInsightsState,
  similarCompaniesData: state.similarCompaniesState.data,
  similarCompaniesDataLoading: state.similarCompaniesState.loading,
  subscriptionStatus: state.coreState.subscriptionStatus,
  publicSymbols: state.symbolState.publicSymbols?.data,
});

const mapDispatchToProps = {
  getValuationData,
  getStockInfo,
  fetchSymbolInsights,
  getSimilarCompaniesData,
  fetchSymbolNews,
  getPastPerformanceData,
  getFutureGrowthData,
  getDividendData,
  getInsidersData,
  getHoldersData,
  getSummaryData,
  showPageLoading,
  getRealTimeData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
