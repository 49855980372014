export const defaultButtonValues = [
  { id: 'revenue', name: 'Revenue', selected: true, color: '#319AA7' },
  { id: 'earnings', name: 'Earnings', selected: true, color: '#3E2ABE' },
  {
    id: 'freeCashFlow',
    name: 'Free Cash Flow',
    selected: false,
    color: '#4B6390',
  },
  {
    id: 'cashFromOps',
    name: 'Cash from Ops',
    selected: false,
    color: '#B4349C',
  },
  {
    id: 'operationExp',
    name: 'Operation Exp',
    selected: false,
    color: '#A81A1A',
  },
];

export const chartValues = {
  revenue: {
    name: 'Revenue',
    color: '#319AA7',
    fill: '#319AA7',
  },
  earnings: {
    name: 'Earnings',
    color: '#3E2ABE',
    fill: '#3E2ABE',
  },
  free_cash_flow: {
    name: 'Free Cash Flow',
    color: '#4B6390',
    fill: '#4B6390',
  },
  cash_from_ops: {
    name: 'Cash from Ops',
    color: '#B4349C',
    fill: '#B4349C',
  },
  operation_exp: {
    name: 'Operation Exp',
    color: '#A81A1A',
    fill: '#A81A1A',
  },
};
