import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { buttonOptions, monthNames, accessors } from './constants';
import { screenSize } from '../../../StyedComponents';
import { useCurrentWidth } from '../../../../core/useCurrentWidth';
import { useMediaQuery } from '@chakra-ui/react';
import {
  buildStockInfoTooltip,
  getFormattedCurrencyValueForTooltip,
  getTooltipDate,
} from '../../../../../utils/ChartUtils';
import numeral from 'numeral';

const determineButtonTooltipX = (screenWidth, unitWidth, button) => {
  if (screenWidth < screenSize.mobileM) {
    return button.x - 5;
  } else if (screenWidth < screenSize.mobileL) {
    return button.x;
  } else if (screenWidth < screenSize.tablet) {
    return button.x + unitWidth / 5.5;
  } else if (screenWidth < screenSize.laptop) {
    return button.x + unitWidth / 5;
  } else if (screenWidth < screenSize.laptopL) {
    return button.x + unitWidth / 6;
  } else return button.x + unitWidth / 4;
};

Highcharts.SVGRenderer.prototype.symbols.customcircle = function (x, y, w, h) {
  var r = 8;

  return [
    'M',
    0,
    9,
    'm',
    -r,
    0,
    'a',
    r,
    r,
    0,
    1,
    0,
    2 * r,
    0,
    'a',
    r,
    r,
    0,
    1,
    0,
    -2 * r,
    0,
  ];
};

if (Highcharts.VMLRenderer) {
  Highcharts.VMLRenderer.prototype.symbols.customcircle =
    Highcharts.SVGRenderer.prototype.symbols.customcircle;
}

function StockInfo({ data, growthMetrics, darkMode }) {
  const width = useCurrentWidth();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [chartWidth, setChartWidth] = React.useState();

  function generateTooltip(_this, mobileMode) {
    const points = _this.points;
    let tooltips = '';
    const padding = mobileMode ? 3 : 5;
    tooltips = tooltips.concat(
      '<div style="position:relative;height:100%;padding:' + padding + 'px;"> '
    );
    const point = points[0];

    tooltips = tooltips.concat(
      buildStockInfoTooltip(
        //getFormattedCurrencyValueForTooltip(point.y),
        numeral(point.y).format('0,0.00'),
        'Stock Price',
        darkMode,
        mobileMode
      )
    );
    tooltips = tooltips.concat(getTooltipDate(_this.x, darkMode, mobileMode));
    return tooltips;
  }

  const options = {
    chart: {
      type: 'areaspline',
      height: 600,
      style: {
        fontFamily: 'Roboto',
      },
      marginTop: 100,
      marginBottom: 0,
      spacingBottom: 0,
      backgroundColor: darkMode ? 'transparent' : '#ffffff',

      events: {
        render: function (e) {
          let chart = this,
            chartWidth = chart.chartWidth;
          setChartWidth(chart.chartWidth);

          //console.log(chart.yAxis[0].getExtremes());
          //console.log(chart.tooltip);
          var group = chart.renderer
              .g(`range-selector-tooltip`)
              .attr({
                transform: 'translate(-9999, -9999)',
                zIndex: 99,
              })
              .add(),
            text = chart.renderer
              .text()
              .attr({
                class: 'range-selector-tooltip-text',
                zIndex: 7,
              })
              .add(group);
          chart.renderer
            .rect()
            .attr({
              class: 'range-selector-tooltip',
              id: 'range-selector-tooltip',
              'stroke-width': 1,
              stroke: 'grey',
              fill: 'white',
              zIndex: 6,
            })
            .add(group);
          var buttons = this.rangeSelector ? this.rangeSelector.buttons : [];

          if (buttons && buttons.length > 0) {
            for (let i = 0, len = buttons.length; i < len; i++) {
              const button = buttons[i];
              //if (item.translateX === 0) continue;

              button.element.id = 'stock-history-range-' + i;
              (function (i) {
                //console.log({ ...button });
                const percentage = growthMetrics[accessors[button.textStr]];
                const boxColor = percentage > 0 ? '#569c30' : '#be2e2c';

                if (
                  button.element.className.baseVal ===
                  'highcharts-button highcharts-button-pressed'
                ) {
                  var group = document.querySelectorAll(
                      '.highcharts-range-selector-tooltip'
                    ),
                    rectElem = document.querySelectorAll(
                      '.range-selector-tooltip'
                    ),
                    textElem = document.querySelectorAll(
                      '.range-selector-tooltip-text'
                    );

                  textElem[0].innerHTML = percentage + '%';

                  const box = textElem[0].getBBox();
                  rectElem[0].setAttribute('x', box.x - 8);
                  rectElem[0].setAttribute('y', box.y - 5);
                  rectElem[0].setAttribute('rx', 2);
                  rectElem[0].setAttribute('ry', 2);
                  rectElem[0].setAttribute('width', box.width + 15);
                  rectElem[0].setAttribute('height', box.height + 10);
                  rectElem[0].setAttribute('fill', boxColor);
                  rectElem[0].setAttribute('stroke', 'transparent');

                  textElem[0].setAttribute('fill', 'white');
                  const unitWidth = chartWidth / 6;
                  if (button.x === 0) button.x = unitWidth * 3.2;

                  group[0].setAttribute(
                    'transform',
                    `translate(${determineButtonTooltipX(
                      width,
                      unitWidth,
                      button
                    )}, ${button.translateY + 160})`
                  );
                }
              })(i);
            }
          }
        },
        // redraw: function() {

        //   var rangSel = this.rangeSelector.group.getBBox();

        //   this.renderer
        //     .rect(
        //       rangSel.x + 25,
        //       rangSel.y + 120,
        //       this.chartWidth - rangSel.width / 2,
        //       rangSel.height
        //     )
        //     .attr({
        //       fill: darkMode ? '#161616' : '#F8F8F8',
        //     })
        //     .add();
        // },
      },
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      allButtonsEnabled: true,
      y: 30,
      selected: 4,
      labelStyle: {
        display: 'none',
      },
      buttons: buttonOptions,
      dropdown: 'never',
      buttonSpacing: 15,
      buttonPosition: {
        top: 30,
        y: -30,
        x: -1,
      },
      buttonTheme: {
        // styles for the buttons
        width: width <= isMobile ? chartWidth / 12.6 : chartWidth / 10,
        //width: 50,
        height: 35,
        r: 15,

        fill: darkMode ? '#161616' : '#F8F8F8',
        style: {
          fontSize: '15px',
          color: darkMode ? 'white' : 'black',
          fontWeight: 'normal',
          stroke: 'none',
          zIndex: 7,
          dominantBaseline: 'hanging',
          outline: 'none',
          overflowX: 'scroll',
        },
        states: {
          hover: {
            fill: 'white',
            style: {
              color: '#3A3A3A',
            },
          },
          select: {
            fill: darkMode ? '#434243' : '#E6F8EF',

            style: {
              color: darkMode ? 'white' : '#313844',
              //  outline: darkMode ? 'none' : 'auto',
              zIndex: 14,
              fontWeight: 'bold',
            },
          },
        },
      },
      inputEnabled: false,
    },
    yAxis: {
      title: {
        text: '',
      },
      opposite: true,
      labels: {
        formatter: function () {
          var label = this.axis.defaultLabelFormatter.call(this);
          return `$${label}`;
        },
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      gridLineColor: darkMode ? '#3b404f' : '#e6e6e6',
    },

    xAxis: {
      crosshair: {
        enabled: true,
        width: 62,
        color: darkMode
          ? {
              linearGradient: [0, 0, 100, 300],
              stops: [
                [0, 'rgba(52, 94, 103, 0.4)'],
                [0.5, 'rgba(52, 94, 103, 0.4)'],
                [1, 'rgba(87, 103, 128, 0.4)'],
              ],
            }
          : {
              linearGradient: [0, 0, 100, 300],
              stops: [
                [0, 'rgba(225, 241, 243, 0.5)'],
                [1, 'rgba(225, 241, 243, 0.5)'],
              ],
            },
      },
      dateTimeLabelFormats: {
        day: '%e %b',
        week: '%e %b',
        month: '%b %Y',
        year: '%Y',
      },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      margin: 0,
      tickLength: 0,
      events: {
        setExtremes: function (e) {
          if (typeof e.rangeSelectorButton !== 'undefined') {
            if (e.rangeSelectorButton.text === '1W') {
              this.update(
                {
                  tickInterval: 86400000 * 2,
                  dateTimeLabelFormat: '%e %b',
                },
                false
              );
            } else if (e.rangeSelectorButton.text === '1M') {
              this.update(
                {
                  tickInterval: 86400000 * 7,
                  dateTimeLabelFormat: '%b %y',
                },
                false
              );
            } else if (e.rangeSelectorButton.text === '6M') {
              this.update(
                {
                  tickInterval: 86400000 * 31,
                  dateTimeLabelFormat: '%e %b',
                },
                false
              );
            } else if (e.rangeSelectorButton.text === '1Y') {
              this.update(
                {
                  tickInterval: 86400000 * 31,
                  dateTimeLabelFormat: '%b %y',
                },
                false
              );
            } else if (e.rangeSelectorButton.text === '5Y') {
              this.update(
                {
                  tickInterval: 86400000 * 730,
                  dateTimeLabelFormat: '%Y',
                },
                false
              );
            }
          }
        },
      },
    },
    navigator: {
      top: 5,
      height: 70,
      outlineColor: darkMode ? '#3b404f' : '#cccccc',
      handles: {
        symbols: ['customcircle', 'customcircle'],
        backgroundColor: 'white',
        borderColor: 'grey',
        radius: 8,
      },
      xAxis: {
        gridLineColor: darkMode ? '#3f4957' : '#e6e6e6',
        labels: {
          align: 'center',
        },
      },
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shadow: false,
      useHTML: true,
      backgroundColor: darkMode ? '#141920' : '#fff',
      borderColor: darkMode ? '#86efaf' : '#5bae7f',
      borderRadius: 8,
      formatter: function () {
        return generateTooltip(this, false);
      },
      /* formatter: function () {
        let currentDate =
          monthNames[new Date(this.x).getMonth()] +
          ' ' +
          new Date(this.x).getDate() +
          ', ' +
          new Date(this.x).getFullYear();

        return (
          '<div style="width:80px;height:100%;"> ' +
          '<div style="margin-bottom:10px">' +
          currentDate +
          '</div>' +
          '<div style="width:70%;">' +
          '<div style="font-weight:500;">' +
          '<div style="color:#686868; font-size:0.7rem; margin-bottom:2px">' +
          'Stock Price' +
          '</div>' +
          '<div style="color:#3A3A3A;font-size: 0.8rem;">$' +
          this.y.toFixed(2) +
          '</div></div></div>'
        );
      }, */
      shared: true,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Stock Price',
        data: data,
        type: 'areaspline',
        threshold: null,
        color: darkMode ? '#7bbfff' : '#438DF7',
        fillColor: {
          linearGradient: [0, 100, 0, 300],
          stops: darkMode
            ? [
                [0, Highcharts.color('#7bbfff').setOpacity(0.5).get('rgba')],
                [1, Highcharts.color('#7bbfff').setOpacity(0.05).get('rgba')],
              ]
            : [
                [0, Highcharts.color('#438DF7').setOpacity(0.5).get('rgba')],
                [1, Highcharts.color('#438DF7').setOpacity(0.05).get('rgba')],
              ],
        },
        fillOpacity: 0.2,
        //dashStyle: 'ShortDash',
        //color: '#319AA7',
        //opacity: 0.5,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            /* rangeSelector: {
              //selected: 3,
              labelStyle: {
                display: 'none',
              },
              //buttons: buttonOptions,
              buttonTheme: {
                // styles for the buttons
                width: chartWidth / 6 - 5,
                height: 25,
                fill: '#F0F5FC',

                style: {
                  fontSize: '12px',
                  color: '#8090aa',
                  fontWeight: 'normal',
                  stroke: 'none',
                  dominantBaseline: 'central',
                  outline: 'none',
                  paddingBottom: '4px',
                },
                states: {
                  hover: {
                    fill: 'white',
                    style: {
                      color: '#3A3A3A',
                    },
                  },
                  select: {
                    fill: 'white',
                    style: {
                      color: '#313844',
                      fontSize: '12px',
                      outline: 'auto',

                      fontWeight: 'bold',
                      paddingBottom: '4px',
                    },
                  },
                },
              },
            }, */
            xAxis: {
              crosshair: {
                width: 45,
              },
            },
            tooltip: {
              formatter: function () {
                return generateTooltip(this, true);
              },
            },
            chart: {
              height: 500,
              spacingBottom: 0,
              marginBottom: 0,
            },
          },
        },
      ],
    },
  };

  return (
    <div className="text-xs font-semibold w-full">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(StockInfo);
