export const getScreenerData = (offset, limit, payload) => ({
  type: 'FETCH_SCREENER_DATA',
  offset,
  limit,
  payload,
});

export const updateFilters = (filters) => ({
  type: 'UPDATE_FILTERS',
  filters,
});

export const resetFilters = () => ({
  type: 'RESET_FILTERS',
});

export const updatePagination = (pageNo, pageSize) => ({
  type: 'UPDATE_PAGINATION',
  pageNo,
  pageSize,
});

export const fetchFilterValues = () => ({
  type: 'FETCH_FILTER_VALUES',
});
