export const HeaderList = [
  { id: 'Ticker', key: 'symbol', upDownIcon: false },
  {
    id: 'Activity',
    key1: 'activity', // concatenate
    key2: 'changePercent',
    prefix: '',
    suffix: '',
    upDownIcon: false,
  },
  {
    id: 'Share Change',
    key1: 'previousShares',
    key2: 'currentShares',
    upDownIcon: true,
    ascend: false,
    descend: false,
    prefix: '',
    suffix: '',
  },
  {
    id: '% of Portfolio',
    key: 'portfolioPercent',
    upDownIcon: true,
    ascend: false,
    descend: false,
    prefix: '',
    suffix: '%',
  },
];
