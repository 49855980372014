import numeral from 'numeral';
import ScreenerCM from '../models/ScreenerCM';
import { getLogoUrl } from '../utils/LogoUtils';
import { formatDecimalValue } from '../utils/number';

export const deserialise = (payload) =>
  new ScreenerCM.builder()
    .symbol(payload.symbol)
    .name(payload.symbol_name)
    .logoUrl(getLogoUrl(payload.symbol))
    .industry(payload.industry)
    .sector(payload.gic_sector_display)
    .currentPrice(payload.current_price)
    .marketCap(
      numeral(payload.marketcap_mln + '000000')
        .format('($0.00a)')
        .toUpperCase()
    )
    .fairPrice(formatDecimalValue(payload.fair_price))
    .targetPrice(formatDecimalValue(payload.target_price))
    .verdeScore(payload.verde_score)
    .growthRating(payload.growth_rating)
    .safetyRating(payload.safety_rating)
    .valueRating(payload.value_rating)
    .fairPriceDeviation(formatDecimalValue(payload.fair_price_deviation))
    .percentChangeInPrice(formatDecimalValue(payload.percent_change_in_price))
    .fairPriceUpside(formatDecimalValue(payload.fair_price_upside))
    .targetPriceUpside(formatDecimalValue(payload.target_price_upside))
    .priceToEarnings(formatDecimalValue(payload.pe_ratio_ttm))
    .priceToEarningsFWD(formatDecimalValue(payload.forward_pe_ratio))
    .pegRatio(formatDecimalValue(payload.peg_ratio))
    .forwardDividendRate(formatDecimalValue(payload.dividend_rate_fwd))
    .analystRating(payload.analyst_rating)
    .dividendRating(payload.dividend_rating)
    .momentumRating(payload.momentum_rating)
    .forwardYield(formatDecimalValue(payload.yield_fwd))
    .oneDay(formatDecimalValue(payload.one_day_return))
    .oneWeek(formatDecimalValue(payload.one_week_return))
    .oneMonth(formatDecimalValue(payload.one_month_return))
    .sixMonths(formatDecimalValue(payload.six_month_return))
    .threeMonth(formatDecimalValue(payload.three_month_return))
    .ytd(formatDecimalValue(payload.ytd_return))
    .oneYear(formatDecimalValue(payload.one_year_return))
    .fiveYears(formatDecimalValue(payload.five_year_return))
    .enterPrise(
      numeral(payload.enterprise_value).format('($0.00a)').toUpperCase()
    )
    .priceToSale(formatDecimalValue(payload.price_to_sales))
    .priceToBook(formatDecimalValue(payload.price_to_book))
    .revenueGrowth(formatDecimalValue(payload.revenue_growth_ttm))
    .revenueGrowthFWD(formatDecimalValue(payload.revenue_growth_fwd))
    .revenueGrowthFY1(formatDecimalValue(payload.revenue_growth_fy1))
    .revenueGrowthFY2(formatDecimalValue(payload.revenue_growth_fy2))
    .epsGrowth(formatDecimalValue(payload.eps_growth_ttm))
    .epsGrowthFWD(formatDecimalValue(payload.eps_growth_fwd))
    .epsGrowthFY1(formatDecimalValue(payload.eps_growth_fy1))
    .epsGrowthFY2(formatDecimalValue(payload.eps_growth_fy2))
    .grossMargin(formatDecimalValue(payload.gross_margin))
    .netMargin(formatDecimalValue(payload.net_margin))
    .operatingMargin(formatDecimalValue(payload.operating_margin))
    .profitabilityRating(payload.profitability_rating)
    .netIncomeMargin(payload.net_income_margin)
    .returnOnAssets(payload.return_on_assets)
    .returnOnEquity(payload.return_on_equity)
    .returnOnInvestmentCapital(payload.return_on_invested_capital)
    .threeYears(payload.three_year_return)
    .evEbit(payload.ev_ebit)
    .evEbita(payload.ev_ebitda)
    .evRevenue(payload.ev_revenue)
    .debtCapital(payload.debt_capital)
    .debtEquity(payload.debt_equity)
    .symbolMarket(payload.symbol_market)
    .build();
