import React, { useEffect } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import { connect } from 'react-redux';
import { showPageLoading } from '../../../actions/coreAction';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import PageContainer from '../../shared/PageContainer';
import { useParams } from 'react-router-dom';
import defaultPic from '../../../public/images/default-profile-picture.jpg';
import QR from '../../../public/images/User_Profile_QR.png';
import VerdeMobile from '../../../public/images/VerdeMobile.png';
import clevertap from 'clevertap-web-sdk';

function UserProfile({ darkMode, showPageLoading, profileData, email, username }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams();
  useEffect(() => 
  {
    if(profileData['name'])
    {
      clevertap.event.push('User Viewed', {
        'USER-Email': profileData['email'],
        'USER-Name': profileData['name'],
        'USER-UserName':profileData['username'],
        Email:email,
        Username:username
      })
    }
  },[profileData])


  return (
    <Box>
      <PageContainer
        style={{ backgroundColor: darkMode ? '#161616' : '#f4f4f4' }}
      >
        <Flex justifyContent={isMobile ? 'center' : null}>
          <img
            id="profilePicture"
            src={`${
              profileData && profileData['profile_pic_url'] !== null
                ? profileData['profile_pic_url']
                : defaultPic
            }`}
            style={{
              objectFit:'cover',
              justifyContent: 'center',
              marginTop: 25,
              borderRadius: 100,
              maxWidth: isMobile ? 100 : 150,
              maxHeight: isMobile ? 100 : 150,
              minWidth: isMobile ? 100 : 150,
              minHeight: isMobile ? 100 : 150,
            }}
          />

          <Box marginLeft={10} marginTop={10} width={isMobile ? '60%' : '90%'}>
            <Flex>
              <Box>
                <Text
                  style={{
                    color: darkMode ? 'white' : 'black',
                    fontWeight: 500,

                    textAlign: 'left',
                    fontSize: 26,
                  }}
                >
                  {profileData && profileData['name']}
                </Text>

                <Text
                  style={{
                    color: darkMode ? '#cccccc' : 'black',
                    fontSize: 16,
                    marginTop: 1,
                    marginBottom: 10,
                    textAlign: 'left',
                  }}
                >
                  {profileData && profileData['description']}
                </Text>
                <Flex
                  justifyContent="flex-start"
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                >
                  <Box marginRight={isMobile ? 30 : 70}>
                    <Flex marginTop={2}>
                      <img
                        src={VerdeScoreSvg}
                        alt=""
                        className="w-5 h-5 mb-0.5 mr-1 mt-1"
                      />
                      <Text
                        style={{
                          color: darkMode ? '#f6f6f6' : 'black',
                          fontSize: isMobile ? 18 : '20px',
                          fontWeight: 'bold',
                        }}
                      >
                        {profileData && profileData['verde_score']}
                      </Text>
                    </Flex>
                    <Text
                      style={{
                        color: darkMode ? '#cccccc' : 'black',
                        fontSize: isMobile ? 14 : 16,
                        marginTop: 5,
                      }}
                    >
                      Verde Score
                    </Text>
                  </Box>
                  <Box marginTop={2} marginRight={isMobile ? 30 : 70}>
                    <Text
                      style={{
                        color: darkMode ? 'white' : 'black',
                        fontSize: isMobile ? 18 : 20,
                        fontWeight: 500,
                      }}
                    >
                      {profileData && profileData['similarity_score'] !== null
                        ? parseFloat(profileData['similarity_score']).toFixed(0)
                        : '-'}
                    </Text>
                    <Text
                      style={{
                        color: darkMode ? '#cccccc' : 'black',
                        fontSize: isMobile ? 14 : 16,
                        marginTop: 5,
                      }}
                    >
                      Similarity Score
                    </Text>
                  </Box>
                  <Box marginTop={2} marginRight={isMobile ? 30 : 70}>
                    <Text
                      style={{
                        color: darkMode ? 'white' : 'black',
                        fontSize: isMobile ? 18 : 20,
                        fontWeight: 500,
                      }}
                    >
                      {profileData && profileData['followers_count']}
                    </Text>
                    <Text
                      style={{
                        color: darkMode ? '#cccccc' : 'black',
                        fontSize: isMobile ? 14 : 16,
                        marginTop: 5,
                      }}
                    >
                      Followers
                    </Text>
                  </Box>
                  <Box marginTop={2}>
                    <Text
                      style={{
                        color: darkMode ? 'white' : 'black',
                        fontSize: isMobile ? 18 : 20,
                        fontWeight: 500,
                      }}
                    >
                      {profileData && profileData['followees_count']}
                    </Text>
                    <Text
                      style={{
                        color: darkMode ? '#cccccc' : 'black',
                        fontSize: isMobile ? 14 : 16,
                        marginTop: 5,
                      }}
                    >
                      Following
                    </Text>
                  </Box>
                </Flex>
              </Box>

              {!isMobile && (
                <Box marginLeft="auto">
                  <Link to="/">
                    <button
                      style={{
                        padding: 5,
                        width: isMobile ? '60%' : '120px',
                        color: darkMode ? '#4adf79' : '#01874e',
                        border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                        borderRadius: 20,
                        marginTop: 10,
                        fontSize: 15,
                        fontWeight: 500,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Go Home
                    </button>
                  </Link>
                  <Box>
                    <button
                      onClick={() => {
                        const dummy = document.createElement('input'),
                          text = window.location.href;

                        document.body.appendChild(dummy);
                        dummy.value = text;
                        dummy.select();
                        document.execCommand('copy');
                        document.body.removeChild(dummy);
                        alert('Profile ID copied to clipboard');
                      }}
                      style={{
                        padding: 5,
                        width: isMobile ? '60%' : '120px',
                        color: darkMode ? '#4adf79' : '#01874e',
                        border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                        borderRadius: 20,
                        marginTop: 10,
                        fontSize: 15,
                        fontWeight: 500,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {' '}
                      Share Profile
                    </button>
                  </Box>
                </Box>
              )}
            </Flex>

            {isMobile && (
              <Link to="/">
                <button
                  style={{
                    padding: 5,
                    width: isMobile ? '60%' : '120px',
                    color: darkMode ? '#4adf79' : '#01874e',
                    border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                    borderRadius: 20,
                    marginTop: 20,
                    fontSize: 15,
                    fontWeight: 500,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  Go Home
                </button>
              </Link>
            )}
          </Box>
        </Flex>

        <Box
          backgroundColor={darkMode ? '#262626' : 'white'}
          padding={10}
          marginTop={10}
          borderRadius={5}
          marginBottom={10}
        >
          <Flex>
            <Box>
              <Text
                style={{ color: darkMode ? 'white' : 'black' }}
                fontSize={21}
              >
                This feature is not currently on Web
              </Text>
              <Text
                style={{ color: darkMode ? 'white' : 'black' }}
                fontSize={21}
              >
                Get the most out of your{' '}
                <span
                  style={{
                    color: darkMode ? '#4adf79' : '#01874e',
                    fontWeight: 550,
                  }}
                >
                  Verde experience with our Mobile App!
                </span>
              </Text>

              <Box mt={10}>
                <Text color="gray" fontSize={19}>
                  Download using our QR code
                </Text>
                <img
                  src={QR}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 10,
                    borderRadius: 10,
                    maxWidth: isMobile ? 200 : 250,
                    maxHeight: isMobile ? 200 : 250,
                    minWidth: isMobile ? 200 : 250,
                    minHeight: isMobile ? 200 : 250,
                  }}
                />
              </Box>
            </Box>
            <Box marginLeft="auto" marginRight={20}>
              <img
                src={VerdeMobile}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 5,
                  borderRadius: 10,
                  maxWidth: isMobile ? 400 : 475,
                  maxHeight: isMobile ? 400 : 475,
                  minWidth: isMobile ? 200 : 200,
                  minHeight: isMobile ? 200 : 200,
                }}
              />
            </Box>
          </Flex>
        </Box>
      </PageContainer>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
