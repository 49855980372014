import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import SectionHeader from '../../shared/SectionHeader';
import { useMediaQuery } from '@chakra-ui/react';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import defaultPic from '../../../public/images/default-profile-picture.jpg';
import { USER_SOCIAL_LINK } from '../../../config/default';
import WrappedAxios from '../../../utils/WrappedAxios';
import { useAuth } from '../../hocs/AuthProvider';

function MyProfile({ darkMode, data, callback }) {
  const { authUser } = useAuth();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [editing, setEditing] = useState(false);
  const [imageChosen, setImageChosen] = useState(false);
  const [userProfileObj, setUserProfileObj] = useState({});
  const [newUserCred, setNewUserCreds] = useState({});
  const [fullnameError, setFullnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [usernameCheckerVar, setUsernameCheckerVar] = useState(''); // for debouncing

  useEffect(() => {
    setUserProfileObj({
      name: data && data['name'],
      email: data && data['email'],
      username: data && data['username'],
      description: data && data['description'],
      website_url: data && data['website_url'],
    });
  }, [data]);

  useEffect(() => {
    async function checkForUserName() {
      try {
        await WrappedAxios.head(
          `${USER_SOCIAL_LINK}/users/${userProfileObj['username']}`
        );
        if (userProfileObj['username'] !== data['username']) {
          // if the new username is not user's old one
          setUsernameError(
            'Looks like someone beat you to it, this username is already taken'
          );
        }
      } catch (err) {
        setUsernameError('');
      }
    }
    const debounce = setTimeout(
      () => usernameCheckerVar && checkForUserName(),
      1000
    );
    return () => clearTimeout(debounce);
  }, [data, userProfileObj, usernameCheckerVar]);

  const separator = () => {
    return (
      <div
        style={{
          height: '0px',
          width: '90%',
          margin: 'auto',
          marginTop: 35,
          border: `0.5px solid ${darkMode ? 'rgb(51,51,51)' : '#d9d9d9'}`,
          borderWidth: 0.5,
        }}
      />
    );
  };
  return (
    <Box>
      <Flex>
        <SectionHeader value="Profile Information" />
        <Box marginLeft="auto">
          {editing && (
            <button
              style={{
                padding: 5,
                width: '120px',
                color: darkMode ? '#EC5B55' : '#be2e2c',
                border: `1px solid ${darkMode ? '#EC5B55' : '#be2e2c'}`,
                borderRadius: 20,
                marginTop: 20,
                fontSize: 15,
                fontWeight: 500,
                marginRight: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => {
                setEditing(false);
                setUsernameError('');
                setFullnameError(false);
                setEmailError(false);
                document.getElementById('nameInput').value = newUserCred['name']
                  ? newUserCred['name']
                  : data['name'];
                document.getElementById('usernameInput').value = newUserCred[
                  'username'
                ]
                  ? newUserCred['username']
                  : data['username'];
                document.getElementById('descriptionInput').value = newUserCred[
                  'description'
                ]
                  ? newUserCred['description']
                  : data['description'];
                document.getElementById('emailInput').value = newUserCred[
                  'email'
                ]
                  ? newUserCred['email']
                  : data['email'];

                document.getElementById('websiteInput').value = newUserCred[
                  'website_url'
                ]
                  ? newUserCred['website_url']
                  : data['website_url'];
              }}
            >
              Cancel
            </button>
          )}

          <button
            style={{
              padding: 5,
              width: '120px',
              color: darkMode ? '#4adf79' : '#01874e',
              border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
              borderRadius: 20,
              marginTop: 20,
              marginLeft: 5,
              fontSize: 15,
              fontWeight: 500,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={async () => {
              if (editing) {
                let changesMade = false;
                const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/;

                //check if user made any changes
                for (let keys in userProfileObj) {
                  if (JSON.stringify(newUserCred) === '{}') {
                    if (userProfileObj[keys] !== data[keys]) {
                      changesMade = true;
                    }
                  } else {
                    if (userProfileObj[keys] != newUserCred[keys]) {
                      changesMade = true;
                    }
                  }
                }

                // check if name is not empty
                if (document.getElementById('nameInput').value === '') {
                  setFullnameError(true);
                }
                // check if email is valid
                if (!emailRegex.test(userProfileObj['email'])) {
                  setEmailError(true);
                }
                if (document.getElementById('usernameInput').value === '') {
                  setUsernameError('username cannot be empty!');
                }

                if (changesMade) {
                  if (
                    usernameError === '' &&
                    emailRegex.test(userProfileObj['email']) &&
                    document.getElementById('nameInput').value !== '' &&
                    document.getElementById('usernameInput').value !== ''
                  ) {
                    let newData = { ...data, ...userProfileObj };
                    document.getElementById('name').innerHTML =
                      userProfileObj['name'];
                    callback(newData); // post request

                    setUsernameError('');
                    setFullnameError(false);
                    setEmailError(false);
                    setNewUserCreds(userProfileObj);
                    setEditing(!editing);
                  }
                } else {
                  if (
                    document.getElementById('nameInput').value !== '' &&
                    document.getElementById('usernameInput').value !== ''
                  )
                    setEditing(!editing);
                }
              } else {
                setEditing(!editing);
              }
            }}
          >
            {editing ? 'Update Profile' : 'Edit Profile'}
          </button>
        </Box>
      </Flex>
      <Box>
        <Flex justifyContent="flex-start" marginTop={10}>
          <Box
            w={isMobile ? '30%' : '20%'}
            marginRight="15%"
            justifyContent="flex-end"
          >
            <img
              id="profilePicture"
              src={defaultPic}
              style={{
                objectFit: 'cover',

                marginLeft: 'auto',
                borderRadius: 100,
                display: imageChosen ? 'block' : 'none',
                maxWidth: isMobile ? 60 : 110,
                maxHeight: isMobile ? 60 : 110,
                minWidth: isMobile ? 60 : 110,
                minHeight: isMobile ? 60 : 110,
              }}
            />

            <img
              src={`${
                data && data['profile_pic_url'] !== null
                  ? data['profile_pic_url']
                  : defaultPic
              }`}
              style={{
                objectFit: 'cover',
                marginLeft: 'auto',
                borderRadius: 100,
                // border: `1px solid ${data && data['profile_pic_url'] === null ? darkMode ? 'white' : 'black' : 'transparent'}`,
                display: !imageChosen ? 'block' : 'none',
                maxWidth: isMobile ? 60 : 110,
                maxHeight: isMobile ? 60 : 110,
                minWidth: isMobile ? 60 : 110,
                minHeight: isMobile ? 60 : 110,
              }}
            />
          </Box>

          <Box w="70%">
            <Text
              id="name"
              style={{ color: darkMode ? 'white' : 'black' }}
              fontSize="large"
            >
              {data && data['name']}
            </Text>

            <label
              for="files"
              style={{
                color: darkMode ? '#4adf79' : '#01874e',
                cursor: 'pointer',
              }}
              fontWeight="bold"
              fontSize="small"
            >
              Change Profile Picture
            </label>
            <input
              type="file"
              id="files"
              accept="image/png, image/gif, image/jpeg"
              style={{
                backgroundColor: 'transparent',
                color: darkMode ? '#4adf79' : '#01874e',
                cursor: 'pointer',
                fontSize: 14,
                fontWeight: 550,
                visibility: 'hidden',
              }}
              onChange={async (e) => {
                const file = e.target.files[0];
                const formData = new FormData();
                e.target.value = '';
                formData.append('input_file', file, file.name);

                try {
                  const response = await WrappedAxios.post(
                    `${USER_SOCIAL_LINK}/users/image`,
                    formData,
                    {
                      headers: {
                        'Content-Type': `application/form-data`,
                      },
                    }
                  );

                  console.log(response);
                } catch (e) {
                  alert(e.message);
                }

                const tmpPath = URL.createObjectURL(file);
                document
                  .getElementById('profilePicture')
                  .setAttribute('src', tmpPath);
                setImageChosen(true);
              }}
            />
            <Text
              style={{
                color: darkMode ? '#4adf79' : '#01874e',
                cursor: 'pointer',
                marginTop: isMobile ? 0 : 2,
              }}
              onClick={() => {
                const response = WrappedAxios.delete(
                  `${USER_SOCIAL_LINK}/users/image`
                ).then((res) => {
                  document
                    .getElementById('profilePicture')
                    .setAttribute('src', defaultPic);
                  setImageChosen(true);
                });
              }}
            >
              Remove Profile Picture
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="flex-start" marginTop={10}>
          <Box w={isMobile ? '30%' : '20%'} marginRight="15%">
            <Text
              textAlign="right"
              fontWeight="bold"
              style={{
                color: darkMode ? 'white' : 'black',
                fontSize: isMobile ? 14 : 'large',
              }}
            >
              Name
            </Text>
          </Box>

          <Box w="70%" alignItems="center" justifyContent="center">
            <input
              style={{
                fontSize: '16',
                fontWeight: 550,
                borderRadius: 6,
                backgroundColor: 'transparent',
                width: isMobile ? '95%' : '80%',
                border: `1px solid ${
                  fullnameError ? (darkMode ? '#EC5B55' : '#be2e2c') : '#d9d9d9'
                }`,
                height: 40,
                padding: 10,
                color: darkMode ? 'white' : 'black',
              }}
              id="nameInput"
              type="text"
              defaultValue={data && data['name']}
              readOnly={!editing}
              onChange={(e) => {
                setUserProfileObj({ ...userProfileObj, name: e.target.value });
              }}
            />
            {fullnameError && (
              <Text mt={2} style={{ color: darkMode ? '#EC5B55' : '#be2e2c' }}>
                Full Name cannot be empty
              </Text>
            )}
          </Box>
        </Flex>

        {separator()}

        <Flex justifyContent="flex-start" marginTop={10}>
          <Box w={isMobile ? '30%' : '20%'} marginRight="15%">
            <Text
              textAlign="right"
              fontWeight="bold"
              style={{
                color: darkMode ? 'white' : 'black',
                fontSize: isMobile ? 14 : 'large',
              }}
            >
              Username
            </Text>
          </Box>

          <Box w="70%" alignItems="center" justifyContent="center">
            <input
              style={{
                fontSize: '16',
                fontWeight: 550,
                borderRadius: 6,
                backgroundColor: 'transparent',
                width: isMobile ? '95%' : '80%',
                height: 40,
                padding: 10,
                textTransform: 'lowercase',
                border: `1px solid ${
                  usernameError ? (darkMode ? '#EC5B55' : '#be2e2c') : '#d9d9d9'
                }`,
                color: darkMode ? 'white' : 'black',
              }}
              id="usernameInput"
              type="text"
              defaultValue={data && data['username']}
              readOnly={!editing}
              onChange={(e) => {
                setUserProfileObj({
                  ...userProfileObj,
                  username: e.target.value,
                });
                setUsernameCheckerVar(e.target.value);
              }}
            />
            {usernameError !== '' && (
              <Text mt={2} style={{ color: darkMode ? '#EC5B55' : '#be2e2c' }}>
                {usernameError}
              </Text>
            )}
          </Box>
        </Flex>

        {separator()}

        <Flex justifyContent="flex-start" marginTop={10}>
          <Box w={isMobile ? '30%' : '20%'} marginRight="15%">
            <Text
              textAlign="right"
              fontWeight="bold"
              style={{
                color: darkMode ? 'white' : 'black',
                fontSize: isMobile ? 14 : 'large',
              }}
            >
              Description
            </Text>
          </Box>

          <Box w="70%" alignItems="center" justifyContent="center">
            <textarea
              rows={5}
              style={{
                fontSize: '16',
                fontWeight: 550,
                borderRadius: 6,
                backgroundColor: 'transparent',
                width: isMobile ? '95%' : '80%',
                padding: 10,
                border: '1px solid #d9d9d9',
                color: darkMode ? 'white' : 'black',
              }}
              id="descriptionInput"
              type="text"
              defaultValue={data && data['description']}
              readOnly={!editing}
              onChange={(e) =>
                setUserProfileObj({
                  ...userProfileObj,
                  description: e.target.value,
                })
              }
            />
          </Box>
        </Flex>

        {separator()}

        <Flex justifyContent="flex-start" marginTop={10}>
          <Box w={isMobile ? '30%' : '20%'} marginRight="15%">
            <Text
              textAlign="right"
              fontWeight="bold"
              style={{
                color: darkMode ? 'white' : 'black',
                fontSize: isMobile ? 14 : 'large',
              }}
            >
              Email ID
            </Text>
          </Box>

          <Box w="70%" alignItems="center" justifyContent="center">
            <input
              style={{
                fontSize: '16',
                fontWeight: 550,
                borderRadius: 6,
                backgroundColor: 'transparent',
                border: `1px solid ${
                  emailError ? (darkMode ? '#EC5B55' : '#be2e2c') : '#d9d9d9'
                }`,
                width: isMobile ? '95%' : '80%',
                height: 40,
                padding: 8,
                color: darkMode ? 'white' : 'black',
              }}
              id="emailInput"
              type="text"
              defaultValue={data && data['email']}
              readOnly={!editing}
              onChange={(e) =>
                setUserProfileObj({ ...userProfileObj, email: e.target.value })
              }
            />
            {emailError && (
              <Text mt={2} style={{ color: darkMode ? '#EC5B55' : '#be2e2c' }}>
                Please enter a valid email
              </Text>
            )}
          </Box>
        </Flex>

        {separator()}

        <Flex justifyContent="flex-start" marginTop={10}>
          <Box w={isMobile ? '30%' : '20%'} marginRight="15%">
            <Text
              textAlign="right"
              fontWeight="bold"
              style={{
                color: darkMode ? 'white' : 'black',
                fontSize: isMobile ? 14 : 'large',
              }}
            >
              Website
            </Text>
          </Box>

          <Box w="70%" alignItems="center" justifyContent="center">
            <input
              style={{
                fontSize: '16',
                fontWeight: 550,
                borderRadius: 6,
                backgroundColor: 'transparent',
                width: isMobile ? '95%' : '80%',
                height: 40,
                padding: 10,
                textTransform: 'lowercase',
                border: `1px solid ${'#d9d9d9'}`,
                color: darkMode ? 'white' : 'black',
              }}
              id="websiteInput"
              type="text"
              defaultValue={data && data['website_url']}
              readOnly={!editing}
              onChange={(e) => {
                setUserProfileObj({
                  ...userProfileObj,
                  website_url: e.target.value,
                });
              }}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
export default MyProfile;
