import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';
import axios from 'axios';

function* fetchSymbolSummaryData({ symbol }) {
  yield put({ type: 'FETCH_SUMMARY_DATA_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .get(`${SYMBOL_BASE_URL}/summary/${symbol.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_SUMMARY_DATA_SUCCESS',
      data: responseData,
      symbol: symbol.toUpperCase(),
    });
  } catch (error) {
    yield put({
      type: 'FETCH_SUMMARY_DATA_ERROR',
      error: error.message,
    });
  }
}

function* fetchSymbolSummaryPublicData({ symbol }) {
  yield put({ type: 'FETCH_SUMMARY_DATA_LOADING' });
  try {
    const responseData = yield axios
      .get(`${SYMBOL_BASE_URL}/summary-public/${symbol.toUpperCase()}`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_SUMMARY_DATA_SUCCESS',
      data: responseData,
      symbol: symbol.toUpperCase(),
    });
  } catch (error) {
    yield put({
      type: 'FETCH_SUMMARY_DATA_ERROR',
      error: error.message,
    });
  }
}


export function* watchSummaryData() {
  yield takeEvery('FETCH_SUMMARY_DATA', fetchSymbolSummaryData);
  yield takeEvery('FETCH_SUMMARY_PUBLIC_DATA', fetchSymbolSummaryPublicData);
}
