import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  BoxContent,
  BoxHeader,
  Description,
  StockSummary,
  SummaryHighlights,
} from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ReadMore from '../../ReadMore';
import {
  Highlight,
  KeyTextButton,
  Subtle,
  Wrapper,
  StyledButton,
} from '../../StyedComponents';
import LoadingIndicator from '../../LoadingIndicator';
import DomainIcon from '../../DomainIcon';
import { showErrorAlert } from '../../../../actions/alertAction';
import SectorIcon from '../../SectorIcon';
import SymbolLogo from '../../SymbolLogo';
import { getCountryNameFromCode } from '../../../../utils/CountryNameFinder';
import SimilarCompaniesModal from '../../SimilarCompaniesModal';
import DomainTerm from '../../DomainTerm';

const CompanySummary = ({
  loading,
  data: { symbol },
  error,
  showErrorAlert,
}) => {
  const descRef = React.useRef();
  const [descWidth, setDescWidth] = React.useState(0);
  const [
    openSimilarCompaniesModal,
    setOpenSimilarCompaniesModal,
  ] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    descRef.current &&
      !loading &&
      setDescWidth(Math.round(descRef.current.offsetWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    error && showErrorAlert(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <Wrapper className="rounded-md">
        {loading ? (
          <LoadingIndicator />
        ) : (
          symbol && (
            <React.Fragment>
              <div className="px-2 lg:px-6 pt-6">
                <div className="flex justify-between">
                  <div className="flex items-center mb-6 justify-between w-full">
                    <div className="flex">
                      <SymbolLogo logoUrl={symbol.logoUrl} />
                      <div className="mx-4">
                        <Highlight className="font-medium text-lg leading-normal">
                          {symbol.id}
                        </Highlight>
                        <Subtle className="text-sm w-48 md:w-full truncate">
                          {symbol.name}
                        </Subtle>
                      </div>
                    </div>
                    <StockSummary
                      as="button"
                      onClick={() => history.push(`/${symbol.id}/summary`)}
                    >
                      Stock Summary
                    </StockSummary>
                  </div>
                  <div className="flex flex-col lg:hidden w-28">
                    <BoxContent className="flex-row text-lg leading-normal">
                      <span>$</span>
                      <span>{symbol.currentPrice}</span>
                    </BoxContent>
                    <BoxHeader className="text-sm text-center">
                      Current Price
                    </BoxHeader>
                  </div>
                </div>
                <Description
                  ref={descRef}
                  data-testid="company-summary-description"
                >
                  <ReadMore text={symbol.description} width={descWidth} />
                </Description>
              </div>
              <SummaryHighlights>
                <Box className="hidden lg:flex flex-col items-center justify-center">
                  <BoxHeader>Current Price</BoxHeader>
                  <BoxContent>
                    <span>$</span>
                    <span>{symbol.currentPrice}</span>
                  </BoxContent>
                  {/* <div className="font-semibold">
                      <div className="flex items-center">
                        <Trend trend={true} color="#fff" />
                        <span className="mx-2 text-xs md:text-sm">
                          {symbol.absolutePriceChange} (
                          {symbol.relativePriceChange})
                        </span>
                        <span className="text-xs font-medium mt-0.5">
                          4:00 PM
                        </span>
                      </div>
                    </div> */}
                </Box>
                <Box className="flex flex-col items-center justify-center">
                  <BoxHeader className="hidden lg:block">Country</BoxHeader>
                  <BoxContent className="flex-col">
                    <div>
                      <img
                        alt="United States"
                        className="w-6 h-4"
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${symbol.country}.svg`}
                      />
                    </div>
                    <BoxHeader className="flex lg:hidden mt-2">
                      Country
                    </BoxHeader>
                    <div className="lg:ml-2">
                      {getCountryNameFromCode(symbol.country)}
                    </div>
                  </BoxContent>
                </Box>
                <Box className="flex flex-col items-center justify-center">
                  <BoxHeader className="hidden lg:block">Sector</BoxHeader>
                  <BoxContent className="flex-col">
                    <SectorIcon value={symbol.sector} />
                    <BoxHeader className="flex lg:hidden mt-2">
                      Sector
                    </BoxHeader>
                    <div className="lg:ml-2">{symbol.sector}</div>
                  </BoxContent>
                </Box>
                <Box
                  className="flex flex-col items-center justify-center"
                  lastBox={true}
                >
                  <BoxHeader className="hidden lg:block">
                    <DomainTerm infoKey="market_cap">Market Cap</DomainTerm>
                  </BoxHeader>
                  <BoxContent className="flex-col">
                    <DomainIcon value="marketcap" />
                    <BoxHeader className="flex lg:hidden mt-2">
                      <DomainTerm infoKey="market_cap">Market Cap</DomainTerm>
                    </BoxHeader>
                    <div className="lg:ml-2">{symbol.marketCap}</div>
                  </BoxContent>
                </Box>
              </SummaryHighlights>
            </React.Fragment>
          )
        )}
      </Wrapper>

      <div
        className="flex flex-col justify-center items-center lg:hidden text-center p-6"
        style={{ fontSize: '15px' }}
      >
        <KeyTextButton
          className="flex justify-center items-center mb-4"
          onClick={() => history.push(`/${symbol.id}/summary`)}
        >
          Stock Summary
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </KeyTextButton>
        <KeyTextButton
          className="flex justify-center items-center"
          onClick={() => setOpenSimilarCompaniesModal(true)}
        >
          Search Similar Companies
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </KeyTextButton>
      </div>
      {openSimilarCompaniesModal && (
        <SimilarCompaniesModal
          open={openSimilarCompaniesModal}
          setOpen={setOpenSimilarCompaniesModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.valuationState.data,
  error: state.valuationState.error,
  loading: state.valuationState.loading,
});

const mapDispatchToProps = {
  showErrorAlert: showErrorAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySummary);
