import React from 'react';
import { Box, Flex, Text, Center, Container } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import errorLight from '../../../public/images/error/error.webp';
import errorDark from '../../../public/images/error/error-d.webp';

function NotExists({ darkMode, isAndroid }) {
  return (
    <Box fontFamily="Plus Jakarta Display">
      <Box bg={darkMode ? '#161616' : '#f4f4f4'} minH="100vh">
        <Container mx="auto" maxW={'container.xl'}>
          <Center flexDirection="column">
            {!isAndroid && (
              <Box mt="40" mx="auto">
                <img
                  src={darkMode ? errorDark : errorLight}
                  height="250px"
                  width="300px"
                />
              </Box>
            )}
            <Text
              color={darkMode ? 'white' : 'black'}
              fontSize="x-large"
              mt={isAndroid ? '125' : '5'}
              // textAlign={isAndroid ? 'center' : ''}
              fontWeight="bold"
            >
              {isAndroid
                ? 'Switch to the Verde Finance app now to finish the brokerage integration.'
                : 'The page you are trying to reach does not exist.'}
            </Text>
            {!isAndroid && (
              <Link to="/">
                <Text
                  align={'center'}
                  color={darkMode ? 'white' : 'black'}
                  decoration="underline"
                  mt="2"
                  textUnderlineOffset={3}
                >
                  Click here and we will take you home.
                </Text>
              </Link>
            )}
            {/* <Text align={'center'} color={'gray'} mb="2">
            Please check the URL again.
          </Text> */}
          </Center>
        </Container>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotExists);
