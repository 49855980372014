import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import StockInfo from './StockInfo';
import SectionHeader from '../../../../shared/SectionHeader';

const StockInformation = ({ data, darkMode }) => {
  const chartData = data
    .map((raw) =>
      Object.keys(raw)
        .filter((key) => ['portfolioDate', 'portfolioValue'].includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {})
    )
    .map((item) => Object.values(item))
    .map((item) => [new Date(item[0].split(' ')[0]).getTime(), item[1]])
    .sort((a, b) => a[0] - b[0]);

  return (
    <>
      <div style={{ marginTop: '10px', paddingTop: '8px' }}>
        <StockInfo
          darkMode={darkMode}
          data={chartData}
          // growthMetrics={
          //   valuationData.growthMetrics.filter(
          //     (metric) => metric.market === 'Company'
          //   )[0]
          // }
        />
      </div>
    </>
  );
};

export default StockInformation;
