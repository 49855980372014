import React, { useState } from 'react';
import { Checkbox } from '@chakra-ui/checkbox';
import { ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { tabs as currentTabs } from './constants';
import { Button } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { columns } from '../MyVerde/utils';

const getSelectedColumns = (tabs) => {
  return tabs;
  const result = tabs.reduce((acc, item) => {
    if (item.selectedColumns)
      item.selectedColumns.forEach((column) => acc.add(column));
    return acc;
  }, new Set());
  return [...result];
};

const toggleAllColumns = (tab, flag, tabs) => {
  if (flag) {
    tab.selectedColumns = [...tab.columns];
    tab.selected = true;
  } else {
    tab.selectedColumns = [];
    tab.selected = false;
  }
  return tabs;
};

const toggleColumn = ({ tab, flag, column, tabs }) => {
  if (!tab.selectedColumns) {
    tab.selectedColumns = [];
  }
  if (flag) {
    tab.selectedColumns.push(column);
  } else {
    tab.selectedColumns.splice(
      tab.selectedColumns.map((item) => item.id).indexOf(column.id),
      1
    );
  }
  if (tab.selectedColumns.length === tab.columns.length) {
    tab.selected = true;
  } else {
    tab.selected = false;
  }
  return tabs;
};

function countSelected(tabs) {
  let count = 0;
  for (let i = 0; i < tabs.length; i++) {
    if (tabs[i].selected) {
      count++;
    }
  }
  return count;
}

export default function CustomTableColumnSelector({
  theme,
  tabs: currentTabs,
  setTabs: setCurrentTabs,
  closeModal = () => {},
  customTableCallback,
  darkMode,
}) {
  const [tabs, setTabs] = useState(currentTabs);
  const currentTheme = theme.screener_new.sidebar;
  const screenerTheme = theme.screener_new;

  const selectedColumns = tabs.reduce((acc, tab) => {
    return [...acc, ...(tab.selectedColumns ?? [])];
  }, []);

  return (
    <Box
      bg={darkMode ? '#161616' : '#f4f4f4'}
      px="2"
      py="4"
      className="h-full flex flex-col"
    >
      <Flex justifyContent="space-between">
        <Box px="2">
          <Text color={currentTheme.textColor} fontSize="medium">
            Customize Table
          </Text>
          <Text color={currentTheme.textColorSecondary} fontSize="small">
            {countSelected(tabs)} columns selected
          </Text>
        </Box>
        <Button
          background="transparent"
          color={darkMode ? 'white' : 'black'}
          borderRadius="50%"
          size="sm"
          border="1px solid"
          borderColor={theme.divider}
          onClick={closeModal}
        >
          <CloseIcon w={2} h={2} transform="scale(1.2)" />
        </Button>
      </Flex>

      <div style={{ marginBottom: '20px' }}>
        {tabs.map((tab) => {
          return tab.name !== 'Customize Table' ? (
            <>
              <div
                style={{
                  backgroundColor: darkMode ? '#262626' : 'white',
                  paddingBottom: tab.open ? '10px' : '0px',
                  borderRadius: '5px',
                }}
              >
                <Flex
                  alignItems="center"
                  cursor="pointer"
                  bg={darkMode ? '#262626' : 'white'}
                  key={tab.name}
                  p="2"
                  m="3"
                  borderRadius="5px"
                  justifyContent="space-between"
                  onClick={(e) => {
                    tab.open = !tab.open;
                    setTabs([...tabs]);
                    // const _tabs = toggleAllColumns(tab, e.target.checked, tabs);
                    // setTabs([..._tabs]);
                  }}
                >
                  <Flex gap="2">
                    <Checkbox
                      iconColor={theme.iconColor}
                      colorScheme={'green'}
                      borderColor={theme.inputCheckColor}
                      onChange={(e) => {
                        const _tabs = toggleAllColumns(
                          tab,
                          e.target.checked,
                          tabs
                        );

                        setTabs([..._tabs]);
                      }}
                      isChecked={tab.selected}
                    />
                    <Text color={currentTheme.textColor}>{tab.name}</Text>
                  </Flex>
                  {tab.open ? (
                    <ChevronDownIcon
                      color={currentTheme.textColor}
                      w="8"
                      h="8"
                      onClick={(e) => {}}
                    />
                  ) : (
                    <ChevronRightIcon
                      color={currentTheme.textColor}
                      w="8"
                      h="8"
                      onClick={(evt) => {
                        // evt.stopPropagation();
                        // tab.open = !tab.open;
                        // setTabs([...tabs]);
                      }}
                    />
                  )}
                </Flex>

                {tab.open
                  ? tab.columns.map((column) => (
                      <Flex
                        alignItems="center"
                        bg={darkMode ? '#343434' : '#f4f4f4'}
                        p="2"
                        m="2"
                        mx="10"
                        borderRadius="5px"
                        key={column.id}
                        justifyContent="space-between"
                      >
                        <Flex gap="2">
                          <Checkbox
                            iconColor={theme.iconColor}
                            colorScheme={'green'}
                            borderColor={theme.inputCheckColor}
                            isChecked={tab.selectedColumns
                              ?.map((item) => item.id)
                              ?.includes(column.id)}
                            onChange={(e) => {
                              const _tabs = toggleColumn({
                                tab,
                                flag: e.target.checked,
                                column,
                                tabs,
                              });

                              setTabs([..._tabs]);
                            }}
                          />
                          <Text color={currentTheme.textColor}>
                            {column.text}
                          </Text>
                        </Flex>
                      </Flex>
                    ))
                  : null}
              </div>
            </>
          ) : null;
        })}
      </div>

      <div
        className="flex-col h-10"
        style={{ marginTop: 'auto', marginLeft: 'auto', marginRight: '8px' }}
      >
        <Button
          borderRadius="20px"
          border="1px"
          mr="10px"
          borderColor={theme.screener_new.buttonBorderColor}
          bg={darkMode ? '#4adf79' : '#01874e'}
          color={darkMode ? 'black' : 'white'}
          onClick={() => {
            setCurrentTabs(getSelectedColumns(tabs));
            customTableCallback(countSelected(tabs) !== 0);
            closeModal();
          }}
          pr="40px"
          pl="40px"
        >
          Save
        </Button>
        <Button
          border="1px"
          borderColor={darkMode ? '#4adf79' : '#01874e'}
          color={darkMode ? '#4adf79' : '#01874e'}
          bg={theme.screener_new.secondaryButtonBg}
          borderRadius="20px"
          onClick={closeModal}
          pr="40px"
          pl="40px"
        >
          Cancel
        </Button>
      </div>
    </Box>
  );
}
