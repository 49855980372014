import { put, takeEvery } from 'redux-saga/effects';

import wrappedAxios from '../utils/WrappedAxios';
import { USER_SOCIAL_LINK } from '../config/default';

function* fetchFrillToken(action) {
  yield put({ type: 'FETCH_FRILL_TOKEN_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .post(
        ` https://8pwz0vhv7l.execute-api.us-east-1.amazonaws.com/prod`,
        action.payload
      )
      .then((response) => {
        return response.data;
      });
    yield put({ type: 'FETCH_FRILL_TOKEN_SUCCESS', payload: responseData });
  } catch (error) {
    yield put({ type: 'FETCH_FRILL_TOKEN_ERROR', error: error });
  }
}

function* fetchLoggedInUserData(action) {
  yield put({ type: 'FETCH_LOGGEDIN_USER_DATA_LOADING' });
  try {
    const responseData = yield wrappedAxios
      .get(`${USER_SOCIAL_LINK}/users`)
      .then((response) => {
        return response.data;
      });
    yield put({
      type: 'FETCH_LOGGEDIN_USER_DATA_SUCCESS',
      data: responseData,
    });
  } catch (error) {
    yield put({
      type: 'FETCH_LOGGEDIN_USER_DATA_ERROR',
      error: error.message,
    });
  }
}

export function* wathFetchingUserData() {
  yield takeEvery('FETCH_FRILL_TOKEN', fetchFrillToken);
  yield takeEvery('FETCH_LOGGEDIN_USER_DATA', fetchLoggedInUserData);
}
