import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import {
  ContentWrapper,
  Subtle,
  Highlight,
  ColoredContentText,
} from '../StyedComponents';
import LoadingIndicator from '../LoadingIndicator';
import { getLogoUrl } from '../../../utils/LogoUtils';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import SymbolLogo from '../SymbolLogo';
import DomainIcon from '../DomainIcon';
import SectionHeader from '../SectionHeader';

export const SimilarCompaniesWrapper = styled.div``;

export const StyledSpan = styled.span`
  ${tw`font-medium ml-1 no-underline`};
  color: ${(props) => props.theme.keyHighlight};
`;

export const CompanyDivider = styled.div`
  ${tw`w-full my-4 h-0`};
  border-top: solid 1px ${(props) => props.theme.similarCompanies.divider};
`;

export const BackTo = styled.div`
  ${tw`flex lg:hidden justify-center items-center font-medium py-4`};
  color: ${(props) => props.theme.keyHighlight};
`;

const SimilarCompanies = ({
  darkMode,
  symbol,
  data: companies,
  loading,
  onCloseModal = () => {},
  subscriptionStatus,
}) => {
  return (
    <>
      <SectionHeader value="Similar Companies" />
      <div
        className="flex"
        style={{
          overflowX: 'auto',
          marginTop: '10px',
          justifyContent: 'space-between',
        }}
      >
        {loading ? (
          <LoadingIndicator />
        ) : companies.length === 0 ? (
          <Highlight className="text-sm text-center">
            No matching company name found.
          </Highlight>
        ) : (
          companies.map((company, index) => (
            <React.Fragment key={index}>
              <div
                className="text-sm font-medium"
                style={{
                  backgroundColor: !darkMode ? '#F9F9F9' : '#343434',
                  marginRight: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  width: '150px',
                }}
              >
                <Link
                  to={
                    subscriptionStatus.active
                      ? `/stocks/${company.symbol_market}`
                      : `/stocks-basic/${company.symbol_market}`
                  }
                  className="no-underline"
                  onClick={() => {
                    setTimeout(onCloseModal, 500);
                  }}
                >
                  <div>
                    <div>
                      <SymbolLogo logoUrl={getLogoUrl(company.symbol)} />
                      <div style={{ marginTop: '5px' }}>
                        <ColoredContentText
                          className="font-medium"
                          style={{ fontWeight: 'bold' }}
                        >
                          {company.symbol}
                        </ColoredContentText>

                        <Subtle
                          className="text-xs truncate 2xl:w-52 xl:w-36 md:w-24 w-44"
                          color={darkMode ? 'white' : 'black'}
                        >
                          {company.symbolName}
                        </Subtle>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: 'flex', marginTop: '8px' }}>
                    <div>
                      {company.current_price && (
                        <div style={{ display: 'flex' }}>
                          <h3
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: darkMode ? 'white' : 'black',
                            }}
                          >
                            ${company.verde_score}
                          </h3>
                        </div>
                      )}
                    </div>

                    <div style={{ marginLeft: 'auto' }}>
                      {company.verde_score && (
                        <div style={{ display: 'flex' }}>
                          <img
                            src={VerdeScoreSvg}
                            alt=""
                            className="w-4 mb-0.5 mr-1"
                          />
                          <h4
                            style={{
                              fontSize: '16px',

                              color: darkMode ? 'white' : 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            {company.verde_score}
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </React.Fragment>
          ))
        )}
      </div>
      {/* <div className="text-center text-sm">
          <KeyTextButton
            onClick={() => {
              history.push({
                pathname: '/screener',
                state: {
                  sectors: [
                    {
                      value: symbol.sector,
                      label: symbol.sector,
                    },
                  ],
                },
              });
            }}
          >
            View All
          </KeyTextButton>
        </div> */}

      <BackTo>
        <div>
          <DomainIcon value="arrow-left" />
        </div>
        <div className="ml-3 cursor-pointer" onClick={onCloseModal}>
          Back To {symbol && symbol.id ? symbol.id : ''}
        </div>
      </BackTo>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.similarCompaniesState.data,
  symbol: state.valuationState.data.symbol,
  loading: state.similarCompaniesState.loading,
  darkMode: state.coreState.darkMode,
  subscriptionStatus: state.coreState.subscriptionStatus,
});

export default connect(mapStateToProps)(SimilarCompanies);
