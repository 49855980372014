import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hocs/AuthProvider';
import { showPageLoading } from '../../../actions/coreAction';
import { useMediaQuery } from '@chakra-ui/react';
import {
  USER_SOCIAL_LINK,
  PLAID_GENERATE_TOKEN,
} from '../../../config/default';
import { LIGHT_THEME } from '../../core/theme';
import { Box } from '@chakra-ui/react';
import WrappedAxios from '../../../utils/WrappedAxios';
import { connect } from 'react-redux';
import MyVerdeRevamp from '../MyVerdeRevamp/MyVerdeRevamp';
import MyVerdeRevampMobile from '../MyVerdeRevamp/MyVerdeRevampMobile';
import UserProfile from './UserProfile';
import NotExists from '../NotExists';
import LoadingIndicator from '../../shared/LoadingIndicator';
import VerdeHeader from '../../shared/VerdeHeader';
import { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getMyVerde } from '../../../actions/my-verde';

const UserView = ({ darkMode, showPageLoading, socialUsername, socialEmail }) => {
  const params = useParams();
  const { authUser } = useAuth();
  const [sameUser, setSameUser] = useState(false);
  const [userExists, setUserExists] = useState(true);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  showPageLoading(
    !userExists
      ? false
      : sameUser
      ? allData.length === 0
      : JSON.stringify(profileData) === '{}'
  );
  useEffect(() => {
    // const response3 = WrappedAxios.get(
    //   `${USER_SOCIAL_LINK}/users/${authUser.username}`
    // )
    //   .then((response3) => {
    //     setProfileData(response3.data);
    //     if (response3.data.username === params.user) {
    //       setSameUser(true);
    //       showPageLoading(false)
    //     }
    //   })
    //   .catch((error) => {
    //     alert(error.message);
    //   });
    setSameUser(false);
    const response4 = WrappedAxios.get(
      `${USER_SOCIAL_LINK}/users/${params.user}`
    )
      .then((response4) => {
        setProfileData(response4.data);
        if (authUser.username === response4.data.id) {
          setSameUser(true);
        }
        if (response4.data.username === params.user) {
          showPageLoading(false);
        }
        setUserExists(true);
      })
      .catch((error) => {
        setUserExists(false);
        showPageLoading(false);
      });
  }, [authUser.username, params.user, showPageLoading]);

  useEffect(() => {
    if (sameUser) {
      const response = WrappedAxios.get(
        `${PLAID_GENERATE_TOKEN}/userholdings?all_portfolios=true`
      )
        .then((response) => {
          setAllData(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      const response2 = WrappedAxios.get(
        `${PLAID_GENERATE_TOKEN}/userholdings?all_portfolios=false`
      )
        .then((response2) => {
          setData(response2.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }, [sameUser]);

  return (
    <div
      style={{
        backgroundColor: darkMode ? '#161616' : '#f4f4f4',
      }}
    >
      {/* {JSON.stringify(profileData) !== '{}' && <VerdeHeader setTheme={setTheme} auth={true}/>} */}
      {userExists ? (
        JSON.stringify(profileData) !== '{}' &&
        (sameUser ? (
          isMobile ? (
            <MyVerdeRevampMobile
              profileData={profileData}
              allData={allData}
              data={data}
            />
          ) : (
            <MyVerdeRevamp
              profileData={profileData}
              allData={allData}
              data={data}
            />
          )
        ) : (
          <UserProfile profileData={profileData} email={socialEmail} username={socialUsername}/>
        ))
      ) : (
        <NotExists />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
  socialUsername: state.myVerde.username,
  socialEmail: state.myVerde.email
});

const mapDispatchToProps = {
  showPageLoading,
  getMyVerde
};

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
