export const showPageLoading = (loading) => ({
  type: 'LOADING_PAGE',
  loading,
});

export const setLoginPhase = (loginPhase) => ({
  type: 'CHANGE_LOGIN_PHASE',
  loginPhase,
});

export const resetLoginPhase = () => ({
  type: 'CHANGE_LOGIN_PHASE',
  loginPhase: '',
});

export const setOpenChangePassword = (openChangePassword) => ({
  type: 'CHANGE_PASSWORD',
  openChangePassword,
});

export const resetAllState = () => ({
  type: 'RESET_ALL_STATE',
});

export const setDarkMode = (darkMode) => ({
  type: 'SET_DARK_MODE',
  darkMode,
});

export const getFrillToken = (payload) => ({
  type: 'FETCH_FRILL_TOKEN',
  payload,
});

export const setSubscriptionStatusLoading = (payload) => ({
  type: 'SET_SUBSCRIPTION_STATUS_LOADING',
  payload,
});

export const setSubscriptionStatus = (payload) => ({
  type: 'SET_SUBSCRIPTION_STATUS',
  payload,
});

export const setOpenYourVerdePlanModal = (payload) => ({
  type: 'SET_OPEN_YOUR_VERDE_PLAN_MODAL',
  payload,
});

export const setOpenJoinVerdePlanModal = (payload) => ({
  type: 'SET_OPEN_JOIN_VERDE_PLAN_MODAL',
  payload,
});

export const setForgotPasswordEmail = (payload) => ({
  type: 'SET_FORGOT_PASSWORD_EMAIL',
  payload,
});
