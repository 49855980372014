import React from 'react';
import { Text } from '@chakra-ui/react';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { SectionDivider } from '../../../StyedComponents';
import FallBackChartCard from './FallBackChartCard';
import ChartCard from './ChartCard';
import { useMediaQuery } from '@chakra-ui/react';
import RiskReturn from './RiskReturnChart';
import RiskPositives from '../../../RiskPositives';
import SectionHeader from '../../../SectionHeader';

export default function AssetClassification({
  data,
  assetData,
  darkMode,
  label,
  insightsData,
  currentKey,
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  data.account_holdings = data.account_holdings?.filter(
    (elem) => elem['symbol'] !== null
  );
  const currentPortfolioInsights = insightsData[currentKey]
    ? insightsData[currentKey]
    : [];

  return (
    <div>
      <div
        style={{
          display: !isMobile ? 'flex' : 'block',
          paddingLeft: isMobile ? '15px' : '',
          paddingRight: isMobile ? '15px' : '',
        }}
      >
        {isMobile && <SectionHeader value="Sectors" />}
        <div
          style={{
            borderRadius: '5px',
            backgroundColor: darkMode ? '#262626' : 'white',
            marginRight: '25px',
            marginTop: isMobile ? '20px' : '',
            padding: '20px',
            width: !isMobile ? '50%' : '100%',
            marginBottom: '15px',
          }}
        >
          {data && (!assetData || !assetData['SECTOR']) ? (
            <FallBackChartCard
              data={data.account_holdings ? data.account_holdings : []}
              darkMode={darkMode}
              title="Sectors"
              keyTitle="sector"
              isNested={false}
              symbolHidden={true}
            />
          ) : (
            <ChartCard
              data={assetData['SECTOR'] && assetData['SECTOR']}
              darkMode={darkMode}
              title="Sectors"
              keyTitle="sector"
              isNested={false}
              symbolHidden={true}
            />
          )}

          <RiskPositives
            // positives={currentPortfolioInsights.filter((elem) => elem['type'] === "SECTOR" && elem['insight'].includes('negative')).map(elem => elem.insight)}
            negatives={currentPortfolioInsights
              .filter((elem) => elem['type'] === 'SECTOR')
              .map((elem) => elem.insight)}
            isInsight={true}
          />
        </div>

        {isMobile && <SectionHeader value="Tickers" />}

        <div
          style={{
            borderRadius: '5px',
            backgroundColor: darkMode ? '#262626' : 'white',
            padding: '20px',
            marginTop: isMobile ? '20px' : '',
            width: !isMobile ? '48%' : '100%',
            marginBottom: '15px',
          }}
        >
          {data && (!assetData || !assetData['TICKER']) ? (
            <FallBackChartCard
              data={data.account_holdings ? data.account_holdings : []}
              innerData={data.account_holdings ? data.account_holdings : []}
              darkMode={darkMode}
              title="Tickers"
              isNested={true}
              symbolHidden={true}
              nestedKeyTitle={['marketcap_mln', 'symbol']}
            />
          ) : (
            <ChartCard
              data={assetData['TICKER'] && assetData['TICKER']}
              innerData={data.account_holdings ? data.account_holdings : []}
              darkMode={darkMode}
              title="Tickers"
              isNested={true}
              symbolHidden={true}
              nestedKeyTitle={['marketcap_mln', 'symbol']}
            />
          )}

          <RiskPositives
            // positives={currentPortfolioInsights.filter((elem) => elem['type'] === "SECTOR" && elem['insight'].includes('negative')).map(elem => elem.insight)}
            negatives={currentPortfolioInsights
              .filter(
                (elem) =>
                  elem['type'] === 'TICKER' || elem['type'] === 'ASSET_CLASS'
              )
              .map((elem) => elem.insight)}
            isInsight={true}
          />
        </div>
      </div>

      <div
        style={{
          display: !isMobile ? 'flex' : 'block',
          paddingLeft: isMobile ? '15px' : '',
          paddingRight: isMobile ? '15px' : '',
        }}
      >
        {isMobile && <SectionHeader value="Industry" />}

        <div
          style={{
            borderRadius: '5px',
            width: !isMobile ? '50%' : '100%',
            backgroundColor: darkMode ? '#262626' : 'white',
            marginRight: '25px',
            padding: '20px',
            marginTop: '15px',
            marginBottom: '15px',
          }}
        >
          {data && (!assetData || !assetData['INDUSTRY']) ? (
            <FallBackChartCard
              data={data.account_holdings ? data.account_holdings : []}
              darkMode={darkMode}
              title="Industry"
              keyTitle="industry"
              isNested={false}
              symbolHidden={true}
            />
          ) : (
            <ChartCard
              data={assetData['INDUSTRY'] && assetData['INDUSTRY']}
              darkMode={darkMode}
              title="Industry"
              keyTitle="industry"
              isNested={false}
              symbolHidden={true}
            />
          )}
          <RiskPositives
            // positives={currentPortfolioInsights.filter((elem) => elem['type'] === "SECTOR" && elem['insight'].includes('negative')).map(elem => elem.insight)}
            negatives={currentPortfolioInsights
              .filter((elem) => elem['type'] === 'INDUSTRY')
              .map((elem) => elem.insight)}
            isInsight={true}
          />
        </div>

        {isMobile && <SectionHeader value="Country" />}

        <div
          style={{
            borderRadius: '5px',
            width: !isMobile ? '48%' : '100%',
            backgroundColor: darkMode ? '#262626' : 'white',
            padding: '20px',
            marginTop: '15px',
            marginBottom: '15px',
          }}
        >
          {data && (!assetData || !assetData['CURRENCY']) ? (
            <FallBackChartCard
              data={data.account_holdings ? data.account_holdings : []}
              innerData={data.account_holdings ? data.account_holdings : []}
              darkMode={darkMode}
              title="Country"
              isNested={true}
              symbolHidden={true}
              nestedKeyTitle={['iso_currency_code', 'country']}
            />
          ) : (
            <ChartCard
              data={assetData['COUNTRY'] && assetData['COUNTRY']}
              innerData={assetData['CURRENCY'] && assetData['CURRENCY']}
              darkMode={darkMode}
              title="Country"
              isNested={true}
              symbolHidden={true}
              nestedKeyTitle={['iso_currency_code', 'country']}
            />
          )}

          <RiskPositives
            // positives={currentPortfolioInsights.filter((elem) => elem['type'] === "SECTOR" && elem['insight'].includes('negative')).map(elem => elem.insight)}
            negatives={currentPortfolioInsights
              .filter(
                (elem) =>
                  elem['type'] === 'COUNTRY' || elem['type'] === 'CURRENCY'
              )
              .map((elem) => elem.insight)}
            isInsight={true}
          />
        </div>
      </div>

      {/* <div
        style={{
          borderRadius: '5px',
          backgroundColor: darkMode ? '#262626' : 'white',
          marginRight: '25px',
          padding: '20px',
          width: !isMobile ? '50%' : '100%',
          marginTop: '25px',
          width: '100%',
        }}
      >
        <RiskReturn darkMode={darkMode} />
      </div> */}
    </div>
  );
}
