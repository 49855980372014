const initialState = {
  message: undefined,
  alertType: 'default',
};

const AlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ALERT': {
      return {
        ...state,
        message: action.message,
        alertType: action.alertType,
      };
    }

    case 'REMOVE_ALERT': {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default AlertReducer;
