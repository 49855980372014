import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import AreaChart from '../../../AreaChart';
import { getAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import BulletPoints from '../../../BulletPoints';

const TTMDividendYield = ({ data, insights }) => {
  const averagePositives = insights?.dividendYield?.average?.positives
    ? insights?.dividendYield?.average?.positives
    : [];
  const industryPositives = insights?.dividendYield?.industry?.positives
    ? insights?.dividendYield?.industry?.positives
    : [];
  const marketPositives = insights?.dividendYield?.market?.positives
    ? insights?.dividendYield?.market?.positives
    : [];
  const averageNegatives = insights?.dividendYield?.average?.negatives
    ? insights?.dividendYield?.average?.negatives
    : [];
  const industryNegatives = insights?.dividendYield?.industry?.negatives
    ? insights?.dividendYield?.industry?.negatives
    : [];
  const marketNegatives = insights?.dividendYield?.market?.negatives
    ? insights?.dividendYield?.market?.negatives
    : [];

  return (
    <>
      <SectionHeader
        value="TTM Dividend Yield"
        className="pb-6"
        infoKey="dividend_yield"
      />
      <BorderedChartContainer className="py-4">
        <AreaChart
          data={getAreaChartData(data, ['dividend_yield_ttm', 'evaluated_on'])}
          name="TTM Dividend Yield"
          color="#826CFF"
          gradientColor="#826CFF"
          yAxisLabelSuffix="%"
          valueType="percent"
        />
      </BorderedChartContainer>

      {(averagePositives.length > 0 ||
        industryPositives.length > 0 ||
        marketPositives.length > 0) && (
        <BulletPoints
          title="Positives"
          points={[
            ...averagePositives,
            ...industryPositives,
            ...marketPositives,
          ]}
          isPositive={true}
        />
      )}
      {(averageNegatives.length > 0 ||
        industryNegatives.length > 0 ||
        marketNegatives.length > 0) && (
        <BulletPoints
          title="Risks"
          points={[
            ...averageNegatives,
            ...industryNegatives,
            ...marketNegatives,
          ]}
          isPositive={false}
        />
      )}
    </>
  );
};

export default TTMDividendYield;
