import styled from 'styled-components/macro';
import { ColoredContentText, HollowButton } from '../../StyedComponents';
import tw from 'twin.macro';
import { LIGHT_THEME as Theme } from '../../../core/theme';
export const TableContainer = styled.div`
  ${tw`overflow-x-auto text-xs`}
  table {
    border-spacing: 0;

    background-color: ${(props) => props.theme.table.background};
    border: 1px solid ${(props) => props.theme.table.border};
    tr {
      color: ${(props) => props.theme.table.color};
      padding: 0 20px;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      height: 50px;
      white-space: nowrap;
    }
    th,
    td {
      padding: 20px 10px;
      border-bottom: 1px solid ${(props) => props.theme.table.border};
      background: ${(props) => props.theme.table.background};
      :last-child {
        border-right: 0;
      }
    }
    tbody tr:nth-child(2n) {
      background: ${(props) => props.theme.table.evenRow};
      th,
      td {
        background: inherit;
      }
    }
    a {
      color: ${(props) => props.theme.content.color};
    }
  }
`;

export const PaginationButton = styled(HollowButton)`
  ${tw`w-20 md:w-28 px-1 h-10 py-0.5`}
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  border: ${(props) =>
    props.disabled
      ? `1.4px solid ${Theme.disabled}`
      : `1.4px solid ${Theme.keyHighlight}`};
  color: ${(props) => (props.disabled ? Theme.disabled : Theme.keyHighlight)};
`;

export const StyledTableHead = styled.thead`
  background-color: ${(props) => props.theme.screener.headerBgColor};
  border: 1px solid ${(props) => props.theme.table.border};
  border-left: 0;
  border-right: 0;
  color: ${(props) => props.theme.screener.color};
`;

export const StyledTh = styled.th`
  background-color: ${(props) => props.theme.screener.headerBgColor};
`;

export const StyledTd = styled.td`
  background-color: ${(props) => props.theme.screener.headerBgColor};
`;

export const TableColumn = styled(ColoredContentText)`
  ${tw`text-sm font-medium flex justify-center text-center`}
`;
