const initialState = {
  data: [],
  loading: true,
  error: null,
};

const StockReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_STOCK_INFO_LOADING': {
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    }

    case 'FETCH_STOCK_INFO_SUCCESS': {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }

    case 'FETCH_STOCK_INFO_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.error,
        data: [],
      };
    }

    default:
      return state;
  }
};

export default StockReducer;
