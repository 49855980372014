import { Box, Text, Container, Center } from '@chakra-ui/layout';
import React from 'react';
import mainImg from '../../../public/images/maintenance/comp.webp';
import mainImgD from '../../../public/images/maintenance/comp-d.webp';
import { useSelector } from 'react-redux';
import { useAuth } from '../../hocs/AuthProvider';
import VerdeHeader from '../../shared/VerdeHeader';
const pageStyles = {
  light: {
    bg: '#f1f7fb',
    textColor: 'black',
    bgImg: mainImg,
  },
  dark: {
    bg: '##272c34',
    textColor: 'white',
    bgImg: mainImgD,
  },
};
export default function MaintenancePage({ setTheme }: { setTheme: Function }) {
  const { authUser } = useAuth();
  const darkMode = useSelector((state: any) => state.coreState.darkMode);
  const styles = darkMode ? pageStyles.dark : pageStyles.light;
  return (
    <Box fontFamily="Plus Jakarta Display">
      <VerdeHeader
        setTheme={setTheme}
        auth={authUser ? true : false}
        hiddenSearch
      ></VerdeHeader>
      <Box bg={styles.bg} minH="100vh">
        <Container mx="auto" maxW="container.xl">
          <Center flexDirection="column">
            <Box mt="40" mx="auto">
              <img src={styles.bgImg} height="250px" width="300px" />
            </Box>
            <Text
              color={styles.textColor}
              fontSize="x-large"
              mt="10"
              fontWeight="bold"
            >
              Surf’s out, dude
            </Text>
            <Text color={styles.textColor} mt="2">
              Verde is down for maintenance, Time to chillax
            </Text>
          </Center>
        </Container>
      </Box>
    </Box>
  );
}
