import { Flex, Box, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import numeral from 'numeral';
import SectionHeader from '../../../SectionHeader';
import InsidersTable from '../../../InsidersTable';
import LoadingSectionIndicator from '../../../LoadingSectionIndicator';

const feeColumns = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: (row) => <div>{row.value}</div>,
  },
  {
    Header: 'Yearly Fee',
    accessor: 'yearly_fee',
    Cell: (row) => <div>${numeral(row.value).format('0,0.00')}</div>,
  },
  {
    Header: 'Weight',
    accessor: 'weight',
    Cell: (row) => <div>{numeral(row.value).format('0,0.00')}%</div>,
  },
  {
    Header: 'Expense Ratio',
    accessor: 'percent_fee',
    Cell: (row) => <div>{numeral(row.value).format('0,0.00')}%</div>,
  },
];

export default function Fees(props) {
  const { data, darkMode } = props;
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const fees = data['other_fee_info'] ? data['other_fee_info'] : [];

  if (!data['other_fee_info']) return <LoadingSectionIndicator />;

  return (
    <Box
      bg={darkMode ? '#262626' : !isMobile ? 'white' : 'rgb(246,248,250)'}
      p="6"
      my="6"
    >
      <Flex alignItems={'center'} direction={'column'}>
        <SectionHeader
          value={`Current Fee Burden: $${data?.total_fees}`}
          className="pb-6"
        />
        <Box minW={'100%'}>
          <InsidersTable darkMode={darkMode} columns={feeColumns} data={fees} />
        </Box>
      </Flex>
    </Box>
  );
}
