import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import StockInfo from './StockInfo';
import SectionHeader from '../../../SectionHeader';
import DomainTerm from '../../../DomainTerm';

const Metric = styled.div`
  ${tw`flex flex-col items-center font-medium`};
`;

const MetricHeader = styled.div`
  ${tw`text-sm`};
  color: ${(props) => props.theme.metrics.header};
`;

const MetricValue = styled.div`
  ${tw`text-base`};
  color: ${(props) => props.theme.metrics.value};
`;

const StockInformation = ({ data, valuationData }) => {
  const chartData = data
    .map((raw) =>
      Object.keys(raw)
        .filter((key) => ['price_on_date', 'adjusted_price'].includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {})
    )
    .map((item) => Object.values(item))
    .map((item) => [new Date(item[0].split(' ')[0]).getTime(), item[1]])
    .sort((a, b) => a[0] - b[0]);
  return (
    <>
      <SectionHeader value="Stock Information" />
      <BorderedChartContainer className="my-8 pt-4">
        <StockInfo
          data={chartData}
          growthMetrics={
            valuationData.growthMetrics.filter(
              (metric) => metric.market === 'Company'
            )[0]
          }
        />
      </BorderedChartContainer>
      <div className="md:flex md:justify-evenly grid grid-cols-3 gap-6">
        <Metric>
          <MetricValue>${valuationData.symbol.fiftyTwoWeekHigh}</MetricValue>
          <MetricHeader>52-Week High</MetricHeader>
        </Metric>
        <Metric>
          <MetricValue>${valuationData.symbol.fiftyTwoWeekLow}</MetricValue>
          <MetricHeader>52-Week Low</MetricHeader>
        </Metric>
        <Metric>
          <MetricValue>${valuationData.symbol.earningsPerShare}</MetricValue>
          <MetricHeader>
            <DomainTerm infoKey="eps">EPS</DomainTerm>
          </MetricHeader>
        </Metric>
        <Metric>
          <MetricValue>
            {valuationData.symbol.trailingPriceToEarnings}x
          </MetricValue>
          <MetricHeader>
            <DomainTerm infoKey="pe">PE</DomainTerm>
          </MetricHeader>
        </Metric>
        <Metric>
          <MetricValue>{valuationData.symbol.forwardYield}%</MetricValue>
          <MetricHeader>
            <DomainTerm infoKey="dividend_yield">Dividend Yield</DomainTerm>
          </MetricHeader>
        </Metric>
      </div>
    </>
  );
};

export default StockInformation;
