import React from 'react';
import { Text } from '@chakra-ui/react';
import styled from 'styled-components';
import tw from 'twin.macro';
import StockInformation from './PerformanceChart';
import LoadingIndicator from '../../../../LoadingIndicator';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';

const Metric = styled.div`
  ${tw`flex flex-col items-center font-medium`};
`;

const PortfolioPerformance = ({ darkMode, data }) => {
  const List = [
    {
      title: '52 Week High',
      value: 'VALUE',
    },
    {
      title: '52 Week Low',
      value: 'VALUE',
    },
    {
      title: 'EPS',
      value: 'VALUE',
    },
    {
      title: 'PE Ratio',
      value: 'VALUE',
    },
    {
      title: 'Dividend Yield',
      value: 'VALUE',
    },
  ];

  return (
    <>
      {data.length > 0  ? (
        <StockInformation data={data ? data : []} darkMode={darkMode} />
      ) : (
        <LoadingIndicator />
      )}
      {/* <Flex justifyContent="space-evenly" marginTop="30px" flexWrap="wrap">
        {List.map((elem) => {
          return (
            <Metric>
              <div
                style={{
                  backgroundColor: darkMode ? '#343434' : '#F9F9F9',
                  padding: '20px',
                  width: '125px',
                  textAlign: 'center',
                }}
              >
                <h6
                  style={{
                    fontWeight: 'bold',
                    color: darkMode ? 'white' : 'black',
                  }}
                >
                  {elem.value}
                </h6>
                <h6 style={{ fontSize: '13px', color: '#9F9F9F' }}>
                  {elem.title}
                </h6>
              </div>
            </Metric>
          );
        })}
      </Flex> */}
    </>
  );
};

export default PortfolioPerformance;
