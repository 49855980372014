import styled from 'styled-components/macro';
import React from 'react';
import tw from 'twin.macro';
import VerdeLogoSvg from '../../../public/images/landing-page/verde-logo-footer.svg';
import Tiktok from '../../../public/images/socialmedia/launch/tiktok.svg';
import Instagram from '../../../public/images/socialmedia/launch/instagram.svg';
import Twitter from '../../../public/images/socialmedia/launch/twitter.svg';
import Linkedin from '../../../public/images/socialmedia/launch/linkedin.svg';
import PlayStore from '../../../public/images/GetOnGooglePlay.png';
import AppleStore from '../../../public/images/GetOnAppleStore.png';
import { Box } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
export const SocialMediaLink = styled.a`
  ${tw`mx-2`};
`;
export const CopyRight = styled.div`
  color: #bebebe;
`;

const SocialLink = (props) => {
  return <SocialMediaLink {...props} target="_blank"></SocialMediaLink>;
};
export const FooterText = styled.div`
  color: #dcdcdc;
`;
export const Footer = styled.div`
  ${tw`md:py-10 py-10`};
`;
export default function FooterComp({ isDark }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Footer className={`bg-black px-12`} isDark={isDark}>
      <div className="flex justify-between items-center md:flex-row flex-col container mx-auto">
        <div className="flex justify-between">
          <div className="flex flex-col items-center md:items-start">
            <img className="w-20" src={VerdeLogoSvg} alt="" />
            <FooterText className="text-white text-sm mt-6 md:text-left text-center">
              2021 Guadalupe Street, Suite 260, <br />
              Austin, Texas, 78705
            </FooterText>
          </div>
        </div>
        <CopyRight className="md:mt-0 mt-6 text-sm flex-1 text-center">
          Copyright @ 2022. All rights reserved.
        </CopyRight>
        <div>
          <div className="logos flex items-center md:mt-0 mt-6">
            <SocialLink href="https://www.tiktok.com/@verde.finance">
              <img src={Tiktok}></img>
            </SocialLink>
            <SocialLink href="https://instagram.com/verde.finance">
              <img src={Instagram}></img>
            </SocialLink>
            <SocialLink href="https://twitter.com/VerdeFinance">
              <img src={Twitter}></img>
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/verdefinance">
              <img src={Linkedin}></img>
            </SocialLink>
          </div>
          {!isMobile && (
            <Box mt={3}>
              <a
                href="https://apps.apple.com/us/app/verde-finance/id1630554888"
                target="_blank"
              >
                <div
                  style={{
                    height: 50,
                    width: 150,
                    cursor: 'pointer',
                    borderRadius: 10,
                    marginBottom: 12,
                    boxShadow:
                      '0 4px 4px 0 rgba(255, 255, 255, 0.2), 0 6px 100px 0 rgba(255, 255, 255, 0.19)',
                  }}
                >
                  <img src={AppleStore}></img>
                </div>
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.verde.finance"
              >
                <div
                  style={{
                    height: 50,
                    width: 150,
                    cursor: 'pointer',
                    marginTop: 12,
                    borderRadius: 10,
                    boxShadow:
                      '0 4px 4px 0 rgba(255, 255, 255, 0.2), 0 6px 100px 0 rgba(255, 255, 255, 0.19)',
                  }}
                >
                  <img src={PlayStore}></img>
                </div>
              </a>
            </Box>
          )}
        </div>
      </div>
    </Footer>
  );
}
