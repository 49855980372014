import { combineReducers } from 'redux';
import SymbolReducer from './symbolReducer';
import StockReducer from './stockReducer';
import PastPerformanceReducer from './pastPerformanceReducer';
import AlertReducer from './alertReducer';
import ScreenerReducer from './screenerReducer';
import ValuationReducer from './valuationReducer';
import SimilarCompaniesReducer from './similarCompaniesReducer';
import CoreReducer from './coreReducer';
import InsightsReducer from './insightsReducer';
import SymbolsNewsReducer from './newsReducer';
import FutureGrowthReducer from './futureGrowthReducer';
import DividendReducer from './dividendReducer';
import InsidersReducer from './insidersReducer';
import MyVerdeReducer from './myVerdeReducer';
import SummaryReducer from './summaryReducer';
import RealTimeReducer from './realTimeReducer';

const appReducer = combineReducers({
  symbolState: SymbolReducer,
  stockHistoryState: StockReducer,
  pastPerformanceState: PastPerformanceReducer,
  alertState: AlertReducer,
  screenerState: ScreenerReducer,
  valuationState: ValuationReducer,
  similarCompaniesState: SimilarCompaniesReducer,
  coreState: CoreReducer,
  symbolInsightsState: InsightsReducer,
  symbolNewsState: SymbolsNewsReducer,
  futureGrowthState: FutureGrowthReducer,
  dividendState: DividendReducer,
  insidersState: InsidersReducer,
  myVerde: MyVerdeReducer,
  summaryState: SummaryReducer,
  realTimeState: RealTimeReducer,
});

const RootReducer = (state, action) => {
  if (action.type === 'RESET_ALL_STATE') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default RootReducer;
