import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { HollowGreenButton, ColoredContentText } from '../StyedComponents';

export const Header = styled(ColoredContentText)`
  ${tw`text-lg font-medium`};
`;

export const ActionButton = styled(HollowGreenButton)`
  ${tw`py-2 px-8 mx-2`}
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.content.background};
  border: 1px solid ${(props) => props.theme.content.border};
`;
