import React, { useRef, useEffect } from 'react';

function useClickedOutside(ref, onClicked) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClicked();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClicked, ref]);
}

export default function ClickedOutside(props) {
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, props.onClicked);
  return (
    <div ref={wrapperRef} className={props.className}>
      {props.children}
    </div>
  );
}
