import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
  Button,
} from '@chakra-ui/react';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/layout';
import Select from 'react-select';
import { connect } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';
import { searchIcon } from '../HomepageLogin/icons';

const Pill = ({ type, theme = {}, first, value, onCloseClick }) => (
  <Flex
    bg={theme.filters.pillBg}
    color={theme.filters.pillColor}
    borderColor={theme.filters.pillBorder}
    borderWidth={'1px'}
    fontSize="sm"
    alignItems="center"
    justify={'space-between'}
    p="2"
    borderRadius="4px"
    my={'2'}
    mr="2"
    whiteSpace="nowrap"
  >
    <Box>
      {type}: <b>{value}</b>
    </Box>
    <Box ml="2">
      <CloseIcon w="8px" h="8px" onClick={onCloseClick} />
    </Box>
  </Flex>
);

function FilterOptions({
  selectedMainMenu,
  theme = {},
  filterOptions = [],
  onBackClick,
  onFilterSelect,
  selectedFilters = {},
  onFilterChange,
  searchTerm,
  selectStyles,
}) {
  return (
    <Container padding="0">
      <Flex color={theme.addFilterText} alignItems="end" mt="2">
        <button onClick={onBackClick}>
          <Flex alignItems="center">
            <ChevronLeftIcon />
            <Text
              ml="1"
              textTransform="uppercase"
              fontSize="sm"
              fontWeight="semibold"
            >
              {selectedMainMenu.label}
            </Text>
          </Flex>
        </button>
      </Flex>
      <Box mt="2">
        <InputSearch
          searchTerm={searchTerm}
          themeStyleObj={theme}
          onFilterChange={onFilterChange}
        />
      </Box>
      <Flex direction={'column'} mt="4">
        {filterOptions.map((option) => {
          return (
            <Box mb="6" key={option.title}>
              <Text
                mb="2"
                color={theme.filters.selectBoxLabelColor}
                fontSize="sm"
              >
                {option.title}
              </Text>
              <Select
                isSearchable={true}
                isMulti={option.isMulti}
                styles={selectStyles}
                name="color"
                options={option.options.map((s) => ({
                  value: s,
                  label: s.title ?? s,
                }))}
                height="42px"
                value={selectedFilters[option.property]?.[0] ?? null}
                onChange={(selected) =>
                  onFilterSelect(selected, option, selectedMainMenu.label)
                }
              />
            </Box>
          );
        })}
      </Flex>
    </Container>
  );
}

export const InputSearch = ({ themeStyleObj, searchTerm, onFilterChange }) => (
  <InputGroup
    size="lg"
    w={['full']}
    outline="none"
    borderColor={themeStyleObj.filters.searchBorder}
    bg={themeStyleObj.filters.searchBg}
    position="relative"
  >
    <InputLeftElement
      mx={2}
      outline="none"
      ml={4}
      transform="translateY(-50%) scale(0.8)"
      w="max"
      justifyContent="end"
      top="50%"
    >
      {searchIcon}
    </InputLeftElement>
    <Input
      type="text"
      outline="none"
      _hover={{ borderColor: '#24b552' }}
      _focus={{ borderColor: 'none', boxShadow: 'none' }}
      color={themeStyleObj.filters.selectBoxLabelColor}
      placeholder="Search"
      borderWidth="0px"
      fontSize="sm"
      size={'lg'}
      value={searchTerm}
      onInput={(event) => {
        const target = event.target;
        onFilterChange(target.value);
      }}
    />
  </InputGroup>
);

function FiltersMainMenu({
  setSelectedMainMenu,
  filterMenuOptions,
  onFilterChange,
  searchTerm,
  themeStyleObj = {},
}) {
  const onClickOfMenu = (menu) => {
    setSelectedMainMenu(menu);
  };

  return (
    <>
      <Box mb="5">
        <Text
          mb="2"
          textTransform="uppercase"
          fontSize="x-small"
          color={themeStyleObj.filters.headerLink}
        >
          Filter stocks by
        </Text>
        <InputSearch
          themeStyleObj={themeStyleObj}
          searchTerm={searchTerm}
          onFilterChange={onFilterChange}
        />
      </Box>
      {filterMenuOptions.map((menu) => (
        <Box overflow={'auto'} key={menu.label}>
          <Box mb="4" cursor="pointer">
            <button onClick={() => onClickOfMenu(menu)}>
              <Text color={themeStyleObj.filters.headerLink}>{menu.label}</Text>
            </button>
          </Box>
          <Divider mb="4" />
        </Box>
      ))}
    </>
  );
}
const filterMap = {
  overview: { title: 'Overview' },
  valuation: { title: 'Valuation' },
  financials: { title: 'Financial' },
  performance: { title: 'Performance' },
};

const filterMenuOptions = Object.keys(filterMap).map((item) => ({
  label: filterMap[item].title,
  value: item,
}));
function NewFilters({
  theme,
  applyFilter,
  filterOptions,
  selectStyles,
  filterValues,
  getPillFilters,
  allSelectedFilters,
}) {
  const [selectedMainMenu, setSelectedMainMenu] = useState({});
  const showFilterOptions = selectedMainMenu.value;
  const [selectedFilters, setSelectedFilters] = useState(allSelectedFilters);
  const [tempSelection, setTempSelection] = useState(allSelectedFilters);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFilterOptions, setFilteredFilterOptions] = useState(
    filterOptions
  );
  const [pillFilters, setPillFilters] = useState(getPillFilters(tempSelection));
  const onFilterSelect = (selectedOption, { property }, category) => {
    const _selectedFilters = {
      ...tempSelection,
      [property]:
        selectedOption.value || Array.isArray(selectedOption)
          ? [selectedOption]
          : [],
    };
    setTempSelection(_selectedFilters);
  };

  const onFilterRemove = (item, category) => {
    delete selectedFilters[category][item];
    setSelectedFilters({ ...selectedFilters });
    //addFilter(selectedFilters);
  };

  useEffect(() => {
    setPillFilters(getPillFilters(tempSelection));
  }, [getPillFilters, tempSelection]);

  const onAddClick = () => {
    setSelectedFilters(tempSelection);
    setSelectedMainMenu({});
  };

  useEffect(() => {
    if (!searchTerm) {
      setFilteredFilterOptions(filterOptions);
      return;
    }
    const _filteredFilterOptions = Object.keys(filteredFilterOptions).reduce(
      (accumulator, option) => {
        accumulator[option] = filteredFilterOptions[option].filter((item) =>
          item.title?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        return accumulator;
      },
      {}
    );
    setFilteredFilterOptions(_filteredFilterOptions);
  }, [filterOptions, filteredFilterOptions, searchTerm]);

  return (
    <Container padding="0" mt="1.5" className="h-full flex flex-col">
      {showFilterOptions ? null : (
        <Flex overflow="auto" w="full" mb="3" flexWrap="wrap">
          {pillFilters.map((filter) => (
            <Pill
              key={filter.type}
              type={filter.type}
              value={filter.value}
              theme={theme}
              onCloseClick={() => onFilterRemove(filter)}
            />
          ))}
        </Flex>
      )}
      <div className="flex-1">
        {selectedMainMenu.value ? (
          <FilterOptions
            theme={theme}
            selectedMainMenu={selectedMainMenu}
            filterOptions={filteredFilterOptions[selectedMainMenu.value]}
            onBackClick={() => setSelectedMainMenu({})}
            onFilterSelect={onFilterSelect}
            selectedFilters={tempSelection}
            themeStyleObj={theme}
            searchTerm={searchTerm}
            selectStyles={selectStyles}
            onFilterChange={(value) => setSearchTerm(value)}
          />
        ) : (
          <FiltersMainMenu
            themeStyleObj={theme}
            filterMenuOptions={filterMenuOptions}
            searchTerm={searchTerm}
            onFilterChange={(value) => setSearchTerm(value)}
            onMenuClick={() => {}}
            setSelectedMainMenu={setSelectedMainMenu}
          />
        )}
      </div>
      <div className="flex-col h-12">
        {showFilterOptions ? (
          <Button
            onClick={onAddClick}
            w="full"
            bg={theme.filters.applyFilterBtnBg}
            _hover={{
              backgroundColor: theme.filters.applyFilterBtnBgHover,
            }}
            color={theme.filters.applyFilterBtnColor}
          >
            Add
          </Button>
        ) : (
          <Button
            w="full"
            _hover={{
              backgroundColor: theme.filters.applyFilterBtnBgHover,
            }}
            bg={theme.filters.applyFilterBtnBg}
            color={theme.filters.applyFilterBtnColor}
            onClick={() => applyFilter(selectedFilters)}
          >
            Apply Filter
          </Button>
        )}
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  filterValues: state.screenerState.filterValues.data,
});

export default connect(mapStateToProps, null)(NewFilters);
