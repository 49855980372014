export const INFO = {
  peratio: {
    data:
      'The multiple equity investors are paying for each dollar of earnings.',
    tldr: 'The multiple investors are paying for each dollar of earnings.',
  },
  pegratio: {
    data:
      'A variation of the P/E ratio that takes into account the growth of the company.',
    tldr: 'The ratio between the P/E and the expected EPS growth rate.',
  },
  psratio: {
    data:
      'The multiple equity investors are paying for each dollar of revenue.',
    tldr: 'The multiple investors are paying for each dollar of revenue.',
  },
  pbratio: {
    data:
      'The multiple equity investors are paying for each dollar of shareholder equity.',
    tldr: 'The multiple investors are paying for each dollar of equity.',
  },
  pe: {
    data:
      'The multiple equity investors are paying for each dollar of earnings.',
    tldr: 'The multiple investors are paying for each dollar of earnings.',
  },
  peg: {
    data:
      'A variation of the P/E ratio that takes into account the growth of the company.',
    tldr: 'The ratio between the P/E and the expected EPS growth rate.',
  },
  ps: {
    data:
      'The multiple equity investors are paying for each dollar of revenue.',
    tldr: 'The multiple investors are paying for each dollar of revenue.',
  },
  pb: {
    data:
      'The multiple equity investors are paying for each dollar of shareholder equity.',
    tldr: 'The multiple investors are paying for each dollar of equity.',
  },
  return_on_assets: {
    data: 'The profit generated from each dollar of the company’s assets.',
    tldr: 'The profit generated by each dollar of the company’s assets.',
  },
  return_on_equity: {
    data:
      'The profit generated from each dollar of the company’s shareholder equity.',
    tldr: 'The profit generated by each dollar of the company’s equity.',
  },
  return_on_invested_capital: {
    data:
      'The profit generated from each dollar of the company’s invested capital.',
    tldr: '',
  },
  eps: {
    data: 'The amount of earnings attributable to each shareholder.',
    tldr: 'The amount of earnings attributable to each shareholder.',
  },
  dividend_yield: {
    data: 'The expected return from dividends at the current share price.',
    tldr: 'The expected return from dividends at the current share price.',
  },
  payout_ratio: {
    data:
      'The dividend per share (DPS) divided by the earnings per share (EPS) over the trailing twelve months (TTM). The payout ratio is used to evaluate a company’s ability to maintain its dividend in the future.',
    tldr:
      'Used to evaluate a companies ability to maintain its dividend in the future.',
  },
  yield_on_cost: {
    data: 'The expected return from dividends at the average cost basis.',
    tldr: 'The expected return from dividends based on the cost basis.',
  },
  market_cap: {
    data: 'The value of a company’s equity based on the current share price.',
    tldr: 'The market value of a company’s equity.',
  },
  value_rating: {
    data: 'Companies with a high Value rating are likely to be undervalued.',
    tldr: 'Companies with high Value Ratings are likely to be undervalued.',
  },
  growth_rating: {
    data:
      'Companies with a high Growth rating are likely to grow quickly in the future.',
    tldr:
      'Companies with high Growth Ratings are likely to grow faster in the future.',
  },
  safety_rating: {
    data:
      'Companies with a high Safety rating are likely to remain solvent in the future.',
    tldr:
      'Companies with high Safety Ratings are likely to remain solvent in the future.',
  },
  dividend_rating: {
    data:
      'Companies with a high Dividend rating are likely to maintain and grow its dividend.',
    tldr:
      'Companies with high Dividend Ratings are likely to continue growing its dividend in the future.',
  },
  profitability_rating: {
    data:
      'Companies with a high Profitabilty rating are likely to remain Profitable.',
    tldr:
      'Companies with high Profitable Ratings are likely to continue growing its Profit in the future.',
  },
  momentum_rating: {
    data:
      'Companies with a high Momentum score generated the high historical returns.',
    tldr:
      'Companies with high Momentum Ratings generated the highest historical returns.',
  },
  fair_price: {
    data:
      'The value of a company’s equity based on its expected future cash flows.',
    tldr: 'The value of a company based on its expected future cash flows.',
  },
  target_price: {
    data:
      'The expected price of a stock based on  Wall Street analysts forecasts.',
    tldr: '',
  },
  dividend_per_share: {
    data: 'The amount of cash distributed to each shareholder.',
    tldr: '',
  },
  revenue: {
    data: 'The amount generated from the sale of goods and services.',
    tldr: '',
  },
  gross_profit: {
    data:
      'Profit after subtracting the direct costs associated with a product or service.',
    tldr: '',
  },
  gross_profit_margin: {
    data:
      'Profit after subtracting the direct costs associated with a product or service.',
    tldr: '',
  },
  ebitda: {
    data:
      'Profit after subtracting the cost of goods sold and cash operating expenses.',
    tldr: '',
  },
  ebit: {
    data:
      'Profit after subtracting the cost of goods sold and all operating expenses.',
    tldr: '',
  },
  net_income: {
    data:
      'Profit after subtracting all expenses, including interest and taxes.',
    tldr: '',
  },
  net_income_margin: {
    data:
      'Profit after subtracting all expenses, including interest and taxes.',
    tldr: '',
  },
  total_assets: {
    data:
      'The resources owned by a company such as property, plant, and equipment.',
    tldr: '',
  },
  total_liabilities: {
    data:
      'The obligations owed by a company such as debt and other interest bearing obligations.',
    tldr: '',
  },
  total_equity: {
    data:
      'The residual value after subtracting total liabilities from total assets.',
    tldr: '',
  },
  cash_and_st_investments: {
    data: 'The amount of cash and other liquid short term investments.',
    tldr: '',
  },
  total_cash: {
    data: 'The amount of cash and other liquid short term investments.',
  },
  total_debt: {
    data: 'The amount of debt and other interest bearing obligations.',
    tldr: '',
  },
  cash_from_operations: {
    data: 'The amount of cash generated from operating activities.',
    tldr: '',
  },
  cash_from_ops: {
    data: 'The amount of cash generated from operating activities.',
  },
  capital_expenditures: {
    data: 'The amount of cash reinvested in property, plant, and equipment.',
    tldr: '',
  },
  free_cash_flow: {
    data:
      'The amount of cash available after all cash expenses and reinvestment costs.',
    tldr: '',
  },
  dividend_paid: {
    data:
      'The amount of cash directly returned to shareholders through cash payments.',
  },
  dividends_paid: {
    data:
      'The amount of cash directly returned to shareholders through cash payments.',
  },
  share_buybacks: {
    data:
      'The amount of cash indirectly returned to shareholders through share repurchases.',
    tldr: '',
  },
  gross_margin: {
    data:
      'The company’s profitability after subtracting the direct costs associated with a product or service.',
    tldr: '',
  },
  ebitda_margin: {
    data:
      'The company’s profitability after subtracting the cost of goods sold and cash operating expenses.',
    tldr: '',
  },
  ebit_margin: {
    data:
      'The company’s profitability after subtracting the cost of goods sold and all operating expenses.',
    tldr: '',
  },
  net_margin: {
    data:
      'The company’s profitability after subtracting all expenses, including interest and taxes.',
    tldr: '',
  },
  fcf_margin: {
    data:
      'The company’s profitability after subtracting all cash expenses and reinvestment costs.',
    tldr: '',
  },
  free_cash_flow_margin: {
    data:
      'The company’s profitability after subtracting all cash expenses and reinvestment costs.',
  },
  net_income_payout_ratio: {
    data: 'The proportion of net income returned to shareholders.',
    tldr: '',
  },
  ni_payout_ratio: {
    data: 'The proportion of net income returned to shareholders.',
  },
  fcf_payout_ratio: {
    data: 'The proportion of free cash flow returned to shareholders.',
    tldr: '',
  },
  total_shareholder_return_ratio: {
    data:
      'The proportion of free cash flow returned to shareholders through either dividends or buybacks.',
    tldr: '',
  },
  total_shareholder_return: {
    data:
      'The proportion of free cash flow returned to shareholders through either dividends or buybacks.',
  },
  debt_ebitda: {
    data:
      'The number of years of cash earnings required to repay the company’s debt.',
  },
  debt_equity: {
    data:
      'The ratio of the company’s debt relative to its shareholder’s equity.',
  },
  debt_capital: {
    data:
      'The proportion of the company’s invested capital that is financed using debt.',
  },
  ev_revenue: {
    data:
      'The revenue multiple an investor would pay to acquire the entire company.',
  },
  ev_ebitda: {
    data:
      'The EBITDA multiple an investor would pay to acquire the entire company.',
  },
  ev_ebit: {
    data:
      'The EBIT multiple an investor would pay to acquire the entire company.',
  },
  shares_outstanding: {
    data: 'The total number of shares current held the company’s shareholders.',
  },
  shares_data: {
    data: 'The total number of shares current held the company’s shareholders.',
  },
  none: {
    data: '',
  },
};
