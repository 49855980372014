import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';

function* fetchRealTimePrice(action) {
  yield put({ type: 'FETCH_REAL_TIME_LOADING' });
  try {
    const response = yield wrappedAxios
      .get(`${SYMBOL_BASE_URL}/real-time/${action.symbol.toUpperCase()}`)
      .then((response) => {
        return response;
      });
    console.log('fetchRealTimePriceres');
    yield put({
      type: 'FETCH_REAL_TIME_SUCCESS',
      data: response.data,
    });
  } catch (error) {
    console.log(error.message);
    yield put({ type: 'FETCH_REAL_TIME_ERROR', error: error.message });
  }
}

export function* watchFetchingRealTimeData() {
  yield takeEvery('FETCH_REAL_TIME', fetchRealTimePrice);
}
