import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

const HoldingsImages = (holdings) => {
  let dupHoldings =
    holdings['holdings'].length === 0 ? [] : holdings['holdings'];
  let randomVar =
    dupHoldings.length > 4
      ? Math.floor(Math.random() * (dupHoldings.length - 5))
      : 0;

  return (
    <>
      <Flex>
        {dupHoldings
          .filter((elem) => elem.symbol !== null)
          .slice(randomVar, randomVar + 4)
          .map((elem, index) => {
            return (
              <img
                style={{
                  width: '25px',
                  height: '25px',
                  marginTop: '5px',
                  borderRadius: 50,
                  position: 'relative',
                  right: index !== 0 && index * 3,
                }}
                src={`http://images.verde.finance/${
                  elem.symbol.split('.')[0]
                }.png`}
              />
            );
          })}
      </Flex>
      {dupHoldings.filter((elem) => elem.symbol !== null).length > 4 && (
        <Text style={{ color: 'gray', fontSize: 12 }}>
          +{dupHoldings.filter((elem) => elem.symbol !== null).length - 4} more
        </Text>
      )}
    </>
  );
};

export default HoldingsImages;
