import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Subtle } from '../StyedComponents';

const InvestmentChart = ({ data }) => {
  const options = {
    chart: {
      type: 'pie',
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: 'transparent',
    },
    title: {
      text: null,
    },
    yAxis: {},
    plotOptions: {
      pie: {
        shadow: false,
        borderColor: 'transparent',
        allowPointSelect: false,
      },
    },
    series: [
      {
        name: 'Invested',
        data: data,
        size: '80%',
        innerSize: '70%',
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="-my-12 sm:-my-8 md:-my-20 lg:-my-20 xl:-my-8">
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className="text-center relative bottom-56">
        <div className="font-semibold text-xl" style={{ color: '#101010' }}>
          $1277
        </div>
        <Subtle className="font-medium">Total Investment</Subtle>
      </div>
    </div>
  );
};

export default InvestmentChart;
