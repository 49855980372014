import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useState, FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import VerdeLogo from '../../../../public/images/VerdeLogo.png';
import { DefaultTheme, useTheme } from 'styled-components';
import { showPageLoading } from '../../../../actions/coreAction';
import PageContainer from '../../../shared/PageContainer';
import { useMediaQuery } from '@chakra-ui/react';
import WrappedAxios from '../../../../utils/WrappedAxios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const PageContainerW = PageContainer;

function TopProfileMobile({ profileData, darkMode, showPageLoading }) {
  const [data, setData] = useState([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams();

  //showPageLoading(data.length === 0);

  return (
    <Box width="100%">
      <Flex>
        <Flex
          backgroundSize="cover"
          borderRadius={100}
          backgroundImage="http://images.verde.finance/TSLA.png"
          width={100}
          height={97}
          marginTop={10}
        />

        <Box marginLeft={10} marginTop={10} width="50%">
          <Text
            style={{
              color: darkMode ? 'white' : 'black',
              fontWeight: 500,

              textAlign: 'left',
              fontSize: 14,
            }}
          >
            {profileData.length > 0 && profileData[0]['name']}
          </Text>
        </Box>
      </Flex>

      <Box alignItems="center" marginTop={5}>
        <Flex>
          <Box marginLeft={5}>
            <Text
              style={{
                color:
                  2.56 > 0
                    ? darkMode
                      ? '#4adf79'
                      : '#01874e'
                    : darkMode
                    ? '#ec5b55'
                    : '#BF2F2C',
                fontWeight: 500,
                marginTop: 10,
                textAlign: 'left',
                fontSize: 20,
              }}
            >
              {/* {profileData.length > 0 && profileData[0]['name']} */}
              2.56%
            </Text>
            <Text
              style={{
                color: darkMode ? '#cccccc' : 'black',
                fontSize: 16,
                marginTop: 5,
              }}
            >
              Returns
            </Text>
          </Box>
          <Box marginLeft={20}>
            <Text
              style={{
                color: darkMode ? '#cccccc' : 'black',
                fontSize: 16,
                marginTop: 10,
              }}
            >
              Images
            </Text>
            <Text
              style={{
                color: darkMode ? '#cccccc' : 'black',
                fontSize: 16,
                marginTop: 10,
              }}
            >
              Followed by 69K people
            </Text>
          </Box>
        </Flex>

        <Flex justifyContent="flex-start" marginLeft={3}>
          <button
            style={{
              padding: 5,
              width: '180px',
              backgroundColor: darkMode ? '#4adf79' : '#01874e',
              borderRadius: 20,
              marginTop: 20,
              fontSize: 15,
              fontWeight: 500,
              color: darkMode ? 'black' : 'white',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Follow
          </button>
        </Flex>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopProfileMobile);
