export const tabs = [
  {
    name: 'Overview',
    columns: [
      { id: 'company', text: 'Company' },
      { id: 'sector', text: 'Sector' },
      { id: 'industry', text: 'Industry' },
      { id: 'marketCap', text: 'Market Cap' },
      { id: 'enterPrise', text: 'Enterprise' },
      { id: 'targetPrice', text: 'Target Price' },
      { id: 'targetPriceUpside', text: 'Target Price % Upside' },
      { id: 'fairPrice', text: 'Fair Value' },
      { id: 'fairPriceUpside', text: 'Fair Value % Upside' },
      { id: 'currentPrice', text: 'Current Price' },
      { id: 'percentChangeInPrice', text: '% Change' },
    ],
  },
  {
    name: 'Ratings',
    columns: [
      { id: 'company', text: 'Company' },
      { id: 'analystRating', text: 'Analyst Rating' },
      { id: 'valueRating', text: 'Value Rating' },
      { id: 'growthRating', text: 'Growth Rating' },
      { id: 'profitabilityRating', text: 'Profitability Rating' },
      { id: 'safetyRating', text: 'Safety Rating' },
      { id: 'dividendRating', text: 'Dividend Rating' },
      { id: 'momentumRating', text: 'Momentum Rating' },
      { id: 'verdeScore', text: 'Verde Score' },
      { id: 'currentPrice', text: 'Current Price' },
      { id: 'percentChangeInPrice', text: '% Change' },
    ],
  },
  {
    name: 'Valuation',
    columns: [
      { id: 'company', text: 'Company' },
      { id: 'priceToEarnings', text: 'P/E (TTM)' },
      { id: 'priceToEarningsFWD', text: 'P/E (FWD)' },
      { id: 'priceToSale', text: 'P/S (TTM)' },
      { id: 'priceToBook', text: 'P/B (MRQ)' },
      { id: 'pegRatio', text: 'PEG (FWD)' },
      { id: 'evRevenue', text: 'EV/SALES (TTM)' },
      { id: 'evEbita', text: 'EV/EBITDA (TTM)' },
      { id: 'evEbit', text: 'EV/EBIT (TTM)' },
      { id: 'currentPrice', text: 'Current Price' },
      { id: 'percentChangeInPrice', text: '% Change' },
    ],
  },
  {
    name: 'Growth',
    columns: [
      { id: 'company', text: 'Company' },
      { id: 'revenueGrowth', text: 'Revenue Growth (TTM)' },
      // { id: 'revenueGrowthFWD', text: 'Revenue Growth (FWD)' },
      { id: 'revenueGrowthFY1', text: 'Revenue Growth (FY1)' },
      { id: 'revenueGrowthFY2', text: 'Revenue Growth (FY2)' },
      { id: 'epsGrowth', text: 'EPS Growth (TTM)' },
      // { id: 'epsGrowthFWD', text: 'EPS Growth (FWD)' },
      { id: 'epsGrowthFY1', text: 'EPS Growth (FY1)' },
      { id: 'epsGrowthFY2', text: 'EPS Growth (FY2)' },
      { id: 'currentPrice', text: 'Current Price' },
      { id: 'percentChangeInPrice', text: '% Change' },
    ],
  },
  {
    name: 'Profitability',
    columns: [
      { id: 'company', text: 'Company' },
      { id: 'grossMargin', text: 'Gross Margin' },
      { id: 'netMargin', text: 'Net Margin' },
      { id: 'operatingMargin', text: 'Operating Margin' },
      { id: 'returnOnAssets', text: 'Return on Assets' },
      { id: 'returnonEquity', text: 'Return on Equity' },
      { id: 'returnOnInvestmentCapital', text: 'Return on Capital' },
      { id: 'debtCapital', text: 'Debt/Capital' },
      { id: 'debtEquity', text: 'Debt/Equity' },
      { id: 'currentPrice', text: 'Current Price' },
      { id: 'percentChangeInPrice', text: '% Change' },
    ],
  },
  {
    name: 'Performance',
    columns: [
      { id: 'company', text: 'Company' },
      { id: 'oneWeek', text: '1W Returns' },
      { id: 'oneMonth', text: '1M Returns' },
      { id: 'threeMonth', text: '3M Returns' },
      { id: 'sixMonths', text: '6M Returns' },
      { id: 'ytd', text: 'YTD Returns' },
      { id: 'oneYear', text: '1Y Returns' },
      { id: 'threeYears', text: '3Y Returns' },
      { id: 'fiveYears', text: '5Y Returns' },
      { id: 'currentPrice', text: 'Current Price' },
      { id: 'percentChangeInPrice', text: '% Change' },
    ],
  },
];
