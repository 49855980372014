import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ArrowIcon = styled(FontAwesomeIcon)`
  color: #4d4d4d;
  opacity: 0.75;
`;

export const StyledButton = styled.button`
  background-color: ${(props) => props.color};
`;

export const StyledSpan = styled.span`
  color: #569329;
`;

export const VerdeImg = styled.img`
  width: 300px;
  height: 240px;
`;

export const UserImg = styled.img`
  width: 400px;
  height: 400px;
`;
