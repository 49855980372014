import React from 'react';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

const Score = styled.div`
  width: ${(props) => (props.isPortfolio ? 130 : 200)}px;
  height: ${(props) => (props.isPortfolio ? 115 : 172)}px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.sectionBorder};
  background-clip: content-box;
  padding: 50px;
  border: ${(props) => (props.isPortfolio ? 30 : 50)}px solid
    ${(props) => props.theme.sectionBorder};
  color: ${(props) => props.theme.content.color};
  margin: auto;
`;

const Marker = styled.div`
  ${tw`text-xs relative border border-t-0 w-2 h-0 border-black`};
`;

const MarkerText = styled.span`
  ${tw`text-xs relative font-medium`};
`;

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const drawArc = (x, y, radius, startAngle, endAngle) => {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  var d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ');

  return d;
};

const MAX_SCORE = 99;

const getScoreColor = (score) => {
  if (score < MAX_SCORE * 0.2) return '#DE5D3C';
  if (score < MAX_SCORE * 0.4) return '#E88126';
  if (score < MAX_SCORE * 0.5) return '#ECB305';
  if (score < MAX_SCORE * 0.7) return '#B7BF45';
  if (score < MAX_SCORE * 0.9) return '#7FA658';
  if (score <= MAX_SCORE) return '#36A867';
};

const portfolioStyles = {
  container: { width: '175px' },
  zeroMarker: { top: '106px', right: '0px' },
  zeroMarkerText: { top: '92px', right: '10px' },
  maxMarker: { left: '168px', bottom: '80px' },
  maxMarkerText: { left: '178px', bottom: '94px' },
  viewBox: { position: 'relative', bottom: '229px' },
  radius: 98,
};

const valuationStyles = {
  container: { width: '210px' },
  zeroMarker: { top: '126px', right: '2px' },
  zeroMarkerText: { top: '112px', right: '14px' },
  maxMarker: { left: '204px', bottom: '99px' },
  maxMarkerText: { left: '218px', bottom: '113px' },
  viewBox: { position: 'relative', bottom: '274px' },
  radius: 102,
};

const VerdeScore = ({ score = 85, variant }) => {
  const styles = variant === 'portfolio' ? portfolioStyles : valuationStyles;

  return (
    <div className="mx-auto -mb-52" style={styles.container}>
      <Marker style={styles.zeroMarker} />
      <MarkerText style={styles.zeroMarkerText}>0</MarkerText>
      <Score isPortfolio={variant === 'portfolio'}>
        <div className="relative right-5 bottom-9">
          <img src={VerdeScoreSvg} alt="" className="min-w-max ml-2.5" />
          <div className="text-xs font-medium ml-1 my-0.5">Score</div>
          <div className="text-4xl font-semibold">
            {score < 10 ? `0${score}` : score}
          </div>
        </div>
      </Score>
      <Marker style={styles.maxMarker} />
      <MarkerText style={styles.maxMarkerText}>100</MarkerText>
      <svg viewBox="45 0 210 200" style={styles.viewBox}>
        <path
          d={drawArc(150, 150, styles.radius, -90, -90 + (score / 100) * 180)}
          fill="none"
          stroke={getScoreColor(score)}
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

export default VerdeScore;
