import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import SymbolSearch from '../SymbolSearch';
import SimilarCompanies from '../SimilarCompanies';

Modal.setAppElement('#root');

const SimilarCompaniesModal = ({ open, setOpen }) => {
  const modalStyles = {
    overlay: {
      //top: '15px',
      zIndex: 30,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '0',
      margin: 'auto',
      height: 'fit-content',
      maxWidth: '550px',
      overflow: 'hidden',
      padding: 0,
      border: 0,
    },
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <Modal isOpen={open} onRequestClose={onCloseModal} style={modalStyles}>
      <SimilarCompanies onCloseModal={onCloseModal} />
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimilarCompaniesModal);
