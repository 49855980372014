import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import { getStackedAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';
import RiskPositives from '../../../RiskPositives';
import { useMediaQuery } from '@chakra-ui/react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';

const ProfitabilityRatios = ({
  data,
  insights,
  period,
  setPeriod,
  darkMode,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'Gross Margin',
      selected: false,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'EBITDA Margin',
      selected: false,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'EBIT Margin',
      selected: true,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
    {
      name: 'Net Margin',
      selected: true,
      darkColor: '#DDDDDD',
      lightColor: '#012749',
    },
    {
      name: 'FCF Margin',
      selected: true,
      darkColor: '#FF7979',
      lightColor: '#F26970',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    [
      'evaluated_on',
      'free_cash_flow_margin',
      'gross_profit_margin',
      'ebitda_margin',
      'ebit_margin',
      'net_income_margin',
    ],
    period
  );
  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['Gross Margin'].selected
      ? {
          name: 'Gross Margin',
          data: fullChartData.map((item) => [item[2], item[4]]),
        }
      : [],
    chartVariants['EBITDA Margin'].selected
      ? {
          name: 'EBITDA Margin',
          data: fullChartData.map((item) => [item[2], item[1]]),
        }
      : [],
    chartVariants['EBIT Margin'].selected
      ? {
          name: 'EBIT Margin',
          data: fullChartData.map((item) => [item[2], item[0]]),
        }
      : [],
    chartVariants['Net Margin'].selected
      ? {
          name: 'Net Margin',
          data: fullChartData.map((item) => [item[2], item[5]]),
        }
      : [],
    chartVariants['FCF Margin'].selected
      ? {
          name: 'FCF Margin',
          data: fullChartData.map((item) => [item[2], item[3]]),
        }
      : [],
  ];

  return (
    <>
      <Flex style={{ marginBottom: '25px' }}>
        <SectionHeader value="Profitability Ratios" />
        {!isMobile && (
          <Box
            style={{
              marginLeft: 'auto',
              backgroundColor: darkMode ? '#161616' : '#f8f8f8',
              borderRadius: '15px',
              height: 'auto',
            }}
          >
            <ToggleButton period={period} setPeriod={setPeriod} />
          </Box>
        )}
      </Flex>

      {isMobile && (
        <Box
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: darkMode ? '#161616' : '#f8f8f8',
            borderRadius: '15px',
            marginBottom: '5px',
            height: 'auto',
          }}
        >
          <ToggleButton period={period} setPeriod={setPeriod} />
        </Box>
      )}
      <div style={{ marginTop: '10px' }}>
        <StackedLineChart data={chartData} valueType="percent" />
        <div className="flex justify-center">
          <MultiLineGraphSelectorButton
            values={buttonValues}
            setValues={setButtonValues}
          />
        </div>
      </div>

      <RiskPositives
        positives={insights?.profitability_ratios?.positives}
        negatives={insights?.profitability_ratios?.negatives}
      />
    </>
  );
};

export default ProfitabilityRatios;
