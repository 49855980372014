import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';
import axios from 'axios';

function* fetchSymbolData(action) {
  yield put({ type: 'FETCH_SYMBOL_DATA_LOADING' });
  try {
    const response = yield wrappedAxios
      .get(`${SYMBOL_BASE_URL}/symbols/${action.payload.toUpperCase()}`)
      .then((response) => {
        return response;
      });
    yield put({ type: 'FETCH_SYMBOL_DATA_SUCCESS', data: response.data });
  } catch (error) {
    yield put({ type: 'FETCH_SYMBOL_DATA_ERROR', error: error });
  }
}

function* fetchSymbolNames(action) {
  yield put({ type: 'FETCH_SYMBOL_NAMES_LOADING' });
  try {
    const url = `${SYMBOL_BASE_URL}/verde-entities`;
    const response = yield wrappedAxios.get(url).then((response) => {
      return response.data;
    });
    yield put({
      type: 'FETCH_SYMBOL_NAMES_SUCCESS',
      data: response,
    });
  } catch (error) {
    yield put({ type: 'FETCH_SYMBOL_NAMES_ERROR', error: error.message });
  }
}

function* fetchTopMovers() {
  yield put({ type: 'FETCH_TOP_MOVERS_LOADING' });
  try {
    const url = `${SYMBOL_BASE_URL}/topmovers`;
    const response = yield wrappedAxios.get(url).then((response) => {
      return response.data;
    });
    yield put({
      type: 'FETCH_TOP_MOVERS_SUCCESS',
      data: response,
    });
  } catch (error) {
    yield put({ type: 'FETCH_TOP_MOVERS_ERROR', error: error.message });
  }
}

function* fetchPublicSymbols(action) {
  yield put({ type: 'FETCH_PUBLIC_SYMBOLS_LOADING' });
  try {
    const url = `${SYMBOL_BASE_URL}/public-symbols`;
    const response = yield axios.get(url).then((response) => {
      return response.data;
    });
    yield put({
      type: 'FETCH_PUBLIC_SYMBOLS_SUCCESS',
      data: response,
    });
  } catch (error) {
    yield put({ type: 'FETCH_PUBLIC_SYMBOLS_ERROR', error: error.message });
  }
}

export function* watchFetchingSymbolData() {
  yield takeEvery('FETCH_SYMBOL_DATA', fetchSymbolData);
  yield takeEvery('FETCH_SYMBOL_NAMES_DATA', fetchSymbolNames);
  yield takeEvery('FETCH_TOP_MOVERS', fetchTopMovers);
  yield takeEvery('FETCH_PUBLIC_SYMBOLS_DATA', fetchPublicSymbols);
}
