import React from 'react';
import { connect } from 'react-redux';
import {
  Scale,
  Marker,
  MarkerText,
  Bar,
  BarName,
  BarValue,
  Container,
} from './style';

const findMaxRange = (n, m) => {
  const q = parseInt(n / m);
  const r = n % m;
  return r === 0 ? m * q : m * (q + (n < 0 ? -1 : 1));
};

const getSplitValue = (width, isNegative) => {
  if (width < 350) return isNegative ? 2 : 3;
  if (width < 400) return isNegative ? 3 : 4;
  if (width < 750) return isNegative ? 5 : 6;
  return 8;
};

const RatioGraph = ({ ratios = [], symbol, darkMode, suffix = '' }) => {
  const unitRef = React.useRef(null);
  const fullRef = React.useRef(null);
  const [unitWidth, setUnitWidth] = React.useState(0);
  const [defaultSplitValue, setDefaultSplitValue] = React.useState(3);
  const isNegative = ratios.filter((ratio) => ratio.value < 0).length > 0;

  React.useEffect(() => {
    if (fullRef.current) {
      const width = fullRef.current.offsetWidth;
      setDefaultSplitValue(getSplitValue(width, isNegative));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (unitRef.current) {
      const width = unitRef.current.getBoundingClientRect().width;
      setUnitWidth(width);
    }
  }, [defaultSplitValue]);

  const COLORS = {
    Sector: {
      color: darkMode ? '#9761dc' : '#4c428e',
      bgColor: darkMode ? '#9761dc' : '#cec9f6',
      borderColor: darkMode ? '#9761dc' : '#b7b0e8',
    },
    Symbol: {
      color: darkMode ? '#57c4c4' : '#477c82',
      bgColor: darkMode ? '#57c4c4' : '#ccf5fa',
      borderColor: darkMode ? '#57c4c4' : '#acdadf',
    },
    Market: {
      color: darkMode ? '#b85ab4' : '#8d3a8a',
      bgColor: darkMode ? '#b85ab4' : '#f2b9f0',
      borderColor: darkMode ? '#b85ab4' : '#ea9ee7',
    },
  };

  const maxRange = ratios.reduce((prev, curr) => {
    const max = findMaxRange(curr.value, defaultSplitValue);
    return prev > max ? prev : max;
  }, defaultSplitValue);

  const offset = maxRange / defaultSplitValue;

  const getWidth = (val) => (val !== 0 ? unitWidth * (val / offset) : 1);

  const colStart = isNegative ? 3 : 2;

  return (
    <Container ref={fullRef}>
      {ratios.map((ratio, index) => (
        <div
          key={index}
          className={`grid grid-cols-${defaultSplitValue + colStart} mb-3`}
        >
          <BarName color={COLORS[ratio.name].color}>
            {ratio.name === 'Symbol' ? symbol : ratio.name}
          </BarName>
          {ratio.value < 0 ? (
            <div className={`flex`}>
              <BarValue
                color={COLORS[ratio.name].color}
                borderColor={COLORS[ratio.name].borderColor}
                value={ratio.value}
              >
                {ratio.value + suffix}
              </BarValue>
              <Bar
                bgColor={COLORS[ratio.name].bgColor}
                borderColor={COLORS[ratio.name].borderColor}
              />
            </div>
          ) : (
            <div
              className={`col-start-${colStart} col-span-${
                defaultSplitValue + colStart
              } flex`}
            >
              <Bar
                width={getWidth(ratio.value)}
                color={COLORS[ratio.name].color}
                bgColor={COLORS[ratio.name].bgColor}
                borderColor={COLORS[ratio.name].borderColor}
              />
              <BarValue
                color={COLORS[ratio.name].color}
                borderColor={COLORS[ratio.name].borderColor}
                marginLeft={getWidth(ratio.value) + 5}
              >
                {ratio.value + suffix}
              </BarValue>
            </div>
          )}
        </div>
      ))}

      <div
        className={`grid grid-cols-${
          defaultSplitValue + colStart
        } text-xs mt-8`}
      >
        <div ref={unitRef} className={`col-start-${colStart}`}>
          <MarkerText className="right-0.5">0</MarkerText>
          <Marker />
        </div>
        {Array.from({ length: defaultSplitValue }, (_, i) => i + 1).map(
          (mark) => (
            <div key={mark}>
              <MarkerText
                className={mark * offset < 10 ? 'right-1' : 'right-1.5'}
              >
                {mark * offset}
              </MarkerText>
              <Marker />
            </div>
          )
        )}
      </div>
      <div className={`grid grid-cols-${defaultSplitValue + colStart}`}>
        {isNegative && (
          <div className={`col-start-2`}>
            <div
              className="w-full h-2"
              style={{ backgroundColor: '#dc5f3d' }}
            ></div>
          </div>
        )}
        <div
          className={`col-start-${colStart} col-span-${
            defaultSplitValue + colStart
          }`}
        >
          <Scale negative={isNegative} defaultSplitValue={defaultSplitValue} />
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(RatioGraph);
