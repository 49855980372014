const SORT_FIELDS_MAP = {
  'Company Name': 'symbol',
  company: 'symbol_market',
  sector: 'gic_sector_display',
  industry: 'industry',
  oneWeek: 'one_week',
  oneMonth: 'one_month',
  sixMonths: 'six_months',
  oneYear: 'one_year',
  ytd: 'ytd',
  fiveYears: 'five_years',
  growthRating: 'growth_rating',
  safetyRating: 'safety_rating',
  valueRating: 'value_rating',
  currentPrice: 'current_price',
  fairPrice: 'fair_price',
  targetPrice: 'target_price',
  fairPriceDeviation: 'fair_price_deviation',
  marketCap: 'marketcap_mln',
  forwardPriceToEarnings: 'forward_pe_ratio',
  forwardDividendRate: 'dividend_rate_fwd',
  priceToEarnings: 'pe_ratio',
  verdeScore: 'verde_score',
  oneDay: 'one_day',
  dividendRating: 'dividend_rating',
  momentumRating: 'momentum_rating',
  enterPrise: 'enterprise_value',
  pegRatio: 'peg_ratio',
  priceToSale: 'price_to_sales',
  priceToBook: 'price_to_book',
  grossMargin: 'gross_profit_margin',
  operatingMargin: 'operating_margin',
  revenueGrowth: 'revenue_growth',
  epsGrowth: 'eps_growth',
  threeMonth: 'three_months',
};

const buildRatingPayload = (rating = []) => {
  return '('.concat(rating.map((r) => `'${r.label}'`).join(',')).concat(')');
};

const buildSortingPayload = (sortFields) =>
  sortFields
    .map(({ id, desc }) => `${SORT_FIELDS_MAP[id]} ${desc ? 'DESC' : 'ASC'}`)
    .join(', ');

const getFilterValue = (value) => {
  let resultArray = [];
  if (Array.isArray(value)) {
    if (value.length) resultArray = value.map((item) => `'${item.value}'`);
    else return undefined;
  } else {
    resultArray = [value.value];
  }
  return `(${resultArray.join(',')})`;
};
export const buildFilterPayload = (filters) => {
  let filterPayload = {};
  if (filters.sectors && filters.sectors.length > 0) {
    filterPayload = {
      ...filterPayload,
      sector: filters.sectors[0]
        ? getFilterValue(filters.sectors[0])
        : undefined,
    };
  }
  if (filters.industries && filters.industries.length > 0) {
    filterPayload = {
      ...filterPayload,
      industry: filters.industries[0]
        ? getFilterValue(filters.industries[0])
        : undefined,
    };
  }
  if (filters.verdeScore && filters.verdeScore.length > 0) {
    filterPayload = {
      ...filterPayload,
      min_verde_score: filters.verdeScore[0],
      max_verde_score: filters.verdeScore[1],
    };
  }
  if (
    filters.overvaluedOrUndervaluedPercent &&
    filters.overvaluedOrUndervaluedPercent.length > 0
  ) {
    filterPayload = {
      ...filterPayload,
      min_overvalued: filters.overvaluedOrUndervaluedPercent[0],
      max_overvalued: filters.overvaluedOrUndervaluedPercent[1],
    };
  }
  // if (filters.marketCap && filters.marketCap.length > 0) {
  //   filterPayload = {
  //     ...filterPayload,
  //     min_marketcap: filters.marketCap[0],
  //     max_marketcap: filters.marketCap[1],
  //   };
  // }
  if (filters.priceToEarnings && filters.priceToEarnings.length > 0) {
    filterPayload = {
      ...filterPayload,
      min_peratio: filters.priceToEarnings[0],
      max_peratio: filters.priceToEarnings[1],
    };
  }
  if (filters.oneWeekReturnPercent && filters.oneWeekReturnPercent.length > 0) {
    filterPayload = {
      ...filterPayload,
      min_oneweek_return: filters.oneWeekReturnPercent[0],
      max_oneweek_return: filters.oneWeekReturnPercent[1],
    };
  }
  if (
    filters.oneMonthReturnPercent &&
    filters.oneMonthReturnPercent.length > 0
  ) {
    filterPayload = {
      ...filterPayload,
      min_onemonth_return: filters.oneMonthReturnPercent[0],
      max_onemonth_return: filters.oneMonthReturnPercent[1],
    };
  }
  if (
    filters.sixMonthsReturnPercent &&
    filters.sixMonthsReturnPercent.length > 0
  ) {
    filterPayload = {
      ...filterPayload,
      min_sixmonths_return: filters.sixMonthsReturnPercent[0],
      max_sixmonths_return: filters.sixMonthsReturnPercent[1],
    };
  }
  if (
    filters.yearToDateReturnPercent &&
    filters.yearToDateReturnPercent.length > 0
  ) {
    filterPayload = {
      ...filterPayload,
      min_ytd_return: filters.yearToDateReturnPercent[0],
      max_ytd_return: filters.yearToDateReturnPercent[1],
    };
  }
  if (filters.oneYearReturnPercent && filters.oneYearReturnPercent.length > 0) {
    filterPayload = {
      ...filterPayload,
      min_oneyear_return: filters.oneYearReturnPercent[0],
      max_oneyear_return: filters.oneYearReturnPercent[1],
    };
  }
  if (
    filters.fiveYearsReturnPercent &&
    filters.fiveYearsReturnPercent.length > 0
  ) {
    filterPayload = {
      ...filterPayload,
      min_fiveyears_return: filters.fiveYearsReturnPercent[0],
      max_fiveyears_return: filters.fiveYearsReturnPercent[1],
    };
  }
  if (filters.dividendYield && filters.dividendYield.length > 0) {
    filterPayload = {
      ...filterPayload,
      min_dividendyield: filters.dividendYield[0],
      max_dividendyield: filters.dividendYield[1],
    };
  }
  if (filters.growthRating && filters.growthRating.size > 0) {
    filterPayload = {
      ...filterPayload,
      growth_ratings: buildRatingPayload([filters.growthRating]),
    };
  }
  if (filters.safetyRating && filters.safetyRating.size > 0) {
    filterPayload = {
      ...filterPayload,
      safety_ratings: buildRatingPayload([filters.safetyRating]),
    };
  }
  if (
    filters.valueRating &&
    (filters.valueRating.size > 0 || filters.valueRating.length)
  ) {
    filterPayload = {
      ...filterPayload,
      value_ratings: buildRatingPayload([filters.valueRating]),
    };
  }
  if (filters.sortFields && filters.sortFields.length > 0) {
    filterPayload = {
      ...filterPayload,
      sorting_fields: buildSortingPayload(filters.sortFields),
    };
  }

  Object.keys(filters).forEach((key) => {
    const filterObj = filters[key]?.[0]?.value?.value;
    if (filterObj) {
      Object.keys(filterObj).forEach((key) => {
        filterPayload[key] = filterObj[key];
      });
    }
  });

  return filterPayload;
};
