import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import { Text, Flex } from '@chakra-ui/layout';

const NoDataFound: FC<{ darkMode: any, value: any }> = ({ darkMode, value }) => {
  return (
    <Flex bg={darkMode ? '#262626' : 'white'} p="3" flexDirection="column">
      <Text fontWeight="bold" color={darkMode ? 'white' : 'black'}>
        {value}
      </Text>
    </Flex>
  );
};

export default NoDataFound;
