import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { connect } from 'react-redux';
import LogoNotFound from '../../../public/images/logo-not-found.png';

const SymbolLogo = ({ logoUrl, darkMode }) => {
  const CompanyLogoWrapper = styled.div`
    ${tw`w-10 h-10 p-1.5 rounded flex items-center justify-center`}
    background-color: ${(props) => (darkMode ? 'white' : 'white')};
  `;

  return (
    <CompanyLogoWrapper>
      <img
        src={logoUrl}
        onError={(event) => {
          event.target.src = LogoNotFound;
        }}
      />
    </CompanyLogoWrapper>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(SymbolLogo);
