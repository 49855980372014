import React from 'react';
import { connect } from 'react-redux';
import { getDividendData } from '../../../actions/action';
import { showPageLoading } from '../../../actions/coreAction';
import { useParams } from 'react-router-dom';
import {
  SectionDivider,
  ColoredContentText,
} from '../../shared/StyedComponents';
import CurrentYield from '../../shared/Sections/Dividend/CurrentYield';
import TTMDividendYield from '../../shared/Sections/Dividend/TTMDividendYield';
import YieldOnCost from '../../shared/Sections/Dividend/YieldOnCost';
import { QUARTERLY } from '../../../utils/ChartUtils';
import DividendHistory from '../../shared/Sections/Dividend/DividendHistory';
import NetIncomePayoutRatio from '../../shared/Sections/Dividend/NetIncomePayoutRatio';
import FreeCashFlowPayoutRatio from '../../shared/Sections/Dividend/FreeCashFlowPayoutRatio';
import TotalShareholderReturn from '../../shared/Sections/Dividend/TotalShareholderReturn';

function Dividend({
  symbol,
  loading,
  showPageLoading,
  getDividendData,
  data,
  insights,
  error,
  symbolData,
}) {
  const params = useParams();
  const [period, setPeriod] = React.useState(QUARTERLY);

  React.useEffect(() => {
    params.sid !== symbol && getDividendData(params.sid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    showPageLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (error) throw new Error('Error in dividend api');

  if (!data?.current?.yieldPercent && Number(data?.current?.yieldPercent) == 0)
    return (
      <ColoredContentText className="text-center">
        <span className="font-medium">{params.sid.toUpperCase()}</span> does not
        currently pay a dividend.
      </ColoredContentText>
    );

  return (
    data && (
      <>
        <CurrentYield data={data.current} insights={insights} />
        {!symbolData?.symbol?.isAdr && (
          <>
            <SectionDivider />
            <TTMDividendYield data={data.history} insights={insights} />
            <SectionDivider />
            <YieldOnCost
              data={data.history}
              period={period}
              setPeriod={setPeriod}
              insights={insights}
            />
            <SectionDivider />
            <DividendHistory
              data={data.history}
              period={period}
              setPeriod={setPeriod}
              insights={insights}
            />
            <SectionDivider />
            <NetIncomePayoutRatio
              data={data.history}
              period={period}
              setPeriod={setPeriod}
              insights={insights}
            />
            <SectionDivider />
            <FreeCashFlowPayoutRatio
              data={data.history}
              period={period}
              setPeriod={setPeriod}
              insights={insights}
            />
            <SectionDivider />
            <TotalShareholderReturn
              data={data.history}
              period={period}
              setPeriod={setPeriod}
              insights={insights}
            />{' '}
          </>
        )}
      </>
    )
  );
}

const mapStateToProps = (state) => ({
  symbol: state.dividendState.symbol,
  loading: state.dividendState.loading || state.valuationState.loading,
  data: state.dividendState.data,
  insights: state.symbolInsightsState.data?.dividendInsights,
  error: state.dividendState.error,
  symbolData: state.valuationState.data,
});
const mapDispatchToProps = {
  showPageLoading,
  getDividendData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dividend);
