import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { gtag } from '../../../utils/analytics';
import { showErrorAlert, showSuccessAlert } from '../../../actions/alertAction';
import { USER_SOCIAL_LINK } from '../../../config/default';
import { Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import {
  showPageLoading,
  setOpenYourVerdePlanModal,
} from '../../../actions/coreAction';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Flex,
  Text,
  Spacer,
  Box,
  Center,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import WrappedAxios from '../../../utils/WrappedAxios';
import ChakraIcon from '../ChakraIcon';

const YourVerdePlanModal = ({
  openYourVerdePlanModal,
  setOpenYourVerdePlanModal,
  showPageLoading,
  darkMode,
  subscriptionStatus,
}) => {
  const closeModal = () => setOpenYourVerdePlanModal(false);

  return (
    <Modal
      isOpen={openYourVerdePlanModal}
      onClose={closeModal}
      size="sm"
      isCentered
    >
      <ModalOverlay
        backdropFilter="auto"
        bg="blackAlpha.900"
        backdropBlur="10px"
      />
      <ModalContent
        color={darkMode ? '#fff' : 'rgb(51, 51, 51)'}
        bg={darkMode ? 'rgba(30, 30, 30, 0.93)' : 'rgba(255, 255, 255, 0.93)'}
      >
        <ModalHeader>
          Your Plan
          <Divider color={'rgba(255, 255, 255, 0.4)'} mt={2} />
        </ModalHeader>

        <ModalBody>
          <Flex>
            <Text
              color={darkMode ? '#4adf79' : 'rgb(56, 168, 118)'}
              fontWeight={500}
            >
              Verde+
            </Text>
            <Spacer />
            <Text
              color={
                darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'
              }
              fontSize="xs"
              fontWeight={500}
            >
              USD $4.99/mo
            </Text>
          </Flex>
          <Center>
            <Box
              px={12}
              py={2}
              bg={darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}
              borderRadius={18}
              width={'fit-content'}
              color={'#fff'}
              my={4}
              fontWeight={500}
              fontSize={14}
            >
              Your current plan
            </Box>
          </Center>
          <List spacing={4} my={2}>
            <ListItem>
              <Flex>
                <ListIcon>
                  <ChakraIcon value="YourVerdePlanCheck" />
                </ListIcon>
                <Text fontSize="xs">
                  Unlock Verde+ for unrestricted access to in-depth stock & ETF
                  research.
                </Text>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex>
                <ListIcon>
                  <ChakraIcon value="YourVerdePlanCheck" />
                </ListIcon>
                <Text fontSize="xs">
                  Take advantage of portfolio tracking & optimization tools.
                </Text>
              </Flex>
            </ListItem>
            {/* <ListItem>
              <Flex>
                <ListIcon color="green.500" />
                <Text fontSize="xs">
                  Gain exclusive beta access to our resident generative AI
                  financial advisor, Richie!
                </Text>
              </Flex>
            </ListItem> */}

            <ListItem>
              <Flex>
                <ListIcon>
                  <ChakraIcon value="YourVerdePlanCheck" />
                </ListIcon>
                <Text fontSize="xs">
                  Elevate your financial decision-making with Verde+.
                </Text>
              </Flex>
            </ListItem>
          </List>
        </ModalBody>
        <ModalFooter display={'block'}>
          {subscriptionStatus?.store === 'stripe' ? (
            <Box>
              <Text
                fontSize="xs"
                color={darkMode ? '#fff' : 'rgb(51, 51, 51)'}
                textDecoration={'underline'}
                cursor={'pointer'}
                onClick={() => {
                  closeModal();
                  showPageLoading(true);
                  WrappedAxios.post(
                    `${USER_SOCIAL_LINK}/subscriptions/billing-session`
                  ).then(({ data }) => {
                    window.location.replace(data.url);
                  });
                }}
              >
                Manage my subscription
              </Text>
            </Box>
          ) : (
            <Box>
              <Text
                fontSize="xs"
                color={darkMode ? '#fff' : 'rgb(51, 51, 51)'}
                cursor={'pointer'}
              >
                Manage subscription via App or Play Store
              </Text>
            </Box>
          )}
          <Box my={2}>
            <a
              href="https://www.notion.so/verde/Frequently-Asked-Questions-7dfc6d99d67f4ee3908c33a5fe1a4599"
              target="_blank"
            >
              <Text
                fontSize="xs"
                color={darkMode ? '#fff' : 'rgb(51, 51, 51)'}
                textDecoration={'underline'}
                cursor={'pointer'}
              >
                I need help with a billing issue
              </Text>
            </a>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  openYourVerdePlanModal: state.coreState.openYourVerdePlanModal,
  subscriptionStatus: state.coreState.subscriptionStatus,
});

const mapDispatchToProps = {
  showErrorAlert,
  showSuccessAlert,
  showPageLoading,
  setOpenYourVerdePlanModal,
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(YourVerdePlanModal);
