import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { connect } from 'react-redux';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import PageContainer from '../../shared/PageContainer';
import valueIcon from '../../../public/images/summary/value.svg';
import marginIcon from '../../../public/images/summary/margins.svg';
import balanceIcon from '../../../public/images/summary/balance.svg';
import dividendIcon from '../../../public/images/summary/dividend.svg';
import growthIcon from '../../../public/images/summary/growth.svg';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import {
  getStockInfo,
  getSummaryData,
  getPastPerformanceData,
} from '../../../actions/action';
import { fetchSymbolInsights } from '../../../actions/insightsAction';
import Chart from './chart';
import { showPageLoading } from '../../../actions/coreAction';
import ToggleButton from '../../shared/ToggleButton';
import { ANNUALLY, QUARTERLY } from '../../../utils/ChartUtils';
import { SimpleGrid } from '@chakra-ui/react';
import { formatToUSDollars } from '../../../utils/CurrencyFormatter';
import { formatDecimalValue } from '../../../utils/number';
const themeObj = {
  dark: {
    arrowBackColor: '#24b552',
    textColor: 'white',
    summaryCardbg: '#272c34',
    summaryCardBorder: 'transparent',
    buttonBg: '#36a867',
    buttonColor: 'white',
    buttonUnSelectBg: '#141920',
    buttonBorder: '#5a5d74',
  },
  light: {
    arrowBackColor: '#24b552',
    textColor: 'black',
    summaryCardbg: '#ffffff',
    summaryCardBorder: '#e7e9ef',
    buttonBg: '#36a867',
    buttonColor: 'white',
    buttonUnSelectBg: '#141920',
    buttonBorder: '#5a5d74',
  },
};
type Theme = DefaultTheme & { isDark: boolean };
const getCardData = (payload: any = {}) => {
  if (!payload.marketcap_mln) {
    return [];
  }
  return [
    [
      {
        title: 'Market Capitalization',
        value: formatToUSDollars(payload.marketcap_mln),
      },
      {
        title: 'Enterprise Value',
        value: formatToUSDollars(payload.enterprise_value),
      },
      {
        title: 'PE (TTM)',
        value: formatDecimalValue(payload.pe_ratio) + 'x',
      },
      {
        title: 'PE (FWD)',
        value: formatDecimalValue(payload.forward_pe_ratio) + 'x',
      },

      {
        title: 'Price To Sales (TTM)',
        value: formatDecimalValue(payload.price_to_sale) + 'x',
      },
      {
        title: 'Price To Book (MRQ)',
        value: formatDecimalValue(payload.price_to_book) + 'x',
      },
    ],
    [
      {
        title: 'Revenue Growth (FY+1)',
        value: `${formatDecimalValue(payload.revenue_growth)} %`,
      },
      {
        title: 'Net Income Growth (FY+1)',
        value: `${formatDecimalValue(payload.earnings_growth)} %`,
      },
      {
        title: 'EPS Growth (FY+1)',
        value: `${formatDecimalValue(payload.eps_growth)} %`,
      },
    ],
    [
      {
        title: 'Operating Margin (TTM)',
        value: `${formatDecimalValue(payload.operating_margin)} %`,
      },
      {
        title: 'Net Margin (TTM)',
        value: `${formatDecimalValue(payload.net_income_margin)} %`,
      },
      {
        title: 'FCF Margin (TTM)',
        value: `${formatDecimalValue(payload.free_cash_flow_margin)} %`,
      },
      {
        title: 'Return on Assets (ROA)',
        value: `${formatDecimalValue(payload.return_on_assets)} %`,
      },
      {
        title: 'Return on Equity (ROE)',
        value: `${formatDecimalValue(payload.return_on_equity)} %`,
      },
      {
        title: 'Return on Invested Capital (ROIC)',
        value: `${formatDecimalValue(payload.return_on_invested_capital)} %`,
      },
    ],
    [
      {
        title: 'Debt to EBITDA (MRQ)',
        value: formatDecimalValue(payload?.debt_ebitda) + 'x',
      },
      {
        title: 'Debt to Equity (MRQ)',
        value: formatDecimalValue(payload?.debt_equity) + 'x',
      },
      {
        title: 'Debt to Capital (MRQ)',
        value: formatDecimalValue(payload?.debt_capital) + 'x',
      },
    ],
    [
      {
        title: 'Dividend Dividend per Share (TTM)',
        value: `$${formatDecimalValue(payload.dividend_share)}`,
      },
      {
        title: 'Dividend Yield (TTM)',
        value: `${formatDecimalValue(payload.yield_fwd)} %`,
      },
      {
        title: 'Payout Ratio (TTM)',
        value: `${formatDecimalValue(payload.payout_ratio)} %`,
      },
    ],
  ];
};

const getChartData = (data: Array<{ adjusted_price: string }>) => {
  return data.map((item: any) => [
    new Date(item.price_on_date).getTime(),
    Number(item.adjusted_price)
  ]);
};
const getPerformanceData = (data: Array<{}> = [], period: String) => {
  const result = {
    ebitdaData: [],
    freeCashFlow: [],
    netIncome: [],
    debt: [],
    revenue: [],
    cash: [],
    dividend: [],
    marketCap: [],
    sharesOutstanding: [],
    operatingIncome: [],
    totalAssets: [],
    totalLiabilities: [],
    earningsPerShare: [],
    pe: [],
    ptb: [],
    peg: [],
    pts: [],
  };
  data
    ?.sort(
      (a: any, b: any) =>
        new Date(a.evaluated_on).getTime() - new Date(b.evaluated_on).getTime()
    )
    .filter((a: any) => a.metric_type === period)
    .reduce((acc: any, item: any) => {
      const timestamp = new Date(item.evaluated_on).getTime();

      acc.ebitdaData.push([timestamp, item.ev_ebitda]);
      acc.freeCashFlow.push([timestamp, item.free_cash_flow]);
      acc.netIncome.push([timestamp, item.net_income]);
      acc.debt.push([timestamp, item.total_debt]);
      acc.revenue.push([timestamp, item.revenue]);
      acc.dividend.push([timestamp, item.dividend_paid_per_share]);
      acc.cash.push([timestamp, item.cash_st_investments]);
      acc.marketCap.push([timestamp, item.marketcap_mln]);
      acc.sharesOutstanding.push([timestamp, item.shares_data]);
      acc.operatingIncome.push([timestamp, item.ebit]);
      acc.totalAssets.push([timestamp, item.total_assets]);
      acc.totalLiabilities.push([timestamp, item.total_liabilities]);
      acc.earningsPerShare.push([timestamp, item.eps_actual]);
      acc.pe.push([timestamp, item.pe_ratio]);
      acc.peg.push([timestamp, item.price_earnings_growth]);
      acc.ptb.push([timestamp, item.price_to_book]);
      acc.pts.push([timestamp, item.price_to_sales]);

      return acc;
    }, result);
  return result;
};
type CardValue = { title: string; value: string | number };
function SymbolSummary(darkMode: any) {
  const currentTheme = useTheme() as Theme;
  const params: { sid: string } = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [period, setPeriod] = useState(ANNUALLY);
  const [graphIntervalSeconds, setGraphIntervalSeconds] = useState(
    3 * 30 * 24 * 3600 * 1000
  );

  useEffect(() => {
    const isQuarter = period === QUARTERLY;
    setGraphIntervalSeconds(
      isQuarter ? 3 * 30 * 24 * 3600 * 1000 : 12 * 30 * 24 * 3600 * 1000
    );
  }, [period]);
  const {
    summaryData,
    stockPriceHistory,
    pastPerformance,
    loading,
    insights,
  } = useAppSelector((state) => ({
    summaryData: state.summaryState.data,
    stockPriceHistory: state.stockHistoryState.data,
    pastPerformance: state.pastPerformanceState.data,
    insights: state.symbolInsightsState.data?.valuation,
    loading:
      state.summaryState.loading ||
      state.stockHistoryState.loading ||
      state.pastPerformanceState.loading,
  }));
  useEffect(() => {
    dispatch(showPageLoading(loading));
  }, [loading]);
  const priceData = useMemo(() => getChartData(stockPriceHistory), [
    stockPriceHistory,
  ]);
  
  const {
    ebitdaData,
    freeCashFlow,
    netIncome,
    debt,
    revenue: revenueData,
    dividend,
    cash,
    marketCap,
    sharesOutstanding,
    operatingIncome,
    totalAssets,
    totalLiabilities,
    earningsPerShare,
    pe,
    ptb,
    pts,
    peg,
  } = useMemo(() => getPerformanceData(pastPerformance, period), [
    pastPerformance,
    period,
  ]);

  const [
    valueCard,
    growthCard,
    marginCard,
    balanceCard,
    dividendCard,
  ] = useMemo(() => getCardData(summaryData), [summaryData]);
  console.log("valueCard");
  console.log(getCardData(summaryData));

  useEffect(() => {
    dispatch(getSummaryData(params.sid));
    dispatch(getPastPerformanceData(params.sid));
    dispatch(getStockInfo(params.sid));
    dispatch(fetchSymbolInsights(params.sid));
  }, [params.sid]);
  const theme = currentTheme.isDark ? themeObj.dark : themeObj.light;
  return (
    <PageContainer className="">
      <Flex
        alignItems="center"
        onClick={() => history.push(`/stocks/${params.sid}`)}
        cursor="pointer"
      >
        <ArrowBackIcon color={theme.arrowBackColor} />
        <Text
          m="1"
          color={theme.textColor}
          fontSize="sm"
          fontWeight="semibold"
          textTransform="uppercase"
        >
          {params.sid}
        </Text>
      </Flex>
      {valueCard ? (
        <SimpleGrid
          mt="2"
          justifyContent="space-between"
          gridGap="4"
          columns={[2, 2, 2, 4]}
          spacing="10"
        >
          <SummaryCard title="Valuation" icon={valueIcon} theme={theme}>
            {valueCard.map((value: CardValue) => (
              <Flex justifyContent="space-between" fontSize="sm" my="1">
                <Text color={theme.textColor}>{value.title}</Text>
                <Text color={theme.textColor} whiteSpace="nowrap">
                  {value.value}
                </Text>
              </Flex>
            ))}
          </SummaryCard>
          <SummaryCard title="Growth" icon={marginIcon} theme={theme}>
            {growthCard.map((value: CardValue) => (
              <Flex justifyContent="space-between" fontSize="sm" my="1">
                <Text color={theme.textColor}>{value.title}</Text>
                <Text color={theme.textColor} whiteSpace="nowrap">
                  {value.value}
                </Text>
              </Flex>
            ))}
          </SummaryCard>
          <SummaryCard title="Profitability" icon={growthIcon} theme={theme}>
            {marginCard.map((value: CardValue) => (
              <Flex justifyContent="space-between" fontSize="sm" my="1">
                <Text color={theme.textColor}>{value.title}</Text>
                <Text color={theme.textColor} whiteSpace="nowrap">
                  {value.value}
                </Text>
              </Flex>
            ))}
          </SummaryCard>
          <SummaryCard title="Dividend" icon={dividendIcon} theme={theme}>
            {dividendCard.map((value: CardValue) => (
              <Flex justifyContent="space-between" fontSize="sm" my="1">
                <Text color={theme.textColor}>{value.title}</Text>
                <Text color={theme.textColor} whiteSpace="nowrap">
                  {value.value}
                </Text>
              </Flex>
            ))}
          </SummaryCard>
        </SimpleGrid>
      ) : null}
      <Flex justifyContent="center" mt="10" fontSize="small">
        <ToggleButton
          forceBorder
          period={period}
          setPeriod={setPeriod}
        ></ToggleButton>
      </Flex>

      <SimpleGrid mt="10" columns={[1, 2, 2, 4]} spacing={10}>
        <Chart
          title="Share Price"
          color={darkMode ? '#7bbfff' : '#448EF7'}
          type="line"
          data={priceData}
          //graphInterval={graphIntervalSeconds}
          darkMode={currentTheme.isDark}
        ></Chart>
        <Chart
          title="Market Cap"
          color={darkMode ? '#7bbfff' : '#448EF7'}
          data={marketCap}
          graphInterval={graphIntervalSeconds}
          darkMode={currentTheme.isDark}
        ></Chart>
        <Chart
          title="Shares Outstanding"
          color={darkMode ? '#7bbfff' : '#448EF7'}
          isNumber
          data={sharesOutstanding}
          graphInterval={graphIntervalSeconds}
          darkMode={currentTheme.isDark}
        ></Chart>
        <Chart
          title="Price to Earnings"
          color={darkMode ? '#D49CFF' : '#AB81E4'}
          suffix="x"
          type="line"
          isNumber
          data={pe}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
        {/* <Chart
          title="Price to Growth"
          color="#acbdc2"
          data={peg}
          suffix="x"
          type="line"
          isNumber
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
        <Chart
          title="Price to Book"
          color="#cdab8c"
          data={ptb}
          type="line"
          suffix="x"
          isNumber
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
        <Chart
          title="Price to Sales"
          color="#acbdc2"
          data={pts}
          suffix="x"
          type="line"
          isNumber
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart> */}
        <Chart
          title="Revenue (TTM)"
          color="#d2a0b1"
          data={revenueData}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>

        {/* <Chart
            title="EBITDA"
            color="#979fd9"
            data={ebitdaData}
            darkMode={currentTheme.isDark}
            graphInterval={graphIntervalSeconds}
          ></Chart> */}

        <Chart
          title="Operating Income (TTM)"
          color="#d2a0b1"
          data={operatingIncome}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
        <Chart
          title="Net Income (TTM)"
          color={darkMode ? '#7bbfff' : '#448EF7'}
          data={netIncome}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
        <Chart
          title="Free cash flow (TTM)"
          data={freeCashFlow}
          color={darkMode ? '#EEB041' : '#FFC176'}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
        <Chart
          title="Total Assets (MRQ)"
          data={totalAssets}
          color={darkMode ? '#D49CFF' : '#AB81E4'}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>

        <Chart
          title="Total Liabilities (MRQ)"
          data={totalLiabilities}
          color={darkMode ? '#D49CFF' : '#AB81E4'}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>

        <Chart
          title="Total Debt (MRQ)"
          data={debt}
          color={darkMode ? '#EEB041' : '#FFC176'}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
        <Chart
          title="Total Cash (MRQ)"
          data={cash}
          color={darkMode ? '#D49CFF' : '#AB81E4'}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
        <Chart
          title="Earnings per Share (TTM)"
          data={earningsPerShare}
          color={darkMode ? '#D49CFF' : '#AB81E4'}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
        <Chart
          title="Dividend per Share (TTM)"
          color="#acbdc2"
          data={dividend}
          darkMode={currentTheme.isDark}
          graphInterval={graphIntervalSeconds}
        ></Chart>
      </SimpleGrid>
    </PageContainer>
  );
}

const SummaryCard: FC<{
  title: string;
  icon: string;
  theme: typeof themeObj.dark;
}> = ({ title, icon, theme, children }) => {
  return (
    <Flex
      bg={theme.summaryCardbg}
      p="4"
      border="1px solid"
      borderColor={theme.summaryCardBorder}
      flexDir="column"
      borderRadius="sm"
      flex="1"
    >
      <Flex>
        <Text color={theme.textColor} fontWeight="semibold" mr="4">
          {title}
        </Text>
        <Box>
          <img src={icon} />
        </Box>
      </Flex>
      <Flex flexDir="column" mt="4">
        {children}
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state: any) => ({
  darkMode: state.coreState.darkMode,

})

export default connect(mapStateToProps)(SymbolSummary);