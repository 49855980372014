import {
  Container as ContainerWrapper,
  HeadingProps,
  Heading,
  ContainerProps,
} from '@chakra-ui/react';
import React from 'react';
export const containerBreakpoints = [
  'container.sm',
  'container.md',
  'container.lg',
  'container.xl',
];

export const h1SizeBreakpoints = ['4xl', '5xl', '6xl', '7xl'];
export const sectionHeaderBreakpoints = ['lg', 'xl', '2xl', '2xl'];
export const Container = (props: ContainerProps) => (
  <ContainerWrapper maxW={containerBreakpoints} {...props}>
    {props.children}
  </ContainerWrapper>
);
export const SectionHeader = (props: HeadingProps) => (
  <Heading as="h2" fontSize={sectionHeaderBreakpoints} {...props}>
    {props.children}
  </Heading>
);
