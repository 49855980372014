import ValuationCM from '../models/ValuationCM';
import { deserialise as deserialiseSymbol } from './SymbolDeserialiser';
import { deserialise as deserialiseGrowthMetric } from './GrowthMetricDeserialiser';
import { deserialise as deserialiseAggregratedMetric } from './AggregratedMetricDeserialiser';

export const deserialise = (payload) =>
  new ValuationCM.builder()
    .symbol(deserialiseSymbol(payload))
    .growthMetrics(
      payload.symbols_growth
        ? payload.symbols_growth.map((growth) =>
            deserialiseGrowthMetric(growth)
          )
        : []
    )
    .priceToEarnings(
      payload.aggregated_metrics && payload.aggregated_metrics.pe_ratio
        ? deserialiseAggregratedMetric(payload.aggregated_metrics.pe_ratio)
        : []
    )
    .priceToEarningsGrowth(
      payload.aggregated_metrics && payload.aggregated_metrics.peg_ratio
        ? deserialiseAggregratedMetric(payload.aggregated_metrics.peg_ratio)
        : []
    )
    .priceToBook(
      payload.aggregated_metrics && payload.aggregated_metrics.pb_ratio
        ? deserialiseAggregratedMetric(payload.aggregated_metrics.pb_ratio)
        : []
    )
    .priceToSales(
      payload.aggregated_metrics && payload.aggregated_metrics.price_to_sale
        ? deserialiseAggregratedMetric(payload.aggregated_metrics.price_to_sale)
        : []
    )
    .build();
