import axios from 'axios';
import { Auth } from 'aws-amplify';

const wrappedAxios = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(
    async (config) => {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      //console.log(token);
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  return instance;
};

export default wrappedAxios();
