import React from 'react';
import MultiLineGraphSelectorButton from '../../../MultiLineGraphSelectorButton';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import { getStackedAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';

const IncomeStatement = ({ data, insights, period, setPeriod, darkMode }) => {
  const [buttonValues, setButtonValues] = React.useState([
    {
      name: 'Revenue',
      selected: true,
      darkColor: '#D49CFF',
      lightColor: '#B47FFF',
    },
    {
      name: 'Gross Profit',
      selected: false,
      darkColor: '#FFC176',
      lightColor: '#DDAD07',
    },
    {
      name: 'EBITDA',
      selected: false,
      darkColor: '#7BBFFF',
      lightColor: '#099AFB',
    },
    {
      name: 'EBIT',
      selected: true,
      darkColor: '#DDDDDD',
      lightColor: '#012749',
    },
    {
      name: 'Net Income',
      selected: true,
      darkColor: '#FF7979',
      lightColor: '#F26970',
    },
  ]);

  const fullChartData = getStackedAreaChartData(
    data,
    ['evaluated_on', 'revenue', 'gross_profit', 'ebitda', 'ebit', 'net_income'],
    period
  );
  const chartVariants = buttonValues.reduce((obj, current) => {
    obj[current.name] = current;
    return obj;
  }, {});

  const chartData = [
    chartVariants['Revenue'].selected
      ? {
          name: 'Revenue',
          data: fullChartData.map((item) => [item[2], item[3]]),
        }
      : [],
    chartVariants['Gross Profit'].selected
      ? {
          name: 'Gross Profit',
          data: fullChartData.map((item) => [item[2], item[5]]),
        }
      : [],
    chartVariants['EBITDA'].selected
      ? {
          name: 'EBITDA',
          data: fullChartData.map((item) => [item[2], item[0]]),
        }
      : [],
    chartVariants['EBIT'].selected
      ? {
          name: 'EBIT',
          data: fullChartData.map((item) => [item[2], item[1]]),
        }
      : [],
    chartVariants['Net Income'].selected
      ? {
          name: 'Net Income',
          data: fullChartData.map((item) => [item[2], item[4]]),
        }
      : [],
  ];

  return (
    <>
      <SectionHeader className="mb-6" value="Income Statement" />
      <BorderedChartContainer>
        <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div>
        <StackedLineChart data={chartData} valueType="currency" />
        <div className="flex justify-center">
          <MultiLineGraphSelectorButton
            values={buttonValues}
            setValues={setButtonValues}
          />
        </div>
      </BorderedChartContainer>

      <BulletPoints
        title="Positives"
        points={insights?.incomeStatement?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.incomeStatement?.negatives}
        isPositive={false}
      />
    </>
  );
};

export default IncomeStatement;
