import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import { ANNUALLY, getChartData } from '../../../../../utils/ChartUtils';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import BarLineComboChart from '../../../BarLineComboChartRevamp';
import SectionHeader from '../../../SectionHeader';
import RiskPositives from '../../../RiskPositives';

const FreeCashFlowPayoutRatio = ({
  data,
  insights,
  period,
  setPeriod,
  darkMode,
}) => {
  const chartData = getChartData(
    data,
    ['free_cash_flow', 'fcf_payout_ratio', 'evaluated_on'],
    ANNUALLY
  ).map((item) => [item[0], item[1], item[2]]);

  return (
    <>
      <SectionHeader
        value="FCF Payout Ratio"
        className="pb-6"
        infoKey="fcf_payout_ratio"
      />
      <div style={{ marginTop: '10px' }}>
        {/* <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div> */}
        <BarLineComboChart
          data={[
            {
              name: 'Total Dividends Paid Out',
              data: chartData.map((d) => d[1] * d[2] * 0.01),
            },
            {
              name: 'Free Cash Flow',
              data: chartData.map((d) => d[2]),
            },
            {
              name: 'FCF Payout Ratio',
              data: chartData.map((d) => d[1]),
            },
          ]}
          xAxisData={chartData.map((d) => d[0])}
          name="FCF Payout Ratio"
        />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          height: 'auto',
          flexWrap: 'wrap',
          margin: '10px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: 'rgba(67, 141, 247)',
              borderRadius: 7,
              marginRight: 10,
              marginTop: 5,
            }}
          />
          <p
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Total Dividend Paid Out
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: 'rgb(143, 188, 250)',
              borderRadius: 7,
              marginRight: 10,
              marginTop: 5,
            }}
          />
          <p
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Free Cash Flow
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: '#9e71ff',
              borderRadius: 7,
              marginRight: 10,
              marginTop: 5,
            }}
          />
          <p
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            FCF Payout Ratio
          </p>
        </div>
      </div>
      <RiskPositives
        positives={insights?.fcf_payout?.positives}
        negatives={insights?.fcf_payout?.negatives}
      />
    </>
  );
};

export default FreeCashFlowPayoutRatio;
