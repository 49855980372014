import React, { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import SectionHeader from '../../shared/SectionHeader';
import { useMediaQuery } from '@chakra-ui/react';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AccountSecurity({ darkMode, data }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [passwordShown, setPasswordShown] = useState(false);
  return (
    <Box>
      <Box>
        <SectionHeader value="Account Security" />
        <Text
          style={{
            color: darkMode ? 'white' : 'black',
            fontSize: 17,
            fontWeight: 549,
            marginTop: 5,
          }}
        >
          Change Password
        </Text>
      </Box>
      <Box>
        <Box w="70%" alignItems="center" justifyContent="center" marginTop={5}>
          <Flex alignItems="center">
            <input
              style={{
                fontSize: '16',
                fontWeight: 550,
                borderRadius: 6,
                backgroundColor: 'transparent',
                width: isMobile ? '100%' : '85%',
                height: 40,
                padding: 8,
                border: '1px solid #d9d9d9',
                color: darkMode ? 'white' : 'black',
              }}
              id="currentPasswordInput"
              type={passwordShown ? 'text' : 'password'}
              placeholder="Current Password"
            />
            <Box position="relative" right={isMobile ? 6 : 10}>
              <FontAwesomeIcon
                onClick={() => setPasswordShown(!passwordShown)}
                icon={passwordShown ? faEye : faEyeSlash}
                width={60}
                height={60}
                color={darkMode ? 'white' : 'color'}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Flex>
          <Text
            style={{
              color: darkMode ? '#4adf79' : '#01874e',
              cursor: 'pointer',
            }}
            mt={2}
            fontWeight="bold"
            fontSize="small"
          >
            Forgot Password ?
          </Text>
        </Box>

        <Box w="70%" alignItems="center" justifyContent="center" marginTop={5}>
          <input
            style={{
              fontSize: '16',
              fontWeight: 550,
              borderRadius: 6,
              backgroundColor: 'transparent',
              width: isMobile ? '100%' : '85%',
              height: 40,
              padding: 8,
              border: '1px solid #d9d9d9',
              color: darkMode ? 'white' : 'black',
            }}
            id="NewPasswordInput"
            type="password"
            placeholder="New Password"
          />
        </Box>

        <Box w="70%" alignItems="center" justifyContent="center" marginTop={5}>
          <input
            style={{
              fontSize: '16',
              fontWeight: 550,
              borderRadius: 6,
              backgroundColor: 'transparent',
              width: isMobile ? '100%' : '85%',
              height: 40,
              padding: 8,
              border: '1px solid #d9d9d9',
              color: darkMode ? 'white' : 'black',
            }}
            id="confirmCurrentPasswordInput"
            type="password"
            placeholder="Confrim New Password"
          />
        </Box>
      </Box>
      <Flex>
        <button
          style={{
            padding: 5,
            width: '120px',
            backgroundColor: darkMode ? '#4adf79' : '#01874e',
            color: darkMode ? 'black' : 'white',
            borderRadius: 20,
            marginTop: 20,
            marginRight: 5,
            fontSize: 15,
            fontWeight: 550,
          }}
        >
          Submit
        </button>
        <button
          style={{
            padding: 5,
            width: '120px',
            color: darkMode ? '#4adf79' : '#01874e',
            border: `1.5px solid ${darkMode ? '#4adf79' : '#01874e'}`,
            borderRadius: 20,
            marginTop: 20,
            marginLeft: 5,
            fontSize: 15,
            fontWeight: 550,
          }}
        >
          Cancel
        </button>
      </Flex>
    </Box>
  );
}
export default AccountSecurity;
