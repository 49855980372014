import React, { useState, useEffect } from 'react';
import SectionHeader from '../../../SectionHeader';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import Chart from '../Chart';
import { useMediaQuery } from '@chakra-ui/react';
import { useRef } from 'react';

function ETFHoldings({ data, darkMode }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const [selected, setSelected] = useState({});
  const [clicked, setClicked] = useState({});
  const [symbol, setSymbol] = useState('');
  const [randomizedColors, setRandomizedColors] = useState([]);

  const colors = [
    darkMode ? '#D49CFF' : '#ab81e4', //purple
    darkMode ? '#553e66' : '#785aa0',
    darkMode ? '#eed7ff' : '#ddcdf4',
    darkMode ? '#7bbfff' : '#785aa0', //blue
    darkMode ? '#314c66' : '#2247cc',
    darkMode ? '#cae5ff' : '#b4d2fc',
    darkMode ? '#ffc176' : '#eeb041', //yellow
    darkMode ? '#664d2f' : '#5f461a',
    darkMode ? '#ffe0bb' : '#f8dfb3',
    darkMode ? '#ff9494' : '#ee8079', //pink :)
    darkMode ? '#a75a55' : '#804a4a',
    darkMode ? '#ffcaca' : '#f8ccc9',
    // '#eeb041',
    // '#cbb6f8',
    // '#f4f0fd',
    // '#e5dafb',
  ];
  useEffect(() => {
    for (let i = 0; i < 5; i++) {
      let newArr = [];
      while (newArr.length < 10) {
        var r = Math.floor(Math.random() * 10) + 1;
        if (newArr.indexOf(r) === -1) newArr.push(r);
      }

      setRandomizedColors(newArr);
    }
  }, [darkMode]);

  let total = 0;
  let arr = [];
  for (let key in data['Top 100 Holdings']) {
    arr.push({
      ...data['Top 100 Holdings'][key],
      value: data['Top 100 Holdings'][key]['Asset_%'],
      symbol: key,
    });
  }
  let count = 0;

  arr = arr
    .sort((a, b) => b['Asset_%'] - a['Asset_%'])
    .slice(0, 20)
    .map((elem, index) => {
      if (count === 9) {
        count = 0;
      }
      total += elem.value;
      return { ...elem, color: colors[randomizedColors[count++]] };
    });

  arr.push({
    value: 100 - total,
    symbol: 'Other',
    color: colors[randomizedColors[count++]],
  });

  function callback(value, focused, mouseOut) {
    value && setSymbol(value.symbol);

    if (!focused) {
      value ? setSelected(value) : setSelected(undefined);
    } else {
      if (value === clicked) {
        setClicked({});
      } else {
        value && setClicked(value);
      }
    }

    if (mouseOut) {
      setSelected(undefined);
    }

    var element = selected
      ? document.getElementById(symbol)
      : document.getElementById(clicked.symbol);
    if (element !== null) {
      var headerOffset = isMobile ? 50 : 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      element.scrollIntoView({
        top: offsetPosition,
        block: 'nearest',
        inline: 'start',
      });
    }
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex' }}>
          <SectionHeader value={'Holdings'} infoKey="val" />

          <div style={{ marginTop: '2px', marginLeft: '2px' }}>
            <InfoOutlineIcon
              color="#0072db"
              height="16px"
              width="16px"
            ></InfoOutlineIcon>
          </div>
        </div>

        <h1
          style={{
            color: darkMode ? 'white' : 'black',
            fontSize: '23px',
            marginTop: '25px',
            fontWeight: 'bold',
          }}
        >
          {data.ETF_Data.ETF_Code} ETF's Top 20 Holdings
        </h1>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Chart
            data={arr}
            darkMode={darkMode}
            callback={callback}
            title="Holdings"
          />

          <div
            style={{
              marginTop: '20px',
              marginLeft: 'auto',
              marginRight: 'auto',
              height: '400px',
              overflowY: 'scroll',
              padding: 10,
            }}
          >
            {arr.map((elem) => (
              <div
                id={elem.symbol}
                style={{
                  display: 'flex',
                  marginBottom: '15px',
                  backgroundColor: selected
                    ? selected.symbol === elem.symbol
                      ? `${elem.color}40`
                      : ''
                    : clicked.symbol === elem.symbol
                    ? `${elem.color}40`
                    : '',
                  borderRadius: '15px',
                  margin: '10px',
                  padding: '10px',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                }}
              >
                <div
                  style={{
                    backgroundColor: elem.color,
                    width: '20px',
                    height: '20px',
                    borderRadius: '10px',
                  }}
                />
                <div style={{ width: '90px' }}>
                  <a
                    style={{
                      marginLeft: '10px',
                      fontSize: '16px',
                      fontWeight: selected
                        ? selected.color === elem.color &&
                          selected.symbol === elem.symbol
                          ? 'bold'
                          : ''
                        : clicked.color === elem.color &&
                          clicked.symbol === elem.symbol
                        ? 'bold'
                        : '',
                      color: darkMode ? '#cccccc' : 'black',
                      textDecoration:
                        elem.symbol !== 'Other'
                          ? selected
                            ? selected.color === elem.color &&
                              selected.symbol === elem.symbol
                              ? 'underline'
                              : ''
                            : clicked.color === elem.color &&
                              clicked.symbol === elem.symbol
                            ? 'underline'
                            : ''
                          : null,
                      textDecorationStyle: 'solid',
                      textUnderlineOffset:
                        elem.symbol !== 'Other'
                          ? selected
                            ? selected.color === elem.color &&
                              selected.symbol === elem.symbol
                              ? '4px'
                              : ''
                            : clicked.color === elem.color &&
                              clicked.symbol === elem.symbol
                            ? '4px'
                            : ''
                          : null,
                    }}
                    href={
                      elem.symbol !== 'Other'
                        ? `/stocks/${elem.SymbolMarket}`
                        : null
                    }
                    target={elem.symbol !== 'Other' ? 'blank' : null}
                  >
                    {elem.symbol}
                  </a>
                </div>
                <h4
                  style={{
                    marginLeft: '25px',
                    color: darkMode ? 'white' : 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {elem.value !== 0
                    ? `${parseFloat(elem.value).toFixed(2)}%`
                    : `${elem.value}%`}
                </h4>

                {selected &&
                selected.color === elem.color &&
                selected.symbol === elem.symbol ? (
                  <h4
                    style={{
                      marginLeft: '10px',
                      color: darkMode ? 'white' : 'black',
                      fontWeight: 'bold',
                      fontSize: '20px',
                      marginTop: '-5px',
                    }}
                  >
                    {'>'}
                  </h4>
                ) : clicked.color === elem.color &&
                  clicked.symbol === elem.symbol &&
                  selected === undefined ? (
                  <h4
                    style={{
                      marginLeft: '10px',
                      color: darkMode ? 'white' : 'black',
                      fontWeight: 'bold',
                      fontSize: '20px',
                      marginTop: '-5px',
                    }}
                  >
                    {'>'}
                  </h4>
                ) : (
                  <div />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ETFHoldings;
