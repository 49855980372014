import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useState, FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import VerdeLogo from '../../../public/images/VerdeLogo.png';
import { DefaultTheme, useTheme } from 'styled-components';
import { showPageLoading } from '../../../actions/coreAction';
import PageContainer from '../../shared/PageContainer';
import { useMediaQuery } from '@chakra-ui/react';
import WrappedAxios from '../../../utils/WrappedAxios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import TopProfile from './Overview/TopProfile.js';
import TopProfileMobile from './Overview/TopProfileMobile';
import Overview from './Overview/Overview';
import AssetClassification from './AssetClassification';
import { SUPER_INVESTOR_URL } from '../../../config/default';
import PortfolioPerformance from './Overview/PortfolioPerformance';
import MobileSummary from './MobileSummary';
import SectionHeader from '../../shared/SectionHeader';
import { getMyVerde } from '../../../actions/my-verde';
import clevertap from 'clevertap-web-sdk';
import { HeaderList } from './Activity/HeaderList';
import Activity from './Activity';

const PageContainerW = PageContainer;

const SuperInvestorMobile = ({
  darkMode,
  showPageLoading,
  socialUserName,
  socialEmail,
}) => {
  const currentTheme = useTheme();
  const [data, setData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams();
  const [tabSelected, setTabSelected] = useState('Portfolio');
  const [showSummary, setShowSummary] = useState(true);

  const mainTabs = [
    {
      title: 'Portfolio',
    },
    {
      title: 'Activity',
    },
    {
      title: 'Performance',
    },
    {
      title: 'Allocation',
    },
  ];

  showPageLoading(data.length === 0 || performanceData.length === 0);

  useEffect(() => {
    async function antiPattern() {
      setData(
        (
          await WrappedAxios.get(
            `${SUPER_INVESTOR_URL}/get-superinvestor-details/${params.cik}`
          )
        ).data
      );

      setPerformanceData(
        (
          await WrappedAxios.get(
            `${SUPER_INVESTOR_URL}/get-superinvestor-performance/${params.cik}`
          )
        ).data
      );

      setActivityData(
        (
          await WrappedAxios.get(
            `${SUPER_INVESTOR_URL}/get-superinvestor-overallactivity/${params.cik}`
          )
        ).data
      );

      setAssetData(
        (
          await WrappedAxios.get(
            `${SUPER_INVESTOR_URL}/get-cik-asset-details/${params.cik}`
          )
        ).data
      );
    }

    antiPattern();
  }, [params.cik]);

  useEffect(() => {
    if (data.length > 0) {
      clevertap.event.push('SuperInvestor Viewed', {
        SuperInvestorFundName: data[0]['fundName'],
        SuperInvestorFundManagerName: data[0]['fundManagerName'],
        Cik: params.cik,
        Email: socialEmail,
        Username: socialUserName,
      });
    }
  }, [data, params.cik, socialEmail, socialUserName]);

  // console.log('ind ' + JSON.stringify(data));
  // console.log('performance' , JSON.stringify(performanceData))
  // console.log(JSON.stringify(assetData))

  return (
    <Box backgroundColor={darkMode ? '#161616' : ''}>
      <PageContainerW>
        <Box>
          <TopProfile profileData={data} />
        </Box>
        <Flex
          //height="76px"
          borderRadius="5px"
          backgroundColor={darkMode ? '' : 'white'}
          marginBottom="25px"
          marginTop="40px"
          overflowX="scroll"
        >
          {mainTabs.map((elem) => {
            return (
              <div
                style={{
                  backgroundColor:
                    tabSelected === elem.title
                      ? `${darkMode ? '#4adf79' : '#01874e'}`
                      : `${darkMode ? 'transparent' : 'rgb(246,248,250)'}`,
                  marginLeft: 7,
                  marginRight: 7,
                  borderRadius: 10,
                  alignItems: 'center',
                  cursor: 'pointer',
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
                onClick={() => setTabSelected(elem.title)}
              >
                <Text
                  style={{
                    color:
                      tabSelected === elem.title
                        ? darkMode
                          ? 'black'
                          : 'white'
                        : darkMode
                        ? 'white'
                        : 'black',
                    fontWeight: tabSelected === elem.title ? 'bold' : 'normal',
                    lineHeight: '35px',
                    fontSize: '17px',
                  }}
                >
                  {elem.title}
                </Text>
              </div>
            );
          })}
        </Flex>

        <div>
          {tabSelected === 'Portfolio' && showSummary ? (
            <MobileSummary
              data={data}
              darkMode={darkMode}
              showPortfolioCallback={() => setShowSummary(false)}
            />
          ) : tabSelected === 'Portfolio' && !showSummary ? (
            <Overview
              data={data.length > 0 && data[0]}
              darkMode={darkMode}
              performanceData={performanceData}
              cik={params.cik}
              showPortfolioCallback={() => setShowSummary(true)}
            />
          ) : tabSelected === 'Performance' ? (
            <div style={{ padding: 10 }}>
              <SectionHeader value="Portfolio Performance" />
              <div
                style={{
                  backgroundColor: darkMode ? '' : 'rgb(246,248,250)',
                  padding: 10,
                  marginTop: 10,
                }}
              >
                <PortfolioPerformance
                  darkMode={darkMode}
                  data={performanceData[0].quarters}
                  valuationData={[]}
                />
              </div>
            </div>
          ) : tabSelected === 'Activity' ? (
            <div style={{ padding: 10 }}>
              <Activity
                data={activityData.length > 0 ? activityData[0].quarters : []}
                headerData={HeaderList}
                darkMode={darkMode}
                cik={params.cik}
              />
            </div>
          ) : (
            <div style={{ padding: 10 }}>
              <AssetClassification
                data={data.length > 0 ? data[0].quarters[0] : []}
                assetData={assetData.length > 0 ? assetData : []}
                // insightsData={insightsData}
                darkMode={darkMode}
              />
            </div>
          )}
        </div>
      </PageContainerW>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
  socialUserName: state.myVerde.username,
  socialEmail: state.myVerde.email,
});

const mapDispatchToProps = {
  showPageLoading,
  getMyVerde,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperInvestorMobile);
