import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { connect } from 'react-redux';
import VerdeScoreMeter from '../../../VerdeScoreMeter';
import RatingCode from '../../../RatingCode';
import SectionHeader from '../../../SectionHeader';
import DomainTerm from '../../../DomainTerm';
import RiskPositives from '../../../RiskPositives';
import BlurRating from '../../../../../public/images/BlurredValue.png';
import { useAuth } from '../../../../hocs/AuthProvider';

const RatingContainer = styled.div`
  ${tw`md:mx-auto md:max-w-min rounded px-2 md:px-6 py-2 md:py-2.5 justify-between my-4`};
  border-top: 1px solid ${(props) => props.theme.rating.border};
  box-shadow: ${(props) => props.theme.rating.boxShadow};
`;

const RatingDivider = styled.div`
  ${tw`mx-2 mb-4 mt-2 md:mx-8`}
  border-left: solid 1px ${(props) => props.theme.rating.divider};
  background-color: ${(props) => props.theme.rating.divider};
`;

const RatingTitle = styled.div`
  ${tw`mt-3 font-medium text-sm`}
  color: ${(props) => props.theme.rating.title};
`;

const Rating = ({ value, title, darkMode, showRating }) => (
  <div
    className="px-2 py-4 md:p-4 flex flex-col items-center justify-center"
    style={{
      backgroundColor: darkMode ? '#434243' : '#F9F9F9',
      width: '140px',
      marginTop: '20px',
      borderRadius: '5px',
    }}
  >
    {showRating ? (
      <RatingCode rating={value} darkMode={darkMode} />
    ) : (
      <img src={BlurRating} style={{ height: '30px' }} />
    )}
    <RatingTitle>
      <DomainTerm infoKey={`${title.toLowerCase()}_rating`}>
        <span className="whitespace-nowrap">{title} Rating</span>
      </DomainTerm>
    </RatingTitle>
  </div>
);

const ValuationSummary = ({
  data: { symbol },
  insights,
  darkMode,
  subscriptionStatus,
  publicSymbols,
}) => {
  const currentPrice = symbol.currentPrice;
  const fairPrice = symbol.fairPrice;
  const { authUser } = useAuth();

  const positiveInsights = insights?.positives
    ? insights?.positives.filter(
        (insight) =>
          !insight.includes('could be <positive>undervalued</positive> by')
      )
    : [];
  const negativeInsights = insights?.negatives
    ? insights?.negatives.filter(
        (insight) =>
          !insight.includes('could be <negative>overvalued</negative> by')
      )
    : [];

  const fairnessInsight =
    currentPrice && fairPrice
      ? parseFloat(fairPrice) < parseFloat(currentPrice)
        ? `${symbol.id} could be <negative>overvalued</negative> by 
  ${Math.abs(
    (parseFloat(fairPrice) / parseFloat(currentPrice) - 1) * 100
  ).toFixed(2)}% based on its fair value.`
        : `${symbol.id} could be <positive>undervalued</positive> by 
  ${Math.abs(
    (parseFloat(fairPrice) / parseFloat(currentPrice) - 1) * 100
  ).toFixed(2)}% based on its fair value.`
      : null;

  if (fairnessInsight)
    fairnessInsight.includes('overvalued')
      ? negativeInsights.push(fairnessInsight)
      : positiveInsights.push(fairnessInsight);

  const showRating =
    (authUser && subscriptionStatus.active) ||
    publicSymbols?.data.includes(symbol?.symbolMarket);

  return (
    <>
      <SectionHeader value="Stock Highlights" />
      <div className="flex flex-col lg:flex-row lg:justify-evenly lg:mt-6 px-2">
        <div className="mx-auto lg:mx-0">
          <VerdeScoreMeter score={symbol.verdeScore} />
        </div>
        {insights && (
          <div className="-mt-12 lg:ml-4">
            <RiskPositives
              positives={positiveInsights}
              negatives={negativeInsights}
            />
          </div>
        )}
      </div>
      {/* <div className="justify-center hidden md:flex">
        <RatingBox title="Growth" rating={symbol.growthRating} />
        <RatingBox title="Safety" rating={symbol.safetyRating} />
        <RatingBox title="Value" rating={symbol.valueRating} />
      </div>
      <div className="grid grid-cols-4 gap-4 md:hidden">
        <RatingBox
          className="col-span-2"
          title="Growth"
          rating={symbol.growthRating}
        />
        <RatingBox
          className="col-span-2"
          title="Safety"
          rating={symbol.safetyRating}
        />
        <RatingBox
          className={`${
            symbol.growthRating && symbol.safetyRating ? 'col-start-2' : ''
          } col-span-2`}
          title="Value"
          rating={symbol.valueRating}
        />
      </div> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '30px',
          flexWrap: 'wrap',
        }}
      >
        <Rating
          value={symbol.valueRating}
          title="Value"
          darkMode={darkMode}
          showRating={showRating}
        />

        <Rating
          value={symbol.growthRating}
          title="Growth"
          darkMode={darkMode}
          showRating={showRating}
        />

        <Rating
          value={symbol.profitabilityRating}
          title="Profitability"
          darkMode={darkMode}
          showRating={showRating}
        />

        <Rating
          value={symbol.safetyRating}
          title="Safety"
          darkMode={darkMode}
          showRating={showRating}
        />

        <Rating
          value={symbol.dividendRating}
          title="Dividend"
          darkMode={darkMode}
          showRating={showRating}
        />

        <Rating
          value={symbol.momentumRating}
          title="Momentum"
          darkMode={darkMode}
          showRating={showRating}
        />
      </div>

      {/* <RatingContainer className="flex md:hidden">
        <Rating value={symbol.growthRating} title="Growth" />
        <RatingDivider />
        <Rating value={symbol.safetyRating} title="Safety" />
        <RatingDivider />
        <Rating value={symbol.valueRating} title="Value" />
      </RatingContainer>

      <RatingContainer
        className="flex md:hidden"
        style={{ marginTop: '-28px' }}
      >
        <Rating value={symbol.dividendRating} title="Dividend" />
        <RatingDivider />
        <Rating value={symbol.momentumRating} title="Momentum" />
      </RatingContainer> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionStatus: state.coreState.subscriptionStatus,
  publicSymbols: state.symbolState.publicSymbols,
});

export default connect(mapStateToProps)(ValuationSummary);
