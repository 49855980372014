import React from 'react';
import { Auth } from 'aws-amplify';
import LoadingPageIndicator from '../shared/LoadingPageIndicator';
import { gtag } from '../../utils/analytics';
const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [checkOnPageLoad, setCheckOnPageLoad] = React.useState(true);
  const [authUser, setAuthUser] = React.useState();

  const requireAuth = () =>
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        //console.log(user);
        gtag('event', `existing-user`, {
          event_category: 'login',
          event_label: [
            ...user?.attributes.name.split(' '),
            user?.username,
          ].join('_'),
        });
        !authUser && setAuthUser(user);
      })
      .catch((error) => {
        authUser && setAuthUser(null);
      })
      .finally(() => setCheckOnPageLoad(false));

  const signin = async (email, password) => {
    const user = await Auth.signIn(email, password);
    gtag('event', `login`, {
      event_category: 'login',
      event_label: [...user?.attributes.name.split(' '), user?.username].join(
        '_'
      ),
    });
    setAuthUser(user);
  };

  const signout = async () => {
    await Auth.signOut();
    setAuthUser(null);
  };

  if (checkOnPageLoad) {
    requireAuth();
    return <LoadingPageIndicator />;
  }

  setInterval(requireAuth, 50000);

  return (
    <AuthContext.Provider value={{ authUser, signin, signout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
