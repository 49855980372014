import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import {
  Highlight,
  Subtle,
  HollowButton,
  ColoredContentText,
  BorderedSection,
  Divider,
} from '../../shared/StyedComponents';
import DefaultProfilePicture from '../../../public/images/default-profile-picture.jpg';
import FilterSvg from '../../../public/images/filter.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import FilterModal from '../../shared/FilterModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Checkbox from '../../shared/Checkbox';
import _ from 'lodash';

const news = [
  {
    title:
      'Nifty futures on Singapore Exchange traded 115 points, or 0.73 per cent lower at 15,660.50 signaling that Dalal Street was headed for a negative start on Thursday.',
    source: 'The verge',
    publishedBefore: '1h',
    symbol: 'NIFT',
  },
  {
    title: 'Apple brings its hardware microphone disconnected design.',
    source: 'Wall street journal',
    publishedBefore: '2h',
    symbol: 'AAPL',
  },
  {
    title:
      'Taking Stock: Market falls for second straight session as Fed signals rate hike by 2023',
    source: 'The verge',
    publishedBefore: '5h',
    symbol: 'MSFT',
  },
];

const getDefaultFilterValues = () => {
  const date = new Date();
  const tags = new Set();
  return { date, tags };
};

const Item = styled.div`
  ${tw`py-4`};
  border-top: 1px solid ${(props) => props.theme.sectionBorder};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  ${tw`my-0.5 mx-2`};
  font-size: 5px;
`;

export const FilterButton = styled(HollowButton)`
  ${tw`flex font-normal px-2 py-1 relative bottom-2`}
  border: solid 1px ${(props) => props.theme.news.filterButton.border};
  color: ${(props) => props.theme.content.color};
  &:hover {
    background-color: ${(props) => props.theme.news.filterButton.background};
  }
`;

const Tag = styled.span`
  ${tw`ml-4 py-0.5 px-1 w-max rounded-sm cursor-pointer text-xs h-6`};
  border: solid 1px #a9e7c6;
  background-color: #bbf3d5;
  color: #2d754e;
`;

const DateContainer = styled(BorderedSection)`
  ${tw`px-4 py-2 my-4 w-full rounded`};
`;

const FilterTitle = styled(ColoredContentText)`
  ${tw`font-medium`};
`;

const CheckboxLabel = styled.div`
  color: ${(props) =>
    props.checked ? props.theme.keyHighlight : props.theme.paleBlack};
`;

const NewsItem = ({ item, filter, setFilter }) => {
  return (
    <Item>
      <div className="flex">
        <img className="w-16 h-16 mr-4" src={DefaultProfilePicture} alt="" />
        <div className="my-auto">
          <Highlight className="text-sm font-medium">{item.title}</Highlight>
          <Subtle className="text-xs mt-1.5">
            {item.source}
            <StyledFontAwesomeIcon icon={faCircle} />
            {item.publishedBefore}
            <Tag
              onClick={() => {
                filter.tags.add(item.symbol);
                setFilter({ ...filter });
              }}
            >
              {item.symbol}
            </Tag>
          </Subtle>
        </div>
      </div>
    </Item>
  );
};

const NewsFeed = () => {
  const [filter, setFilter] = React.useState(getDefaultFilterValues());
  const [appliedFilter, setAppliedFilter] = React.useState(
    getDefaultFilterValues()
  );
  const [openFilterModal, setOpenFilterModal] = React.useState(false);
  const [filteredNews, setFilteredNews] = React.useState(news);
  const DateFilterInput = React.forwardRef(({ value, onClick }, ref) => (
    <DateContainer onClick={onClick} ref={ref}>
      {value}
    </DateContainer>
  ));

  React.useEffect(() => {
    appliedFilter.tags.size > 0
      ? setFilteredNews(news.filter((n) => appliedFilter.tags.has(n.symbol)))
      : setFilteredNews(news);
  }, [appliedFilter]);

  return (
    <div className="mt-2 lg:mt-0">
      <div className="flex justify-between">
        <div className="flex">
          <Highlight className="font-medium">Related news</Highlight>
          <div className="flex">
            {Array.from(appliedFilter.tags).map((tag) => (
              <Tag
                onClick={() => {
                  appliedFilter.tags.delete(tag);
                  const tags = new Set(appliedFilter.tags);
                  setAppliedFilter({ ...appliedFilter, tags });
                }}
              >
                <span className="mr-1">{tag}</span>
                <FontAwesomeIcon icon={faTimesCircle} />
              </Tag>
            ))}
          </div>
        </div>
        <div>
          <FilterButton
            onClick={() => {
              console.log(_.cloneDeep(appliedFilter));
              setFilter(_.cloneDeep(appliedFilter));
              setOpenFilterModal(true);
            }}
          >
            <img className="mr-2 mt-1" src={FilterSvg} alt="" />
            Filter
          </FilterButton>
        </div>
      </div>
      <div className="my-2">
        {filteredNews.map((item, index) => (
          <NewsItem
            key={index}
            item={item}
            filter={appliedFilter}
            setFilter={setAppliedFilter}
          />
        ))}
      </div>
      <FilterModal
        open={openFilterModal}
        setOpen={setOpenFilterModal}
        applyAction={() => {
          setAppliedFilter(filter);
          setOpenFilterModal(false);
        }}
        resetAction={() => {
          setFilter(getDefaultFilterValues());
          setAppliedFilter(getDefaultFilterValues());
          setOpenFilterModal(false);
        }}
      >
        <div className="px-5">
          <FilterTitle>Date</FilterTitle>
          <DatePicker
            selected={filter.date}
            onChange={(date) => {
              setFilter({ ...filter, date });
            }}
            customInput={<DateFilterInput />}
            className="full-width"
          />
          <Divider />
          <FilterTitle className="mb-6">My Holdings</FilterTitle>
          {news.map((n, index) => (
            <div className="flex items-start mb-4 text-sm" key={index}>
              <Checkbox
                onClick={() => {
                  filter.tags.has(n.symbol)
                    ? filter.tags.delete(n.symbol)
                    : filter.tags.add(n.symbol);
                  setFilter({ ...filter });
                }}
                checked={filter.tags.has(n.symbol)}
                id={`filter-symbol-checkbox-${n.symbol}`}
              />
              <CheckboxLabel
                checked={filter.tags.has(n.symbol)}
                className="ml-4"
              >
                {n.symbol}
              </CheckboxLabel>
            </div>
          ))}
        </div>
      </FilterModal>
    </div>
  );
};

export default NewsFeed;
