export const filterList = [
  {
    id: 'Holdings',
    tableHeader: [
      { id: 'Ticker', key: 'symbol', upDownIcon: false },
      {
        id: 'Shares',
        key: 'quantity',
        prefix: '',
        suffix: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
      {
        id: 'Average Price',
        key1: 'cost_basis',
        key2: 'quantity',
        prefix: '$',
        suffix: '',
        upDownIcon: false,
      },
      {
        id: 'Current Price',
        key: 'current_price',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      // {
      //   id: 'Book Value',
      //   key: '',
      //   upDownIcon: true,
      //   ascend: false,
      //   descend: false,
      // },
      {
        id: 'Market Value',
        key: 'market_value',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '% of Portfolio',
        key: 'portfolio_percent',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'P&L',
        key: 'profit_loss',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '%P&L',
        key: 'profit_loss_percent',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Quarter Low',
        key: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
      {
        id: 'Quarter High',
        key: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
    ],
  },
  {
    id: 'Summary',
    tableHeader: [
      { id: 'Ticker', key: 'symbol', upDownIcon: false },
      {
        id: 'Sector',
        key: 'sector',
        prefix: '',
        suffix: '',
        upDownIcon: false,
      },
      {
        id: 'Industry',
        key: 'industry',
        upDownIcon: false,
        prefix: '',
        suffix: '',
      },
      {
        id: 'Market Cap',
        key: 'marketcap_mln',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: 'Enterprise Value',
        key: 'enterprise_value',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: 'Target Price',
        key: 'target_price',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: 'Target % Upside',
        key: 'target_price_upside',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Fair Value',
        key: 'fair_price',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: 'Fair % Upside',
        key: 'fair_price_upside',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Price',
        key: 'current_price',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '% Change',
        key: 'fair_price_deviation',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Quarter High',
        key: '',
        upDownIcon: true,
        ascend: false,
        descend: false,
      },
    ],
  },
  {
    id: 'Ratings',
    tableHeader: [
      { id: 'Ticker', key: 'symbol', upDownIcon: false },
      {
        id: 'Analyst Rating',
        key: 'analyst_rating',
        prefix: '',
        suffix: '',
        upDownIcon: false,
      },
      {
        id: 'Value Rating',
        key: 'value_rating',
        upDownIcon: false,
        prefix: '',
        suffix: '',
        isRating: true,
      },
      {
        id: 'Growth Rating',
        key: 'growth_rating',
        upDownIcon: false,
        prefix: '',
        suffix: '',
        isRating: true,
      },
      {
        id: 'Profitability Rating',
        key: 'profitability_rating',
        upDownIcon: false,
        prefix: '',
        suffix: '',
        isRating: true,
      },
      {
        id: 'Safety Rating',
        key: 'safety_rating',
        upDownIcon: false,
        prefix: '',
        suffix: '',
        isRating: true,
      },
      {
        id: 'Dividend Rating',
        key: 'dividend_rating',
        upDownIcon: false,
        prefix: '',
        suffix: '',
        isRating: true,
      },
      {
        id: 'Momentum Rating',
        key: 'momentum_rating',
        upDownIcon: false,
        prefix: '',
        suffix: '',
        isRating: true,
      },
      {
        id: 'Verde Score',
        key: 'verde_score',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '',
        img: true,
      },
      {
        id: 'Price',
        key: 'current_price',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '% Change',
        key: 'fair_price_deviation',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
    ],
  },
  {
    id: 'Valuation',
    tableHeader: [
      { id: 'Ticker', key: 'symbol', upDownIcon: false },
      {
        id: 'P/E (TTM)',
        key: 'pe_ratio_ttm',
        prefix: '',
        suffix: '',
        upDownIcon: false,
      },
      {
        id: 'P/E (FWD)',
        key: 'pe_ratio',
        upDownIcon: false,
        prefix: '',
        suffix: '',
      },
      {
        id: 'P/S (TTM)',
        key: 'price_to_sales',
        upDownIcon: false,
        prefix: '',
        suffix: '',
      },
      {
        id: 'P/B (MRQ)',
        key: 'price_to_book',
        upDownIcon: false,
        prefix: '',
        suffix: '',
      },
      {
        id: 'PEG (FWD)',
        key: 'peg_ratio',
        upDownIcon: false,
        prefix: '',
        suffix: '',
      },
      {
        id: 'EV/Sales (TTM)',
        key: 'ev_revenue',
        upDownIcon: false,
        prefix: '',
        suffix: '',
      },
      {
        id: 'EV/EBITDA (TTM)',
        key: 'ev_ebitda',
        upDownIcon: false,
        prefix: '',
        suffix: '',
      },
      {
        id: 'EV/EBIT (TTM)',
        key: 'ev_ebit',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '',
      },
      {
        id: 'Price',
        key: 'current_price',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '% Change',
        key: 'fair_price_deviation',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
    ],
  },
  {
    id: 'Growth',
    tableHeader: [
      { id: 'Ticker', key: 'symbol', upDownIcon: false },
      {
        id: 'Revenue Growth (TTM)',
        key: 'revenue_growth_ttm',
        prefix: '',
        suffix: '%',
        upDownIcon: false,
      },
      {
        id: 'Revenue Growth (FWD)',
        key: '',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Revenue Growth (FY1)',
        key: 'revenue_growth_fy1',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Revenue Growth (FY2)',
        key: 'revenue_growth_fy2',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'EPS Growth (TTM)',
        key: 'eps_growth_ttm',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'EPS Growth (FWD)',
        key: '',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'EPS Growth (FY1)',
        key: 'eps_growth_fy1',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'EPS Growth (FY2)',
        key: 'eps_growth_fy2',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Price',
        key: 'current_price',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '% Change',
        key: 'fair_price_deviation',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
    ],
  },
  {
    id: 'Profitability',
    tableHeader: [
      { id: 'Ticker', key: 'symbol', upDownIcon: false },
      {
        id: 'Gross Margin',
        key: 'gross_margin',
        prefix: '',
        suffix: '%',
        upDownIcon: false,
      },
      {
        id: 'Operating Margin',
        key: 'operating_margin',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Net Margin',
        key: 'net_margin',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Return on Assets',
        key: 'return_on_assets',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Return on Equity',
        key: 'return_on_equity',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Return on Capital',
        key: 'return_on_invested_capital',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Debt/Equity',
        key: 'debt_equity',
        upDownIcon: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Debt/Capital',
        key: 'debt_capital',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Price',
        key: 'current_price',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '% Change',
        key: 'fair_price_deviation',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
    ],
  },
  {
    id: 'Performance',
    tableHeader: [
      { id: 'Ticker', key: 'symbol', upDownIcon: false },
      {
        id: '1W Return',
        key: 'one_week_return',
        prefix: '',
        suffix: '%',
        upDownIcon: false,
      },
      {
        id: '1M Return',
        key: 'one_month_return',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: '3M Return',
        key: 'three_month_return',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: '6M Return',
        key: 'six_month_return',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'YTD Return',
        key: 'ytd_return',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: '1Y Return',
        key: 'one_year_return',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: '3Y Return',
        key: 'three_year_return',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: '5Y Return',
        key: 'five_year_return',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
      {
        id: 'Price',
        key: 'current_price',
        upDownIcon: true,
        ascend: false,
        descend: false,
        prefix: '$',
        suffix: '',
      },
      {
        id: '% Change',
        key: 'fair_price_deviation',
        upDownIcon: false,
        ascend: false,
        descend: false,
        prefix: '',
        suffix: '%',
      },
    ],
  },
];
