import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import ToggleButton from '../../../ToggleButton';
import { getStackedAreaChartData } from '../../../../../utils/ChartUtils';
import SectionHeader from '../../../SectionHeader';
import StackedLineChart from '../../../StackedLineChart';

const SharesOutstanding = ({ data, insights, period, setPeriod }) => {
  const fullChartData = getStackedAreaChartData(
    data,
    ['evaluated_on', 'shares_data'],
    period
  );

  const chartData = [
    {
      name: 'Shares Outstanding',
      data: fullChartData.map((item) => [item[0], item[1]]),
    },
  ];

  return (
    <>
      <SectionHeader className="mb-6" value="Shares Outstanding" />
      <BorderedChartContainer>
        <div className="flex justify-center my-4">
          <ToggleButton period={period} setPeriod={setPeriod} />
        </div>
        <StackedLineChart data={chartData} />
      </BorderedChartContainer>

      <BulletPoints
        title="Positives"
        points={insights?.sharesOutstanding?.positives}
        isPositive={true}
      />
      <BulletPoints
        title="Risks"
        points={insights?.sharesOutstanding?.negatives}
        isPositive={false}
      />
    </>
  );
};

export default SharesOutstanding;
