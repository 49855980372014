import { Box, Divider, Flex } from '@chakra-ui/layout';
import { Text, Button, Image } from '@chakra-ui/react';
import VerdeHeader from '../../shared/VerdeHeader';
import styled from 'styled-components/macro';
import * as React from 'react';
import { useState, MouseEvent, useEffect, ReactNode, useMemo } from 'react';
import {
  useMediaQuery,
  Input,
  InputGroup,
  InputLeftElement,
  Tag,
  TagLabel,
  Avatar,  
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { SearchIcon } from '@chakra-ui/icons';
import { useInView } from 'react-intersection-observer';
import { useAuth } from '../../hocs/AuthProvider';
import Hero from '../../../public/images/newhome/hero.webp';
import TestOur from '../../../public/images/newhome/testour.svg';
import Offer1 from '../../../public/images/newhome/offer1.svg';
import Offer2 from '../../../public/images/newhome/offer2.svg';
import Offer3 from '../../../public/images/newhome/offer3.svg';
import Offer4 from '../../../public/images/newhome/testour.svg';
import wefunder from '../../../public/images/newhome/wefunder.webp';
import discord from '../../../public/images/newhome/discord.webp';
import wefunderIllustration from '../../../public/images/newhome/wefunder-illustration.svg';
import VerdeLogoSvg from '../../../public/images/newhome/footer-logo.svg';
import Tiktok from '../../../public/images/newhome/tiktok.svg';
import Instagram from '../../../public/images/newhome/instagram.svg';
import Twitter from '../../../public/images/newhome/twitter.svg';
import Linkedin from '../../../public/images/newhome/linkedin.svg';
import CancelSvg from '../../../public/images/cancel.svg';
import { gtag } from '../../../utils/analytics';
import Modal from 'react-modal';
import { Container } from '../../../utils/styling';
import { motion, useAnimation } from 'framer-motion';
import { useAppDispatch } from '../../../hooks/store';
import { setLoginPhase } from '../../../actions/coreAction';
import { Link, useHistory } from 'react-router-dom';
import { MobileAppModal } from '../../shared/GetTheApp/MobileAppModal';
import PlayStore from '../../../public/images/GetOnGooglePlay.png';
import AppleStore from '../../../public/images/GetOnAppleStore.png';
import {
  ColoredContentText,
  ContentWrapper,
  Highlight,
  StyledInput,
  Subtle,
} from '../../shared/StyedComponents';
import clevertap from 'clevertap-web-sdk';
import iphoneGraphic from '../../../public/images/IPhoneHero.png';
import RoboAdvisorImg from '../../../public/images/RoboAdvisor.svg';
import HedgeFundImg from '../../../public/images/HedgeFund.svg';
import WealthImg from '../../../public/images/Wealth.svg';
import SuccessImg from '../../../public/images/Success.svg';
import QRCode from '../../../public/images/User_Profile_QR.png';
import axios from 'axios';
import { SYMBOL_BASE_URL } from '../../../config/default';
import LoadingIndicator from '../../shared/LoadingIndicator';
import { SearchItem } from '../../shared/SymbolSearch/style';
import {
  filterBySymbolsAndNames,
  getEntityLink,
  getEntityName,
  getEntitySubtext,
} from '../../shared/SymbolSearch';
import SymbolLogo from '../../shared/SymbolLogo';
import { getLogoUrl } from '../../../utils/LogoUtils';
import { CompanyDivider } from '../../shared/SimilarCompanies';
import ClickedOutside from '../../shared/ClickOutside/click-outside';
// import {Animated} from "react-animated-css";
import LogoNotFound from '../../../public/images/logo-not-found.png';
import { useDispatch, useSelector } from 'react-redux';

declare global {
  interface Window {
    gtag: any;
  }
}
const SocialLink = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => (
  <a href={href} target="_blank">
    {children}
  </a>
);
const offers = [
  {
    id: 1,
    img: RoboAdvisorImg,
    title: 'Richie AI',
    description:
      'Gain exclusive beta access to our resident generative AI financial advisor, Richie!',
  },
  {
    id: 2,
    img: Offer1,
    title: 'Unrivaled Equity Insights',
    description:
      'Dive into a world of comprehensive analysis and make investment decisions with confidence. ',
  },
  // {
  //   id: 2,
  //   img: Offer2,
  //   title: 'Portfolio Tracker',
  //   description:
  //     'Connect your brokerage account through Plaid integration, track your investments in real-time, and receive valuable insights to optimize your portfolio.',
  // },
  {
    id: 3,
    img: Offer3,
    title: 'Social Side of Finance',
    description:
      'Forge connections, exchange ideas, and grow alongside a community of like-minded investors.',
  },
  {
    id: 4,
    img: HedgeFundImg,
    title: 'Unlock Hedge Fund Strategies',
    description:
      'Delve into notable hedge funds` holdings and performance, stay updated on their portfolio changes, and draw inspiration from their investment tactics.',
  },
];

const soonCome = [
  {
    id: 1,
    img: RoboAdvisorImg,
    title: 'Tailored Robo-Advisor',
    description:
      ' Effortlessly optimize your portfolio with auto-investing, auto-rebalancing, tax loss harvesting, and margin lending',
  },
  {
    id: 2,
    img: SuccessImg,
    title: 'Rewarding Your Success',
    description:
      ' Experience a unique rebate system, where your portfolio`s performance unlocks additional benefits.',
  },
  {
    id: 3,
    img: WealthImg,
    title: 'Next-Gen Wealth Management',
    description:
      'Get ready for cutting-edge solutions, powered by the Verde Invest Advisory platform',
  },  
];

const reviews = [
  {
    id: 1,
    description:
      'As a young investor trying to identify when/where to put my money in the stock market, I found Verde to be incredibly helpful. Their Insights tool is intuitive and straightforward in letting me know which stocks are undervalued and which are overvalued so that I can enter long-term positions at opportune times. By being able to follow other peoples portfolios, I was able to get an idea of which assets people feel comfortable putting their money into. When combined with my own research, this feature was a truly a game-changer.',
  },
  {
    id: 2,
    description:
      'I downloaded Verde looking for a new investing insights app. So far very satisfied with its features and could see its use and appeal to young/new investors looking to learn or for experienced traders looking to track/compare their portfolios against high ranked leverage a new investing tool. Definitely see a lot of potential in Verde, especially in being able to track/compare other investing portfolios.',
  },
  {
    id: 3,
    description:
      "I'm new to investing and have found it difficult to learn about stocks just by googling information. I've only been using verde for a few days but love it already. It gives me streamlined information and is easy to use. I also love being able to follow other verde users to see what holdings they have so that I can diversify my own portfolio! Seems like a great app so far and I can't wait to see what new updates the team has in store. Would highly recommend to people new to investing!!",
  },
  {
    id: 4,
    description:
      'Stock market is volatile and I need an app that allows me to stay ahead of the curve. Verde allows me to do this with their Verde score and allows me to keep up with family, friends and investors trades along the way. Incredible tool!',
  },
];

const footerServices = [
  'Home',
  'Features',
  // 'Pricing',
  'Community',
  'FAQ',
  { name: 'Careers', link: 'https://verde.freshteam.com/jobs' },
  { name: 'Support', link: '' },
  {
    name: 'Terms of Service',
    link: ' https://verde.finance/terms-and-conditions.html',
  },
  {
    name: 'Privacy Policy',
    link: 'https://verde.finance/terms-and-conditions.html#privacypolicy2',
  },
];
const brandColor = '#01874e';
const modalStyles = (isMobile = false) => ({
  overlay: {
    zIndex: 10,
  },
  content: {
    background: 'white',
    borderRadius: '10px',
    padding: '10px',
    border: 0,
    marginTop: '100px',
    height: isMobile ? '50vh' : 'calc(40vw)',
    width: '90vw',
    right: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
});
const Player = styled(Box)``;
const animText = ['Analytics', 'People', 'Power', 'Style', 'Verde'];
const timeDuration = 1.5;

export default function HomePage1({ setTheme }: { setTheme: Function }) {
  const { authUser, signout } = useAuth();
  const [playVideo, setPlayVideo] = useState(false);
  const [currentSection, setCurrentSection] = useState('Home');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const controls = useAnimation();
  const history = useHistory();
  const dispatch = useDispatch();
  const publicSymbols = useSelector(
    (state: any) => state.symbolState.publicSymbols.data as Array<string>
  );
  if (window.location.href.includes('oauth_state_id')) {
    history.push('/plaidandroid');
  }
  const [symbolsBasic, setSymbolsBasic] = React.useState<any>([]);
  const [symbolsBasicLoading, setSymbolsBasicLoading] = React.useState<boolean>(
    false
  );
  const [searchTerm, setSearchTerm] = React.useState('');
  const [matchingSymbolNames, setMatchingSymbolNames] = React.useState<any>([]);
  const searchInputRef = React.useRef<any>(null);
  useEffect(() => {
    controls.start({
      scale: 0.8,
      transition: { repeat: Infinity, duration: 0.9 },
    });
    setSymbolsBasicLoading(true);
    axios.get(`${SYMBOL_BASE_URL}/symbols-basic`).then((response) => {
      setSymbolsBasic(response.data);
      setSymbolsBasicLoading(false);
    });
  }, []);
  const [getTheAppModal, setGetTheAppModal] = useState(true);
  const trackViralLoops = (event: MouseEvent) => {
    gtag('event', `join-wait-list`);
    const text = (event.target as HTMLElement).innerText;
    let type = 'new_user_prelaunch';
    if (text.includes('position')) {
      type = 'user_check_position';
    }
  };

  const callback = (sectionName: any) => {
    var element = document.getElementById(`${sectionName}`);
    if (element !== null) {
      var headerOffset = isMobile ? 200 : 150;
      var elementPosition = element!.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const [homeRef, homeInView] = useInView({
    threshold: 0.2,
  });

  const [featuresRef, featuresInView] = useInView({
    threshold: 0.3,
  });

  const [pricingRef, pricingInView] = useInView({
    threshold: 0.2,
  });

  const [communityRef, communityInView] = useInView({
    threshold: 0.2,
  });

  const home = useMemo(
    () => (
      <Container display={'flex'} justifyContent="center">
        <Flex flexDirection={isMobile ? 'column' : 'row'}>
          <Flex
            alignItems="center"
            flexDirection={['column-reverse', 'column-reverse', 'row']}
          >
            {/* <Animated 
          animationIn={isMobile ? 'fadeInDown' : "fadeInLeft"} 
         animationOut="zoomOutDown" 
         animationInDuration={1000} 
         animationOutDuration={1500} 
         isVisible={true}
      > */}
            <Flex
              flexDirection="column"
              zIndex={1}
              textAlign={{ base: 'left', lg: 'center' }}
            >
              <Text fontSize={{ base: 40, lg: 65 }}>
                Revolutionize Your{' '}
                <span style={{ color: '#01884D' }}>
                  Investing
                  {/* <Typed
                    strings={['Investing', 'Portfolio', 'Insights']}
                    typeSpeed={120}
                    backSpeed={60}
                    loop
                  /> */}
                </span>
              </Text>
              <Text fontSize={{ base: 40, lg: 65 }}>with Verde Finance</Text>
              <Text
                mt="8"
                fontSize={{ base: 16, lg: 18 }}
                /* width="90%" */ mb={10}
              >
                Powerful investment tools, social connectivity, and seamless
                automation, all in one platform.
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon
                    color="#01884d"
                    boxSize={6}
                    mt={{ base: 3, lg: 5 }}
                    ml={1}
                  />
                </InputLeftElement>
                <Input
                  placeholder={
                    symbolsBasic.length > 0 ? 'Search stock' : 'Loading..'
                  }
                  disabled={symbolsBasic.length === 0}
                  size="lg"
                  borderColor={'#01884d'}
                  py={{ base: 0, lg: 7 }}
                  px={21}
                  color={'#01884d'}
                  focusBorderColor={'#01884d'}
                  _hover={{ borderColor: '#01884d' }}
                  fontSize={16}
                  style={{ paddingInlineStart: '50px' }}
                  ref={searchInputRef}
                  onInput={(event: any) => {
                    const value = event.target.value.toUpperCase();
                    setSearchTerm(value);
                    const result = filterBySymbolsAndNames(symbolsBasic, value);                    
                    value
                      ? setMatchingSymbolNames(result)
                      : setMatchingSymbolNames([]);
                  }}
                />
              </InputGroup>
              {searchTerm && (
                <ClickedOutside
                  onClicked={() => {
                    setSearchTerm('');
                    setMatchingSymbolNames([]);
                  }}
                >
                  <Box
                    style={{
                      width: searchInputRef.current
                        ? searchInputRef.current.offsetWidth + 'px'
                        : 'auto',
                      zIndex: 1,
                    }}
                    className="absolute max-h-80 overflow-y-scroll"
                  >
                    <Box bg={'rgba(255,255,255)'}>
                      {symbolsBasicLoading ? (
                        <LoadingIndicator />
                      ) : matchingSymbolNames.length === 0 ? (
                        <Highlight className="text-sm text-center">
                          No matching symbol found.
                        </Highlight>
                      ) : (
                        matchingSymbolNames.map(
                          (entity: any, index: number) => (
                            <React.Fragment key={index}>
                              <Box _hover={{ bgColor: 'lightgray' }}>
                                <Link
                                  to={
                                    publicSymbols.includes(entity.id)
                                      ? `/stocks-public/${entity.id}`
                                      : `/stocks-basic/${entity.id}`
                                  }
                                  onClick={() => {
                                    setSearchTerm('');
                                    setMatchingSymbolNames([]);
                                  }}
                                  className="no-underline text-black"
                                >
                                  <Flex p={4}>
                                    <Box boxSize="40px">
                                      <Image
                                        src={getLogoUrl(
                                          entity.symbol,
                                          entity.image
                                        )}
                                        fallbackSrc={LogoNotFound}
                                      />
                                    </Box>

                                    {/* <div className="flex flex-col ml-4 justify-evenly w-full">
                                      <ColoredContentText className="font-medium">
                                        {getEntityName(entity)}
                                      </ColoredContentText>
                                      <div className="flex justify-between">
                                        <Subtle className="text-xs">
                                          {getEntitySubtext(entity)}
                                        </Subtle>
                                        <Subtle className="text-xs">
                                          {entity.market}
                                        </Subtle>
                                      </div>
                                    </div> */}
                                    <Box
                                      px={4}
                                      textAlign={'left'}
                                      my={'auto'}
                                      width={'100%'}
                                    >
                                      <Box>{getEntityName(entity)}</Box>
                                      <Flex justifyContent={'space-between'}>
                                        <Box fontSize={13} color={'#8492a2'}>
                                          {getEntitySubtext(entity)}
                                        </Box>
                                        <Box fontSize={13} color={'#8492a2'}>
                                          {entity.market}
                                        </Box>
                                      </Flex>
                                    </Box>
                                  </Flex>
                                </Link>
                              </Box>
                              <Divider />
                            </React.Fragment>
                          )
                        )
                      )}
                    </Box>
                  </Box>
                </ClickedOutside>
              )}
              {publicSymbols.length > 0 && (
                <Box
                  display={{ base: 'block', lg: 'flex' }}
                  alignItems={'center'}
                  textAlign={'center'}
                  mt={5}
                >
                  <Text fontSize="14" m={2}>
                    Popular Equities
                  </Text>
                  <Box>
                    {publicSymbols.map((s) => (
                      <Link
                        to={`/stocks-public/${s}`}
                        className="no-underline text-black"
                      >
                        <Tag
                          size={'lg'}
                          key={'lg'}
                          borderRadius="full"
                          variant="solid"
                          backgroundColor="rgba(172, 210, 177, 0.44)"
                          color={'black'}
                          // fontWeight={'bold'}
                          fontSize={14}
                          fontFamily={'sans-serif'}
                          m={2}
                          _hover={{ cursor: 'pointer' }}
                        >
                          <Avatar
                            src={`http://images.verde.finance/${
                              s.split('.')[0]
                            }.png`}
                            size="xs"
                            backgroundColor="transparent"
                            mr={1}
                            p={0.5}
                          />
                          <TagLabel mt={0.75}>{s.split('.')[0]}</TagLabel>
                        </Tag>
                      </Link>
                    ))}
                  </Box>
                </Box>
              )}

              {/* { !isMobile ? (
            <Flex mt={15} alignItems="center" backgroundColor={"rgb(1, 136, 77,0.3)"} padding={5} borderRadius={10} width={!isMobile ? '70%': '350px'}>
            <img src={QRCode} width={135} height={135} style={{marginTop:2, marginBottom:2, borderRadius:5}}></img>
            <Text marginLeft={7} fontSize={24} fontWeight="bold">Scan to Download the app 
            or <br/>click <a href ="https://linktr.ee/verdefinance" target="_blank" style={{textDecoration:'underline', textUnderlineOffset:'3', color: '#01884D'}}>here</a> to install
            </Text>
            </Flex>) : (
              <>
              <Box onClick={() => clevertap.event.push('MB Apple Store Button Clicked')}>
                 <a
                  href="https://apps.apple.com/us/app/verde-finance/id1630554888"
                  target="_blank"
                >
                 <img src={AppleStore} width={200} height={100} style={{ borderRadius: 8 }}></img>
                </a>
                </Box>
                 <Box onClick={() => clevertap.event.push('MB Play Store Button Clicked')}>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.verde.finance"
                >
                 <img src={PlayStore} width = {200} height = {100}style={{ borderRadius: 8, marginTop:10 }}></img>
                </a>
                </Box>
                </>
            )
            }  */}
              <Flex
                mt="5"
                // alignItems="center"
                flexDirection={['column', 'column', 'row']}
                fontSize="xl"
              >
                {/* <Link to="/signup">
            
                <Button
                  // className="vrlps-trigger"
                  bg={brandColor}
                  fontSize="lg"
                  // onClick={trackViralLoops}
                  color="white"
                  fontFamily="roboto"
                  _hover={{
                    bg: { brandColor },
                    color: 'white',
                  }}
                  _focus={{ boxShadow: 'none', outline: 'none' }}
                  onClick={() => {
                    clevertap.event.push('Landing Page Sign Up');
                  }}
                >
                  Get Started for Free
                </Button>
             
              </Link> */}
              </Flex>
            </Flex>
            {/* </Animated> */}
          </Flex>
          {/* <Animated 
         animationIn={isMobile ? 'fadeInUp' : "fadeInRight"} 
         animationOut="zoomOutDown" 
         animationInDuration={1000} 
         animationOutDuration={1500} 
         isVisible={true}> */}
          {/* <div style={{margin: isMobile ? 'auto' : '', marginTop:isMobile ? 50 : '',}} >
        <img src={iphoneGraphic}
         style={{
          maxWidth:isMobile ? 350: 600,
          maxHeight:isMobile ? 350 : 550,
          minWidth:isMobile ? 350: 600,
          minHeight:isMobile ? 350 : 550,
         }}></img>
        </div> */}
          {/* </Animated> */}
        </Flex>
      </Container>
    ),
    [isMobile, symbolsBasic, matchingSymbolNames, searchTerm]
  );

  const features = useMemo(
    () => (
      <Box>
        <Flex mt={['10', '10', '10']} flexDirection="column">
          <Text
            fontSize="35"
            fontWeight="bold"
            textAlign={['center', 'center', 'left']}
          >
            Our Current Features
          </Text>
          {/* <Animated 
         animationIn="fadeInUp" 
         animationOut="zoomOutDown" 
         animationInDuration={500} 
         animationOutDuration={0} 
         isVisible={featuresInView}
        > */}
          <Flex gap="5" mt="4" flexDirection={['column', 'column', 'row']}>
            {offers.map((offer, index) => (
              <Flex
                key={offer.id}
                flexDirection="column"
                backgroundColor="white"
                borderRadius="20px"
                border="solid 1px #dbe4e9"
                flex="1"
                p="5"
                pb="8"
                pt="8"
              >
                <Text fontSize="xl" fontWeight="bold" m="0">
                  {index + 1}
                </Text>
                <Flex flexDirection="column" alignItems="center">
                  <img
                    src={offer.img}
                    height="220"
                    width="220"
                    style={{ height: '220px', width: '220px' }}
                  />
                  <Text
                    fontWeight="semibold"
                    fontSize="25"
                    mt="4"
                    textAlign="center"
                  >
                    {offer.title}
                  </Text>
                  <Text mt="4" fontSize="lg" textAlign="center">
                    {offer.description}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
          {/* </Animated> */}
        </Flex>
        {/* <Flex mt={['20', '20', '20']} flexDirection="column">
          <Flex alignItems="center">
            <Text
              fontSize="35"
              fontWeight="bold"
              textAlign={['center', 'center', 'left']}
            >
              Get Excited About What's Next
            </Text>
            <Button
              // className="vrlps-trigger"
              bg={brandColor}
              fontSize="lg"
              // onClick={trackViralLoops}
              color="white"
              fontFamily="roboto"
              _hover={{
                bg: { brandColor },
                color: 'white',
              }}
              ml={10}
              _focus={{ boxShadow: 'none', outline: 'none' }}
            >
              Join our mailing list
            </Button>
          </Flex>
          <Flex gap="5" mt="4" flexDirection={['column', 'column', 'row']}>
            {soonCome.map((offer, index) => (
              <Flex
                key={offer.id}
                flexDirection="column"
                backgroundColor="white"
                borderRadius="20px"
                border="solid 1px #dbe4e9"
                flex="1"
                p="5"
                pb="8"
                pt="8"
              >
                <Text fontSize="xl" fontWeight="bold" m="0">
                  {index + 1}
                </Text>
                <Flex flexDirection="column" alignItems="center">
                  <img
                    src={offer.img}
                    height="220"
                    width="200"
                    style={{ height: '220px', width: '200px' }}
                  />
                  <Text
                    fontWeight="semibold"
                    fontSize="3xl"
                    mt="4"
                    textAlign="center"
                  >
                    {offer.title}
                  </Text>
                  <Text mt="4" fontSize="lg" textAlign="center" maxW="2xs">
                    {offer.description}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex> */}
        <Flex mt={['20', '20', '20']} flexDirection="column">
          <Flex alignItems="center">
            <Text
              fontSize="35"
              fontWeight="bold"
              textAlign={['center', 'center', 'left']}
            >
              Our Reviews
            </Text>
          </Flex>
          <Flex gap="5" mt="4" flexDirection={['column', 'column', 'row']}>
            {reviews.map((review, index) => (
              <Flex
                key={review.id}
                flexDirection="column"
                backgroundColor="white"
                borderRadius="20px"
                border="solid 1px #dbe4e9"
                flex="1"
                p="5"
                pb="8"
                pt="8"
              >
                <Flex>
                  <StarIcon color={"#ff9529"} mr={0.5}/>
                  <StarIcon color={"#ff9529"} mr={0.5}/>
                  <StarIcon color={"#ff9529"} mr={0.5}/>
                  <StarIcon color={"#ff9529"} mr={0.5}/>
                  <StarIcon color={"#ff9529"} mr={0.5}/>
                </Flex>
                <Flex flexDirection="column" alignItems="center">                  
                  <Text mt="4" fontSize="lg" maxW="2xs">
                    &ldquo;
                    {review.description}
                    &rdquo;
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Box>
    ),
    [featuresInView, isMobile]
  );

  const pricing = useMemo(
    () => (
      <Flex mt={['20', '20', '32']} flexDirection={['column', 'column', 'row']}>
        <Flex flexDirection="column" flex="1">
          <Flex justifyContent={['center', 'center', 'left']}>
            <img src={wefunder} style={{ height: '60px', width: '200px' }} />
          </Flex>
          <Text
            fontSize="5xl"
            fontWeight="bold"
            mt="5"
            textAlign={['center', 'center', 'left']}
          >
            Invest with Verde Finance
          </Text>
          <Text
            mt="5"
            maxW="lg"
            textAlign={['center', 'center', 'left']}
            fontSize="2xl"
          >
            Click below to learn more about our campaign on Wefunder and join us
            on our journey to democratize financial education and transform
            social investing!
          </Text>
          <Flex mt="5" justifyContent={['center', 'center', 'left']}>
            <Button
              as="a"
              target="_blank"
              fontSize="lg"
              href="https://wefunder.com/verdefinance"
              bg={brandColor}
              onClick={() => {
                gtag('event', `wefunder`);
              }}
              color="white"
              _hover={{
                bg: { brandColor },
                color: 'white',
              }}
              _focus={{ boxShadow: 'none', outline: 'none' }}
            >
              Support the campaign
            </Button>
          </Flex>
        </Flex>
        <Flex flex="1" justifyContent="flex-end" mt={['10', '10', '0']}>
          <img src={wefunderIllustration} alt="test our product" />
        </Flex>
      </Flex>
    ),
    [isMobile]
  );

  const community = useMemo(
    () => (
      <Box backgroundColor="#01874f" mt={['20', '20', '32']}>
        <Container py="10">
          <Flex justifyContent="center">
            <Flex flexDirection="column" color="white">
              <Flex justifyContent="center">
                <img src={discord} style={{ height: '65px', width: '65px' }} />
              </Flex>
              <Text fontSize="5xl" fontWeight="bold" textAlign="center" mt="5">
                Join our community !
              </Text>
              <Text fontSize="2xl" textAlign="center" mt="5">
                Hop on to our Discord Server and join a community of like-minded
                investors.
              </Text>
              <Flex justifyContent="center" mt="5">
                <Button
                  as="a"
                  href="https://discord.gg/mtfzdBcFf5"
                  target="_blank"
                  bg={'white'}
                  fontWeight="normal"
                  fontSize="lg"
                  color="#01874e"
                  onClick={() => {
                    gtag('event', `discord`);
                    clevertap.event.push('Landing Page Join Community');
                  }}
                  _hover={{
                    fontWeight: 'bold',
                  }}
                  _focus={{ boxShadow: 'none', outline: 'none' }}
                >
                  Join Verde's Discord
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
    ),
    [isMobile]
  );

  useEffect(() => {
    if (homeInView && !featuresInView) {
      setCurrentSection('Home');
    } else if (featuresInView && !pricingInView) {
      setCurrentSection('Features');
    } else if (pricingInView && !communityInView) {
      setCurrentSection('Pricing');
    } else {
      setCurrentSection('Community');
    }
  }, [homeInView, featuresInView, pricingInView, communityInView]);

  useEffect(() => {
    clevertap.event.push('On Landing Page');
  }, []);

  return (
    <Box fontFamily="Manrope" backgroundColor="#f4f4f4">
      <VerdeHeader
        setTheme={setTheme}
        auth={authUser ? true : false}
        hiddenSearch
        currentSection={currentSection}
        callback={callback}
      ></VerdeHeader>
      <Box
        id="Home"
        ref={homeRef}
        mt={20}
        backgroundImage="linear-gradient(to top, #62da6a -430%, #fff 190%)"
        py="10"
      >
        {isMobile && getTheAppModal && (
          <MobileAppModal
            darkMode={false}
            closeModal={() => setGetTheAppModal(false)}
          />
        )}
        {home}
      </Box>
      <Container>
        <div id="Features" ref={featuresRef}>
          {features}
        </div>
        {/* <div 
        id="Pricing"
        ref={pricingRef}>
          {pricing}
        </div> */}
      </Container>
      <div id="Community" ref={communityRef}>
        {community}
      </div>
      {/* ---------------------------------------FOOTER------------------------------------------ */}
      <Container py="10">
        <Flex
          justifyContent="space-between"
          flexDirection={['column', 'column', 'row']}
        >
          <Flex
            fontSize="small"
            color="#64607d"
            alignItems={['center', 'center', 'flex-start']}
          >
            <Box>
              <img src={VerdeLogoSvg} style={{ width: '100px' }} />
              <Text mt="5">2021 Guadalupe Street, Suite 260,</Text>
              <Text mt="2">Austin, Texas, 78705</Text>
            </Box>
            <Box ml={20}>
              <ul>
                {footerServices.slice(0, 4).map((elem) =>
                  elem === 'FAQ' ? (
                    <>
                      <div
                        onClick={() => {
                          clevertap.event.push(`Footer ${elem} Clicked`);
                        }}
                      >
                        <a
                          href={
                            'https://verde.notion.site/Website-FAQ-d60c6100ff9b4f569341db4eced885f5'
                          }
                          target="_blank"
                        >
                          <div style={{ cursor: 'pointer' }}>
                            <li style={{ marginBottom: 10 }}>{elem}</li>
                          </div>
                        </a>
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={() => {
                        callback(elem);
                        clevertap.event.push(`Footer ${elem} Clicked`);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <li style={{ marginBottom: 10 }}>{elem}</li>
                    </div>
                  )
                )}
              </ul>
            </Box>

            <Box ml={10}>
              <ul>
                {footerServices
                  .slice(4, footerServices.length)
                  .map((elem: any) => (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        clevertap.event.push(`Footer ${elem.name} Clicked`);
                      }}
                    >
                      <a
                        href={
                          elem.name === 'Support'
                            ? 'mailto:contact@verde.finance'
                            : elem.link
                        }
                        target="_blank"
                      >
                        <li style={{ marginBottom: 10 }}>{elem.name}</li>
                      </a>
                    </div>
                  ))}
              </ul>
            </Box>

            {/* <Box ml={5}>
              Stay updated on the latest news and features from Verde Finance.
              <StyledInput
                id="email"
                name="email"
                type={'email'}
                placeholder="Email"
                // value={}
                style={{
                  marginTop: 5,
                  border: `1.5px solid ${'#e5e5e5'}`,
                }}
              />
              <Button
                // className="vrlps-trigger"
                bg={brandColor}
                fontSize="md"
                // onClick={trackViralLoops}
                color="white"
                fontFamily="roboto"
                _hover={{
                  bg: { brandColor },
                  color: 'white',
                }}
                mt={2}
                _focus={{ boxShadow: 'none', outline: 'none' }}
              >
                Submit
              </Button>
            </Box> */}
          </Flex>

          <Flex direction="column">
            <Flex
              gap="2"
              justifyContent={['center', 'center', 'end']}
              alignItems="flex-end"
              mt={['10', '10', '0']}
              mb={0}
            >
              <Box
                onClick={() =>
                  clevertap.event.push('Footer TikTok Link Clicked')
                }
              >
                <SocialLink href="https://www.tiktok.com/@verde.finance">
                  <img src={Tiktok}></img>
                </SocialLink>
              </Box>
              <Box
                onClick={() =>
                  clevertap.event.push('Footer Instagram Link Clicked')
                }
              >
                <SocialLink href="https://instagram.com/verde.finance">
                  <img src={Instagram}></img>
                </SocialLink>
              </Box>
              <Box
                onClick={() =>
                  clevertap.event.push('Footer Twitter Link Clicked')
                }
              >
                <SocialLink href="https://twitter.com/VerdeFinance">
                  <img src={Twitter}></img>
                </SocialLink>
              </Box>
              <Box
                onClick={() =>
                  clevertap.event.push('Footer LinkedIn Link Clicked')
                }
              >
                <SocialLink href="https://www.linkedin.com/company/verdefinance">
                  <img src={Linkedin}></img>
                </SocialLink>
              </Box>
            </Flex>
            {!isMobile && (
              <Box mt={3}>
                <a
                  href="https://apps.apple.com/us/app/verde-finance/id1630554888"
                  target="_blank"
                >
                  <div
                    style={{
                      height: 50,
                      width: 150,
                      cursor: 'pointer',
                      marginBottom: 5,
                    }}
                    onClick={() =>
                      clevertap.event.push('Footer Get On Apple Store Clicked')
                    }
                  >
                    <img src={AppleStore} style={{ borderRadius: 10 }}></img>
                  </div>
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.verde.finance"
                >
                  <div
                    style={{
                      height: 50,
                      width: 150,
                      cursor: 'pointer',
                      marginTop: 5,
                    }}
                    onClick={() =>
                      clevertap.event.push('Footer Get On Play Store Clicked')
                    }
                  >
                    <img src={PlayStore} style={{ borderRadius: 10 }}></img>
                  </div>
                </a>
              </Box>
            )}
          </Flex>
        </Flex>
        <Divider mt={['2', '2', '10']} size="10" />
        <Text
          py={['2', '2', '10']}
          textAlign="center"
          color="#181433"
          fontSize={['small', 'small', 'normal']}
        >
          Copyright @ {new Date().getFullYear()}. All rights reserved.
        </Text>
      </Container>
      <Modal
        isOpen={playVideo}
        onRequestClose={() => setPlayVideo(false)}
        style={modalStyles(isMobile)}
      >
        <>
          <Flex flexDir="row-reverse">
            <Box cursor="pointer" onClick={() => setPlayVideo(false)} p="4">
              <img src={CancelSvg} />
            </Box>
          </Flex>
          <Player
            position="relative"
            w="full"
            h={['auto', 'auto', 'auto', '80%']}
          >
            <iframe
              src="https://player.vimeo.com/video/682674533?autoplay=1"
              width="100%"
              height="100%"
              allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
            {/* <iframe
              src="https://player.vimeo.com/video/682674533?h=a3c50f1860&title=0&byline=0&portrait=0&autoplay=1"
              style={{
                position: 'absolute',

                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: isMobile ? 'auto' : '100%',
              }}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe> */}
          </Player>
        </>
      </Modal>
    </Box>
  );
}
