import { put, takeEvery } from 'redux-saga/effects';
import { SYMBOL_BASE_URL } from '../config/default';
import wrappedAxios from '../utils/WrappedAxios';
import axios from 'axios';

function* fetchSimilarCompaniesData(action) {
  yield put({ type: 'FETCH_SIMILAR_COMPANIES_DATA_LOADING' });
  try {
    const similarCompanies = yield wrappedAxios
      .get(
        `${SYMBOL_BASE_URL}/similarcompanies/${action.payload.toUpperCase()}?offset=0&limit=5`
      )
      .then((response) => {
        return response.data.similar_companies;
      });
    yield put({
      type: 'FETCH_SIMILAR_COMPANIES_DATA_SUCCESS',
      data: similarCompanies,
    });
  } catch (error) {
    yield put({ type: 'FETCH_SIMILAR_COMPANIES_DATA_ERROR', error: error });
  }
}

function* fetchSimilarCompaniesPublicData(action) {
  yield put({ type: 'FETCH_SIMILAR_COMPANIES_DATA_LOADING' });
  try {
    const similarCompanies = yield axios
      .get(
        `${SYMBOL_BASE_URL}/similarcompanies-public/${action.payload.toUpperCase()}?offset=0&limit=5`
      )
      .then((response) => {
        return response.data.similar_companies;
      });
    yield put({
      type: 'FETCH_SIMILAR_COMPANIES_DATA_SUCCESS',
      data: similarCompanies,
    });
  } catch (error) {
    yield put({ type: 'FETCH_SIMILAR_COMPANIES_DATA_ERROR', error: error });
  }
}

export function* watchFetchingSimilarCompaniesData() {
  yield takeEvery('FETCH_SIMILAR_COMPANIES_DATA', fetchSimilarCompaniesData);
  yield takeEvery('FETCH_SIMILAR_COMPANIES_PUBLIC_DATA', fetchSimilarCompaniesPublicData);
}
