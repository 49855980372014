import {
  Flex,
  Box,
  Grid,
  GridItem,
  Badge,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import SectionHeader from '../../../SectionHeader';
import InsidersTable from '../../../InsidersTable';
import LoadingSectionIndicator from '../../../LoadingSectionIndicator';

export const lowestPerformerColumns = [
  {
    Header: 'Investment',
    accessor: 'stock_name',
    Cell: (row) => <div>{row.value}</div>,
  },
  {
    Header: 'Returns',
    accessor: 'expected_return',
    Cell: (row) => <div>{row.value}</div>,
  },
];

export default function Recommendations(props) {
  const { data, darkMode } = props;
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const recommendations = data['recommendations']
    ? data['recommendations']['recommendations_to_improve_your_portfolio']
    : {};
  const lowestPerformers = data['recommendations']
    ? data['recommendations']['lowest_performers']
    : {};

  if (!data['recommendations']) return <LoadingSectionIndicator />;

  return (
    <Box>
      <Box
        textColor={darkMode ? '#f6f6f6' : '#3a3a3a'}
        bg={darkMode ? '#262626' : !isMobile ? 'white' : 'rgb(246,248,250)'}
        p="6"
        my="6"
      >
        <SectionHeader
          value="Recommendations to improve your portfolio"
          className="pb-4"
        />
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {Object.keys(recommendations).map((key, index) => (
            <GridItem
              bg={darkMode ? '#434243' : '#F9F9F9'}
              mt="5px"
              borderRadius="5px"
              p={4}
            >
              <Badge borderRadius={2}>#{index + 1} Recommendation</Badge>
              <Box fontWeight="bold" fontSize="md" my={2}>
                {recommendations[key]['title']}
              </Box>
              <Box fontSize="sm">{recommendations[key]['description']}</Box>
            </GridItem>
          ))}
        </Grid>
      </Box>

      <Box
        bg={darkMode ? '#262626' : !isMobile ? 'white' : 'rgb(246,248,250)'}
        p="4"
        my="6"
      >
        <SectionHeader value="Lowest performers over the next 12 months" />
        <Flex justifyContent={'center'} my={6}>
          <Box minW={'3xl'}>
            <InsidersTable
              darkMode={darkMode}
              columns={lowestPerformerColumns}
              data={Object.keys(lowestPerformers).map((key) => ({
                stock_name: lowestPerformers[key]['stock_name'],
                expected_return: lowestPerformers[key]['expected_return'],
              }))}
              tableName="Recommendations"
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
