import React from 'react';
import RatioGraph from '../../../RatioGraphRevamp';
import RatioGraphInsights from '../../../RatioGraphInsights';
import SectionHeader from '../../../SectionHeader';
import RiskPositives from '../../../RiskPositives';

const PriceToBook = ({
  data,
  symbol,
  insights,
  darkMode,
  etfData,
  spyData,
}) => {
  const ratios =
    data !== undefined
      ? [
          { name: 'Symbol', value: data.symbol },
          { name: 'Sector', value: data.sector },
          { name: 'Market', value: data.market },
        ]
      : [
          {
            name: 'Symbol',
            value: parseFloat(etfData.Valuation['P/B(TTM)']).toFixed(2),
          },
          {
            name: 'Category',
            value: parseFloat(etfData.Valuation['P/B Category(TTM)']).toFixed(
              2
            ),
          },
          {
            name: 'Market',
            value: parseFloat(spyData.Valuation['P/B(TTM)']).toFixed(2),
          },
        ];

  const pbIndustryPositives = insights?.pb_ratio?.Industry?.positives
    ? insights?.pb_ratio?.Industry?.positives
    : [];
  const pbMarketPositives = insights?.pb_ratio?.Market?.positives
    ? insights?.pb_ratio?.Market?.positives
    : [];

  const pbIndustryNegatives = insights?.pb_ratio?.Industry?.negatives
    ? insights?.pb_ratio?.Industry?.negatives
    : [];
  const pbMarketNegatives = insights?.pb_ratio?.Market?.negatives
    ? insights?.pb_ratio?.Market?.negatives
    : [];

  return (
    <>
      <SectionHeader value="Price to Book (PB)" infoKey="pb" />
      {symbol !== undefined ? (
        <RatioGraph ratios={ratios} symbol={symbol.id} suffix="x" />
      ) : etfData.ETF_Data !== {} ? (
        <RatioGraph
          ratios={ratios}
          symbol={etfData.ETF_Data.ETF_Code}
          suffix="x"
        />
      ) : (
        <div></div>
      )}

      {pbIndustryPositives.length === 0 && pbIndustryNegatives.length === 0 ? (
        <div></div>
      ) : (
        <div>
          <h3
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: darkMode ? 'white' : 'black',
            }}
          >
            PB vs Sector
          </h3>

          <RiskPositives
            positives={pbIndustryPositives}
            negatives={pbIndustryNegatives}
          />
        </div>
      )}

      {pbMarketPositives.length === 0 && pbMarketNegatives.length === 0 ? (
        <div></div>
      ) : (
        <>
          <h3
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: darkMode ? 'white' : 'black',
            }}
          >
            PB vs Market
          </h3>
          <RiskPositives
            positives={pbMarketPositives}
            negatives={pbMarketNegatives}
          />
        </>
      )}
    </>
  );
};

export default PriceToBook;
