import DividendCM from '../models/DividendCM';
import { getPercent } from '../utils/number';
import { deserialise as deserialiseComparison } from './AggregratedMetricDeserialiser';

export const deserialise = (payload, comparisonPayload) =>
  new DividendCM.builder()
    .symbol(payload.symbol)
    .rating(payload.dividend_rating)
    .yieldPercent(payload.yield_fwd)
    .comparison(deserialiseComparison(comparisonPayload.dividend_yield))
    .build();
