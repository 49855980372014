import React from 'react';
import { connect } from 'react-redux';
import mainImg from '../../../public/images/error/error.webp';
import mainImgD from '../../../public/images/error/error-d.webp';
import { Box, Text, Container, Center } from '@chakra-ui/layout';
import VerdeHeader from '../VerdeHeader';
import { showPageLoading } from '../../../actions/coreAction';

const pageStyles = {
  light: {
    bg: '#f1f7fb',
    textColor: 'black',
    bgImg: mainImg,
  },
  dark: {
    bg: '#272c34',
    textColor: 'white',
    bgImg: mainImgD,
  },
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Error can be logged from here
  }

  componentDidUpdate() {
    this.props.showPageLoading(false);
    this.styles = this.props.darkMode ? pageStyles.dark : pageStyles.light;
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box fontFamily="Plus Jakarta Display">
          <VerdeHeader
            setTheme={this.props.setTheme}
            auth={true}
            hiddenSearch
          />
          <Box bg={this.styles.bg} minH="100vh">
            <Container mx="auto" maxW="container.xl">
              <Center flexDirection="column">
                <Box mt="40" mx="auto">
                  <img src={this.styles.bgImg} height="250px" width="300px" />
                </Box>
                <Text
                  color={this.styles.textColor}
                  fontSize="x-large"
                  mt="10"
                  fontWeight="bold"
                >
                  404, That’s an error.
                </Text>
                <Text align={'center'} color={this.styles.textColor} mt="2">
                  Brace yourself till we get the error fixed.
                </Text>
                <Text align={'center'} color={this.styles.textColor} mb="2">
                  You may also refresh the page or try again later.
                </Text>
              </Center>
            </Container>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}
const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
