import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import SectionHeader from '../../../SectionHeader';
import { RATING_COLOR_CODE } from '../../../RatingCode/style';
import RatioGraph from '../../../RatioGraph';
import RatioGraphInsights from '../../../RatioGraphInsights';
import SectionRating from '../../../SectionRating';

const CurrentYieldPercent = styled.div`
  ${tw`text-2xl font-bold`};
  color: ${(props) => props.theme.dividend.currentYield};
`;

const CurrentYieldText = styled.div`
  ${tw`flex text-sm text-white items-center`};
  color: ${(props) => props.theme.dividend.currentYieldText};
`;

const CurrentYield = ({ data, insights }) => {
  const ratios = [
    { name: 'Symbol', value: data.comparison.symbol },
    { name: 'Sector', value: data.comparison.sector },
    { name: 'Market', value: data.comparison.market },
  ];

  return (
    <>
      <SectionHeader
        value="Current Yield"
        className="mb-6"
        infoKey="dividend_yield"
      />
      <div className="flex">
        <SectionRating title="Dividend Rating" rating={data.rating} />
        <div className="flex flex-col justify-center ml-5">
          <CurrentYieldPercent>{data.yieldPercent}%</CurrentYieldPercent>
          <CurrentYieldText>
            <div className="font-medium text-base mr-1">{data.symbol}</div>
            <div className="mt-0.5">Current Dividend Yield</div>
          </CurrentYieldText>
        </div>
      </div>
      <RatioGraph ratios={ratios} symbol={data.symbol} suffix="%" />
      <RatioGraphInsights title="Dividend" insights={insights.dividendYield} />
    </>
  );
};

export default CurrentYield;
