import React, { useEffect, useState } from 'react';
import MobilePortfolioSummary from '../../../MobilePortfolioSummary';
import { useMediaQuery } from '@chakra-ui/react';

export default function MobileSummary({
  data,
  indData,
  darkMode,
  label,
  showPortfolioCallback,
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <div
      style={{
       
      }}
    >
      <MobilePortfolioSummary
        data={data}
        darkMode={darkMode}
        indData={indData}
        label={label}
        showPortfolioCallback={showPortfolioCallback}
      />
    </div>
  );
}
