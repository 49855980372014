import React from 'react';
import SectionHeader from '../../../SectionHeader';
import { InfoOutlineIcon } from '@chakra-ui/icons';

function ETFDividend({ data, darkMode }) {
  let overviewData = data.ETF_Data;
  return (
    <>
      <div>
        <div style={{ display: 'flex' }}>
          <SectionHeader value={'Dividend'} infoKey="val" />

          <div style={{ marginTop: '2px', marginLeft: '2px' }}>
            <InfoOutlineIcon
              color="#0072db"
              height="16px"
              width="16px"
            ></InfoOutlineIcon>
          </div>
        </div>

        <div style={{ display: 'flex', marginTop: '30px' }}>
          <h1 style={{ color: darkMode ? 'white' : 'black', fontSize: '20px' }}>
            {parseFloat(overviewData['Dividend Yield']).toFixed(2)}
          </h1>
          <div
            style={{
              backgroundColor: darkMode ? '#434243' : '#f4f4f4',
              marginLeft: '20px',
              padding: '2px',
              paddingLeft: '5px',
              paddingRight: '5px',
              borderRadius: '5px',
              color: darkMode ? 'white' : 'black',
            }}
          >
            Quarterly
          </div>
        </div>
        <h4
          style={{ color: darkMode ? '#cccccc' : '#595959', marginTop: '10px' }}
        >
          Dividend Yield (TTM)
        </h4>
      </div>
    </>
  );
}

export default ETFDividend;
