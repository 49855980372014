import React from 'react';
import { connect } from 'react-redux';
import { getInsidersData, getHoldersData } from '../../../actions/action';
import { showPageLoading } from '../../../actions/coreAction';
import { useParams } from 'react-router-dom';
import { SectionDivider } from '../../shared/StyedComponents';
import InsiderTransactions from '../../shared/Sections/Ownership/InsidersTransactions';
import Ownership from '../../shared/Sections/Ownership/Ownership';
import InstitutionalShareholders from '../../shared/Sections/Ownership/InstitutionalShareholders';
import FundsShareholders from '../../shared/Sections/Ownership/FundsShareholders';

function Insiders({
  symbol,
  loading,
  showPageLoading,
  getInsidersData,
  getHoldersData,
  insidersData,
  institutionsHolders,
  fundsHolders,
  error,
  insights,
  symbolData,
}) {
  const params = useParams();

  React.useEffect(() => {
    if (params.sid !== symbol) {
      getInsidersData(params.sid);
      getHoldersData(params.sid, 'INSTITUTIONS');
      getHoldersData(params.sid, 'FUNDS');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    showPageLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (error) throw new Error('Error in insiders api');

  return symbolData && symbolData.symbol ? (
    <>
      {symbolData.symbol && !symbolData.symbol.isAdr && (
        <>
          <InsiderTransactions data={insidersData} insights={insights} />
          <SectionDivider />
        </>
      )}

      <InstitutionalShareholders
        data={institutionsHolders}
        symbol={symbolData?.symbol}
      />
      <SectionDivider />
      <FundsShareholders data={fundsHolders} symbol={symbolData?.symbol} />
      {/*  <Ownership /> */}
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  symbol: state.insidersState.symbol,
  loading: state.insidersState.loading || state.valuationState.loading,
  insidersData: state.insidersState.data.insiders,
  institutionsHolders: state.insidersState.data.institutionsHolders,
  fundsHolders: state.insidersState.data.fundsHolders,
  error: state.insidersState.holdersError,
  insights: state.symbolInsightsState.data?.ownersInsiders,
  symbolData: state.valuationState.data,
});
const mapDispatchToProps = {
  showPageLoading,
  getInsidersData,
  getHoldersData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Insiders);
