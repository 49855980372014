import React from 'react';
import { connect } from 'react-redux';
import { gtag } from '../../../utils/analytics';
import { showErrorAlert, showSuccessAlert } from '../../../actions/alertAction';
import { Link, useHistory } from 'react-router-dom';
import {
  showPageLoading,
  setOpenJoinVerdePlanModal,
} from '../../../actions/coreAction';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Flex,
  Text,
  Spacer,
  Box,
  Center,
  List,
  ListItem,
  ListIcon,
  Tag,
  Avatar,
  TagLabel,
} from '@chakra-ui/react';
import { USER_SOCIAL_LINK } from '../../../config/default';
import WrappedAxios from '../../../utils/WrappedAxios';
import ChakraIcon from '../ChakraIcon';

const JoinVerdePlanModal = ({
  openJoinVerdePlanModal,
  setOpenJoinVerdePlanModal,
  darkMode,
  publicSymbols,
  showPageLoading,
}) => {
  const closeModal = () => setOpenJoinVerdePlanModal(false);

  const subscribe = () => {
    closeModal();
    showPageLoading(true);
    WrappedAxios.post(
      `${USER_SOCIAL_LINK}/subscriptions/checkout-session`
    ).then(({ data }) => {
      window.location.replace(data.url);
    });
  };

  return (
    <Modal isOpen={openJoinVerdePlanModal} onClose={closeModal} size="3xl">
      <ModalOverlay
        backdropFilter="auto"
        bg="blackAlpha.900"
        backdropBlur="10px"
      />
      <ModalContent
        color={darkMode ? '#fff' : 'rgb(51, 51, 51)'}
        bg={darkMode ? 'rgba(30, 30, 30, 0.93)' : 'rgba(255, 255, 255, 0.93)'}
      >
        <ModalHeader pb={0}>
          Your Plan
          <Divider color={'rgba(255, 255, 255, 0.4)'} mt={2} />
        </ModalHeader>

        <ModalBody pt={0}>
          <Flex>
            <Box p={5} pl={0}>
              <Text fontWeight={500}>You current free plan</Text>
              <Flex fontSize={'sm'} minWidth="max-content" mt={4}>
                <Text>Check out Fully</Text>
                <Text color={darkMode ? '#4adf79' : 'rgb(56, 168, 118)'} ml={1}>
                  Unlocked Equities
                </Text>
              </Flex>
              {publicSymbols?.length > 0 && (
                <Box
                  display={{ base: 'block', lg: 'flex' }}
                  alignItems={'center'}
                  textAlign={'center'}
                  mt={5}
                >
                  <Box>
                    {publicSymbols.map((s) => (
                      <Link
                        to={`/stocks/${s}`}
                        className="no-underline text-black"
                        onClick={closeModal}
                      >
                        <Tag
                          size={'lg'}
                          key={'md'}
                          borderRadius="full"
                          variant="solid"
                          backgroundColor="rgba(172, 210, 177, 0.44)"
                          color={'black'}
                          // fontWeight={'bold'}
                          fontSize={14}
                          fontFamily={'sans-serif'}
                          m={2}
                          _hover={{ cursor: 'pointer' }}
                        >
                          <Avatar
                            src={`http://images.verde.finance/${
                              s.split('.')[0]
                            }.png`}
                            size="xs"
                            backgroundColor="transparent"
                            mr={1}
                            p={0.5}
                          />
                          <TagLabel
                            color={darkMode ? '#fff' : 'rgb(51, 51, 51)'}
                            fontSize={'sm'}
                            mt={0.75}
                          >
                            {s.split('.')[0]}
                          </TagLabel>
                        </Tag>
                      </Link>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
            <Spacer>
              <Divider
                orientation="vertical"
                color={'rgba(255, 255, 255, 0.4)'}
              />
            </Spacer>

            <Box p={5} pr={0}>
              <Flex alignItems={'center'}>
                <Text color={darkMode ? '#fff' : '#000'} fontWeight={500}>
                  Verde+
                </Text>
                <Spacer />
                <Text
                  color={
                    darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'
                  }
                  fontSize="xs"
                  fontWeight={500}
                >
                  USD $4.99/mo
                </Text>
              </Flex>
              <Center>
                <button
                  onClick={subscribe}
                  style={{
                    backgroundColor: darkMode ? '#4adf79' : '#04874E',
                    color: darkMode ? 'black' : 'white',
                    fontSize: '15px',
                    fontWeight: 'bold',
                    alignSelf: 'center',
                    padding: '10px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    marginTop: '20px',
                    marginBottom: '20px',
                    borderRadius: '20px',
                  }}
                >
                  {'Join Verde+'}
                </button>
              </Center>
              <List spacing={4} my={2}>
                <ListItem>
                  <Flex>
                    <ListIcon>
                      <ChakraIcon value="JoinVerdeCheck" />
                    </ListIcon>
                    <Text fontSize="xs">
                      Unlock Verde+ for unrestricted access to in-depth stock &
                      ETF research.
                    </Text>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Flex>
                    <ListIcon>
                      <ChakraIcon value="JoinVerdeCheck" />
                    </ListIcon>
                    <Text fontSize="xs">
                      Take advantage of portfolio tracking & optimization tools.
                    </Text>
                  </Flex>
                </ListItem>
                {/* <ListItem>
                  <Flex>
                    <ListIcon color="green.500" />
                    <Text fontSize="xs">
                      Gain exclusive beta access to our resident generative AI
                      financial advisor, Richie!
                    </Text>
                  </Flex>
                </ListItem> */}

                <ListItem>
                  <Flex>
                    <ListIcon>
                      <ChakraIcon value="JoinVerdeCheck" />
                    </ListIcon>
                    <Text fontSize="xs">
                      Elevate your financial decision-making with Verde+
                    </Text>
                  </Flex>
                </ListItem>
              </List>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  openJoinVerdePlanModal: state.coreState.openJoinVerdePlanModal,
  publicSymbols: state.symbolState.publicSymbols?.data,
});

const mapDispatchToProps = {
  showErrorAlert,
  showSuccessAlert,
  showPageLoading,
  setOpenJoinVerdePlanModal,
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinVerdePlanModal);
