import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import BulletPoints from '../../../BulletPoints';
import {
  getAdvancedTimelineChartData,
  getPresentDateForFutureGrowth,
} from '../../../../../utils/ChartUtils';
import AdvancedTimelineChart from '../../../AdvancedTimelineChart';
import SectionHeader from '../../../SectionHeader';
import RiskPositives from '../../../RiskPositives';

const RevenueProjection = ({ data, insights }) => {
  const revenuePositives = insights?.revenue?.positives
    ? insights?.revenue?.positives
    : [];
  const revenueGrowthPositives = insights?.revenue_growth?.positives
    ? insights?.revenue_growth?.positives
    : [];
  const revenueNegatives = insights?.revenue?.negatives
    ? insights?.revenue?.negatives
    : [];
  const revenueGrowthNegatives = insights?.revenue_growth?.negatives
    ? insights?.revenue_growth?.negatives
    : [];

  const chartData = getAdvancedTimelineChartData(data, [
    'revenue_average',
    'revenue_low',
    'revenue_high',
    'revenue_actual',
    'evaluated_on',
  ]).map((c) => [c[0], c[4], c[3], c[2], c[1]]);

  return (
    <>
      <SectionHeader
        value="Revenue Projection"
        className="pb-6"
        infoKey="revenue"
      />
      <div style={{ marginTop: '10px' }}>
        <AdvancedTimelineChart
          data={chartData}
          chartVariant="Revenue"
          presentDate={getPresentDateForFutureGrowth(data)}
        />
      </div>

      <RiskPositives
        positives={[...revenuePositives, ...revenueGrowthPositives]}
        negatives={[...revenueNegatives, ...revenueGrowthNegatives]}
      />
    </>
  );
};

export default RevenueProjection;
