import React from 'react';
import VerdeScale from '../../../VerdeScale';
import PriceGrowthTable from '../../../PriceGrowthTable';
import SectionHeader from '../../../SectionHeader';

const defaultTimeSeries = [
  { name: '1W', selected: false },
  { name: '1M', selected: true },
  { name: '3M', selected: false },
  { name: '6M', selected: true },
  { name: 'YTD', selected: true },
  { name: '1Y', selected: false },
  { name: '5Y', selected: false },
];

const firstColumn = {
  Header: 'Market',
  accessor: 'market',
  Cell: ({ row, value }) => {
    return row.original.market;
  },
};

const determineColumns = () =>
  [firstColumn].concat(
    defaultTimeSeries
      .filter((timeSeries) => timeSeries.selected)
      .map((timeSeries) => {
        return {
          Header: timeSeries.name,
          accessor: timeSeries.name,
          Cell: ({ row, value }) => {
            return `${value}%`;
          },
        };
      })
  );

const ValuationPriceComparison = ({ data }) => {
  const [timeSeries, setTimeSeries] = React.useState(defaultTimeSeries);
  const [columns, setColumns] = React.useState(determineColumns());

  React.useEffect(() => {
    setColumns(determineColumns());
  }, [timeSeries]);

  const priceGrowthData = data.growthMetrics.map((g) => {
    return {
      market: g.market,
      '1W': g.oneWeek,
      '1M': g.oneMonth,
      '3M': g.threeMonths,
      '6M': g.sixMonths,
      YTD: g.ytd,
      '1Y': g.oneYear,
      '5Y': g.fiveYears,
    };
  });

  return (
    <>
      {data.symbol.fairPrice ? (
        <>
          <SectionHeader value="Current Price vs Fair Value vs Target Price" />
          <VerdeScale data={data.symbol} />
        </>
      ) : null}
      <PriceGrowthTable
        columns={columns}
        data={priceGrowthData}
        timeSeries={timeSeries}
        setTimeSeries={setTimeSeries}
      />
    </>
  );
};

export default ValuationPriceComparison;
