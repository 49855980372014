import React from 'react';
import BulletPoints from '../BulletPoints';

const RatioGraphInsights = ({ insights, title }) => {
  if (!insights) return null;

  const isSectorInsightPositive = insights.industry.positives.length > 0;
  const isMarketInsightPositive = insights.market.positives.length > 0;

  return (
    <>
      <BulletPoints
        title={`${title} vs Sector`}
        points={
          isSectorInsightPositive
            ? insights.industry.positives
            : insights.industry.negatives
        }
        isPositive={isSectorInsightPositive}
      />
      <BulletPoints
        title={`${title} vs Market`}
        points={
          isMarketInsightPositive
            ? insights.market.positives
            : insights.market.negatives
        }
        isPositive={isMarketInsightPositive}
      />
    </>
  );
};

export default RatioGraphInsights;
