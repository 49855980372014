import React from 'react';
import { BorderedChartContainer } from '../../../StyedComponents';
import AreaChart from '../../../AreaChart';
import { getAreaChartData } from '../../../../../utils/ChartUtils';
import ToggleButton from '../../../ToggleButton';
import SectionHeader from '../../../SectionHeader';
import { useMediaQuery } from '@chakra-ui/react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';

const YieldOnCost = ({ data, period, setPeriod, darkMode }) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  return (
    <>
      <Flex style={{ marginBottom: '25px' }}>
        <SectionHeader value="Yield on Cost" infoKey="yield_on_cost" />
        {!isMobile && (
          <Box
            style={{
              marginLeft: 'auto',
              backgroundColor: darkMode ? '#161616' : '#f8f8f8',
              borderRadius: '15px',
              height: 'auto',
            }}
          >
            <ToggleButton period={period} setPeriod={setPeriod} />
          </Box>
        )}
      </Flex>

      {isMobile && (
        <Box
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: darkMode ? '#161616' : '#f8f8f8',
            borderRadius: '15px',
            marginBottom: '5px',
            height: 'auto',
          }}
        >
          <ToggleButton period={period} setPeriod={setPeriod} />
        </Box>
      )}

      <div style={{ marginTop: '10px' }}>
        <AreaChart
          data={getAreaChartData(
            data,
            ['yield_on_cost', 'evaluated_on'],
            period
          )}
          name="Yield on Cost"
          color={darkMode ? '#7bbfff' : '#438DF7'}
          gradientColor="#438DF7"
          yAxisLabelSuffix="%"
          valueType="percent"
        />
      </div>
    </>
  );
};

export default YieldOnCost;
