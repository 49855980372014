import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import DomainIcon from '../DomainIcon';

const TwButton = styled.button`
  ${tw`font-bold w-16 h-8 focus:outline-none rounded m-2 text-xs flex justify-evenly items-center`}
`;

const StyledButton = styled(TwButton)`
  color: ${(props) =>
    props.selected
      ? props.theme.priceGrowth.button.selectedColor
      : props.theme.priceGrowth.button.color};
  border: 1px solid
    ${(props) =>
      props.selected
        ? props.theme.priceGrowth.button.selectedBorder
        : props.theme.priceGrowth.button.border};
  background-color: ${(props) =>
    props.selected
      ? props.theme.priceGrowth.button.selectedBackground
      : props.theme.priceGrowth.button.background};
  @media (hover: hover) {
    &:hover {
      background-color: ${(props) => props.theme.table.buttonHover};
      color: ${(props) => props.theme.priceGrowth.button.selectedBorder};
      border: 1px solid
        ${(props) => props.theme.priceGrowth.button.selectedBorder};
    }
  }
  &:focus {
    border-color: ${(props) =>
      props.selected
        ? props.theme.priceGrowth.button.selectedBorder
        : props.theme.priceGrowth.button.border + ' !important'};
  }
`;

const PriceGrowthButtonGroup = (props) => {
  const { values, setValues } = props;

  const updateSelected = (index) => {
    const filtered = values.filter((t) => t.selected);
    if (filtered.length === 1 && filtered[0].name === values[index].name) {
      return;
    }

    values[index].selected = !values[index].selected;
    setValues([...values]);
  };

  return (
    <ul className="flex flex-wrap items-center w-full px-2">
      {values.map((v, index) => (
        <li key={index} className="flex">
          <StyledButton
            key={index}
            selected={v.selected}
            onClick={() => updateSelected(index)}
          >
            {v.name}
            {v.selected && <DomainIcon value="multi-button-close" />}
          </StyledButton>
        </li>
      ))}
    </ul>
  );
};

export default PriceGrowthButtonGroup;
