import { Button } from '@chakra-ui/button';
import { Box, List } from '@chakra-ui/layout';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  HollowGreenButton,
  ColoredContentText,
} from '../../shared/StyedComponents';

export const Text = styled(Box)`
  color: ${(props) => props.theme.screener_new.textColor};
`;
export const Tab = styled(List)`
  background-color: ${(props) => props.theme.screener_new.backgroundColor};
  width: max(max-content, 100%);
`;
export const TabItem = styled('li')`
  background-color: ${(props) =>
    props.selected
      ? props.theme.screener_new.tabSelectedBg
      : props.theme.screener_new.tabBg};
  color: ${(props) =>
    props.selected
      ? props.theme.screener_new.tabSelectedColor
      : props.theme.screener_new.tabColor};
  height: max-content;
`;

export const TableContainer = styled(Box)`
  background-color: ${(props) => props.theme.screener_new.backgroundColor};
`;

export const PrimaryButton = styled(Button)``;

export const Header = styled(ColoredContentText)`
  ${tw`text-lg font-medium`};
`;

export const ActionButton = styled(HollowGreenButton)`
  ${tw`py-2 px-8 mx-2`}
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.content.background};
  border: 1px solid ${(props) => props.theme.content.border};
`;
