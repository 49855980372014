import React, { useMemo } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  buildSummaryChartTooltipByValue,
  getFormattedCurrencyValueForTooltip,
  getTooltipDate,
  getFormattedNumberForTooltip,
} from '../../../utils/ChartUtils';
import { Box, Text } from '@chakra-ui/layout';
const getChartData = (data: any, period: number) => {
  let result: any = [];
  if (data.length) {
    result = [data[0]];
    let timestamp = data[0][0];
    data.forEach((item: any) => {
      if (item[0] > timestamp + period) {
        result.push(item);
        timestamp = timestamp + period;
      }
    });
  }

  return result;
};
Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B'],
  },
});
const wrapperFunction = function (
  this: any,
  proceed: any,
  symbol: any,
  x: any,
  y: any,
  w: any,
  h: any
) {
  const self: any = this;
  if (symbol.indexOf('text:') === 0) {
    var text = symbol.split(':')[1],
      svgElem = self.text(text, x, y + w + h) as any;

    return svgElem;
  }

  return proceed.apply(self, Array.prototype.slice.call(arguments, 1));
};

Highcharts.wrap(
  Highcharts.SVGRenderer.prototype,
  'symbol',
  wrapperFunction as (arg: any) => any
);

export const StackedAreaChart = ({
  data,
  darkMode,
  type = 'column',
  title,
  color,
  graphInterval = 3 * 30 * 24 * 3600 * 1000,
  isNumber = false,
  suffix = '',
}: {
  data: Array<Array<any>>;
  darkMode: any;
  title: string;
  color?: string;
  graphInterval?: number;
  type?: string;
  isNumber?: boolean;
  suffix?: string;
}) => {
  function generateTooltip(
    _this: any,
    mobileMode: boolean,
    isNumber: boolean,
    suffix: string
  ) {
    const points = _this.points;
    let tooltips = '';
    const padding = mobileMode ? 3 : 5;
    tooltips = tooltips.concat(
      '<div style="position:relative;height:100%;padding:' + padding + 'px;"> '
    );
    const point = points[0];

    if (point && point.y) {
      tooltips = tooltips.concat(
        buildSummaryChartTooltipByValue(
          suffix
            ? getFormattedNumberForTooltip(point.y) + suffix
            : isNumber
            ? getFormattedNumberForTooltip(point.y)
            : getFormattedCurrencyValueForTooltip(point.y),          
          darkMode,
          mobileMode
        )
      );
    }

    tooltips = tooltips.concat(getTooltipDate(_this.x, darkMode, mobileMode));
    return tooltips;
  }

  const options = {
    chart: {
      type: type,
      style: {
        fontFamily: 'Roboto',
      },
      backgroundColor: darkMode ? 'transparent' : '#ffffff',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
      },
      tickInterval: graphInterval,
      dateTimeLabelFormats: {
        day: '%e%b%y',
        month: '%b %Y',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 500,
          color: darkMode ? '#d8d9e4' : '#3a3a3a',
        },
        formatter: function (this: any): string {
          return (
            `${isNumber ? '' : '$'}` +
              this.axis.defaultLabelFormatter.call(this) +
              suffix ?? ''
          );
        },
      },

      gridLineColor: darkMode ? '#3b404f' : '#e6e6e6',
    },

    credits: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
      hideDelay: 600,
      shadow: false,
      useHTML: true,
      backgroundColor: darkMode ? '#141920' : '#fff',
      borderColor: darkMode ? '#86efaf' : '#5bae7f',
      borderRadius: 8,

      formatter: function () {
        return generateTooltip(this, false, isNumber, suffix);
      },
      shared: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        borderRadius: 5,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            tooltip: {
              formatter: function () {
                return generateTooltip(this, true, isNumber, suffix);
              },
            },

            chart: {
              height: 400,
            },
          },
        },
      ],
    },
    series: [
      {
        data: getChartData(data, graphInterval),
        type: type,
        marker: {
          enabled: false,
          symbol: 'circle',
          lineColor: 'transparent',
        },
        color: color || '#1fcbe0',
      },
    ],
  };

  return (
    <div className="text-xs font-semibold flex-1">
      <Box my="4" ml="2">
        <Text
          fontSize="lg"
          fontWeight="bold"
          color={darkMode ? '#d8d9e4' : '#3a3a3a'}
        >
          {title}
        </Text>
      </Box>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default React.memo(StackedAreaChart);
