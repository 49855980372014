import React, { useEffect } from 'react';
import SectionHeader from '../../../SectionHeader';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import Chart from '../Chart';
import { useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';

function ETFAssets({ data, darkMode }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const colorsPurple = ['#5e3fbe', '#a88deb', '#cbb6f8', '#f4f0fd', '#e5dafb'];

  const colorsGreen = ['#67c587', '#7c9685', '#223f30', '#305a41', '#c9ead4'];

  const marketCapColors = ['#448ef7', '#ee8079', '#eeb041', '#ab81e4'];

  const assetColors = [
    '#448ef7',
    '#ab81e4',
    '#69a5f9',
    '#d69e3b',
    '#5f3330',
    '#ddcdf4',
  ];

  const multiColors = [
    darkMode ? '#D49CFF' : '#ab81e4', //purple
    darkMode ? '#553e66' : '#785aa0',
    darkMode ? '#eed7ff' : '#ddcdf4',
    darkMode ? '#7bbfff' : '#785aa0', //blue
    darkMode ? '#314c66' : '#2247cc',
    darkMode ? '#cae5ff' : '#b4d2fc',
    darkMode ? '#ffc176' : '#eeb041', //yellow
    darkMode ? '#664d2f' : '#5f461a',
    darkMode ? '#ffe0bb' : '#f8dfb3',
    darkMode ? '#ff9494' : '#ee8079', //pink :)
    darkMode ? '#a75a55' : '#804a4a',
    darkMode ? '#ffcaca' : '#f8ccc9',
    // '#eeb041',
    // '#cbb6f8',
    // '#f4f0fd',
    // '#e5dafb',
  ];

  const [randomizedColors, setRandomizedColors] = useState([]);

  const assetClass = [
    'Stock_US_NetAssets',
    'Stock_nonUS_NetAssets',
    'Bond_NetAssets',
    'Bond_NetAssets',
    'Cash_NetAssets',
    'NotClassified_NetAssets',
    ,
  ];

  useEffect(() => {
    for (let i = 0; i < 5; i++) {
      let newArr = [];
      while (newArr.length < 10) {
        var r = Math.floor(Math.random() * 10) + 1;
        if (newArr.indexOf(r) === -1) newArr.push(r);
      }

      randomizedColors.unshift(newArr);
    }
  }, [darkMode, randomizedColors]);

  const [selected, setSelected] = useState({});
  const [clicked, setClicked] = useState({});
  const [symbol, setSymbol] = useState('');

  function callback(value, focused, mouseOut) {
    value && setSymbol(value.symbol);

    if (!focused) {
      value ? setSelected(value) : setSelected(undefined);
    } else {
      if (value === clicked) {
        setClicked({});
      } else {
        value && setClicked(value);
      }
    }

    if (mouseOut) {
      setSelected(undefined);
    }

    var element = selected
      ? document.getElementById(symbol)
      : document.getElementById(clicked.symbol);
    if (element !== null) {
      var headerOffset = isMobile ? 50 : 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      element.scrollIntoView({
        top: offsetPosition,
        block: 'nearest',
        inline: 'start',
      });
    }
  }

  function regionsMapping(data, colors) {
    let arr = [];
    for (let key in data) {
      arr.push({ ...data[key], value: data[key]['Equity_%'], symbol: key });
    }

    arr = arr
      .sort((a, b) => b.value - a.value)
      .filter((elem) => elem.value !== 0)
      .slice(0, 11);

    let count = 0;

    arr = arr.map((elem, index) => {
      if (count === 9) {
        count = 0;
      }

      return { ...elem, color: colors[randomizedColors[0][count++]] }; // also can do colors[newcolors[count++]]
    });
    return arr;
  }

  function sectorsMapping(data, colors) {
    let arr = [];
    for (let key in data) {
      arr.push({ ...data[key], value: data[key]['Equity_%'], symbol: key });
    }

    arr = arr
      .sort((a, b) => b.value - a.value)
      .filter((elem) => elem.value !== 0)
      .slice(0, 11);

    let count = 0;

    arr = arr.map((elem, index) => {
      if (count === 9) {
        count = 0;
      }

      return { ...elem, color: colors[randomizedColors[1][count++]] };
    });
    return arr;
  }

  function marketCapMapping(data, colors) {
    let arr = [];

    for (let key in data) {
      arr.push({ value: parseFloat(data[key]), symbol: key });
    }
    let count = 0;

    arr = arr
      .sort((a, b) => b.value - a.value)
      .filter((elem) => elem.value !== 0)
      .slice(0, 10)
      .map((elem, index) => {
        if (count === 9) {
          count = 0;
        }

        return { ...elem, color: colors[randomizedColors[2][count++]] };
      });

    return arr;
  }

  function assetMapping(data, colors) {
    let arr = [];

    for (let key in data) {
      arr.push({ value: parseFloat(data[key]), symbol: key });
    }
    let count = 0;
    let total = 0;

    arr = arr
      .sort((a, b) => b.value - a.value)
      .filter((elem) => elem.value !== 0)
      .slice(0, 10)
      .filter((elem) => assetClass.includes(elem.symbol))
      .map((elem, index) => {
        if (count === 9) {
          count = 0;
        }

        total += elem.value;

        return { ...elem, color: colors[randomizedColors[3][count++]] };
      });

    return arr;
  }

  let marketCapData = marketCapMapping(
    data['MarketCapitalisation'],
    multiColors
  );
  let assetClassData = assetMapping(data['Asset Allocation'], multiColors);
  let regionsData = regionsMapping(data['World Regions'], multiColors);
  let sectorsData = sectorsMapping(data['Sector Weights'], multiColors);

  return (
    <>
      <div>
        <div style={{ display: 'flex' }}>
          <SectionHeader value={'Asset Allocation'} infoKey="val" />

          <div style={{ marginTop: '2px', marginLeft: '2px' }}>
            <InfoOutlineIcon
              color="#0072db"
              height="16px"
              width="16px"
            ></InfoOutlineIcon>
          </div>
        </div>

        <div>
          <h1
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '20px',
              marginTop: '25px',
              fontWeight: 'bold',
            }}
          >
            Market Cap
          </h1>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Chart
              data={marketCapData}
              darkMode={darkMode}
              hideSymbol={true}
              callback={callback}
              title="Market Cap"
            />

            <div
              style={{
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 20,
              }}
            >
              {marketCapData.map((elem) =>
                elem.symbol !== 'Micro' ? (
                  <div
                    id={elem.symbol}
                    style={{
                      display: 'flex',
                      marginBottom: '15px',
                      backgroundColor: selected
                        ? selected.symbol === elem.symbol
                          ? `${elem.color}40`
                          : ''
                        : clicked.symbol === elem.symbol
                        ? `${elem.color}40`
                        : '',
                      borderRadius: '15px',
                      margin: '10px',
                      padding: '10px',
                      paddingTop: '4px',
                      paddingBottom: '4px',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: elem.color,
                        width: '20px',
                        height: '20px',
                        borderRadius: '10px',
                      }}
                    />
                    <div style={{ width: '160px' }}>
                      <h4
                        style={{
                          marginLeft: '10px',
                          fontSize: '16px',
                          fontWeight: selected
                            ? selected.color === elem.color &&
                              selected.symbol === elem.symbol
                              ? 'bold'
                              : ''
                            : clicked.color === elem.color &&
                              clicked.symbol === elem.symbol
                            ? 'bold'
                            : '',
                          color: darkMode ? '#cccccc' : 'black',
                          // textDecoration:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? 'underline' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? 'underline' : '',
                          // textDecorationStyle: 'solid',
                          // textUnderlineOffset:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? '4px' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? '4px' : '',
                        }}
                      >
                        {elem.symbol === 'Mega'
                          ? `${elem.symbol} Cap (>100B)`
                          : elem.symbol === 'Big'
                          ? `${elem.symbol} Cap (>10B)`
                          : elem.symbol === 'Medium'
                          ? `${elem.symbol} Cap (>1B)`
                          : `${elem.symbol} Cap (<1B)`}
                      </h4>
                    </div>
                    <h4
                      style={{
                        marginLeft: '25px',
                        color: darkMode ? 'white' : 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {elem.value !== 0
                        ? `${parseFloat(elem.value).toFixed(2)}%`
                        : `${elem.value}%`}
                    </h4>

                    {selected &&
                    selected.color === elem.color &&
                    selected.symbol === elem.symbol ? (
                      <h4
                        style={{
                          marginLeft: '10px',
                          color: darkMode ? 'white' : 'black',
                          fontWeight: 'bold',
                          fontSize: '20px',
                          marginTop: '-5px',
                        }}
                      >
                        {'>'}
                      </h4>
                    ) : clicked.color === elem.color &&
                      clicked.symbol === elem.symbol &&
                      selected === undefined ? (
                      <h4
                        style={{
                          marginLeft: '10px',
                          color: darkMode ? 'white' : 'black',
                          fontWeight: 'bold',
                          fontSize: '20px',
                          marginTop: '-5px',
                        }}
                      >
                        {'>'}
                      </h4>
                    ) : (
                      <div />
                    )}
                  </div>
                ) : (
                  <div></div>
                )
              )}
            </div>
          </div>
        </div>

        <div style={{ marginTop: '40px' }}>
          <h1
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '20px',
              marginTop: '25px',
              fontWeight: 'bold',
            }}
          >
            Asset Class
          </h1>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Chart
              data={assetClassData}
              darkMode={darkMode}
              hideSymbol={true}
              callback={callback}
              title="Asset Class"
            />

            <div
              style={{
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 10,
              }}
            >
              {assetClassData.map((elem) =>
                assetClass.includes(elem.symbol) ? (
                  <div
                    id={elem.symbol}
                    style={{
                      display: 'flex',
                      marginBottom: '15px',
                      backgroundColor: selected
                        ? selected.symbol === elem.symbol
                          ? `${elem.color}40`
                          : ''
                        : clicked.symbol === elem.symbol
                        ? `${elem.color}40`
                        : '',
                      borderRadius: '15px',
                      margin: '10px',
                      padding: '10px',
                      paddingTop: '4px',
                      paddingBottom: '4px',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: elem.color,
                        width: '20px',
                        height: '20px',
                        borderRadius: '10px',
                      }}
                    />
                    <div style={{ width: '160px' }}>
                      <h4
                        style={{
                          marginLeft: '10px',
                          fontSize: '16px',
                          fontWeight: selected
                            ? selected.color === elem.color &&
                              selected.symbol === elem.symbol
                              ? 'bold'
                              : ''
                            : clicked.color === elem.color &&
                              clicked.symbol === elem.symbol
                            ? 'bold'
                            : '',
                          color: darkMode ? '#cccccc' : 'black',
                          // textDecoration:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? 'underline' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? 'underline' : '',
                          // textDecorationStyle: 'solid',
                          // textUnderlineOffset:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? '4px' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? '4px' : '',
                        }}
                      >
                        {elem.symbol === 'Stock_US_NetAssets'
                          ? 'Domestic Stocks'
                          : elem.symbol === 'Stock_nonUS_NetAssets'
                          ? 'International Stocks'
                          : elem.symbol === 'Bond_NetAssets'
                          ? 'Bonds'
                          : elem.symbol === 'Cash_NetAssets'
                          ? 'Cash'
                          : elem.symbol === 'NotClassified_NetAssets'
                          ? 'Other'
                          : elem.symbol}
                      </h4>
                    </div>
                    <h4
                      style={{
                        marginLeft: '25px',
                        color: darkMode ? 'white' : 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {elem.value !== 0
                        ? `${parseFloat(elem.value).toFixed(2)}%`
                        : `${elem.value}%`}
                    </h4>

                    {selected &&
                    selected.color === elem.color &&
                    selected.symbol === elem.symbol ? (
                      <h4
                        style={{
                          marginLeft: '10px',
                          color: darkMode ? 'white' : 'black',
                          fontWeight: 'bold',
                          fontSize: '20px',
                          marginTop: '-5px',
                        }}
                      >
                        {'>'}
                      </h4>
                    ) : clicked.color === elem.color &&
                      clicked.symbol === elem.symbol &&
                      selected === undefined ? (
                      <h4
                        style={{
                          marginLeft: '10px',
                          color: darkMode ? 'white' : 'black',
                          fontWeight: 'bold',
                          fontSize: '20px',
                          marginTop: '-5px',
                        }}
                      >
                        {'>'}
                      </h4>
                    ) : (
                      <div />
                    )}
                  </div>
                ) : (
                  <div />
                )
              )}
            </div>
          </div>
        </div>

        <div style={{ marginTop: '40px' }}>
          <h1
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '20px',
              marginTop: '25px',
              fontWeight: 'bold',
            }}
          >
            Regions
          </h1>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Chart
              data={regionsData}
              darkMode={darkMode}
              hideSymbol={true}
              callback={callback}
              title="Regions"
            />

            <div
              style={{
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 10,
              }}
            >
              {regionsData.map((elem) => (
                <div
                  id={elem.symbol}
                  style={{
                    display: 'flex',
                    marginBottom: '15px',
                    backgroundColor: selected
                      ? selected.symbol === elem.symbol
                        ? `${elem.color}40`
                        : ''
                      : clicked.symbol === elem.symbol
                      ? `${elem.color}40`
                      : '',
                    borderRadius: '15px',
                    margin: '10px',
                    padding: '10px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: elem.color,
                      width: '20px',
                      height: '20px',
                      borderRadius: '10px',
                    }}
                  />
                  <div style={{ width: '160px' }}>
                    <h4
                      style={{
                        marginLeft: '10px',
                        fontSize: '16px',
                        fontWeight: selected
                          ? selected.color === elem.color &&
                            selected.symbol === elem.symbol
                            ? 'bold'
                            : ''
                          : clicked.color === elem.color &&
                            clicked.symbol === elem.symbol
                          ? 'bold'
                          : '',
                        color: darkMode ? '#cccccc' : 'black',
                        //  textDecoration:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? 'underline' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? 'underline' : '',
                        //  textDecorationStyle: 'solid',
                        //  textUnderlineOffset:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? '4px' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? '4px' : '',
                      }}
                    >
                      {elem.symbol}
                    </h4>
                  </div>
                  <h4
                    style={{
                      marginLeft: '25px',
                      color: darkMode ? 'white' : 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {elem.value !== 0
                      ? `${parseFloat(elem.value).toFixed(2)}%`
                      : `${elem.value}%`}
                  </h4>

                  {selected &&
                  selected.color === elem.color &&
                  selected.symbol === elem.symbol ? (
                    <h4
                      style={{
                        marginLeft: '10px',
                        color: darkMode ? 'white' : 'black',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        marginTop: '-5px',
                      }}
                    >
                      {'>'}
                    </h4>
                  ) : clicked.color === elem.color &&
                    clicked.symbol === elem.symbol &&
                    selected === undefined ? (
                    <h4
                      style={{
                        marginLeft: '10px',
                        color: darkMode ? 'white' : 'black',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        marginTop: '-5px',
                      }}
                    >
                      {'>'}
                    </h4>
                  ) : (
                    <div />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div style={{ marginTop: '40px' }}>
          <h1
            style={{
              color: darkMode ? 'white' : 'black',
              fontSize: '20px',
              marginTop: '25px',
              fontWeight: 'bold',
            }}
          >
            Sectors
          </h1>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Chart
              data={sectorsData}
              darkMode={darkMode}
              hideSymbol={true}
              callback={callback}
              title="Sectors"
            />

            <div
              style={{
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {sectorsData.map((elem) => (
                <div
                  id={elem.symbol}
                  style={{
                    display: 'flex',
                    marginBottom: '15px',
                    backgroundColor: selected
                      ? selected.symbol === elem.symbol
                        ? `${elem.color}40`
                        : ''
                      : clicked.symbol === elem.symbol
                      ? `${elem.color}40`
                      : '',
                    borderRadius: '15px',
                    margin: '10px',
                    padding: '10px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: elem.color,
                      width: '20px',
                      height: '20px',
                      borderRadius: '10px',
                    }}
                  />
                  <div style={{ width: '160px' }}>
                    <h4
                      style={{
                        marginLeft: '10px',
                        fontSize: '16px',
                        fontWeight: selected
                          ? selected.color === elem.color &&
                            selected.symbol === elem.symbol
                            ? 'bold'
                            : ''
                          : clicked.color === elem.color &&
                            clicked.symbol === elem.symbol
                          ? 'bold'
                          : '',
                        color: darkMode ? '#cccccc' : 'black',
                        // textDecoration:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? 'underline' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? 'underline' : '',
                        // textDecorationStyle: 'solid',
                        // textUnderlineOffset:  selected ? selected.color === elem.color && selected.symbol === elem.symbol ? '4px' : '' :  clicked.color === elem.color && clicked.symbol === elem.symbol ? '4px' : '',
                      }}
                    >
                      {elem.symbol}
                    </h4>
                  </div>
                  <h4
                    style={{
                      marginLeft: '25px',
                      color: darkMode ? 'white' : 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {elem.value !== 0
                      ? `${parseFloat(elem.value).toFixed(2)}%`
                      : `${elem.value}%`}
                  </h4>

                  {selected &&
                  selected.color === elem.color &&
                  selected.symbol === elem.symbol ? (
                    <h4
                      style={{
                        marginLeft: '10px',
                        color: darkMode ? 'white' : 'black',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        marginTop: '-5px',
                      }}
                    >
                      {'>'}
                    </h4>
                  ) : clicked.color === elem.color &&
                    clicked.symbol === elem.symbol &&
                    selected === undefined ? (
                    <h4
                      style={{
                        marginLeft: '10px',
                        color: darkMode ? 'white' : 'black',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        marginTop: '-5px',
                      }}
                    >
                      {'>'}
                    </h4>
                  ) : (
                    <div />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ETFAssets;
