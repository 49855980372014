import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import numeral from 'numeral';

const Chart = ({
  data,
  darkMode,
  format,
  //callback,
  title,
}) => {
  const [mainData, setMainData] = useState([]);
  const [mouseOut, setMouseOut] = useState(false);
  const [clicked, setClicked] = useState(false);

  function getValue(_this) {
    const data = _this.point && _this.point.options;
    data && setMainData(data);
  }

  const options = useMemo(() => {
    const option = {
      chart: {
        type: 'bar',
        style: {
          fontFamily: 'Roboto',
        },
        backgroundColor: darkMode ? 'transparent' : '#ffffff',
        //width: 400,
      },
      title: {
        text: '',
      },
      tooltip: {
        valueDecimals: 2,
        hideDelay: 600,
        shadow: false,
        useHTML: true,
        borderRadius: 8,
        followPointer: true,

        shared: true,
        formatter: function () {
          getValue(this);
          //callback(mainData, clicked, mouseOut);
          setClicked(false);
          return false;
        },
      },
      legend: {
        enabled: false,
      },
      label: {
        // enabled: true,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: 'Sharpe Ratio',
        },
        gridLineWidth: 0,
        //tickInterval: 0.1,
      },
      xAxis: {
        categories: data.map((x) => x.name),
        gridLineWidth: 0,
        visible: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400,
            },
            chartOptions: {
              plotOptions: {
                //pointWidth: 20,
                pie: {
                  size: '70%',
                  borderWidth: 1,
                  borderColor: darkMode ? '#1d212b' : 'white',
                },
              },
              // tooltip: {},
              chart: {
                height: 420,
                width: 360,
              },
            },
          },
        ],
      },
      series: [
        {
          data: data,
          //color: '#16803c',
          borderWidth: 0,
          plotOptions: {
            bar: {
              borderWidth: 0,
              borderRadius: '2px',             
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.point.name}: ${numeral(this.point.y).format(
                '0,0.000'
              )}`;
            },
            color: darkMode ? '#f6f6f6' : '#3a3a3a',
            style: {
              fontSize: '1.2em',
            },
          },
        },
      ],
    };

    return option;
  }, [darkMode, data, format]);

  return (
    <div className="text-xs font-semibold">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
});

export default connect(mapStateToProps)(Chart);
