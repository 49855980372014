import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import VerdeScoreSvg from '../../../../public/images/verde-icon.svg';
import { Link } from 'react-router-dom';
import defaultPic from '../../../../public/images/default-profile-picture.jpg';
import  PortfolioModal  from '../../../pages/MyVerdeRevamp/Modal/PortfolioModal';

const TopProfile = ({ profileData, darkMode, verdeScore, data }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);

  return (
    <Box>
      <Flex justifyContent={isMobile ? 'center' : null}>
        {/* <Flex
          justifyContent="center"
          backgroundSize="cover"
          borderRadius={100}
          backgroundImage={`${profileData && profileData['profile_pic_url'] !== null ? profileData['profile_pic_url'] : VerdeScoreSvg }`}
          width={isMobile ? 100 : 150}
          height={isMobile ? 97 : 147}
          marginTop={10}
        /> */}

        {/* <img
          id="profilePicture"
          src={`${
            profileData && profileData['profile_pic_url'] !== null
              ? profileData['profile_pic_url']
              : defaultPic
          }`}
          style={{
            objectFit:'cover',
            marginTop: 25,
            borderRadius: 100,
            maxWidth: isMobile ? 100 : 150,
            maxHeight: isMobile ? 100 : 150,
            minWidth: isMobile ? 100 : 150,
            minHeight: isMobile ? 100 : 150,
          }}
        /> */}


        {showPortfolioModal && (
          <PortfolioModal
            data={data} //data is ind. portfolios
            darkMode={darkMode}
            closeModal={() => setShowPortfolioModal(false)}
            profileData={profileData}
            onUnlink={(chosenUnlinkPortfolio) => {
              window.location.reload();
              // let filteredArr = portfolios.filter((elem) => elem.insightKey !== chosenUnlinkPortfolio.portfolioId)
              // if(filteredArr.length === 0)
              // {
              // history.push('/')
              // }
              //   else
              //   {
              //   setPortfolios([...filteredArr])
              //   setCurrentPortfolioValue({
              //     insightKey: 'all_portfolios',
              //     account_details: allData[1],
              //     account_holdings: allData[0] && allData[0].all_portfolios,
              //   });

              //   setCurrentPortfolioLabel('All Portfolios');
              //   setCurrentPortfolioInsightKey('all_portfolios');
              // }
            }}
          />
        )}

        {/* <Box marginLeft={10} marginTop={10} width={isMobile ? '60%' : '90%'}> */}
          {/* <Flex>
            <Box>
              <Text
                style={{
                  color: darkMode ? 'white' : 'black',
                  fontWeight: 500,

                  textAlign: 'left',
                  fontSize: 26,
                }}
              >
                {profileData && profileData['name']}
              </Text>
              <Flex
                justifyContent="flex-start"
                flexWrap={isMobile ? 'wrap' : 'nowrap'}
              >
                <Box marginRight={isMobile ? 30 : 70}>
                  <Flex marginTop={2}>
                    <img
                      src={VerdeScoreSvg}
                      alt=""
                      className="w-5 h-5 mb-0.5 mr-1 mt-1"
                    />
                    <Text
                      style={{
                        color: darkMode ? '#f6f6f6' : 'black',
                        fontSize: isMobile ? 18 : '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      {verdeScore}
                    </Text>
                  </Flex>
                  <Text
                    style={{
                      color: darkMode ? '#cccccc' : 'black',
                      fontSize: isMobile ? 14 : 16,
                      marginTop: 5,
                    }}
                  >
                    Verde Score
                  </Text>
                </Box>
                {/* <Box marginTop={2} marginRight={isMobile ? 30 : 70}>
                  <Text
                    style={{
                      color: darkMode ? 'white' : 'black',
                      fontSize: isMobile ? 18 : 20,
                      fontWeight: 500,
                    }}
                  >
                    {profileData && profileData['similarity_score'] !== null
                      ? profileData['similarity_score']
                      : '-'}
                  </Text>
                  <Text
                    style={{
                      color: darkMode ? '#cccccc' : 'black',
                      fontSize: isMobile ? 14 : 16,
                      marginTop: 5,
                    }}
                  >
                    Similarity Score
                  </Text>
                </Box>
                <Box marginTop={2} marginRight={isMobile ? 30 : 70}>
                  <Text
                    style={{
                      color: darkMode ? 'white' : 'black',
                      fontSize: isMobile ? 18 : 20,
                      fontWeight: 500,
                    }}
                  >
                    {profileData && profileData['followers_count']}
                  </Text>
                  <Text
                    style={{
                      color: darkMode ? '#cccccc' : 'black',
                      fontSize: isMobile ? 14 : 16,
                      marginTop: 5,
                    }}
                  >
                    Followers
                  </Text>
                </Box>
                <Box marginTop={2}>
                  <Text
                    style={{
                      color: darkMode ? 'white' : 'black',
                      fontSize: isMobile ? 18 : 20,
                      fontWeight: 500,
                    }}
                  >
                    {profileData && profileData['followees_count']}
                  </Text>
                  <Text
                    style={{
                      color: darkMode ? '#cccccc' : 'black',
                      fontSize: isMobile ? 14 : 16,
                      marginTop: 5,
                    }}
                  >
                    Following
                  </Text>
                </Box>
              </Flex>
            </Box>

            {!isMobile && (
              <Box marginLeft="auto">
                <Link to="/profile">
                  <button
                    style={{
                      padding: 5,
                      width:'120px',
                      color: darkMode ? '#4adf79' : '#01874e',
                      border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                      borderRadius: 20,
                      marginTop: 20,
                      fontSize: 15,
                      fontWeight: 500,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Edit Profile
                  </button>
                </Link>
                <Box>
                  <button
                    onClick={() => {
                      const dummy = document.createElement('input'),
                        text = window.location.href;

                      document.body.appendChild(dummy);
                      dummy.value = text;
                      dummy.select();
                      document.execCommand('copy');
                      document.body.removeChild(dummy);
                      alert('Profile ID copied to clipboard');
                    }}
                    style={{
                      padding: 5,
                      width: isMobile ? '60%' : '120px',
                      color: darkMode ? '#4adf79' : '#01874e',
                      border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                      borderRadius: 20,
                      marginTop: 10,
                      fontSize: 15,
                      fontWeight: 500,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    Share Profile
                  </button>
                </Box>
              </Box>
            )}
          </Flex>

          <Text
            style={{
              color: darkMode ? '#cccccc' : 'black',
              fontSize: 16,
              marginTop: 10,
              textAlign: 'left',
            }}
          >
            {profileData && profileData['description']}
          </Text>
          {isMobile && (
            <Link to="/profile">
              <button
                style={{
                  padding: 5,
                  width: isMobile ? '60%' : '120px',
                  color: darkMode ? '#4adf79' : '#01874e',
                  border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                  borderRadius: 20,
                  marginTop: 20,
                  fontSize: 15,
                  fontWeight: 500,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Edit Profile
              </button>
            </Link>
          )} */}
          {isMobile && (
            <button
              style={{
                padding: 8,
                width: '150px',
                color: darkMode ? '#4adf79' : '#01874e',
                border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                borderRadius: 20,
                fontSize: 15,
                marginTop: 10,
                fontWeight: 500,
                justifyContent: 'center',
                //alignItems: 'center',
              }}
              onClick={() => setShowPortfolioModal(true)}
            >
              Manage Portfolio
            </button>
          )}
        {/* </Box> */}
      </Flex>
    </Box>
  );
};

export default TopProfile;
