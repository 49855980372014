import { all, call } from 'redux-saga/effects';
import { watchFetchingSymbolData } from './symbol';
import { watchFetchingStockData } from './stock';
import { watchFetchingPastPerformanceData } from './pastperformance';
import { watchFetchingScreenerData } from './screener';
import { watchFetchingValuationData } from './valuation';
import { watchFetchingSimilarCompaniesData } from './similarCompanies';
import { watchFetchingSymbolInsightsData } from './insights';
import { watchFetchingSymbolNewsData } from './news';
import { watchFetchingFutureGrowthData } from './futureGrowth';
import { watchFetchingDividendData } from './dividend';
import { watchFetchingInsidersData } from './insiders';
import { wathFetchingUserData } from './user';
import { watchFectchMyVerde } from './my-verde';
import { watchSummaryData } from './summary';
import { watchFetchingRealTimeData } from './real-time';

export function* rootSaga() {
  yield all([
    call(watchFetchingSymbolData),
    call(watchFetchingStockData),
    call(watchFetchingPastPerformanceData),
    call(watchFetchingScreenerData),
    call(watchFetchingValuationData),
    call(watchFetchingSimilarCompaniesData),
    call(watchFetchingSymbolInsightsData),
    call(watchFetchingSymbolNewsData),
    call(watchFetchingFutureGrowthData),
    call(watchFetchingDividendData),
    call(watchFetchingInsidersData),
    call(wathFetchingUserData),
    call(watchFectchMyVerde),
    call(watchSummaryData),
    call(watchFetchingRealTimeData),
  ]);
}
